import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { AssessmentStateService } from '../../assessment-state.service';

import './item-analysis-panel.component.scss';


@Component({
    selector: 'item-analysis-panel',
    templateUrl: './item-analysis-panel.component.html',
    // styleUrls: ['./item-analysis-panel.component.scss'],
})
export class ItemAnalysisPanelComponent implements OnChanges {

    @Input() option: any;
    @Input() questionAnalysis: any;
    @Input() foilIdToRomanMap: any;

    constructor(public AssessmentStateService: AssessmentStateService) {}

    ngOnChanges(change: SimpleChanges) {
    }

    public foilToRoman(foilIds) {
        return String(foilIds)
            .split('|')
            .map(foilId => this.foilIdToRomanMap[Number(foilId)])
            .join(', ');
    }

    public isResponseEqual(resp1, resp2) {
        return String(resp1).toLowerCase() === String(resp2).toLowerCase();
    }
}
