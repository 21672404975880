import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from '../shared/shared.module';
import { MapModule } from '../map/map.module';
import { ReportModule } from '../report/report.module';

import { HeatmapService } from './heatmap.service';
import { HeatmapPageComponent } from './heatmap.component';
import { HeatmapConstructComponent } from './construct/heatmap-construct.component';
import { StudentMultiselectDirective } from './construct/student-multiselect.directive';
import { HeatmapStackQuestionComponent } from './stack-question/heatmap-stack-question.component';
import { HeatmapLegendComponent } from './legend/heatmap-legend.component';
import { HeatmapRosterComponent } from './roster/heatmap-roster.component';

import { StudentFeedbackModalComponent } from './student-feedback/student-feedback-modal.component';
import { StudentFeedbackChartComponent } from './student-feedback/chart/student-feedback-chart.component';


@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        NgbPopoverModule,
        MatFormModule,
        MatExpansionModule,
        MatTooltipModule,
        SharedModule,
        MapModule,
        ReportModule,
    ],
    providers: [
        HeatmapService,
    ],
    declarations: [
        HeatmapPageComponent,
        HeatmapConstructComponent,
        StudentMultiselectDirective,
        HeatmapStackQuestionComponent,
        HeatmapLegendComponent,
        HeatmapRosterComponent,
        StudentFeedbackModalComponent,
        StudentFeedbackChartComponent,
    ],
    entryComponents: [
        StudentFeedbackModalComponent,
    ],
})
export class HeatmapModule { }
