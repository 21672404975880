import { Component, Input } from '@angular/core';

import './cluster-graph-legend.component.scss';


@Component({
    selector: 'cluster-graph-legend',
    templateUrl: './cluster-graph-legend.component.html',
    // styleUrls: ['./cluster-graph-legend.component.scss'],
    host: {
        class: 'cluster-graph-legend',
    },
})
export class ClusterGraphLegendComponent {

    @Input() cluster: any;
    @Input() scoresSimple: boolean;
    @Input() scoresToggling: boolean;

    constructor(
    ) {}

}
