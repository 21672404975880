import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'session-expired-modal',
    templateUrl: './session-expired-modal.component.html'
})
export class SessionExpiredModalComponent {

    constructor(
        public activeModal: NgbActiveModal,
    ) {}
}
