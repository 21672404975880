import { Title } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { StateService } from '@uirouter/core';
import { TransitionService } from '@uirouter/angular';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionService, tokenGetter } from './core/service/session.service';
import { UtilsService } from './core/service/utils.service';
import { AuthService } from './core/service/auth.service';
import { HttpService } from './core/service/http.service';
import { AnalyticsService } from './core/service/analytics.service';
import { ModalService } from './shared/service/modal.service';

import './app.component.scss';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    // styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    public isIE: boolean = false;
    public route: any;
    private unsub: any;
    public isNavOpen: boolean;
    private focusHandler = this.startCheckingSession.bind(this);
    private blurHandler = this.stopCheckingSession.bind(this);
    private unloadHandler = this.pageUnload.bind(this);

    private SESSION_CHECK_INTERVAL = 60 * 1000;
    private checkSessionInterval = null;

    constructor(
        private element: ElementRef,
        private titleService: Title,
        private swUpdate: SwUpdate,
        private $state: StateService,
        private transitionService: TransitionService,
        public jwtHelper: JwtHelperService,
        public SessionService: SessionService,
        private AuthService: AuthService,
        public HttpService: HttpService,
        private ModalService: ModalService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
    ) {
        this.isIE = this.UtilsService.isIE();
    }

    public ngOnInit() {
        // Make route data public and update page title
        this.unsub = this.transitionService.onSuccess({}, transition => {
            this.route = transition.to();
            this.titleService.setTitle( this.route.data.pageTitle );
        });

        // Subscribe to new service worker version available
        this.swUpdate.available.subscribe(event => {
            this.HttpService.isAppOutdated = true;
        });

        this.UtilsService.isNavOpen$.subscribe(shouldOpen => this.isNavOpen = shouldOpen);

        // Load user profile data if stored from a previous session
        this.SessionService.getUserProfile();

        // Start the interval when the application loads the first time
        this.startCheckingSession();
        // Check if session has ended whenever the window has focus
        window.addEventListener('focus', this.focusHandler);
        window.addEventListener('blur', this.blurHandler);
        window.addEventListener('beforeunload', this.unloadHandler);
    }

    public ngOnDestroy() {
        this.unsub();
        this.stopCheckingSession();
        window.removeEventListener('focus', this.focusHandler);
        window.removeEventListener('blur', this.blurHandler);
        window.removeEventListener('beforeunload', this.unloadHandler);
    }

    private pageUnload() {
        this.SessionService.unloadingPage = true;
    }

    public reloadPage() {
        this.AnalyticsService.action({ action: 'reload_app_new_version' })
            .catch(console.warn)
            .finally(() => window.location.reload(true));
    }

    private startCheckingSession() {
        this.checkSession();
        // Initialize check session interval
        if (!this.checkSessionInterval) {
            this.checkSessionInterval = setInterval(() => {
                this.checkSession();
            }, this.SESSION_CHECK_INTERVAL);
        }
    }

    private stopCheckingSession() {
        clearInterval(this.checkSessionInterval);
        this.checkSessionInterval = null;
    }

    private checkSession() {
        // Check token every minute
        let user = this.SessionService.getUser();
        let token = tokenGetter();
        let utc = Math.floor(new Date().getTime() / 1000);

        // Only run check if there's an active user logged in
        if (user) {
            if (this.jwtHelper.isTokenExpired(token) && !document.querySelector('.session-expired-modal')) {
                this.AuthService.logout({
                    isForcedLogout: true,
                    nextStateName: this.$state.current.name,
                    nextStateParams: this.$state.params,
                }).catch(console.warn);
            } else {
                // Token is not expired, so open warning modal if it isn't already open
                if (!document.querySelector('.session-modal') && !document.querySelector('.session-expired-modal')) {
                    // If token is 25 minutes from expiring
                    if (user.exp - utc <= (25 * 60)) {
                        // If the user has been active within the past 20 minutes automatically refresh their token
                        if (utc - (this.SessionService.lastInteraction / 1000) < (20 * 60)) {
                            this.AuthService.refresh().catch(console.warn);
                        // Open warning modal and log them out after session expires unless they manually extend their session
                        } else {
                            this.ModalService.openSessionExpiringModal();
                        }
                    }
                }
            }
        }
    }
}
