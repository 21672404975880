import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { datepickerOptions } from '@/app.constant';
import { groupByCourses } from '@/_helpers/transforms';
import { DateValidators } from '@/shared/directives';
import { UtilsService } from '../../core/service/utils.service';
import { MapService } from '../../map/map.service';
import { AssessmentManagementService } from '../assessment-management.service';

import './new-test-form.component.scss';


@Component({
    selector: 'new-test-form',
    templateUrl: './new-test-form.component.html',
    // styleUrls: ['./new-test-form.component.scss'],
})
export class NewTestFormComponent implements OnInit {

    @Input() type: string;
    @Input() dropdownModels: any;
    @Input() schoolYears: any;
    @Input() cluster: any;
    @Input() courseOrSection: any;
    @Input() courseOrSectionType: 'course'|'section';
    @Input() selectedDate: any;
    @Input() isInactive: boolean = false;
    @Output() testCreated = new EventEmitter<any>();

    public form: FormGroup;
    public loadingClusters = false;
    public loadingGrades = false;
    public clusters = [];
    public constructs = [];
    public formsRange = [];
    public allowRetest: boolean = false;
    public datepickerOptions = Object.assign({}, datepickerOptions);
    public readonly groupByCourses = groupByCourses;

    constructor(
        private fb: FormBuilder,
        private MapService: MapService,
        private AssessmentManagementService: AssessmentManagementService,
        private UtilsService: UtilsService,
    ) {
        this.form = this.fb.group({
            section_ids:        [{ value: null, disabled: true }, Validators.required],
            course_ids:         [{ value: null, disabled: true }, Validators.required],
            cluster_id:         [{ value: null, disabled: true }, Validators.required],
            construct_id:       [{ value: null, disabled: true }],
            grade:              [{ value: null, disabled: true }, Validators.required],
            forms:              [{ value: null, disabled: true }, Validators.required],
            assessment_id:      [{ value: null, disabled: false }, Validators.required],
            start_date:         [{ value: new Date(), disabled: false }, Validators.required],
            end_date:           [{ value: new Date(), disabled: false }, Validators.required],
        }, {
            validators: [
                DateValidators.dateLessThan('start_date', 'end_date', { 'datelt': true }),
            ],
        });
    }

    public ngOnInit() {
        if (this.dropdownModels.schoolYear) {
            this.form.controls.section_ids.enable();
        }

        // If a section is selected from test management page pre-populate this field value
        if (this.dropdownModels.section) {
            this.form.controls.section_ids.enable();
            this.form.controls.section_ids.patchValue([this.dropdownModels.section.id]);
        } else if (this.courseOrSectionType === 'course' || this.courseOrSectionType === 'section') {
            this.form.controls[`${this.courseOrSectionType}_ids`].enable();
            this.form.controls[`${this.courseOrSectionType}_ids`].patchValue([this.courseOrSection.id]);
        }

        this.datepickerOptions.minDate = new Date(this.dropdownModels.schoolYear.start_date + 'T00:00');
        this.datepickerOptions.maxDate = new Date(this.dropdownModels.schoolYear.end_date + 'T00:00');

        if (this.selectedDate) {
            this.form.controls.start_date.setValue(this.selectedDate.toDate());
            this.form.controls.end_date.setValue(this.selectedDate.toDate());
        }

        if (this.cluster) {
            this.form.controls.cluster_id.setValue(this.cluster.id);
            this.constructs = this.cluster.constructs;
            this.form.controls.construct_id.enable();
            this.getGradesList();
        }

        this.loadingClusters = true;
        this.MapService.getLearningMap()
            .then(map => {
                // Trigger ng-select change detection
                this.clusters = map.clusters.filter(cluster => !cluster.wormhole);
                this.form.controls.cluster_id.enable();
            })
            .catch(console.warn)
            .finally(() => this.loadingClusters = false);
    }

    public clearTestValues({ clearSection = false, clearConstruct = false } = {}) {
        if (clearSection) {
            this.form.controls.section_ids.enable();
            this.form.controls.section_ids.setValue(null);
        }

        if (clearConstruct) {
            const selectedCluster = this.clusters.find(clu => clu.id === this.form.controls.cluster_id.value);
            if (selectedCluster) {
                this.constructs = selectedCluster.constructs;
            }
            this.form.controls.construct_id.enable();
            this.form.controls.construct_id.setValue(null);
        }

        this.form.controls.grade.setValue(null);
        this.form.controls.assessment_id.setValue(null);
    }

    public getGradesList() {
        // Reset grade level field and assessment_id value
        this.form.controls.grade.enable();
        this.form.controls.grade.setValue(null);
        this.form.controls.assessment_id.setValue(null);
        this.dropdownModels.grades = null;
        this.form.controls.forms.disable();
        this.form.controls.forms.setValue(null);
        this.formsRange = [];

        // Get list of assessments (and grades)
        this.loadingGrades = true;
        this.AssessmentManagementService.getAssessmentDetails({
            cluster_id: this.form.controls.cluster_id.value,
            construct_id: this.form.controls.construct_id.value,
            school_id: this.dropdownModels.schoolYear.school,
        })
            .then((result: any) => {
                this.dropdownModels.grades = result.sort((a, b) => {
                    const diff = (parseInt(a.grade) || 0) - (parseInt(b.grade) || 0);
                    if (diff === 0) {
                        return a.grade.length - b.grade.length;
                    }
                    return diff;
                });
                // Grey out the grades dropdown if there are no tests available
                if (this.dropdownModels.grades.length === 0) {
                    this.form.controls.grade.disable();
                }
            })
            .catch(console.warn)
            .finally(() => this.loadingGrades = false);
    }

    public setAssessmentAndForms(assessment) {
        // Select the last assessment to match the grade in case there were duplicate assessments for a grade
        this.form.controls.assessment_id.setValue(assessment.assessment_id);
        // Generate array ranging from 1 to # of forms and select default of max num of forms
        this.formsRange = [...new Array(assessment.forms)].map((undef, idx) => {
            return { id: idx + 1, name: idx + 1 };
        });
        this.form.controls.forms.enable();
        this.form.controls.forms.setValue(assessment.forms);
    }

    public submitNewTestForm() {
        // Copy top level props so changes to it do no affect original form values
        let testData = Object.assign({}, this.form.value);

        if (testData.section_ids) {
            testData.section_ids = JSON.stringify(testData.section_ids);
        }
        if (testData.course_ids) {
            testData.course_ids = JSON.stringify(testData.course_ids);
        }

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentManagementService.createClusterAssessment(testData)
            .then((results: any) => {
                // Reset form to blank
                this.form.reset();
                // If any of the new tests belongs to the current section add to the list of tests
                if (this.dropdownModels.section) {
                    let newAssp = results.find((assp) => {
                        return assp.section_id === this.dropdownModels.section.id;
                    });
                    // If a new test has been created for the current section
                    if (newAssp) {
                        return this.testCreated.emit(newAssp);
                    }
                }
                this.testCreated.emit(results);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public cancelForm() {
        this.form.reset();
        this.testCreated.emit();
    }
}
