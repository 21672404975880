import { Component, Input, ElementRef, AfterViewInit } from '@angular/core';

import { select, event as d3Event } from 'd3-selection';
import { interpolate as d3Interpolate } from 'd3-interpolate';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';


const midAngle = (d) => {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
}

const colorScheme = (idx) => {
    let colors = [
        '#5BDDD3', '#4D98D0', '#4892A3', '#5C5C99', '#544367',
        // Google charts colors after first 5 colors are used up
        '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099',
        '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395',
        '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300',
        '#8B0707', '#651067', '#329262', '#5574A6', '#3B3EAC'
    ];
    return colors[idx % colors.length];
}

@Component({
    selector: 'student-feedback-chart',
    templateUrl: './student-feedback-chart.component.svg.html',
    host: {
        'class': 'student-feedback-chart'
    }
})
export class StudentFeedbackChartComponent implements AfterViewInit {

    @Input() responses: any;
    private TRANSITION_DURATION = 1000;
    private radius = 240;

    constructor(
        private element: ElementRef,
    ) {}

    public ngAfterViewInit() {
        if (!this.responses) {
            return false;
        }

        let rootEle = select(this.element.nativeElement).select('.pie-chart-layer');
        let tooltip = select(this.element.nativeElement).select('.chart-popover');
        // Later becomes .modal-dialog.modal-lg after modal finishes loading
        let modalOffset = this.element.nativeElement.closest('[role="document"]');
        // Later becomes .modal.student-feedback-modal after modal finishes loading
        let scrollOffset = this.element.nativeElement.closest('[role="document"]');
        let totalResponses = this.responses.reduce((acc, question) => acc + question.tally, 0);

        // Pie chart
        let arc = d3Arc()
            .innerRadius(0)
            .outerRadius(this.radius * 0.8);
        // Used to position polylines and labels outside pie chart
        let outerArc = d3Arc()
            .innerRadius(this.radius * 0.9)
            .outerRadius(this.radius * 0.9);

        let pie = d3Pie()
            .value((d: any) => d.tally);
        // Pie chart slices
        let slice = rootEle.select('.slices')
            .selectAll('path.pie-chart-slice')
            .data(pie(this.responses));

        slice.enter()
            .append('path')
            .attr('fill', (d, i) => colorScheme(i))
            .attr('d', <any>arc)
            .classed('pie-chart-slice', true)
            // Display tooltip on hover
            .on('mouseenter', (d) => {
                tooltip.style('display', 'inline-block');
            })
            .on('mousemove', (d: any) => {
                tooltip.style('left', d3Event.clientX - modalOffset.offsetLeft - (<HTMLElement>tooltip.node()).clientWidth / 2 + 'px');
                tooltip.style('top', d3Event.clientY - modalOffset.offsetTop + scrollOffset.scrollTop - 40 + 'px');
                tooltip.select('.popover-body').html((d.data.option || d.data.response) + '<br>' + d.data.tally + ' (' + Math.round(d.data.tally / totalResponses * 100) + '%)');
            })
            .on('mouseout', (d) => {
                tooltip.style('display', 'none');
            });

        slice.transition()
            .duration(this.TRANSITION_DURATION)
            .attrTween('d', (d: any) => {
                this['_current'] = this['_current'] || d;
                let interpolateFn = d3Interpolate(this['_current'], d);
                this['_current'] = interpolateFn(0);

                return (t) => {
                    return <any>arc(interpolateFn(t));
                };
            });

        slice.exit().remove();

        // Text Labels
        let text = rootEle.select('.response-label')
            .selectAll('text')
            .data(pie(this.responses));

        text.enter()
            .append('text')
            .attr('dy', '.35em')
            .classed('slice-label', true)
            .text((d: any) => {
                return ((d.data.option || d.data.response) + ': ' + Math.round(d.data.tally / totalResponses * 100) + '%');
            })
            .attr('transform', (d: any) => {
                let pos = outerArc.centroid(d);
                // changes the point to be on left or right depending on where label is.
                pos[0] = this.radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);

                return `translate(${pos})`;
            })
            .style('text-anchor', (d) => {
                return midAngle(d) < Math.PI ? 'start' : 'end';
            });

        text.transition()
            .duration(this.TRANSITION_DURATION)
            .attrTween('transform', (d) => {
                this['_current'] = this['_current'] || d;
                let interpolateFn = d3Interpolate(this['_current'], d);
                this['_current'] = interpolateFn(0);

                return (t: number) => {
                    let d2: any = interpolateFn(t);
                    let pos = outerArc.centroid(d2);
                    pos[0] = this.radius * 0.95 * (midAngle(d2) < Math.PI ? 1 : -1);
                    return `translate(${pos})`;
                };
            })
            .styleTween('text-anchor', (d) => {
                this['_current'] = this['_current'] || d;
                let interpolateFn = d3Interpolate(this['_current'], d);
                this['_current'] = interpolateFn(0);

                return (t) => {
                    let d2 = interpolateFn(t);
                    return midAngle(d2) < Math.PI ? 'start' : 'end';
                };
            })
            .text((d: any) => {
                return ((d.data.option || d.data.response) + ': ' + Math.round(d.data.tally / totalResponses * 100) + '%');
            });


        text.exit().remove();


        // Polylines from slice to text
        let polyline = rootEle.select('.lines')
            .selectAll('polyline')
            .data(pie(this.responses));

        polyline.enter()
            .append('polyline')
            .attr('points', (d: any) => {
                let pos = outerArc.centroid(d);
                pos[0] = this.radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);

                return [arc.centroid(d).join(','), outerArc.centroid(d).join(','), pos.join(',')].join(' ');
            });

        polyline.transition()
            .duration(this.TRANSITION_DURATION)
            .attrTween('points', (d) => {
                this['_current'] = this['_current'] || d;
                let interpolateFn = d3Interpolate(this['_current'], d);
                this['_current'] = interpolateFn(0);

                return (t: number) =>{
                    let d2: any = interpolateFn(t);
                    let pos = outerArc.centroid(d2);
                    pos[0] = this.radius * 0.95 * (midAngle(d2) < Math.PI ? 1 : -1);
                    return [arc.centroid(d2).join(','), outerArc.centroid(d2).join(','), pos.join(',')].join(' ');
                };
            });

        polyline.exit().remove();
    }

}
