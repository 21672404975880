// Import Angular dependencies
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { jwtOptionsFactory } from './jwt.config';
import { routerConfigFn } from './router.config';
import { ServerErrorInterceptor } from './_helpers/server-error.interceptor';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AssessmentModule } from './assessment/assessment.module';
import { AssessmentManagementModule } from './assessment-management/assessment-management.module';
import { HeatmapModule } from './heatmap/heatmap.module';
import { ClassRosterModule } from './class-roster/class-roster.module';
import { LandingModule } from './landing/landing.module';
import { LoginModule } from './login/login.module';
import { MapModule } from './map/map.module';
import { ReportModule } from './report/report.module';
import { ResourcerModule } from './resourcer/resourcer.module';
import { SequencerModule } from './sequencer/sequencer.module';
import { ProfileModule } from './profile/profile.module';

import { AppComponent } from './app.component';


@NgModule({
    imports: [
        // Angular modules
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: process.env.NODE_ENV === 'production' }),
        // Third-party modules
        NgbModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory
            }
        }),
        ToastrModule.forRoot({
            maxOpened: 5,
        }),
        LoadingBarHttpClientModule,
        // Router Module and routes defined in feature modules
        UIRouterModule.forRoot({
            states: [],
            useHash: false,
            otherwise: { state: 'landing' },
            config: routerConfigFn,
        }),
        AppRoutingModule,
        // App modules
        CoreModule,
        SharedModule,
        AssessmentModule,
        AssessmentManagementModule,
        HeatmapModule,
        ClassRosterModule,
        LandingModule,
        LoginModule,
        MapModule,
        ReportModule,
        ResourcerModule,
        SequencerModule,
        ProfileModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    ],
    declarations: [
        AppComponent,
    ],
    exports: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
    constructor() {}
}
