import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';


@Injectable()
export class LoginService {

    public activeForm = 'login';
    public openGuestLoginChange = new Subject<boolean>();
    public openFreePracticeLoginChange = new Subject<boolean>();
    public openGuestLogin$: Observable<boolean> = this.openGuestLoginChange.asObservable();
    public openFreePracticeLogin$: Observable<boolean> = this.openFreePracticeLoginChange.asObservable();

    constructor() {}
}
