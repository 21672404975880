import { Component, Input } from '@angular/core';

import { MapService } from '../map.service';


@Component({
    selector: '[standard-pin]',
    templateUrl: './standard-pin.component.svg.html',
    host: {
        class: 'standard-pin',
    },
})
export class StandardPinComponent {

    @Input() construct: any;

    constructor(
        public MapService: MapService,
    ) {}
}
