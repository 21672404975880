import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AssessmentStateService } from '../../common/assessment-state.service';
import { FeedbackService } from '../../../shared/service/feedback.service';

import './test-instructions-modal.component.scss';


@Component({
    selector: 'test-instructions-modal',
    templateUrl: './test-instructions-modal.component.html',
    // styleUrls: ['./test-instructions-modal.component.scss'],
})
export class TestInstructionsModalComponent {

    @Input() modalOptions;

    constructor(
        public activeModal: NgbActiveModal,
        public AssessmentStateService: AssessmentStateService,
        private FeedbackService: FeedbackService,
    ) {}

    public beginTest() {
        if (this.AssessmentStateService.data.feedback && this.AssessmentStateService.data.feedback.length > 0) {
            const options = {
                a_ssp: this.AssessmentStateService.data.ssp,
            };
            this.FeedbackService.submitSurvey(this.AssessmentStateService.data.feedback, options)
                .catch(console.warn);
        }

        this.activeModal.close(this.AssessmentStateService.data.saved_test);
    }
}
