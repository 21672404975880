import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'accessibility-modal',
    templateUrl: './accessibility-modal.component.html'
})
export class AccessibilityModalComponent implements OnInit {

    public readonly fontSizes = ['50%', '75%', '90%', '100%', '110%', '125%', '150%'];
    public fontSizeIdx = 0;

    constructor(public activeModal: NgbActiveModal) {}

    public ngOnInit() {
        const currentSize = window.sessionStorage.getItem('font-size') || '100%';
        this.fontSizeIdx = this.fontSizes.indexOf(currentSize);
    }

    public updateFontSize(next) {
        let nextIdx;
        if (typeof next === 'string') {
            nextIdx = this.fontSizes.indexOf(next);
        } else {
            nextIdx = this.fontSizeIdx + next;
        }
        // Constrain to an existing font size in array
        this.fontSizeIdx = Math.max(0, Math.min(nextIdx, this.fontSizes.length - 1));
        const newSize = this.fontSizes[this.fontSizeIdx];

        (<HTMLElement>document.querySelector(':root')).style.fontSize = newSize;
        window.sessionStorage.setItem('font-size', newSize);
    }
}
