<!-- School Log In Form -->
<form [formGroup]="loginForm" class="login-form flex-column flex-auto space-children-column" (ngSubmit)="submitLoginInfo($event)">
    <div class="login-message text-center">{{ loginMessage }}</div>
    <div *ngIf="loginType == 'free-practice'">New Users: go to Register to create a MM password</div>

    <div class="form-error-messages text-center" *ngIf="loginFailMessage">
        <div class="error-message body-regular">{{ loginFailMessage }}</div>
    </div>

    <mat-form-field appearance="standard" color="accent">
        <mat-label *ngIf="loginType != 'free-practice'">ID# or Email</mat-label>
        <mat-label *ngIf="loginType == 'free-practice'">Email</mat-label>
        <input matInput type="text" id="`login-email-${loginType}`" autocomplete="username" formControlName="username" (input)="resetFormErrors()" maxlength="150" required>
        <mat-error *ngIf="username.errors?.required">
            ID or Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="username.errors?.maxlength">
            ID cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>Password</mat-label>
        <input matInput type="password" id="`login-password-${loginType}`" autocomplete="current-password" formControlName="password" (input)="resetFormErrors()" name="loginPassword" required>
        <a matSuffix class="text-accent forgot-password text-right" (click)="showForm($event, 'passwordReset')">forgot?<span *ngIf="loginType === 'school'"> (Teachers Only)</span></a>
        <mat-error *ngIf="password.errors?.required">
            Password is <strong>required</strong>
        </mat-error>
    </mat-form-field>

    <ng-select
        class="flex custom login-select-field"
        *ngIf="loginType === 'school'"
        formControlName="school"
        [items]="schools"
        appendTo="body"
        bindLabel="name"
        bindValue="id"
        groupBy="district"
        [clearable]="false"
        [loading]="loadingSchools"
        [searchable]="false"
        name="school"
        placeholder="Select your school">
    </ng-select>

    <div class="g-recaptcha" align="center" *ngIf="captcha">
        <ngx-recaptcha2 #captchaElement
                        [siteKey]="captchaSiteKeyV2"
                        formControlName="recaptcha">
        </ngx-recaptcha2>
    </div>

    <button type="submit" class="btn btn-block primary-button alt-theme" [ngClass]="{ 'balloon': loginType === 'school' }" [disabled]="!loginForm.valid">Log In</button>

    <!-- <div *ngIf="loginType === 'guest'; then register else redirectLanding"></div>
    <ng-template #register> -->
        <button *ngIf="loginType !== 'school'" type="button" class="btn btn-block primary-button alt-theme-red" (click)="showRegistration($event)">Register</button>
    <!-- </ng-template>
    <ng-template #redirectLanding>
        <button type="button" class="btn btn-block primary-button alt-theme-red" uiSref="landing">Cancel</button>
    </ng-template> -->
</form>
