import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '../../../core/service/utils.service';


@Component({
    selector: 'student-test-status-modal',
    templateUrl: './student-test-status-modal.component.html'
})
export class StudentTestStatusModalComponent {

    @Input() students: any;
    @Input() test: any;
    public sortExpression: string;
    public sortReverse = false;

    constructor(
        public activeModal: NgbActiveModal,
        public UtilsService: UtilsService,
        private DatePipe: DatePipe,
    ) {}

    public sortBy(prop) {
        this.sortReverse = this.sortExpression === prop ? !this.sortReverse : false;
        this.sortExpression = prop;
        this.students = this.UtilsService.sortByProp(this.students, prop, this.sortReverse);
    }

    public getSubmittedMsg(test, student) {
        if (!test) {
            student.submittedMsg = '<i>Not Started</i>';
        } else if (test.status === 1) {
            student.submittedMsg = '<i>In Progress</i>';
        } else if (test.status === 2) {
            student.submittedMsg = '<i>Saved for later</i>';
        } else if (test.status === 3) {
            student.submittedMsg = this.DatePipe.transform(test.test_date, dateFormat.shortDate, dateFormat.timezone);
        } else {
            student.submittedMsg = this.UtilsService.testStatusToText(test.status);
        }

        return student.submittedMsg;
    }
}
