<div class="modal-header">
    <h4 class="modal-title limit-text">Student Try Gr. {{ UtilsService.gradeBinToText(tryData.gradeband) }} Status Report</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body">
    <div class="table-component">
        <!-- Table Header -->
        <div class="flex-row justify-center">
            <div class="table-header cursor-pointer flex-40 align-items-center justify-space-between space-children-row" (click)="sortBy('name')">
                <span>Name</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'name' && sortReverse, 'opacity-25': sortExpression !== 'name' }"></i>
            </div>
            <div class="table-header cursor-pointer flex-60 align-items-center justify-space-between space-children-row" (click)="sortBy('submittedMsg')">
                <span>Date Submitted</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'submittedMsg' && sortReverse, 'opacity-25': sortExpression !== 'submittedMsg' }"></i>
            </div>
        </div>
        <!-- Table Rows -->
        <div class="table-body">
            <div class="flex-row justify-center" *ngFor="let student of students; even as isEven" [class.offwhite-pattern]="isEven">
                <div class="table-data flex-column flex-40 justify-center bold">{{ student.name }}</div>
                <div class="table-data flex-column flex-60 justify-center">
                    <span [innerHTML]="student.submittedMsg"></span>
                </div>
            </div>
        </div>
    </div>
</div>
