import { Component, OnInit } from '@angular/core';

import { apiServer } from '@/app.constant';
import { UtilsService } from '../../core/service/utils.service';
import { SessionService } from '../../core/service/session.service';
import { AssessmentService } from '../assessment.service';
import { AssessmentStateService } from '../common/assessment-state.service';


@Component({
    selector: 'assigned-testforms',
    templateUrl: './assigned-testforms.component.html',
})
export class AssignedTestformsComponent implements OnInit {

    public isListCollapsed = true;
    public readonly apiServer = apiServer;
    public userAssessments = [];

    constructor(
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
        private UtilsService: UtilsService,
        public SessionService: SessionService,
    ) {}

    public ngOnInit() {
        if (this.SessionService.userAccess && this.SessionService.userAccess.permission >= 128) {
            this.AssessmentStateService.getStudentAssessments({ option: 'assigned' })
                .then(userAssessments => this.userAssessments = userAssessments)
                .catch(console.warn);
        }
    }

    public getUserAssignedAssessment(assessmentId, testformId) {
        this.UtilsService.addLoadingOverlay();
        this.AssessmentStateService.getAssessmentTestform('real', assessmentId, testformId)
            .then(testInfo => this.AssessmentService.openTestInstructionsModal(testInfo))
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
