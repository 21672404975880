import { Component, Input, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges, ElementRef } from '@angular/core';


@Component({
    selector: 'table-scroll-buttons',
    templateUrl: './table-scroll-buttons.component.html',
    host: {
        class: 'table-scroll',
    },
})
export class TableScrollButtonsComponent implements AfterViewInit, OnChanges {

    @Input() autoScroll: boolean = false;
    @Input() scrollableData: any;
    public scrollContainer: HTMLElement;
    public isLeftDisabled: boolean;
    public isRightDisabled: boolean;
    private animationInProgress = false;
    private SCROLL_OFFSET = 84;
    private startTimestamp: number = null;
    private totalX: number = null;
    private progressX: number = 0;
    private stepVelocity: number = null;
    private MINIMUM_VELOCITY = 8;
    private newScrollLeft: number = null;

    constructor(
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngAfterViewInit() {
        this.scrollContainer = this.element.nativeElement.closest('.whiteframe').querySelector('.tests-container');

        if (this.autoScroll) {
            this.autoScrollToEnd();
        }
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.scrollableData && !change.scrollableData.firstChange && this.autoScroll) {
            this.autoScrollToEnd();
        }
    }

    private autoScrollToEnd() {
        this._scroll(this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth);
        // this.cdr.detectChanges();
    }

    public scrollLeft() {
        let newScrollLeft = this.scrollContainer.scrollLeft - this.scrollContainer.clientWidth + this.SCROLL_OFFSET;
        this._scroll(newScrollLeft);
    }

    public scrollRight() {
        // Scroll panel width minus the size off one column (to be able to access that column if partially showing)
        let newScrollLeft = this.scrollContainer.scrollLeft + this.scrollContainer.clientWidth - this.SCROLL_OFFSET;
        this._scroll(newScrollLeft);
    }

    private _scroll(newScrollLeft) {
        if (this.animationInProgress) { return; }

        this.animationInProgress = true;
        this.startTimestamp = null;
        this.newScrollLeft = Math.max(0, Math.min(newScrollLeft, this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth));
        this.totalX = this.newScrollLeft - this.scrollContainer.scrollLeft;
        this.progressX = 0;
        // Animate in 30 frames (~500ms) but move at a minimum of 8px per frame
        this.stepVelocity = Math.round((this.newScrollLeft - this.scrollContainer.scrollLeft) / 30);
        this.stepVelocity = this.newScrollLeft > this.scrollContainer.scrollLeft ? Math.max(this.stepVelocity, this.MINIMUM_VELOCITY) : Math.min(this.stepVelocity, -this.MINIMUM_VELOCITY);

        window.requestAnimationFrame(this._scrollStep.bind(this));
    }

    private _scrollStep(timestamp) {
        this.startTimestamp = this.startTimestamp || timestamp;
        const duration = timestamp - this.startTimestamp;
        const stepX = this.scrollContainer.scrollLeft + this.stepVelocity;
        const percentProgress = this.progressX / this.totalX;

        this.progressX += this.stepVelocity;
        // Update the horizontal scroll position with left and right bounds
        this.scrollContainer.scrollLeft = Math.max(0, Math.min(stepX, this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth));

        // Scroll when within scrolling bounds and time out at 500ms
        if (
            ((this.stepVelocity > 0 && this.scrollContainer.scrollLeft < this.newScrollLeft) ||
            (this.stepVelocity < 0 && this.scrollContainer.scrollLeft > this.newScrollLeft)) &&
            duration < 500
        ) {
            // Speed up the beginning of the animation
            if (percentProgress < 0.5) {
                this.stepVelocity = Math.round(this.stepVelocity * 1.1);
            // Slow down to a minimum of 8px per frame
            } else if (percentProgress >= 0.5 && Math.abs(this.stepVelocity) > this.MINIMUM_VELOCITY) {
                this.stepVelocity = Math.round(this.stepVelocity * 0.909);
            }
            window.requestAnimationFrame(this._scrollStep.bind(this));
        } else {
            this.animationInProgress = false;
        }

        // Update disabled state on buttons when scrolling has ended
        this.isLeftDisabled = this.scrollContainer.scrollLeft <= 0;
        this.isRightDisabled = this.scrollContainer.scrollLeft >= this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;
    }
}
