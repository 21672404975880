import { Injectable } from '@angular/core';

import { AnalyticsService } from '../../../core/service/analytics.service';


@Injectable()
export class CalculatorService {

    private readonly MAX_DIGITS = 15;
    private bc = {
        c1: 'calculator-key-numeric',
        c2: 'calculator-key-operator',
        c3: 'calculator-key-CE',
        s1: 'calculator-key-normal',
        s2: 'calculator-key-wide',
        s3: 'calculator-key-tall',
    };
    public calculator = {
        isOpen: false,
        cleared: true,
        stored: null,
        operation: null,
        displayVal: '0',
        val: [],
        topKeys: [
            {l: 'CE', v: 'c', s: this.bc.s1, c: this.bc.c3},
            {l: '+/-', v: 's', s: this.bc.s1, c: this.bc.c2},
            {l: '&radic;', v: 'q', s: this.bc.s1, c: this.bc.c2},
            {l: '&divide;', v: '/', s: this.bc.s1, c: this.bc.c2},
        ],
        leftKeys: [
            {l: '7', v: 7, s: this.bc.s1, c: this.bc.c1},
            {l: '8', v: 8, s: this.bc.s1, c: this.bc.c1},
            {l: '9', v: 9, s: this.bc.s1, c: this.bc.c1},
            {l: '4', v: 4, s: this.bc.s1, c: this.bc.c1},
            {l: '5', v: 5, s: this.bc.s1, c: this.bc.c1},
            {l: '6', v: 6, s: this.bc.s1, c: this.bc.c1},
            {l: '1', v: 1, s: this.bc.s1, c: this.bc.c1},
            {l: '2', v: 2, s: this.bc.s1, c: this.bc.c1},
            {l: '3', v: 3, s: this.bc.s1, c: this.bc.c1},
            {l: '0', v: 0, s: this.bc.s2, c: this.bc.c1},
            {l: '.', v: '.', s: this.bc.s1, c: this.bc.c1}
        ],
        rightKeys: [
            {l: '&times;', v: '*', s: this.bc.s1, c: this.bc.c2},
            {l: '&minus;', v: '-', s: this.bc.s1, c: this.bc.c2},
            {l: '+', v: '+', s: this.bc.s1, c: this.bc.c2},
            {l: '=', v: '=', s: this.bc.s1, c: this.bc.c2},
        ]
    };

    constructor(
        private AnalyticsService: AnalyticsService
    ) {}


    // Opens or closes and resets the calculator
    public operate(action, clear) {
        this.calculator.isOpen = action === 'open';
        if (clear) {
            this.clearCalculator();
        }
    }

    public pressButton(pressedButton) {
        if (typeof pressedButton.v === 'number') {
            if (this.calculator.val.length < this.MAX_DIGITS) {
                this.calculator.cleared = false;
                if (this.calculator.val.length === 1 && this.calculator.val[0] === '0') {
                    this.calculator.val.splice(0, 1, String(pressedButton.v));
                } else {
                    this.calculator.val.push(String(pressedButton.v));
                }
            }
        } else {
            switch (pressedButton.v) {
                // clear
                case 'c':
                    this.clearCalculator();
                    break;
                // decimal
                case '.':
                    if (this.calculator.val.indexOf('.') <= -1) {
                        if (this.calculator.val.length === 0 || (this.calculator.val.length === 1 && this.calculator.val[0] === '-')) {
                            this.calculator.val.push('0');
                        }
                        this.calculator.val.push('.');
                    }
                    break;
                // +/-
                case 's':
                    if (this.calculator.val[0] === '-') {
                        this.calculator.val.splice(0, 1);
                    } else {
                        this.calculator.val.unshift('-');
                    }
                    break;
                case '+':
                case '-':
                case '*':
                case '/':
                    const currentOperation = this.calculator.operation;
                    if (this.calculator.cleared) {
                        this.calculator.operation = null;
                        // We are "undoing" the selection by clicking the same operation when there's no second operand
                        if (currentOperation && currentOperation.v === pressedButton.v) {
                            this.calculator.val = String(this.calculator.stored).split('');
                            this.calculator.stored = null;
                            break;
                        }
                    }
                    if (this.calculator.stored === null) {
                        // Store the current number to collect the second operand
                        this.calculator.stored = this.getVal(this.calculator.val, true);
                        this.calculator.cleared = true;
                    } else {
                        this.calculate();
                    }
                    this.calculator.operation = pressedButton;
                    this.calculator.val = [];
                    break;
                // square root
                case 'q':
                    this.calculator.operation = pressedButton;
                    this.calculate();
                    this.calculator.operation = null;
                    this.calculator.stored = null;
                    break;
                // =
                case '=':
                    if (this.calculator.operation !== null) {
                        this.calculate();
                        this.calculator.operation = null;
                        this.calculator.stored = null;
                    }
                    break;
            }
        }

        this.calculator.displayVal = this.getVal(this.calculator.val, false);
    }

    private clearCalculator() {
        this.calculator.cleared = true;
        this.calculator.stored = null;
        this.calculator.operation = null;
        this.calculator.displayVal = '0';
        this.calculator.val = [];
    }

    private calculate() {
        const storedOperand = this.calculator.stored;
        let currentOperand = this.getVal(this.calculator.val, true);

        // Only calculate if we have an operation and current operand to process
        if (this.calculator.operation && (currentOperand || currentOperand === 0)) {
            switch (this.calculator.operation.v) {
                case '+':
                    this.calculator.stored += currentOperand;
                    break;
                case '-':
                    this.calculator.stored -= currentOperand;
                    break;
                case '*':
                    this.calculator.stored *= currentOperand;
                    break;
                case '/':
                    this.calculator.stored /= currentOperand;
                    break;
                case 'q':
                    this.calculator.stored = Math.sqrt(currentOperand);
                    break;
            }

            // Calculate the number of fractional digits. If the '.' is after the max num of digits
            // round to the nearest integer. If before, round to the max decimal point allowed.
            // If there's no '.'' then just leave the number as is
            const result = String(this.calculator.stored);
            const numDigits = Math.min(result.length, this.MAX_DIGITS);
            const decimalPos = result.indexOf('.');
            const fractionDigits = Math.max(0, decimalPos === -1 ? 0 : (numDigits - 1 - decimalPos));
            // To fixed sets the amount of fractional digits and rounds the decimals if necessary.
            // Number removes redundancies like '10.000', then back to string, then to array by the digit
            this.calculator.val = String(Number(this.calculator.stored.toFixed(fractionDigits))).split('');
            // Set val array to new result and slice max number of digits
            if (this.calculator.val.length > this.MAX_DIGITS) {
                this.calculator.val.splice(this.MAX_DIGITS, this.calculator.val.length - this.MAX_DIGITS);
            }

            // Mark current operand as cleared
            this.calculator.cleared = true;

            // Record operation in analytics service
            this.AnalyticsService.action({
                action: 'calculator_calculate',
                values: {
                    left: storedOperand,
                    operation: this.calculator.operation.v,
                    right: currentOperand,
                    result: this.calculator.stored
                }
            });
        }
    }

    private getVal(arr, toNum = false) {
        let val = arr.join('');

        if (toNum) {
            return Number(val);
        }

        return val || '0';
    }
}
