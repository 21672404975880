import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '@/core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { ModalService } from '@/shared/service/modal.service';
import { AssessmentManagementService } from '../assessment-management.service';

// import './student-tries-activity.component.scss';


@Component({
    selector: 'student-tries-activity',
    templateUrl: './student-tries-activity.component.html',
    // styleUrls: ['./student-tries-activity.component.scss'],
})
export class StudentTriesActivityComponent implements OnInit, OnChanges {

    @Input() public cluster: any;
    public triesRecords: any;
    public readonly dateFormat = dateFormat;

    constructor(
        public UtilsService: UtilsService,
        private ModalService: ModalService,
        public AssessmentManagementService: AssessmentManagementService,
    ) {}

    public ngOnInit() {
        // Wrap in timeout because section-assessment.component is also async (@TODO: decouple)
        setTimeout(() => {
            this.fetchTests();
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.cluster && change.cluster.currentValue && !change.cluster.firstChange) {
            this.fetchTests();
        }
    }

    private fetchTests() {
        if (this.AssessmentManagementService.models.section) {
            let params = {
                section_id: this.AssessmentManagementService.models.section.id,
                type: 'tries',
            };

            if (this.cluster) {
                params['cluster_id'] = this.cluster.id;
            }

            // Get tests for the section, group under clusters
            this.UtilsService.addLoadingOverlay();
            this.AssessmentManagementService.getTestsForSection(params)
                .then((result: any) => {
                    this.triesRecords = result;
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    public getStudentTestStatus(tryData, cluster) {
        this.UtilsService.addLoadingOverlay();

        this.AssessmentManagementService.getStudentTriesActivity({
            section_id: this.AssessmentManagementService.models.section.id,
            cluster_id: cluster.id,
            gradeband: tryData.gradeband,
        })
            .then((students) => {
                this.ModalService.openStudentTriesActivityModal(students, tryData, cluster);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
