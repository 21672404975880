import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'data-privacy-modal',
    templateUrl: './data-privacy-modal.component.html'
})
export class DataPrivacyModalComponent {

    constructor(public activeModal: NgbActiveModal) {}
}
