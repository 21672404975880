import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentManagementComponent } from './assessment-management/assessment-management.component';
import { SectionAssessmentComponent } from './assessment-management/section-assessment/section-assessment.component';
import { ReportPageComponent } from './report/report.component';
import { ReportClusterComponent } from './report/cluster/report-cluster.component';
import { ReportTestComponent } from './report/test/report-test.component';
import { SchoolReportComponent } from './report/school/school-report.component';
import { HeatmapPageComponent } from './heatmap/heatmap.component';
import { ClassRosterComponent } from './class-roster/class-roster.component';
import { ResourcerComponent } from './resourcer/resourcer.component';
import { CourseManagementComponent } from './sequencer/course-management/course-management.component';
import { SequencerAbstractComponent } from './sequencer/sequencer-abstract.component';
import { NewCourseComponent } from './sequencer/new-course/new-course.component';
import { SSPPageComponent } from './sequencer/ssp/ssp-page.component';
import { ProfileComponent } from './profile/profile.component';

import { Transition } from '@uirouter/core';
import { UtilsService } from './core/service/utils.service';
import { AssessmentManagementService } from './assessment-management/assessment-management.service';
import { ReportService } from './report/report.service';


export function schoolYearsResolveFn(AssessmentManagementService, UtilsService) {
    UtilsService.addLoadingOverlay();

    return AssessmentManagementService.getTeacherSchoolYear()
        .catch(console.warn)
        .finally(() => UtilsService.removeLoadingOverlay());
};

export const APP_STATES = [
    {
        name: 'landing',
        url: '/',
        component: LandingComponent,
        data: {
            pageTitle: 'Home',
        },
    },
    {
        name: 'login',
        url: '/login?{key}',
        component: LoginComponent,
        data: {
            redirect: true,
            pageTitle: 'Login',
        },
    },
    // {
    //     name: 'practice_login',
    //     url: '/practice_login',
    //     component: LoginPracticeComponent,
    //     data: {
    //         redirect: true,
    //         pageTitle: 'Login',
    //     },
    // },
    // {
    //     name: 'register',
    //     url: '/register',
    //     component: LoginComponent,
    //     data: {
    //         redirect: true,
    //         pageTitle: 'Register',
    //     },
    // },
    {
        name: 'practice_register',
        url: '/practice_register',
        component: LoginComponent,
        data: {
            redirect: true,
            pageTitle: 'Register',
        },
    },
    {
        name: 'changePassword',
        url: '/password/set?key&email',
        component: LoginComponent,
        data: {
            redirect: true,
            pageTitle: 'Reset Password',
        },
    },
    {
        name: 'activateEmail',
        url: '/email/activate?{key}&{email}',
        component: LoginComponent,
        data: {
            pageTitle: 'Activate Email',
        },
    },
    {
        name: 'map',
        url: '/map?{coords}&{link}',
        component: MapComponent,
        data: {
            requiresAuth: true,
            pageTitle: 'Learning Map'
        }
    },
    {
        name: 'assessment',
        url: '/assessment?{t:[0-9]{1,2}}',
        component: AssessmentComponent,
        params: {
            t: {
                value: '0',
                dynamic: true,
            },
            clusterId: 0
        },
        data: {
            requiresAuth: true,
            pageTitle: 'Assessments',
        },
    },
    {
        name: 'assessmentManage',
        url: '/assessment/management',
        component: AssessmentManagementComponent,
        resolve: [
            {
                token: 'schoolYears',
                deps: [AssessmentManagementService, UtilsService],
                resolveFn: schoolYearsResolveFn
            },
        ],
        data: {
            requiresAuth: true,
            pageTitle: 'Manage Assessments',
        },
    },
    {
        name: 'assessmentManage.section',
        url: '/{schoolYearId:[0-9]{1,6}}/{sectionId:[0-9]{1,9}}?{t:[0-9]{1,2}}',
        component: SectionAssessmentComponent,
        params: {
            t: {
                value: '0',
                dynamic: true,
            }
        },
        bindings: {
            schoolYears: 'schoolYears',
        },
        data: {
            pageTitle: 'Section Assessments',
        },
    },
    {
        name: 'report',
        url: '/report?{u}',
        component: ReportPageComponent,
        params: {
            clusterId: 0
        },
        data: {
            requiresAuth: true,
            pageTitle: 'Student Report'
        }
    },
    {
        name: 'report.test',
        url: '/test/{trUid:.{36}}', // tr_uid and optional user_id
        component: ReportTestComponent,
        data: {
            pageTitle: 'Assessment Results'
        }
    },
    {
        name: 'report.school',
        url: '/school',
        component: SchoolReportComponent,
        data: {
            pageTitle: 'School Report'
        }
    },
    {
        name: 'heatmap',
        url: '/heatmap/{asspId:int}-{retestId:int}-{sectionId:int}-{clusterId:int}-{gradeband:int}',
        component: HeatmapPageComponent,
        params: {
            asspId: 0, // assp_id
            retestId: 0, // retake_id
            sectionId: 0, // section_id
            clusterId: 0, // cluster_id
            gradeband: 0, // gradeband
        },
        data: {
            requiresAuth: true,
            pageTitle: 'Heatmap Class Report'
        }
    },
    {
        name: 'section_roster',
        url: '/class/roster',
        component: ClassRosterComponent,
        data: {
            requiresAuth: true,
            pageTitle: 'Class Roster',
        },
    },
    {
        name: 'resourcer',
        url: '/resourcer',
        component: ResourcerComponent,
        data: {
            pageTitle: 'Resourcer',
            requiresAuth: true,
        },
    },
    {
        name: 'sequencer',
        url: '/sequencer',
        abstract: true,
        component: SequencerAbstractComponent,
        data: {
            pageTitle: 'Sequencer',
            requiresAuth: true,
        },
    },
    {
        name: 'sequencer.courses',
        url: '/courses',
        component: CourseManagementComponent,
        data: {
            pageTitle: 'Sequencer Courses'
        }
    },
    {
        name: 'sequencer.newCourse',
        url: '/course/new',
        component: NewCourseComponent,
        data: {
            pageTitle: 'Create New Course'
        }
    },
    {
        name: 'sequencer.demo',
        url: '/demo',
        component: SSPPageComponent,
        params: {
            id: 0,
        },
        data: {
            resourceType: 'demo',
            pageTitle: 'Demo Sequencer'
        },
    },
    {
        name: 'sequencer.course',
        url: '/course/{id:[0-9]{1,6}}',
        component: SSPPageComponent,
        data: {
            resourceType: 'course',
            pageTitle: 'Course Sequencer'
        }
    },
    {
        name: 'sequencer.section',
        url: '/section/{id:[0-9]{1,9}}',
        component: SSPPageComponent,
        data: {
            resourceType: 'section',
            pageTitle: 'Section Sequencer'
        }
    },
    {
        name: 'account',
        url: '/account/profile',
        component: ProfileComponent,
        data: {
            pageTitle: 'My Profile',
            requiresAuth: true,
        }
    },
];
