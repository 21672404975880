import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { apiServer } from '@/app.constant';
import { dateToString } from '@/_helpers/transforms';
import { HttpService } from '@/core/service/http.service';
import { AnalyticsService } from '@/core/service/analytics.service';
import { UtilsService } from '@/core/service/utils.service';


@Injectable()
export class AssessmentStateService {

    private assessmentFinishedChange = new Subject<any>();
    public assessmentFinished$: Observable<any> = this.assessmentFinishedChange.asObservable();

    public correctQuestionChange = new Subject<boolean>();
    public correctQuestion$: Observable<boolean> = this.correctQuestionChange.asObservable();

    public newQuestionChange = new Subject<boolean>();
    public newQuestion$: Observable<boolean> = this.newQuestionChange.asObservable();

    public stackLadderInitChange = new Subject<{
        totalLevels: number,
        startingLevel: number,
        ladderBlock: HTMLElement,
    }>();
    public stackLadderInit$: Observable<any> = this.stackLadderInitChange.asObservable();

    public data = {
        // Assessment type: real|preview
        type: null,
        answerKey: false,
        // Variables from assessment instructions
        assessment: 0,
        instructions: null,
        name: null,
        saved_test: 0, // Backend will send a tr_id if a test was saved
        ssp: 0,
        retestId: 0,
        tasks: 0,
        testform: 0,
        testlet: 0,
        tests: 0,
        // Specific to cluster test
        tid: 0,
        tr: 0,
        trUid: null,
        questionStatuses: null,
        questionCompleted: {
            sk: 0,
            bm: 0,
        },
        currentQuestion: 0,
        maxQuestion: 0,
        submitType: 0,
        submitText: null,
        surveyCompleted: false,
        // Variables used for individual questions
        question: null,
        questionStartTime: 0,
        multi: {
            current: 1,
            total: 1,
            isPartAnswered: false
        },
        feedback: null,
        // Variables used in report page
        isPracticeTest: false,
        isReportPage: false,
        // Variables assigned via heatmap page
        assp_id: 0,
        attempt: 0,
        // Variables used in practice tests
        isFinished: false,
        construct: null,
        zone: null,
        progress: []
    };
    private originalAssessment = JSON.parse(JSON.stringify(this.data));

    public stackLadder = {
        construct: null,
        stack: null
    };
    private originalStackLadder = JSON.parse(JSON.stringify(this.stackLadder));

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
    ) {}

    public broadcastAssessmentFinished(result) {
        this.assessmentFinishedChange.next(result);
    }

    public getStudentAssessments(params: any = {}): any {
        let url = `${apiServer.urlPrefix}/assessment/find/`;

        return this.HttpService.get(url, params)
            .then((response) => {
                if (params.option === 'assigned') {
                    return Promise.resolve(response.result);
                }

                let sortedTests = this.UtilsService.sortTestsByDate(response.result);

                this.AnalyticsService.assessment({
                    action: 'find_student_assessments',
                });

                return Promise.resolve(sortedTests);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'find_student_assessments_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getAssessmentInstructions(assessmentId, testformId): any {
        let url = `${apiServer.urlPrefix}/assessment/info/${assessmentId}/${testformId}/`;

        return this.HttpService.get(url)
            .then((response) => {
                // Store assessment, testform, testlet, and assp ids
                Object.assign(this.data, response.result);

                this.data.type = 'real';
                this.AnalyticsService.assessment({
                    action: 'get_assessment_instructions',
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_assessment_instructions_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    // NOTE: Using this method to start a test does not generate an assessment scope sequence pace id
    public getAssessmentTestform(assessmentType, assessmentId, testformId, retest=null): any {
        let url = `${apiServer.urlPrefix}/assessment/count/${assessmentId}-${testformId}/`;

        return this.HttpService.get(url)
            .then((response) => {
                // Store assessment, testform, testlet, and assp ids
                Object.assign(this.data, response.result);
                // Store retest_id if applicable
                if (retest) {
                    this.data.retestId = retest.retest_id;
                }
                // Store 'real' or 'preview' assessment type
                this.data.type = assessmentType;
                this.AnalyticsService.assessment({
                    action: 'get_assessment_testform',
                    type: assessmentType
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_assessment_testform_fail',
                    type: assessmentType,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getSectionAssessmentInfo(asspId: number, retestId: number): any {
        let url = `${apiServer.urlPrefix}/assessment/count/${asspId}/${retestId}/`;

        return this.HttpService.get(url)
            .then((response) => {
                // Store assessment, testform, testlet, and assp ids
                Object.assign(this.data, response.result);

                this.data.type = 'real';
                this.data.retestId = retestId;
                this.AnalyticsService.assessment({
                    action: 'get_section_assessment_info',
                });

                return Promise.resolve(response.result);
            }).catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_section_assessment_info_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getStudentTryInfo(clusterId: number, gradeband: number): any {
        const url = `${apiServer.urlPrefix}/assessment/tries/count/${clusterId}/${gradeband}/`;

        return this.HttpService.get(url)
            .then(response => {
                // Store assessment, testform, testlet, and assp ids
                Object.assign(this.data, response.result);

                this.data.type = 'real';
                this.AnalyticsService.assessment({
                    action: 'get_student_try_info',
                });

                return Promise.resolve(response.result);
            }).catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_student_try_info_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public getPracticeAssessmentInfo(construct): any {
        let url = `${apiServer.urlPrefix}/assessment/practice/info/`;
        let serverPostData = {
            construct: construct.id
        };
        this.data.construct = construct;

        return this.HttpService.post(url, serverPostData)
            .then((response) => {
                Object.assign(this.data, response.result);

                this.data.type = 'practice';
                this.data.construct.totalItems = 0;

                this.data.construct.stacks.forEach(stack => {
                    // Make a copy of how many questions there were originally to later draw the scorecard
                    stack.totalItems = stack.available_items;
                    this.data.construct.totalItems += stack.available_items;
                    stack.itemsTaken = [];
                });

                this.AnalyticsService.assessment({
                    action: 'get_practice_assessment_info',
                    url,
                });

                return Promise.resolve(response.result);
            }).catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_practice_assessment_info_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public startRealTest(trId) {
        const assessmentId = this.data.assessment;
        const testformId = this.data.testform;
        const asspId = this.data.ssp;
        const retestId = this.data.retestId;
        const url = `${apiServer.urlPrefix}/assessment/${assessmentId}/${testformId}/${asspId}/${retestId}/${trId}/`;

        return this.HttpService.get(url)
            .then((response) => {
                this.AnalyticsService.assessment({
                    action: 'start_assessment',
                    url,
                    type: this.data.type,
                });
                this.setupAssessmentVariables(response.result);

                return Promise.resolve(response.result);
            }).catch((error) => {
                this.AnalyticsService.warning({
                    action: 'start_assessment_fail',
                    url,
                    type: this.data.type,
                    error
                });

                return Promise.reject(error);
            });
    }

    public setupAssessmentVariables(assessmentDetails) {
        this.data.testlet = assessmentDetails.assessment.info.tid;
        this.data.tr = assessmentDetails.trid;
        this.data.trUid = assessmentDetails.tr_uid;

        this.data.currentQuestion = 0;
        this.data.maxQuestion = assessmentDetails.questions - 1;
    }

    public startPracticeTest(constructId) {
        let url = `${apiServer.urlPrefix}/assessment/practice/initialize/`;
        let serverPostData = {
            construct: constructId
        };

        return this.HttpService.post(url, serverPostData)
            .then((response) => {
                this.data.tr = response.result.tr;
                this.AnalyticsService.assessment({
                    action: 'start_practice_test',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'start_practice_test_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public setAssessmentVariables(question) {
        this.data.questionStartTime = Math.floor(Date.now() / 1000);
        this.data.question = question;
        this.data.multi = {
            total: question.stems.length,
            current: 1,
            isPartAnswered: false
        };
    }

    public getRealTestQuestion(assessmentId, trId, seq): any {
        let url = `${apiServer.urlPrefix}/assessment/get/${assessmentId}/${trId}/${seq}/`;

        return this.HttpService.get(url)
            .then((response) => {
                this.setAssessmentVariables(response.result.question);
                this.AnalyticsService.assessment({
                    action: 'show_next_question',
                    'sequence': seq,
                    'question_id': response.result.question.id
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'show_next_question_fail',
                    url,
                    'seq': seq,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getPracticeQuestion(stackId, trId, seq): any {
        let url = `${apiServer.urlPrefix}/assessment/practice/get/`;
        let params = {
            stack: stackId,
            tr: trId,
            seq: seq
        };

        return this.HttpService.post(url, params)
            .then((response) => {
                this.setAssessmentVariables(response.result.question);
                this.data.question.testType = 'practice';
                this.AnalyticsService.assessment({
                    action: 'get_practice_question',
                    url,
                });

                return Promise.resolve(this.data.question);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'get_practice_question_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public submitTestQuestion(formData, action): any {
        let url = `${apiServer.urlPrefix}/assessment/set/`;
        action = action || 'submit_assessment_question';

        return this.HttpService.post(url, formData)
            .then((response) => {
                this.AnalyticsService.assessment({
                    action: action,
                    url,
                });

                return Promise.resolve(response);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: action + '_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public bookmarkQuestion(trId, seq, bookmark, qInfo): any {
        let url = `${apiServer.urlPrefix}/assessment/bookmark_question/`;
        let formData = {
            tr_id: trId,
            q_seq: seq,
            bookmark: bookmark,
            q_info: JSON.stringify(qInfo),
        };

        return this.HttpService.post(url, formData)
            .then((response) => {
                this.AnalyticsService.assessment({
                    action: 'question_bookmark',
                    'index': seq
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'question_bookmark_fail',
                    'index': seq
                });

                return Promise.reject(error);
            });
    }


    public revealAnswer(trid, sid): any {
        let url = `${apiServer.urlPrefix}/assessment/key/`;
        let formData = {
            trid: trid,
            sid: sid
        };

        return this.HttpService.post(url, formData)
            .then((response) => {
                this.AnalyticsService.assessment({
                    action: 'reveal_answer',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: 'reveal_answer_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public collectStudentResponse() {
        let stem = this.data.question.stems[this.data.multi.current - 1];
        let studentAnswer = [];

        if (stem.type === 1) {
            if (stem.selected) {
                studentAnswer.push(stem.selected);
            }
        } else if (stem.type === 2) {
            studentAnswer = stem.foils
                .filter(foil => foil.selected)
                .map(foil => foil.id);
        } else {
            studentAnswer = stem.foils
                .filter(foil => !!foil.selected || foil.selected === 0)
                .map(foil => {
                    return { id: foil.id, value: String(foil.selected).replace('+', '') };
                });
        }

        return {
            trid: this.data.tr,
            qid: this.data.question.id,
            sid: stem.id,
            type: stem.type,
            start: this.data.questionStartTime,
            time: Math.floor(Date.now() / 1000) - this.data.questionStartTime,
            next: true,
            foil: JSON.stringify(studentAnswer)
        };
    }

    public storeQuestion() {
        // No credit will be given for partial score in practice tests
        if (this.data.question.score === 'partial') {
            this.data.question.score = 'incorrect';
        }
        let questionCopy = JSON.parse(JSON.stringify(this.data.question));
        this.data.progress.push(questionCopy);
    }

    public finalizeAssessment(action, aId, tId, trId, submit, qStatus = null): any {
        let url = `${apiServer.urlPrefix}/assessment/finalize/`;
        let serverPostData = {
            aid: aId,
            tid: tId,
            trid: trId,
            // 0 save, 1 complete, 2 finish preview
            submit: submit,
            // json status of each question (sk, bm, vt, etc)
            info: qStatus
        };

        return this.HttpService.post(url, serverPostData)
            .then((response) => {
                this.data.feedback = response.result.feedback;
                this.data.trUid = response.result.uid;
                this.AnalyticsService.assessment({
                    action: action,
                    url,
                });
                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({
                    action: action + '_fail',
                    url,
                    error
                });
                return Promise.reject(error);
            });
    }

    public resetAssessment() {
        // Clean up function after an assessment has been finished
        this.data = JSON.parse(JSON.stringify(this.originalAssessment));
        this.stackLadder = JSON.parse(JSON.stringify(this.originalStackLadder));
    }
}
