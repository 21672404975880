<div class="modal-header">
    <h3 class="capitalize modal-title">Edit {{ type }} details</h3>

    <button class="btn modal-close" (click)="activeModal.dismiss(resource)">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body">
    <form class="edit-resource-form space-children-column" name="editForm" novalidate>
        <div class="flex-row space-children-row align-items-center justify-space-between">
            <mat-form-field class="flex-50" appearance="outline" floatLabel="always">
                <mat-label class="capitalize">{{ type }} Code</mat-label>
                <input matInput type="text" id="resource-code" name="resourceCode" maxlength="30" [(ngModel)]="form.code" disabled="true" required>
            </mat-form-field>
            <mat-form-field class="flex-50" appearance="outline" floatLabel="always">
                <mat-label class="capitalize">{{ type }} Nickname</mat-label>
                <input matInput type="text" id="resource-nickname" name="resourceNickname" maxlength="75" [(ngModel)]="form.name">
            </mat-form-field>
        </div>

        <div class="flex-row justify-space-between align-items-center space-children-row">
            <mat-form-field class="flex-50" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input matInput type="text" [matDatepicker]="startDate" placeholder="mm/dd/yyyy" [(ngModel)]="form.start_date" id="start_date" name="startdate" required>
                <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="flex-50" appearance="outline">
                <mat-label>End Date</mat-label>
                <input matInput type="text" [matDatepicker]="endDate" placeholder="mm/dd/yyyy" [(ngModel)]="form.end_date" id="end_date" name="endDate" required>
                <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex-row space-children-row align-items-center" *ngIf="type === 'course'">
            <label>Grade(s)</label>

            <ng-select  class="flex custom"
                        [items]="grades"
                        appendTo="body"
                        bindLabel="name"
                        [closeOnSelect]="false"
                        [hideSelected]="true"
                        [multiple]="true"
                        [searchable]="true"
                        [loading]="loading"
                        [(ngModel)]="form.grades"
                        name="grades"
                        placeholder="Select one or more grades*"
                        required>
            </ng-select>
        </div>

        <div class="flex-row justify-center align-items-center space-children-row flex-wrap">
            <button class="btn ghost-button" (click)="activeModal.dismiss(resource)">Cancel</button>
            <button type="submit" class="btn primary-button" (click)="updateResource()">Update</button>
        </div>
    </form>
</div>
