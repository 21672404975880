import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '@/core/service/analytics.service';
import { AssessmentStateService } from '../assessment-state.service';
import { HeatmapService } from '@/heatmap/heatmap.service';
import { romanize } from '@/_helpers/transforms';

import './question-view-modal.component.scss';


@Component({
    selector: 'question-view-modal',
    templateUrl: './question-view-modal.component.html',
    // styleUrls: ['./question-view-modal.component.scss'],
})
export class QuestionViewModalComponent implements OnInit {

    @Input() option;
    public foilIdToRomanMap = {};
    public questionMisconceptions = {
        data: null,
        selectedMisconception: null,
    };
    public questionAnalysis: any;

    constructor(
        public activeModal: NgbActiveModal,
        private AnalyticsService: AnalyticsService,
        public AssessmentStateService: AssessmentStateService,
        private HeatmapService: HeatmapService,
    ) {}

    public ngOnInit() {
        if (this.option.itemAnalysis) {
            // Fetch item analysis async after modal has loaded
            this.HeatmapService.getItemAnalysis(this.AssessmentStateService.data.assp_id, this.AssessmentStateService.data.question.id, this.AssessmentStateService.data.retestId)
                .then(result => {
                    this.questionAnalysis = result;
                    // Get total number of students that have answered this question (each stem)
                    this.getTotalStudentsCount(this.questionAnalysis);
                })
                .catch(console.warn);
            // Fetch question misconception
            this.HeatmapService.getQuestionMisconception(this.AssessmentStateService.data.assp_id, this.AssessmentStateService.data.question.id, this.AssessmentStateService.data.retestId)
                .then(result => {
                    this.questionMisconceptions.data = result;
                    this.questionMisconceptions.data.forEach(misc => misc.stems.sort((a, b) => a.order - b.order));
                })
                .catch(console.warn);

            // Assign roman numerals to each foil
            this.AssessmentStateService.data.question.stems.forEach(stem => {
                stem.foils.sort((a, b) => a.order - b.order);
                stem.foils.forEach((foil, foilIdx) => this.assignRomanNumeral(foil, foilIdx + 1));
            });
        }
    }

    public toggleMisconceptionsPanel() {
        this.option.isMisconceptionsPanelOpen = !this.option.isMisconceptionsPanelOpen;
        this.questionMisconceptions.selectedMisconception = this.questionMisconceptions.data[0];
        this.questionMisconceptions.selectedMisconception.heightDelay = 600;
        this.recordAnalyticsAction(this.option.isMisconceptionsPanelOpen, 'misconceptions_panel');
    }

    public recordAnalyticsAction(flag, action) {
        if (flag) {
            this.AnalyticsService.action({ action: ('open_' + action), assp_id: this.AssessmentStateService.data.assp_id, q_id: this.AssessmentStateService.data.question.id, test_attempt: this.AssessmentStateService.data.retestId });
        }
    }

    private getTotalStudentsCount(questionAnalysis) {
        for (const stemId in questionAnalysis) {
            let stem = questionAnalysis[stemId];
            // Sort stems by order ascending
            stem.analysis
                .sort((a, b) => a.order - b.order)
                .forEach(foilAnalysis => {
                    foilAnalysis.total_students = this.calculateTotalStudents(foilAnalysis);
                    foilAnalysis.is_correct_in_top = this.isCorrectResponseInTopResponses(foilAnalysis);
                    // Sort top responses by count descending
                    foilAnalysis.top_responses.sort((a, b) => {
                        if (foilAnalysis.type === 'range') {
                            return 0;
                        }
                        return b.count - a.count;
                    });
                });

            stem.analysis[0].isAccordionOpen = true;
        }
    }

    private calculateTotalStudents(analysis) {
        let totalSudents = 0;

        totalSudents += analysis.no_responses + analysis.other_responses;
        totalSudents += analysis.top_responses.reduce((acc, foil) => acc + foil.count, 0);

        return totalSudents;
    }

    private isCorrectResponseInTopResponses(analysis) {
        return analysis.top_responses.some(response => {
            return String(response.resp).toLowerCase() === String(analysis.correct_response.resp).toLowerCase();
        });
    }

    private assignRomanNumeral(foil, num) {
        let romanNumeral = romanize(num).toLowerCase();
        this.foilIdToRomanMap[foil.id] = foil.romanId = romanNumeral;
    }
}
