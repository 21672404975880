<div id="login-background"></div>

<div class="login-container container-fluid flex-column flex-auto align-items-center justify-center">
    <div class="login-content">
        <a uiSref="landing">
            <img class="provider-logo" [src]="appInfo.providerLogo" [alt]="appInfo.provider + ' logo'">
        </a>

        <h1 class="app-name">{{ appInfo.name }}</h1>

        <!-- School Log In Form -->
        <login-form *ngIf="LoginService.activeForm === 'login'" loginMessage="School Log In" loginType="school" [captcha]="showCaptcha"></login-form>

        <!-- Forgotten Password Form -->
        <forgot-password *ngIf="LoginService.activeForm === 'passwordReset'"></forgot-password>

        <!-- New User Registration -->
        <registration-form *ngIf="LoginService.activeForm === 'registration'" [captcha]="showCaptcha"></registration-form>

        <!-- New Practice User Registration -->
        <practice-registration-form *ngIf="LoginService.activeForm === 'practice_registration'" [captcha]="showCaptcha"></practice-registration-form>

        <!-- Password Reset Form -->
        <new-password-form *ngIf="LoginService.activeForm === 'setPassword'" [captcha]="showCaptcha"></new-password-form>

        <!-- Activate Email -->
        <div class="flex-column flex-auto space-children-column medium-space" *ngIf="LoginService.activeForm === 'activateEmail'">
            <div class="bold capitalize text-center">Activating Email...</div>

            <div class="text-center">
                <i class="fas fa-sync fa-spin fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>

