import { Injectable } from '@angular/core';

import { StateService } from '@uirouter/core';

import { apiServer } from '@/app.constant';
import { UtilsService } from '../../core/service/utils.service';
import { HttpService } from '../../core/service/http.service';
import { AnalyticsService } from '../../core/service/analytics.service';
import { SEQUENCER_DEMO } from '../ssp/sequencer-demo.constant';


@Injectable()
export class CourseManagementService {

    public data = {
        ssp: null,
        resourceType: null,
        resourceId: null,
        course: null,
        section: null,
        demo: {
            id: 0,
            code: 'Demo Course'
        },
        storedSchools: null,
        storedRegions: null,
    };

    constructor(
        private $state: StateService,
        private UtilsService: UtilsService,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) { }


    // public getUserSchools() {
    //     if (this.data.storedSchools) {
    //         return Promise.resolve(this.data.storedSchools);
    //     }

    //     let url = `${apiServer.urlPrefix}/school/`;

    //     return this.HttpService.get(url)
    //         .then(response => {
    //             this.data.storedSchools = response.result;
    //             this.AnalyticsService.action({
    //                 action: 'fetch_school',
    //                 url,
    //             });

    //             return Promise.resolve(response.result);
    //         })
    //         .catch(error => {
    //             this.AnalyticsService.warning({
    //                 action: 'fetch_school_fail',
    //                 url,
    //                 error
    //             });

    //             return Promise.reject(error);
    //         });
    // }

    public getSchoolYears(schoolId): any {
        let url = `${apiServer.urlPrefix}/school_year/${schoolId}/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_school_year',
                    url,
                    schoolId,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_school_year_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getGrades(): any {
        let url = `${apiServer.urlPrefix}/grade/?option=3`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_grade_type_3',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_grade_type_3_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public getCourses(yearId, gradeId = 0): any {
        let url = `${apiServer.urlPrefix}/course/${yearId}/${gradeId}/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_courses_list',
                    url,
                    yearId,
                    gradeId,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_courses_list_fail',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public saveCourse(data, method, resourceType = 'course'): any {
        let url = `${apiServer.urlPrefix}/${resourceType}/`;
        this.UtilsService.addLoadingOverlay(true);

        return this.HttpService.any(url, data, (method || 'PATCH'))
            .then(response => {
                this.AnalyticsService.action({
                    action: ('save_' + resourceType),
                    url,
                    method,
                    resourceType,
                });

                if (resourceType === 'course' && method === 'PUT') {
                    // Redirect if we are in the new courses page
                    this.$state.go('sequencer.courses');
                }
                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: ('failed_save_' + resourceType),
                    url,
                    method,
                    error,
                    supplement: data
                });

                return Promise.reject(error);
            })
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public saveSection(data, method) {
        return this.saveCourse(data, method, 'section');
    }

    public fetchResourceInfo(): any {
        let cos = this.data[this.data.resourceType];
        // Check if current course or section has already been stored in service
        if (cos && cos.id === this.data.resourceId) {
            return Promise.resolve(cos);
        }

        let url = `${apiServer.urlPrefix}/${this.data.resourceType}/`;
        let params = {
            id: this.data.resourceId
        };

        return this.HttpService.get(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_course_or_section_info',
                    url,
                    params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_course_or_section_info_failed',
                    url,
                    params,
                    error
                });

                return Promise.reject(error);
            });
    }

    public fetchSSP(): any {
        if (this.$state.current.name === 'sequencer.demo') {
            let demoSSP = JSON.parse(localStorage.getItem('sequencer_demo')) || Object.assign({}, SEQUENCER_DEMO);
            demoSSP.cluster_ssp = JSON.parse(demoSSP.cluster_ssp);
            demoSSP.region_ssp = JSON.parse(demoSSP.region_ssp);
            this.data.ssp = demoSSP;

            return Promise.resolve(demoSSP);
        }
        let url = `${apiServer.urlPrefix}/${this.data.resourceType}/ssp/`;
        let params = {};
        params[this.data.resourceType + '_id'] = this.data.resourceId;

        return this.HttpService.get(url, params)
            .then(response => {
                this.data.ssp = response.result;
                this.AnalyticsService.action({
                    action: 'fetch_course_or_section_ssp',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_course_or_section_ssp_failed',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    public saveSSP(regionClusterJson): any {
        let url = `${apiServer.urlPrefix}/${this.data.resourceType}/ssp/region_cluster/`;

        regionClusterJson[(this.data.resourceType + '_id')] = this.data.resourceId;
        regionClusterJson.saved = true;

        return this.HttpService.patch(url, regionClusterJson)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'save_course_or_section_ssp',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'save_course_or_section_ssp_failed',
                    url,
                    error
                });

                return Promise.reject(error);
            });
    }

    // public fetchRegions(): any {
    //     if (this.data.storedRegions) {
    //         return Promise.resolve(this.data.storedRegions);
    //     }

    //     let url = `${apiServer.urlPrefix}/map/region/`;
    //     let params = { type: 'nested' };

    //     return this.HttpService.get(url, params)
    //         .then(response => {
    //             this.data.storedRegions = response.result;
    //             this.AnalyticsService.action({
    //                 action: 'fetch_fields',
    //                 url,
    //                 params
    //             });

    //             return Promise.resolve(response.result);
    //         })
    //         .catch(error => {
    //             this.AnalyticsService.warning({
    //                 action: 'fetch_fields_failed',
    //                 url,
    //                 params,
    //                 error
    //             });

    //             return Promise.reject(error);
    //         });
    // }
}
