import { Injectable } from '@angular/core';

import { apiServer } from '@/app.constant';
import { HttpService } from '../core/service/http.service';
import { AnalyticsService } from '../core/service/analytics.service';


@Injectable()
export class ClassRosterService {

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) { }

    public getSectionRoster(sectionId) {
        let url = `${apiServer.urlPrefix}/section/roster/`;
        let params = {
            section_id: sectionId
        };

        return this.HttpService.get(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_section_roster',
                    url: url,
                    params: params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_section_roster_fail',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public updateStudentPassword(sectionId, studentId, newPassword) {
        let url = `${apiServer.urlPrefix}/teacher/password_set/`;
        let serverData = {
            section_id: sectionId,
            student_id: studentId,
            new_password: newPassword
        };

        return this.HttpService.post(url, serverData)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'set_student_password',
                    url: url,
                    data: serverData
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'set_student_password_fail',
                    url: url,
                    data: serverData,
                    error: error
                });

                return Promise.reject(error);
            });
    }
}
