<div [ngSwitch]="type">
    <form *ngSwitchCase="'modal'" [formGroup]="form" class="new-test-form space-children-column big-space body-regular">
        <div class="flex-row align-items-center space-children-row big-space" *ngIf="!courseOrSection">
            <div class="flex align-items-center space-children-row">
                <label for="dropdown-school-year" class="upcase bold">School Year</label>

                <ng-select  class="flex custom"
                            [items]="schoolYears"
                            appendTo="body"
                            bindLabel="name"
                            [clearable]="false"
                            [searchable]="false"
                            [(ngModel)]="dropdownModels.schoolYear"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="!schoolYears"
                            (change)="clearTestValues({ clearSection: true })"
                            name="schoolYear"
                            placeholder="Select a school year*"
                            required>
                </ng-select>
            </div>

            <div class="flex align-items-center space-children-row">
                <label for="dropdown-section" class="upcase bold">Section</label>

                <ng-select  class="flex custom"
                            [items]="dropdownModels.schoolYear.sections"
                            appendTo="body"
                            bindLabel="name"
                            bindValue="id"
                            [closeOnSelect]="false"
                            [groupBy]="groupByCourses"
                            [hideSelected]="true"
                            [multiple]="true"
                            [searchable]="true"
                            formControlName="section_ids"
                            name="sections"
                            placeholder="Select one or more sections*"
                            required>
                </ng-select>
            </div>
        </div>

        <div class="flex-row align-items-center space-children-row" *ngIf="courseOrSection">
            <label for="dropdown-section" class="upcase bold">{{ courseOrSectionType }}</label>
            <div>{{ courseOrSection.name ? (courseOrSection.code + ' - ' + courseOrSection.name) : courseOrSection.code }}</div>
        </div>

        <div class="flex-row align-items-center space-children-row">
            <label class="upcase bold">Cluster</label>

            <ng-select  class="flex custom cluster-dropdown"
                        [items]="clusters"
                        appendTo="body"
                        bindLabel="name"
                        bindValue="id"
                        [clearable]="false"
                        groupBy="regionName"
                        [searchable]="true"
                        formControlName="cluster_id"
                        [loading]="loadingClusters"
                        (change)="clearTestValues({ clearConstruct: true }); getGradesList();"
                        name="cluster"
                        placeholder="Search for cluster tests*"
                        required>
                <ng-template ng-label-tmp let-item="item">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>
            </ng-select>
        </div>

        <div class="flex-row align-items-center space-children-row">
            <label class="upcase bold">Construct (Optional)</label>

            <ng-select  class="flex custom cluster-dropdown"
                        [items]="constructs"
                        appendTo="body"
                        bindLabel="name"
                        bindValue="id"
                        [clearable]="true"
                        [searchable]="true"
                        formControlName="construct_id"
                        (change)="clearTestValues(); getGradesList();"
                        (clear)="getGradesList()"
                        name="construct"
                        placeholder="Narrow down to construct-specific checks">
                <ng-template ng-label-tmp let-item="item">
                    <span [innerHTML]="item.name"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span [innerHTML]="item.name"></span>
                </ng-template>
            </ng-select>
        </div>

        <div class="hr"></div>

        <div class="flex-column align-items-center justify-center warning-message">
            <mat-error *ngIf="dropdownModels.grades && dropdownModels.grades.length === 0">There are no assessments available. Please try a different selection.</mat-error>
            <mat-error *ngIf="form.errors?.datelt">Start date must be before end date</mat-error>
        </div>

        <div class="flex-row align-items-center space-children-row big-space">
            <div class="flex align-items-center space-children-row">
                <label for="dropdown-section" class="upcase bold">Grade Band</label>

                <ng-select  class="flex custom"
                            [items]="dropdownModels.grades"
                            appendTo="body"
                            bindLabel="grade"
                            bindValue="grade_bin"
                            [clearable]="false"
                            [loading]="loadingGrades"
                            [searchable]="false"
                            formControlName="grade"
                            (change)="setAssessmentAndForms($event)"
                            name="grade"
                            placeholder="Select a grade band*"
                            required>
                </ng-select>
            </div>

            <div class="flex align-items-center space-children-row">
                <label for="dropdown-section" class="upcase bold">Testforms</label>

                <ng-select  class="flex custom"
                            [items]="formsRange"
                            appendTo="body"
                            bindLabel="name"
                            bindValue="id"
                            [clearable]="false"
                            formControlName="forms"
                            name="forms"
                            placeholder="# of forms used*"
                            required>
                </ng-select>
            </div>
        </div>

        <div class="flex-row align-items-center space-children-row medium-space">
            <label for="start_date" class="upcase bold">Test Window:</label>

            <mat-form-field appearance="outline">
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    type="text"
                    [matDatepicker]="startDate"
                    placeholder="mm/dd/yyyy"
                    formControlName="start_date"
                    id="start_date"
                    name="startdate"
                    required />
                <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <span>through</span>

            <mat-form-field appearance="outline">
                <mat-label>End Date</mat-label>
                <input
                    matInput
                    type="text"
                    [matDatepicker]="endDate"
                    placeholder="mm/dd/yyyy"
                    formControlName="end_date"
                    id="end_date"
                    name="endDate"
                    required />
                <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex-row align-items-center space-children-row justify-end">
            <button type="button" class="btn btn-link" (click)="testCreated.emit()">Cancel</button>
            <button type="submit" class="btn primary-button" (click)="submitNewTestForm()" [disabled]="!form.valid">Assign</button>
        </div>
    </form>

    <form *ngSwitchCase="'inline'" [formGroup]="form" [class.inactive]="isInactive" class="new-test-form space-children-column filter-group body-regular">
        <div class="flex-row align-items-center">
            <div class="flex-column flex-15 align-items-center justify-center">
                <span></span>
            </div>

            <div class="flex-column flex-10">
                <ng-select  class="form-dropdown custom body-regular"
                            [items]="dropdownModels.grades"
                            appendTo="body"
                            bindLabel="grade"
                            bindValue="grade_bin"
                            [clearable]="false"
                            [loading]="loadingGrades"
                            [searchable]="false"
                            formControlName="grade"
                            (change)="setAssessmentAndForms($event)"
                            name="grade"
                            placeholder="Grade band*"
                            required>
                </ng-select>
            </div>

            <mat-form-field appearance="outline" class="flex-column flex-15">
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    type="text"
                    [matDatepicker]="startDate"
                    placeholder="mm/dd/yyyy"
                    id="start_date"
                    name="startdate"
                    formControlName="start_date"
                    required />
                <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-column flex-15">
                <mat-label>End Date</mat-label>
                <input
                    matInput
                    type="text"
                    [matDatepicker]="endDate"
                    placeholder="mm/dd/yyyy"
                    id="end_date"
                    name="endDate"
                    formControlName="end_date"
                    required />
                <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <div class="flex-column flex-15">
                <ng-select  class="flex custom"
                            [items]="formsRange"
                            appendTo="body"
                            bindLabel="name"
                            bindValue="id"
                            [clearable]="false"
                            formControlName="forms"
                            name="forms"
                            placeholder="# of forms*"
                            required>
                </ng-select>
            </div>

            <div class="flex"></div>

            <div class="form-actions space-children-row small-space">
                <button type="button" class="btn ghost-button body-regular" (click)="cancelForm()">Cancel</button>
                <button type="submit" class="btn primary-button body-regular" (click)="submitNewTestForm()" [disabled]="!form.valid">Assign</button>
            </div>
        </div>

        <div class="flex-column align-items-center warning-message">
            <mat-error *ngIf="dropdownModels.grades && dropdownModels.grades.length === 0">There are no assessments available. Please try a different selection.</mat-error>
            <mat-error *ngIf="form.errors?.datelt">Start date must be before end date</mat-error>
        </div>
    </form>
</div>
