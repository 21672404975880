<div class="feedback-popup dark-theme" [class.show]="survey.state">
    <div class="survey-state flex-row align-items-center" *ngIf="survey.state === 'minimized'">
        <div class="survey-title">Survey</div>
        <button class="btn modal-close" (click)="survey.state = 'started'">
            <i class="fas fa-plus"></i>
        </button>
    </div>

    <div class="survey-state space-children-column" *ngIf="survey.state === 'started'">
        <button class="btn modal-close" (click)="survey.state = 'minimized'">
            <i class="fas fa-minus"></i>
        </button>

        <feedback-form  [questions]="questions"
                        [columnRadio]="true"
                        (completed)="survey.isComplete = $event">
        </feedback-form>

        <div class="flex-row justify-center align-items-center feedback-actions">
            <button class="btn primary-button" (click)="submitFeedback()" [disabled]="!survey.isComplete">Submit</button>
        </div>
    </div>

    <div class="survey-state flex-column align-items-center justify-space-between" *ngIf="survey.state === 'submitted'">
        <button class="btn modal-close" (click)="survey.state = null">
            <i class="icon icon-x"></i>
        </button>

        <div class="flex-row justify-center align-items-center">
            <div class="thank-you-block">Thank you!</div>
        </div>
    </div>
</div>
