<div class="question-breakdown whiteframe" *ngIf="matrix">
    <div class="flex-row report-table">
        <construct-info-column [pageResults]="matrix" [cluster]="cluster"></construct-info-column>

        <div class="tests-container flex">
            <div class="flex-column align-items-center table-column text-center test-info-column is-link" *ngFor="let q_result of matrix.question_scores; index as $index" (click)="viewQuestion.emit($index)">
                <div class="flex-column table-header body-small">
                    <div class="bold">{{ $index + 1 }}</div>
                    <div>Gr. {{ q_result[q_result.construct_id].grade }}</div>
                    <div>Level {{ q_result[q_result.construct_id].stack_level }}</div>
                    <span class="flex"></span>
                    <div class="btn-link text-info flex-none align-items-center justify-center">
                        <span>View</span>
                        <span [hidden]="AssessmentStateService.data.isPracticeTest || !matrix.is_editable">/ Retry</span>
                        <span>&nbsp;</span>
                        <i class="fas fa-angle-double-right"></i>
                    </div>
                </div>
                <div class="table-data" *ngFor="let construct of matrix.construct_info" [class.disabled-background]="!q_result[construct.id]">
                    <point-scale *ngIf="q_result[construct.id] && q_result[construct.id].total > 0" class="flex-column full-height align-items-center justify-space-between" [question]="q_result[construct.id]"></point-scale>
                </div>
            </div>
        </div>

        <div class="table-summary-column flex-column align-items-start table-column" *ngIf="testScores">
            <div class="table-header flex-none align-items-center justify-center body-small">
               <span class="bold text-center">My Construct Score</span>
            </div>

            <div class="table-data text-center body-large" *ngFor="let construct of matrix.construct_info">
                <div class="vertical-center space-children-column small-space">
                    <div>{{ testScores[construct.id] ? (testScores[construct.id].initialPercent + '%') : '&mdash;' }}</div>
                    <div class="revised" *ngIf="testScores[construct.id]?.weightedRevisedOnly">{{ testScores[construct.id].weightedPercent + '%' }}</div>
                </div>
            </div>
        </div>

    </div>

    <div class="flex-row align-items-center report-table-footer">
        <div class="flex align-items-start question-breakdown-legend space-children-row medium-space">
            <span class="key-text">Key:</span>

            <div class="space-children-column small-space">
                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-check correct"></i>
                    <span class="capitalize">Full Credit</span>
                </div>
                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-check revised-full"></i>
                    <span class="capitalize">Full Credit After Retry</span>
                </div>
            </div>

            <div class="space-children-column small-space">
                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-test correct"></i>
                    <span class="capitalize">Partial Credit</span>
                </div>

                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-test revised-no-credit"></i>
                    <span class="capitalize">Partial Credit After Retry</span>
                </div>
            </div>

            <div class="space-children-column small-space">
                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-times incorrect"></i>
                    <span class="capitalize">No Credit</span>
                </div>

                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-times revised-no-credit"></i>
                    <span class="capitalize">No Credit After Retry</span>
                </div>
            </div>

            <div class="space-children-column small-space">
                <div class="flex-none align-items-center space-children-row" [hidden]="AssessmentStateService.data.isPracticeTest">
                    <i class="icon icon-bookmark"></i>
                    <span class="capitalize">Bookmarked</span>
                </div>

                <div class="flex-none align-items-center space-children-row">
                    <i class="icon icon-exclamation-triangle misconception-icon"></i>
                    <span class="capitalize">Misconception</span>
                </div>
            </div>

            <div class="space-children-column small-space">
                <div class="flex-none align-items-center space-children-row item-difficulty">
                    <i class="icon icon-arrow-down-1"></i>
                    <span class="capitalize">Easy For Level</span>
                </div>

                <div class="flex-none align-items-center space-children-row item-difficulty">
                    <i class="icon icon-arrow-up-1"></i>
                    <span class="capitalize">Hard For Level</span>
                </div>
            </div>
        </div>

        <table-scroll-buttons></table-scroll-buttons>

        <div class="construct-overall-score flex-column flex-none align-items-center justify-center" *ngIf="testScores"></div>
    </div>
</div>
