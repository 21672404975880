import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export class DateValidators {
    static dateLessThan(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            const date1 = control.get(dateField1).value;
            const date2 = control.get(dateField2).value;

            if (date1 && date2 && date1 > date2) {
                return validatorField;
            }

            return null;
        };
    }
}
