<div id="coach-marks" *ngIf="coachmark">
    <div class="coach-marks-backdrop"></div>

    <div class="coach-marks-container flex-column align-items-center justify-center">
        <div class="coach-marks-content">
            <div class="coach-mark-loading-frame">
                <div class="loading-animation">
                    <div class="horizontal-line"></div>
                    <div class="vertical-line"></div>
                    <div class="division-blocks top-block"></div>
                    <div class="division-blocks bottom-block"></div>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <img draggable="false" class="img coach-marks-image" [hidden]="!imageLoaded" [src]="'src/assets/coachmarks/' + coachmark.src + '.jpg'" (load)="loadedImage()" (error)="loadedImage()">

            <button class="btn back-button ghost-button dark-theme body-regular upcase" *ngIf="coachmark.back && imageLoaded" (click)="requestPreviousCoachmark()" [ngStyle]="coachmark.back.position">{{ coachmark.back.text || 'Back' }}</button>
            <button class="btn next-button primary-button alt-theme body-regular upcase" *ngIf="coachmark.next && imageLoaded" (click)="requestNextCoachmark()" [ngStyle]="coachmark.next.position">{{ coachmark.next.text || 'Next' }}</button>

            <div class="btn btn-link text-accent learn-more-link capitalize" *ngIf="coachmark.learnMore && imageLoaded" [ngStyle]="coachmark.learnMore.position" placement="bottom" triggers="mouseenter:mouseleave click:click" popoverClass="popover-simple contained" [ngbPopover]="coachmark.learnMore.popover.content" [popoverTitle]="coachmark.learnMore.popover.title">Learn More</div>

            <button class="btn exit-button ghost-button dark-theme body-regular upcase" (click)="exitCoachMarks()" *ngIf="coachmark.exit && imageLoaded" [ngStyle]="coachmark.exit.position">{{ coachmark.exit.text || 'Exit' }}</button>
        </div>
    </div>
</div>
