import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { apiServer } from '@/app.constant';
import { HttpService } from '@/core/service/http.service';


@Injectable({
    providedIn: 'root',
})
export class BackendLoggerService {

    constructor(
        private HttpService: HttpService,
    ) {}

    // Function to log errors caused by our code during runtime inside Angular framework
    // Note: This does not log errors caused by failed $http requests (these are handled by http interceptor and logged to analytics error endpoint)
    public log(error: any) {
        let url = `${apiServer.domain}/events/error/`;

        return this.HttpService.post(url, error)
            .catch(console.warn);
    }
}
