import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';

import { select } from 'd3-selection';
import { interpolateNumber as d3InterpolateNumber } from 'd3-interpolate';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';


@Component({
    selector: '[construct-test-score]',
    templateUrl: './test-score.component.svg.html',
    host: {
        class: 'construct-test-score',
    },
})
export class TestScoreComponent implements OnChanges, AfterViewInit {

    @Input() construct: any;
    @Input() score: any;
    public STROKE_WIDTH = 25;
    private DONUT_THICKNESS = 36;
    private DONUT_PADDING = 4;
    private ANIM_DURATION = 1000;
    private ANIM_DELAY = 800;

    private rootElement;
    private constructArc: any;
    private endAngleInitialScore: number;
    private endAngleRevisedScore: number;

    public constructor(
        private element: ElementRef
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.score && !change.score.isFirstChange && change.score.currentValue) {
            this.renderScores();
        }
    }

    public ngAfterViewInit() {
        if (!this.score) {
            // This will gray out the constructs in report page when there is no score
            this.element.nativeElement.parentNode.classList.add('disabled-construct');

            return false;
        }

        this.rootElement = select(this.element.nativeElement);

        this.constructArc = d3Arc()
            .innerRadius(this.construct.radius - this.STROKE_WIDTH - this.DONUT_THICKNESS / 2 + this.DONUT_PADDING)
            .outerRadius(this.construct.radius - this.STROKE_WIDTH + this.DONUT_THICKNESS / 2 + this.DONUT_PADDING);

        this.renderScores();
    }

    private renderScores() {
        this.endAngleInitialScore = (this.score.weightedOriginalOnly / this.score.weightedTotal * 2 * Math.PI) || 0;
        this.endAngleRevisedScore = (this.score.weightedRevisedOnly / this.score.weightedTotal * 2 * Math.PI) || 0;
        this.drawRevisedScoreArc();
        this.drawInitialScoreArc();
        this.drawScoreNeedle();
    }

    private drawInitialScoreArc() {
        let pie = d3Pie()
            .sort(null)
            .startAngle(0)
            .endAngle(this.endAngleInitialScore);

        // Update action
        let initialScore = this.rootElement.select('.score-group')
            .selectAll('.initial-score-arc')
            .data(pie([(this.score.weightedOriginalOnly / this.score.weightedTotal) || 0]));

        initialScore
            .transition()
            .delay(1000)
            .duration(this.ANIM_DURATION)
            .attrTween('d', d => this.arcTweenInitial(d));

        // Enter action
        let initialScoreEnter = initialScore.enter();
        initialScoreEnter.append('path')
            .classed('initial-score-arc', true)
            .classed('background-correct', true)
            .classed('dark-theme', true)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION)
            .attrTween('d', d => this.arcTweenInitial(d));

        // Exit action
        initialScore.exit().remove();
    }

    private arcTweenInitial(newData) {
        let interpolate = d3InterpolateNumber(0, this.endAngleInitialScore);

        return (t) => {
            newData.endAngle = interpolate(t);
            return this.constructArc(newData);
        };
    }

    private drawRevisedScoreArc() {
        let pie = d3Pie()
            .sort(null)
            .startAngle(this.endAngleInitialScore)
            .endAngle(this.endAngleRevisedScore);

        // Update action
        let revisedScore = this.rootElement.select('.score-group')
            .selectAll('.revised-score-arc')
            .data(pie([(this.score.weightedRevisedOnly / this.score.weightedTotal) || 0]));

        revisedScore
            .transition()
            .delay(1000)
            .duration(this.ANIM_DURATION)
            .attrTween('d', d => this.arcTweenRevised(d));

        // Enter action
        let revisedScoreEnter = revisedScore.enter();
        revisedScoreEnter.append('path')
            .classed('revised-score-arc', true)
            .transition()
            .delay(this.ANIM_DELAY + this.ANIM_DURATION)
            .duration(this.ANIM_DURATION)
            .attrTween('d', d => this.arcTweenRevised(d));

        // Exit action
        revisedScore.exit().remove();
    }

    private arcTweenRevised(newData) {
        let interpolate = d3InterpolateNumber(this.endAngleInitialScore, this.endAngleInitialScore + this.endAngleRevisedScore);

        return (t) => {
            newData.endAngle = interpolate(t);
            return this.constructArc(newData);
        };
    }

    private drawScoreNeedle() {
        let needleGroup = select(this.element.nativeElement.parentNode).select('.needle-group')
            .selectAll('path')
            .data([this.score.weightedPercent || 0]);

        // Update action
        needleGroup
            .transition()
            .delay(1000)
            .duration(this.ANIM_DURATION)
            .attrTween('transform', d => this.angleTween(d));

        // Enter action
        needleGroup.enter()
            .append('path')
            .attr('d', () => {
                return 'M 0,' + (-this.construct.radius + this.STROKE_WIDTH * 2) + ' l ' + (-16) + ',' + (-this.STROKE_WIDTH * 2 - 5) + ' l ' + 32 + ',0 Z';
            })
            .classed('pointer-needle', true)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION * (this.score.weightedRevisedOnly ? 2.5 : 1))
            .attrTween('transform', d => this.angleTween(d));

        needleGroup.exit().remove();
    }

    private angleTween(weightPercent) {
        let interpolate = d3InterpolateNumber(0, (weightPercent / 100) * 360);

        return (t) => 'rotate(' + interpolate(t) + ')';
    }
}
