import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { apiServer } from '@/app.constant';
import { SessionService } from '../../../core/service/session.service';
import { UtilsService } from '../../../core/service/utils.service';
import { ModalService } from '../../../shared/service/modal.service';
import { AssessmentStateService } from '../../../assessment/common/assessment-state.service';

import './stem.component.scss';


@Component({
    selector: 'stem',
    templateUrl: './stem.component.html',
    // styleUrls: ['./stem.component.scss'],
})
export class StemComponent implements OnChanges {

    @Input() stem: any;
    @Input() stemIndex: number;
    @Output() revealAnswer = new EventEmitter<any>();
    public readonly apiServer = apiServer;

    constructor(
        public SessionService: SessionService,
        private UtilsService: UtilsService,
        private ModalService: ModalService,
        public AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.stem.currentValue) {
            this.stem.foils.sort((a, b) => a.order - b.order);
        }
    }

    public displayNextConfirmation() {
        let config = {
            bodyText: 'When you proceed to the next part, you will not be able modify your current answers.',
            actionButtonText: 'Proceed'
        };
        this.ModalService.openModal({}, config)
            .then(() => this.showNextPart())
            .catch(() => {});
    }

    private showNextPart() {
        let data = this.AssessmentStateService.collectStudentResponse();

        // Show loading screen and send request
        this.UtilsService.addLoadingOverlay(true);
        return this.AssessmentStateService.submitTestQuestion(data, 'save_response_for_multi_part')
            .then((result) => {
                // Reset time when user begins working on next part (no new question is fetched so it isn't reset)
                this.AssessmentStateService.data.questionStartTime = Math.floor(Date.now() / 1000);
                // Increment current multi-part
                this.AssessmentStateService.data.multi.current += 1;
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
