import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '../shared/shared.module';

import { AssessmentManagementService } from './assessment-management.service';
import { AssessmentManagementComponent } from './assessment-management.component';
import { SectionAssessmentComponent } from './section-assessment/section-assessment.component';
import { TeacherAssignedTestsComponent } from './teacher-assigned-tests/teacher-assigned-tests.component';
import { StudentPracticeActivityComponent } from './student-practice-activity/student-practice-activity.component';
import { StudentTriesActivityComponent } from './student-tries-activity/student-tries-activity.component';
import { NewTestFormComponent } from './new-test/new-test-form.component';
import { EditTestFormComponent } from './edit-test/edit-test-form.component';
import { NewTestAssignmentModalComponent } from './new-test/new-test-assignment-modal.component';
import { PreviewTestModalComponent } from './preview-test/preview-test-modal.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule,
        MatFormModule,
        MatExpansionModule,
        MatTooltipModule,
        MatTabsModule,
        SharedModule,
    ],
    providers: [
        AssessmentManagementService,
    ],
    declarations: [
        AssessmentManagementComponent,
        SectionAssessmentComponent,
        TeacherAssignedTestsComponent,
        StudentPracticeActivityComponent,
        StudentTriesActivityComponent,
        NewTestFormComponent,
        EditTestFormComponent,
        NewTestAssignmentModalComponent,
        PreviewTestModalComponent,
    ],
    entryComponents: [
        NewTestAssignmentModalComponent,
        PreviewTestModalComponent,
    ],
})
export class AssessmentManagementModule { }
