<div class="calendar-days">
    <div *ngIf="isMonth">
        <div class="calendar-weekdays" *ngFor="let week of CalendarService.calendar.weeks.days; index as weekIndex">
            <div class="calendar-bg">
                <div class="calendar-date" [ngClass]="{ 'calendar-today': day.today, 'calendar-different-month': !day.currentMonth, 'weekend-day': isFirst || isLast, 'blocked-day': !!SequencerStateService.data.holidays[day.formattedDate] || !!SequencerStateService.data.blockedDays[day.formattedDate] }" *ngFor="let day of week.days; first as isFirst; last as isLast">
                    <calendar-day-items [day]="day" date="{{ day.number }}"></calendar-day-items>
                </div>
            </div>


            <div class="calendar-front" *ngIf="CalendarService.calendar.displayData[CalendarService.calendar.weeks.yearNum] && CalendarService.calendar.displayData[CalendarService.calendar.weeks.yearNum][CalendarService.calendar.weeks.monthNum]">
                <div class="calendar-row" *ngFor="let weekEvents of CalendarService.calendar.displayData[CalendarService.calendar.weeks.yearNum][CalendarService.calendar.weeks.monthNum][weekIndex]['events'] | keyvalue">
                    <calendar-day-front-row class="flex-row" [weekEvents]="weekEvents.value"></calendar-day-front-row>
                </div>
            </div>
        </div>
    </div>

    <div class="calendar-weekdays" *ngIf="!isMonth">
        <div class="calendar-bg">
            <div class="calendar-date" [ngClass]="{ 'calendar-today': day.today, 'weekend-day': isFirst || isLast, 'blocked-day': !!SequencerStateService.data.holidays[day.formattedDate] || !!SequencerStateService.data.blockedDays[day.formattedDate] }" *ngFor="let day of CalendarService.calendar.week.days; first as isFirst; last as isLast">
                <calendar-day-items [day]="day" date="{{ day.monthString }} {{ day.number }}"></calendar-day-items>
            </div>
        </div>


        <div class="calendar-front" *ngIf="CalendarService.calendar.displayData[CalendarService.calendar.week.yearNum] && CalendarService.calendar.displayData[CalendarService.calendar.week.yearNum][CalendarService.calendar.week.monthNum]">
            <div class="calendar-row" *ngFor="let weekEvents of CalendarService.calendar.displayData[CalendarService.calendar.week.yearNum][CalendarService.calendar.week.monthNum][CalendarService.calendar.week.mWeekNum]['events'] | keyvalue">
                <calendar-day-front-row class="flex-row" [weekEvents]="weekEvents.value"></calendar-day-front-row>
            </div>
        </div>
    </div>
</div>

