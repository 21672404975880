import { Injectable } from '@angular/core';

import { StateService } from '@uirouter/core';

import { apiServer } from '@/app.constant';
import { HttpService } from '../../core/service/http.service';
import { AnalyticsService } from '../../core/service/analytics.service';


@Injectable()
export class FeedbackService {

    constructor(
        private $state: StateService,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) {}

    public submitSurvey(questions, { trid = 0, a_ssp = 0 } = {}) {
        let url = `${apiServer.urlPrefix}/assessment/feedback/`;
        let trigger = questions[0].trigger_id;
        let answers = this.formatAnswers(questions);
        // Don't send any data if we're not sending anything worthwhile
        // Or if this is a test without a_ssp (demo test, researcher test, etc)
        if (!answers.shouldBeSent) {
            return Promise.reject('invalid input or no assp id');
        }
        delete answers.shouldBeSent;

        let postData = {
            ...answers,
            page: this.$state.current.name,
            trigger,
            trid,
            a_ssp,
        };

        return this.HttpService.post(url, postData)
            .then(response => {
                this.AnalyticsService.assessment({
                    'action': 'submit_feedback',
                    'response': response
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    'action': 'submit_feedback_fail',
                    'url': url,
                    'error': error,
                    'supplement': postData
                });

                return Promise.reject(error);
            });
    }

    private formatAnswers(questions) {
        let feedback = [];
        let option = [];
        let userResponses = [];

        questions.forEach(question => {
            feedback.push(question.id);
            if (question.type === 'scale' || question.type === 'WestEd') {
                option.push(parseInt(question.response));
                userResponses.push(null);
            } else {
                // If open ended response is not required it'll be null/undefined
                if (!question.response) {
                    question.response = '';
                }
                // Applies to (question.type === 'open ended') or any other type
                let cleanResponse = question.response.replace(/([|&;$%@'"<>()+\[\]])/ig, '\\$1')
                option.push(null);
                userResponses.push(cleanResponse);
            }
        });

        return {
            feedback: JSON.stringify(feedback),
            option: JSON.stringify(option),
            response: JSON.stringify(userResponses),
            shouldBeSent: (option.some(this.isValidInput) || userResponses.some(this.isValidInput))
        };
    }

    private isValidInput(element, index, array) {
        // Double negation catches null, undefined, NaN, empty string, and 0 - We consider 0 as valid input
        return !!element || element === 0;
    }
}
