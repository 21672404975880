import { Component, OnInit, OnDestroy } from '@angular/core';

import * as moment from 'moment';
import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { SessionService } from '@/core/service/session.service';
import { UtilsService } from '@/core/service/utils.service';
import { AssessmentService } from '../assessment.service';
import { AssessmentStateService } from '../common/assessment-state.service';

import './student-assigned-tests.component.scss';

@Component({
    selector: 'student-assigned-tests',
    templateUrl: './student-assigned-tests.component.html',
    // styleUrls: ['./student-assigned-tests.component.scss'],
})
export class StudentAssignedTestsComponent implements OnInit, OnDestroy {

    // Minimap variables
    public highlightAssps = [];
    public isMinimapOpen = false;
    // Page variables
    public assignedTests = null;
    public readonly dateFormat = dateFormat;

    constructor(
        private $state: StateService,
        private SessionService: SessionService,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.AssessmentStateService.assessmentFinished$.subscribe(() => {
            // Update student test page when assessment is done
            this.AssessmentStateService.getStudentAssessments()
                .then(assignedTests => {
                    this.assignedTests = assignedTests;
                    this.setTestFlags();
                })
                .catch(console.warn);
        });
        // Config moment.js relative time rounding function to round down
        // e.g. Test due in 1.87 days -> test due in 1 day instead of 2 days
        moment.relativeTimeRounding(Math.floor);

        this.UtilsService.addLoadingOverlay();
        this.AssessmentStateService.getStudentAssessments()
            .then(assignedTests => {
                this.assignedTests = assignedTests;
                this.setTestFlags();
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());

    }

    public ngOnDestroy() {
        // Restore original moment.js config
        moment.relativeTimeRounding(Math.round);
    }

    public showAsspInMap(assp) {
        this.isMinimapOpen = true;
        this.highlightAssps = [assp];
    }

    private setTestFlags() {
        this.assignedTests.forEach(test => {
            test.timeTilDue = this.calculateTimeToDate(test);

            test.retest.forEach(retest => {
                retest.timeTilDue = this.calculateTimeToDate(retest);
            });
        });
    }

    public calculateTimeToDate(test): string {
        const start = moment(test.start_date, 'YYYY-MM-DD');
        // Add 1 day so that it is due on midnight on the day it is due (technically the next day)
        const due = moment(test.end_date, 'YYYY-MM-DD').add(1, 'day');
        const now = moment(new Date(), 'YYYY-MM-DD');

        const difference = moment.duration(due.diff(now));
        const daysTilDue = difference.asDays();

        // If test is past due or before start date, disable test-taking actions
        if (now.isBetween(start, due, 'minute', '[]')) {
            test.isAvailable = true;
        } else if (now.isBefore(start)) {
            test.daysTilStart = start.diff(now, 'days');
        } else if (now.isAfter(due)) {
            test.isExpired = true;
        }

        if (daysTilDue > 0 && daysTilDue < 3) {
            // Test is not past due but less than 3 days until due date so we flag it to alert the user
            test.isDueSoon = true;
        }

        return difference.humanize(true);
    }

    public getSectionAssessment(assp, retestId=0) {
        this.UtilsService.addLoadingOverlay();
        this.AssessmentStateService.getSectionAssessmentInfo(assp.id, retestId)
            .then(testInfo => this.AssessmentService.openTestInstructionsModal(testInfo))
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
