import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer, dateFormat } from '@/app.constant';
import { SessionService } from '../../../core/service/session.service';
import { HttpService } from '../../../core/service/http.service';
import { UtilsService } from '../../../core/service/utils.service';
import { AnalyticsService } from '../../../core/service/analytics.service';

import './opt-out-modal.component.scss';
import { ThrowStmt } from '@angular/compiler';


@Component({
    selector: 'opt-out-modal',
    templateUrl: './opt-out-modal.component.html'
})
export class OptOutModalComponent implements OnInit {
    public optOutForm: FormGroup;
    public today: Date = new Date();
    public readonly dateFormat = dateFormat;
    public optOutVal: number;
    public showMore: boolean = false;
    public optOutReminder: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        public SessionService: SessionService,
        private fb: FormBuilder,
        private HttpService: HttpService,
        private $state: StateService,
        private UtilsService: UtilsService,
        private AnalyticsService: AnalyticsService,
    ) {
        this.optOutForm = this.fb.group({
            optOut: [null]
        }, {});
    }
    
    public ngOnInit() {
        let valOptout: number;
        if (this.$state.$current.name == 'practice_register') {
            this.optOutReminder = true;
            valOptout = 0;
        } else {
            valOptout = this.SessionService.userProfile['opt_out'] & 1;
        }

        this.optOutForm.get('optOut').setValue(valOptout);
    }

    public detailText($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.showMore = !this.showMore;
        
        this.AnalyticsService.action({ action: 'opt_out_text_show_' + (this.showMore ? 'more' : 'less') });
    }

    public saveOptOut($event) {
        const valOptout = this.optOutForm.get('optOut').value;
        const origOptout = this.SessionService.userProfile === null ? 0 : this.SessionService.userProfile['opt_out'];
        if (valOptout != (origOptout & 1)) {
            const url = `${apiServer.urlPrefix}/user/profile/opt_out/`;

            // save opt out and update localstorage
            this.UtilsService.addLoadingOverlay(true);
            this.HttpService.post(url, { opt_out: valOptout })
                .then(response => {
                    this.optOutForm.reset();
                    this.SessionService.setUserProfile(response.result);
                })
                .catch(error => { console.warn })
                .finally(() => {
                    this.UtilsService.removeLoadingOverlay();
                    if (this.$state.$current.name == 'practice_register') {
                        this.$state.go('map');
                    }
                });

            this.AnalyticsService.action({ action: 'opt_out_save_' + valOptout });
        } else {
            if (this.$state.$current.name == 'practice_register') {
                this.$state.go('map');
            }

            this.AnalyticsService.action({ action: 'opt_out_close' });
        }
        this.activeModal.close();
    }
}
