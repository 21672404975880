import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';


@Injectable()
export class SequencerStateService {

    private sequencerDataChange = new Subject<{
        courseOrSection: any,
        ssp: any,
        regions: any,
    }>();
    public sequencerData$: Observable<any> = this.sequencerDataChange.asObservable();

    public data = {
        accordion: {
            boxHeight: 40,
            initialYDisplacement: 30,
            initialX: 0,
            endingX: 0,
            popupWidth: 0
        },
        blockedDays: {},
        holidays: {},
        courseDates: {
            start: null,
            end: null,
            customStart: null
        },
        dateShown: true,
        editMode: false,
        initialized: false,
        isBeingEdited: false,
        model: {
            mapLoaded: false,
            mapRegions: [],
            mapClusters: [],
            pieRegions: [],
            pieClusters: [],
            assessments: [],
            resources: [],
            notes: []
        },
        pie: {
            radiusInner: 100,
            radiusIntermediate: 175,
            radiusOuter: 210,
            center: {
                x: 0,
                y: 0,
            }
        },
        popover: {
            element: null,
            legendElement: null,
            regionTitle: null,
            clusterTitle: null,
        },
        rawMapRegions: [],
        rawMapClusters: [],
        receivedJson: null,
        regionPopup: {
            innerPadding: {
                x: 40,
                y: 10
            },
            outerPadding: {
                x: 10,
                y: 5
            },
            dragPosition: {
                x: 10,
                y: 23
            },
            clusterWidth: {
                text: 300,
                border: 360
            },
            clusterHeight: 0
        },
        selected: {
            region: null,
            cluster: null,
            clusters: [],
            originalDragDays: {},
            validDrag: false,
            insidePie: false,
            previousIndex: null,
        },
        snapshots: [],
        svgSettings: {
            width: 1050, // Should be min 1000 to avoid text overlap
            height: 530,
            pie: {
                arc: null,
                d3Pie: null
            },
            donut: {
                arc: null,
                d3Donut: null
            }
        },
        svgSelections: {
            root: null,
            dragObject: null,
            dragScreenOverlay: null,
            accordionLayer: null,
            pieLayer: null,
            pieFront: null,
            pieBack: null,
            summaryText: null
        },
        tempDatas: {
            prevAngle: null,
            pieClusters: null,
            pieClusterWedgeData: null,
            pieClusterWedgeDataOrdered: null,
        },
        temporarySnapshot: null,
        totalDays: {
            value: 0,
            default: 140
        },
        undoButton: {
            w: 80,
            h: 32,
            x: 0,
            y: 0,
        }
    };
    private original: any;

    constructor(
    ) {
        // Derived values from other definitions
        this.data.pie.center = {
            x: this.data.svgSettings.width / 4,
            y: this.data.svgSettings.height / 2
        };
        this.data.undoButton.x = this.data.pie.center.x - 37;
        this.data.undoButton.y = this.data.pie.center.y + 50;
        // Copy sequencer into original
        this.original = JSON.parse(JSON.stringify(this.data));
    }

    public updateSequencerData(newData) {
        this.sequencerDataChange.next(newData);
    }

    public resetSequencer() {
        // Reset sequencer back to original
        this.data = JSON.parse(JSON.stringify(this.original));
    }

    public getRegionClusterJson(option = 'full') {
        let region_ssp = this.data.model.pieRegions
            .filter(region => region.order > -1)
            .map(region => {
                let json = region.toJson();
                if (option === 'compact') {
                    json.region = { id: json.region.id };
                }

                return json;
            });

        let cluster_ssp = this.data.model.pieClusters
            .filter(cluster => cluster.order > -1)
            .map(cluster => {
                let json = cluster.toJson();
                if (option === 'compact') {
                    json.cluster = { id: json.cluster.id };
                }

                return json;
            });

        return {
            region_ssp: JSON.stringify(region_ssp),
            cluster_ssp: JSON.stringify(cluster_ssp),
            totalDays: this.data.totalDays.value
        };
    }

    public getTotalPieClusterDays() {
        return this.data.model.pieClusters.reduce((accumulator, cluster) => accumulator + cluster.days, 0);
    }
}
