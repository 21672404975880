import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { apiServer } from '@/app.constant';
import { HttpService } from '../../../core/service/http.service';
import { AnalyticsService } from '../../../core/service/analytics.service';
import { GrowlerService } from '../../../core/service/growler.service';
import { SessionService } from '../../../core/service/session.service';
import { SequencerStateService } from '../sequencer-state.service';
import { SequencerService } from '../sequencer.service';
import { CourseManagementService } from '../../course-management/course-management.service';


@Injectable()
export class CalendarService {

    private anchorDate = moment();

    public calendar = {
        type: 'month',
        displayData: {},
        displayAssessments: {},
        displayResources: {},
        week: {
            weekNum: null,
            yearNum: null,
            monthString: null,
            monthNum: null,
            mWeekNum: null,
            days: null,
        },
        weeks: {
            yearNum: null,
            monthNum: null,
            days: null,
            month: null,
        },
        holidays: null,
        blockedDays: null,
    };

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private GrowlerService: GrowlerService,
        private SessionService: SessionService,
        private SequencerStateService: SequencerStateService,
        private SequencerService: SequencerService,
        private CourseManagementService: CourseManagementService,
    ) { }

    public generateCalendarData() {
        let pieRegions = this.SequencerStateService.data.model.pieRegions
            .sort((a, b) => a.order - b.order);
        pieRegions.forEach(pieRegion => {
            let startDate = moment(pieRegion._startDate);
            let endDate = moment(pieRegion._endDate);
            this.buildMonthLevels(startDate, endDate);
            this.fillRegionsAndClusters(pieRegion, (moment(pieRegion._startDate)).startOf('day'), (moment(pieRegion._endDate)).startOf('day'), 'region');
        });

        let pieClusters = this.SequencerStateService.data.model.pieClusters
            .sort((a, b) => a.order - b.order);
        pieClusters.forEach(pieCluster => {
            this.fillRegionsAndClusters(pieCluster, (moment(pieCluster._startDate)).startOf('day'), (moment(pieCluster._endDate)).startOf('day'), 'cluster');
        });

        this.generateAssessmentsData();
        this.generateResourcesData();
    }

    public generateAssessmentsData() {
        this.calendar.displayAssessments = {};
        this.SequencerStateService.data.model.assessments.forEach(assessment => {
            let dateItr = moment(assessment.start_date);
            let endDate = moment(assessment.end_date);
            while (!dateItr.isAfter(endDate)) {
                let formattedDate = dateItr.format('YYYY-MM-DD');
                this.calendar.displayAssessments[formattedDate] = this.calendar.displayAssessments[formattedDate] || [];
                this.calendar.displayAssessments[formattedDate].push(assessment);

                dateItr = this.SequencerService.incrementValidDays(dateItr, 1);
            }
        });
    }

    public generateResourcesData() {
        this.calendar.displayResources = {};
        this.SequencerStateService.data.model.resources.forEach(resource => {
            let dateItr = moment(resource.start_date);
            let endDate = moment(resource.end_date);
            while (!dateItr.isAfter(endDate)) {
                let formattedDate = dateItr.format('YYYY-MM-DD');
                this.calendar.displayResources[formattedDate] = this.calendar.displayResources[formattedDate] || [];
                this.calendar.displayResources[formattedDate].push(resource);

                dateItr = this.SequencerService.incrementValidDays(dateItr, 1);
            }
        });
    }

    public buildMonthLevels(startDate, endDate) {
        let monthStart = moment(startDate).startOf('month').add(-1, 'month');

        while (monthStart.isSameOrBefore(moment(endDate).add(1, 'month'))) {
            if (typeof this.calendar.displayData[monthStart.year()] === 'undefined') {
                this.calendar.displayData[monthStart.year()] = {};
            }

            if (typeof this.calendar.displayData[monthStart.year()][monthStart.month()] === 'undefined') {
                this.calendar.displayData[monthStart.year()][monthStart.month()] = [];
                for (let weekCounter = 0; weekCounter < 6; weekCounter++) {
                    this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter] = {
                        'events': {}
                    };
                }
            }

            monthStart.add(1, 'month');
        }
    }

    /**
    * Method to fill events in the months multi level wise in all weeks
    */
    public fillRegionsAndClusters(event, sDate, eDate, level) {
        let startDate = sDate;
        let endDate = eDate;
        let monthStart = moment(startDate).startOf('month').add(-1, 'month');
        let paddedEndDate = moment(eDate).add(1, 'month');
        while (monthStart.isSameOrBefore(paddedEndDate)) {
            let weekStart = moment(monthStart).startOf('week');
            for (let weekCounter = 0; weekCounter < 6; weekCounter++) {
                let weekDayCounter = 0;
                let selectedWeek = moment(weekStart);
                do {
                    let totalDays = 0;
                    let startCounter = -1;

                    for (; weekDayCounter < 7; weekDayCounter++) {
                        if (selectedWeek.isSameOrAfter(startDate) && selectedWeek.isSameOrBefore(endDate) && selectedWeek.day() !== 6 && selectedWeek.day() !== 0) {
                            if (this.SequencerService.isHoliday(moment(selectedWeek))) {
                                weekDayCounter++;
                                selectedWeek.add('1', 'day');
                                break;
                            }

                            if (startCounter === -1) {
                                startCounter = weekDayCounter;
                            }

                            totalDays++;
                        }
                        selectedWeek.add('1', 'day');
                    }

                    if (totalDays) {
                        /**
                        * Dealing with level and missing dates in a week to have events at appropriate place
                        */
                        if (typeof this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter].events[level] === 'undefined') {
                            this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter].events[level] = [];
                            /* Filing missing days in week with their indexes for selected */
                            for (let dateIndex = 1; dateIndex <= 5; dateIndex++) {
                                if (dateIndex < startCounter || dateIndex > (startCounter + totalDays - 1)) {
                                    this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter].events[level].push({
                                        startIndex: dateIndex,
                                        totalDays: 1,
                                        entity: false
                                    });
                                }
                            }
                        } else {
                            /* Removing missing days in week for which events found for selected level */
                            let eventRowObj = this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter].events[level];
                            for (let totalCounter = startCounter; totalCounter < startCounter + totalDays; totalCounter++) {
                                for (let i = 0; i < eventRowObj.length; i++) {
                                    if (eventRowObj[i].startIndex === totalCounter) {
                                        eventRowObj.splice(i, 1);
                                        break;
                                    }
                                }
                            }
                        }

                        /**
                        * Inserting event week wise with start index and total days to fill
                        */
                        this.calendar.displayData[monthStart.year()][monthStart.month()][weekCounter].events[level].push({
                            startIndex: startCounter,
                            totalDays: totalDays,
                            entity: event,
                            startDate: this.getDisplayDataStartDate(monthStart.year(), monthStart.month(), weekCounter).add(startCounter, 'day'),
                            roundStart: (startDate.isSameOrAfter(weekStart)),
                            roundEnd: (endDate.isSameOrBefore(moment(selectedWeek)))
                        });
                    }
                } while (weekDayCounter < 7);
                weekStart.add('1', 'week');
            }

            monthStart.add('1', 'month');
            monthStart.startOf('month');
        }
    }

    public buildWeek(referenceDate = this.anchorDate) {
        // referenceDate is any day of the desired week
        let days = []; // this array will be returned
        let dayIterator;

        // day notes
        dayIterator = this.resetTime(moment(referenceDate)); // reset to Sunday
        let dayNotes = [ [], [], [], [], [], [], [] ]; // each element represents the note for each day of the week

        this.SequencerStateService.data.model.notes.forEach(note => {
            let dateOfThisNote = moment(note.date);
            if (this.isOnWeekStarting(dayIterator, dateOfThisNote)) {
                let dayIndex = dateOfThisNote.weekday() - dayIterator.weekday();
                dayNotes[dayIndex].push(note);
            }
        });

        // now build the this.calendar days array
        dayIterator = this.resetTime(moment(referenceDate)); // reset to Sunday
        let today = moment(new Date());
        let weekNum;
        let dayIsBlocked;
        let blockedDaysId;

        for (let i = 0; i < 7; i++) {
            weekNum = dayIterator.week();
            dayIsBlocked = this.SequencerService.isBlockedDay(moment(dayIterator));
            blockedDaysId = dayIsBlocked ? this.SequencerStateService.data.blockedDays[dayIterator.format('YYYY-MM-DD')].id : null;
            days.push({
                number: dayIterator.date(),
                currentMonth: dayIterator.month() === referenceDate.month(),
                currentWeek: dayIterator.week() === referenceDate.week(),
                today: dayIterator.isSame(today, 'day'),
                weekNum: weekNum,
                mWeekNum: this.getWeekNumByMonth(moment(referenceDate)),
                monthString: dayIterator.format('MMM'),
                date: moment(dayIterator),
                formattedDate: moment(dayIterator).format('YYYY-MM-DD'),
                notes: dayNotes[i],
                blockedDaysId: blockedDaysId,
                blocked: dayIsBlocked,
            });
            dayIterator.add(1, 'd');
        }
        if (referenceDate.toString() === this.anchorDate.toString()) {
            this.calendar.week = {
                days: days,
                weekNum: weekNum,
                mWeekNum: this.getWeekNumByMonth(moment(referenceDate)),
                monthString: dayIterator.format('MMM'),
                yearNum: referenceDate.year(),
                monthNum: referenceDate.month()
            };
        }

        return days;
    }

    public buildMonth(referenceDate = this.anchorDate) {
        let weeks = [];
        let done = false;
        let dateIterator = moment(referenceDate).date(1);
        let monthIndex = dateIterator.month();
        let count = 0;

        while (!done) {
            weeks.push({
                days: this.buildWeek(dateIterator)
            });
            dateIterator.add(1, 'w');
            // look for the crossover into the next month
            done = (count++ > 2 && monthIndex !== dateIterator.month());
            monthIndex = dateIterator.month();
        }
        if (referenceDate.toString() === this.anchorDate.toString()) {
            this.calendar.weeks = {
                days: weeks,
                month: referenceDate,
                yearNum: referenceDate.year(),
                monthNum: referenceDate.month()
            };
        }

        return {
            days: weeks,
            month: referenceDate
        };
    }

    public changeMonth(direction) {
        // make sure this.anchorDate is the first day of its month
        this.anchorDate.date(1);
        if (direction === 'prev') {
            this.anchorDate.subtract(1, 'month');
        } else if (direction === 'next') {
            this.anchorDate.add(1, 'month');
        }

        this.buildMonth();
    }

    public changeWeek(direction) {
        if (direction === 'prev') {
            this.anchorDate.subtract(1, 'week');
        } else if (direction === 'next') {
            this.anchorDate.add(1, 'week');
        }

        this.buildWeek();
    }

    public getDisplayDataStartDate(year, month, weekCounter) {
        let monthStart = moment().year(year).month(month).startOf('month');
        let date = monthStart.add(weekCounter, 'week');

        return date.startOf('week');
    }

    public createCalendarNote(note): any {
        // Note should have properties 'content' and 'date', optionally 'id':0
        if (this.CourseManagementService.data.resourceType === 'demo') {
            // Fake values assigned by backend successful save
            note.editable = true;
            note.added = new Date().toISOString();
            note.user = this.SessionService.userProfile.first_name;
            this.SequencerStateService.data.model.notes.push(note);
            let demoSSP: any = this.SequencerStateService.getRegionClusterJson();
            demoSSP.note_ssp = this.SequencerStateService.data.model.notes;
            localStorage.setItem('sequencer_demo', JSON.stringify(demoSSP));

            return Promise.resolve(note);
        }

        let url = `${apiServer.urlPrefix}/${this.CourseManagementService.data.resourceType}/ssp/note/`;
        let data = {
            saved: true,
            note: JSON.stringify(note)
        };
        data[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.post(url, data)
            .then(response => {
                this.SequencerStateService.data.model.notes.push(note);
                this.AnalyticsService.action({
                    action: 'create_this.calendar_note',
                    url: url
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'create_this.calendar_note_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public updateCalendarNote(note) {
        if (note.content.trim().length === 0) {
            return this.deleteCalendarNote(note.id);
        }

        if (this.CourseManagementService.data.resourceType === 'demo') {
            let demoSSP: any = this.SequencerStateService.getRegionClusterJson();
            demoSSP.note_ssp = this.SequencerStateService.data.model.notes;
            localStorage.setItem('sequencer_demo', JSON.stringify(demoSSP));

            return Promise.resolve(note);
        }

        let url = apiServer.urlPrefix + '/' + this.CourseManagementService.data.resourceType + '/ssp/note/';
        let data = {
            saved: true,
            note: JSON.stringify(note)
        };
        data[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.patch(url, data)
            .then(response => {
                if (response.success) {
                    this.AnalyticsService.action({
                        action: 'update_calendar_note_success',
                        url,
                    });
                } else {
                    this.GrowlerService.error('Failed to save note.', 'Error!');
                    this.AnalyticsService.warning({
                        action: 'update_calendar_note_failed',
                        url,
                    });
                }
            });
    }

    public deleteCalendarNote(noteId) {
        if (this.CourseManagementService.data.resourceType === 'demo') {
            let demoSSP: any = this.SequencerStateService.getRegionClusterJson();
            demoSSP.note_ssp = this.SequencerStateService.data.model.notes;
            localStorage.setItem('sequencer_demo', JSON.stringify(demoSSP));

            return Promise.resolve();
        }

        let url = apiServer.urlPrefix + '/' + this.CourseManagementService.data.resourceType + '/ssp/note/';
        let data = {
            note_id: noteId
        };
        data[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.delete(url, data)
            .then(response => {
                if (response.success) {
                    this.AnalyticsService.action({
                        action: 'delete_calendar_note_success',
                        url,
                    });
                } else {
                    this.GrowlerService.error('Failed to delete note.', 'Error!');
                    this.AnalyticsService.warning({
                        action: 'delete_calendar_note_failed',
                        url,
                    });
                }
            });
    }

    public createSspResource(resourcesArray) {
        return this.saveSspResource('POST', 'submit_course_ssp', resourcesArray);
    }

    public deleteSspResource(resourceId) {
        let resourceData = [{ id: resourceId }];
        return this.saveSspResource('DELETE', 'delete_calendar_resource', resourceData);
    }

    public saveSspResource(method, action, resourceData): any {
        let url = apiServer.urlPrefix + '/' + this.CourseManagementService.data.resourceType + '/ssp/resource/';
        let params = {
            saved: true,
            resource: JSON.stringify(resourceData)
        };
        params[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.any(url, params, method)
            .then(response => {
                this.AnalyticsService.action({
                    action: action,
                    url: url,
                    params: params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: action + '_failed',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public createBlockedDay(blockedDays): any {
        this.updateBlockedDay(blockedDays);

        if (this.CourseManagementService.data.resourceType === 'demo') {
            return Promise.resolve(blockedDays);
        }

        let url = apiServer.urlPrefix + '/' + this.CourseManagementService.data.resourceType + '/ssp/blocked_days/';
        let data = {
            saved: true,
            blocked_days: JSON.stringify(blockedDays)
        };
        data[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.post(url, data)
            .then(response => {
                if (response.success) {
                    this.updateBlockedDay(response.result);
                }
            });
    }

    public updateBlockedDay(blockedDays) {
        let iteratorM = moment(blockedDays.start_date);
        let endM = moment(blockedDays.end_date);

        while (iteratorM.isSameOrBefore(endM)) {
            this.SequencerStateService.data.blockedDays[iteratorM.format('YYYY-MM-DD')] = Object.assign({}, blockedDays);
            iteratorM.add(1, 'day');
        }
    }

    public deleteBlockedDays(day) {
        let blockedDays = this.SequencerStateService.data.blockedDays[day.formattedDate];
        let iteratorM = moment(blockedDays.start_date);
        let endM = moment(blockedDays.end_date);

        while (iteratorM.isSameOrBefore(endM)) {
            delete this.SequencerStateService.data.blockedDays[iteratorM.format('YYYY-MM-DD')];
            iteratorM.add(1, 'day');
        }
        if (this.CourseManagementService.data.resourceType === 'demo') {
            return Promise.resolve(blockedDays);
        }

        let url = apiServer.urlPrefix + '/' + this.CourseManagementService.data.resourceType + '/ssp/blocked_days/';
        let data = {
            blocked_days_id: blockedDays.id
        };
        data[(this.CourseManagementService.data.resourceType + '_id')] = this.CourseManagementService.data.resourceId;

        return this.HttpService.delete(url, data)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'delete_blocked_day',
                    url: url
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'delete_blocked_day_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public resetTime(momentObject) {
        // set to midnight of first day of the week (Sunday) relative to momentObject (not relative to today's date)
        return momentObject.day(0).hour(0).minute(0).second(0).millisecond(0);
    }

    public isBetweenOrOn(d, startDate, endDate) {
        return d.isBetween(startDate, endDate) || d.isSame(startDate, 'd') || d.isSame(endDate, 'd');
    }

    public isOnWeekStarting(start, dateToCheck) {
        let end = moment(start).add(6, 'd');
        return dateToCheck.isSameOrAfter(start, 'd') && dateToCheck.isSameOrBefore(end, 'd');
    }

    public getWeekNumByMonth(refrenceDate) {
        let monthStart = moment(refrenceDate).startOf('month');
        let weekStart = moment(monthStart).startOf('week');
        let counter = 0;

        while (weekStart.isBefore(refrenceDate)) {
            counter++;
            weekStart.add(7, 'day');
        }

        return counter - 1;
    }
}
