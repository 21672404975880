// jQuery required for bootstrap v4 and Lightbox2
import * as $ from 'jquery';
import * as Lightbox from 'lightbox2';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
    faLock, faSpinner, faCheck, faExternalLinkAlt, faSync, faUser,
    faAngleDoubleUp, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleLeft,
    faArrowLeft, faArrowRight, faPlus, faMinus, faAsterisk, faEllipsisH,
    faCaretDown, faCaretUp, faCaretRight, faCaretLeft, faBook, faExclamationTriangle,
    faBookmark as faSolidBookmark, faBug, faGift, faExclamation, faSearchMinus,
    faSearchPlus, faBars,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTimesCircle, faEdit, faTrashAlt, faStickyNote, faBookmark as faRegBookmark,
    faStar, faFlag, faQuestionCircle, faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';


export const initializeFontAwesome = () => {
    // Add subset of icons instead of whole library to reduce final bundle size.
    library.add(
        faLock, faSpinner, faCheck, faExternalLinkAlt, faSync, faUser,
        faAngleDoubleUp, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleLeft,
        faArrowLeft, faArrowRight, faPlus, faMinus, faAsterisk, faEllipsisH,
        faCaretDown, faCaretUp, faCaretRight, faCaretLeft, faBook, faExclamationTriangle,
        faTimesCircle, faEdit, faTrashAlt, faStickyNote, faSolidBookmark, faRegBookmark,
        faBug, faGift, faStar, faFlag, faQuestionCircle, faExclamation, faSearchMinus,
        faSearchPlus, faPlusSquare, faBars,
    );
    // Replace any existing <i> tags with <svg> and set up a MutationObserver to
    // continue doing this as the DOM changes.
    dom.watch();
};

export const initializeLightboxConfig = () => {
    // Config lightbox library (image full size preview)
    Lightbox.option({
        'alwaysShowNavOnTouchDevices': true,
        'fadeDuration': 100,
        'resizeDuration': 150,
        'showImageNumberLabel': true,
        'wrapAround': true,
    });
};

export const initializeFontSize = () => {
    const savedFontSize = window.sessionStorage.getItem('font-size');
    if (savedFontSize) {
        (<HTMLElement>document.querySelector(':root')).style.fontSize = savedFontSize;
    }
};
