import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SessionService } from '../../core/service/session.service';
import { UtilsService } from '../../core/service/utils.service';
import { AnalyticsService } from '../../core/service/analytics.service';
import { AssessmentStateService } from '../common/assessment-state.service';
import { CalculatorService } from '../common/calculator/calculator.service';

import './cluster-test-modal.component.scss';


@Component({
    selector: 'cluster-test-modal',
    templateUrl: './cluster-test-modal.component.html',
    // styleUrls: ['./cluster-test-modal.component.scss'],
})
export class ClusterTestModalComponent implements OnInit {

    @Input() trid;
    public currentYear = new Date().getFullYear();

    constructor(
        public activeModal: NgbActiveModal,
        private SessionService: SessionService,
        private UtilsService: UtilsService,
        private AnalyticsService: AnalyticsService,
        public AssessmentStateService: AssessmentStateService,
        private CalculatorService: CalculatorService,
    ) {}

    public ngOnInit() {
        this.UtilsService.blockModalClose('realTest');

        this.AssessmentStateService.data.answerKey = this.AssessmentStateService.data.type === 'preview';

        this.UtilsService.addLoadingOverlay();
        this.AssessmentStateService.startRealTest(this.trid)
            .then((assessmentDetails) => {
                this.resumeSavedTestStatus(assessmentDetails);
                this.navigateToQuestion(this.AssessmentStateService.data.currentQuestion)
            })
            .catch(error => {
                console.warn(error);
                this.UtilsService.unblockModalClose('realTest');
                // Close assessment modal and pass on submit type as the reason
                this.activeModal.dismiss(error);
            })
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private resumeSavedTestStatus(assessmentDetails) {
        this.AssessmentStateService.data.questionStatuses = JSON.parse(assessmentDetails.status)

        if (this.AssessmentStateService.data.questionStatuses && this.AssessmentStateService.data.questionStatuses.length === assessmentDetails.questions) {
            // Resume test on first unanswered question
            this.AssessmentStateService.data.currentQuestion = this.AssessmentStateService.data.questionStatuses
                .findIndex(question => question.sk || !question.vt);

            return null;
        } else {
            // Generate blank test
            this.AssessmentStateService.data.questionStatuses = [...Array(assessmentDetails.questions)].map((_, i) => {
                return {
                    id: i,
                    // skipped
                    sk: true,
                    // submitted
                    sm: false,
                    // bookmarked
                    bm: false,
                    // visited
                    vt: false
                };
            });
        }
    }

    public saveResponseAndNavigateTo(futureQuestion) {
        // Skip saving question if we are previewing the test or if we are in the assessment summary tab
        // In this case, just go ahead and navigate to the next question
        if (this.AssessmentStateService.data.type === 'preview' || this.AssessmentStateService.data.currentQuestion < 0 || this.AssessmentStateService.data.currentQuestion > this.AssessmentStateService.data.maxQuestion) {
            return this.navigateToQuestion(futureQuestion);
        }

        // Collect student's response
        let data = this.AssessmentStateService.collectStudentResponse();

        // If we are in the last part of the question
        // Check if stem is completed (if last stem is complete, question is complete)
        if (this.AssessmentStateService.data.multi.current === this.AssessmentStateService.data.multi.total) {
            let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.question.stems.length - 1];
            let foilResponse = JSON.parse(data.foil);
            if (stem.type === 1 || stem.type === 2) {
                this.AssessmentStateService.data.questionStatuses[this.AssessmentStateService.data.currentQuestion].sk = foilResponse.length === 0;
                this.AssessmentStateService.data.questionStatuses[this.AssessmentStateService.data.currentQuestion].sm = foilResponse.length > 0;
            } else if (stem.type === 3 || stem.type === 4 || stem.type === 6) {
                this.AssessmentStateService.data.questionStatuses[this.AssessmentStateService.data.currentQuestion].sk = foilResponse.length < stem.foils.length;
                this.AssessmentStateService.data.questionStatuses[this.AssessmentStateService.data.currentQuestion].sm = foilResponse.length === stem.foils.length;
            }
        }
        this.AssessmentStateService.data.questionStatuses[this.AssessmentStateService.data.currentQuestion].vt = true;

        return this.AssessmentStateService.submitTestQuestion(data, 'save_response')
            .catch(console.warn)
            .finally(() => {
                this.navigateToQuestion(futureQuestion);
            });
    }

    public bookmarkQuestion(question, questionIndex) {
        question.bm = !question.bm;

        this.AssessmentStateService.bookmarkQuestion(this.AssessmentStateService.data.tr, questionIndex, question.bm, this.AssessmentStateService.data.questionStatuses)
            .catch(console.warn);
    }

    public pauseAssessment() {
        // Save current question response before navigating to assessment summary
        this.saveResponseAndNavigateTo(-1);

        this.AssessmentStateService.data.currentQuestion = -1;
        this.AssessmentStateService.data.question = null;
        this.AssessmentStateService.data.questionCompleted = this.AssessmentStateService.data.questionStatuses.reduce((acc, q) => {
            acc.sk += q.sk ? 1 : 0;
            acc.bm += q.bm ? 1 : 0;

            return acc;
        }, { sk: 0, bm: 0 });

        this.AnalyticsService.assessment({'action': 'pause_assessment', 'trid': this.AssessmentStateService.data.tr});
    }

    public returnToAssessment() {
        let lastSkipped = this.AssessmentStateService.data.questionStatuses.findIndex((q) => {
            return q.sk || !q.vt;
        });

        this.navigateToQuestion(lastSkipped === -1 ? 0 : lastSkipped);
        this.AnalyticsService.assessment({'action': 'back_to_assessment', 'trid': this.AssessmentStateService.data.tr});
    }

    public finishAssessment(submit) {
        let qStatuses, action;

        if (this.AssessmentStateService.data.type !== 'preview') {
            qStatuses = JSON.stringify(this.AssessmentStateService.data.questionStatuses);
        }

        if (submit === 0) {
            action = 'save_assessment';
            this.AssessmentStateService.data.submitText = 'Your test has been saved to finish later.';
        } else if (submit === 1) {
            action = 'finish_assessment';
            this.AssessmentStateService.data.submitText = 'You submitted your test.';
        } else if (submit === 2) {
            action = 'preview_assessment';
        }
        this.AssessmentStateService.data.submitType = submit;

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.finalizeAssessment(action, this.AssessmentStateService.data.assessment, this.AssessmentStateService.data.testlet, this.AssessmentStateService.data.tr, submit, qStatuses)
            .then((result) => {
                this.UtilsService.unblockModalClose('realTest');
                // Broadcast that an assessment has been finished
                this.AssessmentStateService.broadcastAssessmentFinished(result);
                // Close assessment modal and pass on submit type as the reason
                this.activeModal.close(submit);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private navigateToQuestion(seq) {
        if (seq < 0 || seq > this.AssessmentStateService.data.maxQuestion) {
            return false;
        }

        this.AssessmentStateService.data.currentQuestion = seq;
        // Close calculator when switching to a new question
        this.CalculatorService.operate('close', true);

        this.AssessmentStateService.getRealTestQuestion(this.AssessmentStateService.data.assessment, this.AssessmentStateService.data.tr, this.AssessmentStateService.data.currentQuestion)
            .then(this.setPriorResponse.bind(this))
            .catch(console.warn);
    }

    private setPriorResponse(result) {
        let question = result.question;
        let savedResponse = result.response;

        // Current question part depends on how many saved stem responses there are
        // If there are none, then we are on part 1
        // If we are previewing the test, disable user input for foils
        this.AssessmentStateService.data.multi.current = this.AssessmentStateService.data.type !== 'preview' ? (Object.keys(savedResponse).length || 1) : question.stems.length + 1;

        // Set prior response to each stem/foil
        question.stems.forEach(stem => {
            let stemResponse = savedResponse[stem.id];
            if (stemResponse) {
                this.AssessmentStateService.data.multi.isPartAnswered = true;

                if (stem.type === 1) {
                    stem.selected = stemResponse;
                } else {
                    stem.foils.forEach(foil => {
                        let foilResponse = stemResponse[foil.id];
                        if (foilResponse) {
                            foil.selected = stem.type === 2 ? !!foilResponse.response : foilResponse.response;
                            foil.comment = foilResponse.comment;
                        }
                    });
                }
            }
        });
    }
}
