<ngx-loading-bar
    color="#21C2B7"
    [includeSpinner]="false"
    height="3px">
</ngx-loading-bar>

<loading-overlay></loading-overlay>

<browser-warning *ngIf="isIE"></browser-warning>

<coach-marks *ngIf="SessionService.userAccess && route?.data.requiresAuth"></coach-marks>

<global-header id="header" class="dark-theme flex-row align-items-center" *ngIf="route && !route.data.redirect" [route]="route"></global-header>

<global-nav *ngIf="SessionService.userAccess && route?.data.requiresAuth" [route]="route"></global-nav>

<div ui-view class="content-wrapper" [class.slide-right]="isNavOpen" [ngClass]="route?.name + '-body'"></div>

<global-footer id="footer" class="flex-row align-items-center"></global-footer>

<div class="page-reload-toast flex-none align-items-center justify-space-between space-children-row" *ngIf="HttpService.isAppOutdated">
    <div>A new version of MathMapper is now available!</div>
    <button class="btn ghost-button upcase dark-theme" (click)="reloadPage()">Reload</button>
</div>
