import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { SessionService } from '../service/session.service';
import { UtilsService } from '../service/utils.service';
import { AnalyticsService } from '../service/analytics.service';
import { CoachmarksService } from '../coachmarks/coachmarks.service';
import { coachmarksMeta } from '../coachmarks/coachmarks.constant';
import { ModalService } from '../../shared/service/modal.service';
import { LoginService } from '../../login/login.service';

import './header.component.scss';


@Component({
    selector: 'global-header',
    templateUrl: './header.component.html',
    // styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() public route: any;
    @Input() public videoName: any;
    @ViewChild('tourOptOutTrigger', { static: false }) tourOptOutTrigger: NgbPopover;
    @ViewChild('guestLoginTrigger', { static: false }) guestLoginTrigger: NgbPopover;
    @ViewChild('freePracticeLoginTrigger', { static: false }) freePracticeLoginTrigger: NgbPopover;
    public coachmarkState: any;
    public coachmarksMeta: any;
    public coachmarkSubscription: Subscription;

    constructor(
        public SessionService: SessionService,
        private UtilsService: UtilsService,
        private CoachmarksService: CoachmarksService,
        private AnalyticsService: AnalyticsService,
        private ModalService: ModalService,
        private LoginService: LoginService,
    ) {
        // Reference this read-only constant in template
        this.coachmarksMeta = Object.assign({}, coachmarksMeta);
    }

    public ngOnInit() {
        this.coachmarkSubscription = this.CoachmarksService.getCurrentState().subscribe(newState => {
            this.coachmarkState = newState;
            // If they have not opted out of the tour show popover that tour may be accessed through user panel
            if (newState.currentlyViewing === null && this.tourOptOutTrigger && !this.SessionService.userProfile.tour_opt) {
                this.tourOptOutTrigger.open();
            }
        });

        this.LoginService.openGuestLogin$.subscribe(shouldOpen => {
            if (shouldOpen) {
                this.guestLoginTrigger.open();
            } else {
                this.guestLoginTrigger.close();
            }
        });

        this.LoginService.openFreePracticeLogin$.subscribe(shouldOpen => {
            if (shouldOpen) {
                this.freePracticeLoginTrigger.open();
            } else {
                this.freePracticeLoginTrigger.close();
            }
        });
    }

    public ngOnDestroy() {
        this.coachmarkSubscription.unsubscribe();
    }

    public triggerLogin(name: string) {
        if (name == 'guest') {
            if (this.freePracticeLoginTrigger.isOpen()) {
                this.freePracticeLoginTrigger.close();
            }
            this.guestLoginTrigger.toggle();
        } else if (name == 'practice') {
            if (this.guestLoginTrigger.isOpen()) {
                this.guestLoginTrigger.close();
            }
            this.freePracticeLoginTrigger.toggle();
        }
    }

    public toggleSidenav($event) {
        $event.preventDefault();
        $event.stopPropagation();

        const nextValue = !this.UtilsService.isNavOpenSource.value;
        this.UtilsService.isNavOpenSource.next(nextValue);
    }

    public openVideo = (video: string) => {
        this.ModalService.openHowToVideoModal(video);
    }

    public takeTour = (coachMarkIndex) => {
        this.AnalyticsService.action({ action: 'open_coachmarks', index: coachMarkIndex });
        this.coachmarkState.currentlyViewing = coachMarkIndex;
        this.CoachmarksService.updateState(this.coachmarkState);
    }

    public openOptOut = () => {
        this.ModalService.openOptOutModal();
    }

    public checkCoachMarkPopover = () => {
        if (this.tourOptOutTrigger.isOpen()) {
            this.closePopover();
        }
    }

    public closePopover = () => {
        this.AnalyticsService.action({ action: 'close_coachmark_access_tip' });
        this.tourOptOutTrigger.close();
        this.CoachmarksService.updateUserSettings(this.SessionService.userProfile.tour_opt, this.SessionService.userProfile.tour)
            .catch(console.warn);
    }

    public openLogoutModal = () => {
        this.ModalService.openLogoutModal();
    }
}
