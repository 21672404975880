<div class="assessment-manage-page-content page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2 class="page-title">Teacher Hub: Assignments and Reports</h2>
    </div>

    <div class="hr medium-size"></div>

    <div class="flex-row justify-start align-items-center space-children-row medium-space">
        <div class="filter-group body-regular flex-column flex-none">
            <label for="dropdown-school-year" class="bold">School Year</label>
            <ng-select  class="custom body-regular forced-width"
                        [items]="schoolYears"
                        appendTo="body"
                        bindLabel="name"
                        [clearable]="false"
                        groupBy="school_name"
                        [searchable]="false"
                        [(ngModel)]="AssessmentManagementService.models.schoolYear"
                        [disabled]="!schoolYears"
                        (change)="schoolYearSelected($event)"
                        name="schoolYear"
                        placeholder="Select a school year"
                        required>
            </ng-select>
        </div>

        <div class="filter-group body-regular flex-column flex-none">
            <label class="bold">Section</label>
            <ng-select  class="custom body-regular forced-width"
                        [items]="AssessmentManagementService.models.schoolYear?.sections"
                        appendTo="body"
                        bindLabel="name"
                        [clearable]="false"
                        [groupBy]="UtilsService.groupByCourses"
                        [searchable]="false"
                        [(ngModel)]="AssessmentManagementService.models.section"
                        [disabled]="!AssessmentManagementService.models.schoolYear"
                        (change)="sectionSelected($event)"
                        name="section"
                        placeholder="Select a section"
                        required>
            </ng-select>
        </div>
    </div>
</div>

<div class="assessment-manage-page-content page-content bottom container-fluid">

    <div class="flex-column space-children-column align-items-start" *ngIf="schoolYears?.length === 0">
        <h5>You have not been assigned as a teacher to any sections.</h5>
    </div>

    <ui-view></ui-view>
</div>
