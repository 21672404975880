<div class="flex-column space-children-column align-items-start margin-top-1em" *ngIf="triesRecords?.length === 0">
    <h5>There is no student tries activity in this section<span *ngIf="cluster">&nbsp;for the selected cluster</span>.</h5>
</div>

<ng-container *ngIf="triesRecords?.length > 0">
    <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let cluster of triesRecords">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <span class="region-legend-circle" [ngClass]="['region-background-' + cluster.parent.region]"></span>
                        <span>CLU: </span>
                        <span [innerHTML]="cluster.name"></span>
                        <span class="test-count">({{ cluster.tests?.length }})</span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="table-component">
                <!-- Table Header -->
                <div class="flex-row justify-center">
                    <div class="table-header flex-column flex-15 justify-center">Activity Type</div>
                    <div class="table-header flex-column flex-10 justify-center">Grade Band</div>
                    <div class="table-header flex-column flex-30 justify-center">Most Recent Activity Date</div>
                    <div class="table-header flex-column flex-20 justify-center"># of Students Submitted</div>
                    <div class="table-header flex-column flex-15 justify-center">Score (Revised Score)</div>
                    <div class="table-header flex-column flex-10 justify-center">Actions</div>
                </div>
                <!-- Table Assessment Rows -->
                <ng-container *ngFor="let try of cluster.tests">
                    <div class="flex-row justify-center">
                        <div class="table-data test-type flex-column flex-15 justify-center text-center">Student Tries</div>

                        <div class="table-data flex-column flex-10 justify-center text-center">{{ UtilsService.gradeBinToText(try.gradeband) }}</div>

                        <div class="table-data flex-column flex-30 justify-center text-center">{{ try.most_recent | date: dateFormat.shortDate : dateFormat.timezone }}</div>

                        <div class="table-data students-submitted flex-20 justify-space-between align-items-center">
                            <span></span>
                            <span>{{ try.students_taken || 0 }}/{{ AssessmentManagementService.models.section.total_students || 0 }}</span>
                            <div class="test-action text-info" matTooltip="Status Report" (click)="getStudentTestStatus(try, cluster)">
                                <i class="icon icon-doc-text"></i>
                            </div>
                        </div>

                        <div class="table-data flex-column flex-15 justify-center text-center" [innerHTML]="UtilsService.formatClusterScore(try.weight)"></div>

                        <div class="table-data table-actions flex-10 justify-center align-items-center space-children-row">
                            <div class="test-action text-info" matTooltip="See Report" uiSref="heatmap" [uiParams]="{ sectionId: AssessmentManagementService.models.section.id, clusterId: cluster.id, gradeband: try.gradeband }">
                                <i class="icon icon-bar-chart"></i>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
