<div *ngIf="option.isItemAnalysisOpen" class="item-analysis-panel flex-column" dragDrop [enableDrag]="true">
    <div class="item-analysis-header">
        <div class="modal-close" (click)="option.isItemAnalysisOpen = false">
            <i class="icon icon-x"></i>
        </div>

        <span class="panel-title">Question Analysis</span>
    </div>

    <scrolling-buttons [enableButtons]="false" scrollDirection="vertical" class="flex-column flex-auto">
        <div *ngFor="let stem of AssessmentStateService.data.question?.stems; index as stemIndex">
            <mat-accordion [multi]="true">
                <mat-expansion-panel *ngFor="let foilAnalysis of questionAnalysis[stem.id].analysis" [expanded]="foilAnalysis.isAccordionOpen" [disabled]="questionAnalysis[stem.id].analysis.length === 1" [hideToggle]="questionAnalysis[stem.id].analysis.length === 1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="flex-none space-children-row small-space">
                                <span *ngIf="AssessmentStateService.data.multi.total > 1">Part {{ stemIndex + 1 }}:</span>
                                <span class="roman-id">{{ foilToRoman(foilAnalysis.id) }}</span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="table-component">
                        <!-- Stem type 1: Multiple Choice | Stem type 2: Multiple Select -->
                        <div *ngIf="stem.type === 1 || stem.type === 2">
                            <div class="flex-row table-row">
                                <div class="flex-55 table-header">Student Response Pattern</div>
                                <div class="flex-45 table-header flex-column justify-center align-items-center">
                                    <span>Count / Percent</span>
                                    <span>(n = {{ foilAnalysis.total_students }})</span>
                                </div>
                            </div>

                            <div class="flex-row table-row mark-correct" *ngIf="AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top">
                                <div class="flex-55 table-data">{{ foilToRoman(foilAnalysis.correct_response.resp) }}</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.correct_response.count }} ({{ ((foilAnalysis.correct_response.count / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row" [ngClass]="{ 'mark-correct': AssessmentStateService.data.answerKey && isResponseEqual(response.resp, foilAnalysis.correct_response.resp) }" *ngFor="let response of foilAnalysis.top_responses ">
                                <div class="flex-55 table-data">{{ foilToRoman(response.resp) }}</div>
                                <div class="flex-45 table-data justify-center">{{ response.count }} ({{ ((response.count / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row" *ngIf="stem.type !== 1">
                                <div class="flex-55 table-data">Other</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.other_responses - (AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top ? foilAnalysis.correct_response.count : 0) }} ({{ (((foilAnalysis.other_responses - (AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top ? foilAnalysis.correct_response.count : 0)) / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row">
                                <div class="flex-55 table-data">No Response</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.no_responses }} ({{ ((foilAnalysis.no_responses / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>
                        </div>


                        <!-- Stem type 3: Open Ended | Stem type 4: Numeric Response | Stem type 6: 1 Letter -->
                        <div *ngIf="stem.type === 3 || stem.type === 4 || stem.type === 6">
                            <div class="flex-row table-row">
                                <div class="flex-55 table-header">Student Response Pattern</div>
                                <div class="flex-45 table-header flex-column justify-center align-items-center">
                                    <span>Count / Percent</span>
                                    <span>(n = {{ foilAnalysis.total_students }})</span>
                                </div>
                            </div>

                            <div class="flex-row table-row mark-correct" *ngIf="AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top">
                                <div class="flex-55 table-data" [ngClass]="{ upcase: foilAnalysis.type !== 'range' }">{{ foilAnalysis.correct_response.resp }}</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.correct_response.count }} ({{ ((foilAnalysis.correct_response.count / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row" [ngClass]="{ 'mark-correct': AssessmentStateService.data.answerKey && isResponseEqual(response.resp, foilAnalysis.correct_response.resp) }" *ngFor="let response of foilAnalysis.top_responses">
                                <div class="flex-55 table-data" [ngClass]="{ upcase: foilAnalysis.type !== 'range' }">{{ response.resp }}</div>
                                <div class="flex-45 table-data justify-center">{{ response.count }} ({{ ((response.count / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row" [hidden]="foilAnalysis.type === 'range'">
                                <div class="flex-55 table-data">Other</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.other_responses - (AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top ? foilAnalysis.correct_response.count : 0) }} ({{ (((foilAnalysis.other_responses - (AssessmentStateService.data.answerKey && !foilAnalysis.is_correct_in_top ? foilAnalysis.correct_response.count : 0)) / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>

                            <div class="flex-row table-row">
                                <div class="flex-55 table-data">No Response</div>
                                <div class="flex-45 table-data justify-center">{{ foilAnalysis.no_responses }} ({{ ((foilAnalysis.no_responses / foilAnalysis.total_students) || 0) * 100 | number: '1.0-1' }}%)</div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </scrolling-buttons>
</div>
