import { Component, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AssessmentToastService } from './assessment-toast.service';

import './assessment-toast.component.scss';


@Component({
    selector: 'assessment-toast',
    templateUrl: './assessment-toast.component.html',
    // styleUrls: ['./assessment-toast.component.scss'],
    animations: [
        trigger('EnterLeave', [
            state(
                'slideUp',
                // Style when element has entered
                style({ transform: 'translateY(0rem)' })
            ),
            // Start from 5rem and animate to 0rem over 400ms
            transition(':enter', [
                style({ transform: 'translateY(5rem)' }),
                animate('400ms cubic-bezier(0.65, 0.05, 0.36, 1)')
            ]),
            // Animate to 5rem (from 0rem)
            transition(':leave', [
                animate(
                    '400ms cubic-bezier(0.65, 0.05, 0.36, 1)',
                    style({ transform: 'translateY(5rem)' })
                )
            ]),
        ]),
    ],
})
export class AssessmentToastComponent {

    @Output() closed = new EventEmitter();

    constructor(
        public AssessmentToastService: AssessmentToastService,
    ) {}

    public closeToast(reason, event) {
        event.stopPropagation();

        this.AssessmentToastService.closeToast(reason);
        this.closed.emit(reason);
    }
}
