import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SessionService } from '../core/service/session.service';
import { AnalyticsService } from '../core/service/analytics.service';
import { UtilsService } from '../core/service/utils.service';
import { ModalService } from '../shared/service/modal.service';
import { MapService } from '../map/map.service';
import { ResourcerService } from './resourcer.service';

import { ResourceManagementModalComponent } from './resource-management/resource-management-modal.component';

import './resourcer.component.scss';


@Component({
    selector: 'resourcer-page',
    templateUrl: 'resourcer.component.html',
    // styleUrls: ['./resourcer.component.scss'],
})
export class ResourcerComponent implements OnInit {

    public resourcer = {
        items: [],
        count: -1,
        page: {
            current: 1,
            total: 1
        }
    };

    public fields: any;
    public rawFields: string;
    public sources: any;
    public grades: any;
    public tags: any;
    public filters = {
        selection: {
            overall: [],
            paid: 0
        },
        checkboxes: {},
        searchText: ''
    };

    constructor(
        private ngbmodal: NgbModal,
        public SessionService: SessionService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private ModalService: ModalService,
        private MapService: MapService,
        private ResourcerService: ResourcerService,
    ) { }


    public ngOnInit() {
        this.MapService.getLearningMap()
            .then(mapData => {
                // Order by map pattern
                this.fields = [2, 3, 0, 1].map(fieldSeq => mapData.fields[fieldSeq]);
                this.rawFields = JSON.stringify(this.fields);
            })
            .catch(console.warn);

        this.ResourcerService.getFilterGrades()
            .then(result => {
                this.grades = JSON.parse(JSON.stringify(result));
            })
            .catch(console.warn);

        this.ResourcerService.getFilterTags()
            .then(result => {
                this.tags = JSON.parse(JSON.stringify(result));
            })
            .catch(console.warn);

        this.ResourcerService.getResourceAuthors()
            .then(result => {
                this.sources = JSON.parse(JSON.stringify(result));
            })
            .catch(console.warn);

        return this.fetchLinks(1);
    }

    private fetchLinks(page = null) {
        let serverData = this.selectionsToJson();
        this.resourcer.page.current = typeof page === 'number' ? page : this.resourcer.page.current;

        this.UtilsService.addLoadingOverlay();

        return this.ResourcerService.fetchResources(this.resourcer.page.current, serverData)
            .then((result: any) => {
                this.resourcer.items = result.link;
                this.resourcer.count = result.items;
                this.resourcer.page.total = result.page.total;
                this.resourcer.page.current = Math.max(1, Math.min(result.page.current, result.page.total));
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay() );
    }

    private selectionsToJson() {
        let filters = {};
        // Separate filters by type (grade, tag, search...)
        this.filters.selection.overall.forEach(selection => {
            let content = selection.id || selection.name;
            if (filters[selection.type]) {
                filters[selection.type].push(content);
            } else {
                filters[selection.type] = [content];
            }
        });

        // Convert all arrays to string before sending
        for (const key of Object.keys(filters)) {
            filters[key] = JSON.stringify(filters[key]);
        }

        return filters;
    }

    public openMapTagging() {
        const instructions = 'Filter Resources by Map Location (select one or more)';
        let filters = {};
        this.filters.selection.overall.forEach(selection => {
            (filters[selection.type] = filters[selection.type] || []).push(selection.id);
        });

        this.ResourcerService.openMapTaggingModal({
            instructions,
            fields: JSON.parse(this.rawFields),
            filters,
        })
            .then((result) => this.applyMapLocationFilter(result.fields))
            .catch(() => {});
    }

    private applyMapLocationFilter(fields) {
        let overall = this.filters.selection.overall;
        // Clear any map-related filters
        for (let i = overall.length - 1; i >= 0; i--) {
            if (overall[i].type === 'region' || overall[i].type === 'cluster' || overall[i].type === 'construct') {
                overall.splice(i, 1);
            }
        }
        // Go through nested map data and add selected filters to overall array
        fields.forEach(field => {
            field.regions.forEach(region => {
                if (region.isSelected) {
                    overall.push({id: region.id, name: this.UtilsService.stripHTMLTags(region.name), type: 'region'});
                }
                region.clusters.forEach(cluster => {
                    if (cluster.isSelected) {
                        overall.push({id: cluster.id, name: this.UtilsService.stripHTMLTags(cluster.name), type: 'cluster'});
                    }
                    cluster.constructs.forEach(construct => {
                        if (construct.isSelected) {
                            overall.push({id: construct.id, name: this.UtilsService.stripHTMLTags(construct.name), type: 'construct'});
                        }
                    });
                });
            });
        });

        this.fetchLinks(1);
    }

    public searchResourcesWithText() {
        const term = this.filters.searchText.trim();
        if (term) {
            this.filters.selection.overall.push({name: term, type: 'search'});
            // Reset text in search bar
            this.filters.searchText = '';
            this.fetchLinks(1);
        }
    }

    public savePaidSelection(tagId) {
        if (this.filters.selection.paid !== tagId) {
            this.filters.selection.paid = tagId;
            // Clear paid/free filter selection (All)
            this.removeSelectionFromOverall('tag', 52);
            this.removeSelectionFromOverall('tag', 53);

            if (tagId) {
                this.filters.selection.overall.push({id: tagId, name: (tagId === 52 ? 'Free' : 'Paid'), type: 'tag'});
            }
            // Reset the current page to page 1 when a search term is changed
            this.fetchLinks(1);
        }
    }

    public clearFilters() {
        this.filters.selection.overall.splice(0, this.filters.selection.overall.length);

        for (const key of Object.keys(this.filters.checkboxes)) {
            this.filters.checkboxes[key] = false;
        }

        this.fetchLinks(1);
    }

    public removeFilterSelection(type, id) {
        this.removeSelectionFromOverall(type, id);
        this.filters.checkboxes[type + '-id-' + id] = false;
        this.fetchLinks(1);
    }

    public toggleFilterSelection(type, id, name) {
        let isSelected = this.filters.selection.overall
            .find(selection => selection.type === type && selection.id === id);

        if (isSelected) {
            this.removeSelectionFromOverall(type, id);
        } else {
            this.filters.selection.overall.push({id: id, type: type, name: name});
        }

        this.fetchLinks(1);
    }

    private removeSelectionFromOverall(type, id) {
        let selectionIndex = this.filters.selection.overall
            .findIndex(selection => selection.type === type && selection.id === id);

        if (selectionIndex >= 0) {
            this.filters.selection.overall.splice(selectionIndex, 1);
        }
    }

    public openResourceManagerModal(resource = null) {
        this.AnalyticsService.action({'action': 'resource_management_modal_opened', 'resource': resource});

        const modalRef = this.ngbmodal.open(ResourceManagementModalComponent, <any>{
            windowClass: 'resource-management-modal',
            size: 'assessment',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.resource = resource;
        modalRef.componentInstance.fields = JSON.parse(this.rawFields);
        modalRef.componentInstance.sources = JSON.parse(JSON.stringify(this.ResourcerService.sources));
        modalRef.componentInstance.grades = JSON.parse(JSON.stringify(this.ResourcerService.grades));
        modalRef.componentInstance.tags = JSON.parse(JSON.stringify(this.ResourcerService.tags));

        modalRef.result
            .then(updatedLink => this.fetchLinks())
            .catch(() => {});
    }

    public openResourceDeletionModal($event) {
        let modalOptions = {
            actionButtonText: 'Yes, delete',
            closeButtonText: 'No',
            bodyText: 'You are about to delete "' + $event.resource.name + '" resource.<br/>Do you want to proceed?'
        };

        // Open confirmation modal
        this.ModalService.openModal({}, modalOptions)
            .then(() => {
                // Remove resource from view
                this.resourcer.items.splice($event.idx, 1);

                this.UtilsService.addLoadingOverlay();
                // Request for deletion
                this.ResourcerService.deleteResource($event.resource)
                    .catch(console.warn)
                    .finally(() => this.UtilsService.removeLoadingOverlay());
            })
            .catch(() => {});
    }

    public loadNextPage() {
        this.UtilsService.scrollToTop();
        this.fetchLinks();
    }
}
