<div class="flex-column space-children-column align-items-start margin-top-1em" *ngIf="assignedTests?.length === 0">
    <h5>You do not have any tests assigned.</h5>
</div>

<div class="table-component" *ngIf="assignedTests?.length > 0">
    <!-- Table Header -->
    <div class="flex-row justify-center">
        <div class="table-header flex-column flex-20 justify-center">Due Date</div>
        <div class="table-header flex-column flex-40 justify-center">Test/Check</div>
        <div class="table-header flex-column flex-10 justify-center">Grade Band</div>
        <div class="table-header flex-column flex-15 justify-center">Status</div>
        <div class="table-header flex-column flex-15 justify-center">Actions</div>
    </div>
    <!-- Table Assessment Rows -->
    <div *ngFor="let test of assignedTests" class="position-relative test-row">
        <!-- Main assp -->
        <div *ngIf="!test.completed && !test.isExpired" class="flex-row">
            <div class="table-data flex-column flex-20 justify-center text-center" [class.test-due-soon]="test.isDueSoon">
                <span>{{ test.end_date | date: dateFormat.shortDate : dateFormat.timezone }}</span>
                <span class="time-til-due">({{ test.timeTilDue }})</span>
            </div>

            <div class="table-data flex-40 justify-center align-items-center">
                <div *ngIf="test.construct; then constructName else clusterName"></div>
                <ng-template #clusterName>
                    <div class="clucon-name limit-text" (click)="showAsspInMap(test)">
                        <span class="region-legend-circle" [ngClass]="['region-background-' + test.cluster.parent.region]"></span>
                        <span>CLU: </span>
                        <span [innerHTML]="test.cluster.name"></span>
                    </div>
                </ng-template>
                <ng-template #constructName>
                    <div class="clucon-name limit-text" (click)="showAsspInMap(test)">
                        <span class="region-legend-circle" [ngClass]="['region-background-' + test.construct.parent.region]"></span>
                        <span>CON: </span>
                        <span [innerHTML]="test.construct.name"></span>
                        <span>&nbsp;(CLU: <span [innerHTML]="test.cluster.name"></span>)</span>
                    </div>
                </ng-template>
            </div>

            <div class="table-data flex-column flex-10 justify-center text-center">{{ UtilsService.gradeBinToText(test.grade) }}</div>

            <div class="table-data test-status flex-column flex-15 justify-center text-center capitalize">
                <span>{{ (test.testlet_response ? UtilsService.testStatusToText(test.testlet_response.status, test.isExpired) : 'Not Started') }}</span>
            </div>

            <div class="table-data table-actions flex-15 justify-center align-items-center space-children-row">
                <div class="btn btn-link text-info capitalize" *ngIf="test.isAvailable" (click)="getSectionAssessment(test)">{{ test.testlet_response ? 'Resume' : 'Begin' }} Test</div>
                <div class="text-center text-gray" *ngIf="test.isExpired">
                    <span>This test can no longer be started</span>
                </div>
                <div class="text-center text-gray" *ngIf="test.daysTilStart || test.daysTilStart === 0">
                    <span>This test will become available </span>
                    <span [ngPlural]="test.daysTilStart">
                        <ng-template ngPluralCase="0">tomorrow</ng-template>
                        <ng-template ngPluralCase="other">in {{ test.daysTilStart + 1 }} days</ng-template>
                    </span>
                </div>
            </div>
        </div>

        <!-- Retests and rechecks -->
        <!-- <ng-container *ngIf="test.completed"> -->
        <div *ngFor="let retest of test.retest" class="flex-row retest-row">
            <div class="table-data flex-column flex-20 justify-center text-center" [class.test-due-soon]="retest.isDueSoon">
                <span>{{ retest.end_date | date: dateFormat.shortDate : dateFormat.timezone }}</span>
                <span class="time-til-due">({{ retest.timeTilDue }})</span>
            </div>

            <div class="table-data flex-40 justify-center align-items-center">
                <div *ngIf="test.construct; then constructName else clusterName"></div>
                <ng-template #clusterName>
                    <div class="clucon-name limit-text" (click)="showAsspInMap(test)">
                        <span class="region-legend-circle" [ngClass]="['region-background-' + test.cluster.parent.region]"></span>
                        <span>CLU: </span>
                        <span [innerHTML]="test.cluster.name"></span>
                    </div>
                </ng-template>
                <ng-template #constructName>
                    <div class="clucon-name limit-text" (click)="showAsspInMap(test)">
                        <span class="region-legend-circle" [ngClass]="['region-background-' + test.construct.parent.region]"></span>
                        <span>CON: </span>
                        <span [innerHTML]="test.construct.name"></span>
                        <span>&nbsp;(CLU: <span [innerHTML]="test.cluster.name"></span>)</span>
                    </div>
                </ng-template>
            </div>

            <div class="table-data flex-column flex-10 justify-center text-center">{{ UtilsService.gradeBinToText(test.grade) }}</div>

            <div class="table-data test-status flex-column flex-15 justify-center text-center capitalize">
                <span>{{ retest.testlet_response ? UtilsService.testStatusToText(retest.testlet_response.status, retest.isExpired) : 'Not Started' }}</span>
            </div>

            <div class="table-data table-actions flex-15 justify-center align-items-center space-children-row">
                <div class="btn btn-link text-info capitalize" *ngIf="retest.isAvailable" (click)="getSectionAssessment(test, retest.retest_id)">{{ retest.testlet_response ? 'Resume' : 'Begin' }} Test</div>
                <div class="text-center text-gray" *ngIf="retest.isExpired">
                    <span>This test can no longer be resumed</span>
                </div>
                <div class="text-center text-gray" *ngIf="retest.daysTilStart || retest.daysTilStart === 0">
                    <span>This test will become available </span>
                    <span [ngPlural]="retest.daysTilStart">
                        <ng-template ngPluralCase="0">tomorrow</ng-template>
                        <ng-template ngPluralCase="other">in {{ test.daysTilStart + 1 }} days</ng-template>
                    </span>
                </div>
            </div>
        </div>
        <!-- </ng-container> -->
    </div>
</div>

<minimap-panel
    [isOpen]="isMinimapOpen"
    [highlightAssps]="highlightAssps"
    [addDragging]="true"
    (opened)="isMinimapOpen = true;"
    (closed)="isMinimapOpen = false;">
</minimap-panel>
