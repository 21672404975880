import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../core/service/utils.service';
import { AssessmentService } from '../../assessment/assessment.service';
import { AssessmentStateService } from '../../assessment/common/assessment-state.service';

import './preview-test-modal.component.scss';


@Component({
    selector: 'preview-test-modal',
    templateUrl: './preview-test-modal.component.html',
    // styleUrls: ['./preview-test-modal.component.scss'],
})
export class PreviewTestModalComponent implements OnInit {
    @Input() public previewList: any;
    @Input() public assp: any;
    @Input() public retest: any;
    public testformType: string;

    constructor(
        public activeModal: NgbActiveModal,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.testformType = this.UtilsService.testformTypeToText(this.retest ? 9 : 8, !!this.assp.construct);
    }

    public previewAssessment(assessmentId, testformId) {
        this.activeModal.dismiss('opening assessment modal');

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.getAssessmentTestform('preview', assessmentId, testformId, this.retest)
            .then(testInfo => {
                this.AssessmentService.openTestInstructionsModal(testInfo);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
