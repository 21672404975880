import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { selectAll } from 'd3-selection';

import { MapService } from '../map.service';

import './misconception-card.component.scss';


@Component({
    selector: 'misconception-card',
    templateUrl: './misconception-card.component.html',
    // styleUrls: ['./misconception-card.component.scss'],
    host: {
        class: 'misconception-card',
    }
})
export class MisconceptionCardComponent implements OnInit, OnChanges {

    @Input() misconception: any;
    @Input() clickSearch: boolean;
    @ViewChild('flipper', { static: false }) flipper: ElementRef;

    constructor(
        public MapService: MapService,
    ) {}

    public ngOnInit() {
    }

    public ngOnChanges(change: SimpleChanges) {
        // Update card size when a new misconception is assigned to the card
        if (change.misconception.currentValue) {
            this.misconception.longId = String(this.misconception.id).padStart(3, '0');
            this.updateCardHeight();
        }
    }

    private updateCardHeight() {
        if (this.misconception) {
            setTimeout(() => {
                this.flipper.nativeElement.style.height = this.flipper.nativeElement.querySelector(this.misconception.flipped ? '.card-back' : '.card-front').clientHeight + 'px';
            }, this.misconception.heightDelay || 0);
            // Reset height delay back to 0 if not already 0
            this.misconception.heightDelay = 0;
        }
    }

    public flipMisconception($event, misconception) {
        $event.stopPropagation();
        misconception.flipped = !misconception.flipped;
        // Hack so we can animate the height of the parent as we flip the card
        this.updateCardHeight();
    }

    public searchMisconception(misconception) {
        if (this.clickSearch && this.MapService.searchable.meta.type === 'misconceptions') {
            let panelMisc = this.MapService.searchable.data.find(misc => misc.id === misconception.id);

            if (panelMisc) {
                selectAll('.pin-pulse').property('repeat', 0);
                // Update search field and filter items displayed
                this.MapService.searchable.meta.searchText = panelMisc.code;
                this.MapService.filterSearchableData();
                // Select the misconception just clicked and display it in the map
                this.MapService.searchable.meta.selected = panelMisc;
                this.MapService.searchable.meta.displaySelected = true;
                this.MapService.searchable.linkedFunctions.displayPins(panelMisc, 'misconception');
            }
        }
    }
}
