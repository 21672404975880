<div class="class-roster-page-content page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2 class="page-title">Class Roster</h2>
    </div>
</div>

<div class="class-roster-page-content page-content bottom container-fluid">
    <div class="flex-column space-children-column align-items-start" *ngIf="schoolYears?.length === 0">
        <h5>You have not been assigned as a teacher to any sections.</h5>
    </div>

    <div *ngFor="let schoolYear of schoolYears" class="mat-expansion-panel-container mat-expansion-panel-spacing">
        <div class="flex-row align-items-center justify-start space-children-row">
            <h3 class="dark-theme page-subheader reset">{{ schoolYear.school_name }}</h3>
        </div>

        <mat-accordion [multi]="true">
            <mat-expansion-panel *ngFor="let section of schoolYear.sections" (opened)="getSectionRoster(section)" (closed)="closeRoster(section)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="section-name">
                            <span>{{ section.course.name }} - {{ section.name }} ({{ section.code }})</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="table-component" *ngIf="section.roster?.length > 0">
                    <!-- Table Header -->
                    <div class="flex-row justify-center">
                        <div class="table-header flex-column flex-20 justify-center header-left">Name</div>
                        <div class="table-header flex-column flex-20 justify-center">Username</div>
                        <div class="table-header flex-column flex-25 justify-center">Password Last Updated</div>
                        <div class="table-header flex-column flex-35 justify-center">Action</div>
                    </div>
                    <!-- Table Rows -->
                    <div *ngFor="let student of section.roster" class="flex-row justify-center student-row">
                        <div class="table-data flex-column flex-20 justify-center">{{ student.name }}</div>
                        <div class="table-data flex-column flex-20 justify-center text-center">{{ student.username }}</div>
                        <div class="table-data flex-column flex-25 justify-center text-center">{{ (student.pwd_updated | date: dateFormat.shortDate : dateFormat.timezone) || '&mdash;' }}</div>
                        <div class="table-data flex-column flex-35 justify-center text-center">
                            <div class="position-relative">
                                <button class="btn btn-block ghost-button" [ngClass]="{ 'password-changed': student.passwordChanged, active: pwdChangeTrigger.isOpen() }" #pwdChangeTrigger="ngbPopover" [ngbPopover]="studentPasswordChange" [autoClose]="'outside'" container="body" placement="bottom" popoverClass="popover-dropdown pwd-change-popover">{{ student.passwordChanged ? 'Password Changed' : 'Change Password' }}</button>
                                <!-- Password Change Popover -->
                                <ng-template #studentPasswordChange>
                                    <div class="password-reset-container">
                                        <!-- Password Reset Form -->
                                        <form [formGroup]="form" (ngSubmit)="submitPasswordChange(section.id, student)" class="flex-column flex-auto space-children-column">
                                            <div class="form-error-messages text-center" *ngIf="student.passwordResetFailMessage">
                                                <div class="error-message body-regular">{{ student.passwordResetFailMessage }}</div>
                                            </div>

                                            <mat-form-field appearance="standard">
                                                <mat-label>New Password</mat-label>
                                                <input matInput type="password" autocomplete="new-password" formControlName="password" minlength="4" maxlength="75" placeholder="New Password" required />
                                                <mat-error *ngIf="password.errors?.required">
                                                    New password is <strong>required</strong>
                                                </mat-error>
                                                <mat-error *ngIf="password.errors?.minlength">
                                                    Password must be at least 4 characters long
                                                </mat-error>
                                                <mat-error *ngIf="password.errors?.maxlength">
                                                    Password cannot be longer than 75 characters
                                                </mat-error>
                                            </mat-form-field>


                                            <mat-form-field appearance="standard">
                                                <mat-label>Password Confirmation</mat-label>
                                                <input matInput type="password" autocomplete="new-password" formControlName="passwordConfirm" minlength="4" maxlength="75" placeholder="Repeat password" required />
                                                <mat-error *ngIf="passwordConfirm.errors?.required">
                                                    Password confirmation is <strong>required</strong>
                                                </mat-error>
                                                <mat-error *ngIf="passwordConfirm.errors?.minlength">
                                                    Password must be at least 4 characters long
                                                </mat-error>
                                                <mat-error *ngIf="passwordConfirm.errors?.maxlength">
                                                    Password cannot be longer than 75 characters
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-error *ngIf="form.errors?.mismatchedPassword && (password.dirty && passwordConfirm.dirty)" class="no-margin body-xs">
                                                Passwords do not match
                                            </mat-error>

                                            <button class="btn btn-block primary-button alt-theme" type="submit" [disabled]="!form.valid">Submit</button>

                                            <button class="btn btn-block primary-button alt-theme-red" type="button" (click)="closePopover(student)">Cancel</button>
                                        </form>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-column space-children-column align-items-start" *ngIf="section.roster?.length === 0">
                    <h6>There are no students assigned to this section.</h6>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
