import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../shared/shared.module';
import { LoginModule } from '../login/login.module';
import { LandingComponent } from './landing.component';


@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        SharedModule,
        LoginModule,
    ],
    providers: [
    ],
    declarations: [
        LandingComponent,
    ],
})
export class LandingModule { }
