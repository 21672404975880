import {
    Component, OnInit, AfterViewInit,
    OnChanges, SimpleChanges, Input,
    Output, EventEmitter, ViewChild, ElementRef
} from '@angular/core';
import { useAnimation, trigger, state, style, animate, transition } from '@angular/animations';
import { slideAnimation } from '@/_helpers/animations';

import { mapSizesAndBounds } from '@/app.constant';
import { MapService } from '../../../map/map.service';

import './minimap.component.scss';


@Component({
    selector: 'minimap-panel',
    templateUrl: './minimap.component.html',
    // styleUrls: ['./minimap.component.scss'],
    animations: [
        trigger('EnterLeave', [
            state(
                'open',
                // Style when element has entered
                style({ transform: 'translateY(0rem)', opacity: 1, display: 'flex' })
            ),
            state(
                'closed',
                // Style when element has entered
                style({ transform: 'translateY(10rem)', opacity: 0, display: 'none' })
            ),
            // Start from 10rem and animate to 0rem over 500ms
            transition('closed => open', [
                style({ transform: 'translateY(10rem)', opacity: 0, display: 'flex' }),
                animate('500ms cubic-bezier(0.65, 0.05, 0.36, 1)')
            ]),
            // Animate to 10rem (from 0rem)
            transition('open => closed', [
                animate(
                    '500ms cubic-bezier(0.65, 0.05, 0.36, 1)',
                    style({ transform: 'translateY(10rem)', opacity: 0 })
                )
            ]),
        ]),
    ],
})
export class MinimapComponent implements OnInit, AfterViewInit, OnChanges{

    @Input() isOpen: boolean;
    @Input() highlightAssps: any = [];
    @Input() addDragging: boolean;
    @Output() loaded = new EventEmitter();
    @Output() opened = new EventEmitter();
    @Output() closed = new EventEmitter();
    @ViewChild('minimap', { static: false }) minimap: ElementRef;
    public mapData: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;
    public _isOpen = false;

    constructor(
        private MapService: MapService,
        private element: ElementRef,
    ) {}

    public ngOnInit() {
        this.MapService.getLearningMap()
            .then(map => {
                this.mapData = map;
            })
            .catch(console.warn);
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.isOpen && !change.isOpen.firstChange) {
            if (change.isOpen.currentValue) {
                this.openPanel();
            } else {
                this.closePanel();
            }
        }
    }

    public ngAfterViewInit() {
        this.loaded.emit(this.element.nativeElement);
    }

    public openPanel() {
        this._isOpen = true;
        this.opened.emit();
    }

    public closePanel() {
        this.minimap.nativeElement.style.top = '';
        this.minimap.nativeElement.style.left = '';
        this._isOpen = false;
        this.highlightAssps = [];
        this.closed.emit();
    }

    public regionInAssp(region) {
        return this.highlightAssps.some(assp => assp.cluster.parent.region === region.id);
    }

    public constructInAssp(construct) {
        return this.highlightAssps.some(assp => {
            if (assp.construct) {
                return assp.construct.id === construct.id;
            }

            return assp.cluster.id === construct.cluster;
        });
    }
}
