<ng-container *ngIf="isGuest && $state.current.name === 'report'">
    <div class="report-container page-content top">
        <h2 class="page-title flex-none">{{ ReportService.studentName }}'s Reports</h2>
    </div>
    <div class="report-container page-content bottom container-fluid">
        <student-reports></student-reports>
    </div>
</ng-container>

<ui-view></ui-view>

<back-to-top></back-to-top>
