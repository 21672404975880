<div class="modal-body flex-row">
    <div class="modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </div>

    <div class="flex-40 flex-column content-container space-children-column medium-space dark-theme" [ngClass]="'region-background-' + cluster.region">
        <div class="filter-heading">Filter Links by Construct</div>

        <div class="flex-row flex-none align-items-center justify-space-between space-children-row" *ngFor="let construct of cluster.constructs">
            <mat-checkbox [(ngModel)]="construct.filterResources" (change)="filterResourcesByConstruct()" [disableRipple]="true">
                <span [innerHTML]="construct.name" class="wrap"></span>
            </mat-checkbox>
        </div>
    </div>

    <div class="flex-60 flex-column content-container">
        <h4 class="modal-title">Links to External Resources for <span [innerHTML]="cluster.name"></span></h4>

        <div class="flex-column">
            <div *ngIf="selectedResources && selectedResources.length === 0">There are no external resources associated with all of the selected constructs.</div>
            <div *ngFor="let resource of selectedResources">
                <a [href]="resource.url" target="_blank" rel="noopener" class="btn btn-link text-left space-children-row">
                    <span>{{ resource.name }}</span>
                    <i class="icon icon-external-link"></i>
                </a>
            </div>
        </div>
    </div>
</div>
