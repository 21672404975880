import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { StateService } from '@uirouter/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '@/core/service/utils.service';
import { AssessmentService } from '@/assessment/assessment.service';
import { AssessmentStateService } from '@/assessment/common/assessment-state.service';
import { HeatmapService } from '../heatmap.service';

import './heatmap-stack-question.component.scss';


@Component({
    selector: 'heatmap-stack-question',
    templateUrl: './heatmap-stack-question.component.html',
    // styleUrls: ['./heatmap-stack-question.component.scss'],
    host: {
        class: 'heatmap-stack-question',
    },
})
export class HeatmapStackQuestionComponent implements OnInit {

    @Input() stack: any;
    @Input() stackQuestions: any;
    @Input() assp: any;
    @ViewChild('multiStackQuestionTrigger', { static: false }) multiStackQuestionTrigger: NgbPopover;
    public lastQuestionViewed = null;

    constructor(
        private $state: StateService,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
        public HeatmapService: HeatmapService,
    ) {}

    public ngOnInit() {
        this.stack.itemDifficulty = this.stackQuestions.some(item => item.difficulty > 0);
    }

    public selectStack(courseId, sectionId) {
        if (this.stackQuestions.length === 1) {
            this.fetchQuestion(this.stackQuestions[0].id, courseId, sectionId);
        }
    }

    public includesLastQuestionViewed() {
        return this.stackQuestions.some(question => question.id === this.lastQuestionViewed);
    }

    public highlightQuestion(questionId) {
        this.HeatmapService.filters.highlightedQuestion = this.HeatmapService.filters.highlightedQuestion === questionId ? null : questionId;
    }

    public fetchQuestion(questionId, courseId, sectionId) {
        // Close popover due to z-index being visible in modal
        this.multiStackQuestionTrigger.close();
        // Highlight this question on heatmap
        this.lastQuestionViewed = questionId;

        this.UtilsService.addLoadingOverlay();
        this.HeatmapService.getCourseQuestion(questionId, courseId, sectionId)
            .then(question => {
                this.AssessmentStateService.data.assp_id = Number(this.$state.params.asspId);
                this.AssessmentStateService.data.retestId = Number(this.$state.params.retestId);
                this.AssessmentService.loadQuestion(question, { itemAnalysis: true })
                    .finally(() => {
                        // Clean up the assessment data variables that we have modified
                        this.AssessmentStateService.resetAssessment();
                        // Re-open question dropdown to indicate where you last clicked before opening the modal
                        if (this.stackQuestions.length > 1) {
                            this.multiStackQuestionTrigger.open();
                        }
                        // Wrap in timeout because the 'outsideClick' for closing the modal will close the dropdown again
                        setTimeout(() => {
                            // Remove question highlight few seconds after closing the modal
                            this.lastQuestionViewed = null;
                        }, 1500);
                    });
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
