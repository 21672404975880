import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Subscription } from 'rxjs';

import { UtilsService } from '@/core/service/utils.service';

import './loading-overlay.component.scss';


@Component({
    selector: 'loading-overlay',
    templateUrl: './loading-overlay.component.html',
    // styleUrls: ['./loading-overlay.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('100ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('200ms', style({ opacity: 0 }))
            ])
        ]),
    ],
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;
    public isFullScreen: boolean = false;
    private countSub: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
        this.countSub = this.UtilsService.loadingCount$.subscribe(({ requests, isFullScreen }) => {
            this.isLoading = requests >= 1;
            this.isFullScreen = isFullScreen;
            this.cdr.detectChanges();
        });
    }

    public ngOnDestroy() {
        this.countSub.unsubscribe();
    }
}
