import { Component, Output, EventEmitter } from '@angular/core';

import { CalculatorService } from './calculator.service';

import './calculator.component.scss';


@Component({
    selector: 'calculator',
    templateUrl: './calculator.component.html',
    // styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {

    // @Output() closed = new EventEmitter();

    constructor(
        public CalculatorService: CalculatorService,
    ) {}
}
