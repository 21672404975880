<div class="card flex-row align-items-center" *ngFor="let test of userAssessments">
    <button class="btn btn-link" (click)="getUserAssignedAssessment(test.assessment, test.testform)">
        <b class="capitalize">{{ test.type }}</b><span>: {{ test.name }}</span>
    </button>

    <div class="flex-none align-items-center space-children-row small-space">
        <span>(</span>
        <a [href]="apiServer.domain + '/item/data/cluster/get/' + test.cluster.id + '/'" target="_blank" rel="noopener" class="inline-dropdown align-items-center test-link">
            <span class="region-legend-circle spaced" [ngClass]="['region-background-' + test.cluster.parent.region]"></span>
            <span>Cluster {{ test.cluster.id }}</span>
        </a>

        <a [href]="apiServer.domain + '/item/assessment/get/' + test.assessment + '/'" target="_blank" rel="noopener" class="test-link">Assessment {{ test.assessment }}</a>

        <a [href]="apiServer.domain + '/item/testform/get/' + test.testform + '/'" target="_blank" rel="noopener" class="test-link">Testform {{ test.testform }}</a>
        <span>)</span>
    </div>
</div>
