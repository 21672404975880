import { Directive, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';

import { HeatmapService } from '../heatmap.service';


@Directive({
    selector: '[studentMultiselect]',
})
export class StudentMultiselectDirective implements AfterViewInit, OnDestroy {

    @Output() toggleActiveStudent = new EventEmitter<number>();
    private constructHeatmap: HTMLElement;
    private helper: HTMLElement;
    private activeHelper: HTMLElement;
    private startX: number = 0;
    private startY: number = 0;
    // https://stackoverflow.com/questions/33859113/javascript-removeeventlistener-not-working-inside-a-class
    private mousedownHandler = this.mousedown.bind(this);
    private mousemoveHandler = this.mousemove.bind(this);
    private mouseupHandler = this.mouseup.bind(this);

    public constructor(
        private element: ElementRef,
        private HeatmapService: HeatmapService,
    ) {}

    public ngAfterViewInit() {
        this.constructHeatmap = this.element.nativeElement.closest('.heatmap-construct');
        this.helper = document.createElement('div');
        this.helper.classList.add('heatmap-select-helper');

        ['mousedown', 'touchstart'].forEach(eventType => this.element.nativeElement.addEventListener(eventType, this.mousedownHandler, {passive: true}));
    }

    public ngOnDestroy() {
        ['mousedown', 'touchstart'].forEach(eventType => this.element.nativeElement.removeEventListener(eventType, this.mousedownHandler));
    }

    private isElementColliding(box1, box2) {
        return (
            (
                box2.startX <= box1.startX && box1.startX <= box2.endX)
                || (box1.startX <= box2.startX && box2.startX <= box1.endX)
            )
            && (
                (box2.startY <= box1.startY && box1.startY <= box2.endY)
                || (box1.startY <= box2.startY && box2.startY <= box1.endY)
            );
    }

    private getRectCorners(startX, startY, endX, endY) {
        return {
            startX: Math.min(startX, endX),
            endX: Math.max(startX, endX),
            startY: Math.min(startY, endY),
            endY: Math.max(startY, endY)
        };
    }

    private mousedown(event) {
        if (event.shiftKey) {
            this.startX = event.pageX;
            this.startY = event.pageY;

            let helpers = document.querySelectorAll('.heatmap-select-helper');
            // If there are any existing helpers remove them all and cancel any existing mouse listeners
            if (helpers.length > 0) {
                // Remove any multiselect helper boxes
                helpers.forEach(h => h.remove());
            }
            // Append a new selection helper and listen for mouse drag
            this.activeHelper = <HTMLElement>this.helper.cloneNode();
            document.body.appendChild(this.activeHelper);

            ['mousemove', 'touchmove'].forEach(eventType => this.constructHeatmap.addEventListener(eventType, this.mousemoveHandler, {passive: true}));
            ['mouseup', 'touchend'].forEach(eventType => this.constructHeatmap.addEventListener(eventType, this.mouseupHandler, {passive: true}));
        }
    }

    private mousemove(event) {
        let helperBounds = this.getRectCorners(this.startX, this.startY, event.pageX, event.pageY);
        if (this.activeHelper) {
            this.activeHelper.style.top = helperBounds.startY + 'px';
            this.activeHelper.style.left = helperBounds.startX + 'px';
            this.activeHelper.style.width = (helperBounds.endX - helperBounds.startX) + 'px';
            this.activeHelper.style.height = (helperBounds.endY - helperBounds.startY) + 'px';
        }
    }

    private mouseup(event) {
        this.activeHelper.remove();
        this.activeHelper = null;
        // Get selectable elements
        let heatmapUnit = this.element.nativeElement.querySelectorAll('.heatmap-unit-block, .student-initials-container');
        // Filter selectable items (row of blocks) to get only those colliding with selection box
        let intersectBlocks = [];
        heatmapUnit.forEach(heatmapBlockEle => {
            let bcr = heatmapBlockEle.getBoundingClientRect();
            let heatmapBlockBounds = this.getRectCorners(bcr.left, bcr.top, bcr.right, bcr.bottom);
            let selectHelperBounds = this.getRectCorners(this.startX, this.startY, event.pageX, event.pageY);

            if (this.isElementColliding(heatmapBlockBounds, selectHelperBounds)) {
                intersectBlocks.push(heatmapBlockEle);
            }
        });

        // Iterate over those blocks that have been selected
        intersectBlocks.forEach(heatmapBlockEle => {
            let studentId = heatmapBlockEle.getAttribute('data-student-id');
            // If we are selecting multiple students, make the selection add-only
            // Otherwise we are clicking only one student and the selection should be add/remove toggle
            if (intersectBlocks.length > 1 && this.HeatmapService.filters.highlightedStudents.includes(studentId)) {
                return null;
            }

            this.toggleActiveStudent.emit(studentId);
        });


        // Remove listeners
        ['mousemove', 'touchmove'].forEach(eventType => this.constructHeatmap.removeEventListener(eventType, this.mousemoveHandler));
        ['mouseup', 'touchend'].forEach(eventType => this.constructHeatmap.removeEventListener(eventType, this.mouseupHandler));
    }
}
