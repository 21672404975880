import { Component, Input, Output, EventEmitter } from '@angular/core';

import { zoomTransform as d3ZoomTransform } from 'd3-zoom';

import { mapSizesAndBounds } from '@/app.constant';
import { MapService } from '../map.service';

import './map-construct.component.scss';


@Component({
    selector: '[map-construct]',
    templateUrl: './map-construct.component.svg.html',
    // styleUrls: ['./map-construct.component.scss'],
    host: {
        class: 'map-construct',
    },
})
export class MapConstructComponent {

    @Input() construct: any;
    @Input() cluster: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;

    constructor(
        public MapService: MapService,
    ) {}

    public toggleConstruct(construct, $event) {
        $event.stopPropagation();

        if (construct.selected) {
            this.deselectConstruct(construct);
        } else {
            this.selectConstruct(construct);
        }
        this.centerConstructInView(construct);
    }

    private deselectConstruct(construct) {
        construct.selected = false;
        this.MapService.learningMap.stacksPanel.construct = null;
        this.MapService.learningMap.stacksPanel.constructs = null;
        this.MapService.setSearchText('');
    }

    private selectConstruct(construct) {
        // un-darken the previously selected construct, if any
        if (this.MapService.learningMap.stacksPanel.construct) {
            this.MapService.learningMap.stacksPanel.construct.selected = false;
        }
        // darken the clicked construct circle
        construct.selected = true;
        // update the list to match the clicked construct
        this.MapService.learningMap.stacksPanel.construct = construct;
        this.MapService.learningMap.stacksPanel.constructs = this.cluster.constructs;
        this.MapService.setSearchText(construct.name);
    }

    private centerConstructInView(construct) {
        // horizontally center on the construct or cluster according to which panels are open and zoom level
        // vertically center on the cluster
        let zoomContainer = <SVGGraphicsElement>document.querySelector('#zoom-container');
        let currentScale = d3ZoomTransform(zoomContainer).k;
        let availableWidthInPixels = window.innerWidth - (this.MapService.searchable.meta.isOpen ? 1 : 0) * 370 - (this.MapService.learningMap.stacksPanel.construct ? 1 : 0) * 320;
        let availableWidthInMapCoords = this.pixelsToMapCoord(availableWidthInPixels);
        let offsetToMissOnePanel = Math.max(0, -(this.pixelsToMapCoord(window.innerWidth / 2 - 350) - mapSizesAndBounds.cluster.width / 2)) * currentScale;

        let offsetToAvoidPanels = (this.MapService.searchable.meta.isOpen ? 1 : 0) * offsetToMissOnePanel + (this.MapService.learningMap.stacksPanel.construct ? 1 : 0) * -offsetToMissOnePanel;
        let centerX = (availableWidthInMapCoords < mapSizesAndBounds.cluster.width * 0.7 ? construct.attributes.cx : this.cluster.attributes.cx); // center on teh cluster unless there isn't enough room betwee panels

        if (this.MapService.learningMap.option.currentZoomLevel <= 2) {
            currentScale = mapSizesAndBounds.width / mapSizesAndBounds.viewSizes[3].w;
            this.MapService.learningMap.option.currentZoomLevel = 3;
        }

        let x = mapSizesAndBounds.width / 2 - currentScale * centerX + offsetToAvoidPanels;
        let y = mapSizesAndBounds.height / 2 - currentScale * this.cluster.attributes.cy; // center on the cluster, not on the construct

        this.MapService.zoomChange.next({ level: currentScale, x: x, y: y, duration: 800, transform: true });
    }

    private pixelsToMapCoord(lengthPixels) {
        let zoomElement = <SVGGraphicsElement>document.querySelector('#zoom-element'); // this element has the necessary scaling we need for this calculation
        let matrix = zoomElement.getScreenCTM(); // we need the inverse of this
        let lengthMapCoords = lengthPixels / matrix.a;

        return lengthMapCoords;
    }
}

