import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';
import { StateService } from '@uirouter/core';

import { captchaSiteKeyV2, captchaSiteKeyV3 } from '@/app.constant';
import { LoginService } from '../login.service';
import { AuthService } from '../../core/service/auth.service';
import { UtilsService } from '../../core/service/utils.service';

import './login-form.component.scss';


@Component({
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    // styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnChanges {

    @Input() loginMessage: string = 'Log in';
    @Input() loginType: string = 'guest';
    @Input() captcha: boolean;
    @ViewChild('captchaElement', { static: false }) captchaElement: ReCaptcha2Component;

    public readonly captchaSiteKeyV2 = captchaSiteKeyV2;
    public loginForm: FormGroup;
    public schools: any;
    public loginFailMessage: string;
    public loading: boolean = false;
    public loadingSchools: boolean = false;

    get username() { return this.loginForm.get('username'); }
    get password() { return this.loginForm.get('password'); }

    constructor(
        private fb: FormBuilder,
        private $state: StateService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        public LoginService: LoginService,
        private AuthService: AuthService,
        private UtilsService: UtilsService,
    ) {
        this.loginForm = this.fb.group({
            username:  ['', [Validators.required, Validators.maxLength(150)]],
            password:  ['', Validators.required],
            school:    [null],
            recaptcha: ['', Validators.required],
        });
    }

    public ngOnInit() {
        if (this.$state.current.name && this.$state.current.name !== 'landing') {
            this.loadingSchools = true;
            this.AuthService.getSchools()
                .then(result => {
                    this.schools = result.schools;
                })
                .catch(console.warn)
                .finally(() => this.loadingSchools = false);
        }

        if (this.loginType == 'free-practice') {
            this.loginForm.get('school').setValue(1);
        }
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.captcha && change.captcha.currentValue !== change.captcha.previousValue) {
            this.toggleCaptchaValidation();
        }
    }

    public toggleCaptchaValidation() {
        if (this.captcha) {
            this.loginForm.get('recaptcha').enable();
        } else {
            this.loginForm.get('recaptcha').disable();
        }
    }

    public resetFormErrors() {
        this.loginFailMessage = null;
    }

    public showForm($event, formName) {
        $event.stopPropagation();
        this.resetFormErrors();
        this.LoginService.activeForm = formName;
    }

    public showRegistration($event) {
        if (this.loginType == 'free-practice') {
            this.$state.go('practice_register');
        } else {
            this.showForm($event, 'registration');
        }
    }

    public submitLoginInfo($event) {
        $event.stopPropagation();

        this.UtilsService.addLoadingOverlay(true);
        this.reCaptchaV3Service.execute(captchaSiteKeyV3, 'login', token => {
            this.AuthService.login(this.loginForm.value, token)
                .then(() => {
                    this.loginForm.reset();
                })
                .catch(error => {
                    if (error.result && error.result.msg) {
                        this.captcha = error.result.msg.captcha;
                        this.toggleCaptchaValidation();
                        this.loginFailMessage = error.result.msg.msg || 'Sorry, the email or password you submitted is not correct';
                    }
                    // Only reset captcha on fail because on success component is destroyed
                    if (this.captchaElement) {
                        this.captchaElement.resetCaptcha();
                    }
                })
                .finally(() => {
                    this.UtilsService.removeLoadingOverlay();
                    // Clear username and captcha field, but keep username
                    this.loginForm.patchValue({
                        password: '',
                        recaptcha: '',
                    });
                });
        });
    }
}
