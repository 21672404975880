import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '@/core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { ModalService } from '@/shared/service/modal.service';
import { AssessmentManagementService } from '../assessment-management.service';

// import './student-practice-activity.component.scss';


@Component({
    selector: 'student-practice-activity',
    templateUrl: './student-practice-activity.component.html',
    // styleUrls: ['./student-practice-activity.component.scss'],
})
export class StudentPracticeActivityComponent implements OnInit, OnChanges {

    @Input() public cluster: any;
    public practiceRecords: any;

    public readonly dateFormat = dateFormat;

    constructor(
        public UtilsService: UtilsService,
        private ModalService: ModalService,
        public AssessmentManagementService: AssessmentManagementService,
    ) {}

    public ngOnInit() {
        // Wrap in timeout because section-assessment.component is also async (@TODO: decouple)
        setTimeout(() => {
            this.fetchTests();
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        // Refresh view when cluster is changed but not when component is first initialized
        if (change.cluster && change.cluster.currentValue && !change.cluster.firstChange) {
            this.fetchTests();
        }
    }

    private fetchTests() {
        if (this.AssessmentManagementService.models.section) {
            let params = {
                section_id: this.AssessmentManagementService.models.section.id,
                type: 'practice',
            };

            if (this.cluster) {
                params['cluster_id'] = this.cluster.id;
            }

            // Get tests for the section, group under clusters
            this.UtilsService.addLoadingOverlay();
            this.AssessmentManagementService.getTestsForSection(params)
                .then((result: any) => {
                    this.practiceRecords = result;
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    public getConstructPractice(practiceRec) {
        if (!practiceRec.studentRoster) {
            this.UtilsService.addLoadingOverlay();
            this.AssessmentManagementService.getTestsForSection({
                section_id: this.AssessmentManagementService.models.section.id,
                type: 'practice',
                construct_id: practiceRec.construct.id,
            })
                .then((result: any) => {
                    practiceRec.studentRoster = result.practice_sessions;
                    practiceRec.studentRoster.tests.forEach(stu => {
                        // Unpack practice score properties to allow sorting by this prop
                        if (stu.p_score) {
                            Object.assign(stu, stu.p_score);
                        }
                    });

                    // Pre-sort table by most recent test practiced
                    practiceRec.studentRoster.sortExpression = 'last_test_date';
                    practiceRec.studentRoster.sortReverse = false;
                    this.sortBy(practiceRec.studentRoster, 'last_test_date');

                    // Save construct roster to keep construct practice data if switching tabs
                    this.AssessmentManagementService.lastTabPracticeData = this.practiceRecords
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    public sortBy(roster, prop) {
        roster.sortReverse = roster.sortExpression === prop ? !roster.sortReverse : false;
        roster.sortExpression = prop;
        roster.tests = this.UtilsService.sortByProp(roster.tests, prop, roster.sortReverse, true);
    }
}
