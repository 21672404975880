<div *ngIf="CourseManagementService.data.resourceType === 'demo' && !isDemoBannerCollapsed">
    <div class="sequencer-demo-banner flex-row align-items-center">
        <div>This is a playground for demonstration purposes. Any changes made will be deleted at the end of your session.</div>
        <div class="modal-close" (click)="isDemoBannerCollapsed = true;">
            <i class="icon icon-x"></i>
        </div>
    </div>
</div>

<div class="sequencer-container page-content top">
    <div class="flex-row justify-space-between align-items-center space-children-row">
        <h2 class="page-title flex-none">
            <span>{{ resource?.code }}</span>
            <span *ngIf="resource?.name"> - {{ resource?.name }}</span>
        </h2>

        <div class="flex-none justify-end align-items-center space-children-row" *ngIf="!SequencerStateService.data.isBeingEdited">
            <button class="btn ghost-button" (click)="viewCalendar()">View Calendar</button>
            <button class="btn primary-button" *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp?.editable) || (CourseManagementService.data.resourceType === 'section' && (resource?.permissions.ssp && resource?.teacher || SessionService.userAccess?.permission >= 16 || SessionService.userAccess?.teacher_roles?.length))" (click)="editScopeAndSequence()">Edit</button>
        </div>

        <div class="flex-none justify-end align-items-center space-children-row" *ngIf="SequencerStateService.data.isBeingEdited">
            <button class="btn ghost-button" (click)="cancelEditingScopeAndSequence()">Cancel</button>
            <button class="btn primary-button" (click)="saveScopeAndSequence()">Save</button>
        </div>
    </div>
</div>

<div class="sequencer-container page-content bottom container-fluid">
    <sequencer></sequencer>
</div>
