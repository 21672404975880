import { Component, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { AssessmentStateService } from '../../../assessment/common/assessment-state.service';

import './scrolling-buttons.component.scss';


@Component({
    selector: 'scrolling-buttons',
    templateUrl: './scrolling-buttons.component.html',
    // styleUrls: ['./scrolling-buttons.component.scss'],
    host: {
        class: 'scrolling-buttons',
    },
})
export class ScrollingButtonsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() enableButtons: boolean;
    @Input() componentClass: string;
    @Input() maxHeight: string;
    @Input() scrollDirection: string = 'vertical';
    @Output() scrollBottom = new EventEmitter();

    @ViewChild('barTop', { static: false }) barTop: ElementRef;
    @ViewChild('barBottom', { static: false }) barBottom: ElementRef;
    @ViewChild('panelScrollable', { static: false }) panelScrollable: ElementRef;

    // private animationInProgress: boolean = false;
    private scrollOffset: number;
    private scrollHandler = this.toggleButtons.bind(this);

    constructor(
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.AssessmentStateService.stackLadderInit$.subscribe(ladderData => {
            // Number of levels to scroll down
            const levelsScrolled: number = ladderData.totalLevels - ladderData.startingLevel;
            this.scrollOffset = ladderData.ladderBlock.offsetHeight - ladderData.ladderBlock.clientHeight;
            // Scroll to the currently selected ladder level (it being at the top of the 1+ displayed levels)
            this.scroll(this.scrollOffset + ladderData.ladderBlock.offsetHeight * levelsScrolled);
        });
    }

    public ngAfterViewInit() {
        if (this.componentClass) {
            let c = this.componentClass.split(' ');
            this.element.nativeElement.classList.add(...c);
        }
        // Show and hide the buttons when we reach the top or bottom of the scrollable area
        this.panelScrollable.nativeElement.addEventListener('scroll', this.scrollHandler);
        // Trigger for the first time to update the panel after inner elements have loaded
        this.toggleButtons();
    }

    public ngOnDestroy() {
        this.panelScrollable.nativeElement.removeEventListener('scroll', this.scrollHandler);
    }

    private toggleButtons() {
        if (this.barTop && this.barBottom) {
            // We're at the top of the scrollable area
            if (this.panelScrollable.nativeElement.scrollTop <= 0) {
                this.barTop.nativeElement.classList.remove('show-bar');
                this.barBottom.nativeElement.classList.toggle('show-bar', this.panelScrollable.nativeElement.clientHeight < this.panelScrollable.nativeElement.scrollHeight);
            }
            // We've reached the bottom of the scrollable area
            if (this.panelScrollable.nativeElement.scrollTop >= this.panelScrollable.nativeElement.scrollHeight - this.panelScrollable.nativeElement.clientHeight) {
                this.barTop.nativeElement.classList.toggle('show-bar', this.panelScrollable.nativeElement.clientHeight < this.panelScrollable.nativeElement.scrollHeight);
                this.barBottom.nativeElement.classList.remove('show-bar');
                this.scrollBottom.emit();
            }
            // We're in between
            if (this.panelScrollable.nativeElement.scrollTop > 0 && this.panelScrollable.nativeElement.scrollTop < this.panelScrollable.nativeElement.scrollHeight - this.panelScrollable.nativeElement.clientHeight) {
                this.barTop.nativeElement.classList.add('show-bar');
                this.barBottom.nativeElement.classList.add('show-bar');
            }
        }
        this.cdr.detectChanges();
    }

    public scrollup() {
        let newScrollTop = this.panelScrollable.nativeElement.scrollTop - this.panelScrollable.nativeElement.clientHeight;
        if (this.panelScrollable.nativeElement.scrollTop > (this.panelScrollable.nativeElement.scrollHeight - this.panelScrollable.nativeElement.clientHeight - this.scrollOffset)) {
            newScrollTop -= this.scrollOffset;
        }
        this.scroll(newScrollTop);
    }

    public scrolldown() {
        let newScrollTop = this.panelScrollable.nativeElement.scrollTop + this.panelScrollable.nativeElement.clientHeight;
        if (this.panelScrollable.nativeElement.scrollTop < this.scrollOffset) {
            newScrollTop += this.scrollOffset;
        }
        this.scroll(newScrollTop);
    }

    public scrollLeft() {
        let newScrollLeft = this.panelScrollable.nativeElement.scrollLeft - this.panelScrollable.nativeElement.clientWidth;
        if (this.panelScrollable.nativeElement.scrollLeft > (this.panelScrollable.nativeElement.scrollHeight - this.panelScrollable.nativeElement.clientWidth - this.scrollOffset)) {
            newScrollLeft -= this.scrollOffset;
        }
        this.scroll(null, newScrollLeft);
    }

    public scrollRight() {
        let newScrollLeft = this.panelScrollable.nativeElement.scrollLeft + this.panelScrollable.nativeElement.clientWidth;
        if (this.panelScrollable.nativeElement.scrollLeft < this.scrollOffset) {
            newScrollLeft += this.scrollOffset;
        }
        this.scroll(null, newScrollLeft);
    }

    private scroll(newScrollTop = 0, newScrollLeft = 0) {
        // if (this.animationInProgress) { return false; }
        // this.animationInProgress = true;

        if (this.panelScrollable.nativeElement.scrollTop !== newScrollTop) {
            this.panelScrollable.nativeElement.scrollTop = newScrollTop;
        }

        if (this.panelScrollable.nativeElement.scrollLeft !== newScrollLeft) {
            this.panelScrollable.nativeElement.scrollLeft = newScrollLeft;
        }
    }
}
