<div class="profile-page-content page-content top">
    <h2 class="page-title">Profile</h2>
</div>

<div class="profile-page-content page-content bottom container-fluid">
    <h6 class="mb-4">Manage all your settings and preferences in one place.</h6>

    <div class="card centered-card">
        <div class="card-header bold">Account Information</div>

        <div class="card-body">
            <div class="profile-item flex-row align-items-center">
                <div class="flex-40 profile-item-description">First Name</div>
                <mat-form-field appearance="standard" class="flex-60 simple-form-field">
                    <!-- <mat-label>First Name</mat-label> -->
                    <input matInput type="text" name="first_name" [(ngModel)]="profileData.first_name" [disabled]="!isEditing" />
                </mat-form-field>
            </div>

            <div class="hr small-size"></div>

            <div class="profile-item flex-row align-items-center">
                <div class="flex-40 profile-item-description">Last Name</div>
                <mat-form-field appearance="standard" class="flex-60 simple-form-field">
                    <!-- <mat-label>Last Name</mat-label> -->
                    <input matInput type="text" name="last_name" [(ngModel)]="profileData.last_name" [disabled]="!isEditing" />
                </mat-form-field>
            </div>

            <div class="hr small-size"></div>

            <div class="profile-item flex-row align-items-center">
                <div class="flex-40 profile-item-description">Email</div>
                <mat-form-field appearance="standard" class="flex-60 simple-form-field">
                    <!-- <mat-label>Email</mat-label> -->
                    <input matInput type="email" name="email" [(ngModel)]="profileData.email" [disabled]="!isEditing" />
                </mat-form-field>
            </div>

            <div class="hr small-size"></div>

            <div class="profile-item flex-row align-items-center">
                <div class="flex-40 profile-item-description">Opted Out</div>
                <div class="flex-60 flex-column">
                    <mat-checkbox [(ngModel)]="profileData.tour_opt" [disabled]="!isEditing">Guided Tour</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="card-footer flex-row align-items-center justify-end space-children-row">
            <button class="btn btn-link text-info" *ngIf="!isEditing" (click)="editProfile()">Edit Profile</button>
            <button class="btn ghost-button" *ngIf="isEditing" (click)="cancelEdit()">Cancel</button>
            <button class="btn primary-button" *ngIf="isEditing" (click)="saveEdit()">Save</button>
        </div>
    </div>
</div>
