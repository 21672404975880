<div class="report-container page-content top">
    <h2 class="page-title flex-none">Assessment Results</h2>
</div>

<div class="report-container page-content container-fluid">
    <ng-container *ngIf="ReportService.models.test">
        <div class="flex-row justify-space-between space-children-row big-space margin-top-1em">
            <div class="flex-column flex-none">
                <div class="flex-none align-items-center space-children-row small-space">
                    <label class="bold">Activity:</label>
                    <span>{{ ReportService.models.test.status === 3 ? ('Grade ' + this.UtilsService.gradeBinToText(ReportService.models.test.grade) + ' ' + this.UtilsService.testformTypeToText(ReportService.models.test.type)) : 'Practice Test' }}</span>
                </div>

                <div class="flex-none align-items-center space-children-row small-space">
                    <label class="bold">Date Submitted:</label>
                    <span>{{ ReportService.models.test.date | date : dateFormat.shortDate : dateFormat.timezone }}</span>
                </div>
            </div>

            <div class="flex-column flex-none align-items-center cluster-score-container" *ngIf="(ReportService.models.test.status === 3 || ReportService.models.test.status === 12)">
                <span class="score-text bold">My Score</span>
                <div class="cluster-scores flex-column flex-none align-items-end justify-center">
                    <div class="limit-text space-children-row small-space">
                        <span class="body-regular">Original:</span>
                        <span class="score" [innerHTML]="UtilsService.formatClusterScore(ReportService.models.test.score?.initial, '- -')"></span>
                    </div>
                    <div
                        class="limit-text space-children-row small-space"
                        *ngIf="ReportService.models.test.score?.has_revised">
                        <span class="body-regular">Revised:</span>
                        <span class="score" [innerHTML]="UtilsService.formatClusterScore(ReportService.models.test.score.revised, '- -')"></span>
                    </div>
                </div>
            </div>

            <div class="flex-column flex-none align-items-center cluster-score-container" *ngIf="(ReportService.models.test.status === 4 || ReportService.models.test.status === 5) && ReportService.models.test.p_score?.ss !== null">
                <span class="score-text bold">My Scores</span>
                <div class="cluster-scores flex-column flex-none align-items-end justify-center">
                    <div class="practice-score-container flex-none align-items-baseline justify-space-between limit-text space-children-row">
                        <span class="practice-score-title body-regular" matTooltip="Success Score - Sum of all the points divided by the total number of problems worked during practice." matTooltipPosition="above">SS <i class="icon icon-info text-info"></i></span>
                        <span class="score">{{ ReportService.models.test.p_score.ss.toFixed() }}%</span>
                    </div>
                    <div
                        class="practice-score-container flex-none align-items-baseline justify-space-between limit-text space-children-row">
                        <span class="practice-score-title body-regular" matTooltip="Worked Problems Index - Count of the problems you have worked with additional credit added for working harder levels." matTooltipPosition="above">WPI <i class="icon icon-info text-info"></i></span>
                        <span class="score">{{ ReportService.models.test.p_score.wpi }}</span>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="(ReportService.models.test.status === 3 || ReportService.models.test.status === 12)">
            <div *ngIf="resultsByConstruct && resultsByConstruct.display">
                <div class="flex-row align-items-center justify-space-between">
                    <h4 class="dark-theme page-subheader reset">My Results by Construct</h4>
                </div>

                <div class="construct-graphs-container flex-row align-items-stretch" [ngClass]="['pattern-level-' + resultsByConstruct.pattern.length]">
                    <div class="percentage-test-scores whiteframe flex flex-column">
                        <cluster-graph
                            [fullName]="true"
                            [testScores]="constScore"
                            [cluster]="resultsByConstruct">
                        </cluster-graph>

                        <cluster-graph-legend
                            [cluster]="resultsByConstruct"
                            [scoresToggling]="true">
                        </cluster-graph-legend>
                    </div>

                    <stack-panel
                        [construct]="resultsByConstruct.selectedConstruct"
                        [constructsList]="resultsByConstruct.constructs"
                        [enableLadder]="true"
                        [enableStackColors]="true"
                        [enablePracticeTest]="!isTeacherViewing"
                        (closed)="resultsByConstruct.selectedConstruct = null"
                        (change)="resultsByConstruct.selectedConstruct = $event">
                    </stack-panel>
                </div>

                <div class="hr"></div>
            </div>

            <div class="flex-row align-items-center justify-space-between">
                <h4 class="dark-theme page-subheader reset">My Scores by Question and Construct</h4>
            </div>

            <question-breakdown
                [matrix]="questionBreakdown"
                [cluster]="resultsByConstruct"
                [testScores]="constScore"
                (viewQuestion)="viewQuestion($event)">
            </question-breakdown>

            <feedback-popup
                *ngIf="!isTeacherViewing"
                [questions]="resultsByConstruct?.feedback"
                page="report"
                [trid]="resultsByConstruct?.trid"
                [assp]="resultsByConstruct?.a_ssp"
                [delay]="60000">
            </feedback-popup>
        </ng-container>

        <ng-container *ngIf="(ReportService.models.test.status === 4 || ReportService.models.test.status === 5)">
            <div class="flex-row align-items-center justify-space-between">
                <h4 class="dark-theme page-subheader reset">Practice Session Summary</h4>
            </div>

            <div class="whiteframe p-3 flex flex-column">
                <div *ngIf="highlightedConstruct; then ladderScores else noLadderMsg"></div>
                <ng-template #ladderScores>
                    <div class="construct-name-container">
                        <span>Construct: </span>
                        <span class="bold" [innerHTML]="highlightedConstruct.name"></span>
                    </div>

                    <div class="flex-row justify-space-between">
                        <div class="practice-cluster-container">
                            <cluster-graph
                                *ngIf="resultsByConstruct"
                                [cluster]="resultsByConstruct"
                                [connectors]="true"
                                [singleLetter]="false"
                                [highlightedConstruct]="highlightedConstruct">
                            </cluster-graph>
                        </div>

                        <stack-ladder
                            [construct]="highlightedConstruct"
                            [sessionSummary]="true"
                            [formatStacks]="true"
                            [truncateChars]="300"
                            (viewQuestionByIdx)="viewQuestion($event)">
                        </stack-ladder>

                        <span class="practice-cluster-container"></span>
                    </div>
                </ng-template>

                <ng-template #noLadderMsg>
                    <div>This practice report is no longer available. Please try a different selection.</div>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
</div>
