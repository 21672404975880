<div class="modal-body">
    <div class="modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </div>

    <calendar-navigation *ngIf="isMonth" (navigate)="CalendarService.changeMonth($event)" [title]="CalendarService.calendar.weeks.month.format('MMMM YYYY')"></calendar-navigation>
    <calendar-navigation *ngIf="!isMonth" (navigate)="CalendarService.changeWeek($event)" [title]="'Week ' + CalendarService.calendar.week.weekNum + ' (' + CalendarService.calendar.week.monthString + ' ' + CalendarService.calendar.week.yearNum + ')'"></calendar-navigation>

    <div class="flex-row space-children-row justify-center pad-bottom">
        <label for="week-month-toggle-input">Week</label>
        <mat-slide-toggle id="week-month-toggle" [disableRipple]="true" [(ngModel)]="isMonth">Month</mat-slide-toggle>
    </div>

    <div class="calendar-week">
        <div class="calendar-day text-center" *ngFor="let dayOfWeek of daysOfWeek; first as isFirst; last as isLast" [class.weekend]="isFirst || isLast">{{ dayOfWeek }}</div>
    </div>

    <calendar-days [isMonth]="isMonth"></calendar-days>

    <div class="flex-row justify-space-between align-items-center">
        <div class="legend flex-none align-items-center space-children-row medium-space">
            <span>
                <i class="icon icon-test"></i>
                <span>Test Assigned</span>
            </span>

            <span class="flex-none align-items-center">
                <span class="region-legend-circle bg-info small-margin"></span>
                <span>Today</span>
            </span>
        </div>

        <div class="summary-text"><b>{{ daysScheduled || 0 }}</b> days scheduled of {{ totalCalendarDays }} total</div>
    </div>
</div>
