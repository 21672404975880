import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'terms-of-use-modal',
    templateUrl: './terms-of-use-modal.component.html'
})
export class TOUModalComponent {

    constructor(public activeModal: NgbActiveModal) {}
}
