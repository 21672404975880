import { Component, OnInit } from '@angular/core';

import { SessionService } from '@/core/service/session.service';
import { UtilsService } from '@/core/service/utils.service';
import { GrowlerService } from '@/core/service/growler.service';
import { ProfileService } from './profile.service';

import './profile.component.scss';


@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    // styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

    public isEditing = false;
    public profileData: any;
    public originalProfile: any;

    constructor(
        private SessionService: SessionService,
        private UtilsService: UtilsService,
        private GrowlerService: GrowlerService,
        private ProfileService: ProfileService,
    ) {}

    public ngOnInit() {
        this.profileData = Object.assign({}, this.SessionService.userProfile);
        this.originalProfile = JSON.stringify(this.profileData);
    }

    public editProfile() {
        this.isEditing = true;
    }

    public cancelEdit() {
        this.isEditing = false;
        this.profileData = JSON.parse(this.originalProfile);
    }

    public saveEdit() {
        this.UtilsService.addLoadingOverlay();
        this.ProfileService.updateUserProfile(this.profileData)
            .then(savedProfile => {
                this.isEditing = false;
                this.GrowlerService.success('Your account information has been updated!', 'Thank you');
                this.profileData = savedProfile;
                this.originalProfile = JSON.stringify(this.profileData);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
