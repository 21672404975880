import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../../../../core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { CalendarService } from '../../calendar.service';


@Component({
    selector: 'blocked-day-modal',
    templateUrl: './blocked-day-modal.component.html'
})
export class BlockedDayModalComponent implements OnInit {

    @Input() day: any;
    public blockedDays: any;

    constructor(
        public activeModal: NgbActiveModal,
        private UtilsService: UtilsService,
        private CalendarService: CalendarService,
    ) {}

    public ngOnInit() {
        this.blockedDays = {
            start_date: this.day.date.toDate(),
            end_date: this.day.date.toDate(),
            note: '',
        };
    }

    public addBlockedDays() {
        let data = {
            start_date: dateToString(this.blockedDays.start_date),
            end_date: dateToString(this.blockedDays.end_date),
            note: this.blockedDays.note
        };

        this.UtilsService.addLoadingOverlay(true);
        this.CalendarService.createBlockedDay(data)
            .then((result) => {
                this.activeModal.close(result);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
