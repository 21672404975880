<div *ngIf="SchoolReportService.isGraphReset && this.SchoolReportService.isGraphEmpty" class="p-2 text-center">
    No test data for these selections
</div>

<div *ngIf="!SchoolReportService.isGraphReset" class="text-center">
    <span [innerHTML]="SchoolReportService.title"></span>
</div>

<plotly-plot
    id="box-plot"
    *ngIf="!SchoolReportService.isGraphReset"
    [data]="barChartData"
    [layout]="SchoolReportService.graphLayout"
    [config]="SchoolReportService.graphConfig"
    [useResizeHandler]="true"
    [divId]="'school-report-chart'"
    (legendClick)="SchoolReportService.hideLegend($event)">
</plotly-plot>
