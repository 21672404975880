import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { MapService } from '../map.service';
import { AnalyticsService } from '../../core/service/analytics.service';

import './map-options.component.scss';


@Component({
    selector: 'map-options',
    templateUrl: './map-options.component.html',
    // styleUrls: ['./map-options.component.scss'],
})
export class MapOptionsComponent implements OnInit, OnDestroy {

    public filterBarOpen = false;
    public barScale: string;

    public misconceptions: any;
    public standards: any;
    private resizeHandler = this.resizeFilterBar.bind(this);

    constructor(
        public MapService: MapService,
        private AnalyticsService: AnalyticsService,
    ) {}

    public ngOnInit() {
        // Initialize the courses dropdown by pre-selecting all courses
        this.gradeSelected();
        // Open map filter bar open
        this.toggleMapFilterBar();
        window.addEventListener('resize', this.resizeHandler);

        // Request misconceptions and standards data for the map filter/options bar
        this.MapService.getMisconceptionsData()
            .then(misconceptionsData => {
                this.misconceptions = misconceptionsData;
            })
            .catch(console.warn);
        // Retrieve Standards related data
        this.MapService.getStandardsData()
            .then(standardsData => {
                this.standards = standardsData;
            })
            .catch(console.warn);
    }

    public ngOnDestroy() {
        this.MapService.learningMap.option.showAllRegionLabels = false; // Reset to hidden
        window.removeEventListener('resize', this.resizeHandler);
    }

    public toggleMapFilterBar() {
        this.filterBarOpen = !this.filterBarOpen;
        this.AnalyticsService.action({ action: 'toggle_map_options_bar', open: this.filterBarOpen });
        this.barScale = 'scale(' + (this.filterBarOpen ? 2 * (Math.max(window.innerWidth, window.innerHeight) / 40) : 1) + ')';
    }

    public toggleSearchPanel(type) {
        let isOpen = this.MapService.searchable.meta.type === type ? !this.MapService.searchable.meta.isOpen : true;
        this.AnalyticsService.action({ action: 'toggle_search_panel', type: type, open: isOpen });
        // Reset searchable panel to default settings
        this.MapService.searchable.meta = JSON.parse(this.MapService.searchableMetaConst);
        // Open or close panel depending on whether the same type is being clicked,
        // the panel is being opened for the first time, or we are switching to a different searchable
        this.MapService.searchable.meta.isOpen = isOpen;
        this.MapService.searchable.meta.type = type;
        // Assign the appropriate data array for the list of displayed elements
        this.MapService.searchable.data = type === 'misconceptions' ? this.misconceptions : this.standards;
        this.MapService.searchable.filteredData = this.MapService.searchable.data;
        // If there's a construct/stacks panel open pre-fill searchable search bar and filter data results
        if (this.MapService.learningMap.stacksPanel.construct) {
            this.MapService.setSearchText(this.MapService.learningMap.stacksPanel.construct.name);
        }
    }

    public gradeSelected() {
        let selected = this.MapService.learningMap.gradeNames
            .filter(grade => grade.selected)
            .map(grade => grade.name)
            .sort((a, b) => a - b);
        // Possible results are '', 6', '7', '8', '6_7', '6_8', '7_8', and '6_7_8'
        let gradeRange = selected.join('_') || 0;

        localStorage.setItem('grade_selection', JSON.stringify(this.MapService.learningMap.gradeNames));
        this.fetchValidMap(gradeRange);
    }

    public allRegionLabelsChanged() {
        this.MapService.allRegionLabelsChanged();
    }

    private resizeFilterBar() {
        this.barScale = 'scale(' + (this.filterBarOpen ? 2 * (Math.max(window.innerWidth, window.innerHeight) / 40) : 1) + ')';
    }

    private fetchValidMap(gradeRange) {
        this.MapService.getValidMapForGrade(gradeRange)
            .then(result => {
                this.MapService.learningMap.data.fields.forEach(field => {
                    field.regions.forEach(region => {
                        region.valid = result.regions.includes(region.id);

                        region.clusters.forEach(cluster => {
                            cluster.valid = result.clusters.includes(cluster.id);

                            cluster.constructs.forEach(construct => {
                                construct.valid = result.constructs.includes(construct.id);
                                construct.belowGrade = result.below_grade.includes(construct.id);
                                if (construct.id === 52) {
                                }
                            });
                        });
                    });
                });
            })
            .catch(console.warn);
    }
}
