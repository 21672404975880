<div class="heatmap-legend-title">Key:</div>

<div class="heatmap-legend-colors flex-row justify-center align-items-center">
    <div class="legend-incorrect"></div>
    <div class="flex legend-correct-gradient"></div>
    <div class="legend-revised"></div>
</div>

<div class="heatmap-legend-description flex-row justify-center align-items-start">
    <div class="description-incorrect">Incorrect</div>
    <div class="flex justify-center text-right">
        <div class="correct-percentage">20%</div>
        <div class="correct-percentage">40%</div>
        <div class="correct-percentage">60%</div>
        <div class="correct-percentage">80%</div>
        <div class="correct-percentage">100%</div>
    </div>
    <div class="description-revised text-right">Revised Correctly</div>
</div>

<div class="margin-top-1em flex-row align-items-center justify-space-between">
    <div class="flex-none body-small">
        <div class="legend-untested"></div>
        <div class="description-untested">Untested Level</div>
    </div>
    <span class="body-small"><span class="item-difficulty-asterisk">*</span> An item was unusually hard or easy for that level</span>
</div>
