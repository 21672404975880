<div class="search-input-panel flex-column" [class.display]="MapService.searchable.meta.isOpen && !MapService.searchable.meta.displaySelected">
    <button class="btn modal-close" (click)="closePanel()">
        <i class="icon icon-x"></i>
    </button>

    <div class="panel-header flex-row flex-none align-items-center justify-center">
        <h4 class="searchable-type modal-title capitalize">{{ MapService.searchable.meta.type }}</h4>
    </div>

    <div class="panel-input-section">
        <div class="searchable-instructions">Tap on a construct in the map, or search by keyword or code.</div>

        <mat-form-field appearance="standard">
            <mat-label>Keyword or Code</mat-label>
            <input matInput type="text" [(ngModel)]="MapService.searchable.meta.searchText" (input)="MapService.filterSearchableData()">
            <span matSuffix class="search-clear" *ngIf="MapService.searchable.meta.searchText" (click)="clearSearch()">
                <i class="icon icon-times-circle"></i>
            </span>
        </mat-form-field>
    </div>

    <div class="panel-data-header flex-row flex-none align-items-center">
        <div class="flex-25 justify-center">ID</div>
        <div class="flex justify-center">Description</div>
    </div>

    <scrolling-buttons  [enableButtons]="false"
                        scrollDirection="vertical"
                        class="flex-column flex-auto"
                        componentClass="panel-data-content"
                        (scrollBottom)="loadMoreItems()">
        <div *ngFor="let item of MapService.searchable.filteredData | slice: 0 : MapService.searchable.meta.itemLimit">
            <div [ngSwitch]="MapService.searchable.meta.type">
                <!-- Standards -->
                <div
                    *ngSwitchCase="'standards'"
                    class="list-item cursor-pointer list-item-standard flex-row"
                    (click)="selectStandard(item)"
                    [class.selected]="MapService.searchable.meta.selected?.id === item.id"
                    [class.standard-secondary]="isSecondaryStandard(item)">
                    <div class="flex-30 flex-column item-name">
                        <span>{{ item.name }}</span>
                        <i class="secondary-label">secondary</i>
                    </div>
                    <div class="item-description" [innerHTML]="item.description"></div>
                </div>

                <!-- Misconceptions -->
                <div
                    *ngSwitchCase="'misconceptions'"
                    class="list-item cursor-pointer list-item-misconception flex-row"
                    (click)="selectMisconception(item)"
                    [class.selected]="MapService.searchable.meta.selected?.id === item.id">
                    <div class="flex-25 item-name">{{ item.code }}</div>
                    <misconception-card [misconception]="item"></misconception-card>
                </div>
            </div>
        </div>
    </scrolling-buttons>
</div>

<div class="selected-panel" [class.display]="MapService.searchable.meta.selected && MapService.searchable.meta.displaySelected">
    <div class="selected-actions flex align-items-center justify-end space-children-row small-space">
        <button type="button" class="btn back-to-search" (click)="MapService.searchable.meta.displaySelected = false;">
            <i class="icon icon-arrow-left"></i>
        </button>

        <button type="button" class="btn close-panel" (click)="closePanel()">
            <i class="icon icon-x"></i>
        </button>
    </div>

    <div [ngSwitch]="MapService.searchable.meta.type">
        <!-- Standards -->
        <div *ngSwitchCase="'standards'" class="list-item list-item-standard flex-row">
            <div class="flex-30 item-name">{{ MapService.searchable.meta.selected?.name }}</div>
            <div class="item-description" [innerHTML]="MapService.searchable.meta.selected?.description"></div>
        </div>

        <!-- Misconceptions -->
        <div *ngSwitchCase="'misconceptions'" class="list-item list-item-misconception flex-row">
            <div class="flex-25 item-name">{{ MapService.searchable.meta.selected?.code }}</div>
            <misconception-card [misconception]="MapService.searchable.meta.selected"></misconception-card>
        </div>
    </div>
</div>
