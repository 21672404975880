<div class="practice-info" *ngIf="stage === 'info'">
    <div class="modal-header">
        <h4 class="modal-title">Set Starting Level:</h4>

        <button class="btn modal-close" (click)="activeModal.dismiss()">
            <i class="icon icon-x"></i>
        </button>
    </div>

    <div class="modal-body">
        <p class="body-regular">Select a level at which you need practice. Numbers of questions available are listed below the level number. Levels can be changed after each worked problem.</p>

        <div class="flex-row justify-center margin-top-1em">
            <scrolling-buttons class="flex-row justify-center" [enableButtons]="false" scrollDirection="vertical">
                <stack-ladder [construct]="AssessmentStateService.data.construct" startingLevel="1"></stack-ladder>
            </scrolling-buttons>
        </div>
    </div>

    <div class="modal-footer">
        <div class="flex-row justify-space-between align-items-center space-children-row">
            <div class="body-regular">Practice: <span [innerHTML]="AssessmentStateService.data.construct.name"></span></div>

            <button class="btn primary-button" (click)="startPractice()" [disabled]="!AssessmentStateService.stackLadder.stack">Start Practice</button>
        </div>
    </div>
</div>



<div class="practice-assessment" *ngIf="stage === 'assessment'">
    <div class="modal-header dark-theme question-header">
        <div class="flex-row align-items-center justify-space-between">
            <div class="current-question-stats">
                <span class="item-count"><i class="icon icon-ladder"></i> Level {{ AssessmentStateService.stackLadder.stack.order }}</span>
                <span class="item-count">Question {{ AssessmentStateService.data.question?.itemNumberInStack }} of {{ AssessmentStateService.stackLadder.stack.totalItems }}</span>
                <span class="item-count">Practice question #{{ totalQuestionsTaken }}</span>
                <cms-item-link class="item-count"></cms-item-link>
            </div>

            <button class="btn ghost-button end-practice-button" (click)="endPracticeTest()">End Practice</button>
        </div>
    </div>

    <div class="modal-body">
        <confetti></confetti>
        <stack-ladder id="invisible-ladder" *ngIf="shouldRefreshLevel" [construct]="AssessmentStateService.data.construct"></stack-ladder>
        <question class="flex-row full-height flex-wrap"></question>

        <assessment-toast (closed)="onToastClosed($event)"></assessment-toast>
    </div>

    <div class="modal-footer flex-row align-items-center justify-space-between flex-wrap space-children-row">
        <div class="practice-construct-name flex">
            <span>Practice: <span [innerHTML]="AssessmentStateService.data.construct.name"></span></span>
        </div>

        <div class="flex-none align-items-center justify-space-between assessment-actions-bar">
            <!-- Left side buttons -->
            <button class="test-action left btn ghost-button white-bg" (click)="requestAnswerReveal()" *ngIf="AssessmentStateService.data.question?.isSubmitted && !AssessmentStateService.data.question?.isFinishedOrRevealed" [disabled]="AssessmentToastService.toast.show && AssessmentToastService.toast.status === 'reveal'">Reveal</button>

            <button class="test-action left btn ghost-button white-bg ladder-dropdown-button flex-none align-items-center" *ngIf="AssessmentStateService.data.question?.isFinishedOrRevealed && AssessmentStateService.stackLadder.stack" #ladderDropdownTrigger="ngbPopover" [ngbPopover]="ladderDropdown" placement="top-right" container="body" autoClose="outside" popoverClass="ladder-dropdown">
                <!-- <i class="icon icon-ladder"></i> -->
                <span class="button-text">Change Level&nbsp;</span>
                <i class="icon icon-caret-up" [class.rotate]="ladderDropdownTrigger.isOpen()"></i>
            </button>

            <ng-template #ladderDropdown>
                <scrolling-buttons [enableButtons]="false" scrollDirection="vertical" maxHeight="300px">
                    <stack-ladder [construct]="AssessmentStateService.data.construct" [assessmentStage]="true" [startingLevel]="AssessmentStateService.stackLadder.stack?.order"></stack-ladder>
                </scrolling-buttons>
            </ng-template>

            <!-- Right side buttons -->
            <div
                class="try-container"
                @tryNumSlideInOut
                *ngIf="(AssessmentStateService.data.question?.isSubmitted || AssessmentStateService.data.question?.isFinishedOrRevealed) && !(AssessmentStateService.data.question?.isFinishedOrRevealed && (!AssessmentStateService.stackLadder.stack || AssessmentStateService.stackLadder.stack.available_items === 0))">
                <div class="try-number">Try {{ AssessmentStateService.data.question?.tries }}</div>
                <div class="try-bubble-row flex-row align-items-center justify-start space-children-row small-space">
                    <div class="try-bubble" *ngFor="let tryNum of triesRange" [ngClass]="{ filled: AssessmentStateService.data.question?.tries >= tryNum }"></div>
                </div>
            </div>

            <button class="test-action right btn ghost-button white-bg" (click)="tryAgain()" *ngIf="AssessmentStateService.data.question?.isSubmitted && !AssessmentStateService.data.question?.isFinishedOrRevealed">Revise</button>

            <button class="test-action right btn ghost-button white-bg" (click)="submitQuestion()" *ngIf="!AssessmentStateService.data.question?.isSubmitted && !AssessmentStateService.data.question?.isFinishedOrRevealed" [disabled]="!AssessmentStateService.data.multi.isPartAnswered">Submit</button>

            <button class="test-action right btn ghost-button white-bg" (click)="getNewQuestion()" *ngIf="AssessmentStateService.data.question?.isFinishedOrRevealed && AssessmentStateService.stackLadder.stack.available_items > 0">Next</button>

            <span class="completed-text" *ngIf="AssessmentStateService.data.question?.isFinishedOrRevealed && AssessmentStateService.stackLadder.stack.available_items === 0">Choose the next level to continue your practice.</span>

            <span class="completed-text flex justify-end" *ngIf="AssessmentStateService.data.question?.isFinishedOrRevealed && !AssessmentStateService.stackLadder.stack">You have completed all of the questions in this construct!</span>
        </div>
    </div>
</div>



<div class="practice-scorecard" *ngIf="stage === 'scorecard'">
    <div class="modal-header">
        <h4 class="modal-title">Session Summary</h4>

        <button class="btn modal-close" (click)="activeModal.dismiss(AssessmentStateService.data.trUid)">
            <i class="icon icon-x"></i>
        </button>
    </div>

    <div class="modal-body">
        <scrolling-buttons class="flex-row justify-center" [enableButtons]="false" scrollDirection="vertical">
            <stack-ladder [construct]="AssessmentStateService.data.construct" [sessionSummary]="true"></stack-ladder>
        </scrolling-buttons>

        <div class="hr"></div>

        <div class="flex-row align-items-center justify-center flex-wrap space-children-row">
            <button class="btn ghost-button" (click)="activeModal.dismiss(AssessmentStateService.data.trUid)">Close</button>
            <button class="btn primary-button" (click)="activeModal.close(AssessmentStateService.data.trUid)">Go To My Report</button>
        </div>
    </div>
</div>
