<div class="modal-body text-center">
    <button class="btn modal-close" (click)="extendSession()">
        <i class="icon icon-x"></i>
    </button>

    <div class="text-center">{{ instanceMessage }}</div>
    <div class="text-center">You will be safely logged out in {{ minutesLeft }}</div>

    <div class="progress margin-top-1em">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [attr.aria-valuenow]="secondsToConfirm" aria-valuemin="0" aria-valuemax="5" [ngStyle]="{ width: (secondsToConfirm * 100 / 5 + '%') }">
            <span>{{ secondsToConfirm }} sec.</span>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="flex-row flex-wrap align-items-center justify-center space-children-row">
        <button class="btn ghost-button" (click)="extendSession()">Cancel</button>
        <button class="btn primary-button" (click)="manualLogout()">Log Out</button>
    </div>
</div>
