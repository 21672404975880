<div *ngIf="construct" #stackPanel class="stacks-panel flex-column" dragDrop [enableDrag]="enableDragging">
    <button class="btn modal-close" (click)="closePanel()">
        <i class="icon icon-x"></i>
    </button>

    <div class="flex-row flex-noshrink align-items-center justify-center space-children-row construct-quick-toggle" *ngIf="constructsList">
        <button *ngFor="let iterConstruct of constructsList" (click)="switchConstruct(iterConstruct)" class="btn ghost-button" [class.active-construct]="construct.id === iterConstruct.id">{{ UtilsService.getLetterFromIndex(iterConstruct.order - 1) }}</button>
    </div>

    <div class="stacks-panel-header" [ngClass]="['region-color-' + construct.region]">
        <span class="construct-order" *ngIf="enableLadder">{{ UtilsService.getLetterFromIndex(construct.order - 1) }}:&nbsp;</span>
        <span class="construct-name" [innerHTML]="construct.name"></span>
    </div>

    <scrolling-buttons [enableButtons]="false" scrollDirection="vertical" class="flex-column flex-auto">
        <div *ngFor="let stack of construct.stacks" class="stack-item flex-row">
            <div
                class="flex-15 stack-level ladder-block"
                [ngClass]="[enableLadder && enableStackColors ? (stack.scoreClasses || '') : '', enableLadder ? 'with-ladder' : '']"
                [matTooltip]="stack.avgPercent + '%'"
                [matTooltipDisabled]="!stack.avgPercent && stack.avgPercent !== 0"
                matTooltipPosition="before"
                matTooltipClass="mat-tooltip-margin-sm">
                <span>L{{ stack.order }}</span>
            </div>

            <div class="stack-content flex-column">
                <div class="stack-description" [ngClass]="'grade-' + stack.grade[stack.grade.length - 1]" [attr.data-grade]="stack.grade[stack.grade.length - 1]">
                    <span [mathHTML]="stack.description" [truncateChars]="100" [truncateEnable]="enableLadder"></span>

                    <span class="misconception-toggle cursor-pointer flex-none inline-flex space-children-row small-space" *ngIf="enableMisconceptions && stack.misconceptions?.length > 0" (click)="toggleMisconceptionsCollapse(stack, $event)" [matTooltip]="'See misconception' + (stack.misconceptions?.length > 1 ? 's' : '')">
                        <i class="icon icon-exclamation-triangle"></i>
                        <i class="icon icon-angle-down" [class.rotate]="!stack.isMisconceptionCollapsed"></i>
                    </span>
                </div>

                <div *ngIf="enableMisconceptions && stack.misconceptions?.length > 0 && !stack.isMisconceptionCollapsed">
                    <misconception-tabs [misconceptions]="stack.misconceptions" [selectedMisconception]="stack.selectedMisconception"></misconception-tabs>
                </div>
            </div>
        </div>
    </scrolling-buttons>

    <div class="stacks-panel-footer flex-none align-items-center justify-end">
        <button class="btn ghost-button practice-button" *ngIf="enablePracticeTest" (click)="showPracticeAssessment(construct)">Go To Practice {{ UtilsService.getLetterFromIndex(construct.order - 1) }}</button>
    </div>
</div>
