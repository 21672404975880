<svg
    id="map-svg"
    class="unselectable"
    xmlns="http://www.w3.org/2000/svg"
    [attr.viewBox]="'900 -900 ' + mapSizesAndBounds.width + ' ' + mapSizesAndBounds.height"
    [attr.height]="mapSizesAndBounds.height"
    [attr.width]="mapSizesAndBounds.width">
    <defs>
        <filter id="pin-shadow" width="300%" height="300%">
            <feGaussianBlur stdDeviation="10"></feGaussianBlur>
        </filter>

        <filter id="drop-shadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="4"/> <!-- stdDeviation is how much to blur -->
            <feOffset result="offsetblur" dx="0" dy="0"/> <!-- how much to offset -->
            <feComponentTransfer> <!-- controls the opacity -->
                <feFuncA type="linear" slope="0.4"/>
            </feComponentTransfer>
            <feMerge>
                <feMergeNode/> <!-- this contains the offset blurred image -->
                <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
            </feMerge>
        </filter>
    </defs>

    <svg:g id="zoom-container">
        <svg:g id="zoom-element">
            <svg:rect
                class="map-background"
                [attr.x]="-mapSizesAndBounds.width * 0.5"
                [attr.y]="-mapSizesAndBounds.height * 0.5"
                [attr.width]="mapSizesAndBounds.width * 2"
                [attr.height]="mapSizesAndBounds.height * 2">
            </svg:rect>

            <svg:image
                class="grid-image"
                xlink:href="src/assets/image/svg/grid.svg"
                x="-7005"
                y="-19195"
                [attr.width]="mapSizesAndBounds.width * 1.5"
                [attr.height]="mapSizesAndBounds.height * 1.5">
            </svg:image>

            <svg:image
                class="grid-image"
                xlink:href="src/assets/image/svg/grid.svg"
                x="-7005"
                y="9595"
                [attr.width]="mapSizesAndBounds.width * 1.5"
                [attr.height]="mapSizesAndBounds.height * 1.5">
            </svg:image>

            <svg:g *ngFor="let field of MapService.learningMap.data.fields">
                <svg:g class="field-group" *ngIf="!field.wormhole">
                    <svg:g map-field [field]="field"></svg:g>
                </svg:g>
            </svg:g>
        </svg:g>
    </svg:g>
</svg>

<map-slider></map-slider>

<minimap-panel
    [hidden]="MapService.learningMap.option.currentZoomLevel === 0"
    (loaded)="initMinimap($event)"
    class="learning-map-minimap">
</minimap-panel>

<stack-panel
    [construct]="MapService.learningMap.stacksPanel.construct"
    [constructsList]="MapService.learningMap.stacksPanel.constructs"
    [enableMisconceptions]="true"
    (closed)="MapService.learningMap.stacksPanel.construct = null"
    (change)="MapService.learningMap.stacksPanel.construct = $event">
</stack-panel>
