import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { appInfo } from '@/app.constant';
import { SessionService } from '../service/session.service';
import { ModalService } from '../../shared/service/modal.service';

import './footer.component.scss';


@Component({
    selector: 'global-footer',
    templateUrl: './footer.component.html',
    // styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

    public readonly appInfo = appInfo;
    public userGuidePath: string;
    private userGuides = {
        teacher: 'src/assets/pdf/mathmapper-guide.pdf',
        student: 'src/assets/pdf/mathmapper-student-guide.pdf',
    };
    private userAccessSub: Subscription;

    constructor(
        public SessionService: SessionService,
        private ModalService: ModalService,
    ) { }

    public ngOnInit() {
        this.userAccessSub = this.SessionService.userAccess$.subscribe(userAccess => {
            if (userAccess) {
                if (userAccess.permission === 2) {
                    this.userGuidePath = this.userGuides.student;
                } else {
                    this.userGuidePath = this.userGuides.teacher;
                }
            } else {
                this.userGuidePath = null;
            }
        });
    }

    public ngOnDestroy() {
        this.userAccessSub.unsubscribe();
    }

    public openAboutModal() {
        this.ModalService.openAboutModal();
    }

    public openHelpModal() {
        this.ModalService.openHelpModal();
    }

    public openAccessibilityModal() {
        this.ModalService.openAccessibilityModal();
    }

    public openDataPrivacyModal() {
        this.ModalService.openDataPrivacyModal();
    }

    public openTermsOfUseModal() {
        this.ModalService.openTermsOfUseModal();
    }
}
