import { Component, Input, Output, EventEmitter } from '@angular/core';

import { UtilsService } from '@/core/service/utils.service';
import { HeatmapService } from '../heatmap.service';

import './heatmap-construct.component.scss';


@Component({
    selector: 'heatmap-construct',
    templateUrl: './heatmap-construct.component.html',
    // styleUrls: ['./heatmap-construct.component.scss'],
})
export class HeatmapConstructComponent {

    @Input() construct: any;
    @Input() constructInfo: any;
    @Input() assp: any;
    @Input() students: any;
    @Output() toggleActiveStudent = new EventEmitter();

    constructor(
        public UtilsService: UtilsService,
        public HeatmapService: HeatmapService,
    ) {}

    public toggleStacksPanel() {
        if (this.HeatmapService.filters.activeConstructPanel && this.HeatmapService.filters.activeConstructPanel.id === this.construct.id) {
            this.HeatmapService.filters.activeConstructPanel = null;
        } else {
            this.HeatmapService.filters.activeConstructPanel = this.construct;
        }
    }

    public getInitialsFontSize(numQuestionScores) {
        if (numQuestionScores < 20) {
            return '13px';
        } else if (numQuestionScores > 55) {
            return '4px';
        }
        return Math.round(225 / numQuestionScores) + 'px';
    }

    public getCorrectGradient(score, stack) {
        if (!score.stack_level_scores) { return null; }

        let unitScore = score.stack_level_scores[stack.order];
        if (unitScore) {
            if (this.HeatmapService.filters.revised && unitScore.rv_sc === 100) {
                return {
                    'background-color': 'rgba(83, 255, 195)'
                };
            } else if (unitScore.sc) {
                return {
                    'background-color': 'rgba(24, 129, 237, ' + (Math.round(unitScore.sc) / 100) + ')'
                };
            }
        }

        return null;
    }

    public isTopBlock(score, stack) {
        if (this.HeatmapService.filters.untested) {
            return stack.order === this.construct.stacks.length;
        }
        let highestStackWithQuestions;
        for (let stackOrder in this.constructInfo.stack_question_ids) {
            if (this.constructInfo.stack_question_ids[stackOrder].length) {
                highestStackWithQuestions = Number(stackOrder);
            }
        }
        return stack.order === highestStackWithQuestions;
    }
}
