import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { UIRouterModule } from '@uirouter/angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbPopoverModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// Import login components used in guest login component
import { LoginModule } from '../login/login.module';

import { AnalyticsService } from './service/analytics.service';
import { AuthService } from './service/auth.service';
import { CoachmarksService } from './coachmarks/coachmarks.service';
import { GrowlerService } from './service/growler.service';
import { HttpService } from './service/http.service';
import { SessionService } from './service/session.service';
import { UtilsService } from './service/utils.service';

import { BackendLoggerService } from './service/backend-logger.service';
import { GlobalErrorHandler } from './service/global-error-handler.service';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { FreePracticeLoginComponent } from './free-practice-login/free-practice-login.component';
import { NavComponent } from './nav/nav.component';
import { BrowserWarningComponent } from './browser-warning/browser-warning.component';
import { CoachMarksComponent } from './coachmarks/coachmarks.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { SessionExpiredModalComponent } from './session-expired/session-expired-modal.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ToastrModule,
        UIRouterModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgbPopoverModule,
        NgbDropdownModule,
        LoginModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        BackendLoggerService,
        AnalyticsService,
        AuthService,
        CoachmarksService,
        GrowlerService,
        HttpService,
        SessionService,
        UtilsService,
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        GuestLoginComponent,
        FreePracticeLoginComponent,
        NavComponent,
        BrowserWarningComponent,
        CoachMarksComponent,
        LoadingOverlayComponent,
        SessionExpiredModalComponent,
    ],
    entryComponents: [
        SessionExpiredModalComponent,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        NavComponent,
        BrowserWarningComponent,
        CoachMarksComponent,
        LoadingOverlayComponent,
    ],
})
export class CoreModule {}
