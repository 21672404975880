export const appInfo = {
    version: '4.4.4',
    deployed: '2020-04-22T14:33:41+00:00',
    provider: 'SUDDS',
    providerUrl: 'https://sudds.ced.ncsu.edu/',
    providerEmail: 'sudds_group@ncsu.edu',
    providerLogo: 'src/assets/image/svg/sudds_logo.svg',
    name: 'Math-Mapper 6-8',
}

export const captchaSiteKeyV2 = '6Lfd_QMTAAAAAASFc02PnUY4ImVA_8nUCzFTVHcx';
export const captchaSiteKeyV3 = '6Lf-ym4UAAAAACA_1AMI_6dul-khmuZ3TWH-HK50';

const apiDomain = 'https://api6.sudds.co';
const apiVersion = 'v4';
export const apiServer = {
    domain: apiDomain,
    urlPrefix: `${apiDomain}/${apiVersion}`,
}

export const datepickerOptions = {
    showWeeks: false,
    minDate: null,
    maxDate: null,
}

export const dateFormat = {
    shorterDate: 'MM/dd/yy',
    shortDate: 'MM/dd/yyyy',
    datetime: 'MM/dd/yy h:mm:ss a',
    // timezone: new Date().toString().match(/([-+][0-9]+)\s/)[1],
    timezone: 'UTC',
}

const mapWidth = 28800;
const mapHeight = 19200;
// Defines the size of each element in the map and minimap
export const mapSizesAndBounds = {
    width: mapWidth,
    height: mapHeight,
    scaleExtent: [0.9, 14],
    translateExtent: [
        [
            -mapWidth / 10,
            -mapHeight / 10
        ],
        [
            mapWidth,
            mapHeight
        ]
    ],
    viewSizes: [ // for zoom scaling
        {
            w: mapWidth * 1.000,
            h: mapHeight * 1.000
        }, // full map, zoom level 0; user can zoom out a little further than this
        {
            w: mapWidth * 0.700,
            h: mapHeight * 0.700
        }, // field, zoom level 1
        {
            w: mapWidth * 0.375,
            h: mapHeight * 0.375
        }, // region, zoom level 2
        {
            w: mapWidth * 0.170,
            h: mapHeight * 0.170
        }, // cluster, zoom level 3
        {
            w: mapWidth * 0.090,
            h: mapHeight * 0.090
        } // construct, zoom level 4
    ],
    zoomScalingThresholds: [ // these are related to the above viewSizes
        1 / 0.92, // boundary between 0 and 1
        1 / 0.40, // boundary between 1 and 2
        1 / 0.19, // boundary between 2 and 3
        1 / 0.12 // boundary between 3 and 4
    ],
    region: {
        width: 4320,
        height: 2880,
        iButtonScaling: [
            14,
            9,
            6,
            4,
            3
        ]
    },
    cluster: {
        width: 1080,
        height: 1080,
        fontSizes: [
            '6.0rem', // Same as level 1 because clusters are not visible at level 0 and they shouldn't grow before disappearing
            '6.0rem',
            '5.0rem',
            '4.0rem',
            '4.0rem'
        ],
        visibleAtLevel: 1,
        patternConnectors: {
            1: [],
            2: [
                {
                    x1: -0.275,
                    y1: 0,
                    x2: 0.275,
                    y2: 0
                }
            ],
            11: [
                {
                    x1: 0,
                    y1: 0.275,
                    x2: 0,
                    y2: -0.275
                }
            ],
            12: [
                {
                    x1: 0,
                    y1: 0.23815698604072066,
                    x2: 0,
                    y2: -0.23815698604072066
                },
                {
                    x1: -0.275,
                    y1: -0.23815698604072066,
                    x2: 0.275,
                    y2: -0.23815698604072066
                }
            ],
            21: [
                {
                    x1: -0.275,
                    y1: 0.23815698604072066,
                    x2: 0.275,
                    y2: 0.23815698604072066
                },
                {
                    x1: 0,
                    y1: -0.23815698604072066,
                    x2: 0,
                    y2: 0.23815698604072066
                }
            ],
            22: [
                {
                    x1: -0.275,
                    y1: 0.275,
                    x2: 0.275,
                    y2: 0.275
                },
                {
                    x1: -0.275,
                    y1: -0.275,
                    x2: 0.275,
                    y2: -0.275
                },
                {
                    x1: 0,
                    y1: 0.275,
                    x2: 0,
                    y2: -0.275
                }
            ],
            111: [
                {
                    x1: 0,
                    y1: 0.55,
                    x2: 0,
                    y2: -0.55
                }
            ],
            112: [
                {
                    x1: 0,
                    y1: 0.55,
                    x2: 0,
                    y2: -0.47631397208144133
                },
                {
                    x1: -0.275,
                    y1: -0.47631397208144133,
                    x2: 0.275,
                    y2: -0.47631397208144133
                }
            ],
            121: [
                {
                    x1: -0.275,
                    y1: 0,
                    x2: 0.275,
                    y2: 0
                },
                {
                    x1: 0,
                    y1: 0.47631397208144133,
                    x2: 0,
                    y2: -0.47631397208144133
                }
            ],
            211: [
                {
                    x1: -0.275,
                    y1: 0.47631397208144133,
                    x2: 0.275,
                    y2: 0.47631397208144133
                },
                {
                    x1: 0,
                    y1: -0.55,
                    x2: 0,
                    y2: 0.47631397208144133
                }
            ]
        }
    },
    construct: {
        height: 378,
        width: 378,
        fontSize: 28,
        extraStackHeight: 100,
        visibleAtLevel: 1,
        misconceptionScaling: [
            0,
            3,
            2.5,
            2,
            1.5
        ]
    },
    stack: {
        font_size: 18,
        width: 359,
        height: 378,
        visibleAtLevel: 2
    }
}
