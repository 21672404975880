import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    ConfirmModalComponent,
    ContactModalComponent,
    AboutModalComponent,
    HelpModalComponent,
    AccessibilityModalComponent,
    DataPrivacyModalComponent,
    TOUModalComponent,
    OptOutModalComponent,
    HowToVideoModalComponent,
    FreePracticeIntroModalComponent,
    LogoutModalComponent,
    FeedbackModalComponent,
    MapClusterResourcesModalComponent,
    StudentTestStatusModalComponent,
    StudentTriesActivityModalComponent,
    StudentPracticeActivityModalComponent,
} from '../modal';
import { AnalyticsService } from '../../core/service/analytics.service';


@Injectable()
export class ModalService {

    private modalDefaults = {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'confirm-modal',
        size: 'confirmation',
        centered: true,
    };
    private modalOptionDefaults = {
        closeButtonText: 'Cancel',
        actionButtonText: 'OK',
        bodyText: 'Perform this action?',
    };

    constructor(
        private ngbmodal: NgbModal,
        private AnalyticsService: AnalyticsService,
    ) { }


    // Generic modal with defaults to a small confirmation modal with simple actions
    public openModal(customModalDefaults = {}, customModalOptions = {}) {
        let modalConfig = Object.assign({}, this.modalDefaults, customModalDefaults);
        let modalOptions = Object.assign({}, this.modalOptionDefaults, customModalOptions);

        const modalRef = this.ngbmodal.open(ConfirmModalComponent, <any>modalConfig);
        modalRef.componentInstance.modalOptions = modalOptions;

        return modalRef.result;
    }

    // Modals opened from global components
    public openContactModal() {
        this.AnalyticsService.action({ action: 'open_contact_modal' });

        return this.ngbmodal.open(ContactModalComponent, {
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'contact-modal',
        }).result;
    }

    public openAboutModal() {
        this.AnalyticsService.action({ action: 'open_about_modal' });

        return this.ngbmodal.open(AboutModalComponent, {
            windowClass: 'about-modal',
            size: 'lg',
        }).result;
    }

    public openHelpModal() {
        this.AnalyticsService.action({ action: 'open_help_modal' });

        return this.ngbmodal.open(HelpModalComponent, {
            windowClass: 'help-desk-modal',
            size: 'sm',
        }).result;
    }

    public openAccessibilityModal() {
        this.AnalyticsService.action({ action: 'open_accessibility_modal' });

        return this.ngbmodal.open(AccessibilityModalComponent, {
            windowClass: 'accessibility-modal',
            size: 'sm',
        }).result;
    }

    public openDataPrivacyModal() {
        this.AnalyticsService.action({ action: 'open_privacy_modal' });

        return this.ngbmodal.open(DataPrivacyModalComponent, {
            windowClass: 'data-privacy-modal',
            size: 'sm',
        }).result;
    }

    public openTermsOfUseModal() {
        this.AnalyticsService.action({ action: 'open_terms_of_use_modal' });

        return this.ngbmodal.open(TOUModalComponent, {
            windowClass: 'terms-of-use-modal',
            size: 'lg',
        }).result;
    }

    public openOptOutModal() {
        this.AnalyticsService.action({ action: 'open_opt_out_modal' });

        return this.ngbmodal.open(OptOutModalComponent, {
            windowClass: 'opt-out-modal',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        }).result;
    }

    public openHowToVideoModal(name: string) {
        this.AnalyticsService.action({ action: 'open_' + name + '_how_to_video_modal' });

        const modalConfig = {
            windowClass: 'how-to-video-modal',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            centered: true,
        };
        
        const modalRef = this.ngbmodal.open(HowToVideoModalComponent, <any>modalConfig);
        modalRef.componentInstance.videoType = name;

        return modalRef.result;
    }

    public openFreePracticeIntroModal() {
        this.AnalyticsService.action({ action: 'open_free_practice_intro_modal' });

        return this.ngbmodal.open(FreePracticeIntroModalComponent, {
            size: 'lg',
            backdrop: 'static',
            backdropClass: 'free-practice-intro-backdrop',
            centered: true,
            keyboard: false,
            windowClass: 'free-practice-intro-modal',
        }).result;
    }

    public openLogoutModal() {
        this.AnalyticsService.action({ action: 'open_logout_modal' });

        const modalRef = this.ngbmodal.open(LogoutModalComponent, <any>{
            windowClass: 'logout-modal',
            size: 'confirmation',
        });
        modalRef.componentInstance.instanceMessage = 'Thank you for using MathMapper.';
        modalRef.componentInstance.secondsToConfirm = 5;

        return modalRef.result;
    }

    public openSessionExpiringModal() {
        this.AnalyticsService.action({ action: 'open_session_expiring_modal' });

        const modalRef = this.ngbmodal.open(LogoutModalComponent, <any>{
            windowClass: 'session-modal',
            size: 'confirmation',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.instanceMessage = 'Your session is about to expire.';
        modalRef.componentInstance.secondsToConfirm = null;

        return modalRef.result;
    }

    public openFeedbackFormModal(questions) {
        this.AnalyticsService.action({ action: 'open_feedback_form_modal' });
        const modalRef = this.ngbmodal.open(FeedbackModalComponent, {
            windowClass: 'feedback-modal',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.questions = questions;

        return modalRef.result;
    }

    // Map Page
    public openMapClusterResources(cluster, resources) {
        this.AnalyticsService.action({ action: 'open_map_cluster_external_links_modal' });

        const modalRef = this.ngbmodal.open(MapClusterResourcesModalComponent, {
            windowClass: 'cluster-resources-modal',
            size: 'lg',
        });
        modalRef.componentInstance.cluster = cluster;
        modalRef.componentInstance.resources = resources;

        return modalRef.result;
    }

    // Assessment Management Page
    public openStudentTestStatusModal(students, test) {
        this.AnalyticsService.action({ action: 'open_student_list_test_status_modal' });

        const modalRef = this.ngbmodal.open(StudentTestStatusModalComponent, {
            windowClass: 'students-test-status-modal',
            size: 'sm',
        });
        modalRef.componentInstance.students = students;
        modalRef.componentInstance.test = test;

        return modalRef.result;
    }

    public openStudentTriesActivityModal(students, tryData, cluster) {
        this.AnalyticsService.action({ action: 'open_student_list_tries_activity_modal' });

        const modalRef = this.ngbmodal.open(StudentTriesActivityModalComponent, {
            windowClass: 'students-tries-activity-modal',
            size: 'sm',
        });
        modalRef.componentInstance.students = students;
        modalRef.componentInstance.tryData = tryData;
        modalRef.componentInstance.cluster = cluster;

        return modalRef.result;
    }

    // Report/Class Report pages
    public openPracticeActivityModal(students) {
        this.AnalyticsService.action({ action: 'open_student_list_practice_test_activity_modal' });

        const modalRef = this.ngbmodal.open(StudentPracticeActivityModalComponent, {
            windowClass: 'students-practice-activity-modal',
            size: 'sm',
        });
        modalRef.componentInstance.students = students;

        return modalRef.result;
    }
}
