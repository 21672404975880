import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { HttpService } from '../core/service/http.service';
import { AnalyticsService } from '../core/service/analytics.service';

import { MapTaggingModalComponent } from './map-tagging/map-tagging-modal.component';


@Injectable()
export class ResourcerService {

    // public fields: any[];
    public sources: any[];
    public grades: any[];
    public tags: any[];


    constructor(
        private ngbmodal: NgbModal,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) { }

    public fetchResources(page, query) {
        let url = `${apiServer.urlPrefix}/link/get/${page}/`;

        return this.HttpService.post(url, query)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'resourcer_fetch_links',
                    url: url
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'resourcer_fetch_links_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getTeacherCourses() {
        let url = `${apiServer.urlPrefix}/course/district/get/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'resourcer_fetch_courses',
                    url: url
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'resourcer_fetch_courses_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    // public getResourcerLearningMap() {
    //     let url = `${apiServer.urlPrefix}/map/field/?option=links&type=nested`;
    //     let params = { option: 'links', type: 'nested' };

    //     return this.HttpService.get(url, params)
    //         .then(response => {
    //             this.AnalyticsService.action({
    //                 action: 'resourcer_fetch_map_location',
    //                 url: url
    //             });
    //             // Order by map pattern
    //             this.fields = [2, 3, 0, 1].map(fieldSeq => response.result[fieldSeq]);

    //             return Promise.resolve(this.fields);
    //         })
    //         .catch(error => {
    //             this.AnalyticsService.warning({
    //                 action: 'resourcer_fetch_map_location_failed',
    //                 url: url,
    //                 error: error
    //             });

    //             return Promise.reject(error);
    //         });
    // }

    public openMapTaggingModal({ instructions = '', fields = [], filters = null, resource = null } = {}) {
        this.AnalyticsService.action({ action: 'open_resourcer_map_tagging_modal' });

        const modalRef = this.ngbmodal.open(MapTaggingModalComponent, {
            windowClass: 'map-tagging-modal',
            size: 'lg',
        });
        modalRef.componentInstance.instructions = instructions;
        modalRef.componentInstance.fields = fields;
        // Optional filters and resource
        modalRef.componentInstance.filters = filters;
        modalRef.componentInstance.resource = resource;

        return modalRef.result;
    }

    public getFilterGrades() {
        let url = `${apiServer.urlPrefix}/grade/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_resourcer_grades',
                    url: url
                });
                response.result.splice(0, 4); // Remove KG, HS, etc
                this.grades = response.result;

                return Promise.resolve(this.grades);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_resourcer_grades_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getFilterTags() {
        let url = `${apiServer.urlPrefix}/tag/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_resourcer_tags',
                    url: url
                });
                response.result.splice(10, 1); // Remove Free/Paid
                this.tags = response.result;

                return Promise.resolve(this.tags);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_resourcer_tags_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getResourceAuthors() {
        let url = `${apiServer.urlPrefix}/tag/authors/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'fetch_source_names',
                    url: url
                });
                this.sources = response.result;

                return Promise.resolve(this.sources);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_source_names_failed',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public saveResource(data, method = 'PATCH', action) {
        let url = `${apiServer.urlPrefix}/link/`;

        return this.HttpService.any(url, data, method)
            .then(response => {
                this.AnalyticsService.action({
                    action: action,
                    url: url,
                    method: method
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: action + '_failed',
                    url: url,
                    method: method,
                    error: error
                });

                return Promise.reject(error);
            });
    }


    public deleteResource(resource) {
        let params = {
            id: resource.id
        };

        return this.saveResource(params, 'DELETE', 'delete_resource');
    }
}
