import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { AssessmentStateService } from '../../../assessment/common/assessment-state.service';

import './question-breakdown.component.scss';


@Component({
    selector: 'question-breakdown',
    templateUrl: './question-breakdown.component.html',
    // styleUrls: ['./question-breakdown.component.scss'],
})
export class QuestionBreakdownComponent {

    @Input() matrix: any;
    @Input() cluster: any;
    @Input() testScores: any;
    @Output() viewQuestion = new EventEmitter<number>();

    constructor(
        private element: ElementRef,
        public AssessmentStateService: AssessmentStateService,
    ) {}
}
