import { Input, Component, OnInit } from '@angular/core';

import { StateService } from '@uirouter/core';

import { MapService } from '@/map/map.service';
import { AssessmentManagementService } from '../assessment-management.service';

import './section-assessment.component.scss';


@Component({
    selector: 'section-assessment',
    templateUrl: './section-assessment.component.html',
    // styleUrls: ['./section-assessment.component.scss'],
})
export class SectionAssessmentComponent implements OnInit {

    @Input() public schoolYears: any;
    public selectedIndex: number;
    public clusters = [];
    public loadingClusters = false;
    // Minimap variables
    public highlightAssps = [];
    public isMinimapOpen = false;

    constructor(
        private $state: StateService,
        private MapService: MapService,
        public AssessmentManagementService: AssessmentManagementService,
    ) {}

    public ngOnInit() {
        this.selectedIndex = Math.min(Math.max(0, Number(this.$state.params.t) || 0), 2);
        // Wrap dropdown models initializer in async fn due to ExpressionChangedAfterItHasBeenCheckedError
        // It will allow the test mgt page component to stabilize before updating the models
        setTimeout(() => {
            // If we arrive to this page from an outside link or a page refresh
            // Pre-populate the dropdown models with the selected ids
            this.AssessmentManagementService.models.schoolYear = this.schoolYears.find(schoolYear => {
                return schoolYear.id === Number(this.$state.params.schoolYearId);
            });

            this.AssessmentManagementService.models.section = this.AssessmentManagementService.models.schoolYear.sections.find(section => {
                return section.id === Number(this.$state.params.sectionId);
            });
        });

        this.loadingClusters = true;
        this.MapService.getLearningMap()
            .then(map => {
                this.clusters = map.clusters;
            })
            .catch(console.warn)
            .finally(() => this.loadingClusters = false);
    }

    public updateStateParams(selectedIndex) {
        this.$state.transitionTo(
            this.$state.current.name,
            { t: selectedIndex },
            { inherit: true, location: 'replace', reload: false }
        );
    }
}
