import { Component, OnInit } from '@angular/core';

import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SessionService } from '../../core/service/session.service';
import { UtilsService } from '../../core/service/utils.service';
import { AssessmentManagementService } from '../../assessment-management/assessment-management.service';
import { CourseManagementService } from '../course-management/course-management.service';

import { EditResourceModalComponent } from './edit-resource/edit-resource-modal.component';
import { CoursePermissionsModalComponent } from './course-permissions/course-permissions-modal.component';

import './course-management.component.scss';


@Component({
    selector: 'course-management',
    templateUrl: 'course-management.component.html',
    // styleUrls: ['./course-management.component.scss'],
})
export class CourseManagementComponent implements OnInit {

    public schoolYears: any;
    public courses: any;
    public dropdownModels = {
        school: null,
        schoolYear: null,
    };

    constructor(
        private $state: StateService,
        private ngbmodal: NgbModal,
        public SessionService: SessionService,
        private UtilsService: UtilsService,
        private AssessmentManagementService: AssessmentManagementService,
        private CourseManagementService: CourseManagementService,
    ) { }


    public ngOnInit() {
        this.UtilsService.addLoadingOverlay();

        this.AssessmentManagementService.getTeacherSchoolYear()
            .then(schoolYears => {
                this.schoolYears = schoolYears;
                if (this.schoolYears.length === 1) {
                    // Pre-select the only school year on the list
                    this.dropdownModels.schoolYear = this.schoolYears[0];
                    // Then, find all the courses for this school year
                    this.getCourses(this.dropdownModels.schoolYear)
                }
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public getCourses(year) {
        this.UtilsService.addLoadingOverlay();

        this.CourseManagementService.getCourses(year.id)
            .then(courses => {
                this.courses = courses || [];
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public navigateToCourseDetail(course) {
        this.CourseManagementService.data.course = course;
        this.$state.go('sequencer.course', {id: course.id});
    }

    public navigateToSectionDetail(section) {
        this.CourseManagementService.data.section = section;
        this.$state.go('sequencer.section', {id: section.id});
    }

    public editCoursePermissions(course) {
        const modalRef = this.ngbmodal.open(CoursePermissionsModalComponent, <any>{
            windowClass: 'course-permissions-modal',
            size: 'confirmation',
        });
        modalRef.componentInstance.course = course;

        return modalRef.result;
    }

    public editCourse(course) {
        this.openResourceEditModal(course, 'course');
    }

    public editSection(section) {
        this.openResourceEditModal(section, 'section');
    }

    private openResourceEditModal(resource, type) {
        const modalRef = this.ngbmodal.open(EditResourceModalComponent, {
            windowClass: 'edit-resource-modal',
            size: 'sm',
        });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.resource = resource;
        modalRef.componentInstance.schoolYear = this.dropdownModels.schoolYear;

        return modalRef.result;
    }

    public deleteCourse(course) {
        course.deleting = true;
        const courseData = {
            id: course.id,
        };
        this.CourseManagementService.saveCourse(courseData, 'DELETE')
            .catch(console.warn);
    }

    public undoCourseDelete(course) {
        course.deleting = false;
        this.CourseManagementService.saveCourse(course, 'PATCH')
            .catch(console.warn);
    }

    public deleteSection(section) {
        section.deleting = true;
        this.CourseManagementService.saveSection(section, 'DELETE')
            .catch(console.warn);
    }

    public undoSectionDelete(section) {
        section.deleting = false;
        this.CourseManagementService.saveSection(section, 'PATCH')
            .catch(console.warn);
    }

    public addSection(course) {
        let data = {
            name: 'Section ' + (course.sections.length + 1),
            code: course.code,
            course: course.id,
            start_date: course.start_date,
            end_date: course.end_date
        };
        this.submitSection(data, course);
    }

    public duplicateSection(course, section) {
        let data = Object.assign({}, section);
        data.name = data.name + ' (copy)';
        data.course = course.id;
        this.submitSection(data, course);
    }

    private submitSection(data, course) {
        course.sections.push(data);
        this.CourseManagementService.saveSection(data, 'PUT')
            .then(newSection => {
                if (newSection) {
                    Object.assign(data, newSection);
                }
            })
            .catch(console.warn);
    }
}
