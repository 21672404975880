<button class="btn ghost-button map-fab flex-none inline-flex align-items-center justify-center" (click)="zoomSliderChanged(-1)" [disabled]="MapService.learningMap.option.currentZoomLevel === 0">
    <!-- <i class="fas fa-search-minus"></i> -->
    <i class="icon icon-minus"></i>
</button>

<mat-slider
    class="shadow-svg"
    [min]="0"
    [max]="4"
    [step]="1"
    [thumbLabel]="false"
    [tickInterval]="1"
    [(ngModel)]="MapService.learningMap.option.currentZoomLevel"
    (input)="zoomSliderChanged($event)">
</mat-slider>

<button class="btn ghost-button map-fab flex-none inline-flex align-items-center justify-center" (click)="zoomSliderChanged(1)" [disabled]="MapService.learningMap.option.currentZoomLevel === 4">
    <!-- <i class="fas fa-search-plus"></i> -->
    <i class="icon icon-plus"></i>
</button>
