import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '../../../core/service/analytics.service';

import './how-to-video-modal.component.scss';


@Component({
    selector: 'how-to-video-modal',
    templateUrl: './how-to-video-modal.component.html'
})
export class HowToVideoModalComponent {

    @Input() public videoType: string;

    constructor(
        public activeModal: NgbActiveModal,
        private AnalyticsService: AnalyticsService,
    ) {}
}
