import { Component, Renderer2, OnInit, OnDestroy } from '@angular/core';

import './browser-warning.component.scss';


@Component({
    selector: 'browser-warning',
    templateUrl: './browser-warning.component.html',
    // styleUrls: ['./browser-warning.component.scss'],
})
export class BrowserWarningComponent implements OnInit, OnDestroy {

    constructor(
        private renderer: Renderer2,
    ) {}

    public ngOnInit() {
        this.renderer.addClass(document.body, 'is-ie');
    }

    public ngOnDestroy() {
        this.renderer.removeClass(document.body, 'is-ie');
    }
}
