import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../../core/service/utils.service';
import { CourseManagementService } from '../course-management.service';


@Component({
    selector: 'course-permissions-modal',
    templateUrl: './course-permissions-modal.component.html'
})
export class CoursePermissionsModalComponent implements OnInit {

    @Input() course: any;
    public permissions: any;

    constructor(
        public activeModal: NgbActiveModal,
        private UtilsService: UtilsService,
        private CourseManagementService: CourseManagementService,
    ) {}

    public ngOnInit() {
        this.permissions = this.UtilsService.calculateSectionPermissions(this.course.status);
    }

    public saveChanges() {
        this.course.status = this.reduceToStatus(this.permissions);
        let data = {
            id: this.course.id,
            name: this.course.name,
            code: this.course.code,
            status: this.course.status,
            start_date: this.course.start_date,
            end_date: this.course.end_date,
            grade: [],
        };
        data.grade = (this.course.grade || []).map(grade => grade.id);

        this.CourseManagementService.saveCourse(data, 'PATCH')
            .catch(console.warn);

        this.activeModal.close(this.course);
    }

    private reduceToStatus(permissions) {
        let status = 0;
        status += permissions.ssp ? 0 : 1;
        status += permissions.resources ? 0 : 2;
        status += permissions.tests ? 0 : 4;

        return status;
    }
}
