<div class="card-flipper" #flipper [class.flip]="misconception?.flipped">
    <div class="card card-front">
        <div class="card-header flex-row justify-center dark-theme"></div>

        <div class="card-content">
            <span [mathHTML]="misconception?.description"></span>
        </div>

        <div class="card-footer flex-row justify-space-between align-items-end">
            <div class="misconception-code" [class.clickable]="clickSearch && MapService.searchable.meta.isOpen && MapService.searchable.meta.type === 'misconceptions'" (click)="searchMisconception(misconception)">Misconception <span>{{ misconception?.longId }}</span></div>
            <div class="icon-container">
                <span class="body-small italic">Flip</span>
                <span class="cursor-pointer" (click)="flipMisconception($event, misconception)">
                    <i class="icon icon-card-flip"></i>
                </span>
            </div>
        </div>
    </div>

    <div class="card card-back">
        <div class="card-header flex-row justify-center dark-theme"></div>

        <div class="card-content">
            <span [mathHTML]="misconception?.concept"></span>
        </div>

        <div class="card-footer flex-row justify-space-between align-items-end">
            <div class="misconception-code" [class.clickable]="clickSearch && MapService.searchable.meta.isOpen && MapService.searchable.meta.type === 'misconceptions'" (click)="searchMisconception(misconception)">Correct Concept <span>{{ misconception?.longId }}</span></div>
            <div class="icon-container">
                <span class="body-small italic">Back</span>
                <span class="cursor-pointer" (click)="flipMisconception($event, misconception)">
                    <i class="icon icon-card-flip"></i>
                </span>
            </div>
        </div>
    </div>
</div>
