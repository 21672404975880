import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { dateToString } from '@/_helpers/transforms';


@Injectable()
export class UtilsService {

    private _loadingCountSource = new BehaviorSubject<any>({
        requests: 0,
        isFullScreen: false,
    });
    public loadingCount$ = this._loadingCountSource.asObservable();

    public isNavOpenSource = new BehaviorSubject<boolean>(false);
    public isNavOpen$ = this.isNavOpenSource.asObservable();

    constructor(private ngbmodal: NgbModal) { }

    public sortByProp(arr, prop, reverse = false, nullToLast = false) {
        arr.sort((a, b) => {
            let num;
            if (typeof a[prop] === 'number' && typeof b[prop] === 'number') {
                num = a[prop] - b[prop];
            } else {
                num = String(a[prop]).localeCompare(String(b[prop]));
            }

            if (reverse) {
                num = num * -1;
            }

            return num;
        });

        if (nullToLast) {
            let truthyValues = arr.filter(item => item[prop] || item[prop] === 0);
            let falsyValues = arr.filter(item => !item[prop] && item[prop] !== 0);
            arr = truthyValues.concat(falsyValues);
        }

        return arr;
    }

    public sortTestsByDate(tests) {
        const today = dateToString(new Date());
        let closedPast = [];
        let openTest = [];
        let closedFuture = [];

        tests.forEach(test => {
            this.sortAndFormatRetests(test);

            if (test.start_date < today && test.end_date < today) {
                closedPast.push(test);
            } else if (test.start_date > today && test.end_date > today) {
                closedFuture.push(test);
            } else {
                openTest.push(test);
            }
        });

        openTest.sort(this.closestEndDateToToday);
        closedFuture.sort(this.closestStartDateToToday);
        closedPast.sort(this.closestEndDateToToday);

        return openTest.concat(closedFuture).concat(closedPast);
    }

    private sortAndFormatRetests(test) {
        if (test.retest && test.retest.length > 0) {
            // When a test is completed we want to use the retest due date for sorting
            // assp will not be shown to the student so it is safe to overwrite this prop
            if (test.completed) {
                test.end_date = test.retest[test.retest.length - 1].end_date;
                test.start_date = test.retest[test.retest.length - 1].start_date;
            }
            // Re-format if it's an old retest without a retest_id or dates
            test.retest.forEach(retest => {
                if (!retest.start_date || !retest.end_date) {
                    retest.start_date = test.retake_start_date;
                    retest.end_date = test.retake_end_date;
                    retest.forms = test.forms;
                }
            });
            // Sort by oldest to newest activity
            test.retest.sort((a, b) => {
                return a.end_date > b.end_date ? 1 : -1;
            });
        }
    }

    private closestEndDateToToday(a, b) {
        const today = dateToString(new Date());

        if (a.end_date < b.end_date) {
            return -2;
        } else if (a.end_date === b.end_date) {
            if (a.start_date > b.start_date) {
                return -1;
            }
            return 0;
        } else {
            return 1;
        }
    }

    private closestStartDateToToday(a, b) {
        const today = dateToString(new Date());

        if (a.start_date < b.start_date) {
            return -2;
        } else if (a.start_date === b.start_date) {
            if (a.end_date < b.end_date) {
                return -1;
            }
            return 0;
        } else {
            return 1;
        }
    }

    public stripHTMLTags(text: string): string {
        if (!text) { return ''; }

        return text.replace(/<\/?[a-z][a-z0-9]*[^<>]*>/ig, '');
    }

    public getLetterFromIndex(index) {
        index = Math.abs(index) % 26;

        // get the index and return the letter of the alphabet
        return String.fromCharCode(65 + index);
    }

    public groupByCourses(section) {
        return section.course.code + (section.course.name ? ' (' + section.course.name + ')' : '');
    }

    public formatClusterScore(cs, falsyText = '&mdash;') {
        // If the input is a number convert to string with 1 decimal point and add % symbol
        if (typeof cs === 'number') {
            return (cs / 100).toFixed(1) + '%';
        }

        // If the input is an object of type { initial: number, revised?: number }
        if (cs && (cs.initial || cs.initial === 0)) {
            let displayScore = (cs.initial / 100).toFixed(1) + '%';

            if (cs.revised || cs.revised === 0) {
                displayScore += ' (' + (cs.revised / 100).toFixed(1) + '%)';
            }

            return displayScore;
        }

        return falsyText;
    }

    public calculateSectionPermissions(status) {
        return {
            ssp: (status & 1) === 0,
            resources: (status & 2) === 0,
            tests: (status & 4) === 0
        };
    }

    public testformTypeToText(type, isConstructTest=false) {
        let types = [
            null,
            'Pilot Test',
            'Field Test',
            'Demo Practice',
            'Demo Test', // Demo Pre-Test
            'Demo Test', // Demo Post-Test
            'Practice',
            'Test', // Pre-Test
            isConstructTest ? 'Check' : 'Test',
            isConstructTest ? 'Recheck' : 'Retest',
            'Test', // Post-Test
            'Retired Pretest',
            'Retired Test',
            'Retired Retest',
            'Retired Posttest',
            'Student Try',
            'Retired Student Try',
        ];

        if (!type && type !== 0) {
            console.warn('Unkown test type: ', type, 'Defaulting to "Test"');
        }

        return types[type] || 'Test';
    }

    public gradeBinToText(grade) {
        let grades = [
            null,
            '6',
            '7',
            '6, 7',
            '8',
            '6, 8',
            '7, 8',
            '6, 7, 8',
            'Wormhole',
            '6 Wormhole',
            '7 Wormhole',
            '6, 7 Wormhole',
            '8 Wormhole',
            '6, 8 Wormhole',
            '7, 8 Wormhole',
            '6, 7, 8 Wormhole',
            'E',
        ];

        if (!grade && grade !== 0) {
            console.warn('Unkown grade level: ', grade, 'Defaulting to "None"');
        }

        return grades[grade] || 'None';
    }

    public testStatusToText(status, isPastDue = false) {
        let statuses = [
            'N/A',
            isPastDue ? 'Expired' : 'In Progress',
            isPastDue ? 'Expired' : 'Saved',
            'Completed',
            'In Progress Practice',
            'Completed Practice',
            'Previewing',
            'Previewed',
            'Expired',
            'Abandoned',
            'Forfeited',
            'Destroyed'
        ];

        if (!status && status !== 0) {
            console.warn('Unkown test status: ', status, 'Defaulting to "None"');
        }

        return statuses[status] || 'None';
    }

    public getOpacityLevel(percent) {
        if (percent >= 100) {
            // doesn't really do anything
            return 'opacity-100';
        } else if (percent >= 67) {
            return 'opacity-75';
        } else if (percent >= 34) {
            return 'opacity-50';
        } else if (percent > 0) {
            return 'opacity-25';
        } else {
            return 'background-incorrect';
        }
    }

    public addLoadingOverlay(isFullScreen = false) {
        this._loadingCountSource.next({
            requests: this._loadingCountSource.value.requests + 1,
            isFullScreen,
        });
    }

    public removeLoadingOverlay() {
        this._loadingCountSource.next({
            requests: this._loadingCountSource.value.requests - 1,
            isFullScreen: false,
        });
    }

    public scrollToTop() {
        // This gets animated via CSS scroll-behavior: smooth;
        window.scrollTo(0, 0);
        // let scrollInterval = window.setInterval(() => {
        //     let pos = window.pageYOffset;
        //     if (pos > 0) {
        //         window.scrollTo(0, pos - 50);
        //     } else {
        //         window.clearInterval(scrollInterval);
        //     }
        // }, 16);
    }

    public blockModalClose(modalName = 'any') {
        // Alerts user if they try to close browser or navigate away
        window.addEventListener('beforeunload', this.warnBeforeUnload);
    }

    public unblockModalClose(modalName = 'any') {
        // Do not alert the user when they are trying to close browser anymore
        window.removeEventListener('beforeunload', this.warnBeforeUnload);
    }

    private warnBeforeUnload(event) {
        // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
        event.preventDefault();
        event.returnValue = 'You are about to leave this test.';
        return 'You are about to leave this test.';
    }

    public closeAllModals(reason = 'Closing all open modals') {
        this.unblockModalClose();
        this.ngbmodal.dismissAll(reason);
    }

    // Returns x and y values from an svg element transform attribute string
    public getXYFromTranslate(transform) {
        // Create a dummy g for calculation purposes only. This will never
        // be appended to the DOM and will be discarded once this function
        // returns.
        let g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

        // Set the transform attribute to the provided string value.
        g.setAttributeNS(null, 'transform', transform);

        // consolidate the SVGTransformList containing all transformations
        // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
        // its SVGMatrix.
        let matrix = g.transform.baseVal.consolidate().matrix;

        // As per definition values e and f are the ones for the translation.
        return [matrix.e, matrix.f];
    }

    public isIE() {
        let ua = window.navigator.userAgent;

        return ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
    }

    public isEdge() {
        return window.navigator.userAgent.indexOf('Edge/') > 0;
    }

    // ported from Utility
    public translateString(x, y) {
        // minimal validation because we want this function to be efficient, as it is called frequently
        if (arguments.length === 1) {
            y = x[1];
            x = x[0];
        }

        return `translate(${x}, ${y})`;
    }
}
