<form [formGroup]="npForm" class="set-password-form flex-column flex-auto space-children-column medium-space" (ngSubmit)="setPassword()">
    <div class="bold capitalize text-center">Set A New Password</div>

    <div class="form-error-messages text-center" *ngIf="passwordResetFailMessage">
        <div class="error-message body-regular">{{ passwordResetFailMessage }}</div>
    </div>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>New Password</mat-label>
        <input matInput type="password" autocomplete="new-password" id="set-password-password" formControlName="password" (input)="resetFormErrors()" name="setPassword" minlength="6" maxlength="75" required>
        <span matSuffix class="text-accent forgot-password text-right">at least 6 characters</span>
        <mat-error *ngIf="password.errors?.required">
            Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="password.errors?.minlength">
            Password must contain at least 6 characters
        </mat-error>
        <mat-error *ngIf="password.errors?.maxlength">
            Password cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>Password Confirmation</mat-label>
        <input matInput type="password" autocomplete="new-password" id="set-password-password-confirm" formControlName="passwordConfirm" name="setPasswordConfirm" (input)="resetFormErrors()" minlength="6" maxlength="75" placeholder="Repeat password" required />
        <mat-error *ngIf="passwordConfirm.errors?.required">
            Password confirmation is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordConfirm.errors?.minlength">
            Password must be at least 6 characters long
        </mat-error>
        <mat-error *ngIf="passwordConfirm.errors?.maxlength">
            Password cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <mat-error *ngIf="npForm.errors?.mismatchedPassword && (password.dirty && passwordConfirm.dirty)" class="no-margin body-xs">
        Passwords do not match
    </mat-error>

    <div class="g-recaptcha" align="center" *ngIf="captcha">
        <ngx-recaptcha2 #captchaElement
                        [siteKey]="captchaSiteKeyV2"
                        formControlName="recaptcha">
        </ngx-recaptcha2>
    </div>

    <button type="submit" class="btn btn-block primary-button alt-theme balloon" [disabled]="npForm.invalid">Change Password</button>

    <button class="btn btn-block primary-button alt-theme-red" (click)="showForm('login')">Cancel</button>
</form>
