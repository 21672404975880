import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '@/core/service/utils.service';
import { FeedbackService } from '@/shared/service/feedback.service';

import './feedback-modal.component.scss';


@Component({
    selector: 'feedback-modal',
    templateUrl: './feedback-modal.component.html',
    // styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit {
    @Input() questions;

    public stage: number;
    public currentSurveyPage: number;
    public progressPercent: number;
    public pageCompleted: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private UtilsService: UtilsService,
        private FeedbackService: FeedbackService,
    ) {}

    public ngOnInit() {
        this.stage = 0;
        this.currentSurveyPage = 0;
        this.progressPercent = 0;
    }

    public beginSurvey() {
        this.stage = 1;
        this.UtilsService.blockModalClose('feedback-modal');
    }

    public changePage(toPage) {
        if (toPage < 0 || toPage >= this.questions.length) {
            return false;
        }

        this.saveCurrentAnswers();

        this.currentSurveyPage = toPage;
        this.progressPercent = Math.round(this.currentSurveyPage / this.questions.length * 100);
    }

    public submitSurvey() {
        this.saveCurrentAnswers();
        this.stage = 2;
        this.UtilsService.unblockModalClose('feedback-modal');
    }

    private saveCurrentAnswers() {
        let questions = this.questions[this.currentSurveyPage];

        if (questions && questions.length > 0) {
            this.FeedbackService.submitSurvey(questions)
                .then(() => {
                    // Remove set of questions from cache since we want to avoid answering multiple times
                    let cachedMap = JSON.parse(localStorage.getItem('learning_map'));
                    cachedMap.feedback.shift();
                    localStorage.setItem('learning_map', JSON.stringify(cachedMap));
                })
                .catch(console.warn);
        }
    }
}
