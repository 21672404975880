import { Component, Input, OnInit } from '@angular/core';

import * as moment from 'moment';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../../../core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { MapService } from '../../../../map/map.service';
import { CalendarService } from '../calendar.service';
import { SequencerStateService } from '../../sequencer-state.service';

import './resource-assignment-modal.component.scss';


@Component({
    selector: 'resource-assignment-modal',
    templateUrl: './resource-assignment-modal.component.html',
    // styleUrls: ['./resource-assignment-modal.component.scss'],
})
export class ResourceAssignmentModalComponent implements OnInit {

    @Input() cluster: any;
    @Input() selectedDate: any;
    private resources: any;
    public filteredResources: any;
    public clusters: any;
    public loadingClusters = false;

    constructor(
        public activeModal: NgbActiveModal,
        private ngbmodal: NgbModal,
        private UtilsService: UtilsService,
        private MapService: MapService,
        private CalendarService: CalendarService,
        private SequencerStateService: SequencerStateService,
    ) {}

    public ngOnInit() {
        this.MapService.getMapResources()
            .then(resources => this.initSavedResourceAssignment(resources))
            .catch(console.warn);

        this.loadingClusters = true;
        this.MapService.getLearningMap()
            .then(map => {
                this.clusters = map.clusters;
            })
            .catch(console.warn)
            .finally(() => this.loadingClusters = false);
    }

    private initSavedResourceAssignment(resources) {
        this.resources = resources;
        // Locate all resources that have saved resource assignments
        this.resources.forEach(resource => {
            let savedResourceAssignments = this.SequencerStateService.data.model.resources
                .filter(resourceAssignment => resource.id === resourceAssignment.resource.id);

            resource.assignedResources = savedResourceAssignments.map(resourceAssignment => {
                let resourceData = {
                    id: resourceAssignment.id, // id of resource assignment saved on server
                    resourceId: resource.id, // id of the resource itself
                    name: resource.name,
                    startDate: new Date(resourceAssignment.start_date),
                    endDate: new Date(resourceAssignment.end_date),
                    dateTitle: '',
                };
                resourceData.dateTitle = resourceData.startDate.toLocaleDateString() + (resourceData.startDate.getTime() === resourceData.endDate.getTime() ? '' : (' - ' + resourceData.endDate.toLocaleDateString()));
                return resourceData;
            });
        });
        this.filterByCluster();
    }

    public filterByCluster() {
        this.filteredResources = this.cluster ? this.resources.filter(resource => {
            return resource.cluster_id.includes(this.cluster.id);
        }) : this.resources;
    }

    public noResourcesSelected() {
        return !this.resources || !this.resources.find(resource => resource.selected);
    }

    public assignResources() {
        let newResources = this.resources
            .filter(resource => resource.selected)
            .map(selectedResource => {
                return {
                    resource: selectedResource.id,
                    start_date: dateToString(this.selectedDate.toDate()),
                    end_date: dateToString(this.selectedDate.toDate()),
                };
            });

        this.UtilsService.addLoadingOverlay(true);
        this.CalendarService.createSspResource(newResources)
            .then(resources => {
                this.SequencerStateService.data.model.resources = resources;
                this.CalendarService.generateResourcesData();
                this.activeModal.close(resources);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public deleteAssignedResource($event) {
        this.UtilsService.addLoadingOverlay(true);
        this.CalendarService.deleteSspResource($event.assignedResource.id)
            .then((result: any) => {
                this.SequencerStateService.data.model.resources = result;
                this.CalendarService.generateResourcesData();
                // Remove assigned resource from view
                $event.resource.assignedResources.splice($event.assignedResourceIdx, 1);
                if ($event.resource.assignedResources.length === 0) {
                    $event.resource.selected = false;
                }
            })
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
