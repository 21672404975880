import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { SessionService } from '@/core/service/session.service';
import { UtilsService } from '@/core/service/utils.service';
import { AssessmentService } from '@/assessment/assessment.service';
import { AssessmentStateService } from '@/assessment/common/assessment-state.service';

import './map-assessment-option-modal.component.scss';


@Component({
    selector: 'map-assessment-option-modal',
    templateUrl: './map-assessment-option-modal.component.html',
    // styleUrls: ['./map-assessment-option-modal.component.scss'],
})
export class MapAssessmentOptionModalComponent implements OnInit {

    @Input() cluster;
    @Input() availableAssessments;
    public hasAssignedTests: boolean;
    public readonly dateFormat = dateFormat;

    constructor(
        public activeModal: NgbActiveModal,
        private $state: StateService,
        public SessionService: SessionService,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.hasAssignedTests = this.availableAssessments.tests.length > 0
            && this.availableAssessments.tests.some(test => !test.completed || test.retest.length > 0);
    }

    public showPracticeAssessment(construct) {
        this.UtilsService.addLoadingOverlay(true);

        this.AssessmentStateService.getPracticeAssessmentInfo(construct)
            .then(() => {
                this.AssessmentService.openPracticeTestModal();
                this.activeModal.close('Closing current modal so as to open practice modal');
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public getSectionAssessment(assp, retestId=0) {
        this.UtilsService.addLoadingOverlay(true);

        this.AssessmentStateService.getSectionAssessmentInfo(assp.id, retestId)
            .then((testInstructions) => {
                this.activeModal.close('Closing current modal so as to open assigned section assessment modal');
                this.AssessmentService.openTestInstructionsModal(testInstructions);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public getStudentTry(gradeband) {
        this.UtilsService.addLoadingOverlay(true);

        this.AssessmentStateService.getStudentTryInfo(this.cluster.id, gradeband)
            .then((testInstructions) => {
                this.activeModal.close('Closing current modal so as to open student try modal');
                this.AssessmentService.openTestInstructionsModal(testInstructions);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public showAssessmentInfo(testformId) {
        this.activeModal.close('Closing current modal so as to open real assessment modal');

        this.UtilsService.addLoadingOverlay();
        // Pass Assessment ID and Testform ID to fetch test
        this.AssessmentStateService.getAssessmentInstructions(this.availableAssessments.assessment, testformId)
            .then(testInfo => this.AssessmentService.openTestInstructionsModal(testInfo))
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public goToClusterReport() {
        if (this.availableAssessments.stats) {
            if ((this.SessionService.userAccess.permission & 2) === 2) {
                this.$state.go('assessment', { t: 1, clusterId: this.cluster.id });
            } else {
                this.$state.go('report', { clusterId: this.cluster.id });
            }
        }
    }
}
