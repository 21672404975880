<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h4 class="modal-title text-center">Data Privacy</h4>
</div>

<div class="modal-body">
    <p>In order to use Math Mapper in your classroom, your district has provided us with roster information that includes student first and last names. This is the only element of student Personally Identifiable Information (PII) we collect. We use this information to assign students to classes provide assessment reports to students and their teachers. These reports list the student names so that the teacher can identify students who may need additional support based on their assessment results.</p>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
    <copyright></copyright>
</div>
