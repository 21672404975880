import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '../core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { ModalService } from '../shared/service/modal.service';
import { AssessmentManagementService } from './assessment-management.service';

import './assessment-management.component.scss';


@Component({
    selector: 'assessment-management-page',
    templateUrl: './assessment-management.component.html',
    // styleUrls: ['./assessment-management.component.scss'],
})
export class AssessmentManagementComponent implements OnInit, OnDestroy {

    @Input() public schoolYears: any;

    public readonly dateFormat = dateFormat;

    constructor(
        private $state: StateService,
        public UtilsService: UtilsService,
        private ModalService: ModalService,
        public AssessmentManagementService: AssessmentManagementService,
    ) {}

    public ngOnInit() {
        if (this.$state.current.name === 'assessmentManage' && this.schoolYears.length) {
            // Default to latest school year
            this.AssessmentManagementService.models.schoolYear = this.schoolYears[0];
            // Pre-select section if it's the only available option in the list
            if (this.AssessmentManagementService.models.schoolYear.sections.length === 1) {
                this.AssessmentManagementService.models.section = this.AssessmentManagementService.models.schoolYear.sections[0];
                // Fetch section data so we can show some tests
                this.sectionSelected(this.AssessmentManagementService.models.section);
            }
        }
    }

    public ngOnDestroy() {
        this.AssessmentManagementService.resetAssessmentManagementModels();
    }

    public schoolYearSelected(schoolYear) {
        if (this.$state.current.name !== 'assessmentManage') {
            this.$state.go('assessmentManage');
        }
        // De-select all other dropdowns
        this.AssessmentManagementService.models.section = null;
    }

    public sectionSelected(section) {
        this.$state.go('assessmentManage.section', {
            schoolYearId: this.AssessmentManagementService.models.schoolYear.id,
            sectionId: this.AssessmentManagementService.models.section.id,
        });
    }
}
