import { UIRouter, Category } from '@uirouter/core';
import { Visualizer } from '@uirouter/visualizer';

import { requiresAuthHook, shouldRedirectHook } from './_guards/auth.guard';
import { pageTrackHook } from './_helpers/page-track';
import { pageChangeWarningHook } from './_helpers/page-change-warning';

export function routerConfigFn(router: UIRouter) {
    const transitionService = router.transitionService;

    requiresAuthHook(transitionService);
    shouldRedirectHook(transitionService);
    pageTrackHook(transitionService);
    pageChangeWarningHook(transitionService);

    // router.trace.enable(Category.TRANSITION);
    // router.plugin(Visualizer);
}
