import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

import { tokenGetter } from '../../../core/service/session.service';
import { AuthService } from '../../../core/service/auth.service';
import { secondsToMinutes } from '@/_helpers/transforms';


@Component({
    selector: 'logout-modal',
    templateUrl: './logout-modal.component.html'
})
export class LogoutModalComponent implements OnInit, OnDestroy {

    @Input() secondsToConfirm: number;
    @Input() instanceMessage: string;
    private countdownTimeout: any;
    public minutesLeft: string;

    constructor(
        public activeModal: NgbActiveModal,
        private jwtHelper: JwtHelperService,
        private AuthService: AuthService,
    ) {}

    public ngOnInit() {
        // In the case of a session expiring, calculate time remaining until logout
        if (!this.secondsToConfirm) {
            let token = tokenGetter();
            const serverLatencyOffset = 3; // 3 second buffer to request logout before token becomes invalid
            const now = new Date().getTime();
            const expire = token ? this.jwtHelper.getTokenExpirationDate(token).getTime() : now + 3000;

            this.secondsToConfirm = Math.floor((expire - now) / 1000) - serverLatencyOffset;
        }

        this.minutesLeft = secondsToMinutes(this.secondsToConfirm);
        // Confirm logout - when logout modal shows, count down 5 seconds and automatically logs user out unless cancelled
        this.countdownTimeout = setTimeout(() => this.countdownToLogout(), 1000);
    }

    public ngOnDestroy() {
        clearTimeout(this.countdownTimeout);
        this.countdownTimeout = null;
    }

    public manualLogout() {
        this.AuthService.logout()
            .catch(console.warn);

        this.activeModal.close('logged out');
    }

    public extendSession() {
        this.AuthService.refresh()
            .catch(console.warn);

        this.activeModal.close('stayed logged in');
    }

    private countdownToLogout() {
        if (this.secondsToConfirm <= 0) {
            return this.manualLogout();
        }
        this.secondsToConfirm -= 1;
        this.minutesLeft = secondsToMinutes(this.secondsToConfirm);
        this.countdownTimeout = setTimeout(() => this.countdownToLogout(), 1000);
    }
}
