<div class="assessment-page-content page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2 class="page-title">Student Hub: My Assigned Tests and Reports</h2>
    </div>
</div>

<div class="assessment-page-content page-content bottom container-fluid">
    <mat-tab-group color="accent" [selectedIndex]="selectedIndex" (selectedIndexChange)="updateStateParams($event)">
        <mat-tab label="My Assigned Tests">
            <ng-template matTabContent>
                <student-assigned-tests></student-assigned-tests>
            </ng-template>
        </mat-tab>

        <mat-tab label="My Reports">
            <ng-template matTabContent>
                <student-reports></student-reports>
            </ng-template>
        </mat-tab>

        <mat-tab label="User Assigned Tests" *ngIf="SessionService.userAccess?.permission >= 128">
            <ng-template matTabContent>
                <assigned-testforms></assigned-testforms>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
