import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { selectAll as d3SelectAll } from 'd3-selection';

import { MapService } from '../map.service';
import { AnalyticsService } from '../../core/service/analytics.service';

import './map-search-panel.component.scss';


@Component({
    selector: 'map-search-panel',
    templateUrl: './map-search-panel.component.html',
    // styleUrls: ['./map-search-panel.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    host: {
        class: 'map-search-panel',
    },
})
export class MapSearchPanelComponent implements OnChanges {

    constructor(
        public MapService: MapService,
        private AnalyticsService: AnalyticsService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
    }

    public loadMoreItems() {
        this.MapService.searchable.meta.itemLimit += 8;
    }

    public closePanel() {
        this.MapService.searchable.meta.isOpen = false;
        this.MapService.searchable.meta.displaySelected = false;
        // Wait until close animation is complete to remove selected item
        setTimeout(() => this.MapService.searchable.meta.selected = null, 500);
    }

    public clearSearch() {
        this.MapService.searchable.meta.searchText = '';
        this.MapService.filterSearchableData();
    }

    public selectStandard(standard) {
        this._toggleItem(standard, 'standard');
    }

    public isSecondaryStandard(item) {
        let text = this.MapService.searchable.meta.searchText;
        return item.secondary.some(construct => construct.code === text || construct.name === text);
    }

    public selectMisconception(misconception) {
        this._toggleItem(misconception, 'misconception');
    }

    private _toggleItem(item, searchableType) {
        // Remove pulse animation from pins
        d3SelectAll('.pin-pulse').property('repeat', 0);
        if (this.MapService.searchable.meta.selected && this.MapService.searchable.meta.selected.id === item.id) {
            this.MapService.searchable.meta.selected = null;
            this.MapService.searchable.meta.displaySelected = false;
        } else {
            this.MapService.searchable.meta.selected = item;
            this.MapService.searchable.meta.displaySelected = true;
            this.AnalyticsService.action({action: 'select_' + searchableType, id: item.id});
            this.MapService.searchable.linkedFunctions.displayPins(item, searchableType);
        }
    }
}
