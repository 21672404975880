<div class="modal-header">
    <h4 class="modal-title">Non-instructional Day(s)</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body">
    <div class="flex-row align-items-center space-children-row">
        <mat-form-field class="flex-50" appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput type="text" [matDatepicker]="startDate" placeholder="mm/dd/yyyy" [(ngModel)]="blockedDays.start_date" id="start_date" name="startdate" required>
            <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-50" appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput type="text" [matDatepicker]="endDate" placeholder="mm/dd/yyyy" [(ngModel)]="blockedDays.end_date" id="end_date" name="endDate" required>
            <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
    </div>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Note</mat-label>
        <textarea   matInput
                    id="blocked-day-note-input"
                    [(ngModel)]="blockedDays.note"
                    placeholder="Reason (optional)"
                    required></textarea>
    </mat-form-field>
</div>

<div class="modal-footer flex-row align-items-center justify-center space-children-row">
    <button class="btn primary-button" (click)="addBlockedDays()">Save</button>
    <button class="btn ghost-button" (click)="activeModal.dismiss()">Close</button>
</div>
