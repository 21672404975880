import { Component, Input, ElementRef, AfterViewInit } from '@angular/core';

import { apiServer } from '@/app.constant';
import { SessionService } from '@/core/service/session.service';
import { AssessmentStateService } from '@/assessment/common/assessment-state.service';


@Component({
    selector: 'cms-item-link',
    templateUrl: './cms-item-link.component.html'
})
export class CmsItemLinkComponent implements AfterViewInit {

    public readonly apiServer = apiServer;

    constructor(
        public SessionService: SessionService,
        public AssessmentStateService: AssessmentStateService,
        private element: ElementRef,
    ) {}

    public ngAfterViewInit() {
        if (this.SessionService.userAccess && this.SessionService.userAccess.permission < 32) {
            this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
        }
    }
}
