import { TransitionService } from '@uirouter/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from '@/core/service/auth.service';
import { SessionService, tokenGetter } from '@/core/service/session.service';

/**
 * Transition Hook which protects a route that requires authentication.
 *
 * This hook redirects to / when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
    // Matches if the destination state's data property has a truthy 'requiresAuth' property
    const requiresAuthCriteria = {
        to: (state) => state.data && state.data.requiresAuth
    };

    // Function that returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)

    const redirectToLogin = (transition) => {
        const token = tokenGetter();
        const jwtHelper: JwtHelperService = transition.injector().get(JwtHelperService);
        const authService: AuthService = transition.injector().get(AuthService);
        const sessionService: SessionService = transition.injector().get(SessionService);
        const user = sessionService.getUser();

        if (!token || jwtHelper.isTokenExpired(token) || user.access.length === 0) {
            authService.logout({
                isForcedLogout: true,
                nextStateName: transition.to().name,
                nextStateParams: transition.params(),
            }).catch(console.warn);

            return false;
        } else if (!user.access.includes(transition.to().name)) {
            console.warn('User does not have permission to access page: ', transition.to().name);

            // Redirect to assigned default page
            return transition.router.stateService.target(user.page || 'map');
        }
    };

    // Check user auth before beginning a transition to a new route/state
    // Register the "requires auth" hook with the TransitionsService
    transitionService.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
}

/**
 * Transition Hook which redirects user when already logged in
 */
export function shouldRedirectHook(transitionService: TransitionService) {
    // Matches if the destination state's data property has a truthy 'redirect' property
    const shouldRedirectCriteria = {
        to: (state) => state.data && state.data.redirect
    };

    const redirectToMap = (transition) => {
        const token = tokenGetter();
        const jwtHelper: JwtHelperService = transition.injector().get(JwtHelperService);
        const sessionService: SessionService = transition.injector().get(SessionService);
        const user = sessionService.getUser();

        // If already logged in (token exists), redirect user to default page
        if (token && !jwtHelper.isTokenExpired(token)) {
            return transition.router.stateService.target(user.page || 'map');
        } else {
            // Allow user to go through login process without errors if token is expired
            sessionService.destroy();
        }
    };

    transitionService.onBefore(shouldRedirectCriteria, redirectToMap, { priority: 1 });
}
