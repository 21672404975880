export { ConfirmModalComponent } from './confirm/confirm-modal.component';
export { ContactModalComponent } from './contact/contact-modal.component';
export { AboutModalComponent } from './about/about-modal.component';
export { HelpModalComponent } from './help/help-modal.component';
export { AccessibilityModalComponent } from './accessibility/accessibility-modal.component';
export { DataPrivacyModalComponent } from './data-privacy/data-privacy-modal.component';
export { TOUModalComponent } from './terms-of-use/terms-of-use-modal.component';
export { OptOutModalComponent } from './opt-out/opt-out-modal.component';
export { HowToVideoModalComponent } from './how-to-video/how-to-video-modal.component';
export { FreePracticeIntroModalComponent } from './free-practice-intro/free-practice-intro-modal.component';
export { LogoutModalComponent } from './logout/logout-modal.component';
export { MapClusterResourcesModalComponent } from './map-resources/map-cluster-resources-modal.component';
export { StudentTestStatusModalComponent } from './student-test-status/student-test-status-modal.component';
export { StudentTriesActivityModalComponent } from './student-tries-activity/student-tries-activity-modal.component';
export { StudentPracticeActivityModalComponent } from './student-practice-activity/student-practice-activity-modal.component';
export { FeedbackModalComponent } from './feedback/feedback-modal.component';
