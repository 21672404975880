import { Directive, OnChanges, ElementRef, Input, SimpleChanges } from '@angular/core';

import { apiServer } from '@/app.constant';


@Directive({
    selector: '[mathHTML]',
})
export class MathHTMLDirective implements OnChanges {

    @Input() mathHTML: string;
    @Input() truncateChars: number = 100;
    @Input() truncateEnable: boolean = false;

    public constructor(private element: ElementRef) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.mathHTML.currentValue) {
            this.updateHTML(change.mathHTML.currentValue);
        }
    }

   private updateHTML(newValue: string) {
        newValue = newValue.replace(
            /src="(https?:\/\/.*sudds\.co)?\/media\/wysiwyg/ig,
            `src="#{apiServer.domain}/media/wysiwyg`
        ).replace(/<br.{0,2}>/ig, '');

        this.element.nativeElement.innerHTML = newValue;

        if (this.truncateEnable) {
            const text = this.element.nativeElement.innerText.trim();

            if (text.length > this.truncateChars) {
                this.element.nativeElement.innerText = text.substr(0, this.truncateChars) + '...';
            }
        }

        this.compileMathJax(this.element.nativeElement);
    }

    private compileMathJax(node) {
        MathJax.Hub.Queue(['Typeset', MathJax.Hub, node]);
    }
}
