import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { SchoolReportService } from '@/report/school/school-report.service';

// import './performance-box-plot.component.scss';


@Component({
    selector: 'performance-box-plot',
    templateUrl: 'performance-box-plot.component.svg.html',
    // styleUrls: ['./performance-box-plot.component.scss'],
    host: {
        class: 'performance-box-plot'
    }
})
export class PerformanceBoxPlotComponent implements OnInit, OnChanges {

    @Input() public graphData: any;
    @Input() public graphParams: any;
    private COLOR_PALETTE = [
        '#4A4BA3', '#4ABAA3', '#C5396C', '#877B5D', '#FF8333', '#A093B7', '#F60563', '#F3A6CE',
        '#C690FF', '#FDC8AD', '#D4575A', '#AD97DC', '#5D7299', '#D08E75', '#FFD933', '#FBF48B',
        '#D0E1D1', '#FDC8AD', '#FFCC82', '#D5D4DC', '#DACE4C', '#FAA7A5', '#63C8CF', '#6AC15F',
        '#DEA235', '#B9A991', '#A19C98', '#ADB2C7', '#8AC6A1', '#A0A53E', '#A88E59', '#F67B62',
        '#7C91CD', '#5A94D2', '#AC7545', '#F87929', '#5B644E', '#786F81', '#346EA0', '#4C5A63',
        '#174670', '#923439', '#54762A', '#E57E00', '#6D8E84', '#C30030', '#938BD4', '#CAC37A',
        '#A15227', '#7A92A9', '#2A6D66', '#9D8942', '#C0A19B', '#FFD400', '#D9E6EB', '#2897FF',
    ];
    public colorMap: any;
    public colorIdx: number;
    public boxPlotData: any = [];
    public selectedIndex: number = 0;

    constructor(
        public SchoolReportService: SchoolReportService,
    ) {}

    public ngOnInit() {

    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.graphData && change.graphData.currentValue) {
            this.formatPlotlyGraphData();
        }
    }

    public formatPlotlyGraphData() {
        this.colorMap = {};
        this.colorIdx = 0;
        this.boxPlotData = [];
        this.SchoolReportService.graphLayout = {
            autosize: true,
            showlegend: true,
            height: window.innerHeight - 250,
            boxmode: 'group',
            boxgap: 0.3,
            boxgroupgap: 0.3,
            // hovermode: 'closest',
            margin: { t: 50, b: 50, l: 50, r: 10 },
            // title: {
            //     text: this.SchoolReportService.getChartTitle(this.graphParams),
            //     font: { family: 'Lato, Arial', size: 16, color: 'rgba(0,0,0,0.65)' }
            // },
            xaxis: {
                visible: true,
                automargin: true,
                title: {
                    text: 'School',
                    font: { family: 'Lato, Arial', size: 10, color: 'rgba(0,0,0,0.59)' }
                },
                color: 'rgba(0,0,0,0.68)',
                tickfont: { family: 'Lato, Arial', size: 11, color: 'rgba(0,0,0,0.68)' },
                // ticks: 'outside',
                // ticklen: 3,
            },
            yaxis: {
                visible: true,
                automargin: true,
                title: {
                    text: 'Score',
                    font: { family: 'Lato, Arial', size: 10, color: 'rgba(0,0,0,0.55)' }
                },
                tickmode: 'array',
                tickvals: [0, 25, 50, 75, 100],
                ticktext: ['0%', '25%', '50%', '75%', '100%'],
                range: [-5, 105],
                tickfont: { family: 'Lato, Arial', size: 10, color: 'rgba(0,0,0,0.55)' },
                // zeroline: false,
            },
            annotations: [],
        };

        const x2Param = JSON.parse(this.graphParams.grades || this.graphParams.courses);
        const groupCount = JSON.parse(this.graphParams.schools).length * JSON.parse(this.graphParams.school_years).length * x2Param.length;
        // Decrease space as more box plots are added
        const nSpacing = Math.min(Math.max(30, 80 - (8 * groupCount)), 80);

        this.graphData.forEach((schoolYear, syIdx) => {
            const schools = Object.keys(schoolYear.schools);
            const startYear = new Date(schoolYear.start + 'T00:00').toLocaleDateString('en-US', { year: '2-digit' });
            const endYear = new Date(schoolYear.end + 'T00:00').toLocaleDateString('en-US', { year: '2-digit' });

            schools.forEach((schoolName, snIdx) => {
                const schoolDetails = schoolYear.schools[schoolName];
                const x2Labels = Object.keys(schoolDetails);

                x2Labels.forEach((x2Label, x2Idx) => {
                    const x2Details = schoolDetails[x2Label];
                    const testTypes = Object.keys(x2Details);

                    testTypes.forEach((testType, ttIdx) => {
                        let score = x2Details[testType];
                        score = score.length === 0 ? [null] : score;
                        const xLabel = `${x2Label} ${schoolName} (${schoolYear.name})`;
                        // Repeat xLabel for as many scores as there are to group box plots
                        const xAxisGroupLabels = [...new Array(score.length)].map(u => xLabel);

                        let legendLabel: string = '';
                        if (testType === 'tests') {
                            legendLabel = 'First Test Score';
                        } else if (testType === 'overall') {
                            legendLabel = 'Most Recent Score';
                        }

                        const existingBoxPlotGroup = this.boxPlotData.find(t => t.name === legendLabel);

                        if (existingBoxPlotGroup) {
                            existingBoxPlotGroup.x = existingBoxPlotGroup.x.concat(xAxisGroupLabels);
                            existingBoxPlotGroup.y = existingBoxPlotGroup.y.concat(score);
                        } else {
                            const boxPlotData: any = {
                                type: 'box',
                                x: xAxisGroupLabels,
                                y: score,
                                name: legendLabel,
                                boxmean: true,
                            };

                            this.boxPlotData.push(boxPlotData);

                            // Only assign color if scores exist. No scores traces are already omitted
                            this.assignTraceColor(this.boxPlotData[this.boxPlotData.length - 1], legendLabel);
                        }

                        // Add annotation for this box plot that show n= number of data points
                        const xCount = this.SchoolReportService.graphLayout.annotations.filter(an => an.x === xLabel).length;
                        const showN = score.length >= 1 && score[0] !== null;
                        this.SchoolReportService.graphLayout.annotations.push({
                            x: xLabel,
                            xshift: xCount * nSpacing - nSpacing / 2,
                            y: 0,
                            yshift: -15,
                            name: legendLabel,
                            text: showN ? `n=${score.length}` : '',
                            textangle: groupCount < 7 ? 0 : 30,
                            font: { family: 'Lato, Arial', size: 12, color: this.colorMap[legendLabel] },
                            showarrow: false,
                            arrowcolor: 'rgba(0, 0, 0, 0.59)',
                            arrowhead: 2,
                            arrowsize: 0.75,
                            ax: xLabel,
                            axref: 'x',
                        });
                    });
                });
            });
        });

        this.SchoolReportService.isGraphReset = false;
        this.SchoolReportService.isGraphEmpty = this.boxPlotData.every(plotGroup => plotGroup.y.every(score => !score && score !== 0));

        this.SchoolReportService.getChartTitle(this.graphParams);
    }

    private getChartTitle() {
        let title = '';

        title += 'Test Scores';

        return title;
    }

    private assignTraceColor(newTrace, legendLabel) {
        if (this.colorMap[legendLabel]) {
            newTrace.showlegend = false;
        } else {
            this.colorMap[legendLabel] = this.COLOR_PALETTE[this.colorIdx++];
        }

        newTrace.marker = { color: this.colorMap[legendLabel] };

        if (!this.COLOR_PALETTE[this.colorIdx]) {
            this.colorIdx = 0;
        }
    }
}
