import { Component, Input } from '@angular/core';

import { CalendarService } from '../calendar.service';
import { SequencerStateService } from '../../sequencer-state.service';

import './calendar-days.component.scss';


@Component({
    selector: 'calendar-days',
    templateUrl: './calendar-days.component.html',
    // styleUrls: ['./calendar-days.component.scss'],
})
export class CalendarDaysComponent {
    @Input() isMonth: boolean;

    constructor(
        public CalendarService: CalendarService,
        public SequencerStateService: SequencerStateService,
    ) {}
}
