<button class="btn stack-level-indicator flex-none justify-space-between align-items-center"
        [class.selected-question]="includesLastQuestionViewed()"
        [disabled]="stackQuestions.length === 0"
        (click)="selectStack(assp.course.id, assp.section.id)"
        #multiStackQuestionTrigger="ngbPopover"
        [ngbPopover]="multiStackQuestion"
        container="body"
        [disablePopover]="stackQuestions.length <= 1"
        [autoClose]="'outside'"
        placement="bottom-left"
        popoverClass="popover-dropdown multi-question-popover"
        [matTooltip]="'View Item' + (stackQuestions.length > 1 ? 's' : '') + (stack.itemDifficulty ? ': An item was unusually hard or easy for this level' : '')"
        matTooltipClass="mat-tooltip-margin-sm"
        matTooltipPosition="above">
    <span class="level-text">
        {{ stack.order }}<span *ngIf="stack.itemDifficulty" class="item-difficulty-asterisk">*</span>
    </span>
    <i class="icon icon-caret-down" [class.rotate]="multiStackQuestionTrigger.isOpen()" *ngIf="stackQuestions.length > 1"></i>
</button>

<ng-template #multiStackQuestion>
    <div class="list-item flex-row" role="menuitem">
        <div *ngFor="let question of stackQuestions; index as $index" matTooltip="Show all students who took this question" matTooltipPosition="above" matTooltipClass="mat-tooltip-margin-sm" class="cursor-pointer flex align-items-center single-question" [ngClass]="{ 'active-question': question.id === HeatmapService.filters.highlightedQuestion }" (click)="highlightQuestion(question.id)">
            <span>{{ stack.order }}{{ UtilsService.getLetterFromIndex($index) }}</span>
            <span *ngIf="question.difficulty === 1" class="item-difficulty" matTooltip="This question was aligned to this level but it was unusually easy">
                <i class="icon icon-arrow-down-1"></i>
            </span>
            <span *ngIf="question.difficulty === 2" class="item-difficulty" matTooltip="This question was aligned to this level but it was unusually hard">
                <i class="icon icon-arrow-up-1"></i>
            </span>
        </div>
    </div>

    <div class="list-item flex-row" role="menuitem">
        <div *ngFor="let question of stackQuestions" class="cursor-pointer flex align-items-center question-view" [ngClass]="{ 'selected-question': question.id === lastQuestionViewed }" (click)="fetchQuestion(question.id, assp.course.id, assp.section.id)">
            <span class="flex-none">View Item</span>
        </div>
    </div>
</ng-template>
