<div class="flex-none align-items-center">
    <button class="btn ghost-button icon-button sidenav-toggle" *ngIf="route.data.requiresAuth" (click)="toggleSidenav($event)">
        <i class="fas fa-bars"></i>
    </button>

    <div class="logo-container">
        <a uiSref="landing" href="/" class="logo-anchor">
            <img class="logo" src="/src/assets/image/math-mapper-logo.png">
        </a>
    </div>
</div>

<div class="logged-in-user" *ngIf="SessionService.userAccess">
    <!-- Tour Access Tip Popover -->
    <ng-template #coachmarksOptOut>
        <div>You can access the tour at any time through the User Panel.</div>

        <div class="flex-row justify-end">
            <button class="btn primary-button alt-theme body-regular" (click)="closePopover()">Got It!</button>
        </div>

        <div class="flex-row">
            <mat-checkbox id="tour-opt-out-checkbox" *ngIf="SessionService.userProfile" [(ngModel)]="SessionService.userProfile.tour_opt" [disableRipple]="true">Don't show me this again.</mat-checkbox>
        </div>
    </ng-template>
    <div class="ghost-popover-container" [ngbPopover]="coachmarksOptOut" popoverTitle="Tour Access" [autoClose]="false" triggers="manual" #tourOptOutTrigger="ngbPopover" placement="bottom-right" popoverClass="popover-simple contained tour-access-popover"></div>

    <div ngbDropdown #accountDropdown="ngbDropdown" (openChange)="checkCoachMarkPopover()" [autoClose]="true" placement="bottom-right">
        <!-- User Profile Dropdown Menu Trigger -->
        <div class="user-dropdown-toggle flex-none align-items-center justify-center space-children-row" ngbDropdownToggle id="user-dropdown-toggle">
            <div class="icon-container flex-none align-items-start justify-center">
                <i class="fas fa-user"></i>
            </div>

            <div class="user-summary">
                <div class="user-name">{{ SessionService.userProfile?.first_name }}</div>
                <div class="user-role">{{ SessionService.userAccess.roles[SessionService.userAccess.roles.length - 1] }}</div>
            </div>
        </div>

        <!-- User Profile Dropdown Menu -->
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labeledby="user-dropdown-toggle">
            <div *ngIf="SessionService.userAccess.access.indexOf('account') > -1">
                <a class="link-container" uiSrefActive="active-page" uiSref="account">Profile</a>

                <div class="hr small-size"></div>
            </div>

            <div *ngIf="SessionService.userAccess.access.indexOf('map') > -1 && !route.data.requiresAuth">
                <a class="link-container" uiSrefActive="active-page" uiSref="map">Return to Map</a>

                <div class="hr small-size"></div>
            </div>

            <div class="hidden-xs" *ngIf="route.data.requiresAuth">
                <div *ngIf="SessionService.userAccess.permission === 3">
                    <div class="link-container">"How to" Videos:</div>
                    <div class="link-container flex-none align-items-center space-children-row small-space" (click)="openVideo('map')" [class.completed]="coachmarkState.isMapCoachMarkCompleted">
                        <i class="icon icon-video-camera text-accent"></i>
                        <span class="tour-section">Use the Map</span>
                    </div>
                    <div class="link-container flex-none align-items-center space-children-row small-space" (click)="openVideo('practice')" [class.completed]="coachmarkState.isMapCoachMarkCompleted">
                        <i class="icon icon-video-camera text-accent"></i>
                        <span class="tour-section">Practice</span>
                    </div>
                    <div class="link-container flex-none align-items-center space-children-row small-space" (click)="openVideo('tries')" [class.completed]="coachmarkState.isMapCoachMarkCompleted">
                        <i class="icon icon-video-camera text-accent"></i>
                        <span class="tour-section">Use &quot;Tries&quot;</span>
                    </div>
    
                    <div class="hr small-size"></div>
                </div>

                <div class="link-container" (click)="takeTour(0)">Take a Tour</div>
                <div class="link-container flex-none align-items-center space-children-row small-space" (click)="takeTour(coachmarksMeta.map.start)" [class.completed]="coachmarkState.isMapCoachMarkCompleted">
                    <i class="icon icon-check text-accent"></i>
                    <span class="tour-section">Navigating the Map</span>
                </div>
                <div class="link-container flex-none align-items-center space-children-row small-space" (click)="takeTour(coachmarksMeta.test.start)" [class.completed]="coachmarkState.isTestCoachMarkCompleted">
                    <i class="icon icon-check text-accent"></i>
                    <span class="tour-section">Taking Tests</span>
                </div>
                <div class="link-container flex-none align-items-center space-children-row small-space" (click)="takeTour(coachmarksMeta.report.start)" [class.completed]="coachmarkState.isReportCoachMarkCompleted">
                    <i class="icon icon-check text-accent"></i>
                    <span class="tour-section">Viewing Reports</span>
                </div>
                <div class="link-container flex-none align-items-center space-children-row small-space" (click)="takeTour(coachmarksMeta.resourcer.start)" [class.completed]="coachmarkState.isResourcerCoachMarkCompleted">
                    <i class="icon icon-check text-accent"></i>
                    <span class="tour-section">Exploring Resources</span>
                </div>
                <div class="link-container flex-none align-items-center space-children-row small-space" (click)="takeTour(coachmarksMeta.sequencer.start)" [class.completed]="coachmarkState.isSequencerCoachMarkCompleted" *ngIf="SessionService.userAccess.access.includes('sequencer')">
                    <i class="icon icon-check text-accent"></i>
                    <span class="tour-section">Using the Sequencer</span>

                </div>

                <div class="hr small-size"></div>

                <div *ngIf="SessionService.userAccess.permission === 3">
                    <div class="link-container" (click)="openOptOut()">Data Agreement Page</div>
    
                    <div class="hr small-size"></div>
                </div>
            </div>

            <div class="link-container" (click)="openLogoutModal()">Log Out</div>
        </div>
    </div>
</div>

<div class="logged-out-user flex-none align-items-center space-children-row" *ngIf="!SessionService.userAccess">
    <ng-template #freePracticeLogin>
        <free-practice-login class="free-practice-login"></free-practice-login>
    </ng-template>
    <button class="btn primary-button body-regular upcase"
            #freePracticeLoginTrigger="ngbPopover"
            [ngbPopover]="freePracticeLogin"
            (click)="triggerLogin('practice')"
            placement="bottom-right"
            [autoClose]="false"
            triggers="manual"
            popoverClass="popover-simple free-practice-login-popover">
            Free Practice Log In
    </button>
    
    <a class="btn primary-button alt-theme body-regular upcase" uiSref="login">School Log In</a>

    <ng-template #guestLogin>
        <guest-login class="guest-login"></guest-login>
    </ng-template>
    <button class="btn ghost-button body-regular upcase"
        #guestLoginTrigger="ngbPopover"
        [ngbPopover]="guestLogin"
        (click)="triggerLogin('guest')"
        placement="bottom-right"
        [autoClose]="false"
        triggers="manual"
        popoverClass="popover-simple guest-login-popover">
        Guest Log In
    </button>
</div>
