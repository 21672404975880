<svg:g
    class="region-label-popover zoom-level-transition"
    *ngIf="region.showPopover"
    [attr.transform]="'translate(' + (region.attributes.x + mapSizesAndBounds.region.width * 0.96 - 15 * mapSizesAndBounds.region.iButtonScaling[MapService.learningMap.option.currentZoomLevel]) + ', ' + (region.attributes.y + mapSizesAndBounds.region.height * 0.95 + 15) + ') scale(' + (mapSizesAndBounds.region.iButtonScaling[MapService.learningMap.option.currentZoomLevel] / 1.1) + ')'">
    <svg:g transform="translate(-195, 0)">
        <!-- triangular pointer that looks like it is part of the label -->
        <svg:path class="triangle-tip" transform="translate(195, 0)" d="M0,0 L-22,22 L22,22 Z"></svg:path>

        <foreignObject x="0" y="21" width="400" height="250" (click)="hidePopover($event)">
            <xhtml:div class="region-label">
                <xhtml:div class="region-code" *ngIf="MapService.learningMap.option.codeEnabled">{{ region.code }}</xhtml:div>
                <xhtml:span class="region-description" [innerHTML]="region.description"></xhtml:span>
            </xhtml:div>
        </foreignObject>
    </svg:g>
</svg:g>
