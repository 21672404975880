import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { datepickerOptions } from '@/app.constant';
import { dateToString } from '@/_helpers/transforms';
import { DateValidators } from '@/shared/directives';
import { AssessmentManagementService } from '../../assessment-management/assessment-management.service';
import { CourseManagementService } from '../course-management/course-management.service';

import './new-course.component.scss';


@Component({
    selector: 'new-course-page',
    templateUrl: 'new-course.component.html',
    // styleUrls: ['./new-course.component.scss'],
})
export class NewCourseComponent implements OnInit {

    public form: FormGroup;
    public grades = [];
    public schoolYears = [];
    public datepickerOptions = Object.assign({}, datepickerOptions);
    public loadingSchoolYears = false;
    public loadingGrades = false;

    constructor(
        private fb: FormBuilder,
        private AssessmentManagementService: AssessmentManagementService,
        private CourseManagementService: CourseManagementService,
    ) {
        this.form = this.fb.group({
            code:            [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(20)]],
            name:            [{ value: null, disabled: false }, Validators.maxLength(75)],
            start_date:      [{ value: null, disabled: true },  Validators.required],
            end_date:        [{ value: null, disabled: true },  Validators.required],
            section_num:     [{ value: 1, disabled: false },    Validators.required],
            grades:          [{ value: [], disabled: true },    Validators.required],
            school_year:     [{ value: null, disabled: true },  Validators.required],
        }, {
            validators: [
                DateValidators.dateLessThan('start_date', 'end_date', { 'datelt': true }),
            ],
        });
    }

    public ngOnInit() {
        this.loadingSchoolYears = true;
        this.AssessmentManagementService.getTeacherSchoolYear('current')
            .then(schoolYears => {
                this.schoolYears = schoolYears;
                this.form.controls.school_year.enable();
                if (this.schoolYears.length === 1) {
                    this.form.controls.school_year.patchValue(this.schoolYears[0].id);
                    this.selectSchoolYear(this.schoolYears[0]);
                }
            })
            .catch(console.warn)
            .finally(() => this.loadingSchoolYears = false);

        this.loadingGrades = true;
        this.CourseManagementService.getGrades()
            .then(grades => {
                this.grades = grades;
                this.form.controls.grades.enable();
            })
            .catch(console.warn)
            .finally(this.loadingGrades = false);
    }

    public selectSchoolYear(schoolYear) {
        this.datepickerOptions.minDate = new Date(schoolYear.start_date + 'T00:00');
        this.datepickerOptions.maxDate = new Date(schoolYear.end_date + 'T00:00');

        // Pre-select course to span the duration of the school year
        this.form.controls.start_date.enable();
        this.form.controls.start_date.patchValue(new Date(schoolYear.start_date + 'T00:00'));
        this.form.controls.end_date.enable();
        this.form.controls.end_date.patchValue(new Date(schoolYear.end_date + 'T00:00'));
    }

    public createNewCourse() {
        let data = Object.assign({}, this.form.value);

        data.start_date = dateToString(data.start_date);
        data.end_date = dateToString(data.end_date);
        data.grades = <any>data.grades.join();
        // For some reason we are using PUT requests for creating new courses isntead of POST
        this.CourseManagementService.saveCourse(data, 'PUT')
            .catch(console.warn);
    }
}
