import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';

import { captchaSiteKeyV2, captchaSiteKeyV3 } from '@/app.constant';
import { matchingPasswordValidator } from '../../shared/directives';
import { LoginService } from '../login.service';
import { AuthService } from '../../core/service/auth.service';
import { ModalService } from '../../shared/service/modal.service';
import { UtilsService } from '../../core/service/utils.service';

import './practice-registration-form.component.scss';


@Component({
    selector: 'practice-registration-form',
    templateUrl: './practice-registration-form.component.html',
})
export class PracticeRegistrationFormComponent implements OnChanges {

    @Input() captcha: boolean;
    @ViewChild('captchaElement', { static: false }) captchaElement: ReCaptcha2Component;

    public readonly captchaSiteKeyV2 = captchaSiteKeyV2;
    public rForm: FormGroup;
    public registerFailMessage: string;
    public availLocation: any = [
        { id: 'AL', name: 'Alabama' },
        { id: 'AK', name: 'Alaska' },
        { id: 'AS', name: 'American Samoa' },
        { id: 'AZ', name: 'Arizona' },
        { id: 'AR', name: 'Arkansas' },
        { id: 'CA', name: 'California' },
        { id: 'CO', name: 'Colorado' },
        { id: 'CT', name: 'Connecticut' },
        { id: 'DE', name: 'Delaware' },
        { id: 'DC', name: 'District Of Columbia' },
        { id: 'FM', name: 'Federated States Of Micronesia' },
        { id: 'FL', name: 'Florida' },
        { id: 'GA', name: 'Georgia' },
        { id: 'GU', name: 'Guam' },
        { id: 'HI', name: 'Hawaii' },
        { id: 'ID', name: 'Idaho' },
        { id: 'IL', name: 'Illinois' },
        { id: 'IN', name: 'Indiana' },
        { id: 'IA', name: 'Iowa' },
        { id: 'KS', name: 'Kansas' },
        { id: 'KY', name: 'Kentucky' },
        { id: 'LA', name: 'Louisiana' },
        { id: 'ME', name: 'Maine' },
        { id: 'MH', name: 'Marshall Islands' },
        { id: 'MD', name: 'Maryland' },
        { id: 'MA', name: 'Massachusetts' },
        { id: 'MI', name: 'Michigan' },
        { id: 'MN', name: 'Minnesota' },
        { id: 'MS', name: 'Mississippi' },
        { id: 'MO', name: 'Missouri' },
        { id: 'MT', name: 'Montana' },
        { id: 'NE', name: 'Nebraska' },
        { id: 'NV', name: 'Nevada' },
        { id: 'NH', name: 'New Hampshire' },
        { id: 'NJ', name: 'New Jersey' },
        { id: 'NM', name: 'New Mexico' },
        { id: 'NY', name: 'New York' },
        { id: 'NC', name: 'North Carolina' },
        { id: 'ND', name: 'North Dakota' },
        { id: 'MP', name: 'Northern Mariana Islands' },
        { id: 'OH', name: 'Ohio' },
        { id: 'OK', name: 'Oklahoma' },
        { id: 'OR', name: 'Oregon' },
        { id: 'PW', name: 'Palau' },
        { id: 'PA', name: 'Pennsylvania' },
        { id: 'PR', name: 'Puerto Rico' },
        { id: 'RI', name: 'Rhode Island' },
        { id: 'SC', name: 'South Carolina' },
        { id: 'SD', name: 'South Dakota' },
        { id: 'TN', name: 'Tennessee' },
        { id: 'TX', name: 'Texas' },
        { id: 'UT', name: 'Utah' },
        { id: 'VT', name: 'Vermont' },
        { id: 'VI', name: 'Virgin Islands' },
        { id: 'VA', name: 'Virginia' },
        { id: 'WA', name: 'Washington' },
        { id: 'WV', name: 'West Virginia' },
        { id: 'WI', name: 'Wisconsin' },
        { id: 'WY', name: 'Wyoming' },
        { id: 'ZZ', name: 'Other'}
    ];
    public availUserType: any = [
        { id: 1, name: 'Student' },
        { id: 2, name: 'Teacher' },
        { id: 3, name: 'Other'}
    ];
    public availGradeLevel: any = [
        { id: 10, name: 'Grade 6' },
        { id: 11, name: 'Grade 7' },
        { id: 12, name: 'Grade 8' },
        { id: 1, name: 'K - 3' },
        { id: 2, name: '4 - 5' },
        { id: 13, name: '9 - 10' },
        { id: 15, name: '11 - 12' },
    ];
    public availGradeBand: any = [
        { id: 'Preservice', name: 'Preservice'},
        { id: '6 - 8', name: '6 - 8'},
        { id: 'K - 5', name: 'K - 5'},
        { id: '9 - 12', name: '9 - 12'},
    ];
    public availSchoolType: any = [
        { id: 1, name: 'Public' },
        { id: 2, name: 'Private' },
        { id: 3, name: 'Home school' },
        { id: 4, name: 'Other' }
    ];
    public status: number = 0;

    get first_name() { return this.rForm.get('first_name'); }
    get email() { return this.rForm.get('email'); }
    get password() { return this.rForm.get('password'); }
    get passwordConfirm() { return this.rForm.get('passwordConfirm'); }

    constructor(
        private fb: FormBuilder,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private LoginService: LoginService,
        private AuthService: AuthService,
        private ModalService: ModalService,
        private UtilsService: UtilsService,
    ) {
        this.rForm = this.fb.group({
            first_name:      ['', [Validators.required, Validators.maxLength(30)]],
            email:           ['', [Validators.required, Validators.maxLength(150)]],
            password:        ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            passwordConfirm: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            location:        [{ value: [], disabled: false }, [Validators.required]],
            user_type:       ['', Validators.required],
            grade_level:     [null],
            grade_band:      [null],
            school_type:     [null],
            terms_of_use:    ['', Validators.required],
            recaptcha:       ['', Validators.required],
        }, {
            validators: matchingPasswordValidator
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.captcha && change.captcha.currentValue !== change.captcha.previousValue) {
            this.toggleCaptchaValidation();
        }
    }

    public updateUserType(buttonChange) {
        this.rForm.get('grade_level').setValidators(buttonChange.value !== 1 ?  null : [Validators.required]);
        this.rForm.get('school_type').setValidators(buttonChange.value !== 1 ?  null : [Validators.required]);
        this.rForm.get('grade_band').setValidators(buttonChange.value !== 2 ? null : [Validators.required]);

        if (buttonChange.value == 1) {
            this.rForm.get('grade_band').setValue([]);
        } else if (buttonChange.value == 2) {
            this.rForm.get('grade_level').setValue([]);
            this.rForm.get('school_type').setValue([]);
        } else {
            this.rForm.get('grade_band').setValue([]);
            this.rForm.get('grade_level').setValue([]);
            this.rForm.get('school_type').setValue([]);
        }
    }

    public toggleCaptchaValidation() {
        if (this.captcha) {
            this.rForm.get('recaptcha').enable();
        } else {
            this.rForm.get('recaptcha').disable();
        }
    }

    public resetFormErrors() {
        this.registerFailMessage = null;
    }

    public openTermsOfUse($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
        this.ModalService.openTermsOfUseModal();
    }

    public openOptOut($event) {
        $event.stopPropagation();
        this.ModalService.openOptOutModal()
    }

    public showForm($event, formName) {
        $event.stopPropagation();
        this.resetFormErrors();
        this.LoginService.activeForm = formName;
    }

    public registerUser($event) {
        $event.stopPropagation();

        this.UtilsService.addLoadingOverlay(true);
        this.reCaptchaV3Service.execute(captchaSiteKeyV3, 'register', token => {
            this.AuthService.register_practice(this.rForm.value)
                .then(() => {
                    this.rForm.reset();
                })
                .catch(error => {
                    if (error.result && error.result.msg) {
                        this.captcha = error.result.msg.captcha;
                        this.toggleCaptchaValidation();
                        this.registerFailMessage = error.result.msg.msg || 'The email you entered is already registered. Please try another email or logging in again.';
                    }
                    // Only reset captcha on fail because on success component is destroyed
                    if (this.captchaElement) {
                        this.captchaElement.resetCaptcha();
                    }
                })
                .finally(() => {
                    this.UtilsService.removeLoadingOverlay();
                    this.rForm.patchValue({
                        password: '',
                        passwordConfirm: '',
                        recaptcha: '',
                    });
                });
        });
    }
}
