import { Injectable } from '@angular/core';

import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

import { UserAccess } from '@/_models';

import { apiServer } from '@/app.constant';
import { HttpService } from './http.service';
import { SessionService, tokenGetter } from './session.service';
import { AnalyticsService } from './analytics.service';

import { SessionExpiredModalComponent } from '../session-expired/session-expired-modal.component';
import { OptOutModalComponent } from '../../shared/modal/opt-out/opt-out-modal.component';


@Injectable()
export class AuthService {

    public nextStateName: string;
    public nextStateParams: any;

    constructor(
        private $state: StateService,
        private jwtHelper: JwtHelperService,
        private HttpService: HttpService,
        private SessionService: SessionService,
        private AnalyticsService: AnalyticsService,
        private ngbmodal: NgbModal,
    ) { }


    private loginUser(response): Promise<UserAccess> {
        let session: UserAccess = this.SessionService.create(response.result);

        this.$state.go((this.nextStateName || 'map'), this.nextStateParams);
        this.nextStateName = null;
        this.nextStateParams = null;

        this.getUserProfile();

        return Promise.resolve(session);
    }

    private loginPracticeUser(response): Promise<UserAccess> {
        let session: UserAccess = this.SessionService.create(response.result);

        this.getUserProfile();

        this.ngbmodal.open(OptOutModalComponent, {
            windowClass: 'opt-out-modal',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        }).result;

        return Promise.resolve(session);
    }

    public login(credentials, captchaToken = null) {
        let url = `${apiServer.urlPrefix}/user/login/`;

        if (captchaToken) {
            credentials.captcha = captchaToken;
        }

        return this.HttpService.post(url, credentials)
            .then(response => this.loginUser(response))
            .catch(error => Promise.reject(error));
    }

    public loginWithKey(key) {
        let url = `${apiServer.urlPrefix}/user/login/key/`;
        let credentials = {
            key: key
        };

        return this.HttpService.post(url, credentials)
            .then(response => this.loginUser(response))
            .catch(error => Promise.reject(error));
    }

    public getCaptcha() {
        let url = `${apiServer.urlPrefix}/user/login/captcha/`;

        return this.HttpService.get(url, {});
    }

    public logout({isForcedLogout = false, nextStateName = null, nextStateParams = null} = {}) {
        let url = `${apiServer.urlPrefix}/user/logout/`;
        let token = tokenGetter();

        if (token && !this.jwtHelper.isTokenExpired(token)) {
            return this.HttpService.get(url)
                .finally(() => this.logoutUser(isForcedLogout, nextStateName, nextStateParams));
        }
        return this.logoutUser(isForcedLogout, nextStateName, nextStateParams)
    }

    private logoutUser(isForcedLogout, nextStateName, nextStateParams) {
        this.AnalyticsService.action({ action: (isForcedLogout ? 'forced ' : '') + 'logout' });

        this.SessionService.destroy();

        if (isForcedLogout) {
            // Save attempted state so we can redirect upon successful login
            this.nextStateName = nextStateName;
            this.nextStateParams = Object.assign({}, nextStateParams);
        }

        return this.$state.go('landing').then(newState => {
            if (isForcedLogout) {
                this.openExpiredSessionNotice();
            }
        });
    }

    private openExpiredSessionNotice() {
        if (!document.querySelector('.session-expired-modal')) {
            this.AnalyticsService.action({ action: 'open_session_expired_modal' });

            this.ngbmodal.open(SessionExpiredModalComponent, <any>{
                windowClass: 'session-expired-modal',
                size: 'confirmation',
            });
        }
    }

    public register(user, captchaToken = null) {
        let url = `${apiServer.urlPrefix}/user/register/`;
        if (captchaToken) {
            user.captcha = captchaToken;
        }

        return this.HttpService.post(url, user)
            .then(response => this.loginUser(response))
            .catch(error => Promise.reject(error));
    }

    public register_practice(user, captchaToken = null) {
        let url = `${apiServer.urlPrefix}/user/register/practice/`;
        if (captchaToken) {
            user.captcha = captchaToken;
        }

        return this.HttpService.post(url, user)
        .then(response => {
            this.SessionService.setMapInterstitial();
            this.loginPracticeUser(response);
        })
        .catch(error => Promise.reject(error));
    }

    public refresh() {
        let url = `${apiServer.domain}/auth/refresh/`;
        let accessToken = tokenGetter();
        let refresh = localStorage.getItem('refresh_token');
        let options = Object.assign({ rawResponse: true });

        if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
            return this.HttpService.post(url, { refresh }, options)
                .then(response => {
                    this.SessionService.update(response);

                    return Promise.resolve(response);
                })
                .catch(error => {
                    this.logout({
                        isForcedLogout: true,
                        nextStateName: this.$state.current.name,
                        nextStateParams: this.$state.params,
                    });

                    return Promise.reject(error);
                });
        }
        // Token is expired so skip the logout endpoint
        return this.logoutUser(true, this.$state.current.name, this.$state.params);
    }

    public passwordReset(credentials) {
        let url = `${apiServer.urlPrefix}/user/password_reset/`;

        return this.HttpService.post(url, credentials);
    }

    public setPassword(credentials) {
        let url = `${apiServer.urlPrefix}/user/password_set/`;

        return this.HttpService.post(url, credentials);
    }

    public activateEmail(key, email) {
        let url = `${apiServer.urlPrefix}/user/email/activate/`;
        let serverData = {
            key: key,
            email: email
        };

        return this.HttpService.post(url, serverData);
    }

    public getSchools() {
        let url = `${apiServer.urlPrefix}/schools/`;

        return this.HttpService.get(url, {})
            .then(response => Promise.resolve(response.result))
            .catch(error => Promise.reject(error));
    }

    public getUserProfile() {
        let url = `${apiServer.urlPrefix}/user/profile/`;

        return this.HttpService.get(url, {})
            .then(response => {
                this.SessionService.setUserProfile(response.result);

                return Promise.resolve(response.result)
            })
            .catch(error => Promise.reject(error));
    }
}
