<h4 class="text-info">{{ SchoolReportService.graphTitle }}</h4>

<usage-bar-chart
    *ngIf="graphParams.graph_type === 1 && (graphParams.y_axis === 1 || graphParams.y_axis === 3)"
    [graphData]="graphData"
    [graphParams]="graphParams">
</usage-bar-chart>

<usage-donut-chart
    *ngIf="graphParams.graph_type === 1 && graphParams.y_axis === 2"
    [graphData]="graphData"
    [graphParams]="graphParams">
</usage-donut-chart>

<performance-box-plot
    *ngIf="graphParams.graph_type === 2 && graphParams.g_type == 1"
    [graphData]="graphData"
    [graphParams]="graphParams">
</performance-box-plot>

<performance-stacked-bar-plot
    *ngIf="graphParams.graph_type === 2 && graphParams.g_type == 2"
    [graphData]="graphData"
    [graphParams]="graphParams">
</performance-stacked-bar-plot>
