import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer, appInfo } from '@/app.constant';
import { HttpService } from '../core/service/http.service';
import { AnalyticsService } from '../core/service/analytics.service';
import { UtilsService } from '../core/service/utils.service';

import { MapAssessmentOptionModalComponent } from './map-assessment/map-assessment-option-modal.component';


@Injectable()
export class MapService {

    public zoomChange = new Subject<any>();
    public zoom$: Observable<any> = this.zoomChange.asObservable();

    public learningMap = {
        // Server data: fields, regions, clusters...
        data: {
            fields: [],
            regions: [],
            clusters: [],
            constructs: [],
            stacks: [],
            feedback: [],
        },
        option: {
            showAllRegionLabels: false,
            currentZoomLevel: 0,
            codeEnabled: false,
            isMinimapOpen: false
        },
        gradeNames: JSON.parse(localStorage.getItem('grade_selection')) || [
            { name: 6, selected: true },
            { name: 7, selected: true },
            { name: 8, selected: true }
        ],
        stacksPanel: {
            construct: null,
            constructs: null,
        },
    };
    public learningMapConst = JSON.stringify(this.learningMap);
    public searchable = {
        meta: {
            type: null,
            searchText: '',
            isOpen: false,
            selected: null,
            displaySelected: false,
            itemLimit: 8
        },
        data: [],
        filteredData: [],
        linkedFunctions: {
            displayPins: null
        }
    };
    public searchableMetaConst = JSON.stringify(this.searchable.meta);
    private pendingMapRequest = null;
    private pendingStandardsRequest = null;
    private pendingMiscsRequest = null;

    constructor(
        private sanitizer: DomSanitizer,
        private ngbmodal: NgbModal,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
    ) {}

    public resetLearningMap() {
        this.learningMap = JSON.parse(this.learningMapConst);
        this.searchable.meta = JSON.parse(this.searchableMetaConst);
        this.searchable.filteredData = [];
    }

    // Set standards/misconceptions search input field
    public setSearchText(text) {
        this.AnalyticsService.action({ action: 'set_search_panel_text', text: text });
        this.searchable.meta.displaySelected = false;
        this.searchable.meta.searchText = this.UtilsService.stripHTMLTags(text);
        this.filterSearchableData();
        // Remove pins and clear selected item panel display
        setTimeout(() => {
            this.searchable.meta.selected = null;
        }, 1200);
    }

    public filterSearchableData(text = this.searchable.meta.searchText.toLowerCase()) {
        // Reset back to showing only 8 results at a time and hide any items already selected
        this.searchable.meta.itemLimit = 8;
        this.searchable.meta.selected = null;
        // Stringify the standard/misc to search within deep object properties (e.g. id, desc, array of constructs)
        this.searchable.filteredData = this.searchable.data.filter(item => {
            return JSON.stringify(item).toLowerCase().indexOf(text) > -1;
        });
    }

    public showPin(constructId, searchable, constructProperty) {
        return this.searchable.meta.type === searchable
            && this.searchable.meta.selected
            && this.searchable.meta.selected[constructProperty].some(c => c.id === constructId);
    }

    public allRegionLabelsChanged() {
        this.AnalyticsService.action({ action: 'toggle_map_region_labels', open: this.learningMap.option.showAllRegionLabels });
        // when unchecking the "region labels" checkbox, hide all the labels (even if user previously showed one by tapping on the info button)
        this.learningMap.data.fields.forEach(field => {
            field.regions.forEach(region => {
                region.showPopover = this.learningMap.option.showAllRegionLabels;
            });
        });
    }

    public getLearningMap(): any {
        let url = apiServer.urlPrefix + '/map/';
        let params = { option: 'map' };
        let cachedMap = JSON.parse(localStorage.getItem('learning_map'));

        if (cachedMap) {
            if (cachedMap.version === appInfo.version) {
                this.AnalyticsService.action({
                    'action': 'return_map__cached',
                    'version': appInfo.version
                });

                return Promise.resolve(cachedMap);
            }
            this.AnalyticsService.action({
                'action': 'break_map_cache',
                'version': appInfo.version
            });
            localStorage.removeItem('learning_map');
        }

        if (this.pendingMapRequest) {
            return this.pendingMapRequest;
        }

        this.pendingMapRequest = this.HttpService.get(url, params)
            .then(response => {
                response.result.version = appInfo.version;
                this.processMapProperties(response.result);
                localStorage.setItem('learning_map', JSON.stringify(response.result));
                this.AnalyticsService.action({
                    'action': 'fetch_map_and_cache',
                    'version': appInfo.version
                });

                return Promise.resolve(response.result);
            })
            .finally(() => this.pendingMapRequest = null);

        return this.pendingMapRequest;
    }

    private processMapProperties(map) {
        map.regions = [];
        map.clusters = [];
        map.constructs = [];

        map.fields.forEach(field => {
            field.regions.forEach(region => {
                map.regions.push(region);
                region.description = this.sanitizer.sanitize(SecurityContext.HTML, region.description);
                region.valid = true;
                region.wormhole = field.wormhole;
                region.clusters
                    .sort((a, b) => a.order - b.order)
                    .forEach(cluster => {
                        map.clusters.push(cluster);
                        cluster.regionName = region.short_name;
                        cluster.valid = true;
                        cluster.wormhole = region.wormhole;
                        cluster.constructs
                            .sort((a, b) => a.order - b.order)
                            .forEach(construct => {
                                map.constructs.push(construct);
                                construct.valid = true;
                                construct.stacks.sort((a, b) => b.order - a.order);
                            });
                    });
            });
        });
    }

    public getStandardsData(): any {
        let url = apiServer.urlPrefix + '/map/standard/';
        let params = { option: 'full' };
        let cachedStandards = JSON.parse(localStorage.getItem('CCSM_Standards'));

        if (cachedStandards) {
            if (cachedStandards.version === appInfo.version) {
                this.AnalyticsService.action({
                    'action': 'return_standards__cached',
                    'version': appInfo.version
                });

                return Promise.resolve(cachedStandards.result);
            }
            this.AnalyticsService.action({
                'action': 'break_ccsm_standards_cache',
                'version': appInfo.version
            });
            localStorage.removeItem('CCSM_Standards');
        }

        if (this.pendingStandardsRequest) {
            return this.pendingStandardsRequest;
        }

        this.pendingStandardsRequest = this.HttpService.get(url, params)
            .then(response => {
                response.result.forEach(standard => {
                    // We must sanitize standard descriptions because they are marked as unsafe
                    // by Angular when doing a XSS check due to style tag attributes (e.g. <p style="font-weight: 400;">)
                    // https://angular.io/guide/security#xss
                    // https://stackoverflow.com/questions/4546591/xss-attacks-and-style-attributes
                    standard.description = this.sanitizer.sanitize(SecurityContext.HTML, standard.description);
                });
                response.version = appInfo.version;
                localStorage.setItem('CCSM_Standards', JSON.stringify(response));
                this.AnalyticsService.action({
                    'action': 'fetch_ccsm_standards_and_cache',
                    'version': appInfo.version
                });

                return Promise.resolve(response.result);
            })
            .finally(() => this.pendingStandardsRequest = null);

        return this.pendingStandardsRequest;
    }

    public getMisconceptionsData(): any {
        let url = apiServer.urlPrefix + '/map/misconception/';
        let params = { option: 'map' };
        let cachedMisconceptions = JSON.parse(localStorage.getItem('map_misconceptions'));

        if (cachedMisconceptions) {
            if (cachedMisconceptions.version === appInfo.version) {
                this.AnalyticsService.action({
                    'action': 'return_misconceptions__cached',
                    'version': appInfo.version
                });

                return Promise.resolve(cachedMisconceptions.result);
            }
            this.AnalyticsService.action({
                'action': 'break_misconceptions_cache',
                'version': appInfo.version
            });
            localStorage.removeItem('map_misconceptions');
        }

        if (this.pendingMiscsRequest) {
            return this.pendingMiscsRequest;
        }

        this.pendingMiscsRequest = this.HttpService.get(url, params)
            .then(response => {
                // Remove html tags from construct names so they can be matched with in search panel
                response.result.forEach(misconception => {
                    misconception.constructs.forEach(construct => {
                        construct.name = this.UtilsService.stripHTMLTags(construct.name);
                    });
                });
                response.version = appInfo.version;
                localStorage.setItem('map_misconceptions', JSON.stringify(response));
                this.AnalyticsService.action({
                    'action': 'fetch_misconceptions_and_cache',
                    'version': appInfo.version
                });

                return Promise.resolve(response.result);
            })
            .finally(() => this.pendingMiscsRequest = null);

        return this.pendingMiscsRequest;
    }

    public getValidMapForGrade(gradeRange): any {
        let url = apiServer.urlPrefix + '/map/grade/' + gradeRange + '/';

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    'action': 'map_grade_selected',
                    'grade': gradeRange
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    'action': 'map_grade_selected_fail',
                    'url': url,
                    'error': error
                });

                return Promise.reject(error);
            });
    }

    public getMapAssessments(): any {
        const url = `${apiServer.urlPrefix}/map/assessment/count/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'map_tests_count',
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'map_tests_count_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public getClusterAssessmentOptions(clusterId): any {
        let url = apiServer.urlPrefix + '/map/cluster/assessment/' + clusterId + '/';

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    'action': 'show_assessment_options',
                    'cluster': clusterId
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    'action': 'show_assessment_options_failed',
                    'cluster': clusterId,
                    'error': error
                });

                return Promise.reject(error);
            });
    }

    public openMapAssessmentOptions(cluster, availableAssessments) {
        this.AnalyticsService.action({ action: 'open_map_assessments_modal' });

        const modalRef = this.ngbmodal.open(MapAssessmentOptionModalComponent, {
            windowClass: 'assessment-option-modal',
            size: 'lg',
        });
        modalRef.componentInstance.cluster = cluster;
        modalRef.componentInstance.availableAssessments = availableAssessments;

        return modalRef.result;
    }

    public getMapResources(): any {
        let url = apiServer.urlPrefix + '/link/get/';
        let params = { option: 'map' };
        let cachedResources = JSON.parse(localStorage.getItem('map_resources'));

        if (cachedResources) {
            if (cachedResources.version === appInfo.version) {
                this.AnalyticsService.action({
                    'action': 'return_map_resources__cached',
                    'version': appInfo.version
                });

                return Promise.resolve(cachedResources.result);
            }
            this.AnalyticsService.action({
                'action': 'break_map_resources_cache',
                'version': appInfo.version
            });
            localStorage.removeItem('map_resources');
        }


        return this.HttpService.get(url, params)
            .then(response => {
                response.version = appInfo.version;
                localStorage.setItem('map_resources', JSON.stringify(response));
                this.AnalyticsService.action({
                    'action': 'fetch_map_resources_and_cache',
                    'version': appInfo.version
                });

                return Promise.resolve(response.result);
            });
    }
}
