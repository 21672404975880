import { Component, Input } from '@angular/core';

import { mapSizesAndBounds } from '@/app.constant';
import { MapService } from '../map.service';


@Component({
    selector: '[misconception-pin]',
    templateUrl: './misconception-pin.component.svg.html',
    host: {
        class: 'misconception-pin',
    },
})
export class MisconceptionPinComponent {

    @Input() construct: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;

    constructor(
        public MapService: MapService,
    ) {}
}
