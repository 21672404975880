<div class="modal-header">
    <h4 class="modal-title limit-text">Preview {{ testformType }}s</h4>
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body space-children-column">
    <div *ngIf="assp.construct; then constructName else clusterName"></div>

    <ng-template #clusterName>
        <div class="flex-row align-items-center body-regular space-children-row small-space">
            <span class="bold">Cluster:</span>
            <span class="region-legend-circle spaced" [ngClass]="['region-background-' + assp.cluster.parent.region]"></span>
            <i [innerHTML]="assp.cluster.name"></i>
        </div>
    </ng-template>

    <ng-template #constructName>
        <div class="flex-row align-items-center body-regular space-children-row small-space">
            <span class="bold">Construct:</span>
            <span class="region-legend-circle spaced" [ngClass]="['region-background-' + assp.construct.parent.region]"></span>
            <i [innerHTML]="assp.construct.name"></i>
        </div>
    </ng-template>

    <div *ngIf="previewList && previewList.length === 0">No <span class="lowercase">{{ testformType }}s</span> to preview.</div>

    <div class="tests-container" *ngIf="previewList && previewList.length > 0">
        <div>A preview displays all of the questions included on any of the forms, and the answer to each question. Each form will contain approximately ten questions.</div>

        <div class="hr"></div>

        <div class="text-center" *ngFor="let testform of previewList">
            <button class="btn btn-link" (click)="previewAssessment(assp.assessment.id, testform.id)">
                <span class="bold">{{ UtilsService.testformTypeToText(retest ? 9 : 8, !!assp.construct) }}:&nbsp;</span>
                <span>Grade {{ testform.grade }}</span>
            </button>
        </div>
    </div>
</div>
