import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from '../service/auth.service';
import { LoginService } from '../../login/login.service';


@Component({
    selector: 'guest-login',
    templateUrl: './guest-login.component.html'
})
export class GuestLoginComponent implements OnInit, OnDestroy {

    public showCaptcha: boolean = false;

    constructor(
        public LoginService: LoginService,
        private AuthService: AuthService,
    ) {}

    public ngOnInit() {
        this.AuthService.getCaptcha()
            .then(response => {
                this.showCaptcha = response.result.captcha;
            })
            .catch(console.warn);
    }

    public ngOnDestroy() {
        this.LoginService.activeForm = 'login';
    }
}
