<form [formGroup]="form" class="test-edit-overlay flex-row flex-wrap justify-center">
    <div class="flex-15 flex-column justify-center text-center overlay-text bold">{{ testformType }}</div>
    <div class="flex-10 flex-column justify-center text-center overlay-text">{{ UtilsService.gradeBinToText(test.grade) }}</div>

    <mat-form-field appearance="outline" floatLabel="always" class="datepicker-container flex-column flex-15">
        <mat-label>Start Date</mat-label>
        <input matInput type="text" [matDatepicker]="startDate" placeholder="mm/dd/yyyy" formControlName="start_date" id="start_date" name="startdate" required>
        <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="datepicker-container flex-column flex-15">
        <mat-label>End Date</mat-label>
        <input matInput type="text" [matDatepicker]="endDate" placeholder="mm/dd/yyyy" formControlName="end_date" id="end_date" name="endDate" required>
        <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <div class="flex align-items-center">
        <mat-error *ngIf="form.errors?.datelt" class="overlay-text">Start date must be before end date</mat-error>
    </div>

    <div class="edit-actions flex-none align-items-center justify-center space-children-row small-space">
        <button type="button" class="btn ghost-button small-button" (click)="cancelTestEdit()">Cancel</button>
        <button type="submit" class="btn primary-button small-button" (click)="saveTestEdit()" [disabled]="!form.valid">Save</button>
    </div>
</form>
