import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormModule } from '../_custom/mat-form.module';

import {
    ModalService,
    FeedbackService,
} from './service';
import {
    ConfirmModalComponent,
    ContactModalComponent,
    AboutModalComponent,
    HelpModalComponent,
    AccessibilityModalComponent,
    DataPrivacyModalComponent,
    TOUModalComponent,
    OptOutModalComponent,
    HowToVideoModalComponent,
    FreePracticeIntroModalComponent,
    LogoutModalComponent,
    MapClusterResourcesModalComponent,
    FeedbackModalComponent,
    StudentTestStatusModalComponent,
    StudentTriesActivityModalComponent,
    StudentPracticeActivityModalComponent,
} from './modal';
import {
    BackToTopComponent,
    ConfettiComponent,
    CopyrightComponent,
    FeedbackFormComponent,
    MinimapComponent,
    ScrollingButtonsComponent,
    TruncateTextComponent,
} from './components';
import { StackLadderComponent } from '../assessment/practice/ladder/stack-ladder.component';
import { ScoreBubbleComponent } from '../assessment/practice/score-bubble/score-bubble.component';
import {
    DragDropDirective,
    ImageSrcDirective,
    MathHTMLDirective,
} from './directives';


@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatMenuModule,
        MatFormModule,
    ],
    providers: [
        DatePipe,
        ModalService,
        FeedbackService,
    ],
    declarations: [
        // Components
        BackToTopComponent,
        ConfettiComponent,
        CopyrightComponent,
        FeedbackFormComponent,
        MinimapComponent,
        ScrollingButtonsComponent,
        TruncateTextComponent,
        StackLadderComponent,
        ScoreBubbleComponent,
        // Directives
        DragDropDirective,
        ImageSrcDirective,
        MathHTMLDirective,
        // Shared Modals
        ConfirmModalComponent,
        ContactModalComponent,
        AboutModalComponent,
        HelpModalComponent,
        AccessibilityModalComponent,
        DataPrivacyModalComponent,
        TOUModalComponent,
        OptOutModalComponent,
        HowToVideoModalComponent,
        FreePracticeIntroModalComponent,
        LogoutModalComponent,
        MapClusterResourcesModalComponent,
        FeedbackModalComponent,
        StudentTestStatusModalComponent,
        StudentTriesActivityModalComponent,
        StudentPracticeActivityModalComponent,
    ],
    entryComponents: [
        ConfirmModalComponent,
        ContactModalComponent,
        AboutModalComponent,
        HelpModalComponent,
        AccessibilityModalComponent,
        DataPrivacyModalComponent,
        TOUModalComponent,
        OptOutModalComponent,
        HowToVideoModalComponent,
        FreePracticeIntroModalComponent,
        LogoutModalComponent,
        MapClusterResourcesModalComponent,
        FeedbackModalComponent,
        StudentTestStatusModalComponent,
        StudentTriesActivityModalComponent,
        StudentPracticeActivityModalComponent,
    ],
    exports: [
        // Components
        BackToTopComponent,
        ConfettiComponent,
        CopyrightComponent,
        FeedbackFormComponent,
        MinimapComponent,
        ScrollingButtonsComponent,
        TruncateTextComponent,
        StackLadderComponent,
        ScoreBubbleComponent,
        // Directives
        DragDropDirective,
        MathHTMLDirective,
    ],
})
export class SharedModule {}
