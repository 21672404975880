import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { MapService } from '../map.service';

import './map-field.component.scss';


@Component({
    selector: '[map-field]',
    templateUrl: './map-field.component.svg.html',
    // styleUrls: ['./map-field.component.scss'],
    viewEncapsulation: ViewEncapsulation.Emulated,
    host: {
        class: 'map-field',
    },
})
export class MapFieldComponent {

    @Input() field: any;

    constructor(
        public MapService: MapService,
    ) {}
}
