<div class="modal-body">
    <div class="modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </div>

    <div class="flex-row">
        <h4 class="modal-title">Assign New Test</h4>
    </div>

    <div class="hr"></div>

    <new-test-form type="modal" [dropdownModels]="dropdownModels" [schoolYears]="schoolYears" [cluster]="cluster" [courseOrSection]="courseOrSection" [courseOrSectionType]="courseOrSectionType" [selectedDate]="selectedDate" (testCreated)="onNewTest($event)"></new-test-form>
</div>
