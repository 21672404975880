import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';

import { captchaSiteKeyV2, captchaSiteKeyV3 } from '@/app.constant';
import { matchingPasswordValidator } from '../../shared/directives';
import { LoginService } from '../login.service';
import { AuthService } from '../../core/service/auth.service';
import { ModalService } from '../../shared/service/modal.service';
import { UtilsService } from '../../core/service/utils.service';

import './registration-form.component.scss';


@Component({
    selector: 'registration-form',
    templateUrl: './registration-form.component.html',
})
export class RegistrationFormComponent implements OnChanges {

    @Input() captcha: boolean;
    @ViewChild('captchaElement', { static: false }) captchaElement: ReCaptcha2Component;

    public readonly captchaSiteKeyV2 = captchaSiteKeyV2;
    public rForm: FormGroup;
    public registerFailMessage: string;

    get first_name() { return this.rForm.get('first_name'); }
    get email() { return this.rForm.get('email'); }
    get password() { return this.rForm.get('password'); }
    get passwordConfirm() { return this.rForm.get('passwordConfirm'); }

    constructor(
        private fb: FormBuilder,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private LoginService: LoginService,
        private AuthService: AuthService,
        private ModalService: ModalService,
        private UtilsService: UtilsService,
    ) {
        this.rForm = this.fb.group({
            first_name:      ['', [Validators.required, Validators.maxLength(30)]],
            email:           ['', [Validators.required, Validators.maxLength(150)]],
            password:        ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            passwordConfirm: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            termsOfUse:      ['', Validators.required],
            recaptcha:       ['', Validators.required],
        }, {
            validators: matchingPasswordValidator
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.captcha && change.captcha.currentValue !== change.captcha.previousValue) {
            this.toggleCaptchaValidation();
        }
    }

    public toggleCaptchaValidation() {
        if (this.captcha) {
            this.rForm.get('recaptcha').enable();
        } else {
            this.rForm.get('recaptcha').disable();
        }
    }

    public resetFormErrors() {
        this.registerFailMessage = null;
    }

    public openTermsOfUse($event) {
        $event.stopPropagation();
        $event.preventDefault();
        this.ModalService.openTermsOfUseModal();
    }

    public showForm($event, formName) {
        $event.stopPropagation();
        this.resetFormErrors();
        this.LoginService.activeForm = formName;
    }

    public registerUser($event) {
        $event.stopPropagation();

        this.UtilsService.addLoadingOverlay(true);
        this.reCaptchaV3Service.execute(captchaSiteKeyV3, 'register', token => {
            this.AuthService.register(this.rForm.value)
                .then(() => {
                    this.rForm.reset();
                })
                .catch(error => {
                    if (error.result && error.result.msg) {
                        this.captcha = error.result.msg.captcha;
                        this.toggleCaptchaValidation();
                        this.registerFailMessage = error.result.msg.msg || 'The email you entered is already registered. Please try another email or logging in again.';
                    }
                    // Only reset captcha on fail because on success component is destroyed
                    if (this.captchaElement) {
                        this.captchaElement.resetCaptcha();
                    }
                })
                .finally(() => {
                    this.UtilsService.removeLoadingOverlay();
                    this.rForm.patchValue({
                        passwordConfirm: '',
                        recaptcha: '',
                    });
                });
        });
    }
}
