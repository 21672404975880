<div class="modal-header">
    <div class="modal-close" (click)="activeModal.close()" *ngIf="stage === 2">
        <i class="icon icon-x"></i>
    </div>

    <div class="modal-title">Math-Mapper Student Survey</div>

</div>

<div class="modal-body" [ngSwitch]="stage">
    <div class="flex-row align-items-center justify-end space-children-row progressbar-row" *ngIf="stage === 1">
        <ngb-progressbar class="flex-35" [showValue]="true" type="info" [value]="progressPercent" [max]="100"></ngb-progressbar>
    </div>

    <div class="body-large survey-instructions" *ngSwitchCase="0">
        <p>Our research team would like to ask you some questions about your opinions. For these questions, there are no right or wrong answers; we just care about what you think. Your teachers will not see your answers, and your answers will not affect your grade in any way. Please try to answer the questions as honestly as you can.</p>

        <div class="hr"></div>

        <div class="flex-row align-items-center justify-center space-children-row">
            <button class="btn primary-button" (click)="beginSurvey()">Begin Survey</button>
        </div>
    </div>

    <div class="survey-form space-children-column" *ngSwitchCase="1">
        <feedback-form [questions]="questions[currentSurveyPage]" (completed)="pageCompleted = $event"></feedback-form>

        <div class="flex-row align-items-center justify-end space-children-row">
            <button class="btn ghost-button" (click)="changePage(currentSurveyPage + 1)" [disabled]="!pageCompleted" *ngIf="currentSurveyPage < questions.length - 1">
                <span>Next Page</span>
                <i class="icon icon-caret-right"></i>
            </button>

            <button class="btn primary-button" (click)="submitSurvey()" [disabled]="!pageCompleted" *ngIf="currentSurveyPage === questions.length - 1">Submit Survey</button>
        </div>
    </div>

    <div class="body-large survey-instructions" *ngSwitchCase="2">
        <p class="text-center">Thank you for completing the survey. We value your participation!</p>
    </div>
</div>
