import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { datepickerOptions } from '@/app.constant';
import { DateValidators } from '@/shared/directives';
import { UtilsService } from '../../core/service/utils.service';
import { AssessmentManagementService } from '../assessment-management.service';

import './edit-test-form.component.scss';


@Component({
    selector: 'edit-test-form',
    templateUrl: './edit-test-form.component.html',
    // styleUrls: ['./edit-test-form.component.scss'],
})
export class EditTestFormComponent implements OnInit {

    @Input() test: any;
    @Input() retest: any;
    @Input() cluCon: any;
    @Input() section: any;
    @Output() finishedEdit = new EventEmitter();
    @Output() savedEdit = new EventEmitter();

    public form: FormGroup;
    public testformType: string;
    public datepickerOptions = Object.assign({}, datepickerOptions);

    constructor(
        private fb: FormBuilder,
        private AssessmentManagementService: AssessmentManagementService,
        public UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
        this.testformType = this.UtilsService.testformTypeToText(this.retest ? 9 : 8, this.cluCon.construct);
        // Set min and max test dates for datepickers
        this.datepickerOptions.minDate = new Date(this.section.start_date + 'T00:00');
        this.datepickerOptions.maxDate = new Date(this.section.end_date + 'T00:00');

        // Initialize test form values
        const sd = new Date((this.retest || this.test).start_date + 'T00:00');
        const ed = new Date((this.retest || this.test).end_date + 'T00:00');

        this.form = this.fb.group({
            id:                 [this.test.id,                    Validators.required],
            retest_id:          [this.retest ? this.retest.retest_id : null],
            assessment_id:      [this.test.assessment.id,         Validators.required],
            cluster_id:         [this.cluCon.cluster ? this.cluCon.id : this.cluCon.parent.cluster],
            construct_id:       [this.cluCon.construct ? this.cluCon.id : null],
            section_id:         [this.section.id,                 Validators.required],
            grade:              [this.test.grade,                 Validators.required],
            start_date:         [{ value: sd, disabled: false },  Validators.required],
            end_date:           [{ value: ed, disabled: false },  Validators.required],
        }, {
            validators: [
                DateValidators.dateLessThan('start_date', 'end_date', { 'datelt': true }),
            ],
        });
    }

    public saveTestEdit() {
        let serverData = Object.assign({}, this.form.value);

        this.UtilsService.addLoadingOverlay();
        this.AssessmentManagementService.updateClusterAssessment(serverData)
            .then((result: any) => {
                this.cancelTestEdit();
                this.savedEdit.emit(result);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public cancelTestEdit() {
        this.form.reset();
        this.test.isEditing = false;
        if (this.retest) {
            this.retest.isEditing = false;
        }
        this.finishedEdit.emit();
    }
}
