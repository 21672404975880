import { tokenGetter } from './core/service/session.service';


export function jwtOptionsFactory() {
    return {
        tokenGetter: tokenGetter,
        whitelistedDomains: new Array(
            // Must escape regex tokens when using new constructor from string notation
            new RegExp('^local(host)?:?\\d{0,4}$', 'i'),
            new RegExp('^api\\d?.sudds.co$', 'i'),
        ),
        blacklistedRoutes: new Array(
            new RegExp('\\/user\\/login\\/.*$', 'i'),
            new RegExp('\\/user\\/register\\/$', 'i'),
            new RegExp('\\/user\\/password_(re)?set\\/$', 'i'),
            new RegExp('\\/user\\/email\\/activate\\/$', 'i'),
            new RegExp('\\/auth\\/refresh\\/$', 'i'),
            new RegExp('\\/schools\\/$', 'i'),
        ),
        throwNoTokenError: false,
        skipWhenExpired: true,
    }
}
