import { Component, Input, ViewChild } from '@angular/core';

import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { datepickerOptions, dateFormat } from '@/app.constant';
import { SessionService } from '../../../../core/service/session.service';
import { UtilsService } from '../../../../core/service/utils.service';
import { CalendarService } from '../calendar.service';
import { SequencerStateService } from '../../sequencer-state.service';
import { CourseManagementService } from '../../../course-management/course-management.service';

import { NewTestAssignmentModalComponent } from '../../../../assessment-management/new-test/new-test-assignment-modal.component';
import { ResourceAssignmentModalComponent } from '../resource-assignment/resource-assignment-modal.component';
import { NewNoteModalComponent } from './new-note/new-note-modal.component';
import { BlockedDayModalComponent } from './blocked-day/blocked-day-modal.component';

import './calendar-day-items.component.scss';


@Component({
    selector: 'calendar-day-items',
    templateUrl: './calendar-day-items.component.html',
    // styleUrls: ['./calendar-day-items.component.scss'],
})
export class CalendarDayItemsComponent {

    @Input() day: any;
    @Input() date: string;
    @ViewChild('dayNotesTrigger', { static: false }) dayNotesTrigger: NgbPopover;
    public resource: any;
    public blockedDays: any;
    public datepickerOptions = datepickerOptions;
    public readonly dateFormat = dateFormat;

    constructor(
        public SessionService: SessionService,
        public UtilsService: UtilsService,
        public CalendarService: CalendarService,
        public SequencerStateService: SequencerStateService,
        public CourseManagementService: CourseManagementService,
        public ngbmodal: NgbModal,
    ) {
        this.resource = this.CourseManagementService.data[this.CourseManagementService.data.resourceType];
    }

    public assignTest(day) {
        let cluster = this.tempFindClusterFn(day);
        if (this.CourseManagementService.data.resourceType !== 'demo') {
            this.openAssignAssessmentModal(day, cluster);
        }
    }

    private openAssignAssessmentModal(day, cluster) {
        const modalRef = this.ngbmodal.open(NewTestAssignmentModalComponent, {
            windowClass: 'new-test-assignment-modal',
            size: 'lg',
        });
        modalRef.componentInstance.dropdownModels = {
            schoolYear: {
                start_date: this.resource.start_date,
                end_date: this.resource.end_date,
            }
        };
        modalRef.componentInstance.cluster = cluster ? cluster.cluster : null;
        modalRef.componentInstance.courseOrSection = this.resource;
        modalRef.componentInstance.courseOrSectionType = this.CourseManagementService.data.resourceType;
        modalRef.componentInstance.selectedDate = day.date;

        modalRef.result
            .then(newTest => {
                this.SequencerStateService.data.model.assessments = this.SequencerStateService.data.model.assessments.concat(newTest);
                this.CalendarService.generateAssessmentsData();
            })
            .catch(() => {});
    }

    public assignResource(day) {
        let cluster = this.tempFindClusterFn(day);
        if (this.CourseManagementService.data.resourceType !== 'demo') {
            this.openResourceAssignmentModal(day, cluster);
        }
    }

    private openResourceAssignmentModal(day, cluster) {
        const modalRef = this.ngbmodal.open(ResourceAssignmentModalComponent, {
            windowClass: 'resource-assignment-modal',
            size: 'lg',
        });
        modalRef.componentInstance.cluster = cluster ? cluster.cluster : null;
        modalRef.componentInstance.selectedDate = day.date;

        return modalRef.result;
    }


    public deleteSspResource(day, resource, rssIndex) {
        // Remove resource item from view
        this.CalendarService.calendar.displayResources[day.formattedDate].splice(rssIndex, 1);
        // Send request and update resources with backend values
        this.CalendarService.deleteSspResource(resource.id)
            .then((result: any) => {
                this.SequencerStateService.data.model.resources = result;
                this.CalendarService.generateResourcesData();
            });
    }

    // Blocked day functions
    public openDayBlockPopover(day) {
        const modalRef = this.ngbmodal.open(BlockedDayModalComponent, {
            windowClass: 'new-note-modal',
            size: 'sm',
        });
        modalRef.componentInstance.day = day;

        return modalRef.result;
    }

    public deleteBlockedDays(day) {
        this.CalendarService.deleteBlockedDays(day);
    }

    // Note functions
    public openNewNoteModal(day) {
        const modalRef = this.ngbmodal.open(NewNoteModalComponent, {
            windowClass: 'new-note-modal',
            size: 'sm',
        });
        modalRef.componentInstance.day = day;

        return modalRef.result;
    }

    public editNote(note) {
        note.tempContent = note.content;
        note.editing = true;
    }

    public updateNote(note) {
        note.content = note.tempContent;
        note.editing = false;
        delete note.tempContent;
        this.CalendarService.updateCalendarNote(note);
    }

    public cancelEditingNote(note) {
        note.editing = false;
        delete note.tempContent;
    }

    public deleteNote(note) {
        note.deleting = true;
        this.CalendarService.deleteCalendarNote(note.id);
    }

    public undoDeleteNote(note) {
        note.deleting = false;
        this.CalendarService.updateCalendarNote(note);
    }

    // @TODO
    // This function should be removed by linking clusters to days so it can be accessed through day.cluster
    private tempFindClusterFn(day) {
        let year = this.CalendarService.calendar.displayData[day.date.year()];
        if (!year) {
            return null;
        }
        let month = year[day.date.month()];
        if (!month) {
            return null;
        }
        let week = month[day.mWeekNum];
        if (!week) {
            return null;
        }

        let events = week.events['cluster'];
        if (typeof events === 'undefined') {
            return null; // No events this week
        }
        let eventsWithCluster = events.filter(event => {
            if (!event.entity) {
                return false;
            }

            return day.date.isBetween(event.entity._startDate, event.entity._endDate, 'days', '[]');
        });
        if (eventsWithCluster.length > 0) {
            return eventsWithCluster[0].entity; // Found a match
        }

        return null; // No cluster for this day
    }
}
