import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { GrowlerService } from '../../../core/service/growler.service';
import { UtilsService } from '../../../core/service/utils.service';
import { HttpService } from '../../../core/service/http.service';
import { AnalyticsService } from '../../../core/service/analytics.service';


@Component({
    selector: 'help-modal',
    templateUrl: './help-modal.component.html',
})
export class HelpModalComponent {

    // Request types:

    // id: type
    // 4: Request a Bug
    // 5: Suggest a new feature
    // 6: Improve a feature
    // 7: Report a test issue
    // 8: Report an item issue
    // 1: General help
    public form = {
        request_type: 0,
        email: null,
        request_data: {
            summary: null,
            description: null,
            customfield_10500: null,
        },
        option: {
            customfield_10300: null,
            customfield_10301: null,
        }
    };
    public originalForm = JSON.parse(JSON.stringify(this.form));
    public submittingForm = false;

    constructor(
        public activeModal: NgbActiveModal,
        private GrowlerService: GrowlerService,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
    ) { }


    public submitTicket() {
        let url = `${apiServer.urlPrefix}/contact_us/`;
        // Deep copy user submitted data and format it as necessary
        let formData = JSON.parse(JSON.stringify(this.form));
        formData.request_data = JSON.stringify(formData.request_data);
        formData.option = JSON.stringify(formData.option);

        this.UtilsService.addLoadingOverlay(true);
        this.HttpService.post(url, formData)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'submit_help_request',
                    url: url,
                    state: location.pathname,
                    data: response
                });

                this.activeModal.close(response);
                this.GrowlerService.success('We have received your help request.', 'Thank You!');
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'submit_help_request_fail',
                    url: url,
                    state: location.pathname,
                    error: error
                });
            })
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public goToRequest(type: number) {
        // Reset form to pristine state
        this.form = JSON.parse(JSON.stringify(this.originalForm));
        this.form.request_type = type;
    }

    public returnToMainMenu() {
        this.goToRequest(0);
    }
}
