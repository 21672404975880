<!-- construct circles and texts -->
<svg:g [attr.transform]="'translate(' + construct.attributes.cx + ',' + construct.attributes.cy + ')'">
    <!-- Circle matching color of region to hide the connector lines -->
    <svg:circle
        [attr.r]="construct.attributes.radius"
        cx="0"
        cy="0"
        [class.construct-opaque-background-invalid]="!construct.valid"
        [ngClass]="['region-background-' + construct.region]">
    </svg:circle>

    <!-- Background that is visible only when construct is selected. Overlays on top of the above -->
    <svg:circle
        *ngIf="construct.selected"
        [attr.r]="construct.attributes.radius"
        cx="0"
        cy="0"
        class="construct-selected-shadow"
        [class.construct-background-invalid]="!construct.valid">
    </svg:circle>

    <!-- Construct circle that skews when selected -->
    <svg:g [attr.transform]="construct.selected ? 'translate(-27, 0) scale(0.85, 1.016)' : ''">
        <svg:circle
            [attr.r]="construct.attributes.radius"
            cx="0"
            cy="0"
            [class.construct-background-invalid]="!construct.valid"
            [class.construct-background-selected]="construct.selected"
            [ngClass]="['construct-background-' + construct.cluster]">
        </svg:circle>

        <!-- Gray stripes pattern when construct is below selected grades -->
        <svg:g
            *ngIf="!construct.valid && construct.belowGrade"
            [attr.transform]="'scale(' + (construct.attributes.radius / 133) + ')'">
            <svg:g transform="translate(-116, -115.5)">
                <svg:path fill="#C8C8C8" d="M98.1,1.9C74.7,5.5,52.3,16.4,34.3,34.3c-18,18-28.8,40.4-32.4,63.7L98.1,1.9z"/>
                <svg:path fill="#C8C8C8" d="M6.4,152.6L152.6,6.4c-8.4-2.8-17.1-4.6-25.9-5.4L1,126.7C1.8,135.5,3.6,144.2,6.4,152.6z"/>
                <svg:path fill="#C8C8C8" d="M190.4,27.7c-5.8-4.9-12-9.2-18.5-12.8l-157,157c3.6,6.5,7.9,12.7,12.8,18.5L190.4,27.7z"/>
                <svg:path fill="#C8C8C8" d="M41.6,204.3c5.8,4.9,12,9.2,18.5,12.8l157-157c-3.6-6.5-7.9-12.7-12.8-18.5L41.6,204.3z"/>
                <svg:path fill="#C8C8C8" d="M231,105.3c-0.8-8.8-2.6-17.4-5.4-25.9L79.4,225.6c8.4,2.8,17.1,4.6,25.9,5.4L231,105.3z"/>
                <svg:path fill="#C8C8C8" d="M197.7,197.7c18-18,28.8-40.4,32.4-63.7l-96.2,96.2C157.3,226.5,179.7,215.6,197.7,197.7L197.7,197.7z"/>
            </svg:g>
        </svg:g>

        <!-- Construct Name -->
        <foreignObject
            *ngIf="MapService.learningMap.option.currentZoomLevel >= 2"
            [attr.x]="-construct.attributes.radius"
            [attr.y]="-construct.attributes.radius - 11"
            [attr.width]="construct.attributes.radius * 2"
            [attr.height]="mapSizesAndBounds.construct.height"
            [attr.transform]="construct.selected ? 'skewY(-2) translate(0,-7)' : ''">
            <xhtml:div
                class="construct-title"
                [class.invalid]="construct.valid === 0"
                [style.width]="construct.attributes.radius * 1.6 + 'px'"
                [style.height]="construct.attributes.radius * 2 + 'px'">
                <xhtml:div class="construct-title-position">
                    <xhtml:span class="construct-valid-{{ construct.valid }}">
                        <xhtml:div *ngIf="MapService.learningMap.option.codeEnabled" class="bold">{{ construct.code }}</xhtml:div>
                        <xhtml:span class="bold" [innerHTML]="construct.name"></xhtml:span>
                    </xhtml:span>
                </xhtml:div>
            </xhtml:div>
        </foreignObject>
    </svg:g>
</svg:g>

<svg:g
    class="construct-group-overlay"
    [class.construct-selected]="construct.selected"
    [attr.transform]="'translate(' + (construct.selected ? (construct.attributes.cx - 27) + ', ' + construct.attributes.cy + ') skewY(-1) skewX(0) scale(0.85, 1.016)' : construct.attributes.cx + ',' + construct.attributes.cy + ')')">
    <!-- stacks moon button -->
    <svg:g
        class="stacks-moon-button zoom-level-transition"
        *ngIf="MapService.learningMap.option.currentZoomLevel >= mapSizesAndBounds.stack.visibleAtLevel">
        <svg:g [attr.transform]="'scale(' + (construct.attributes.radius * 0.012) + ') translate(-63,39.5)'">
            <svg:path class="moon-shape zoom-level-transition" d="M0.983,0 C13.866,20 36.817,34.5 63,34.5 C89.183,34.5 112.134,20 125.017,0 L0.983,0 Z">
            </svg:path>
        </svg:g>
        <!-- stacks icon -->
        <svg:g [attr.transform]="'translate(-45, ' + (construct.attributes.radius * 0.7 - 55) + ')'">
            <svg:text x="48" y="80" class="map-icon stack-ladder-icon">&#79;</svg:text>
        </svg:g>
    </svg:g>

    <!-- construct click target overlay -->
    <svg:circle
        [attr.r]="construct.attributes.radius"
        cx="0"
        cy="0"
        fill="#000"
        opacity="0"
        (click)="toggleConstruct(construct, $event)">
    </svg:circle>
</svg:g>
