import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { UtilsService } from '@/core/service/utils.service';
import { SchoolReportService } from '@/report/school/school-report.service';

import './school-report-graph.component.scss';


@Component({
    selector: 'school-report-graph',
    templateUrl: 'school-report-graph.component.html',
    // styleUrls: ['./school-report-graph.component.scss'],
    host: {
        class: 'school-report-graph flex-column'
    }
})
export class SchoolReportGraphComponent implements OnInit {

    public graphParams: any = {};
    public graphData: any = {};
    private graphDataSub: Subscription;

    constructor(
        public UtilsService: UtilsService,
        public SchoolReportService: SchoolReportService,
    ) {}

    public ngOnInit() {
        this.graphDataSub = this.SchoolReportService.graphDataObs$.subscribe(({params, graphData}) => {
            if (params && graphData) {
                this.graphParams = params;
                this.graphData = graphData;
            }
        });
    }

    public ngOnDestroy() {
        if (this.graphDataSub) {
            this.graphDataSub.unsubscribe();
        }
    }
}
