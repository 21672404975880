import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { HttpService } from '../core/service/http.service';
import { AnalyticsService } from '../core/service/analytics.service';
import { UtilsService } from '../core/service/utils.service';

import { ReviseRevealModalComponent } from './revise-reveal/revise-reveal-modal.component';


@Injectable()
export class ReportService {

    public models = {
        cluster: null,
        test: null
    };
    private modelsConst = JSON.stringify(this.models);
    public studentName: string;
    public reportClusters: any;

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private ngbmodal: NgbModal,
    ) { }

    public resetReportModels() {
        this.models = JSON.parse(this.modelsConst);
    }

    public getClustersWithStudentReports(user_id=null): any {
        let url = `${apiServer.urlPrefix}/report/cluster/tests/`;
        let params = {
            user: user_id
        };

        return this.HttpService.get(url, params)
            .then(response => {
                response.result.clusters
                    .sort((a, b) => a.parent.region - b.parent.region);
                    // .forEach(cluster => {
                    //     cluster.tests.sort((a, b) => a.test_date < b.test_date ? 1 : -1);
                    // });

                this.studentName = response.result.name;
                this.reportClusters = response.result.clusters;

                this.AnalyticsService.action({
                    action: 'find_clusters_report',
                    url: url
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'find_clusters_report_fail',
                    url: url,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getOverallClusterTestPerformance(clusterId): any {
        let url = `${apiServer.urlPrefix}/report/cluster/overall/`;
        let params = {
            cluster_id: clusterId
        };

        return this.HttpService.get(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_overall_cluster_test_performance',
                    url: url,
                    params: params
                });

                // Sort constructs asc and stacks desc
                response.result.cluster.constructs
                    .sort((a, b) => a.order - b.order)
                    .forEach(co => {
                        co.stacks.sort((a, b) => b.order - a.order);
                    });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_overall_cluster_test_performance_fail',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getClusterRunningRecord(clusterId): any {
        let url = `${apiServer.urlPrefix}/report/cluster_running_record/`;
        let params = {
            cluster_id: clusterId
        };

        return this.HttpService.get(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_cluster_running_record',
                    url: url,
                    params: params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_cluster_running_record_fail',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getResultsByConstruct(trUid): any {
        let url = `${apiServer.urlPrefix}/report/results_by_construct/${trUid}/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_results_by_construct',
                    url: url,
                    trUid: trUid
                });

                // Sort constructs ascending
                response.result.constructs
                    .sort((a, b) => a.order - b.order)
                    .forEach(co => {
                        // Sort stack levels descending and get grade level
                        co.stacks
                            .sort((a, b) => b.order - a.order)
                            .forEach(stack => {
                                stack.grade = stack.grade ? stack.grade[stack.grade.length - 1] : 'N/A';
                            });
                    });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_results_by_construct_fail',
                    url: url,
                    trUid: trUid,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public getQuestionBreakdown(trUid): any {
        let url = `${apiServer.urlPrefix}/report/question_breakdown/${trUid}/`;

        return this.HttpService.get(url)
            .then(response => {
                response.result.question_scores.sort((q1, q2) => {
                    let q1c = Object.keys(q1)[0];
                    let q2c = Object.keys(q2)[0];

                    return q1[q1c].q_order - q2[q2c].q_order;
                });

                this.models.test = response.result.test_info;

                this.AnalyticsService.action({
                    action: 'get_question_breakdown',
                    url: url,
                    trUid: trUid
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_question_breakdown_fail',
                    url: url,
                    trUid: trUid,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public computeConstructScores(questionScores) {
        let overallWeightedPoints = 0;
        let overallWeightedTotal = 0;
        let constructScores = {
            overall: 0,
        };

        questionScores.forEach(question => {
            let constructId = question.construct_id;
            let q = question[constructId];
            let isRevised = !!q.percent_revised || q.percent_revised === 0;
            let qWeightedPoints = isRevised ? q.weight_revised : q.weight_initial;

            // Initialize these values at 0 in first iteration
            constructScores[constructId] = constructScores[constructId] || {
                weightedOriginalOnly: 0,
                weightedRevisedOnly: 0,
                weightedPoints: 0,
                weightedTotal: 0
            };

            // Separate scored points scored by scored initially and scored after revision
            // But always preserve the original score even when it is revised for full credit
            constructScores[constructId].weightedOriginalOnly += q.weight_initial;
            if (isRevised) {
                constructScores[constructId].weightedRevisedOnly += q.weight_revised - q.weight_initial;
            }

            // Aggregate total weight, as well as scored weight
            constructScores[constructId].weightedTotal += q.weight_total;
            constructScores[constructId].weightedPoints += qWeightedPoints;

            // Calculate original score as a percentage (0-100)
            constructScores[constructId].initialPercent = Math.round(constructScores[constructId].weightedOriginalOnly / constructScores[constructId].weightedTotal * 100);
            // Calculate score after revisions as a percentage (0-100)
            constructScores[constructId].weightedPercent = Math.round(constructScores[constructId].weightedPoints / constructScores[constructId].weightedTotal * 100);

            overallWeightedTotal += q.weight_total;
            overallWeightedPoints += qWeightedPoints;
        });

        // Convert to backend weight (e.g. 8120 = 81.2%)
        constructScores.overall = Math.round(overallWeightedPoints / overallWeightedTotal * 10000);

        return constructScores;
    }

    public getReviseRevealQuestion(questionId, stemId, trUid): any {
        let url = `${apiServer.urlPrefix}/report/revise_reveal/${questionId}/${stemId}/${trUid}/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_revise_reveal_question',
                    url: url,
                    trUid: trUid,
                    q_id: questionId
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_revise_reveal_question_fail',
                    url: url,
                    trUid: trUid,
                    q_id: questionId,
                    error: error
                });

                return Promise.reject(error);
            });
    }

    public revealQuestion(questionId, stemId, trId): any {
        let url = `${apiServer.urlPrefix}/report/reveal/`;
        let params = {
            trid: trId,
            qid: questionId,
            sid: stemId
        };

        return this.HttpService.post(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'reveal_question',
                    url: url,
                    params: params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'reveal_question_fail',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }
}
