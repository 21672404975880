<div class="modal-header">
    <button class="btn modal-close" (click)="dismissModal('cancel')">
        <i class="icon icon-x"></i>
    </button>

    <div class="modal-title">{{ modalTitle }}</div>
</div>

<div class="modal-body">
    <form class="flex-row flex-wrap" name="resourceForm" novalidate>
        <div class="form-column flex-column flex-35">
            <div>Resource Information</div>

            <mat-form-field appearance="outline">
                <mat-label>Resource Title</mat-label>
                <input matInput type="text" name="resourceName" placeholder="Numbers in Space" [(ngModel)]="resource.name" [disabled]="resource.disabled" required />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Resource URL</mat-label>
                <input matInput type="url" (change)="prependHTTP()" placeholder="https://website.com/resource" name="resourceUrl" [(ngModel)]="resource.url" [disabled]="resource.disabled" required />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Cover Image URL</mat-label>
                <input matInput type="url" (change)="prependHTTP()" placeholder="https://website.com/image.jpg" name="resourceImage" [(ngModel)]="resource.image" [disabled]="resource.disabled" />
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!resource.disabled">
                <mat-label>Description</mat-label>
                <textarea   matInput
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5"
                            [(ngModel)]="resource.description"
                            name="resourceDescription"
                            maxlength="1000">
                </textarea>
                <mat-hint align="end">Max 1000 characters</mat-hint>
            </mat-form-field>
            <div *ngIf="resource.disabled" class="resource-description-container">
                <label class="description-label">Description (max 1000 characters)</label>
                <p class="resource-description">{{ UtilsService.stripHTMLTags(resource.description) }}</p>
            </div>

            <button class="btn btn-block filter-button flex-none justify-space-between align-items-center space-children-row" (click)="openMapTagging()" *ngIf="!resource.disabled">
                <span>Map Location OR Standard Selection <span class="asterisk">*</span></span>
                <i class="icon icon-map-marker"></i>
            </button>

            <div class="resource-standards margin-top-1em" *ngIf="resource.standards.length > 0">
                <div class="bold body-regular">Selected Standards</div>
                <span class="resource-standard" *ngFor="let standard of resource.standards; index as stIdx">
                    <span>{{ standard.name }}</span>
                    <span (click)="removeStandard(stIdx)" *ngIf="!resource.disabled" class="body-large text-muted">&nbsp;×</span>
                </span>
            </div>

            <div class="margin-top-1em">
                <ng-select  class="flex custom"
                            [addTag]="true"
                            addTagText="Add author"
                            [items]="sources"
                            appendTo="body"
                            bindLabel="name"
                            [closeOnSelect]="false"
                            [hideSelected]="true"
                            [multiple]="true"
                            [searchable]="true"
                            [(ngModel)]="resource.sources"
                            [disabled]="resource.disabled"
                            name="authors"
                            placeholder="Select one or more authors">
                </ng-select>
            </div>
        </div>

        <div class="form-column flex-column flex-35">
            <div>Global Tag Selection</div>

            <mat-accordion [multi]="true">
                <!-- Grade filter container -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Grades</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="space-children-column">
                        <div *ngFor="let grade of grades">
                            <mat-checkbox   name="mgtGrade{{ grade.id }}"
                                            [(ngModel)]="filterSelections.grades[grade.id]"
                                            labelPosition="after"
                                            [disabled]="resource.disabled"
                                            [disableRipple]="true">
                                <span>{{ grade.name }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-expansion-panel>

                <!-- Other tags filter container -->
                <mat-expansion-panel *ngFor="let tagCategory of tags">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ tagCategory.name }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="space-children-column">
                        <div *ngFor="let tag of tagCategory.tags">
                            <mat-checkbox   name="mgtTag{{ tag.id }}"
                                            [(ngModel)]="filterSelections.tags[tag.id]"
                                            labelPosition="after"
                                            [disabled]="resource.disabled"
                                            [disableRipple]="true">
                                <span>{{ tag.name }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form-column flex-column flex-30">
            <div>Notes and Rating</div>

            <mat-form-field appearance="outline">
                <mat-label>Private Keywords</mat-label>
                <textarea   matInput
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5"
                            [(ngModel)]="resource.note"
                            name="resourceNote"
                            maxlength="1500">
                </textarea>
                <mat-hint align="end">Max 1500 characters</mat-hint>
            </mat-form-field>

            <div class="margin-top-1em">
                <div class="bold body-regular">Resource Rating</div>
                <ngb-rating [(rate)]="resource.rating.user_rating" name="resourceRating" max="5" [readonly]="false"></ngb-rating>
            </div>

            <div class="margin-top-1em">
                <mat-checkbox   *ngIf="(resource.owner || resource.editable) && isTeacherOrAdmin"
                                [(ngModel)]="resource.share"
                                name="resourceShare"
                                [disableRipple]="true">
                    Share resource with my school district
                </mat-checkbox>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer flex-row align-items-center justify-center space-children-row">
    <button class="btn ghost-button" (click)="dismissModal('cancel')">Cancel</button>
    <button class="btn primary-button" (click)="saveResourceChanges()">Save</button>
</div>
