<div class="modal-header">
    <h4 class="modal-title limit-text">Assign Resources</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss('cancel')">
        <i class="icon icon-x"></i>
    </button>
</div>
<div class="modal-body">
    <div class="flex-row align-items-center space-children-row">
        <label class="bold">Filter Resources by Cluster</label>

        <ng-select  class="flex custom cluster-dropdown"
                    [items]="clusters"
                    appendTo="body"
                    bindLabel="name"
                    [clearable]="true"
                    groupBy="regionName"
                    [searchable]="true"
                    [(ngModel)]="cluster"
                    [disabled]="!clusters"
                    [loading]="loadingClusters"
                    (change)="filterByCluster()"
                    name="cluster"
                    placeholder="Select a cluster">
            <ng-template ng-label-tmp let-item="item">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                <span [innerHTML]="item.name"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                <span [innerHTML]="item.name"></span>
            </ng-template>
        </ng-select>
    </div>

    <div class="hr"></div>

    <div *ngIf="filteredResources?.length === 0" class="bold body-large">No search results found</div>

    <div class="flex-row flex-wrap justify-start">
        <resource-card *ngFor="let resource of filteredResources; index as rIdx" [resource]="resource" [resourceIdx]="rIdx" cardType="selectable" (deleteAssignedResource)="deleteAssignedResource($event)"></resource-card>
    </div>
</div>

<div class="modal-footer flex-row justify-center">
    <button class="btn primary-button" aria-label="Submit" (click)="assignResources()" [disabled]="noResourcesSelected()">Submit</button>
    <button class="btn ghost-button" aria-label="Close" (click)="activeModal.dismiss('cancel')">Cancel</button>
</div>
