import { Component, Input } from '@angular/core';

import { HeatmapService } from '../heatmap.service';

import './heatmap-legend.component.scss';


@Component({
    selector: 'heatmap-legend',
    templateUrl: './heatmap-legend.component.html',
    // styleUrls: ['./heatmap-legend.component.scss'],
    host: {
        class: 'heatmap-legend',
    },
})
export class HeatmapLegendComponent {

    constructor(
        public HeatmapService: HeatmapService,
    ) {}
}
