<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h4 class="modal-title text-center">&quot;How To&quot; Video</h4>
</div>

<div class="modal-body">
    <p class="text-center">
        <iframe *ngIf="videoType === 'map'" width="800" height="450" src="https://www.youtube.com/embed/Dl4c6GN83zM?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe *ngIf="videoType === 'practice'" width="800" height="450" src="https://www.youtube.com/embed/HsjKQP4hR4s?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe *ngIf="videoType === 'tries'" width="800" height="450" src="https://www.youtube.com/embed/XMKeJiNxUK4?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </p>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
</div>

