<svg:g [attr.transform]="'translate(' + construct.attributes.cx + ', ' + construct.attributes.cy + ')'">
    <svg:g
        class="pin-primary"
        *ngIf="MapService.showPin(construct.id, 'standards', 'primary')"
        [attr.transform]="'translate(' + (construct.attributes.radius * 0.65 + [180,180,130,100,80][MapService.learningMap.option.currentZoomLevel]) + ', ' + (-construct.attributes.radius * 0.5 - [300,300,210,160,130][MapService.learningMap.option.currentZoomLevel]) + ') scale(' + [2.1,2.1,1.5,1.1,0.85][MapService.learningMap.option.currentZoomLevel] + ') rotate(55)'">
        <svg:g opacity="0.5">
            <svg:g filter="url(#pin-shadow)">
                <svg:path fill="#000" d="M126.3,10.5c0,0-0.1,0-0.1,0c-7.3-1.6-16.3-2.8-24.9-2c-19,1.8-34.5,9.3-38.1,30.9C60.3,57,64,76.7,66.8,94.3c0.1,0.5,0.2,1,0.2,1.5c0.2,1.2,0.5,2.5,0.7,3.6c3,15,3,31.5,5,46.8c0.6,4.2,3.1,8.2,9.4,8.4c6.3,0.2,10.3-3.6,13.7-8.5c13.7-19.4,26.4-39.5,39.4-59.2c1.3-1.9,2.8-4.6,4.6-7.9c2-3.7,4.2-8,6.5-12.7c0,0,0,0,0-0.1c0.2-0.5,0.5-1.1,0.7-1.6C157.1,37.3,155.6,21.9,126.3,10.5z"/>
            </svg:g>
        </svg:g>
        <svg:g>
            <svg:path fill="#332F2F" d="M86.5,10.8C86.5,10.8,86.5,10.8,86.5,10.8c-4.4-0.8-9-1.1-14.2-0.9c-7.1,0.3-19.9,1.4-33.2,16.6c-11.6,13.2-14.8,30.7-8.9,47c0.2,0.6,0.4,1.2,0.7,1.8c0.6,1.6,1.3,3.1,2.1,4.7c11.2,21.6,22.4,43.1,33.4,64.7c2.7,5.3,6.2,9.3,12.5,9c6.3-0.3,9.4-4.6,11.7-10c9.5-22.7,18.8-43.9,27.7-65.4c0.8-2,1.8-4.9,2.5-8.2c0.9-3.8,1.6-8.3,1.8-13c0,0,0,0,0-0.1c0-0.6,0-1.1,0-1.6C121.8,33.6,106.6,15.8,86.5,10.8z"/>
            <svg:path fill="#E8E1CF" d="M99,57.4c0.5,13.4-9.9,24.5-23.2,24.9c-13,0.4-24.1-9.9-24.8-22.9c-0.6-13.1,9.9-24.5,23.1-25.1C87.5,33.7,98.5,44,99,57.4z"/>
        </svg:g>
    </svg:g>

    <svg:g
        class="pin-secondary"
        *ngIf="MapService.showPin(construct.id, 'standards', 'secondary')"
        [attr.transform]="'translate(' + (construct.attributes.radius * 0.65 + [180,180,130,100,80][MapService.learningMap.option.currentZoomLevel]) + ', ' + (-construct.attributes.radius * 0.5 - [300,300,210,160,130][MapService.learningMap.option.currentZoomLevel]) + ') scale(' + [2.5,2.1,1.5,1.1,0.85][MapService.learningMap.option.currentZoomLevel] + ') rotate(55)'">
        <svg:g opacity="0.5">
            <svg:g filter="url(#pin-shadow)">
                <svg:path fill="#000" d="M126.3,11c0,0-0.1,0-0.1,0c-7.3-1.6-16.3-2.8-24.9-2c-19,1.8-34.5,9.3-38.1,30.9c-2.9,17.6,0.8,37.4,3.5,54.9c0.1,0.5,0.2,1,0.2,1.5c0.2,1.2,0.5,2.5,0.7,3.6c3,15,3,31.5,5,46.8c0.6,4.2,3.1,8.2,9.4,8.4c6.3,0.2,10.3-3.6,13.7-8.5c13.7-19.4,26.4-39.5,39.4-59.2c1.3-1.9,2.8-4.6,4.6-7.9c2-3.7,4.2-8,6.5-12.7c0,0,0,0,0-0.1c0.2-0.5,0.5-1.1,0.7-1.6C157.1,37.8,155.6,22.4,126.3,11z"/>
            </svg:g>
        </svg:g>
        <svg:g>
            <svg:path fill="#E8E1CF" stroke="#332F2F" stroke-miterlimit="10" d="M86.5,11.3C86.5,11.3,86.5,11.3,86.5,11.3c-4.4-0.8-9-1.1-14.2-0.9c-7.1,0.3-19.9,1.4-33.2,16.6c-11.6,13.2-14.8,30.7-8.9,47c0.2,0.6,0.4,1.2,0.7,1.8c0.6,1.6,1.3,3.1,2.1,4.7c11.2,21.6,22.4,43.1,33.4,64.7c2.7,5.3,6.2,9.3,12.5,9c6.3-0.3,9.4-4.6,11.7-10c9.5-22.7,18.8-43.9,27.7-65.4c0.8-2,1.8-4.9,2.5-8.2c0.9-3.8,1.6-8.3,1.8-13c0,0,0,0,0-0.1c0-0.6,0-1.1,0-1.6C121.8,34.1,106.6,16.3,86.5,11.3z"/>
            <svg:path fill="#332F2F" stroke="#332F2F" stroke-miterlimit="10" d="M99,57.9c0.5,13.4-9.9,24.5-23.2,24.9c-13,0.4-24.1-9.9-24.8-22.9c-0.6-13.1,9.9-24.5,23.1-25.1C87.5,34.2,98.5,44.5,99,57.9z"/>
        </svg:g>
    </svg:g>

    <svg:circle
        class="pin-pulse"
        *ngIf="MapService.searchable.meta.displaySelected"
        [attr.id]="'standard-pulse-' + construct.id "
        [attr.cx]="construct.attributes.radius * 0.65 + [180,180,130,100,80][MapService.learningMap.option.currentZoomLevel]"
        [attr.cy]="-construct.attributes.radius * 0.5 - [300,300,210,160,130][MapService.learningMap.option.currentZoomLevel] + 90"
        r="0"
        fill="white"
        opacity="0">
    </svg:circle>
</svg:g>
