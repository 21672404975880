<div class="flex-column space-children-column align-items-start margin-top-1em" *ngIf="testCluCon?.length === 0">
    <h5>You do not have any tests assigned to this section<span *ngIf="cluster">&nbsp;for the selected cluster</span>.</h5>
</div>

<div class="assign-test-header flex-row align-items-center justify-center" (click)="assignNewTest()" *ngIf="isCurrentSchoolYear(AssessmentManagementService.models.schoolYear?.end_date) && !AssessmentManagementService.models.cluster">
    <span class="assign-test-content flex-none align-items-center space-children-row small-space">
        <i class="icon icon-plus"></i>
        <span>Assign New Test</span>
    </span>
</div>

<ng-container *ngIf="testCluCon?.length > 0">
    <!-- <div class="flex-row body-regular margin-bottom-1em">
        <span>Sort Clusters by:</span>
        <span class="sort-selection" [class.selected]="clusterSortExpression === 'start_date'" (click)="sortBy('start_date')">Test Date</span>
        <span class="sort-selection" [class.selected]="clusterSortExpression === 'id'" (click)="sortBy('id')">Map order</span>
    </div> -->

    <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let cluCon of testCluCon" [expanded]="cluCon.isAccordionOpen">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div *ngIf="cluCon.cluster; then clusterName else constructName" class="cluster-name"></div>
                    <ng-template #clusterName>
                        <span class="region-legend-circle" [ngClass]="['region-background-' + cluCon.parent.region]"></span>
                        <span>CLU: </span>
                        <span [innerHTML]="cluCon.name"></span>
                        <span class="test-count">({{ cluCon.tests?.length }})</span>
                    </ng-template>
                    <ng-template #constructName>
                        <span class="region-legend-circle" [ngClass]="['region-background-' + cluCon.parent.region]"></span>
                        <span>CON: </span>
                        <span [innerHTML]="cluCon.name"></span>
                        <span>&nbsp;(CLU: <span [innerHTML]="cluCon.clusterName"></span>)</span>
                        <span class="test-count">({{ cluCon.tests?.length }})</span>
                    </ng-template>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="table-component">
                <!-- Table Header -->
                <div class="flex-row justify-center">
                    <div class="table-header flex-column flex-15 justify-center">Test Type</div>
                    <div class="table-header flex-column flex-10 justify-center">Grade Band</div>
                    <div class="table-header flex-column flex-15 justify-center">Test Date</div>
                    <div class="table-header flex-column flex-15 justify-center no-border">Close Of Window</div>
                    <div class="table-header flex-column flex-10 justify-center"># of Forms</div>
                    <div class="table-header flex-column flex-10 justify-center"># of Students Submitted</div>
                    <div class="table-header flex-column flex-15 justify-center">Score</div>
                    <div class="table-header flex-column flex-10 justify-center">Actions</div>
                </div>
                <!-- Table Assessment Rows -->
                <ng-container *ngFor="let test of cluCon.tests">
                    <div class="row-wrapper position-relative">
                        <div class="flex-row justify-center" [class.inactive]="editingTest">
                            <div class="table-data test-type flex-column flex-15 justify-center text-center" [class.with-retake]="test.retest?.length">{{ UtilsService.testformTypeToText(test.type, cluCon.construct) }}</div>
                            <div class="table-data flex-column flex-10 justify-center text-center">{{ UtilsService.gradeBinToText(test.grade) }}</div>
                            <div class="table-data flex-column flex-15 justify-center text-center">{{ test.start_date | date: dateFormat.shortDate : dateFormat.timezone }}</div>
                            <div class="table-data flex-column flex-15 justify-center text-center">{{ test.end_date | date: dateFormat.shortDate : dateFormat.timezone }}</div>

                            <div class="table-data flex-column flex-10 justify-center text-center">{{ test.forms }}</div>

                            <div class="table-data students-submitted flex-10 justify-space-between align-items-center">
                                <span></span>
                                <span>{{ test.taken || 0 }}/{{ AssessmentManagementService.models.section.total_students || 0 }}</span>
                                <div class="test-action" matTooltip="Status Report" (click)="getStudentTestStatus(test)">
                                    <i class="icon text-info icon-doc-text"></i>
                                </div>
                            </div>

                            <div class="table-data flex-column flex-15 justify-center text-center" [innerHTML]="UtilsService.formatClusterScore(test.weight)"></div>

                            <div class="table-data table-actions flex-10 justify-center align-items-center space-children-row">
                                <div class="test-action" matTooltip="Preview" (click)="previewAssessmentModal(test)">
                                    <i class="icon text-info icon-preview"></i>
                                </div>

                                <div class="test-action" matTooltip="Edit" *ngIf="isCurrentSchoolYear(AssessmentManagementService.models.schoolYear?.end_date)" (click)="editTest(test)">
                                    <i class="icon text-info icon-pencil"></i>
                                </div>

                                <div class="test-action" matTooltip="See Report" uiSref="heatmap" [uiParams]="{ asspId: test.id, retestId: 0 }" *ngIf="test.taken">
                                    <i class="icon text-info icon-bar-chart"></i>
                                </div>

                                <div class="test-action unavailable" matTooltip="Delete will become active when there are no retests remaining" *ngIf="!test.taken && test.retest?.length > 0">
                                    <i class="icon icon-trash-o"></i>
                                </div>
                                <div class="test-action text-danger" matTooltip="Delete" *ngIf="!test.taken && test.retest?.length === 0" (click)="deleteAssessment(test, cluCon)">
                                    <i class="icon icon-trash-o"></i>
                                </div>
                            </div>
                        </div>

                        <edit-test-form
                            *ngIf="test.isEditing"
                            [test]="test"
                            [cluCon]="cluCon"
                            [section]="AssessmentManagementService.models.section"
                            (savedEdit)="onEditTestForm($event)"
                            (finishedEdit)="cancelTestEdit()">
                        </edit-test-form>
                    </div>

                    <!-- Test retest rows -->
                    <ng-container *ngFor="let retest of test.retest; index as rIdx">
                        <div class="row-wrapper position-relative">
                            <div class="flex-row retest-row justify-center" [class.inactive]="editingTest">
                                <div class="table-data retake-test-type flex-column flex-15 justify-center text-center" [class.last-row]="rIdx === test.retest.length - 1">{{ cluCon.construct ? 'Recheck' : 'Retest' }} {{ retest.name }}</div>
                                <div class="table-data flex-column flex-10 justify-center text-center">{{ UtilsService.gradeBinToText(test.grade) }}</div>
                                <div class="table-data flex-column flex-15 justify-center text-center">{{ retest.start_date | date: dateFormat.shortDate : dateFormat.timezone }}</div>
                                <div class="table-data flex-column flex-15 justify-center text-center">{{ retest.end_date | date: dateFormat.shortDate : dateFormat.timezone }}</div>

                                <div class="table-data flex-column flex-10 justify-center text-center">{{ retest.forms }}</div>

                                <div class="table-data students-submitted flex-10 justify-space-between align-items-center">
                                    <span></span>
                                    <span>{{ retest.taken || 0 }}/{{ AssessmentManagementService.models.section.total_students || 0 }}</span>
                                    <div class="test-action" matTooltip="Status Report" (click)="getStudentTestStatus(test, retest)">
                                        <i class="icon text-info icon-doc-text"></i>
                                    </div>
                                </div>

                                <div class="table-data flex-column flex-15 justify-center text-center" [innerHTML]="UtilsService.formatClusterScore(retest.weight)"></div>

                                <div class="table-data table-actions flex-10 justify-center align-items-center space-children-row">
                                    <div class="test-action" matTooltip="Preview" *ngIf="retest.retest_id" (click)="previewAssessmentModal(test, retest)">
                                        <i class="icon text-info icon-preview"></i>
                                    </div>
                                    <div class="test-action unavailable" matTooltip="This retest cannot be previewed" *ngIf="!retest.retest_id">
                                        <i class="icon icon-preview"></i>
                                    </div>

                                    <div class="test-action" matTooltip="Edit" *ngIf="isCurrentSchoolYear(AssessmentManagementService.models.schoolYear?.end_date) && retest.retest_id" (click)="editTest(retest)">
                                        <i class="icon text-info icon-pencil"></i>
                                    </div>

                                    <div class="test-action" matTooltip="See Report" uiSref="heatmap" [uiParams]="{ asspId: test.id, retestId: retest.retest_id }" *ngIf="retest.taken && retest.retest_id">
                                        <i class="icon text-info icon-bar-chart"></i>
                                    </div>
                                    <div class="test-action unavailable" matTooltip="This retest report is no longer available" *ngIf="retest.taken && !retest.retest_id">
                                        <i class="icon icon-bar-chart"></i>
                                    </div>

                                    <div class="test-action text-danger" matTooltip="Delete" *ngIf="!retest.taken && retest.retest_id" (click)="deleteAssessment(test, cluCon, retest)">
                                        <i class="icon icon-trash-o"></i>
                                    </div>
                                </div>
                            </div>

                            <edit-test-form
                                *ngIf="retest.isEditing"
                                [test]="test"
                                [retest]="retest"
                                [cluCon]="cluCon"
                                [section]="AssessmentManagementService.models.section"
                                (savedEdit)="onEditTestForm($event)"
                                (finishedEdit)="cancelTestEdit()">
                            </edit-test-form>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="cluster-actions flex-row align-items-center justify-end" *ngIf="isCurrentSchoolYear(AssessmentManagementService.models.schoolYear?.end_date) && !cluCon.isNewTestFormActive" [class.inactive]="editingTest">
                <button class="btn primary-button body-regular" (click)="openNewClusterTestForm(cluCon)">Add a {{ cluCon.construct ? 'Recheck' : 'Retest' }}</button>
            </div>

            <new-test-form
                type="inline"
                *ngIf="cluCon.isNewTestFormActive"
                [isInactive]="editingTest"
                [dropdownModels]="AssessmentManagementService.models"
                [schoolYears]="schoolYears"
                [cluster]="cluCon"
                (testCreated)="onNewTestForm($event)">
            </new-test-form>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
