import { select } from 'd3-selection';
import { Component, Input, ElementRef, AfterViewInit } from '@angular/core';

import './score-bubble.component.scss';


@Component({
    selector: 'score-bubble',
    templateUrl: './score-bubble.component.svg.html',
    // styleUrls: ['./score-bubble.component.scss'],
    host: {
        class: 'score-bubble',
    },
})
export class ScoreBubbleComponent implements AfterViewInit {

    @Input() question: any;
    @Input() sessionSummary: boolean;
    private radius = 16;
    private angle = 150; // Start drawing at the 5 o'clock position
    private anglePadding = 360 / 12;
    private centerX = 25;
    private centerY = 25;

    constructor(
        private element: ElementRef,
    ) {}

    public ngAfterViewInit() {
        this.drawTries();
    }

    private drawTries() {
        let selection = select(this.element.nativeElement).select('.tries-group');
        // Clean up the circle before inserting new elements
        this.resetTries();
        for (let i = 0; i < this.question.tries; i++) {
            this.drawTryCircle(selection);
        }
    }

    private drawTryCircle(selection) {
        selection
            .append('circle')
            .classed('try-bubble', true)
            .attr('r', 3)
            .attr('cx', this.centerX + (this.radius * Math.sin(0))) // Calculate the position for the initial circle north in outer circle
            .attr('cy', this.centerY - (this.radius * Math.cos(0)) + 1)
            .attr('transform', 'rotate(' + this.angle + ' ' + this.centerX + ' ' + this.centerY + ')');

        this.angle += this.anglePadding;
    }

    private resetTries() {
        this.angle = 150;
        select(this.element.nativeElement).selectAll('.try-bubble').remove();
    }
}
