import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { appInfo } from '@/app.constant';
import { GrowlerService } from './growler.service';


export interface ServerInfo {
    version: string;
    date: string;
}

export interface BackendResponse {
    result: any;
    msg?: any;
    success: boolean;
    server_info: ServerInfo
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {

    public isAppOutdated: boolean = false;

    constructor(
        private http: HttpClient,
        private GrowlerService: GrowlerService,
    ) { }


    public get(url, params = {}, options = {}) {
        // Remove empty params
        this.removeFalseyValues(params);

        const config = Object.assign({ params }, options);

        return this.base('GET', url, config);
    }

    public post(url, body, options = {}) {
        return this.requestWithData(url, body, 'POST', options);
    }

    public put(url, body, options = {}) {
        return this.requestWithData(url, body, 'PUT', options);
    }

    public patch(url, body, options = {}) {
        return this.requestWithData(url, body, 'PATCH', options);
    }

    public delete(url, body, options = {}) {
        return this.requestWithData(url, body, 'DELETE', options);
    }

    public any(url, body, method = 'POST', options = {}) {
        return this.requestWithData(url, body, method.toUpperCase(), options);
    }

    // This is the service that we use to config all http requests and avoid duplicating config objects
    // For adding a global action to request or responses, see http-interceptors.js
    private base(method, url, requestConfig) {
        let defaultConfig = {
            cache: false,
            timeout: 120000
        };
        let config = Object.assign(defaultConfig, requestConfig);

        return this.http.request(method, url, config).toPromise()
            .then((response) => {
                return config.rawResponse ? Promise.resolve(response) : this.extractResponseBody(response);
            });
    }

    private requestWithData(url, body, method, options = {}) {
        this.removeFalseyValues(body);

        // Serialize body payload as params (username=stu&password=123)
        const params = new HttpParams({ fromObject: body });
        let config = {
            body: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;'
            }
        };
        Object.assign(config, options);

        return this.base(method, url, config);
    }

    private removeFalseyValues(obj) {
        // Remove null/undefined/NaN form data
        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (val === null || typeof val === 'undefined' || (typeof val === 'number' && Number.isNaN(val))) {
                delete obj[key];
            }
        });
    }

    private extractResponseBody(response) {
        // Status 200
        if (response) {
            // Check server info for current app version
            if (response.server_info) {
                let serverVersion = response.server_info.version.match(/\d+/ig);
                let appVersion = appInfo.version.match(/\d+/ig);
                // If major or minor versions do not match app should be reloaded
                if (serverVersion[0] !== appVersion[0] || serverVersion[1] !== appVersion[1]) {
                    this.isAppOutdated = true;
                }
            }
            // API success
            if (response.success) {
                return Promise.resolve(response);
            }
            // API handled exception
            if (response.result) {
                this.GrowlerService.warning(response.result.msg);
            }

            return Promise.reject(response);
        }

        // HTTP fail or API unhandled exception
        return Promise.reject(response);
    }
}
