<div *ngIf="AssessmentStateService.data.multi.current > stemIndex">
    <div class="question-part bold" *ngIf="AssessmentStateService.data.multi.total > 1">Part {{ stemIndex + 1 }} of {{ AssessmentStateService.data.multi.total }}</div>

    <div class="assessment-rlc-info" *ngIf="SessionService.userAccess?.permission >= 8">[Construct: <b [innerHTML]="stem.construct"></b>, level: <b>{{ stem.level }}</b>]</div>

    <div [mathHTML]="stem.description"></div>

    <div class="assessment-attachment-container" *ngFor="let attachment of stem.attachments">
        <a [href]="attachment.filename.substring(0, 6) == '/media' ? apiServer.domain + attachment.filename : attachment.filename" data-lightbox="roadtrip" data-title="{{ attachment.name }}">
            <img [src]="attachment.filename.substring(0, 6) == '/media' ? apiServer.domain + attachment.filename : attachment.filename" class="assessment-attachment" [ngStyle]="{ width: attachment.width, height: attachment.height }" [attr.title]="attachment.name">
            <span class="assessment-attachment-magnify-icon"></span>
        </a>
    </div>

    <foil [foil]="foil" [foilIndex]="foilIndex" [stem]="stem" [stemIndex]="stemIndex" *ngFor="let foil of stem.foils; index as foilIndex"></foil>

    <div class="flex-row" [class.justify-end]="AssessmentStateService.data.question?.template !== 2">
        <button class="btn primary-button" *ngIf="!AssessmentStateService.data.isReportPage && AssessmentStateService.data.multi.current === stemIndex + 1 && AssessmentStateService.data.multi.current < AssessmentStateService.data.multi.total" (click)="displayNextConfirmation()" [disabled]="!AssessmentStateService.data.multi.isPartAnswered">Proceed</button>
        <button class="btn ghost-button" *ngIf="stem.isRevealable && !stem.isCorrect && AssessmentStateService.data.multi.current > stemIndex + 1" (click)="revealAnswer.emit(stem)">Reveal</button>
    </div>
</div>
