import { Component, Input, Output, EventEmitter } from '@angular/core';

import { mapSizesAndBounds } from '@/app.constant';
import { AnalyticsService } from '../../core/service/analytics.service';
import { UtilsService } from '../../core/service/utils.service';
import { ModalService } from '../../shared/service/modal.service';
import { MapService } from '../map.service';

import './map-cluster.component.scss';


@Component({
    selector: '[map-cluster]',
    templateUrl: './map-cluster.component.svg.html',
    // styleUrls: ['./map-cluster.component.scss'],
    host: {
        class: 'map-cluster',
    },
})
export class MapClusterComponent {

    @Input() cluster: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;

    constructor(
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private ModalService: ModalService,
        public MapService: MapService,
    ) {}

    public showClusterResources($event, cluster) {
        $event.stopPropagation();
        this.AnalyticsService.action({'action': 'show_map_cluster_resources', 'cluster': cluster.id});

        this.UtilsService.addLoadingOverlay();
        this.MapService.getMapResources()
            .then(resources => {
                const filteredResources = resources.filter(resource => {
                    return resource.cluster_id.includes(cluster.id);
                });
                this.ModalService.openMapClusterResources(cluster, filteredResources);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public showAssessmentOptions($event, cluster) {
        $event.stopPropagation();
        this.AnalyticsService.action({'action': 'show_map_cluster_assessment_options', 'cluster': cluster.id});

        if (cluster.assessment) {
            this.UtilsService.addLoadingOverlay();
            this.MapService.getClusterAssessmentOptions(cluster.id)
                .then(availableAssessments => {
                    this.MapService.openMapAssessmentOptions(cluster, availableAssessments);
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }
}
