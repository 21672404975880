<form *ngIf="questions && questions.length" name="feedbackForm" class="feedback-survey" novalidate>
    <div *ngIf="!includesPrompt">
        <div class="form-instructions">
            <span>Please answer&nbsp;</span>
            <span [ngPlural]="questions.length">
                <ng-template ngPluralCase="one">this question</ng-template>
                <ng-template ngPluralCase="other">these questions</ng-template>
            </span>
            <span>&nbsp;before proceeding:</span>
        </div>
    </div>

    <div *ngIf="includesPrompt" class="space-children-column small-space">
        <div class="form-instructions bold">For the questions that follow, answer based on the following prompt:</div>
        <div class="form-prompt" [innerHTML]="questions[0].prompt"></div>
    </div>

    <div class="hr"></div>

    <div *ngFor="let question of questions" class="question space-children-column small-space">
        <div class="question-title">
            <label>{{ question.feedback }}</label>
        </div>

        <div class="form-group">
            <div class="radio-input-row flex-row justify-center" *ngIf="question.type === 'scale' || question.type === 'WestEd'">
                <!-- Radio buttons aligned vertically -->
                <div class="flex-none flex-column" *ngIf="columnRadio">
                    <mat-radio-group [(ngModel)]="question.response" name="q-{{ question.id }}" class="radio-group-column" (change)="checkFormCompletion()" [required]="question.required">
                        <mat-radio-button *ngFor="let option of question.options" class="radio-button-column body-regular" [value]="option.id">{{ option.option }}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- Radio buttons aligned horizontally -->
                <div class="flex-column align-items-center space-children-column small-space" *ngIf="!columnRadio">
                    <div class="option-range-bar flex-row align-items-center justify-space-around">
                        <label class="option-label direction-indicator">{{ question.options[1].option }}</label>
                        <label class="option-label direction-indicator">{{ question.options[question.options.length - 2].option }}</label>
                    </div>

                    <div class="flex-row align-items-center justify-center space-children-row big-space">
                        <div class="flex align-items-center justify-end">
                            <label for="q-{{ question.id }}-option-{{ question.options[0].id }}-input" class="option-label">{{ question.options[0].option }}</label>
                        </div>

                        <mat-radio-group [(ngModel)]="question.response" name="q-{{ question.id }}" class="question-options flex justify-center align-items-center space-children-row big-space" (change)="checkFormCompletion()" [required]="question.required">
                            <mat-radio-button *ngFor="let option of question.options" id="q-{{ question.id }}-option-{{ option.id }}" class="option flex-none align-items-center" [value]="option.id"></mat-radio-button>
                        </mat-radio-group>

                        <div class="flex align-items-center justify-start">
                            <label for="q-{{ question.id }}-option-{{ question.options[question.options.length - 1].id }}-input" class="option-label">{{ question.options[question.options.length - 1].option }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="question.type === 'open ended' || question.type === 'text'" class="question-default flex-row justify-start">
                <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput type="text" maxlength="256" [(ngModel)]="question.response" name="q-{{ question.id }}" (input)="checkFormCompletion()" [required]="question.required">
                </mat-form-field>
            </div>
        </div>

    </div>
</form>
