import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

import { select as d3select } from 'd3-selection';

import { SequencerService } from '../sequencer.service';
import { SequencerStateService } from '../sequencer-state.service';

import './sequencer-svg.component.scss';


@Component({
    selector: 'sequencer',
    templateUrl: 'sequencer-svg.component.html',
    // styleUrls: ['./sequencer-svg.component.scss'],
    host: {
        class: 'sequencer-svg-container',
    }
})
export class SequencerComponent implements AfterViewInit {

    @ViewChild('svgRoot', { static: false }) svgRoot: ElementRef;

    constructor(
        public SequencerStateService: SequencerStateService,
        private SequencerService: SequencerService,
    ) {}

    public ngAfterViewInit() {
        this.SequencerStateService.sequencerData$.subscribe(sequencerData => {
            if (!this.SequencerStateService.data.model.mapLoaded) {
                this.SequencerService.updateMapRegions(sequencerData.regions);
            }
            // Select the <svg> tag from the template
            const svgElement = d3select(this.svgRoot.nativeElement);

            this.SequencerService.initializeSSPData(sequencerData.ssp);
            this.SequencerService.initializeSequencer(svgElement);
        });
    }

    public toggleDateRepresentation() {
        this.SequencerStateService.data.dateShown = !this.SequencerStateService.data.dateShown;
        this.SequencerService.renderTabs();
    }
}

