import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { select as d3Select, event as d3Event } from 'd3-selection';
import { drag } from 'd3-drag';
import { zoom, zoomTransform, zoomIdentity } from 'd3-zoom';

import { mapSizesAndBounds } from '@/app.constant';
import { AnalyticsService } from '../../core/service/analytics.service';
import { UtilsService } from '../../core/service/utils.service';
import { ModalService } from '../../shared/service/modal.service';
import { MapService } from '../map.service';

import './learning-map.component.scss';


@Component({
    selector: 'learning-map',
    templateUrl: './learning-map.component.svg.html',
    // styleUrls: ['./learning-map.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    host: {
        class: 'learning-map',
    },
})
export class LearningMapComponent implements OnInit, AfterViewInit, OnDestroy {

    // Zoom and minimap
    public readonly mapSizesAndBounds = mapSizesAndBounds;
    private minimapSvg: any;
    private minimapZoomElement: any;
    private zoomBehavior: any;
    private zoomContainer: any;
    private zoomElement: any;
    private zoomSub: Subscription;

    constructor(
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private ModalService: ModalService,
        public MapService: MapService,
    ) {}

    public ngOnInit() {
        this.MapService.searchable.linkedFunctions.displayPins = this.displayPins.bind(this);

        this.zoomBehavior = zoom()
            .scaleExtent(<any>this.mapSizesAndBounds.scaleExtent)
            .translateExtent(<any>this.mapSizesAndBounds.translateExtent)
            .on('zoom', () => this.zoomHandler())
            .on('end', () => this.zoomEndHandler());

        this.zoomSub = this.MapService.zoom$.subscribe(({ level, x, y, duration, transform }) => {
            if (transform) {
                // Uses d3 scale level directly
                this.animateZoom(level, x, y, duration);
            } else {
                // Uses own zoom level thresholds directly
                this.zoomAndPosition(level, x, y, duration);
            }
        });

        this.UtilsService.addLoadingOverlay();
        const p1 = this.MapService.getLearningMap()
            .then(mapData => {
                this.MapService.learningMap.data = mapData;

                if (this.MapService.learningMap.data.feedback && this.MapService.learningMap.data.feedback.length > 0) {
                    this.ModalService.openFeedbackFormModal(this.MapService.learningMap.data.feedback);
                }

                let mapAnimated = JSON.parse(localStorage.getItem('map_animated'));
                if (mapAnimated) {
                    this.animateZoom(1.05, 1000, 300, 500);
                } else {
                    this.runOpeningAnimation();
                    localStorage.setItem('map_animated', 'true');
                }

                return mapData;
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());

        const p2 = this.MapService.getMapAssessments()
            .catch(console.warn);

        Promise.all([p1, p2])
            .then(results => {
                const map = results[0];
                const mapAssessments = results[1];

                if (!map || !mapAssessments) {
                    return Promise.reject(results);
                }

                map.fields.forEach(field => {
                    if (field.wormhole) return;

                    const fieldAssessment = mapAssessments.fields[field.id];
                    // Backend will return a dictionary of { fields: {} } if not a student
                    if (fieldAssessment) {
                        field.test_count = fieldAssessment.count;
                        field.regions.forEach(region => {
                            const regionAssessment = fieldAssessment.regions[region.id];
                            region.test_count = regionAssessment.count;
                            region.clusters.forEach(cluster => {
                                const clusterAssessment = regionAssessment.clusters[cluster.id];
                                cluster.test_count = clusterAssessment.count;
                                cluster.constructs.forEach(construct => {
                                    construct.test_count = clusterAssessment.constructs[construct.id];
                                });
                            });
                        });
                    }
                });

                localStorage.setItem('learning_map', JSON.stringify(map));
            })
            .catch(console.warn);
    }

    public ngAfterViewInit() {
        this.zoomContainer = d3Select('#zoom-container');
        this.zoomElement = d3Select('#zoom-element');

        this.zoomContainer
            .call(this.zoomBehavior)
            .on('dblclick.zoom', null);
    }

    public ngOnDestroy() {
        this.zoomSub.unsubscribe();
    }

    public initMinimap(minimapNode) {
        let minimapZoomElementX, minimapZoomElementY;
        let dragBehavior = drag()
            .on('start', () => {
                let currentPos = this.UtilsService.getXYFromTranslate(this.minimapZoomElement.attr('transform'));
                minimapZoomElementX = currentPos[0];
                minimapZoomElementY = currentPos[1];
            })
            .on('drag', () => {
                let mapTransform = zoomTransform(this.zoomContainer.node());
                minimapZoomElementX += d3Event.dx;
                minimapZoomElementY += d3Event.dy;
                // Update viewport coordinates in minimap
                this.minimapZoomElement.attr('transform', 'translate(' + minimapZoomElementX + ',' + minimapZoomElementY + ')');

                // Calculate drag limits
                let x = Math.min(
                    this.mapSizesAndBounds.width * 0.08,
                    Math.max(this.mapSizesAndBounds.width * (1 - mapTransform.k), -minimapZoomElementX * mapTransform.k)
                );
                let y = Math.min(
                    this.mapSizesAndBounds.height * 0.13,
                    Math.max(this.mapSizesAndBounds.height * (1 - mapTransform.k), -minimapZoomElementY * mapTransform.k)
                );

                // Update map zoom transform values
                // Keep the same scale and update x and y values over a 0s transition
                this.animateZoom(mapTransform.k, x, y, 0);
            });

        this.minimapSvg = d3Select(minimapNode).select('.minimap-svg').call(this.zoomBehavior);
        this.minimapZoomElement = this.minimapSvg.select('.zoom-element').call(dragBehavior);
    }

    private updateMinimap(mapTransform) {
        let viewportHeight = this.mapSizesAndBounds.height / mapTransform.k;
        let viewportWidth = this.mapSizesAndBounds.width / mapTransform.k;

        // Update map and minimap zoom value since their zoom values are independent and we are trying to keep map and minimap in sync
        this.minimapSvg.property('__zoom', mapTransform);
        this.zoomContainer.property('__zoom', mapTransform);

        // Update rectangle/viewport position in minimap
        this.minimapZoomElement.attr('transform', 'translate(' + (-mapTransform.x / mapTransform.k) + ',' + (-mapTransform.y / mapTransform.k) + ')')
            .select('.minimap-viewport')
            .attr('width', viewportWidth)
            .attr('height', viewportHeight);
    }

    private zoomHandler() {
        // if (!this.minimapSvg) {
        //     let minimapNode = document.querySelector('#minimap');
        //     this.initMinimap(minimapNode);
        // }

        if (d3Event.sourceEvent instanceof MouseEvent && !(d3Event.sourceEvent instanceof WheelEvent) && !!d3Event.sourceEvent.target.closest('.minimap-svg')) {
            // Ignore panning in minimap outside of drag viewport
            return false;
        }

        let t = d3Event.transform;
        // Update zoom element with the latest transform from the model
        this.zoomElement.attr('transform', t);
        // Update viewport in minimap
        this.updateMinimap(t);

        // if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[0]) {
        //     this.MapService.learningMap.option.currentZoomLevel = 0;
        // } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[1]) {
        //     this.MapService.learningMap.option.currentZoomLevel = 1;
        // } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[2]) {
        //     this.MapService.learningMap.option.currentZoomLevel = 2;
        // } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[3]) {
        //     this.MapService.learningMap.option.currentZoomLevel = 3;
        // } else {
        //     this.MapService.learningMap.option.currentZoomLevel = 4;
        // }
    }

    private zoomEndHandler() {
        let t = d3Event.transform;

        if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[0]) {
            this.MapService.learningMap.option.currentZoomLevel = 0;
        } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[1]) {
            this.MapService.learningMap.option.currentZoomLevel = 1;
        } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[2]) {
            this.MapService.learningMap.option.currentZoomLevel = 2;
        } else if (t.k < this.mapSizesAndBounds.zoomScalingThresholds[3]) {
            this.MapService.learningMap.option.currentZoomLevel = 3;
        } else {
            this.MapService.learningMap.option.currentZoomLevel = 4;
        }
    }

    public zoomAndPosition(level: number, centerX = 0, centerY = 0, duration = 500) {
        let x: number;
        let y: number;
        let offset: number = 150;
        let viewWidth: number = this.mapSizesAndBounds.viewSizes[level].w; // target zoom dimensions
        let viewHeight: number = this.mapSizesAndBounds.viewSizes[level].h;
        if (level === 0) {
            // map's aspect ratio was chosen to fit iPad.  the following helps if/when the aspect ratio is very wide
            x = -(this.mapSizesAndBounds.width - offset - viewWidth) / 2;
            y = -(this.mapSizesAndBounds.height - viewHeight) / 2;
        } else {
            if (centerX && centerY) {
                x = -(centerX - offset - viewWidth / 2) * this.mapSizesAndBounds.width / viewWidth;
                y = -(centerY - viewHeight / 2) * this.mapSizesAndBounds.width / viewWidth;
            }
        }
        this.animateZoom(this.mapSizesAndBounds.width / viewWidth, x, y, duration);
    }

    private animateZoom(newScale, x, y, animDuration) {
        if ((!x && x !== 0) || (!y && y !== 0)) {
            // whatever point is at the center, keep it in the center when changing the scale
            let t = zoomTransform(this.zoomContainer.node());
            let translateX = (this.mapSizesAndBounds.width / 2 - t.x) / t.k;
            let translateY = (this.mapSizesAndBounds.height / 2 - t.y) / t.k;

            x = this.mapSizesAndBounds.width / 2 - translateX * newScale;
            y = this.mapSizesAndBounds.height / 2 - translateY * newScale;
        }

        this.zoomContainer.transition()
            .duration(animDuration)
            .call(this.zoomBehavior.transform, () => {
                return zoomIdentity.translate(x, y).scale(newScale);
            });

        // Returns a promise that will resolve at the end of the animation
        return new Promise((resolve) => {
            setTimeout(resolve, animDuration);
        });
    }

    private runOpeningAnimation() {
        let x = this.MapService.learningMap.data.clusters[0].attributes.cx;
        let y = this.MapService.learningMap.data.clusters[0].attributes.cy;
        let scaleFactor = 11;
        // Immediately zoom into center of cluster
        this.animateZoom(scaleFactor, -x * scaleFactor, -y * scaleFactor, 0);
        // Zoom out to base position over given duration
        this.animateZoom(1, 0, 0, 1000);
    }

    public displayPins(item, searchable) {
        let scale = 1.09;

        this.animateZoom(scale, 0, 0, 500)
            .then(() => {
                let iterable = searchable === 'standard' ? item.primary : item.constructs;
                iterable.forEach(construct => {
                    /** Add pulsing animation to primary pins */
                    d3Select(`#${searchable}-pulse-${construct.id}`)
                        .property('repeat', 5)
                        .call(this.disperseAnimation.bind(this));
                });
            })
            .catch(() => {});
    }

    private disperseAnimation(selection) {
        /** variable size on scale level */
        let radius = 300 + (4 - this.MapService.learningMap.option.currentZoomLevel) * 250;
        let count = selection.property('repeat');

        if (count > 0) {
            count -= 1;
            selection
                .property('repeat', count)
                .attr('opacity', 0.7)
                .attr('r', 0)
                .transition()
                .duration(1500)
                .attr('r', radius)
                .attr('opacity', 0)
                .on('end', () => {
                    this.disperseAnimation(selection);
                });
        } else {
            selection
                .attr('r', 0)
                .attr('opacity', 0)
                .property('repeat', 0);
        }
    }
}
