<div class="filter-bar-wrapper" [class.open]="filterBarOpen">
    <button class="btn ghost-button map-fab icon-button" (click)="toggleMapFilterBar()">
        <div class="bar-background" [style.transform]="barScale"></div>
        <i class="icon" [ngClass]="{ 'icon-filter': !filterBarOpen, 'icon-x': filterBarOpen }"></i>
    </button>

    <div class="filter-bar flex-row justify-space-between align-items-center space-children-row big-space">
        <div class="flex-none space-children-row small-space">
            <button type="button" class="btn btn-sm text-info flex-none align-items-center body-regular" (click)="toggleSearchPanel('standards')" [disabled]="!standards">
                <span class="mr-1" *ngIf="!standards"><i class="fas fa-spinner fa-spin"></i></span>
                <span>CCSS-M Standards&nbsp;</span>
                <i class="icon icon-caret-down" [class.rotate]="MapService.searchable.meta.isOpen && MapService.searchable.meta.type === 'standards'"></i>
            </button>

            <button type="button" class="btn btn-sm text-info flex-none align-items-center body-regular" (click)="toggleSearchPanel('misconceptions')" [disabled]="!misconceptions">
                <span class="mr-1" *ngIf="!misconceptions"><i class="fas fa-spinner fa-spin"></i></span>
                <span>Misconceptions&nbsp;</span>
                <i class="icon icon-caret-down" [class.rotate]="MapService.searchable.meta.isOpen && MapService.searchable.meta.type === 'misconceptions'"></i>
            </button>
        </div>

        <button class="btn btn-sm text-info flex-none align-items-center body-regular" #mapOptionsTrigger="ngbPopover" [ngbPopover]="mapOptions" [autoClose]="'outside'" placement="bottom-right" popoverClass="popover-dropdown map-options-popover" container="body">
            <span>Options&nbsp;</span>
            <i class="icon icon-caret-down" [class.rotate]="mapOptionsTrigger.isOpen()"></i>
        </button>

        <ng-template #mapOptions>
            <div class="flex-row align-items-center space-children-row medium-space">
                <div class="grades-group space-children-row flex-none align-items-center">
                    <label>Grades:</label>
                    <mat-checkbox *ngFor="let grade of MapService.learningMap.gradeNames" [(ngModel)]="grade.selected" [value]="grade.name" [disableRipple]="true" (change)="gradeSelected()">{{ grade.name }}</mat-checkbox>
                </div>

                <mat-checkbox [(ngModel)]="MapService.learningMap.option.showAllRegionLabels" (change)="allRegionLabelsChanged()" id="show-labels" [disableRipple]="true">Big Idea Labels</mat-checkbox>

                <mat-checkbox [(ngModel)]="MapService.learningMap.option.codeEnabled" id="show-codes" [disableRipple]="true">Code</mat-checkbox>
            </div>
        </ng-template>
    </div>
</div>
