<div class="legend-items flex-row body-regular align-items-center justify-space-between">
    <div class="legend-subsection">
        <div class="subsection-title bold">Key:</div>

        <div class="subsection-content flex-none space-children-row legend-option" *ngIf="scoresToggling">
            <div class="flex-none align-items-center">
                <span class="region-legend background-correct dark-theme"></span>
                <span>Correct</span>
            </div>

            <div class="flex-none align-items-center">
                <span class="region-legend background-revised"></span>
                <span>Revised Correctly</span>
            </div>
        </div>
        <div [class.invisible]="!scoresToggling"><i>as a percent of the tested items on construct</i></div>
    </div>

    <div class="legend-subsection" *ngIf="scoresSimple || (scoresToggling && cluster.selectedConstruct)">
        <div class="subsection-title bold invisible">Key:</div>

        <div class="subsection-content flex-none space-children-row">
            <div class="flex-none align-items-center space-children-row small-space">
                <span>Untested</span>
                <span class="region-legend untested"></span>
            </div>

            <div class="flex-none align-items-center space-children-row small-space">
                <span>0%</span>
                <span class="region-legend background-incorrect"></span>
            </div>

            <div class="flex-none align-items-center space-children-row small-space">
                <span>1-33%</span>
                <span class="region-legend background-correct opacity-25"></span>
            </div>

            <div class="flex-none align-items-center space-children-row small-space">
                <span>34-66%</span>
                <span class="region-legend background-correct opacity-50"></span>
            </div>

            <div class="flex-none align-items-center space-children-row small-space">
                <span>67-99%</span>
                <span class="region-legend background-correct opacity-75"></span>
            </div>

            <div class="flex-none align-items-center space-children-row small-space">
                <span>100%</span>
                <span class="region-legend background-correct"></span>
            </div>
        </div>

        <div><i>percent correct at each level</i></div>
    </div>
</div>
