<!-- Stem type 1: Multiple Choice | Stem type 2: Multiple Select -->
<div *ngIf="stem.type === 1 || stem.type === 2" class="flex-row align-items-center response-selection--foil space-children-row" [ngClass]="{ revealed: AssessmentStateService.data.answerKey && foil.key?.grading === 4 }">
    <span class="roman-id" *ngIf="foil.romanId">{{ foil.romanId }}</span>

    <i class="question-feedback-icon icon" [ngClass]="{ 'icon-times': foil.isCorrect === false, 'icon-check-circle': foil.isCorrect }"></i>

    <!-- MC -->
    <mat-radio-button *ngIf="stem.type === 1" name="ans-{{ stem.id }}" class="assessment-foil" [value]="foil.id" id="foil-{{ foil.id }}" [checked]="stem.selected === foil.id" (change)="stem.selected = foil.id; isStemAnswered()" [disabled]="foil.isCorrect || AssessmentStateService.data.question.isSubmitted || AssessmentStateService.data.multi.current > (stemIndex + 1)" [disableRipple]="true"></mat-radio-button>
    <!-- SM -->
    <mat-checkbox *ngIf="stem.type === 2" name="ans-{{ stem.id }}[{{ foil.id }}]" class="assessment-foil" [value]="foil.id" id="foil-{{ foil.id }}" [(ngModel)]="foil.selected" (change)="isStemAnswered()" [disabled]="foil.isCorrect || AssessmentStateService.data.question.isSubmitted || AssessmentStateService.data.multi.current > (stemIndex + 1)" [disableRipple]="true"></mat-checkbox>

    <span *ngIf="SessionService.userAccess?.permission >= 32 && foil.value">[{{ foil.value }}]</span>

    <div class="flex-column">
        <label for="foil-{{ foil.id }}-input" [mathHTML]="foil.description" class="wrap"></label>

        <label for="foil-{{ foil.id }}-input" class="flex assessment-attachment-container" *ngFor="let attachment of foil.attachments">
            <a [href]="attachment.filename.substring(0, 6) === '/media' ? apiServer.domain + attachment.filename : attachment.filename" data-lightbox="roadtrip" [attr.title]="attachment.name">
                <img [src]="attachment.filename.substring(0, 6) === '/media' ? apiServer.domain + attachment.filename : attachment.filename" class="assessment-attachment" [ngStyle]="{ width: attachment.width || '100%', height: attachment.height }" [attr.title]="attachment.name">
                <span class="assessment-attachment-magnify-icon"></span>
            </a>
        </label>
    </div>
</div>

<!-- Stem type 3: Open Ended | Stem type 4: Numeric Response | Stem type 6: 1 Letter -->
<div *ngIf="stem.type === 3 || stem.type === 4 || stem.type === 6" class="response-open-ended response-selection--foil">
    <label for="foil-{{ foil.id }}" [mathHTML]="foil.description"></label>

    <span *ngIf="SessionService.userAccess?.permission >= 32">
        <span>[{{ foil.value || 0 }}]</span>
        <span *ngIf="stem.type === 4">[{{ foil.min }} &le; <i>x</i> &le; {{ foil.max }}]</span>
        <span *ngIf="stem.type === 3 || stem.type === 6">[Ans: {{ foil.answer }}]</span>
    </span>

    <label for="foil-{{ foil.id }}" class="flex assessment-attachment-container" *ngFor="let attachment of foil.attachments">
        <a [href]="attachment.filename.substring(0, 6) === '/media' ? apiServer.domain + attachment.filename : attachment.filename" data-lightbox="roadtrip" [attr.title]="attachment.name">
            <img [src]="attachment.filename.substring(0, 6) === '/media' ? apiServer.domain + attachment.filename : attachment.filename" class="assessment-attachment" [ngStyle]="{ width: attachment.width || '100%', height: attachment.height }" [attr.title]="attachment.name">
            <span class="assessment-attachment-magnify-icon"></span>
        </a>
    </label>

    <div class="open-ended-input-container flex-row align-items-center space-children-row">
        <span class="roman-id" *ngIf="foil.romanId">{{ foil.romanId }}</span>

        <i class="question-feedback-icon icon" [ngClass]="{ 'icon-times': foil.isCorrect === false, 'icon-check-circle': foil.isCorrect }"></i>

        <div class="input-container assessment-open-end-input" *ngIf="stem.type === 3">
            <mat-form-field appearance="outline" class="assessment-foil-container">
                <mat-label>{{ foil.placeholder }}</mat-label>
                <input matInput type="text" class="assessment-foil" name="ans-{{ stem.id }}[{{ foil.id }}]" id="foil-{{ foil.id }}" [(ngModel)]="foil.selected" (input)="isStemAnswered()" [maxlength]="(foil.length || 20)" [disabled]="foil.isCorrect || AssessmentStateService.data.question.isSubmitted || AssessmentStateService.data.multi.current > (stemIndex + 1)" />
            </mat-form-field>

            <div class="foil-answer" *ngIf="AssessmentStateService.data.answerKey && foil.key?.grading === 4">The correct answer is {{ foil.key.desc }}</div>
        </div>
        <div class="input-container assessment-open-end-input" *ngIf="stem.type === 4">
            <mat-form-field appearance="outline" class="assessment-foil-container">
                <mat-label>{{ foil.placeholder }}</mat-label>
                <input matInput type="number" steps="any" class="assessment-foil" name="ans-{{ stem.id }}[{{ foil.id }}]" id="foil-{{ foil.id }}" [(ngModel)]="foil.selected" (input)="isStemAnswered()" [disabled]="foil.isCorrect || AssessmentStateService.data.question.isSubmitted || AssessmentStateService.data.multi.current > (stemIndex + 1)" />
            </mat-form-field>

            <div class="foil-answer" *ngIf="AssessmentStateService.data.answerKey && foil.key?.grading === 4">The correct answer is {{ foil.key.min === foil.key.max ? foil.key.min : ('any number between ' + foil.key.min + ' and ' + foil.key.max + ' inclusive') }}</div>
        </div>
        <div class="input-container assessment-open-end-input" *ngIf="stem.type === 6">
            <mat-form-field appearance="outline" class="assessment-foil-container">
                <mat-label>{{ foil.placeholder }}</mat-label>
                <input matInput type="text" class="assessment-foil" name="ans-{{ stem.id }}[{{ foil.id }}]" id="foil-{{ foil.id }}" [(ngModel)]="foil.selected" (input)="isStemAnswered()" maxlength="1" [disabled]="foil.isCorrect || AssessmentStateService.data.question.isSubmitted || AssessmentStateService.data.multi.current > (stemIndex + 1)" />
            </mat-form-field>

            <div class="foil-answer" *ngIf="AssessmentStateService.data.answerKey && foil.key?.grading === 4">The correct answer is {{ foil.key.desc }}</div>
        </div>
    </div>
</div>
