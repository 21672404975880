<div class="calendar-event-col-offset"></div>

<div *ngFor="let event of weekEvents" [ngClass]="'calendar-event-col-' + event.totalDays">
    <div
        class="calendar-event"
        *ngIf="event.entity"
        [ngClass]="[
            (event.entity.region ? 'region-color-' + event.entity.region.id : 'region-background-' + event.entity.cluster.region),
            event.roundStart ? 'round-left' : '',
            event.roundEnd ? 'round-right' : ''
        ]">
        <div class="event-text">
            <span *ngIf="event.entity.region && event.entity.region.name" class="calendar-row-title limit-text" [innerHTML]="event.entity.region.name">
            </span>
            <span *ngIf="event.entity.cluster && event.entity.cluster.name" class="calendar-row-title limit-text" [innerHTML]="event.entity.cluster.name">
            </span>
        </div>
    </div>
</div>
