<calculator></calculator>

<div
    class="flex-column"
    [ngClass]="['assessment-question-content-' + AssessmentStateService.data.question?.template]"
    [class.flex-65]="AssessmentStateService.data.question?.template === 1"
    [class.flex-50]="AssessmentStateService.data.question?.template === 3">
    <div class="flex-column flex-auto">
        <div [mathHTML]="AssessmentStateService.data.question?.stem1"></div>

        <div class="assessment-attachment-container" [style.width]="attachment.width" *ngFor="let attachment of AssessmentStateService.data.question?.attachments">
            <a [href]="attachment.filename.substring(0, 6) == '/media' ? apiServer.domain + attachment.filename : attachment.filename" data-lightbox="roadtrip" data-title="{{ attachment.name }}">
                <img [src]="attachment.filename.substring(0, 6) == '/media' ? apiServer.domain + attachment.filename : attachment.filename" class="assessment-attachment" [ngStyle]="{ width: attachment.width, height: attachment.height }" title="{{ attachment.name }}">
                <span class="assessment-attachment-magnify-icon"></span>
            </a>
        </div>

        <div class="flex-none align-items-center calculator-button-wrapper" *ngIf="AssessmentStateService.data.question?.calculator === 1">
            <button class="btn primary-button icon-button calculator-button" (click)="CalculatorService.operate('open', true)">
                <i class="icon icon-calculator"></i>
            </button>
        </div>

        <!-- Question template 2 -->
        <div class="assessment-answer-content-2" *ngIf="AssessmentStateService.data.question?.template === 2">
            <stem [stem]="stem" [stemIndex]="stemIndex" (revealAnswer)="revealAnswer.emit($event)" *ngFor="let stem of AssessmentStateService.data.question?.stems; index as stemIndex"></stem>
        </div>
    </div>
</div>
<!-- Question template 1 and 3 -->
<div
    *ngIf="AssessmentStateService.data.question?.template !== 2"
    class="flex-column"
    [ngClass]="['assessment-answer-content-' + AssessmentStateService.data.question?.template]"
    [class.flex-35]="AssessmentStateService.data.question?.template === 1"
    [class.flex-50]="AssessmentStateService.data.question?.template === 3">
    <stem [stem]="stem" [stemIndex]="stemIndex" (revealAnswer)="revealAnswer.emit($event)" class="flex-column flex-auto" *ngFor="let stem of AssessmentStateService.data.question?.stems; index as stemIndex"></stem>
</div>
