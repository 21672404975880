import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { appInfo, dateFormat } from '@/app.constant';


@Component({
    selector: 'about-modal',
    templateUrl: './about-modal.component.html'
})
export class AboutModalComponent {

    public info = appInfo;
    public deployed = new Date(appInfo.deployed);
    public readonly dateFormat = dateFormat;

    constructor(public activeModal: NgbActiveModal) {}
}
