import { Component, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

import { UtilsService } from '@/core/service/utils.service';

import './back-to-top.component.scss';


@Component({
    selector: 'back-to-top',
    templateUrl: './back-to-top.component.html',
    // styleUrls: ['./back-to-top.component.scss'],
    host: {
        class: 'back-to-top'
    }
})
export class BackToTopComponent implements AfterViewInit, OnDestroy {

    private scrollHandler = this.toggleShow.bind(this);

    constructor(
        private element: ElementRef,
        private UtilsService: UtilsService,
    ) {}

    public ngAfterViewInit() {
        window.addEventListener('scroll', this.scrollHandler);
    }

    public ngOnDestroy() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    private toggleShow() {
        this.element.nativeElement.classList.toggle('bring-to-screen', window.scrollY >= 50);
    }

    public scrollToTop() {
        this.UtilsService.scrollToTop();
    }
}
