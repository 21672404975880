import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { datepickerOptions } from '@/app.constant';
import { UtilsService } from '../../../core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { CourseManagementService } from '../course-management.service';


@Component({
    selector: 'edit-resource-modal',
    templateUrl: './edit-resource-modal.component.html'
})
export class EditResourceModalComponent implements OnInit {

    @Input() type: any;
    @Input() resource: any;
    @Input() schoolYear: any;
    public loading = false;
    public grades: any;
    public form: any;
    public datepickerOptions = Object.assign({}, datepickerOptions);

    constructor(
        public activeModal: NgbActiveModal,
        private UtilsService: UtilsService,
        private CourseManagementService: CourseManagementService,
    ) {
    }

    public ngOnInit() {
        this.datepickerOptions.minDate = new Date(this.schoolYear.start_date + 'T00:00');
        this.datepickerOptions.maxDate = new Date(this.schoolYear.end_date + 'T00:00');

        this.form = {
            id: this.resource.id,
            name: this.resource.name,
            code: this.resource.code,
            status: this.resource.status,
            grades: (this.resource.grades || []),
            start_date: new Date(this.resource.start_date + 'T00:00'),
            end_date: new Date(this.resource.end_date + 'T00:00'),
        };

        this.loading = true;
        this.CourseManagementService.getGrades()
            .then(grades => this.grades = grades)
            .catch(console.warn)
            .finally(() => this.loading = false);
    }

    public updateResource() {
        let data = Object.assign({}, this.form);
        data.start_date = dateToString(data.start_date);
        data.end_date = dateToString(data.end_date);
        data.grade = data.grades.map(g => g.id).join();
        delete data.grades;

        this.UtilsService.addLoadingOverlay(true);
        this.CourseManagementService.saveCourse(data, 'PATCH', this.type)
            .then(updatedResource => {
                if (updatedResource) {
                    // Fix naming inconsistency
                    updatedResource.grades = updatedResource.grade;
                    Object.assign(this.resource, updatedResource);
                    this.activeModal.close(this.resource);
                }
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
