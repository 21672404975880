import { TransitionService } from '@uirouter/core';

import { AnalyticsService } from '@/core/service/analytics.service';


/* tslint:disable */
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date().valueOf();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
window['ga']('create', 'UA-60887243-1', 'auto');
window['ga']('send', 'pageview');
/* tslint:enable */

export function pageTrackHook(transitionService: TransitionService) {
    // Track page changes in google analytics
    const recordPageAnalytics = (transition) => {
        const from = {
            name: transition.from().name,
            params: transition.params('from'),
            url: transition.$from().url.format(transition.params('from')),
        };
        const to = {
            name: transition.to().name,
            params: transition.params('to'),
            url: transition.$to().url.format(transition.params('to')),
        };

        if (document.body) {
            document.body.scrollTop = 0;
        }

        if (window['ga']) {
            window['ga']('set', 'page', to.url);
            window['ga']('send', 'pageview');
        }

        const analyticsService: AnalyticsService = transition.injector().get(AnalyticsService);
        analyticsService.pageTrack(from, to);
    };

    // Record error if page transition fails
    const recordStateChangeFail = (transition) => {
        const error = transition.error();
        const from = {
            name: transition.from().name,
            params: transition.params('from'),
            url: transition.$from().url.format(transition.params('from')),
        };
        const to = {
            name: transition.to().name,
            params: transition.params('to'),
            url: transition.$to().url.format(transition.params('to')),
        };

        console.warn('Error navigating from ' + transition.from().name + ' to ' + transition.to().name, error);
        // - [[RejectType.SUPERSEDED]] (`2`) (e.g. user accessed different page before page resolved, or was redirected)
        // - [[RejectType.ABORTED]] (`3`) (e.g. declined transition during onBefore/onStart hook)
        // - [[RejectType.INVALID]] (`4`) (e.g. user tried to access a page with wrong/invalid params)
        // - [[RejectType.IGNORED]] (`5`) (e.g. user tries to navigate to the same page they're already in)
        // - [[RejectType.ERROR]] (`6`) (e.g. state values could not resolve)
        if (error.type === 4 && error.type === 6) {
            const analyticsService: AnalyticsService = transition.injector().get(AnalyticsService);
            analyticsService.warning({
                action: 'state_change_fail',
                from,
                to,
                type: error.type,
                error: error.message,
                detail: error.detail
            });
        }
    };

    transitionService.onSuccess({}, (trans) => recordPageAnalytics(trans), { priority: -10 });
    transitionService.onError({}, (trans) => recordStateChangeFail(trans), { priority: -10 });
}
