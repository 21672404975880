<form [formGroup]="form" class="email-forgotten-form flex-column flex-auto space-children-column medium-space" [hidden]="emailSent">
    <div class="bold capitalize text-center">Reset Password</div>

    <div class="form-error-messages text-center" *ngIf="passwordResetFailMessage">
        <div class="error-message body-regular">{{ passwordResetFailMessage }}</div>
    </div>

    <p>Enter your email address and we'll send you instructions on how to reset your password. If you don't see an email from us soon, please check your spam folder.</p>
    <p>STUDENTS: If you forgot your password, please contact your teacher.</p>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>Email</mat-label>
        <input matInput type="email" id="password-reset-email" formControlName="email" name="passwordResetEmail" [email]="true" maxlength="150" required>
        <mat-error *ngIf="email.errors?.required">
            Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="email.errors?.email">
            Email is not <strong>valid</strong>
        </mat-error>
        <mat-error *ngIf="email.errors?.maxlength">
            Email cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <button type="submit" class="btn btn-block primary-button alt-theme balloon" (click)="submitPasswordReset($event)" [disabled]="form.invalid">Reset Password</button>
    <button class="btn btn-block primary-button alt-theme-red" (click)="showForm($event, 'login')">Cancel</button>
</form>

<!-- Forgotten Password Reset Email Successfully Sent -->
<div class="flex-column flex-auto space-children-column medium-space" [hidden]="!emailSent">
    <div class="bold capitalize text-center">Password Email Sent Successfully</div>

    <img src="/src/assets/image/icon_password_sent.svg">

    <p>We have sent an email to the address you have provided. If the email is valid you will receive instructions on how to reset your password. If you don't see an email from us soon, please check your spam folder.</p>

    <button class="btn btn-block primary-button alt-theme" (click)="showForm($event, 'login')">Got it!</button>
</div>
