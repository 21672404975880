import { Component, Input, ElementRef, OnInit, AfterViewInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../core/service/utils.service';
import { MapService } from '../../map/map.service';

import './map-tagging-modal.component.scss';


@Component({
    selector: 'map-tagging-modal',
    templateUrl: './map-tagging-modal.component.html',
    // styleUrls: ['./map-tagging-modal.component.scss'],
})
export class MapTaggingModalComponent implements OnInit, AfterViewInit {

    @Input() instructions: string;
    @Input() fields: any;
    @Input() filters: any;
    @Input() resource: any;

    public standardsList: any;
    public isStandardsDisabled = false;
    public isMapCheckboxDisabled = false;

    constructor(
        public activeModal: NgbActiveModal,
        private element: ElementRef,
        public UtilsService: UtilsService,
        private MapService: MapService,
    ) {}

    public ngOnInit() {
        // Get list of standards
        this.UtilsService.addLoadingOverlay();
        this.MapService.getStandardsData()
            .then(standards => this.standardsList = standards)
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());

        // Update checkbox indeterminate status
        this.fields.forEach(field => {
            field.regions.forEach(region => {
                if (this.filters && this.filters.region) {
                    region.isSelected = this.filters.region.includes(region.id);
                }
                region.clusters.forEach(cluster => {
                    if (this.filters && this.filters.cluster) {
                        cluster.isSelected = this.filters.cluster.includes(cluster.id);
                    }
                    cluster.constructs.forEach(construct => {
                        if (this.filters && this.filters.construct) {
                            construct.isSelected = this.filters.construct.includes(construct.id);
                        }
                    });
                    // Initiate indeterminate checkboxes if applicable
                    this.updateClusterIndeterminateStatus(cluster);
                });
            });
        });

        // Enable map checkboxes or resources
        if (this.resource && this.resource.standards.length > 0 && !this.isStandardsDisabled) {
            this.isMapCheckboxDisabled = true;
        }
    }

    public ngAfterViewInit() {
    }

    // When cluster is clicked on, update construct checkboxes
    public toggleConstructsSelection(cluster, clickEvent = false) {
        let isChecked = cluster.isSelected;

        // Check or un-check children constructs when checkbox changes
        cluster.constructs.forEach(construct => {
            construct.isSelected = isChecked;
        });

        if (this.resource && clickEvent) {
            this.updateDisabledStatus(true);
        }
    }

    // When construct is clicked on, update parent cluster checkbox
    public updateClusterIndeterminateStatus(cluster, clickEvent = false) {
        let anyChecked = false;
        let anyNotChecked = false;

        cluster.constructs.forEach(construct => {
            if (construct.isSelected) {
                anyChecked = true;
                this.isStandardsDisabled = true;
            } else {
                anyNotChecked = true;
            }
        });

        if (anyChecked && anyNotChecked) {
            // If one construct is checked, and another one is not checked it is indeterminate
            cluster.indeterminate = true;
            cluster.isSelected = false;
        } else {
            // They're all either checked or not checked
            cluster.indeterminate = false;
            cluster.isSelected = anyChecked;
        }

        if (this.resource && clickEvent) {
            this.updateDisabledStatus(true);
        }
    }

    public updateDisabledStatus(fromMapToggle = false) {
        let mapCheckboxSelected = false;

        this.fields.forEach(field => {
            field.regions.forEach(region => {
                region.clusters.forEach(cluster => {
                    cluster.constructs.forEach(construct => {
                        if (construct.isSelected) {
                            mapCheckboxSelected = true;
                        }
                    });
                });
            });
        });

        if (this.resource.standards.length === 0 && !mapCheckboxSelected) {
            // If all inputs are clear, enable both parts
            this.isStandardsDisabled = false;
            this.isMapCheckboxDisabled = false;
        } else if (!mapCheckboxSelected && fromMapToggle) {
            // If we unselect the last map checkbox clear all existing standards and enable the disabled standards
            this.resource.standards.splice(0, this.resource.standards.length);
            this.isStandardsDisabled = false;
        } else {
            this.isStandardsDisabled = mapCheckboxSelected;
            this.isMapCheckboxDisabled = !mapCheckboxSelected;
        }
    }
}
