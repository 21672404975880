import { Component, OnInit } from '@angular/core';

import { SchoolReportService } from '@/report/school/school-report.service';

import './school-report-graph-options.component.scss';


@Component({
    selector: 'school-report-graph-options',
    templateUrl: 'school-report-graph-options.component.html',
    // styleUrls: ['./school-report-graph-options.component.scss'],
})
export class SchoolReportGraphOptionsComponent implements OnInit {

    public navIsOpen: boolean = false;
    public graphOptions = [
        { id: 1, name: 'Show legend', selected: true },
        { id: 2, name: 'Show Y-axis', selected: true },
        { id: 3, name: 'Show X-axis', selected: true },
    ];
    private clickHandler = this.checkOutsideClick.bind(this);

    constructor(
        private SchoolReportService: SchoolReportService,
    ) {}

    public ngOnInit() {
        this.SchoolReportService.graphDataObs$.subscribe(({params, graphData}) => {
            this.graphOptions.forEach(opt => opt.selected = true);
        });
    }

    public toggleNav(shouldOpen) {
        const currentState = this.navIsOpen;
        this.navIsOpen = shouldOpen;

        if (shouldOpen) {
            // If we're not attempting to open when it's already open
            if (currentState !== shouldOpen) {
                document.addEventListener('click', this.clickHandler);
            }
        } else {
            document.removeEventListener('click', this.clickHandler);
        }
    }

    public toggleOption(option) {
        option.selected = !option.selected;

        if (option.id === 1) {
            this.SchoolReportService.graphLayout.showlegend = option.selected;
        } else if (option.id === 2) {
            this.SchoolReportService.graphLayout = Object.assign({}, this.SchoolReportService.graphLayout, { yaxis: { ...this.SchoolReportService.graphLayout.yaxis, visible: option.selected }});
        } else if (option.id === 3) {
            Object.keys(this.SchoolReportService.graphLayout).filter(key => key.includes('xaxis')).forEach(key => {
                this.SchoolReportService.graphLayout[key].visible = option.selected;
            });

            this.SchoolReportService.graphLayout = Object.assign({}, this.SchoolReportService.graphLayout, { xaxis: { ...this.SchoolReportService.graphLayout.xaxis }});
        }
    }

    private checkOutsideClick(event) {
        // If we clicked outside of this panel
        if (!event.target.closest('.school-report-graph-options')) {
            this.toggleNav(false);
        }
    }
}
