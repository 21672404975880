import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { MatFormModule } from '../_custom/mat-form.module';

import { SharedModule } from '../shared/shared.module';

import { ProfileService } from './profile.service';
import { ProfileComponent } from './profile.component';


@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        MatFormModule,
        SharedModule,
    ],
    providers: [
        ProfileService,
    ],
    declarations: [
        ProfileComponent,
    ],
})
export class ProfileModule { }
