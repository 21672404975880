import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

import { StateService } from '@uirouter/core';

import { UtilsService } from '@/core/service/utils.service';
import { SessionService } from '@/core/service/session.service';
import { AssessmentService } from '@/assessment/assessment.service';
import { ReportService } from './report.service';

import './report.component.scss';


@Component({
    selector: 'report-page',
    templateUrl: 'report.component.html',
    // styleUrls: ['./report.component.scss'],
})
export class ReportPageComponent implements OnInit, OnDestroy {

    public isTeacherViewing: boolean;
    public isGuest: boolean;

    constructor(
        private DatePipe: DatePipe,
        public $state: StateService,
        public UtilsService: UtilsService,
        private SessionService: SessionService,
        private AssessmentService: AssessmentService,
        public ReportService: ReportService,
    ) { }

    public ngOnInit() {
        this.isTeacherViewing = !!this.$state.params.u;
        this.isGuest = (this.SessionService.userAccess.permission & 1) === 1;
    }

    public ngOnDestroy() {
        this.ReportService.resetReportModels();
    }

    public clusterSelected(cluster) {
        if (this.$state.current.name !== 'report') {
            this.$state.go('report');
        }
        // De-select all other dropdowns
        this.ReportService.models.test = null;
    }

    public getTestReport(test) {
        this.$state.go('report.test', { trUid: test.uid });
    }

    public groupByTrStatus(tr) {
        if (tr.status === 3 || tr.status === 12) {
            return 'Tests';
        } else if (tr.status === 4 || tr.status === 5) {
            return 'Practice';
        }

        return undefined;
    }
}
