import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import './resource-details-modal.component.scss';


@Component({
    selector: 'resource-details-modal',
    templateUrl: './resource-details-modal.component.html',
    // styleUrls: ['./resource-details-modal.component.scss'],
})
export class ResourceDetailsModalComponent {
    @Input() resource: any;

    constructor(
        public activeModal: NgbActiveModal,
    ) {}
}
