<svg:g (click)="$event.stopPropagation(); MapService.zoomChange.next({ level: 1, x: field.attributes.cx, y: field.attributes.cy })">
    <svg:rect
        class="field-background"
        [attr.x]="field.attributes.background_x"
        [attr.y]="field.attributes.background_y"
        width="28800"
        height="28800">
    </svg:rect>
</svg:g>

<svg:g
    [attr.transform]="'translate(' + field.attributes.x + ', ' + field.attributes.y + ')'"
    (click)="$event.stopPropagation(); MapService.zoomChange.next({ level: 1, x: field.attributes.cx, y: field.attributes.cy })">
    <svg:text
        class="field-title"
        [attr.x]="field.attributes.title_x"
        [attr.y]="field.attributes.title_y">
        {{ field.name }}
    </svg:text>
</svg:g>

<svg:g class="region-group" *ngFor="let region of field.regions">
    <svg:g map-region [region]="region"></svg:g>
    <svg:g map-region-popover [region]="region"></svg:g>
</svg:g>
