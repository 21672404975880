<div class="flex-row justify-center align-items-center">
    <div class="icon-previous cursor-pointer" (click)="navigate.emit('prev')">
        <i class="fas fa-caret-left"></i>
    </div>
    <div class="current-calendar-title text-center">
        <span>{{ title }}</span>
    </div>
    <div class="icon-next cursor-pointer" (click)="navigate.emit('next')">
        <i class="fas fa-caret-right"></i>
    </div>
</div>
