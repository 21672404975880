import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../core/service/utils.service';
import { AnalyticsService } from '../../core/service/analytics.service';

import { ResourceDetailsModalComponent } from './resource-details-modal.component';

import './resource-card.component.scss';


@Component({
    selector: 'resource-card',
    templateUrl: './resource-card.component.html',
    // styleUrls: ['./resource-card.component.scss'],
    host: {
        'class': 'resource-card-container',
        '[class.multicard-container]': 'cardType === \'preview\'',
        '[class.selectable-container]': 'cardType === \'selectable\'',
    }
})
export class ResourceCardComponent implements OnInit {

    @Input() resource: any;
    @Input() resourceIdx: number;
    @Input() cardType: string;
    @Output() openResourceManagerModal = new EventEmitter();
    @Output() openResourceDeletionModal = new EventEmitter();
    @Output() deleteAssignedResource = new EventEmitter();

    constructor(
        private element: ElementRef,
        private ngbmodal: NgbModal,
        public UtilsService: UtilsService,
        private AnalyticsService: AnalyticsService,
    ) { }

    public ngOnInit() {
        this.resource.textDesc = this.UtilsService.stripHTMLTags(this.resource.description);
        this.resource.media = '';
        this.resource.popoverText = 'Added by ' + (this.resource.curated ? 'Math-Mapper team and visible to everyone.' : this.resource.owner_name + ' and visible ' + (this.resource.district ? 'to your district.' : 'only to you.'));

        if (this.resource.clusters) {
            this.resource.regions = this.resource.clusters.reduce((regions, cluster) => {
                return regions.indexOf(cluster.region) > -1 ? regions : regions.concat([cluster.region]);
            }, []);
        }

        if (this.resource.sources) {
            this.resource.authorNames = this.resource.sources.map(author => author.name).join(', ');
        }

        if (this.resource.tags) {
            let media = this.resource.tags.filter(tag => tag.category.id === 9).map(tag => tag.name);
            this.resource.media = media.length > 1 ? 'Assorted Media' : media[0];
        }
    }

    public imageNotFound(event) {
        // let imageErrors = JSON.parse(localStorage.getItem('resourcer_image_not_found')) || [];
        // if (!imageErrors.includes(this.resource.image)) {
        //     this.AnalyticsService.warning({
        //         'action': 'resourcer_image_not_found.',
        //         'url': this.resource.image,
        //         'resource_id': this.resource.id
        //     });
        //     // Do not report this image anymore for the rest of the session
        //     imageErrors.push(this.resource.image);
        //     localStorage.setItem('resourcer_image_not_found', JSON.stringify(imageErrors));
        // }
        // Set fallback image
        this.resource.image = 'src/assets/image/resourcer-thumbnail-generic.png';
    }

    public openResourceDetailsModal(resource) {
        this.AnalyticsService.action({
            action: 'resources_manager_modal_opened',
            id: resource.id
        });

        const modalRef = this.ngbmodal.open(ResourceDetailsModalComponent, {
            windowClass: 'resource-details-modal',
            size: 'sm',
        });
        modalRef.componentInstance.resource = resource;

        return modalRef.result;
    }

    public openResourceExternalLink(resource, id) {
        this.AnalyticsService.action({
            action: 'resources_manager_url_opened',
            resource,
            id,
        });
    }

    public isResourceDeletable(resource) {
        return resource.owner
            && resource.editable
            && !resource.curated;
    }
}
