import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SchoolReportService } from '@/report/school/school-report.service';

// import './performance-stacked-bar-plot.component.scss';


@Component({
    selector: 'performance-stacked-bar-plot',
    templateUrl: 'performance-stacked-bar-plot.component.svg.html',
    // styleUrls: ['./performance-stacked-bar-plot.component.scss'],
    host: {
        class: 'performance-stacked-bar-plot'
    }
})
export class PerformanceStackedBarPlotComponent implements OnChanges {

    @Input() public graphData: any;
    @Input() public graphParams: any;
    public stackedBarPlotData: any = [];
    public selectedIndex: number = 0;

    constructor(
        public SchoolReportService: SchoolReportService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.graphData && change.graphData.currentValue) {
            this.formatPlotlyGraphData();
        }
    }

    public formatPlotlyGraphData() {
        this.selectedIndex = 0;
        this.stackedBarPlotData = [];
        this.SchoolReportService.graphConfig['displayModeBar'] = false;
        this.SchoolReportService.graphLayout = {
            autosize: true,
            showlegend: true,
            height: 300,
            xaxis: {
                range: [0, 1]
            },
            yaxis: {
                ticklen: 5,
                tickcolor: 'rgba(0,0,0,0)'
            },
            margin: {
                t: 20,
                b: 20
            },
            // title: {
            //     text: this.SchoolReportService.getChartTitle(this.graphParams),
            //     font: { family: 'Lato, Arial', size: 16, color: 'rgba(0,0,0,0.65)' }
            // },
            annotations: [],
            barmode: 'stack',
            legend: {
                traceorder: 'normal'
            }
        };

        this.graphData.forEach((schoolYear, syIdx) => {
            const schools = Object.keys(schoolYear.schools);

            let timeAxisGroup = [];
            let timeClusterColors = [];

            schools.forEach((schoolName, snIdx) => {
                let isTabEmpty: boolean;
                const timePeriod = `${schoolYear.name} ${schoolName}`;
                this.SchoolReportService.graphLayout.xaxis = {
                    range: [0, schoolYear.schools.based_num || 1]
                };

                const stackedBarPlotData: any = [];

                if ('constructs' in schoolYear.schools[schoolName]) {
                    timeAxisGroup = [];
                    schoolYear.schools[schoolName].constructs.forEach((construct: any) => {
                        let correct = {
                            x: [],
                            y: [],
                            text: [],
                            name: 'Correct',
                            type: 'bar',
                            orientation: 'h'
                        }
                        let incorrect = {
                            x: [],
                            y: [],
                            text: [],
                            name: 'Incorrect',
                            type: 'bar',
                            orientation: 'h'
                        }

                        construct.stacks.forEach((stack: any) => {
                            let n: Number = stack.n ? Number(stack.n) : 0;
                            correct.y.push(`Level ${stack.lvl}<br>n=${n}`);
                            incorrect.y.push(`Level ${stack.lvl}<br>n=${n}`);
                            
                            if (stack.correct === null || stack.incorrect === null) {
                                correct.x.push(0);
                                incorrect.x.push(0);
                            } else {
                                let upper: number = Math.round(((stack.correct + stack.interval) + Number.EPSILON) * 100) / 100;
                                let lower: number = Math.round(((stack.correct - stack.interval) + Number.EPSILON) * 100) / 100;
                                
                                correct.x.push(stack.correct);
                                correct.text.push(`Upper: ${upper}<br>Lower: ${lower}`);
                                incorrect.x.push(stack.incorrect);
                            }
                        });
                        timeAxisGroup.push({
                            name: construct.name,
                            data: [correct, incorrect]
                        });
                    });
                    this.stackedBarPlotData.push({ timePeriod, isTabEmpty, chartData: timeAxisGroup });
                    isTabEmpty = false;
                } else {
                    isTabEmpty = true;
                }
            });
        });
        this.SchoolReportService.isGraphReset = false;

        this.SchoolReportService.getChartTitle(this.graphParams);
    }
}
