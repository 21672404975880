import { Component, Input, Output, EventEmitter } from '@angular/core';

import { mapSizesAndBounds } from '@/app.constant';
import { MapService } from '../map.service';

import './map-region.component.scss';


@Component({
    selector: '[map-region]',
    templateUrl: './map-region.component.svg.html',
    // styleUrls: ['./map-region.component.scss'],
    host: {
        class: 'map-region',
    },
})
export class MapRegionComponent {

    @Input() region: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;

    constructor(
        public MapService: MapService,
    ) {}

    public activateRegion(region, $event) {
        $event.stopPropagation();

        let targetLevel = this.MapService.learningMap.option.currentZoomLevel >= 3 ? 1 : 3;
        // Add a fixed x offset to make room for side nav bar
        this.MapService.zoomChange.next({ level: targetLevel, x: this.region.attributes.cx - 200, y: this.region.attributes.cy });
    }

    public togglePopover($event) {
        $event.stopPropagation();

        if (this.MapService.learningMap.option.showAllRegionLabels) {
            return false; // if all the popovers are already displayed, then do nothing
        }
        // if not currently enabled, then we need to update the scaling
        this.region.showPopover = !this.region.showPopover;
    }
}
