<div class="heatmap-construct unselectable">
    <div class="flex-row align-items-center heatmap-unit-header">
        <div class="stack-level-separator">
            <div class="btn ghost-button ladder-button" [class.active-construct]="HeatmapService.filters.activeConstructPanel && HeatmapService.filters.activeConstructPanel.id === construct.id" (click)="toggleStacksPanel()">
                <i class="icon icon-ladder"></i>
            </div>
        </div>

        <div class="construct-details flex-row align-items-center justify-space-between space-children-row">
            <div class="flex-column">
                <span class="bold">Construct {{ UtilsService.getLetterFromIndex(construct.order - 1) }}:&nbsp;</span>
                <span class="construct-name" [innerHTML]="construct.name"></span>
            </div>
        </div>
    </div>

    <div class="heatmap-unit" studentMultiselect (toggleActiveStudent)="toggleActiveStudent.emit($event)">
        <ng-container *ngFor="let stack of construct.stacks">
            <div class="heatmap-row flex-row" *ngIf="constructInfo.stack_question_ids[stack.order].length || HeatmapService.filters.untested">
                <heatmap-stack-question [stack]="stack" [stackQuestions]="constructInfo.stack_question_ids[stack.order]" [assp]="assp"></heatmap-stack-question>

                <div class="stack-questions flex">
                    <div
                        *ngFor="let score of constructInfo.scores"
                        class="heatmap-unit-block flex"
                        [ngClass]="{ 'active-student': HeatmapService.filters.highlightedStudents.indexOf(score.student_id) > -1, 'active-question': score.stack_level_scores[stack.order]?.questions.includes(HeatmapService.filters.highlightedQuestion), 'inactive disabled-background': !HeatmapService.filters.testforms[score.testform]?.selected, incorrect: score.stack_level_scores[stack.order]?.sc === 0, revised: HeatmapService.filters.revised && score.stack_level_scores[stack.order]?.rv_sc === 100, 'top-block': isTopBlock(score, stack) }"
                        [ngStyle]="getCorrectGradient(score, stack)"
                        [matTooltip]="score.stack_level_scores[stack.order] ? score.stack_level_scores[stack.order][HeatmapService.filters.revised && score.stack_level_scores[stack.order].rv_sc === 100 ? 'rv_sc' : 'sc'] + '%' : ''"
                        matTooltipClass="mat-tooltip-margin-sm"
                        matTooltipPosition="above"
                        [matTooltipDisabled]="!score.stack_level_scores[stack.order] || (!score.stack_level_scores[stack.order].sc && score.stack_level_scores[stack.order].sc !== 0)"
                        [attr.data-student-id]="score.student_id"
                        (click)="toggleActiveStudent.emit(score.student_id)">
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="heatmap-row flex-row" *ngIf="HeatmapService.filters.initials">
            <div class="stack-level-separator"></div>

            <div class="student-initials-row flex">
                <div
                    *ngFor="let score of constructInfo.scores"
                    class="student-initials-container flex align-items-center justify-center"
                    [class.active-student]="HeatmapService.filters.highlightedStudents.indexOf(score.student_id) > -1"
                    [attr.data-student-id]="score.student_id"
                    (click)="toggleActiveStudent.emit(score.student_id)">
                    <span class="student-initials" [style.font-size]="getInitialsFontSize(constructInfo.scores.length)">{{ students[score.student_id].initials }}</span>
                </div>
            </div>
        </div>

        <div class="heatmap-row flex-row">
            <div class="stack-level-separator"></div>

            <div class="flex">
                <div
                    *ngFor="let score of constructInfo.scores"
                    class="practice-icon-container flex align-items-center justify-center"
                    [class.active-student]="HeatmapService.filters.highlightedStudents.indexOf(score.student_id) > -1"
                    [class.active-initials]="HeatmapService.filters.initials"
                    (click)="toggleActiveStudent.emit(score.student_id)">
                    <span
                        class="practice-icon"
                        *ngIf="score.co_practice_tests"
                        matTooltip="This student practiced at least once in this construct this school year."
                        matTooltipPosition="below">
                        <i class="far fa-plus-square"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
