<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h3 class="modal-title text-center">Accessibility</h3>
</div>

<div class="modal-body">
    <div class="mb-2">Font Size</div>

    <div class="card">
        <div class="card-header flex-row align-items-center justify-space-between">
            <div class="bold">{{ fontSizes[fontSizeIdx] }}</div>

            <div class="flex-none align-items-center space-children-row">
                <button class="btn ghost-button" (click)="updateFontSize(-1)" [disabled]="fontSizeIdx <= 0">
                    <i class="icon icon-minus"></i>
                </button>
                <button class="btn ghost-button" (click)="updateFontSize(+1)" [disabled]="fontSizeIdx >= this.fontSizes.length - 1">
                    <i class="icon icon-plus"></i>
                </button>
                <button class="btn ghost-button" (click)="updateFontSize('100%')" [disabled]="fontSizes[fontSizeIdx] === '100%'">Reset</button>
            </div>
        </div>
    </div>
</div>
