import { Component, Input, ElementRef } from '@angular/core';


@Component({
    selector: 'point-scale',
    templateUrl: './point-scale.component.html'
})
export class PointScaleComponent {

    @Input() question: any;

    constructor(
        private element: ElementRef,
    ) {}

    public getIconClass(question) {
        let ngClass = {};

        if (question.percent_revised || question.percent_revised === 0) {
            ngClass['revised-full'] = question.percent_revised === 100;
            ngClass['revised-no-credit'] = question.percent_revised >= 0 && question.percent_revised < 100;
            let ic = this.getGradingIcon(question.percent_revised);
            ngClass[ic] = true;
        } else {
            ngClass['correct'] = question.percent_points > 0;
            ngClass['incorrect'] = question.percent_points <= 0;
            let ic = this.getGradingIcon(question.percent_points);
            ngClass[ic] = true;
        }

        return ngClass;
    }

    private getGradingIcon(percent) {
        if (percent === 100) {
            return 'icon-check';
        } else if (!percent) {
            return 'icon-times'; // percent is 0 or percent is undefined|null|NaN
        } else {
            return 'icon-test'; // Icon for partial
        }
    }
}
