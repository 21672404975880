<div class="flex-column space-children-column align-items-start margin-top-1em" *ngIf="practiceRecords?.length === 0">
    <h5>There is not practice activity by students of this section<span *ngIf="cluster">&nbsp;for the selected cluster</span>.</h5>
</div>

<ng-container *ngIf="practiceRecords?.length > 0">
    <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let practiceRec of practiceRecords" (opened)="getConstructPractice(practiceRec)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <span class="region-legend-circle" [ngClass]="['region-background-' + practiceRec.construct.parent.region]"></span>
                        <span>CON: </span>
                        <span [innerHTML]="practiceRec.construct.name"></span>
                        <i class="body-regular">&nbsp;(Number of Students who Practiced: {{ practiceRec.students_taken || 0 }}/{{ AssessmentManagementService.models.section.total_students || 0 }})</i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="table-component">
                <!-- Table Header -->
                <div class="flex-row justify-center">
                    <div class="table-header cursor-pointer flex-20 align-items-center justify-center space-children-row" (click)="sortBy(practiceRec.studentRoster, 'user_name')">
                        <span>Name</span>
                        <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': practiceRec.studentRoster?.sortExpression === 'user_name' && practiceRec.studentRoster?.sortReverse, 'opacity-25': practiceRec.studentRoster?.sortExpression !== 'user_name' }"></i>
                    </div>

                    <div class="table-header cursor-pointer flex-15 align-items-center justify-center space-children-row" (click)="sortBy(practiceRec.studentRoster, 'practice_taken_count')">
                        <span>Total Sessions</span>
                        <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': practiceRec.studentRoster?.sortExpression === 'practice_taken_count' && practiceRec.studentRoster?.sortReverse, 'opacity-25': practiceRec.studentRoster?.sortExpression !== 'practice_taken_count' }"></i>
                    </div>

                    <div class="table-header cursor-pointer flex-25 align-items-center justify-center space-children-row" (click)="sortBy(practiceRec.studentRoster, 'last_test_date')">
                        <span>Most Recent Activity Data</span>
                        <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': practiceRec.studentRoster?.sortExpression === 'last_test_date' && practiceRec.studentRoster?.sortReverse, 'opacity-25': practiceRec.studentRoster?.sortExpression !== 'last_test_date' }"></i>
                    </div>

                    <div class="table-header cursor-pointer flex-20 align-items-center justify-center space-children-row" matTooltip="Success Score - Sum of all the points divided by the total number of problems worked during practice." matTooltipPosition="above" (click)="sortBy(practiceRec.studentRoster, 'ss')">
                        <span>SS <i class="icon icon-info text-info"></i></span>
                        <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': practiceRec.studentRoster?.sortExpression === 'ss' && practiceRec.studentRoster?.sortReverse, 'opacity-25': practiceRec.studentRoster?.sortExpression !== 'ss' }"></i>
                    </div>

                    <div class="table-header cursor-pointer flex-20 align-items-center justify-center space-children-row" matTooltip="Worked Problems Index - Sum of all practice problems this student has worked during the school year with additional credit added for working harder levels." matTooltipPosition="above" (click)="sortBy(practiceRec.studentRoster, 'wpi')">
                        <span>WPI <i class="icon icon-info text-info"></i></span>
                        <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': practiceRec.studentRoster?.sortExpression === 'wpi' && practiceRec.studentRoster?.sortReverse, 'opacity-25': practiceRec.studentRoster?.sortExpression !== 'wpi' }"></i>
                    </div>
                </div>
                <!-- Table Assessment Rows -->
                <ng-container *ngFor="let student of practiceRec.studentRoster?.tests">
                    <div class="flex-row justify-center">
                        <div class="table-data flex-column flex-20 justify-center bold text-center">{{ student.user_name }}</div>

                        <div class="table-data flex-column flex-15 justify-center text-center">{{ student.practice_taken_count }}</div>

                        <div class="table-data flex-column flex-25 justify-center text-center">{{ (student.last_test_date | date: dateFormat.shortDate : dateFormat.timezone) || '&mdash;' }}</div>

                        <div class="table-data flex-column flex-20 justify-center text-center">{{ (student.ss || student.ss === 0) ? student.ss.toFixed() + '%' : '&mdash;' }}</div>

                        <div class="table-data flex-column flex-20 justify-center text-center">{{ student.wpi || '&mdash;' }}</div>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
