import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { AssessmentStateService } from '../../assessment-state.service';

import './misconception-trigger-panel.component.scss';


@Component({
    selector: 'misconception-trigger-panel',
    templateUrl: './misconception-trigger-panel.component.html',
    // styleUrls: ['./misconception-trigger-panel.component.scss'],
})
export class MisconceptionTriggerPanelComponent implements OnChanges {

    @Input() option: any;
    @Input() questionMisconceptions: any;
    @Input() foilIdToRomanMap: any;

    constructor(public AssessmentStateService: AssessmentStateService) {}

    ngOnChanges(change: SimpleChanges) {
    }

    public foilToRoman(foilIds) {
        return String(foilIds)
            .split('|')
            .map(foilId => this.foilIdToRomanMap[Number(foilId)])
            .join(', ');
    }

    public comparisonSymbol(comparison) {
        // The backend displays less sign regardless of whether it is a greater or less than comparison
        // So we are replicating that functionality in this method to keep it consistent.
        return ['', '&#61;', '&#60;', '&#8804;', '&#60;', '&#8804;'][comparison] || '';
        // return ['', '&#61;', '&#60;', '&#8804;', '&#62;', '&#8805;'][comparison] || '';
    }
}
