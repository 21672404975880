import { Component, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../core/service/utils.service';
import { ModalService } from '../../shared/service/modal.service';
import { AssessmentStateService } from '../common/assessment-state.service';
import { AssessmentToastService } from '../common/assessment-toast/assessment-toast.service';
import { CalculatorService } from '../common/calculator/calculator.service';

import { ordinal } from '@/_helpers/transforms';

import './practice-test-modal.component.scss';


@Component({
    selector: 'practice-test-modal',
    templateUrl: './practice-test-modal.component.html',
    // styleUrls: ['./practice-test-modal.component.scss'],
    animations: [
        trigger('tryNumSlideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(0rem)' }),
                animate(
                    '400ms cubic-bezier(0.65, 0.05, 0.36, 1)',
                    style({ transform: 'translateX(-3rem)' })
                )
            ]),
            // transition(':leave', [
            //     animate(
            //         '400ms cubic-bezier(0.65, 0.05, 0.36, 1)',
            //         style({ transform: 'translateY(0rem)' })
            //     )
            // ]),
        ]),
    ],
})
export class PracticeTestModalComponent implements AfterViewInit, OnDestroy {

    private MAX_TRIES = 3;

    public stage = 'info';
    public triesRange = [...Array(this.MAX_TRIES).keys()].map(i => i + 1);
    public totalQuestionsTaken = 0;
    public shouldRefreshLevel = false;
    private modalDialog: HTMLElement;

    constructor(
        public activeModal: NgbActiveModal,
        public element: ElementRef,
        private UtilsService: UtilsService,
        private modalService: ModalService,
        public AssessmentStateService: AssessmentStateService,
        private CalculatorService: CalculatorService,
        public AssessmentToastService: AssessmentToastService,
    ) {}

    public ngAfterViewInit() {
        this.modalDialog = this.element.nativeElement.closest('[role="document"]');
    }

    // Clean up assessment service after scope is destroyed
    public ngOnDestroy() {
        this.AssessmentStateService.resetAssessment();
    }

    // "Start Practice" button on info modal
    public startPractice() {
        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.startPracticeTest(this.AssessmentStateService.data.construct.id)
            .then(() => {
                this.UtilsService.blockModalClose('practiceTest');
                this.stage = 'assessment';
                this.getNewQuestion();
                this.modalDialog.classList.remove('modal-lg');
                this.modalDialog.classList.add('modal-assessment');
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public getNewQuestion() {
        // Close calculator and question feedback toast if open
        this.CalculatorService.operate('close', true);
        this.AssessmentToastService.closeToast();

        // Hide answer if revealed from last question
        this.AssessmentStateService.data.answerKey = false;

        // Seq 0 gets a question for the first time (greater than 0 is for reviewing)
        let seq = 0;
        this.totalQuestionsTaken += 1;
        this.AssessmentStateService.newQuestionChange.next(true);

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.getPracticeQuestion(this.AssessmentStateService.stackLadder.stack.id, this.AssessmentStateService.data.tr, seq)
            .then(() => {
                this.AssessmentStateService.stackLadder.stack.available_items--;
                this.AssessmentStateService.data.question.itemNumberInStack = this.AssessmentStateService.stackLadder.stack.totalItems - this.AssessmentStateService.stackLadder.stack.available_items;
                this.AssessmentStateService.data.question.itemNumberInSequence = this.totalQuestionsTaken;
                this.AssessmentStateService.stackLadder.stack.itemsTaken.push(this.AssessmentStateService.data.question);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public submitQuestion() {
        // Get foil ids and values and ignore disabled fields
        let data = this.AssessmentStateService.collectStudentResponse();

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.submitTestQuestion(data, 'submit_practice_question')
            .then(response => {
                // Disable the submit button until they change something about their answer (if trying again)
                this.AssessmentStateService.data.multi.isPartAnswered = false;
                // Add to number of tries or default to 1 if undefined
                this.AssessmentStateService.data.question.tries = (this.AssessmentStateService.data.question.tries + 1 || 1);
                this.AssessmentStateService.data.question.isSubmitted = true;
                // Display the results of the submitted question
                this.handleScoreResult(response.result.score);
                // Delete the score reference so we can iterate over the individual foils
                delete response.result.score;
                // Handle each foil depending on its input type
                this.handleFoilResult(response.result);
                // Reveal answer if we still haven't got the correct answer after the allowed number of tries
                if (this.AssessmentStateService.data.question.score !== 'correct' && this.AssessmentStateService.data.question.tries >= this.MAX_TRIES) {
                    this.revealAnswer();
                    this.openQuestionFeedbackToast('force revealed');
                } else {
                    this.openQuestionFeedbackToast('score');
                }
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private handleScoreResult(score) {
        let triesOrdinal = ordinal(this.AssessmentStateService.data.question.tries);
        this.AssessmentStateService.data.question.triesOrdinal = triesOrdinal;

        if (score === 'f') {
            this.AssessmentStateService.data.question.feedbackPrimary = 'Correct';
            this.AssessmentStateService.data.question.feedbackSecondary = 'You got this item right on the ' + triesOrdinal + ' attempt.';
            this.AssessmentStateService.data.question.score = 'correct';
            this.calculateStreakNumber();
            this.finishCurrentQuestion();
            this.AssessmentStateService.correctQuestionChange.next(true);
        } else if (score === 'p') {
            this.AssessmentStateService.data.question.feedbackPrimary = 'Partially Correct';
            this.AssessmentStateService.data.question.feedbackSecondary = 'Your answer is partially correct. Try again to get full credit!';
            this.AssessmentStateService.data.question.score = 'partial';
        } else {
            this.AssessmentStateService.data.question.feedbackPrimary = 'Incorrect';
            this.AssessmentStateService.data.question.feedbackSecondary = 'Try again or use the REVEAL button to reveal the answer.';
            this.AssessmentStateService.data.question.score = 'incorrect';
        }
    }

    private openQuestionFeedbackToast(type) {
        let toastOptions = {
            show: true
        };

        if (type === 'score') {
            Object.assign(toastOptions, {
                status: this.AssessmentStateService.data.question.score,
                title: this.AssessmentStateService.data.question.feedbackPrimary,
                message: this.AssessmentStateService.data.question.feedbackSecondary
            });
        } else if (type === 'force revealed') {
            Object.assign(toastOptions, {
                status: 'reveal',
                title: 'The answer has been revealed',
                message: 'You have reached your number of attempts allowed.'
            });
        }

        return this.AssessmentToastService.openToast(toastOptions, 0);
    }

    private handleFoilResult(result) {
        let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];

        // Do not do anything for select multiple as displaying x or check will give away the answer
        if (stem.type === 2) { return; }

        stem.foils.forEach(foil => {
            foil.isCorrect = result[foil.id];
        });
    }

    private refreshLevelOnStackLadder() {
        this.shouldRefreshLevel = true;
        setTimeout(() => {
            this.shouldRefreshLevel = false;
        }, 0);
    }

    public tryAgain() {
        let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];
        // Remove only the incorrect feedback so they can try again
        stem.foils.forEach(foil => {
            if (foil.isCorrect === false) {
                foil.isCorrect = undefined;
            }
        });
        this.AssessmentStateService.data.question.isSubmitted = false;
        this.AssessmentToastService.closeToast();
    }

    public requestAnswerReveal() {
        this.AssessmentToastService.openRevealAnswerToast();
    }

    public onToastClosed(reason) {
        if (reason === 'reveal') {
            this.revealAnswer();
        }
    }

    private revealAnswer() {
        let stemIds = this.AssessmentStateService.data.question.stems.map(stem => stem.id);

        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.revealAnswer(this.AssessmentStateService.data.tr, stemIds[0])
            .then((stemAnswers) => {
                let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];
                // Disable all input fields since we've revealed the answer and won't allow any further action
                this.AssessmentStateService.data.question.isSubmitted = true;
                this.handleScoringMessage();
                // Mark the correct answers
                this.AssessmentStateService.data.answerKey = true;
                // Iterate over question foils and update the grading
                stem.foils.forEach(foil => {
                    // Find matching foil in the response
                    let responseFoil = stemAnswers.find(resFoil => resFoil.id === foil.id);
                    foil.key = responseFoil || {};
                });

                if (!this.AssessmentStateService.data.question.isFinishedOrRevealed) {
                    this.finishCurrentQuestion();
                }
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private handleScoringMessage() {
        // Score can't be full because they would not be able to reveal the answer if they got it correct
        this.AssessmentStateService.data.question.feedbackSecondary = 'You will ' + (this.AssessmentStateService.data.question.score === 'partial' ? 'earn partial credit' : 'not earn credit') + ' for this item.';
    }

    private finishCurrentQuestion() {
        this.AssessmentStateService.data.question.isFinishedOrRevealed = true;
        this.AssessmentStateService.storeQuestion();
        // Automatically move user to the next level if we ran out of questions for this level
        if (this.totalQuestionsTaken >= this.AssessmentStateService.data.construct.totalItems) {
            this.refreshLevelOnStackLadder(); // Disabled in favor of allowing the student choose the level manually after a question
        }
    }

    // This function is executed before we store the question in history
    // So at this point the previous question is the last item in the progress array
    private calculateStreakNumber() {
        // Don't award streak if answered correctly after more than 1 try
        if (this.AssessmentStateService.data.question.tries > 1) { return false; }

        // Initialize streak at 1
        this.AssessmentStateService.data.question.streak = 1;

        // Check if there's a previous question
        let prevq = this.AssessmentStateService.data.progress[this.AssessmentStateService.data.progress.length - 1];
        if (prevq) {
            // If we have a streak going add the streak from last question, otherwise leave at 1
            this.AssessmentStateService.data.question.streak += (prevq.streak || 0);
        }
    }

    public loadScorecard() {
        this.UtilsService.unblockModalClose('practiceTest');
        this.stage = 'scorecard';
        this.modalDialog.classList.remove('modal-assessment');
        this.modalDialog.classList.add('modal-lg');
    }

    public endPracticeTest() {
        if (this.AssessmentStateService.data.question && !this.AssessmentStateService.data.question.isFinishedOrRevealed) {
            let config = {
                bodyText: 'If you end the test now, you will get an incorrect grading for this question that has been started. Are you sure you want to end your practice session?',
                actionButtonText: 'End Practice',
                closeButtonText: 'Keep Practicing',
            };
            this.modalService.openModal({}, config)
                .then(this.finishTest.bind(this))
                .catch(() => {});
        } else {
            this.finishTest();
        }
    }

    private finishTest() {
        this.UtilsService.addLoadingOverlay(true);
        this.AssessmentStateService.finalizeAssessment('end_practice_test', this.AssessmentStateService.data.assessment, this.AssessmentStateService.data.testlet, this.AssessmentStateService.data.tr, 1)
            .then(() => {
                this.AssessmentStateService.data.isFinished = true;
                this.loadScorecard();
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
