import './styles.scss';

import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { UIRouter } from '@uirouter/core';

import { AppModuleNgFactory } from './app/app.module.ngfactory';
// import { environment } from './environments/environment';
import { initializeFontAwesome, initializeLightboxConfig, initializeFontSize } from '@/_helpers/initializers';


// Increase the default number of frames/lines shown when an error is triggered
// from 10 lines to 100 lines. This will allow better errors and debugging in prod mode
// It is a non-standard feature, and not supported across all browsers but works on Chrome
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Microsoft_Extensions/Error.stackTraceLimit
Error.stackTraceLimit = 100;

// Initialize 3rd party libraries
initializeFontAwesome();
initializeLightboxConfig();
initializeFontSize();

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

platformBrowser().bootstrapModuleFactory(AppModuleNgFactory)
    .then(moduleRef => {})
    .catch(err => console.error(err));
