<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" preserveAspectRatio="xMidYMid meet" class="unselectable" [attr.viewBox]="getViewBox(cluster.pattern.length)" height="100%" width="100%" *ngIf="cluster.display">
    <defs>
        <filter id="stroke-shadow">
            <feFlood flood-color="black"/>
            <feComponentTransfer>
                <feFuncA type="linear" slope="0.25"/>
            </feComponentTransfer>
            <feComposite operator="out" in2="SourceGraphic"/>
            <feGaussianBlur stdDeviation="5"/>
            <feComposite operator="atop" in2="SourceGraphic"/>
        </filter>

        <filter id="drop-shadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="4"/> <!-- stdDeviation is how much to blur -->
            <feOffset dx="0" dy="0" result="offsetblur"/> <!-- how much to offset -->
            <feComponentTransfer> <!-- controls the opacity -->
                <feFuncA type="linear" slope="0.4"/>
            </feComponentTransfer>
            <feMerge>
                <feMergeNode/> <!-- this contains the offset blurred image -->
                <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
            </feMerge>
        </filter>
    </defs>


    <g class="connectors" *ngIf="connectors">
        <line *ngFor="let connector of mapSizesAndBounds.cluster.patternConnectors[cluster.pattern]" class="construct-line" [attr.x1]="connector.x1 * mapSizesAndBounds.cluster.width" [attr.y1]="connector.y1 * mapSizesAndBounds.cluster.width" [attr.x2]="connector.x2 * mapSizesAndBounds.cluster.width" [attr.y2]="connector.y2 * mapSizesAndBounds.cluster.width"></line>
    </g>

    <g *ngFor="let construct of cluster.constructs" [attr.transform]="'translate(' + (construct.cxRelative || 0) + ',' + (construct.cyRelative || 0) + ')'">
        <circle
            [attr.r]="construct.radius || 0"
            cx="0"
            cy="0"
            [class.bordered]="connectors"
            [ngClass]="[highlightedConstruct ? (highlightedConstruct.id === construct.id ? constructBgColor : 'disabled') : '']"
            class="cluster-graph-construct-circle"></circle>

        <text *ngIf="singleLetter" class="construct-single-letter" x="0" y="80">{{ UtilsService.getLetterFromIndex(construct.order - 1) }}</text>

        <svg:g *ngIf="cumulativeScore" construct-cumulative-score [construct]="construct" [score]="cumulativeScore[construct.id]" />

        <svg:g *ngIf="testScores" construct-test-score [construct]="construct" [score]="testScores[construct.id]" />

        <g class="graph-direction-pointer" *ngIf="cumulativeScore || testScores">
            <line class="starting-line" x1="0" [attr.y1]="(-construct.radius || -45) + 45" x2="0" [attr.y2]="-construct.radius || 0"></line>
            <path class="starting-line" [attr.d]="'M -5 ' + ((-construct.radius || 15) - 15) + ' l 10 -10 l -10 -10'"></path>
            <g class="needle-group"></g>
        </g>

        <foreignObject *ngIf="fullName" [attr.x]="construct.textPosition === 'right' ? (construct.radius || 0) * 1.6 : (-construct.radius || 0) * 3.5" [attr.y]="-construct.radius || 0" [attr.width]="(construct.radius || 0) * 2" [attr.height]="(construct.radius || 0) * 2.5" class="construct-full-name" [class.panel-open]="cluster.selectedConstruct === construct" [ngClass]="[construct.textPosition]">
            <xhtml:div class="construct-order bold">Construct {{ UtilsService.getLetterFromIndex(construct.order - 1) }}:</xhtml:div>
            <xhtml:div class="construct-name" [innerHTML]="construct.name"></xhtml:div>

            <xhtml:div class="flex-row space-children-row construct-details">
                <xhtml:button class="btn ghost-button icon-button" (click)="openStacksPanel(construct)">
                    <i class="icon icon-ladder"></i>
                </xhtml:button>
            </xhtml:div>
        </foreignObject>
    </g>
</svg>
