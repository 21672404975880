<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h4 class="modal-title text-center">Terms of Use</h4>
</div>

<div class="modal-body">
    <p>This website ("site") is a non-commercial resource developed for the professional development use of educators and education researchers. Your access and use of this site constitute your consent to the terms and conditions below, and your agreement to be legally bound by them. Absent the express written permission from North Carolina State University as to particular Content on the site, you may use the Content only for limited personal, noncommercial, informational purposes</p>
    <p>You may not distribute, publish, transmit, modify, create derivative works from, or in any way exploit, any of the Content, in whole or in part, for any purpose without North Carolina State University's prior written consent. Framing pages or parts of pages on the site and deep linking to pages in the site are prohibited. Institutional use of any form is strictly prohibited; institutional use is defined as incorporation of links or content into any product commercially distributed or into shared curriculum or software applications serving multiple sections/teachers. Any other use of this work--including reproduction for purposes other than those noted above, modification, distribution, or re-publication--without the prior written permission of North Carolina State University, is strictly prohibited. All use by commercial vendors without permission is prohibited.</p>
    <p>North Carolina State University, in its sole discretion, for any or no reason, and without penalty, may suspend or terminate any account (or any part thereof) you may have with North Carolina State University or your use of the Services and remove and discard all or any part of your account, User profile, and User Content, at any time. North Carolina State University may also in its sole discretion and at any time discontinue providing access to the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services or any account you may have, or portion thereof, may be affected without prior notice, and you agree that North Carolina State University will not be liable to you or any third party for any such termination. Any suspected fraudulent, abusive or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies North Carolina State University may have at law or in equity.</p>
    <p>The learning map undergoes ongoing revision. Access to the site is dependent on continued research support.  Emails provided for any form of registration will not be shared with any third parties. </p>
    <p>We can be contacted at sudds_outreach@ncsu.edu.</p>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
    <copyright></copyright>
</div>
