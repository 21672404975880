import { Component, OnInit } from '@angular/core';

import { UtilsService } from '@/core/service/utils.service';

import './school-report.component.scss';


@Component({
    selector: 'school-report',
    templateUrl: 'school-report.component.html',
    // styleUrls: ['./school-report.component.scss'],
})
export class SchoolReportComponent implements OnInit {

    constructor(
        public UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
    }
}
