import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import './student-feedback-modal.component.scss';


@Component({
    selector: 'student-feedback-modal',
    templateUrl: './student-feedback-modal.component.html',
    // styleUrls: ['./student-feedback-modal.component.scss'],
})
export class StudentFeedbackModalComponent {
    @Input() feedback: any;

    constructor(
        public activeModal: NgbActiveModal,
    ) {}
}
