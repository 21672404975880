import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { dateFormat } from '@/app.constant';
import { AnalyticsService } from '@/core/service/analytics.service';
import { UtilsService } from '@/core/service/utils.service';
import { AssessmentService } from '@/assessment/assessment.service';
import { AssessmentStateService } from '@/assessment/common/assessment-state.service';
import { ReportService } from '@/report/report.service';
import { HeatmapService } from '../heatmap.service';

import './heatmap-roster.component.scss';


@Component({
    selector: 'heatmap-roster',
    templateUrl: './heatmap-roster.component.html',
    // styleUrls: ['./heatmap-roster.component.scss'],
})
export class HeatmapRosterComponent implements OnInit, OnDestroy {

    @Input() roster: any;
    @Input() cluster: any;
    @Input() constructInfo: any;
    @Output() toggleActiveStudent = new EventEmitter();
    public readonly dateFormat = dateFormat;
    public questionBreakdown: any;
    public constScore: any;
    public heatmapSortExpression: string = 'co_weight_initial';
    public readonly sortOriginal = this.heatmapSortExpression;
    public sortReverse: boolean = false;
    private revisedSub: Subscription;

    constructor(
        private AnalyticsService: AnalyticsService,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
        private ReportService: ReportService,
        public HeatmapService: HeatmapService,
    ) {}

    public ngOnInit() {
        this.revisedSub = this.HeatmapService.revisedFilter$.subscribe(isRevisedOn => {
            this.updateInitialOrRevisedSort(isRevisedOn);
        });
        this.applySorting();
        // Check if there's practice data to be shown
        this.roster.forEach(student => {
            const practiceProps = Object.keys(student).filter(key => key.includes('_tests'));
            student.hasPracticed = practiceProps.some(prop => student[prop] && student[prop] > 0);
        });
    }

    public ngOnDestroy() {
        this.revisedSub.unsubscribe();
    }

    public formatWeight(studentScore, propPrefix) {
        let initial = studentScore[propPrefix + 'initial'];
        let revised = studentScore[propPrefix + 'revised'];
        if (initial || initial === 0) {
            return this.HeatmapService.filters.revised ? ((revised || initial) / 100).toFixed(1) + '%' : (initial / 100).toFixed(1) + '%';
        }
        return '&mdash;';
    }

    public sortBy(expression, appendScoreType = false) {
        if (appendScoreType) {
            expression = expression + (this.HeatmapService.filters.revised ? '_revised' : '_initial');
        }
        this.sortReverse = this.heatmapSortExpression === expression ? !this.sortReverse : false;
        this.heatmapSortExpression = expression;
        this.applySorting();
    }

    public updateInitialOrRevisedSort(isRevisedOn) {
        let reg = /initial|revised/gi;
        if (reg.test(this.heatmapSortExpression)) {
            this.heatmapSortExpression = this.heatmapSortExpression.replace(reg, isRevisedOn ? 'revised' : 'initial');
            this.applySorting();
        }
    }

    private applySorting() {
        // Sort students in roster
        this.roster = this.UtilsService.sortByProp(this.roster, this.heatmapSortExpression, this.sortReverse, true);
        // Sort students in heatmap construct view
        for (const cId in this.constructInfo) {
            let sc = this.constructInfo[cId].scores;
            sc = this.UtilsService.sortByProp(sc, this.heatmapSortExpression, this.sortReverse, true);
        }
    }

    public resetSorting() {
        this.heatmapSortExpression = this.sortOriginal;
        this.sortReverse = false;
        this.HeatmapService.filters.revised = false;
        this.applySorting();
        this.AnalyticsService.action({ action: 'heatmap_roster_sorting_reset' });
    }

    public toggleStudentReport(studentScore, $event) {
        if (!studentScore.tr_uid) { return false; }

        let isCurrentlyOpen = studentScore.isStudentReportOpen;
        // Avoid triggering student selection in heatmap
        $event.stopPropagation();
        // Hide previous results if opening a different student report
        this.questionBreakdown = null;
        // Close any student reports that may be open
        this.roster.forEach(sc => sc.isStudentReportOpen = false);

        if (!isCurrentlyOpen) {
            // Open the collapsed area
            studentScore.isStudentReportOpen = !isCurrentlyOpen;

            // Load question breakdown
            this.UtilsService.addLoadingOverlay();
            this.ReportService.getQuestionBreakdown(studentScore.tr_uid)
                .then(result => {
                    this.questionBreakdown = result;
                    this.computeConstructScore();
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    private computeConstructScore() {
        this.constScore = this.ReportService.computeConstructScores(this.questionBreakdown.question_scores);
    }

    public viewQuestion(qIndex) {
        this.AssessmentService.openReviseRevealModal(qIndex, this.questionBreakdown)
            .finally(() => this.AssessmentStateService.resetAssessment() );
    }
}
