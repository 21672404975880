<div class="heatmap-page-content page-content top">
    <div class="flex-row justify-start align-items-end space-children-row medium-space">
        <h2 class="page-title flex-none">Class Report: {{ testformType }} Gr. {{ gradeLevel }}</h2>

        <div class="body-small flex space-children-row">
            <div>
                <span class="bold">Course:</span>
                <span>{{ reportData?.assp.course.name }}</span>
            </div>

            <div *ngIf="reportData?.assp.section">
                <span class="bold">Section:</span>
                <span>{{ reportData?.assp.section.name }}</span>
            </div>

            <div>
                <span class="bold">Cluster:</span>
                <span [innerHTML]="reportData?.assp.cluster.name"></span>
            </div>
        </div>
    </div>

    <div class="hr medium-size"></div>

    <div class="heatmap-filter flex-row align-items-center justify-end space-children-row">
        <div class="heatmap-filter-button text-info flex-none align-items-center space-children-row small-space" #heatmapFiltersTrigger="ngbPopover" [ngbPopover]="heatmapFilters" placement="bottom-right" autoClose="outside" popoverClass="popover-dropdown heatmap-filter-popover">
            <span>Options</span>
            <i class="icon icon-caret-down" [class.rotate]="heatmapFiltersTrigger.isOpen()"></i>
        </div>

        <ng-template #heatmapFilters>
            <div class="flex-column justify-start space-children-column small-space">
                <span class="body-small bold">Test Forms:</span>

                <mat-checkbox *ngFor="let testform of HeatmapService.filters.testforms | keyvalue" id="testform-{{ testform.key }}" [(ngModel)]="testform.value.selected" (change)="recordFilterAnalytics('testform')">{{ testform.value.name }}</mat-checkbox>
            </div>

            <div class="hr"></div>

            <div class="flex-column space-children-column small-space">
                <span class="body-small bold">Show:</span>

                <mat-checkbox id="toggle-revised" [(ngModel)]="HeatmapService.filters.revised" (change)="emitRevisedValue()">Revised Questions</mat-checkbox>

                <mat-checkbox id="toggle-untested" [(ngModel)]="HeatmapService.filters.untested" (change)="recordFilterAnalytics('untested')">Untested Levels</mat-checkbox>

                <mat-checkbox id="toggle-initials" [(ngModel)]="HeatmapService.filters.initials" (change)="recordFilterAnalytics('initials')">Student Initials</mat-checkbox>
            </div>

            <div *ngIf="reportData && reportData.feedback && reportData.feedback[0]?.length > 0">
                <div class="hr"></div>

                <button class="btn btn-link body-small text-info" (click)="viewStudentFeedback()">View Student Feedback</button>
            </div>
        </ng-template>
    </div>
</div>


<div class="heatmap-page-content page-content bottom container-fluid">
    <div *ngIf="reportData?.heatmap.valid">
        <stack-panel    [construct]="HeatmapService.filters.activeConstructPanel"
                        [constructsList]="HeatmapService.testedConstructs"
                        [enableDragging]="true"
                        [enableLadder]="true"
                        (closed)="HeatmapService.filters.activeConstructPanel = null"
                        (change)="HeatmapService.filters.activeConstructPanel = $event">
        </stack-panel>

        <cluster-graph
            *ngIf="reportData.assp.construct"
            class="construct-check-cluster"
            [cluster]="reportData.assp.cluster"
            [connectors]="true"
            [singleLetter]="true"
            [highlightedConstruct]="reportData.assp.construct">
        </cluster-graph>

        <div *ngFor="let patternRow of patternedConstructs" class="construct-pattern-row flex-row justify-center">
            <heatmap-construct  *ngFor="let construct of patternRow"
                                class="heatmap-construct-container flex-50 justify-center"
                                [construct]="construct"
                                [constructInfo]="reportData?.heatmap.construct_info[construct.id]"
                                [students]="reportData?.heatmap.students"
                                [assp]="reportData?.assp"
                                (toggleActiveStudent)="toggleActiveStudent($event)">
            </heatmap-construct>
        </div>

        <div class="flex-row align-items-center justify-end">
            <div class="text-info body-small" matTooltip="Hold down the Shift key, and click and drag across the heatmap" matTooltipPosition="above">Selecting multiple students at once?</div>
        </div>

        <heatmap-legend></heatmap-legend>

        <heatmap-roster [roster]="reportData?.heatmap.roster"
                        [cluster]="reportData?.heatmap.cluster"
                        [constructInfo]="reportData?.heatmap.construct_info"
                        (toggleActiveStudent)="toggleActiveStudent($event)"
                        [hidden]="!HeatmapService.filters.initials">
        </heatmap-roster>
    </div>

    <div class="flex-row align-items-center justify-space-between" *ngIf="!reportData?.heatmap.valid">
        <div class="bold">No students in this section have taken this test.</div>
    </div>
</div>

<back-to-top></back-to-top>

<div class="deselect-container" [class.bring-to-screen]="HeatmapService.filters.highlightedStudents.length > 0 || HeatmapService.filters.highlightedQuestion">
    <button class="btn ghost-button upcase shadow-3dp" (click)="resetFilters()">Deselect All</button>
</div>
