import { Injectable } from '@angular/core';


@Injectable()
export class AssessmentToastService {

    public toast = {
        show: false,
        status: null,
        title: '',
        message: '',
        action: '',
    };
    private masterToast = JSON.parse(JSON.stringify(this.toast));

    constructor() {
    }

    public openToast(options = {}, delay = 0) {
        if (this.toast.show) {
            this.closeToast('Dismissing to show new toast');
            delay += 500;
        }

        setTimeout(() => {
            // Reset toast to default, then open with options passed in
            Object.assign(this.toast, this.masterToast, options);
        }, delay);
    }

    public closeToast(reason = '') {
        this.toast.show = false;
    }

    // Pre-set toasts (existing styles for toast types)
    public openRevealAnswerToast(delay = 0) {
        let options = {
            show: true,
            status: 'reveal',
            title: 'Are you sure?',
            action: 'reveal',
            message: 'You will not be able to revise your answer later.'
        };

        return this.openToast(options, delay);
    }
}
