<div class="landing-container">
    <div class="covid-19-response" *ngIf="showCovidMsg">
        <div class="covid-19-response-close" (click)="closeCovidMsg($event)">
            <i class="icon icon-x"></i>
        </div>
        <p class="covid-19-header">Math-Mapper response to <span class="covid-19-header-title">COVID-19</span>:</p>

        <p class="covid-19-body"><span class="covid-19-body-free">Free</span> diagnostic practice and assessments of students’ progress on learning trajectories-- find out what you know and need to know.</p>  
        
        <p class="covid-19-express">For MIDDLE GRADERS!!!</p>

        <p class="covid-19-login-desc">Free Practice Users: Use the FREE PRACTICE LOG IN button to register/log in</p>

        <p class="covid-19-login-desc">Existing School Users: Use the SCHOOL LOG IN button</p>
    </div>

    <section class="landing-section video-section flex-row align-items-center justify-center">
        <iframe title="Video introduction to Math-Mapper" class="intro-video flex-none" src="https://www.youtube.com/embed/vS8BhKYrE-M" frameborder="0" allowfullscreen></iframe>

        <div class="section-right-panel flex-column flex-auto">
            <h2 class="intro-heading">What happens when students believe they <i>can</i> learn math?</h2>

            <div class="intro-list">
                <div>We've built a tool that:</div>
                <ul class="intro-bulletpoints">
                    <li><b>Engages</b> students in compelling problems</li>
                    <li><b>Leverages</b> their ideas</li>
                    <li><b>Elevates</b> them as partners in learning</li>
                </ul>
            </div>

            <div class="flex-row align-items-center justify-center space-children-row big-space">
                <button class="btn btn-lg primary-button dark-theme body-regular upcase" (click)="openContactModal()">Get in touch</button>
                <button class="btn btn-lg primary-button dark-theme body-regular upcase" *ngIf="!sessionService.userAccess" (click)="openGuestRegistration()">Try for free</button>
                <button class="btn btn-lg primary-button dark-theme body-regular upcase" *ngIf="sessionService.userAccess" [uiSref]="(sessionService.userAccess.page || 'map')">Return to {{ sessionService.userAccess.page || 'map' }}</button>
            </div>

        </div>
    </section>

    <div class="flex-row align-items-center justify-center space-children-row big-space text-center">
        <p class="section-content mm-intro-text text-center">Math Mapper content is currently directed to middle school content</p>
    </div>

    <section class="landing-section mathmapper-section">
        <h2 class="section-heading text-center">This is Math-Mapper.</h2>

        <p class="section-content text-center">Our tool integrates all the elements of instruction, from planning to assessment, using a <b>dynamic learning map.</b> Research-based <b>learning trajectories</b> set the foundation, while curated <b>curricular materials,</b> a dynamic <b>practice environment,</b> and <b>diagnostic assessments</b> and <b>immediate data reports</b> form the supports for student learning.</p>
    </section>

    <section class="landing-section dbir-section flex-row align-items-center justify-center">
        <img class="teachers-image shadow-9dp" src="/src/assets/image/teachers.png">

        <div class="section-right-panel flex-column flex-auto">
            <h2 class="section-heading">Everyone is at the table.</h2>

            <p class="section-content">We use design-based implementation research (DBIR) to work with students, teachers, and administrators to make Math-Mapper <b>work for them.</b> Our interdisciplinary team of learning scientists, testing specialists, designers, and developers work together to deliver school solutions.</p>
        </div>
    </section>

    <section class="landing-section carousel-section">
        <h2 class="section-heading text-center">What they're saying...</h2>

        <scrolling-buttons class="section-content flex-row align-items-start justify-start" [enableButtons]="true" scrollDirection="horizontal">
            <div class="testimonial-container flex-column flex-noshrink">
                <p class="testimonial-text">I love the approach: research-driven, student prioritized, thoughtful, all-encompassing...I feel that you respect teachers’ opinions and thoughts, which is so encouraging.</p>
                <p class="testimonial-source">Teacher</p>
            </div>

            <div class="testimonial-container flex-column flex-noshrink">
                <p class="testimonial-text">One important thing I notice about my data report is that I can see my past score. It’s very useful because I can see if I have gone down and need to study, or if I have improved. </p>
                <p class="testimonial-source">Student</p>
            </div>

            <div class="testimonial-container flex-column flex-noshrink">
                <p class="testimonial-text">Math-Mapper has been a vital tool for teachers and administrators...it provides a visual, easy to understand display of the concepts, which allows users to gain a better understanding about the things we are trying teach.</p>
                <p class="testimonial-source">Administrator</p>
            </div>

            <div class="testimonial-container flex-column flex-noshrink">
                <p class="testimonial-text">I think the learning map has made the vertical alignment clearer. Being able to see the progression of learning through each grade level and the standards makes it easier to see what students should already know and where they need to go.</p>
                <p class="testimonial-source">Teacher</p>
            </div>

            <div class="testimonial-container flex-column flex-noshrink">
                <p class="testimonial-text">It told me exactly i mean EXACTLY what i got wrong and helped me understand.</p>
                <p class="testimonial-source">Student</p>
            </div>
        </scrolling-buttons>
    </section>

    <section class="landing-section cta-section">
        <h2 class="section-heading text-center">Ready to explore?</h2>

        <p class="section-content text-center">Contact our team to find out how to bring Math-Mapper to your school. Or sign up for a free demo account and start exploring.</p>

        <div class="flex-row align-items-center justify-center space-children-row big-space">
            <button class="btn btn-lg primary-button alt-theme body-regular upcase" (click)="openContactModal()">Get in touch</button>
            <button class="btn btn-lg primary-button alt-theme body-regular upcase" *ngIf="!sessionService.userAccess" (click)="openGuestRegistration()">Try for free</button>
            <button class="btn btn-lg primary-button alt-theme body-regular upcase" *ngIf="sessionService.userAccess" [uiSref]="(sessionService.userAccess.page || 'map')">Return to {{ sessionService.userAccess.page || 'map' }}</button>
        </div>
    </section>
</div>
