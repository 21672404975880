<div class="resource-card flex-column" [class.card]="cardType === 'preview'" *ngIf="cardType === 'preview' || cardType === 'detailed'">
    <div class="resource-map-tags flex-row" *ngIf="cardType === 'preview'">
        <div class="flex region-color-tag" *ngFor="let region of resource.regions" [ngClass]="['region-background-' + region]"></div>
    </div>

    <div class="resource-body">
        <div class="resource-image-container">
            <img [src]="resource.image" class="resource-image" (error)="imageNotFound($event)" />

            <div class="image-overlay cursor-pointer" *ngIf="cardType === 'preview'" (click)="openResourceDetailsModal(resource)">
                <div class="view-details-button">View Details</div>
            </div>

            <div class="image-overlay-footer" [class.curated]="resource.curated" *ngIf="cardType === 'preview' && resource.sources?.length > 0">
                <truncate-text chars="50">By {{ resource.authorNames }}</truncate-text>
            </div>
        </div>

        <div class="resource-name-container flex-row space-children-row small-space align-items-center justify-space-between limit-text">
            <a class="resource-link limit-text" [href]="resource.url" target="_blank" rel="noopener" (click)="openResourceExternalLink(resource.url, resource.id)">{{ resource.name }}</a>

            <div class="resource-actions space-children-row small-space">
                <a class="resource-link-icon" [href]="resource.url" target="_blank" rel="noopener" (click)="openResourceExternalLink(resource.url, resource.id)">
                    <i class="fas fa-external-link-alt"></i>
                </a>
                <span *ngIf="cardType === 'preview'" class="cursor-pointer" (click)="openResourceManagerModal.emit(resource)">
                    <i class="far fa-edit"></i>
                </span>
                <span *ngIf="cardType === 'preview' && isResourceDeletable(resource)" class="cursor-pointer" (click)="openResourceDeletionModal.emit({ resource: resource, idx: resourceIdx })">
                    <i class="far fa-trash-alt"></i>
                </span>
            </div>
        </div>

        <div class="space-children-row small-space" *ngIf="resource.grades && resource.grades.length > 0">
            <span class="bold body-regular">Grades:</span>
            <div class="resource-tag" *ngFor="let grade of resource.grades">{{ grade.name.replace('Grade ', '') }}</div>
        </div>

        <div class="space-children-row small-space" *ngIf="cardType === 'detailed' && resource.clusters?.length > 0">
            <span class="bold body-regular">Map Location:</span>
            <div class="resource-tag" *ngFor="let cluster of resource.clusters">
                <span class="region-legend-circle" [ngClass]="'region-background-' + cluster.region"></span>
                <span [innerHTML]="cluster.name"></span>
            </div>
        </div>

        <div class="space-children-row small-space" *ngIf="cardType === 'detailed' && resource.standards?.length > 0">
            <span class="bold body-regular">Standards:</span>
            <span class="resource-tag" *ngFor="let standard of resource.standards">{{ standard.name }}</span>
        </div>

        <div class="space-children-row small-space" *ngIf="cardType === 'detailed' && resource.sources?.length > 0">
            <span class="bold body-regular">Sources:</span>
            <span class="resource-tag" *ngFor="let source of resource.sources">{{ source.name }}</span>
        </div>

        <p class="resource-description" *ngIf="cardType === 'preview'">
            <span>{{ resource.textDesc.length > 140 ? (resource.textDesc | slice: 0: 140) + '...' : resource.textDesc }}</span>
            <a class="text-info" (click)="openResourceDetailsModal(resource)" *ngIf="resource.textDesc.length >= 140">&nbsp;More</a>
        </p>
        <p class="resource-description" *ngIf="cardType === 'detailed'">{{ resource.textDesc }}</p>
    </div>

    <span class="flex"></span>

    <div class="resource-footer">
        <p class="resource-media text-right">{{ resource.media }}</p>

        <div class="flex-row justify-space-between align-items-end space-children-row">
            <div class="resource-rating space-children-row small-space">
                <ngb-rating [(rate)]="resource.rating.avg_rating" max="5" [readonly]="true"></ngb-rating>
                <span class="resource-votes body-small">({{ resource.rating.num_ratings }} votes)</span>
            </div>

            <i class="icon resource-icon" [ngClass]="{'icon-math-mapper': resource.curated, 'icon-onlyme': !resource.curated && !resource.district, 'icon-onlymydistrict': !resource.curated && resource.district }" [matTooltip]="resource.popoverText" matTooltipPosition="below" matTooltipClass="popover-simple-text"></i>
        </div>
    </div>
</div>

<div *ngIf="cardType === 'selectable'" class="resource-card card selectable flex-column">
    <div class="flex-row">
        <div class="resource-image-container flex-30">
            <img [src]="resource.image" class="resource-image" (error)="imageNotFound($event)" />
        </div>

        <div class="resource-body flex-70 flex-column">
            <div class="resource-name-container flex-none space-children-row small-space align-items-center justify-space-between">
                <a class="resource-link limit-text" [href]="resource.url" target="_blank" rel="noopener" (click)="openResourceExternalLink(resource.url, resource.id)">{{ resource.name }}</a>

                <a class="resource-link-icon" [href]="resource.url" target="_blank" rel="noopener" (click)="openResourceExternalLink(resource.url, resource.id)">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </div>

            <div class="flex-none align-items-center justify-space-between space-children-row" *ngFor="let assignedResource of resource.assignedResources; index as assignedResourceIdx">
                <div class="body-regular">Assigned for {{ assignedResource.dateTitle }}</div>
                <span class="cursor-pointer" (click)="deleteAssignedResource.emit({ resource: resource, assignedResource: assignedResource, assignedResourceIdx: assignedResourceIdx })">
                    <i class="far fa-trash-alt"></i>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="!resource.assignedResources || resource.assignedResources.length === 0" class="resource-selection-bar flex-row align-items-center justify-space-between space-children-row" [class.selected]="resource.selected">
        <mat-checkbox name="resourceAssigned{{resource.id}}" id="resource-{{ resource.id }}" [(ngModel)]="resource.selected" class="resource-label-text" [disableRipple]="true">Assign this resource</mat-checkbox>
    </div>
</div>
