<div class="construct-info-column flex-column align-items-start table-column">
    <div *ngIf="cluster; then clusterGraph else titleHeader"></div>
    <ng-template #clusterGraph>
        <div class="table-header flex-none align-items-center justify-center">
            <cluster-graph  [cluster]="cluster"
                            [connectors]="true"
                            [singleLetter]="true">
            </cluster-graph>
        </div>
    </ng-template>

    <ng-template #titleHeader>
        <div class="table-header flex-none align-items-center justify-center bold body-small">Construct Breakdown</div>
    </ng-template>

    <div class="table-data flex-column" *ngFor="let construct of pageResults.construct_info">
        <div class="bold">Construct {{ UtilsService.getLetterFromIndex(construct.order - 1) }}</div>
        <div [innerHTML]="construct.name"></div>
        <span class="flex"></span>
        <div class="levels-covered flex-none align-items-center">
            <i class="icon icon-ladder"></i>
            <span *ngFor="let grade of construct.stack_grades" class="stack-grades" [class.inactive]="grade.grade__name !== pageResults.user_grade.name">
                <span>Gr. {{ grade.grade__name[grade.grade__name.length - 1] }}:</span>
                <span>{{ grade.min_lvl }}</span>
                <span *ngIf="grade.max_lvl !== grade.min_lvl">-{{ grade.max_lvl }}</span>
            </span>
        </div>
    </div>
</div>
