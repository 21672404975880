import { Component, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

import './feedback-form.component.scss';


@Component({
    selector: 'feedback-form',
    templateUrl: './feedback-form.component.html',
    // styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnChanges {

    @Input() questions: any;
    @Input() columnRadio: boolean;
    @Output() completed = new EventEmitter<boolean>();
    public includesPrompt: boolean;

    constructor(
        private element: ElementRef,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.questions) {
            this.updateFormProps(this.questions);
        }
    }

    private updateFormProps(newQuestions) {
        const questionsAvailable = this.questions && this.questions.length > 0;
        this.includesPrompt = questionsAvailable && !!this.questions[0].prompt;
        // If there are no questions emit as completed
        this.completed.emit(!questionsAvailable);
    }

    public checkFormCompletion() {
        // All questions have been responded, or are not required
        const isCompleted = this.questions.every(q => !!q.response || !q.required);
        this.completed.emit(isCompleted);
    }
}
