<button class="btn modal-close" (click)="activeModal.dismiss()">
    <i class="icon icon-x"></i>
</button>

<div class="modal-body">
    <div class="text-center" [innerHTML]="modalOptions.bodyText"></div>
</div>

<div class="modal-footer flex-row align-items-center justify-center space-children-row">
    <button class="btn ghost-button" (click)="activeModal.dismiss('cancel')">{{ modalOptions.closeButtonText }}</button>
    <button class="btn primary-button" (click)="activeModal.close('ok')">{{ modalOptions.actionButtonText }}</button>
</div>
