import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { apiServer } from '@/app.constant';
import { coachmarksData, coachmarksMeta } from './coachmarks.constant';
import { SessionService } from '../service/session.service';
import { AuthService } from '../service/auth.service';
import { HttpService } from '../service/http.service';
import { AnalyticsService } from '../service/analytics.service';


@Injectable()
export class CoachmarksService {

    private coachMarkStateChange = new BehaviorSubject<any>({
        currentlyViewing: null,
        isMapCoachMarkCompleted: false,
        isTestCoachMarkCompleted: false,
        isReportCoachMarkCompleted: false,
        isResourcerCoachMarkCompleted: false,
        isSequencerCoachMarkCompleted: false,
    });

    constructor(
        private SessionService: SessionService,
        private AuthService: AuthService,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) {}


    public getCurrentState(): Observable<any> {
        if (this.SessionService.userProfile) {
            this.updateState({
                currentlyViewing: this.SessionService.userProfile.tour_opt ? null : 0,
                isMapCoachMarkCompleted: (this.SessionService.userProfile.tour & 1) === 1,
                isTestCoachMarkCompleted: (this.SessionService.userProfile.tour & 2) === 2,
                isReportCoachMarkCompleted: (this.SessionService.userProfile.tour & 4) === 4,
                isResourcerCoachMarkCompleted: (this.SessionService.userProfile.tour & 8) === 8,
                isSequencerCoachMarkCompleted: (this.SessionService.userProfile.tour & 16) === 16,
            });
        }

        return this.coachMarkStateChange.asObservable();
    }

    public updateState(newState) {
        this.coachMarkStateChange.next(newState);
    }

    public getCoachmarkData(index) {
        const coachmark = Object.assign({}, coachmarksData[index]);

        switch (index) {
            case 0:
                if (this.SessionService.userAccess.permission === 1) {
                    coachmark.src += '_guest';
                    coachmark.exit = {
                        text: 'Take me to Math-Mapper',
                        position: {
                            top: '70%',
                            left: '31%',
                            bottom: 'initial',
                            right: 'initial'
                        }
                    };
                    coachmark.next = {
                        text: 'I want to learn more',
                        position: {
                            top: '70%',
                            left: 'calc(31% + 235px + 25px)',
                            bottom: 'initial',
                            right: 'initial'
                        }

                    };
                }
                break;
            case (coachmarksMeta.map.start + coachmarksMeta.map.length - 1):
                if (this.SessionService.userAccess.permission === 1) {
                    coachmark.src += '_guest';
                } else if (this.SessionService.userAccess.permission === 2) {
                    coachmark.src += '_student';
                } else if (this.SessionService.userAccess.permission === 4) {
                    coachmark.src += '_teacher';
                }
                break;
            default:
        }

        return coachmark;
    }

    public recordMilestones(idx) {
        let tourValue;
        let cmState = this.coachMarkStateChange.value;
        if (idx === coachmarksMeta.map.start + coachmarksMeta.map.length - 1 && !cmState.isMapCoachMarkCompleted) {
            tourValue = 1;
            cmState.isMapCoachMarkCompleted = true;
        } else if (idx === coachmarksMeta.test.start + coachmarksMeta.test.length - 1 && !cmState.isTestCoachMarkCompleted) {
            tourValue = 2;
            cmState.isTestCoachMarkCompleted = true;
        } else if (idx === coachmarksMeta.report.start + coachmarksMeta.report.length - 1 && !cmState.isReportCoachMarkCompleted) {
            tourValue = 4;
            cmState.isReportCoachMarkCompleted = true;
        } else if (idx === coachmarksMeta.resourcer.start + coachmarksMeta.resourcer.length - 1 && !cmState.isResourcerCoachMarkCompleted) {
            tourValue = 8;
            cmState.isResourcerCoachMarkCompleted = true;
        } else if (idx === coachmarksMeta.sequencer.start + coachmarksMeta.sequencer.length - 1 && !cmState.isSequencerCoachMarkCompleted) {
            tourValue = 16;
            cmState.isSequencerCoachMarkCompleted = true;
        }

        if (tourValue) {
            this.updateState(cmState);
            this.updateUserSettings(null, tourValue)
                .catch(console.warn);
        }
    }

    public updateUserSettings(optOut = false, tourBinary = 0) {
        let url = `${apiServer.urlPrefix}/user/setting/`;
        let settings = {
            tour_opt_out: optOut,
            tour: tourBinary
        };

        return this.HttpService.post(url, settings)
            .then(response => {
                const updatedProfile = Object.assign({
                    tour: response.result.tour,
                    tour_opt: response.result.tour_opt,
                }, this.SessionService.userProfile);
                this.SessionService.setUserProfile(updatedProfile);

                this.AnalyticsService.action({
                    action: 'update_user_settings',
                    params: settings
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'update_user_settings_fail',
                    error: error,
                    url: url,
                    supplement: settings
                });

                return Promise.reject(error);
            });
    }
}
