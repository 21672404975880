<div class="modal-body">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>

    <h3 class="capitalize modal-title margin-bottom-1em">Contact Us!</h3>

    <form class="contact-form flex-column flex-auto" name="contactForm" novalidate>
        <mat-form-field appearance="outline">
            <mat-label>First name</mat-label>
            <input matInput type="text" id="contact-name" [(ngModel)]="contact.name" name="contactFirstName" maxlength="40" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" id="contact-email" [(ngModel)]="contact.email" name="contactEmail" maxlength="75" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>School, district, or organization</mat-label>
            <input matInput type="text" id="contact-org" [(ngModel)]="contact.org" name="contactOrg" maxlength="75">
        </mat-form-field>


        <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <input matInput type="text" id="contact-role" [(ngModel)]="contact.role" name="contactRole" maxlength="75">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Message</mat-label>
            <textarea   matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5"
                        id="contact-message"
                        [(ngModel)]="contact.message"
                        name="contactMessage"
                        minlength="10"
                        maxlength="1000"
                        required>
            </textarea>
        </mat-form-field>

        <div class="flex-row align-items-center justify-center space-children-row">
            <button type="submit" class="btn primary-button body-regular" (click)="sendEmail()">Send</button>

            <button type="button" class="btn ghost-button body-regular" (click)="activeModal.dismiss()">Cancel</button>
        </div>
    </form>
</div>
