import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';
import { StateService } from '@uirouter/core';

import { captchaSiteKeyV2, captchaSiteKeyV3 } from '@/app.constant';
import { matchingPasswordValidator } from '../../shared/directives';
import { LoginService } from '../login.service';
import { AuthService } from '../../core/service/auth.service';
import { GrowlerService } from '../../core/service/growler.service';
import { UtilsService } from '../../core/service/utils.service';


@Component({
    selector: 'new-password-form',
    templateUrl: './new-password-form.component.html',
})
export class NewPasswordFormComponent implements OnChanges {

    @Input() captcha: boolean;
    @ViewChild('captchaElement', { static: false }) captchaElement: ReCaptcha2Component;

    public readonly captchaSiteKeyV2 = captchaSiteKeyV2;
    public npForm: FormGroup;
    public passwordResetFailMessage: string;

    get password() { return this.npForm.get('password'); }
    get passwordConfirm() { return this.npForm.get('passwordConfirm'); }

    constructor(
        private fb: FormBuilder,
        private $state: StateService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private loginService: LoginService,
        private AuthService: AuthService,
        private GrowlerService: GrowlerService,
        private UtilsService: UtilsService,
    ) {
        this.npForm = this.fb.group({
            password:        ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            passwordConfirm: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(75)]],
            recaptcha:       ['', Validators.required],
        }, {
            validators: matchingPasswordValidator
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.captcha && change.captcha.currentValue !== change.captcha.previousValue) {
            this.toggleCaptchaValidation();
        }
    }

    public toggleCaptchaValidation() {
        if (this.captcha) {
            this.npForm.get('recaptcha').enable();
        } else {
            this.npForm.get('recaptcha').disable();
        }
    }

    public resetFormErrors() {
        this.passwordResetFailMessage = null;
    }

    public showForm(formName) {
        this.resetFormErrors();
        this.loginService.activeForm = formName;
    }

    public setPassword() {
        this.UtilsService.addLoadingOverlay(true);
        this.reCaptchaV3Service.execute(captchaSiteKeyV3, 'set_new_password', token => {
            const credentials = Object.assign({
                email: this.$state.params.email,
                code: this.$state.params.key,
            }, this.npForm.value);

            this.AuthService.setPassword(credentials)
                .then(() => {
                    this.npForm.reset();
                    this.GrowlerService.success('You can now log in with your new credentials.', 'Your new password was saved');
                    this.showForm('login');
                })
                .catch(error => {
                    if (error.result && error.result.msg) {
                        this.captcha = error.result.msg.captcha;
                        this.toggleCaptchaValidation();
                        this.passwordResetFailMessage = error.result.msg.msg;
                    }
                    // Only reset captcha on fail because on success component is destroyed
                    if (this.captchaElement) {
                        this.captchaElement.resetCaptcha();
                    }
                })
                .finally(() => {
                    this.UtilsService.removeLoadingOverlay();
                    this.npForm.patchValue({
                        passwordConfirm: '',
                        recaptcha: '',
                    });
                });
        });
    }
}
