import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import './map-cluster-resources-modal.component.scss';


@Component({
    selector: 'map-cluster-resources-modal',
    templateUrl: './map-cluster-resources-modal.component.html',
    // styleUrls: ['./map-cluster-resources-modal.component.scss'],
})
export class MapClusterResourcesModalComponent implements OnInit {

    @Input() cluster: any;
    @Input() resources: any;
    public selectedResources: any;

    constructor(
        public activeModal: NgbActiveModal,
    ) {}

    public ngOnInit() {
        this.selectedResources = this.resources;
    }

    public filterResourcesByConstruct() {
        let selectedConstructs = this.cluster.constructs.filter(co => co.filterResources);
        // If no constructs are selected display all cluster resources
        if (selectedConstructs.length === 0) {
            this.selectedResources = this.resources;
        } else {
            // Check that every selected construct is included in the resource
            this.selectedResources = this.resources.filter(resource => {
                return selectedConstructs.every(construct => {
                    return resource.construct_id.includes(construct.id);
                });
            });
        }
    }
}
