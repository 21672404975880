<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h3 class="modal-title text-center">About {{ info.provider }} {{ info.name }}</h3>
</div>

<div class="modal-body">
    <h4 class="subsection-title">Overview</h4>
    <p>The {{ info.provider }} {{ info.name }} project is conducted under the direction of <a class="italic" href="http://sudds.ced.ncsu.edu/team#jere" target="_blank" rel="noopener">Dr. Jere Confrey</a>. We gratefully acknowledge support for our research and development work from the <a class="italic" href="http://www.nsf.gov/" target="_blank" rel="noopener">National Science Foundation</a> (DRL1118858, DRL1621254) and the <a class="italic" href="http://www.gatesfoundation.org/" target="_blank" rel="noopener">Bill and Melinda Gates Foundation</a> (OPP1104046). For more information on the {{ info.provider }} research team, go to <a class="italic" [href]="info.providerUrl" target="_blank" rel="noopener">our website</a> or email us at <a class="italic" [href]="'mailto:' + info.providerEmail" target="_blank" rel="noopener">{{ info.providerEmail }}</a></p>
    <p>The application supports latest versions of Chrome (preferred), Safari, and Firefox. To learn more about how to use the map and its features, please click Contact Us at any time or click User Guide after logging in.</p>

    <h4 class="subsection-title">Contributors</h4>
    <p><b>Research</b>: Dr. Jere Confrey, Dr. Meetal Shah, and Michael Belcher.</p>
    <p><b>Psychometrics</b>: Dr. Emily Toutkoushian.</p>
    <p><b>Outreach</b>: Dr. Jenn Persson.</p>
    <p><b>Developers</b>: Yungjae Kim and David Meza.</p>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
    <copyright></copyright>
    <div class="app-version">v{{ info.version }} ({{ deployed | date: dateFormat.shortDate }})</div>
</div>
