<div class="modal-header dark-theme flex-row align-items-center justify-space-between">
    <div class="assessment-questions-wrapper flex flex-wrap align-items-center justify-start">
        <div class="text-center assessment-question" *ngFor="let q of AssessmentStateService.data.questionStatuses; let i = index">
            <span *ngIf="AssessmentStateService.data.type === 'real' && q.bm" (click)="bookmarkQuestion(q, i)" class="cursor-pointer">
                <i class="fas fa-bookmark assessment-bookmark"></i>
            </span>
            <span *ngIf="AssessmentStateService.data.type === 'real' && !q.bm" (click)="bookmarkQuestion(q, i)" class="cursor-pointer">
                <i class="far fa-bookmark assessment-bookmark"></i>
            </span>

            <div class="assessment-question-number flex-none justify-center align-items-center" [ngClass]="{ current: AssessmentStateService.data.currentQuestion === i, skipped: q.sk, submitted: q.sm }" (click)="saveResponseAndNavigateTo(i)">{{ i + 1 }}</div>
        </div>
    </div>

    <div class="assessment-navigation flex-none align-items-center justify-end space-children-row small-space">
        <div>
            <button type="button" class="btn ghost-button icon-button flex-none inline-flex align-items-center justify-center" [ngClass]="{ inactive: AssessmentStateService.data.currentQuestion <= 0 || AssessmentStateService.data.currentQuestion > AssessmentStateService.data.maxQuestion }" (click)="saveResponseAndNavigateTo(AssessmentStateService.data.currentQuestion - 1)">
                <i class="fas fa-arrow-left"></i>
            </button>

            <button type="button" class="btn ghost-button icon-button flex-none inline-flex align-items-center justify-center" [ngClass]="{ inactive: AssessmentStateService.data.currentQuestion < 0 || AssessmentStateService.data.currentQuestion >= AssessmentStateService.data.maxQuestion }" (click)="saveResponseAndNavigateTo(AssessmentStateService.data.currentQuestion + 1)">
                <i class="fas fa-arrow-right"></i>
            </button>
        </div>

        <button type="button" class="btn ghost-button body-regular" (click)="pauseAssessment()" *ngIf="AssessmentStateService.data.type === 'real' && AssessmentStateService.data.currentQuestion >= 0" [class.inactive]="!AssessmentStateService.data.question">
            <span class="mr-1" *ngIf="!AssessmentStateService.data.question"><i class="fas fa-spinner fa-spin"></i></span>
            <span>Pause / Finish</span>
        </button>

        <button type="button" class="btn ghost-button" (click)="finishAssessment(2)" *ngIf="AssessmentStateService.data.type === 'preview'">Exit</button>
    </div>
</div>

<div *ngIf="AssessmentStateService.data.currentQuestion >= 0">
    <div class="modal-body">
        <question class="flex-row full-height flex-wrap"></question>
    </div>

    <div class="modal-footer modal-footer--assessment flex-row align-items-center justify-center flex-wrap space-children-row">
        <h4 class="assessment-title flex-33">
            <span class="capitalize">{{ AssessmentStateService.data.type }}&nbsp;</span>
            <span>Test</span>
            <span *ngIf="AssessmentStateService.data.type === 'preview' && AssessmentStateService.data.question?.testforms">: Question in
                <span [ngPlural]="AssessmentStateService.data.question?.testforms?.length">
                    <ng-template ngPluralCase="one">Form</ng-template>
                    <ng-template ngPluralCase="other">Forms</ng-template>
                </span> {{ AssessmentStateService.data.question?.testforms?.join(', ') }}
            </span>
        </h4>

        <div class="flex-column justify-space-between align-items-center">
            <cms-item-link></cms-item-link>
            <copyright></copyright>
        </div>

        <h5 class="assessment-name flex-33 text-right" [innerHTML]="AssessmentStateService.data.name"></h5>
    </div>
</div>

<div *ngIf="AssessmentStateService.data.currentQuestion === -1">
    <div class="modal-body flex-column align-items-center justify-center text-center">
        <div>
            <span>You have {{ AssessmentStateService.data.questionCompleted.sk }} incomplete (</span>
            <span class="assessment-skipped-legend"></span>
            <span>) and</span>
        </div>
        <div>
            <span>{{ AssessmentStateService.data.questionCompleted.bm }} bookmarked (</span>
            <span class="assessment-bookmark-legend"><i class="fas fa-bookmark"></i></span>
            <span>) tasks.</span>
        </div>

        <div class="hr"></div>

        <div>Want to <b>finish by submitting</b> your test? Click "Submit Test"</div>
        <div>Want to <b>continue your test</b> now? Click "Go Back"</div>
    </div>

    <div class="modal-footer modal-footer--assessment flex-row align-items-center justify-center space-children-row">
        <button class="btn ghost-button" (click)="returnToAssessment()">Go back</button>
        <button class="btn primary-button" (click)="finishAssessment(0)">Save to Finish Later</button>
        <button class="btn primary-button" (click)="finishAssessment(1)">Submit Test</button>
    </div>
</div>
