<div class="modal-header">
    <div class="modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </div>

    <h4 class="modal-title">What would you like to do within <span [innerHTML]="cluster.name"></span>?</h4>
</div>

<div class="modal-body">
    <div class="flex-row justify-space-between flex-wrap assessment-options-wrapper">
        <div class="assessment-option flex-column align-items-center">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-abacus"></i>
            </div>

            <button #practiceDropdownTrigger="matMenuTrigger" [matMenuTriggerFor]="practiceDropdown" [disabled]="availableAssessments.practice.length === 0" class="btn primary-button space-children-row small-space upcase assessment-option-button">
                <span>Practice On...</span>
                <i class="icon icon-caret-down" *ngIf="availableAssessments.practice.length > 0" [class.rotate]="practiceDropdownTrigger.menuOpen"></i>
            </button>

            <mat-menu #practiceDropdown="matMenu" class="actions-popover practice-construct-selection" xPosition="after">
                <button mat-menu-item [disableRipple]="true" *ngFor="let construct of availableAssessments.practice" (click)="showPracticeAssessment(construct)">
                    <span [innerHTML]="construct.name" class="construct-selection"></span>
                </button>
            </mat-menu>

            <p class="assessment-option-description">Practice tasks by construct with immediate feedback and sharing.</p>
        </div>

        <div class="assessment-option flex-column align-items-center">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-take-test"></i>
            </div>

            <button #assessmentDropdownTrigger="matMenuTrigger" [matMenuTriggerFor]="assessmentDropdown" [disabled]="!availableAssessments.assessment && !hasAssignedTests && availableAssessments.student_try.length === 0" class="btn primary-button space-children-row small-space upcase assessment-option-button">
                <span>Take Assessment...</span>
                <i class="icon icon-caret-down" *ngIf="availableAssessments.assessment || hasAssignedTests || availableAssessments.student_try.length > 0" [class.rotate]="assessmentDropdownTrigger.menuOpen"></i>
            </button>

            <mat-menu #assessmentDropdown>
                <button mat-menu-item [matMenuTriggerFor]="assigned" *ngIf="hasAssignedTests" [disableRipple]="true">Take <span style="text-decoration: underline;">Assigned</span> Assessment</button>
                <button mat-menu-item [matMenuTriggerFor]="tries" *ngIf="availableAssessments.student_try.length > 0" [disableRipple]="true">Opt to <span style="text-decoration: underline;">Try</span> a Cluster Test</button>
                <button mat-menu-item *ngIf="availableAssessments.pre" [disableRipple]="true" (click)="showAssessmentInfo(availableAssessments.pre)">Take Demo Test</button>
                <button mat-menu-item *ngIf="availableAssessments.post" [disableRipple]="true" (click)="showAssessmentInfo(availableAssessments.post)">Take Demo Test</button>
            </mat-menu>

            <mat-menu #assigned>
                <ng-container *ngFor="let test of availableAssessments.tests">
                    <button mat-menu-item [disableRipple]="true" (click)="getSectionAssessment(test)" *ngIf="!test.completed">{{ !!test.construct ? 'CON' : 'CLU' }}: Gr. {{ UtilsService.gradeBinToText(test.grade) }} {{ UtilsService.testformTypeToText(8, !!test.construct) }}</button>
                    <ng-container *ngFor="let retest of test.retest">
                        <button mat-menu-item [disableRipple]="true" (click)="getSectionAssessment(test, retest.retest_id)">{{ !!test.construct ? 'CON' : 'CLU' }}: Gr. {{ UtilsService.gradeBinToText(test.grade) }} {{ UtilsService.testformTypeToText(9, !!test.construct) }}</button>
                    </ng-container>
                </ng-container>
            </mat-menu>

            <mat-menu #tries>
                <ng-container *ngFor="let test of availableAssessments.student_try">
                    <button mat-menu-item [disableRipple]="true" (click)="getStudentTry(test.gradeband)" *ngIf="!test.completed">Grade {{ UtilsService.gradeBinToText(test.gradeband) }}</button>
                </ng-container>
            </mat-menu>

            <p class="assessment-option-description">Take assigned test/retests or opt to try a test.</p>
            <p class="assessment-option-description" *ngIf="availableAssessments.due">This test is due: <span class="bold">{{ availableAssessments.due | date: dateFormat.shortDate : dateFormat.timezone }}</span></p>
        </div>

        <div class="assessment-option flex-column align-items-center">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-bar-chart"></i>
            </div>

            <button [disabled]="!availableAssessments.stats" class="btn primary-button upcase assessment-option-button" (click)="goToClusterReport()" [matTooltipDisabled]="availableAssessments.stats" matTooltip="You don't have any test results in this cluster yet.">See My Track Record</button>

            <p class="assessment-option-description">See my results on current and prior cluster assessment events.</p>
        </div>
    </div>
</div>
