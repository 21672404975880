import { Component, OnInit, OnDestroy } from '@angular/core';

import * as moment from 'moment';
import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { SessionService } from '../core/service/session.service';
import { UtilsService } from '../core/service/utils.service';
import { ReportService } from '@/report/report.service';
import { AssessmentService } from './assessment.service';
import { AssessmentStateService } from './common/assessment-state.service';

import './assessment.component.scss';

@Component({
    selector: 'assessment-page',
    templateUrl: './assessment.component.html',
    // styleUrls: ['./assessment.component.scss'],
})
export class AssessmentComponent implements OnInit, OnDestroy {

    public selectedIndex: number;
    public isMinimapOpen: boolean = false;

    constructor(
        private $state: StateService,
        public SessionService: SessionService,
        public UtilsService: UtilsService,
        private ReportService: ReportService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.selectedIndex = Math.min(Math.max(0, Number(this.$state.params.t) || 0), 2);
    }

    public ngOnDestroy() {
        this.ReportService.resetReportModels();
    }

    public updateStateParams(selectedIndex) {
        this.$state.transitionTo(
            this.$state.current.name,
            { t: selectedIndex },
            { inherit: true, location: 'replace', reload: false }
        );
    }
}
