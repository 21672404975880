import { Component } from '@angular/core';

import { appInfo } from '@/app.constant';


@Component({
    selector: 'copyright',
    templateUrl: './copyright.component.html',
})
export class CopyrightComponent {

    public readonly appInfo = appInfo;
    public currentYear = new Date().getFullYear();

    constructor() {}
}
