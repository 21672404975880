import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { dateToString } from '@/_helpers/transforms';
import { AnalyticsService } from '../core/service/analytics.service';
import { UtilsService } from '../core/service/utils.service';
import { HttpService } from '../core/service/http.service';

import { NewTestAssignmentModalComponent } from './new-test/new-test-assignment-modal.component';
import { PreviewTestModalComponent } from './preview-test/preview-test-modal.component';


@Injectable()
export class AssessmentManagementService {

    public models = {
        schoolYear: null,
        section: null,
        cluster: null,
    };
    private modelsConst = JSON.stringify(this.models);

    private lastTabAssessmentSearch: string;
    private lastTabAssessmentData: any;

    private lastTabTriesSearch: string;
    private lastTabTriesData: any;

    private lastTabPracticeSearch: string;
    public lastTabPracticeData: any;

    constructor(
        private ngbmodal: NgbModal,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private HttpService: HttpService,
    ) { }

    public resetAssessmentManagementModels() {
        this.models = JSON.parse(this.modelsConst);
        // Remove all cached data
        this.lastTabAssessmentSearch = null;
        this.lastTabAssessmentData = null;
        this.lastTabTriesSearch = null;
        this.lastTabTriesData = null;
        this.lastTabPracticeSearch = null;
        this.lastTabPracticeData = null;
    }

    public getTeacherSchoolYear(option = undefined, dateSorted = true): any {
        let url = `${apiServer.urlPrefix}/teacher/school_year/get/`;
        let params = {
            option: option
        };

        return this.HttpService.get(url, params)
            .then(response => {
                if (dateSorted) {
                    // Sort future to past (descending)
                    response.result.sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime());
                }

                this.AnalyticsService.action({
                    action: 'get_teacher_school_years',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_teacher_school_years_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    // section_id is the only required param
    public getTestsForSection(params: { section_id: number, type?: string, cluster_id?: number, construct_id?: number } = { section_id: null }) {
        let url = `${apiServer.urlPrefix}/assessment/manage/`;
        const paramsStr = JSON.stringify(params);

        if (paramsStr === this.lastTabAssessmentSearch) {
            return Promise.resolve(this.lastTabAssessmentData);
        } else if (paramsStr === this.lastTabTriesSearch) {
            return Promise.resolve(this.lastTabTriesData);
        } else if (paramsStr === this.lastTabPracticeSearch) {
            return Promise.resolve(this.lastTabPracticeData);
        }

        return this.HttpService.get(url, params)
            .then(response => {
                let result = null;

                this.AnalyticsService.action({
                    action: 'get_section_assessments',
                    url,
                    params,
                });

                if (!params.type) {
                    let sortedTests = this.UtilsService.sortTestsByDate(response.result);
                    result = this.groupByCluCon(sortedTests);

                    this.lastTabAssessmentSearch = JSON.stringify(params);
                    this.lastTabAssessmentData = result;
                } else if (params.type === 'tries') {
                    let sortedTests = this.UtilsService.sortTestsByDate(response.result);
                    result = this.groupByCluCon(sortedTests);

                    this.lastTabTriesSearch = JSON.stringify(params);
                    this.lastTabTriesData = result;
                } else if (params.type === 'practice') {
                    result = response.result;

                    if (!params.construct_id) {
                        this.lastTabPracticeSearch = JSON.stringify(params);
                        this.lastTabPracticeData = result;
                    }
                }

                return Promise.resolve(result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_section_assessments_fail',
                    url,
                    params,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public getStudentTriesActivity(params: { section_id: number, cluster_id: number, gradeband: number }) {
        let url = `${apiServer.urlPrefix}/section/tries/taken/`;

        return this.HttpService.post(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_student_tries_activity',
                    url,
                    params,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_student_tries_activity_fail',
                    url,
                    params,
                    error,
                });

                return Promise.reject(error);
            });
    }

    private groupByCluCon(tests) {
        let cluConArr = [];

        tests.forEach(test => {
            this.findOrCreateCluCon(cluConArr, test);
        });

        return cluConArr;
    }

    public findOrCreateCluCon(cluConArr, test) {
        if (test.construct) {
            this.addTestToCluCon(cluConArr, test, 'construct');
        } else if (test.cluster) {
            this.addTestToCluCon(cluConArr, test, 'cluster');
        }
    }

    private addTestToCluCon(cluConArr, test, entityName) {
        const entity = test[entityName];
        let existingEntity = cluConArr.find(c => c[entityName] && c.id === entity.id);
        // If construct or cluster are already in the array append tests to existing clu/con
        if (existingEntity) {
            // Check if assp already exists in clu/con to replace instead of add
            let existingAssp = existingEntity.tests.find(t => t.id === test.id);
            if (existingAssp) {
                let existingRetests;
                if (existingAssp.retest && existingAssp.retest.length > 0) {
                    // Save a copy of the retest array
                    existingRetests = existingAssp.retest;
                }
                // When replacing do not splice because new assp doesn't have test data. Instead update props
                Object.assign(existingAssp, test);

                if (existingRetests) {
                    // If retests were stored then restore them with any updated props
                    existingAssp.retest = existingAssp.retest.map(newRetest => {
                        let matchingRt = existingRetests.find(rt => rt.retest_id === newRetest.retest_id);
                        if (matchingRt) {
                            return Object.assign(matchingRt, newRetest);
                        }
                        return newRetest;
                    });
                }
            } else {
                existingEntity.tests.push(test);
            }
        } else {
            // Otherwise it's a new clu/con so add to array with current test
            entity[entityName] = true;
            if (entityName === 'construct') {
                // Copy cluster name so we can display it along with construct name
                entity.clusterName = test.cluster.name;
            }
            entity.tests = [test];
            cluConArr.push(entity);
        }
    }

    public getAssessmentPreviewList(aSspId, retest) {
        let url = `${apiServer.urlPrefix}/assessment/preview/ssp/${aSspId}/${(retest && retest.retest_id ? retest.retest_id : 0)}/`;

        return this.HttpService.get(url)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_section_assessment_preview',
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_section_assessment_preview_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public openNewTestAssignmentModal(dropdownModels, schoolYears) {
        const modalRef = this.ngbmodal.open(NewTestAssignmentModalComponent, {
            windowClass: 'new-test-assignment-modal',
            size: 'lg',
            // User is likely to hit ESC key to close a form dropdown
            keyboard: false,
            backdrop: 'static',
        });
        modalRef.componentInstance.dropdownModels = JSON.parse(JSON.stringify(dropdownModels));
        modalRef.componentInstance.schoolYears = schoolYears;

        return modalRef.result;
    }

    public openTestPreviewModal(previewList, assp, retest) {
        const modalRef = this.ngbmodal.open(PreviewTestModalComponent, {
            windowClass: 'preview-assessment-selection-modal',
            size: 'sm',
        });
        modalRef.componentInstance.previewList = previewList;
        modalRef.componentInstance.assp = assp;
        modalRef.componentInstance.retest = retest;

        return modalRef.result;
    }

    public getStudentTestTaken(sectionId, testId, retest=null) {
        let url = `${apiServer.urlPrefix}/section/assessment/taken/`;
        let serverData = {
            section_id: sectionId,
            assp_id: testId,
        };

        if (retest) {
            serverData['retest_id'] = retest.retest_id;
            // Patch for old type retests
            if (retest.attempt) {
                serverData['retest'] = retest.attempt - 1;
            }
        }

        return this.HttpService.post(url, serverData)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_section_students_test_taken',
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_section_students_test_taken_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public getAssessmentDetails({ cluster_id, construct_id, school_id }) {
        const url = `${apiServer.urlPrefix}/assessment/detail/`;
        const serverData = {
            cluster_id,
            construct_id,
            school_id,
        };

        return this.HttpService.post(url, serverData)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_assessment_details',
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_assessment_details_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public createClusterAssessment(serverData) {
        return this.sectionAssessmentAction('create', serverData, 'PUT');
    }

    public updateClusterAssessment(serverData) {
        return this.sectionAssessmentAction('update', serverData, 'PATCH');
    }

    public deleteClusterAssessment(serverData) {
        return this.sectionAssessmentAction('delete', serverData, 'DELETE');
    }

    private sectionAssessmentAction(action, serverData, method) {
        let url = `${apiServer.urlPrefix}/assessment/manage/`;
        this.formatDatepickerToModel(serverData);

        return this.HttpService.any(url, serverData, method)
            .then(response => {
                this.AnalyticsService.action({
                    action: action + '_section_cluster_assessment',
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: action + '_section_cluster_assessment_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    private formatDatepickerToModel(serverData) {
        Object.keys(serverData)
            .filter(key => key.includes('date'))
            .forEach(key => {
                if (serverData[key]) {
                    serverData[key] = dateToString(serverData[key]);
                }
            });
    }
}
