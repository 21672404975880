<div class="stack-ladder flex-row justify-center" *ngIf="construct" #stackLadder>
    <div class="ladder-container">
        <div class="ladder-top"></div>

        <div
            class="ladder-block flex-none cursor-pointer"
            *ngFor="let stack of construct.stacks"
            (click)="selectStack(stack)"
            [class.disabled]="stack.available_items <= 0 && !sessionSummary"
            [class.question-review]="sessionSummary"
            [class.selected]="AssessmentStateService.stackLadder.stack === stack"
            [attr.data-grade]="stack.grade">
            <div class="stack-summary flex-none flex-column align-items-center justify-center">
                <div class="stack-level">L{{ stack.order }}</div>
                <div class="questions-remaining" *ngIf="stack.available_items">
                    <span>{{ stack.available_items }}&nbsp;</span>
                    <span [ngPlural]="stack.available_items">
                        <ng-template ngPluralCase="one">question</ng-template>
                        <ng-template ngPluralCase="other">questions</ng-template>
                    </span>
                    <span *ngIf="assessmentStage">&nbsp;left</span>
                </div>
            </div>

            <div class="stack-description flex-column justify-space-between">
                <div [mathHTML]="stack.description" [truncateChars]="truncateChars" [truncateEnable]="true"></div>

                <div class="bubbles-side-scrolling">
                    <div class="bubble-container" *ngFor="let question of stack.itemsTaken">
                        <score-bubble class="scorecard-bubble cursor-pointer" [question]="question" [sessionSummary]="sessionSummary" #questionScoreTrigger="ngbPopover" [ngbPopover]="questionScore" container="body" autoClose="outside" popoverClass="question-credit-popover"></score-bubble>

                        <ng-template #questionScore>
                            <div class="flex-column space-children-column align-items-center">
                                <p *ngIf="!question.score">Question not answered</p>
                                <p *ngIf="question.score === 'correct'">You got full credit on the {{ question.triesOrdinal }} try.</p>
                                <p *ngIf="question.score === 'partial'">You got partial credit for this question on the {{ question.triesOrdinal }} try.</p>
                                <p *ngIf="question.score === 'incorrect'">You did not get credit for this question after the {{ question.triesOrdinal }} try.</p>

                                <button class="btn btn-link text-info" *ngIf="sessionSummary && question.score" (click)="questionScoreTrigger.close();viewQuestion(question)">
                                    <span>View Question</span>&nbsp;
                                    <i class="fas fa-external-link-alt"></i>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <div class="ladder-bottom"></div>
    </div>
</div>
