export const ordinal = (input: number): string => {
    let s = [
        'th',
        'st',
        'nd',
        'rd'
    ];
    let v = input % 100;

    return input + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const romanize = (num: number): string => {
    let digits = String(num).split('');
    let key = [
        '', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
        '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
        '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'
    ];
    let roman = '';
    let i = 3;

    while (i--) {
        roman = (key[+digits.pop() + (i * 10)] || '') + roman;
    }

    return Array(+digits.join('') + 1).join('M') + roman;
};

export const secondsToMinutes = (seconds: number): string => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = String(seconds % 60);

    return minutes + ':' + remainingSeconds.padStart(2, '0');
};

export const groupByCourses = (section: any): string => {
    return section.course.code + (section.course.name ? ' (' + section.course.name + ')' : '');
};

export const dateToString = (date: any): string => {
    if (date instanceof Date) {
        return date.toISOString().replace(/T.+/, '');
    }

    return date;
};

export const stripHTMLTags = (text: string): string => {
    if (!text) { return ''; }

    return text.replace(/<\/?[a-z][a-z0-9]*[^<>]*>/ig, '');
};
