import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbPopoverModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormModule } from '../_custom/mat-form.module';

import { SharedModule } from '../shared/shared.module';

import { MapService } from './map.service';

import { MapComponent } from './map.component';
import { LearningMapComponent } from './learning-map/learning-map.component';
import { MapFieldComponent } from './field/map-field.component';
import { MapRegionComponent } from './region/map-region.component';
import { MapRegionPopoverComponent } from './region/map-region-popover.component';
import { MapClusterComponent } from './cluster/map-cluster.component';
import { MapConstructComponent } from './construct/map-construct.component';

import { MapOptionsComponent } from './options/map-options.component';
import { MapSearchPanelComponent } from './searchable/map-search-panel.component';
import { MisconceptionPinComponent } from './searchable/misconception-pin.component';
import { StandardPinComponent } from './searchable/standard-pin.component';

import { StackPanelComponent } from './stack/stack-panel.component';
import { MisconceptionCardComponent } from './misconception/misconception-card.component';
import { MisconceptionTabsComponent } from './misconception/misconception-tabs.component';
import { MapSliderComponent } from './slider/map-slider.component';

import { MapAssessmentOptionModalComponent } from './map-assessment/map-assessment-option-modal.component';


@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        NgbCollapseModule,
        MatTooltipModule,
        MatMenuModule,
        MatSliderModule,
        MatFormModule,
        SharedModule,
    ],
    providers: [
        MapService,
    ],
    declarations: [
        MapComponent,
        LearningMapComponent,
        MapFieldComponent,
        MapRegionComponent,
        MapRegionPopoverComponent,
        MapClusterComponent,
        MapConstructComponent,
        MapOptionsComponent,
        MapSearchPanelComponent,
        MisconceptionPinComponent,
        StandardPinComponent,
        StackPanelComponent,
        MisconceptionCardComponent,
        MisconceptionTabsComponent,
        MapSliderComponent,
        MapAssessmentOptionModalComponent,
    ],
    entryComponents: [
        MapAssessmentOptionModalComponent,
    ],
    exports: [
        StackPanelComponent,
        MisconceptionCardComponent,
        MisconceptionTabsComponent,
    ],
})
export class MapModule { }
