import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
    selector: 'resourcer-pagination',
    templateUrl: './resourcer-pagination.component.html',
})
export class ResourcerPaginationComponent implements OnInit {

    @Input() resourcer: any;
    @Output() pageChange = new EventEmitter<number>();
    public maxSize = 5;

    constructor() { }

    public ngOnInit() {
        if (window.innerWidth < 768) {
            this.maxSize = 3;
        } else if (window.innerWidth < 991) {
            this.maxSize = 4;
        }
    }
}
