import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '../core/service/analytics.service';
import { AssessmentStateService } from './common/assessment-state.service';

import { PracticeTestModalComponent } from './practice/practice-test-modal.component';
import { QuestionViewModalComponent } from './common/question-view/question-view-modal.component';
import { TestInstructionsModalComponent } from './cluster/instructions/test-instructions-modal.component';
import { ClusterTestModalComponent } from './cluster/cluster-test-modal.component';
import { TestFinalizeModalComponent } from './cluster/finalize/test-finalize-modal.component';
import { ReviseRevealModalComponent } from '../report/revise-reveal/revise-reveal-modal.component';


@Injectable()
export class AssessmentService {

    private practiceFinishedChange = new Subject<any>();
    public practiceFinished$: Observable<any> = this.practiceFinishedChange.asObservable();

    constructor(
        private $state: StateService,
        private ngbmodal: NgbModal,
        private AnalyticsService: AnalyticsService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public openPracticeTestModal() {
        this._openPracticeTestModal()
            .then((trUid) => {
                this.practiceFinishedChange.next({ trUid: trUid, isStateChange: true });
                this.$state.go('report.test', { trUid: trUid });
            })
            .catch((trUid) => {
                this.practiceFinishedChange.next({ trUid: trUid });
            });
    }

    // Cannot be defined in ModalService because of cyclic modal dependency in the component definition
    private _openPracticeTestModal() {
        this.AnalyticsService.action({ action: 'open_practice_test_modal' });

        return this.ngbmodal.open(PracticeTestModalComponent, {
            windowClass: 'practice-test-modal',
            size: 'lg',
            keyboard: false,
            backdrop: 'static',
        }).result;
    }

    public loadQuestion(question, option = {}) {
        this.AssessmentStateService.data.multi = {
            current: question.stems.length + 1,
            total: question.stems.length,
            isPartAnswered: false,
        };
        this.AssessmentStateService.data.question = question;

        return this._openQuestionViewModal(question, option);
    }

    private _openQuestionViewModal(questionId, option) {
        this.AnalyticsService.assessment({
            action: 'review_question_modal',
            question: questionId,
        });

        const modalRef = this.ngbmodal.open(QuestionViewModalComponent, {
            windowClass: 'question-view-modal',
            size: 'lg',
        });
        modalRef.componentInstance.option = option;

        return modalRef.result;
    }

    public openTestInstructionsModal(testInfo) {
        this._openTestInstructionsModal()
            .then(trid => this.openAssessmentModal(trid))
            .catch(() => {});
    }

    private _openTestInstructionsModal() {
        this.AnalyticsService.assessment({ action: 'open_assessment_instructions_modal' });

        return this.ngbmodal.open(TestInstructionsModalComponent, {
            windowClass: 'test-instructions-modal',
            size: 'lg',
        }).result;
    }

    private openAssessmentModal(trid) {
        this._openAssessmentModal(trid)
            .then(submitType => this.openAssessmentFinalizeModal(submitType))
            .catch(() => {});
    }

    private _openAssessmentModal(trid) {
        this.AnalyticsService.assessment({ action: 'open_cluster_assessment_modal', trid: trid });

        const modalRef = this.ngbmodal.open(ClusterTestModalComponent, <any>{
            windowClass: 'assessment-modal',
            size: 'assessment',
            keyboard: false,
            backdrop: 'static',
        });
        modalRef.componentInstance.trid = trid;

        return modalRef.result;
    }

    private openAssessmentFinalizeModal(submitType) {
        // If we are not previewing open finalize modal and display survey if available
        if (submitType !== 2) {
            this._openAssessmentFinalizeModal()
                .catch(() => {})
                .finally(() => this.AssessmentStateService.resetAssessment());
        } else {
            this.AssessmentStateService.resetAssessment();
        }
    }

    private _openAssessmentFinalizeModal() {
        const modalRef = this.ngbmodal.open(TestFinalizeModalComponent, {
            windowClass: 'assessment-finalize-modal',
            size: 'lg',
            keyboard: false,
            backdrop: 'static',
        });

        return modalRef.result;
    }

    public openReviseRevealModal(qIndex, questionBreakdown) {
        this.AnalyticsService.action({
            action: 'open_revise_reveal_modal',
            q_seq: qIndex
        });

        const modalRef = this.ngbmodal.open(ReviseRevealModalComponent, <any>{
            windowClass: 'revise-reveal-modal',
            size: 'assessment',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.qIndex = qIndex;
        modalRef.componentInstance.questions = questionBreakdown.question_scores;
        modalRef.componentInstance.hasEditPermission = questionBreakdown.is_editable;

        return modalRef.result;
    }
}
