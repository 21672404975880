<div class="sequencer-container page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2 class="page-title">Courses</h2>
        <button class="btn primary-button upcase sequencer-add-course-button" uiSref="sequencer.newCourse" *ngIf="dropdownModels.school?.admin">Create New Course</button>
    </div>

    <div class="hr medium-size"></div>

    <div class="flex-row justify-start align-items-center space-children-row big-space">
        <div class="filter-group body-regular flex-column flex-none">
            <label for="school-year" class="bold">School year</label>
            <ng-select  class="custom body-regular forced-width"
                        [items]="schoolYears"
                        appendTo="body"
                        bindLabel="name"
                        bindValue="id"
                        [clearable]="false"
                        groupBy="school_name"
                        [searchable]="false"
                        [(ngModel)]="dropdownModels.schoolYear"
                        [disabled]="!schoolYears"
                        (change)="getCourses($event)"
                        name="schoolYear"
                        placeholder="Select a school year"
                        required>
            </ng-select>
        </div>
    </div>
</div>

<div class="sequencer-container page-content bottom container-fluid">
    <div class="flex-column justify-start align-items-start space-children-column" *ngIf="courses?.length === 0">
        <span>No courses have been set up yet.</span>
    </div>

    <div class="flex-row flex-wrap justify-start">
        <div *ngFor="let course of courses" class="sequencer-course-list flex-auto">

            <div class="course-container" *ngIf="!course.deleting">
                <div class="sequencer-course-list--header flex-row justify-space-between align-items-center">
                    <button class="btn btn-link" [ngClass]="{'course-inactive': !dropdownModels.school?.admin && !course.teacher}" (click)="navigateToCourseDetail(course)">
                        <span>{{ course.code }}</span>
                        <span *ngIf="course.name"> - {{ course.name }}</span>
                    </button>

                    <span class="flex"></span>
                    <!-- Course Actions Trigger Button -->
                    <button class="btn no-bg" *ngIf="SessionService.userAccess?.teacher_roles?.length" [matMenuTriggerFor]="courseActionsMenu">
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <!-- Course Actions Trigger Menu -->
                    <mat-menu #courseActionsMenu="matMenu" panelClass="actions-popover" xPosition="before">
                        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="editCourse(course)">Edit Course</button>
                        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="editCoursePermissions(course)">Edit Permissions</button>
                        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="addSection(course)">Add Section</button>
                        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="deleteCourse(course)">Delete Course</button>
                    </mat-menu>
                </div>

                <div class="sequencer-course-list--body">
                    <div class="flex-row no-section" *ngIf="course.sections?.length === 0">
                        <span>No sections found.</span>
                    </div>

                    <div class="section-list flex-row justify-space-between align-items-center" *ngFor="let section of course.sections">
                        <div *ngIf="!section.deleting" class="flex-row justify-space-between align-items-center">
                            <button class="section-name btn btn-link" (click)="navigateToSectionDetail(section)">
                                <span [ngClass]="{'section-name-inactive': !dropdownModels.school?.admin && !section.teacher && SessionService.userAccess?.teacher_roles?.length === 0}">{{ section.code }} - {{ section.name }}</span>
                            </button>

                            <span class="flex"></span>
                            <!-- Section Actions Trigger Button -->
                            <button class="btn no-bg" *ngIf="section.teacher || SessionService.userAccess?.permission >= 16 || SessionService.userAccess?.teacher_roles?.length" [matMenuTriggerFor]="sectionActionsMenu">
                                <i class="fas fa-ellipsis-h"></i>
                            </button>
                            <!-- Section Actions Trigger Menu -->
                            <mat-menu #sectionActionsMenu="matMenu" panelClass="actions-popover" xPosition="before">
                                <div class="section-actions">
                                    <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="editSection(section)">Edit Section</button>
                                </div>
                                <div class="section-actions" *ngIf="section.admin">
                                    <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="duplicateSection(course, section)">Duplicate Section</button>
                                    <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="deleteSection(section)">Delete Section</button>
                                </div>
                            </mat-menu>

                        </div>

                        <div *ngIf="section.deleting" class="flex-row justify-space-between align-items-center">
                            <i>Deleted</i>
                            <button class="btn btn-link text-info" (click)="undoSectionDelete(section)">Undo</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="course-container" *ngIf="course.deleting">
                <div class="sequencer-course-list--header flex-row justify-space-between align-items-center">
                    <i class="button-padding">Deleted</i>
                    <button class="btn btn-link text-info" (click)="undoCourseDelete(course)">Undo</button>
                </div>
            </div>
        </div>
    </div>
</div>
