<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h4 class="modal-title">What would you like to contact us about?</h4>
</div>

<div class="modal-body" [ngSwitch]="form.request_type">
    <div *ngSwitchDefault class="flex-column align-items-start space-children-column big-space">
        <div (click)="goToRequest(4)" class="flex-none align-items-center">
            <i class="fas fa-fw fa-bug"></i>
            <button class="btn btn-link">Report a bug</button>
        </div>

        <div (click)="goToRequest(5)" class="flex-none align-items-center">
            <i class="fas fa-fw fa-gift"></i>
            <button class="btn btn-link">Request a new feature</button>
        </div>

        <div (click)="goToRequest(6)" class="flex-none align-items-center">
            <i class="far fa-fw fa-star"></i>
            <button class="btn btn-link">Suggest a feature improvement</button>
        </div>

        <div (click)="goToRequest(7)" class="flex-none align-items-center">
            <i class="fas fa-fw fa-exclamation"></i>
            <button class="btn btn-link">Report a test issue</button>
        </div>

        <div (click)="goToRequest(8)" class="flex-none align-items-center">
            <i class="far fa-fw fa-flag"></i>
            <button class="btn btn-link">Report an item issue</button>
        </div>

        <div (click)="goToRequest(1)" class="flex-none align-items-center">
            <i class="far fa-fw fa-question-circle"></i>
            <button class="btn btn-link">Other/General help</button>
        </div>
    </div>

    <div *ngSwitchCase="4" class="flex-column space-children-column">
        <form name="reportBugForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="fas fa-fw fa-bug"></i>
                <span>Report a bug</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>What page in the site has the bug?</mat-label>
                    <input matInput type="text" id="form-summary" [(ngModel)]="form.request_data.summary" name="formSummary" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>What is the bug?</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription"
                                required>
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Steps to reproduce (optional)</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-steps"
                                [(ngModel)]="form.request_data.customfield_10500"
                                name="formSteps">
                    </textarea>
                    <mat-hint>If you think a specific action or set of actions caused the bug to appear, please describe them here.</mat-hint>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-2em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>
                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>

    <div *ngSwitchCase="5" class="flex-column space-children-column">
        <form name="newFeatureForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="fas fa-fw fa-gift"></i>
                <span>Request a new feature</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Please describe the new feature</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription"
                                required>
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-1em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>

                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>

    <div *ngSwitchCase="6" class="flex-column space-children-column">
        <form name="featureImprovForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="far fa-fw fa-star"></i>
                <span>Suggest a feature improvement</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" placeholder="" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>What feature could be improved?</mat-label>
                    <input matInput type="text" id="form-summary" [(ngModel)]="form.request_data.summary" name="formSummary" placeholder="" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Please describe how this feature could be improved upon</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription">
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-1em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>

                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>

    <div *ngSwitchCase="7" class="flex-column space-children-column">
        <form name="testIssueForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="fas fa-fw fa-exclamation"></i>
                <span>Report a test issue</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" placeholder="" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>What test has the issue?</mat-label>
                    <input matInput type="text" id="form-summary" [(ngModel)]="form.request_data.summary" name="formSummary" placeholder="" required>
                    <mat-hint>Please identify the test by Cluster, Gradeband, test form, or any other identifier.</mat-hint>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none margin-top-1em">
                <label class="label-control">What type of issue is it?</label>
                <mat-radio-group [(ngModel)]="form.option.customfield_10300" name="formselection" class="radio-group-column" required>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-1" value="10200">Test not available</mat-radio-button>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-2" value="10201">Items don't match test cluster</mat-radio-button>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-3" value="10202">Other</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Describe the issue (if "Other" above)</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription">
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-1em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>

                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>

    <div *ngSwitchCase="8" class="flex-column space-children-column">
        <form name="itemIssueForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="far fa-fw fa-flag"></i>
                <span>Report an item issue</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Describe the item so we can locate it</mat-label>
                    <input matInput type="text" id="form-summary" [(ngModel)]="form.request_data.summary" name="formSummary" required>
                    <mat-hint>context, mathematics, cluster, or test it appeared on</mat-hint>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none margin-top-1em">
                <label class="label-control">What type of issue is it?</label>
                <mat-radio-group [(ngModel)]="form.option.customfield_10301" name="formselection" class="radio-group-column" required>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-1" value="10203">Answer key is wrong</mat-radio-button>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-2" value="10204">Wording is incorrect</mat-radio-button>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-3" value="10205">Image is incorrect</mat-radio-button>
                    <mat-radio-button class="radio-button-column body-regular" id="form-choice-4" value="10206">Other</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Describe the issue (if "Other" above)</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription">
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-1em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>

                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>

    <div *ngSwitchCase="1" class="flex-column space-children-column">
        <form name="itemIssueForm" class="flex-column" novalidate>
            <div class="justify-center flex-none align-items-center space-children-row">
                <i class="far fa-fw fa-question-circle"></i>
                <span>Other/General help</span>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" id="form-email" [(ngModel)]="form.email" name="formEmail" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>How can we help?</mat-label>
                    <input matInput type="text" id="form-summary" [(ngModel)]="form.request_data.summary" name="formSummary" required>
                </mat-form-field>
            </div>

            <div class="flex-column flex-none">
                <mat-form-field appearance="standard">
                    <mat-label>Describe the issue (optional)</mat-label>
                    <textarea   matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="5"
                                type="text"
                                id="form-description"
                                [(ngModel)]="form.request_data.description"
                                name="formDescription">
                    </textarea>
                </mat-form-field>
            </div>

            <div class="flex-none align-items-center justify-center space-children-row margin-top-1em">
                <button class="btn ghost-button body-regular" (click)="returnToMainMenu()">Return to Selection</button>

                <button class="btn primary-button body-regular" type="submit" (click)="submitTicket()">Submit</button>
            </div>
        </form>
    </div>
</div>
