import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { SessionService } from '../service/session.service';
import { UtilsService } from '../service/utils.service';

import './nav.component.scss';


@Component({
    selector: 'global-nav',
    templateUrl: './nav.component.html',
    // styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {

    @Input() public route: any;

    public navIsOpen = false;
    public isSubnavOpen = {
        sequencer: false,
    };
    private clickHandler = this.checkOutsideClick.bind(this);
    private toggleSub: Subscription;

    constructor(
        public sessionService: SessionService,
        private UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
        this.toggleSub = this.UtilsService.isNavOpen$.subscribe(shouldOpen => this.toggleNav(shouldOpen));
    }

    public ngOnDestroy() {
        this.toggleSub.unsubscribe();
    }

    public toggleNav(shouldOpen) {
        const currentState = this.navIsOpen;
        this.navIsOpen = shouldOpen;

        if (shouldOpen) {
            // If we're not attempting to open when it's already open
            if (currentState !== shouldOpen) {
                document.addEventListener('click', this.clickHandler);
            }
        } else {
            document.removeEventListener('click', this.clickHandler);
            this.toggleSubmenu(); // Collapse all open submenus
        }
    }

    public mobileTap(event) {
        // If sidenav is already open ignore this event
        if (this.navIsOpen) {
            return null;
        }
        // Prevent default behavior such as :hover events from tapping on a sidenav item
        event.preventDefault();
        // Stop state change from ui-sref click
        event.stopPropagation();
        this.UtilsService.isNavOpenSource.next(true);
    }

    public toggleSubmenu(submenu = null) {
        // Open the selected submenu (or close if already open)
        if (submenu) {
            // Make sure sidebar is open when toggling a submenu
            this.UtilsService.isNavOpenSource.next(true);
            this.isSubnavOpen[submenu] = !this.isSubnavOpen[submenu];
        }
        // Collapse all other submenus
        for (const key in this.isSubnavOpen) {
            if (key !== submenu) {
                this.isSubnavOpen[key] = false;
            }
        }
    }

    private checkOutsideClick(event) {
        // If we clicked outside of sidenav
        if (!event.target.closest('#sidenav')) {
            this.UtilsService.isNavOpenSource.next(false);
        }
    }
}
