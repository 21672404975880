import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';

import { select } from 'd3-selection';
import { interpolate as d3Interpolate } from 'd3-interpolate';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';

import { UtilsService } from '../../../../core/service/utils.service';

import './cumulative-score.component.scss';


@Component({
    selector: '[construct-cumulative-score]',
    templateUrl: './cumulative-score.component.svg.html',
    // styleUrls: ['./cumulative-score.component.scss'],
    host: {
        class: 'construct-cumulative-score',
    },
})
export class CumulativeScoreComponent implements AfterViewInit {

    @Input() construct: any;
    @Input() score: any;
    private stacks: any;
    private RADIAN = 180 / Math.PI;
    private ANIM_DURATION = 750;
    private ANIM_DELAY = 800;

    private rootElement;

    private constructRadius: number;
    private gradeRadius: number;
    private stackRadius: number;

    private d3GradeArc: any;
    private d3GradePie: any;
    private d3StackArc: any;
    private d3StackPie: any;

    public constructor(
        private element: ElementRef,
        public UtilsService: UtilsService,
    ) {}

    public ngAfterViewInit() {
        if (!this.score) {
            // This will gray out the constructs in report page when there is no score
            this.element.nativeElement.parentNode.classList.add('disabled-construct');

            return false;
        }

        this.rootElement = select(this.element.nativeElement);

        // Sort construct stacks ascending
        this.stacks = [...this.construct.stacks].sort((a, b) => a.order - b.order);

        let gradesWithAllLevels = this.groupStacksByGrade(this.stacks);

        // Initialize the d3 chart
        this.initArcs();
        this.renderGradeGroup(gradesWithAllLevels);
    }

    private groupStacksByGrade(stacks) {
        let gradesMap = {};
        let gradesList = [];

        stacks.forEach(stack => {
            let grade = stack.grade ? stack.grade[stack.grade.length - 1] : 'N/A';
            (gradesMap[grade] = gradesMap[grade] || []).push(stack);
        });

        // Turn gradesMap object into an array
        for (const grade in gradesMap) {
            let stacks = gradesMap[grade];
            let gradeText = isNaN(Number(grade)) ? grade : ('Gr' + grade);
            let gradeGroup = { id: grade, displayText: gradeText, stacks: stacks };
            gradesList.push(gradeGroup);
        }

        return gradesList;
    }

    private initArcs() {
        this.constructRadius = this.construct.radius * 0.35;
        this.gradeRadius = this.construct.radius * 0.62;
        this.stackRadius = this.construct.radius;

        // Initialize element
        this.rootElement.select('.construct-circle')
            .attr('r', this.constructRadius);

        // Initialize arc and pie helper functions
        this.d3GradeArc = d3Arc()
            .outerRadius(this.gradeRadius)
            .innerRadius(this.constructRadius)
            .padAngle(this.stacks.length <= 10 ? 0.07 : 0.04);

        this.d3GradePie = d3Pie()
            .sort((a: any, b: any) => a.stacks[0].order - b.stacks[0].order)
            .value((d: any) => d.stacks.length)
            .startAngle(0)
            .endAngle(2 * Math.PI);

        this.d3StackArc = d3Arc()
            .outerRadius(this.stackRadius)
            .innerRadius(this.gradeRadius)
            .padAngle(this.stacks.length <= 10 ? 0.07 : 0.04);
    }

    private renderGradeGroup(grades) {
        let gradeArcTween = (newAngles) => {
            let interpolate = d3Interpolate({value: newAngles.previousValue, startAngle: newAngles.startAngle, endAngle: newAngles.endAngle}, newAngles);
            return (t) => this.d3GradeArc(interpolate(t));
        }

        let newGradeArcTween = (newAngles) => {
            let midAngle = (newAngles.startAngle + newAngles.endAngle) / 2;
            let interpolate = d3Interpolate({startAngle: midAngle, endAngle: midAngle}, newAngles);
            return (t) => this.d3GradeArc(interpolate(t));
        }

        let removeGradeArcTween = (d) => {
            let midAngle = (d.startAngle + d.endAngle) / 2;
            let interpolate = d3Interpolate(d, {startAngle: midAngle, endAngle: midAngle});
            return (t) => this.d3GradeArc(interpolate(t));
        }

        let getGradeTextCenter = (d) => {
            let midAngle = (d.endAngle + d.startAngle) / 2 * this.RADIAN;
            return 'translate(' + this.d3GradeArc.centroid(d) + ')rotate(' + midAngle + ')';
        }

        let getGradeText = (d) => {
            return d.data.displayText;
        }

        // Select group with donut data
        let gradeArc = this.rootElement.select('.grade-group').selectAll('.grade-arc')
            .each(d => this.storeCurrentData(d))
            .data(this.d3GradePie(grades));

        // Update actions (existing elements)
        gradeArc.select('.grade-arc-path')
            .transition()
            .duration(this.ANIM_DURATION)
            .attrTween('d', gradeArcTween);

        gradeArc.select('.grade-text')
            .text(getGradeText)
            .transition()
            .duration(this.ANIM_DURATION)
            .attr('transform', getGradeTextCenter);

        gradeArc.each(this.renderStackGroup.bind(this));

        // Enter action (new elements)
        let gradeEnter = gradeArc.enter().append('g')
            .classed('grade-arc', true)
            .each(this.generateNewStackGroup.bind(this));

        gradeEnter.append('path')
            .attr('id', (d: any) => {
                return `path-${d.data.displayText}`;
            })
            .classed('grade-arc-path', true)
            .classed('grade-background', true)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION)
            .attrTween('d', newGradeArcTween);

        gradeEnter.append('text')
            .attr('dy', '0.35em')
            .classed('grade-text', true)
            .text(getGradeText)
            .style('fill-opacity', 0)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION)
            .attr('transform', getGradeTextCenter)
            .style('fill-opacity', 1);


        // Exit action (elements removed from data array)
        let gradeExit = gradeArc.exit();

        gradeExit.select('.grade-arc-path')
            .transition()
            .duration(this.ANIM_DURATION)
            .attrTween('d', removeGradeArcTween);

        gradeExit.select('.grade-text')
            .transition()
            .duration(this.ANIM_DURATION)
            .style('fill-opacity', 0);

        gradeExit
            .transition()
            .duration(this.ANIM_DURATION)
            .style('fill-opacity', 0)
            .remove();
    }

    private storeCurrentData(d) {
        d.previousValue = d.value;
        d.previousStartAngle = d.startAngle;
        d.previousEndAngle = d.endAngle;
    }

    private generateNewStackGroup(d, i, nodes) {
        let gradeEnter = select(nodes[i]);
        gradeEnter.append('g')
            .classed('stack-group', true);

        gradeEnter.each(this.renderStackGroup.bind(this));
    }

    private renderStackGroup(d, i, nodes) {
        let stacks = d.data.stacks;
        let gradeGroup = select(nodes[i]);

        let stackArcTween = (newAngles) => {
            let interpolate = d3Interpolate({value: newAngles.previousValue, startAngle: newAngles.startAngle, endAngle: newAngles.endAngle}, newAngles);
            return (t) => this.d3StackArc(interpolate(t));
        }

        let newStackArcTween = (newAngles) => {
            let midAngle = (newAngles.startAngle + newAngles.endAngle) / 2;
            let interpolate = d3Interpolate({startAngle: midAngle, endAngle: midAngle}, newAngles);
            return (t) => this.d3StackArc(interpolate(t));
        }

        let getStackTextCenter = (d) => {
            let midAngle = (d.endAngle + d.startAngle) / 2 * this.RADIAN;
            return 'translate(' + this.d3StackArc.centroid(d) + ')rotate(' + midAngle + ')';
        }

        let getStackText = (d) => {
            return 'L' + (d.data.order);
        }

        let getStackClasses = (d) => {
            let classes = 'white-fill stack-arc-path';
            if (this.score) {
                let stackScores = this.score.stack_scores[d.data.id];
                if (!stackScores) { return classes; }

                let percent = Math.round(stackScores.points / stackScores.total * 100);
                classes += percent > 0 ? ' background-correct' : '';
                classes += ' ' + this.UtilsService.getOpacityLevel(percent);
            }
            return classes;
        }

        // Create stack paths within the grade angle boundaries
        this.d3StackPie = d3Pie()
            .sort(null)
            .value(d => { return 1; })
            .startAngle(d.startAngle)
            .endAngle(d.endAngle);

        // Select group with stack data within the grade group
        let stackArc = gradeGroup.select('.stack-group')
            .selectAll('.stack-arc')
            .each(d => this.storeCurrentData(d))
            .data(this.d3StackPie(stacks));

        // Update actions (existing elements)
        stackArc.select('.stack-arc-path')
            .attr('class', getStackClasses)
            .transition()
            .duration(this.ANIM_DURATION)
            .attrTween('d', stackArcTween);

        stackArc.select('.stack-level-text')
            .text(getStackText)
            .transition()
            .duration(this.ANIM_DURATION)
            .attr('transform', getStackTextCenter);

        // Enter action (new elements)
        let stackEnter = stackArc.enter().append('g')
            .classed('stack-arc', true);

        stackEnter.append('path')
            .attr('id', (d: any) => {
                return `path-${d.data.id}`;
            })
            .attr('class', getStackClasses)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION)
            .attrTween('d', newStackArcTween);

        stackEnter.append('text')
            .text(getStackText)
            .attr('dy', '0.35em')
            .classed('stack-level-text', true)
            .style('fill-opacity', 0)
            .transition()
            .delay(this.ANIM_DELAY)
            .duration(this.ANIM_DURATION)
            .attr('transform', getStackTextCenter)
            .style('fill-opacity', 1);

        // Exit action (elements removed from data array)
        stackArc.exit().remove();
    }
}
