import { Component, Input } from '@angular/core';

import { SequencerService } from '../../sequencer.service';
import { SequencerStateService } from '../../sequencer-state.service';


@Component({
    selector: 'calendar-day-front-row',
    templateUrl: './calendar-day-front-row.component.html'
})
export class CalendarDayFrontRowComponent {
    @Input() weekEvents: any;

    constructor(
        public SequencerService: SequencerService,
        public SequencerStateService: SequencerStateService,
    ) {}

}
