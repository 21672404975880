<div class="modal-header">
    <span>{{ instructions }}</span>
</div>

<div class="modal-body">
    <div class="selected-standards-container" *ngIf="resource">
        <ng-select  class="flex custom"
                    [items]="standardsList"
                    appendTo="body"
                    bindLabel="name"
                    [closeOnSelect]="false"
                    [hideSelected]="true"
                    [multiple]="true"
                    [searchable]="true"
                    [(ngModel)]="resource.standards"
                    [disabled]="isStandardsDisabled"
                    name="standards"
                    placeholder="Select one or more standards"
                    (change)="updateDisabledStatus()"
                    required>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span>{{ item.name }} - {{ UtilsService.stripHTMLTags(item.description) }}</span>
            </ng-template>
        </ng-select>
    </div>

    <div class="flex-row flex-wrap">
        <div class="flex-50 map-tagging-field" *ngFor="let field of fields">
            <mat-accordion [multi]="false">
                <mat-expansion-panel *ngFor="let region of field.regions">
                    <mat-expansion-panel-header class="region-header" [ngClass]="['region-background-' + region.id]">
                        <mat-panel-title [innerHTML]="region.short_name"></mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="region-body" [ngClass]="['region-color-' + region.id]">
                        <div *ngFor="let cluster of region.clusters" class="cluster-container">
                            <div class="cluster-header flex-row align-items-center justify-space-between space-children-row">
                                <div (click)="cluster.isOpen = !cluster.isOpen;" class="cursor-pointer flex align-items-center space-children-row small-space">
                                    <i class="icon icon-plus" [ngClass]="{'icon-minus': cluster.isOpen}"></i>
                                    <span [innerHTML]="cluster.name"></span>
                                </div>

                                <mat-checkbox
                                    class="example-margin"
                                    [(ngModel)]="cluster.isSelected"
                                    [(indeterminate)]="cluster.indeterminate"
                                    labelPosition="before"
                                    [disabled]="isMapCheckboxDisabled"
                                    (change)="toggleConstructsSelection(cluster, true)">
                                </mat-checkbox>
                            </div>

                            <div class="cluster-body" *ngIf="cluster.isOpen">
                                <div *ngFor="let construct of cluster.constructs" class="construct-container flex-row align-items-center justify-space-between space-children-row">
                                    <label for="tag-construct-{{ construct.id }}-input" [innerHTML]="construct.name"></label>
                                    <mat-checkbox
                                        class="example-margin"
                                        id="tag-construct-{{ construct.id }}"
                                        [(ngModel)]="construct.isSelected"
                                        [(indeterminate)]="construct.indeterminate"
                                        labelPosition="before"
                                        [disabled]="isMapCheckboxDisabled"
                                        (change)="updateClusterIndeterminateStatus(cluster, true)">
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<div class="modal-footer flex-row justify-center space-children-row">
    <button class="btn ghost-button" (click)="activeModal.dismiss()">Cancel</button>
    <button class="btn primary-button" (click)="activeModal.close({ fields: fields, isMapCheckboxDisabled: isMapCheckboxDisabled })">Apply Changes</button>
</div>
