<div class="modal-header">
    <h4 class="modal-title limit-text">Students Practice Activity</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body">
    <div class="table-component">
        <!-- Table Header -->
        <div class="flex-row justify-center">
            <div class="table-header cursor-pointer flex-25 align-items-center space-children-row" (click)="sortBy('user_name')">
                <span>Name</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'user_name' && sortReverse, 'opacity-25': sortExpression !== 'user_name' }"></i>
            </div>

            <!-- <div class="table-header cursor-pointer flex-40 align-items-center justify-space-between space-children-row" (click)="sortBy('submittedMsg')">
                <span>Last Practice Date</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'submittedMsg' && sortReverse, 'opacity-25': sortExpression !== 'submittedMsg' }"></i>
            </div> -->

            <div class="table-header cursor-pointer flex-25 align-items-center justify-center space-children-row" (click)="sortBy('tests')">
                <span>Total Sessions</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'tests' && sortReverse, 'opacity-25': sortExpression !== 'tests' }"></i>
            </div>

            <div class="table-header cursor-pointer flex-25 align-items-center justify-center space-children-row" matTooltip="Success Score - Sum of all the points divided by the total number of problems worked during practice." matTooltipPosition="above" (click)="sortBy('ss')">
                <span>SS <i class="icon icon-info text-info"></i></span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'ss' && sortReverse, 'opacity-25': sortExpression !== 'ss' }"></i>
            </div>

            <div class="table-header cursor-pointer flex-25 align-items-center justify-center space-children-row" matTooltip="Worked Problems Index - Sum of all practice problems this student has worked during the school year with additional credit added for working harder levels." matTooltipPosition="above" (click)="sortBy('wpi')">
                <span>WPI <i class="icon icon-info text-info"></i></span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'icon-sort-amount-desc': sortExpression === 'wpi' && sortReverse, 'opacity-25': sortExpression !== 'wpi' }"></i>
            </div>
        </div>
        <!-- Table Rows -->
        <div class="table-body">
            <div class="flex-row justify-center" *ngFor="let student of students; even as isEven" [class.offwhite-pattern]="isEven">
                <div class="table-data flex-column flex-25 justify-center bold">{{ student.user_name }}</div>

                <!-- <div class="table-data flex-column flex-40 justify-center text-center">
                    <span [innerHTML]="getSubmittedMsg(student)"></span>
                </div> -->

                <div class="table-data flex-column flex-25 justify-center text-center">{{ student.tests || 0 }}</div>

                <div class="table-data flex-column flex-25 justify-center text-center">{{ (student.ss || student.ss === 0) ? student.ss.toFixed() + '%' : '&mdash;' }}</div>

                <div class="table-data flex-column flex-25 justify-center text-center">{{ student.wpi || '&mdash;' }}</div>
            </div>
        </div>
    </div>
</div>
