<div class="flex-column school-report-graph-options dark-theme" [class.open]="navIsOpen">
    <div class="link-container flex-row align-items-center" (click)="toggleNav(!navIsOpen)">
        <div class="icon-container flex-none justify-center align-items-center">
            <i class="icon icon-cog"></i>
        </div>
        <span class="navigation-text">Graph Options</span>
    </div>

    <ng-container *ngFor="let option of graphOptions; last as isLast">
        <div class="sublink space-children-row cursor-pointer" (click)="toggleOption(option)">
            <span *ngIf="option.selected" class="text-accent"><i class="fas fa-check"></i></span>
            <span>{{ option.name }}</span>
        </div>

        <!-- <div *ngIf="!isLast" class="hr group-separator" [class.slide-in]="navIsOpen"></div> -->
    </ng-container>
</div>
