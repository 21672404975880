import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../../../../../core/service/utils.service';
import { CalendarService } from '../../calendar.service';


@Component({
    selector: 'new-note-modal',
    templateUrl: './new-note-modal.component.html'
})
export class NewNoteModalComponent {

    @Input() day: any;
    public content: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private UtilsService: UtilsService,
        private CalendarService: CalendarService,
    ) {}

    public submitNewNote() {
        if (this.content.trim().length === 0) return;

        let newNote = {
            content: this.content,
            date: this.day.formattedDate,
        };

        this.UtilsService.addLoadingOverlay(true);
        this.CalendarService.createCalendarNote(newNote)
            .then(createdNote => {
                this.day.notes.push(createdNote);
                this.activeModal.close(createdNote);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
