import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UtilsService } from '@/core/service/utils.service';
import { MapService } from '@/map/map.service';
import { SchoolReportService } from '@/report/school/school-report.service';

import './school-report-filters.component.scss';


@Component({
    selector: 'school-report-filters',
    templateUrl: 'school-report-filters.component.html',
    // styleUrls: ['./school-report-filters.component.scss'],
    host: {
        class: 'flex-column school-report-filters',
    }
})
export class SchoolReportFiltersComponent implements OnInit {

    public form: FormGroup;
    public isLoadingDistricts: boolean = false;
    public isLoadingClusters: boolean = false;
    public filtersInfo: any;
    public districts: any = [];
    public schoolYears: any = [];
    // Keep track of the school years associated with a unique label to be able to find
    // all unique teachers for the selected school year labels
    private schoolYearMap: any = {};
    public clusters: any = [];
    public constructs: any = [];
    public grades: any = [];
    private coursesMap: any = {};

    public x3Options = null;

    public multipleSchoolsSelected: boolean = false;

    public X2_AXIS_OPTIONS = [
        { id: 0, name: 'None', isDisabled: false, x3Options: null },
        { id: 1, name: 'Teacher', isDisabled: false, x3Options: [
            this.SchoolReportService.X3_AXIS_OPTIONS[0],
            this.SchoolReportService.X3_AXIS_OPTIONS[5],
        ]},
        { id: 2, name: 'Course', isDisabled: false, x3Options: [
            this.SchoolReportService.X3_AXIS_OPTIONS[0],
            this.SchoolReportService.X3_AXIS_OPTIONS[5],
        ]},
        { id: 3, name: 'Grade Level', isDisabled: false, x3Options: [
            this.SchoolReportService.X3_AXIS_OPTIONS[0],
            this.SchoolReportService.X3_AXIS_OPTIONS[5],
        ]},
        { id: 4, name: 'Cluster', isDisabled: false, x3Options: [
            this.SchoolReportService.X3_AXIS_OPTIONS[0],
            this.SchoolReportService.X3_AXIS_OPTIONS[1],
            this.SchoolReportService.X3_AXIS_OPTIONS[3],
            this.SchoolReportService.X3_AXIS_OPTIONS[5],
        ]},
        { id: 5, name: 'Activity', isDisabled: false, x3Options: [
            this.SchoolReportService.X3_AXIS_OPTIONS[0],
            this.SchoolReportService.X3_AXIS_OPTIONS[6],
        ]},
    ];

    public maxPerformanceSelections: number = 5;

    public GRADE_OPTIONS = [
        { id: 10, name: 'Grade 6' },
        { id: 11, name: 'Grade 7' },
        { id: 12, name: 'Grade 8' },
    ];

    constructor(
        private fb: FormBuilder,
        public UtilsService: UtilsService,
        private MapService: MapService,
        public SchoolReportService: SchoolReportService,
    ) {
        this.form = this.fb.group({
            user_type:           [null,                            Validators.required],
            graph_type:          [this.SchoolReportService.GRAPH_TYPE_OPTIONS[0].id,   Validators.required],
            districts:           [{ value: [], disabled: true },   Validators.required],
            schools:             [{ value: [], disabled: true },   Validators.required],
            school_years:        [{ value: [], disabled: true },   Validators.required],
            y_axis:              [this.SchoolReportService.Y_AXIS_OPTIONS[0].id,       Validators.required],
            x_axis:              [this.SchoolReportService.X_AXIS_OPTIONS[0].id,       Validators.required],
            x2:                  [this.X2_AXIS_OPTIONS[0].id,      Validators.required],
            x3:                  [{ value: null, disabled: true }, Validators.required],
            g_type:              [{ value: this.SchoolReportService.GRAPH_TYPE_VARIANT[0].id, disabled: false }],
            cluster:             [{ value: null, disabled: false }],
            construct:           [{ value: null, disabled: false }],
            graph_option:        [{ value: this.SchoolReportService.GRAPH_TYPE_OPTIONS_SECONDARY[0].id, disabled: false }],
            grades:              [{ value: [], disabled: false }],
            courses:             [{ value: [], disabled: false }],
        }, {
            validators: [
                // DateValidators.dateLessThan('start_date', 'end_date', { 'datelt': true }),
            ],
        });
    }

    public ngOnInit() {
        this.isLoadingDistricts = true;
        this.SchoolReportService.getSchoolReportFiltersInfo()
            .then(filtersInfo => {
                this.filtersInfo = filtersInfo;
                this.form.controls.user_type.setValue(this.filtersInfo.user_type);

                // Set filters to default settings (basic usage chart)
                this.setDefaultFilterSelection();

                if (this.form.valid) {
                    this.fetchGraphData();
                }
            })
            .catch(console.warn)
            .finally(() => this.isLoadingDistricts = false);

        this.isLoadingClusters = true;
        this.MapService.getLearningMap()
            .then(map => {
                // Trigger ng-select change detection
                this.clusters = map.clusters;
                this.form.controls.cluster.enable();
            })
            .catch(console.warn)
            .finally(() => this.isLoadingClusters = false);
    }

    private setDefaultFilterSelection() {
        this.districts = this.filtersInfo.districts;

        if (this.districts && this.districts.length) {
            this.form.controls.districts.setValue([this.districts[0].id]);

            // Enable district selection if user belongs to more than 1 district
            if (this.districts.length > 1) {
                this.form.controls.districts.enable();
            }

            this.SchoolReportService.schools = this.districts[0].schools;

            if (this.SchoolReportService.schools && this.SchoolReportService.schools.length) {
                this.form.controls.schools.enable();
                this.form.controls.schools.setValue([this.SchoolReportService.schools[0].id]);
                this.schoolsChanged([this.SchoolReportService.schools[0]]);

                if (this.schoolYears && this.schoolYears.length) {
                    this.form.controls.school_years.enable();
                    this.form.controls.school_years.setValue([this.schoolYears[0].name]);
                    this.schoolYearsChanged([this.schoolYears[0]]);
                }
            }
        }
    }

    public updateGraphType(buttonChange) {
        const yAxis = this.form.get('y_axis');
        const xAxis = this.form.get('x_axis');
        const x2 = this.form.get('x2');
        const x3 = this.form.get('x3');

        const gType = this.form.get('g_type');
        const graphOption = this.form.get('graph_option');
        const cluster = this.form.get('cluster');
        const grades = this.form.get('grades');
        const courses = this.form.get('courses');

        yAxis.setValidators(   buttonChange.value === 1 ? [Validators.required] : null);
        yAxis[buttonChange.value === 1 ? 'enable' : 'disable']();
        xAxis.setValidators(   buttonChange.value === 1 ? [Validators.required] : null);
        xAxis[buttonChange.value === 1 ? 'enable' : 'disable']();
        x2.setValidators(      buttonChange.value === 1 ? [Validators.required] : null);
        x2[buttonChange.value === 1 ? 'enable' : 'disable']();
        x3.setValidators(      buttonChange.value === 1 ? [Validators.required] : null);
        x3[buttonChange.value === 1 ? 'enable' : 'disable']();

        graphOption.setValidators(   buttonChange.value === 2 ? [Validators.required] : null);
        graphOption[buttonChange.value === 2 ? 'enable' : 'disable']();
        cluster.setValidators( buttonChange.value === 2 ? [Validators.required] : null);
        cluster[buttonChange.value === 2 ? 'enable' : 'disable']();
        grades.setValidators(  buttonChange.value === 2 ? [Validators.required] : null);
        grades[buttonChange.value === 2 ? 'enable' : 'disable']();
        courses.setValidators( buttonChange.value === 2 ? [Validators.required] : null);
        courses[buttonChange.value === 2 ? 'enable' : 'disable']();

        yAxis.updateValueAndValidity();
        xAxis.updateValueAndValidity();
        x2.updateValueAndValidity();
        x3.updateValueAndValidity();

        graphOption.updateValueAndValidity();
        cluster.updateValueAndValidity();
        grades.updateValueAndValidity();
        courses.updateValueAndValidity();

        // If switching to performance toggle, select grade toggle and reset secondary validators
        if (buttonChange.value === 2) {
            graphOption.setValue(this.SchoolReportService.GRAPH_TYPE_OPTIONS_SECONDARY[0].id);
            this.updateGraphTypeSecondary({ value: graphOption.value });
        }

        // Update text
        this.SchoolReportService.graphTitle = (buttonChange.value === 1 ? 'Usage' : 'Performance') + ' Report';

        // Reset graph
        this.resetGraph();
    }

    // Grade or Course toggle
    public updateGraphTypeSecondary(buttonChange) {
        const grades = this.form.get('grades');
        const courses = this.form.get('courses');

        grades.setValidators(  buttonChange.value === 1 ? [Validators.required] : null);
        courses.setValidators( buttonChange.value === 2 ? [Validators.required] : null);

        grades[buttonChange.value === 1 ? 'enable' : 'disable']();
        courses[buttonChange.value === 2 ? 'enable' : 'disable']();

        grades.updateValueAndValidity();
        courses.updateValueAndValidity();

        // Reset graph
        this.resetGraph();
    }

    // Districts changed
    public districtsChanged(districts) {
        if (districts.length || districts.length === 0) {
            // Reset previous school selection
            this.resetNestedSelection({ depth: 1 });

            // Get schools from the selected districts
            districts.forEach(district => {
                this.SchoolReportService.schools = this.SchoolReportService.schools.concat(district.schools);
            });
        }
        
        // Reset graph
        this.resetGraph();
    }

    // Schools changed
    public schoolsChanged(schools) {
        if (schools.length || schools.length === 0) {
            this.updateX3Value({ value: this.form.get('x2').value });
            this.multipleSchoolsSelected = schools.length >= 2;

            // Reset previous school year selection
            this.resetNestedSelection({ depth: 2 });

            // Get unique school years
            schools.forEach(school => {
                school.school_years.forEach(year => {
                    const existingYearLabel = this.schoolYears.find(y => y.name === year.name);

                    if (existingYearLabel) {
                        this.schoolYearMap[year.name].push(year);
                    } else {
                        this.schoolYears.push(year);
                        this.schoolYearMap[year.name] = [year];
                    }
                });
            });

            // Sort school years descending
            this.schoolYears.sort((a, b) => a.name > b.name ? -1 : 1);
        }
        
        // Reset graph
        this.resetGraph();
    }

    // School years changed
    public schoolYearsChanged(schoolYears) {
        if (schoolYears.length || schoolYears.length === 0) {
            // Trigger y axis change method to disable monthly option if needed
            this.updateTimeOptions({ value: this.form.get('y_axis').value });

            // Reset previous course/grade selection
            this.resetNestedSelection({ depth: 3 });

            // Restrict multiple performance x3 if two multiple options are selected
            this.maxPerformanceSelections = (this.form.get('schools').value.length > 1 && schoolYears.length > 1) ? 1 : 5;

            // Get unique courses from each school year
            schoolYears.forEach(yearLabel => {
                const mappedYears = this.schoolYearMap[yearLabel.name];
                mappedYears.forEach(year => {
                    year.courses.forEach(course => {
                        const existingCourse = this.SchoolReportService.courses.find(c => c.code === course.code);

                        if (existingCourse) {
                            this.coursesMap[course.code].push(course);
                        } else {
                            this.SchoolReportService.courses.push(course);
                            this.coursesMap[course.code] = [course];
                        }
                    });
                });
            });

            // Sort courses alphabetically by name ascending
            this.SchoolReportService.courses.sort((a, b) => a.name <= b.name ? -1 : 1);
        }

        // Reset graph
        this.resetGraph();
    }

    // Y axis changed - Material select box change event
    public updateTimeOptions(yAxis) {
        const schools = this.form.get('schools').value;
        const sy = this.form.get('school_years').value;
        const x2Field = this.form.get('x2');

        this.SchoolReportService.X_AXIS_OPTIONS.forEach(option => {
            // 1: biweekly - 2: monthly - 3: yearly
            if (option.id === 1) {
                // Disable bi-weekly if donut chart is selected or 3+ school years on any chart
                option.isDisabled = yAxis.value === 2 || (sy && sy.length >= 3);
            } else if (option.id === 2) {
                // Disable monthly if 2+ school years only in donut charts
                option.isDisabled = yAxis.value === 2 && (sy && sy.length >= 2);
            }

            const xAxis = this.form.get('x_axis');

            if (option.isDisabled && xAxis.value === option.id) {
                xAxis.setValue(3); // If selected option is disabled switch to yearly which is always enabled
            }
        });

        // Disable x2 "none" option when donut chart is selected
        this.X2_AXIS_OPTIONS[0].isDisabled = yAxis.value === 2;

        // Disable N/A x2 options when % is selected and it is multi school
        this.X2_AXIS_OPTIONS[1].isDisabled = yAxis.value === 3 && schools.length >= 2;
        this.X2_AXIS_OPTIONS[2].isDisabled = yAxis.value === 3 && schools.length >= 2;
        this.X2_AXIS_OPTIONS[4].isDisabled = yAxis.value === 3 && schools.length >= 2;
        this.X2_AXIS_OPTIONS[5].isDisabled = yAxis.value === 3 && schools.length >= 2;

        // If an option we've just disabled was already selected, reset the x2 field value
        const selectedX2 = this.X2_AXIS_OPTIONS.find(opt => opt.id === x2Field.value);

        if (selectedX2 && selectedX2.isDisabled) {
            x2Field.setValue(null);
        }

        // Disable x3 for pie chart and % of students tested
        if (yAxis.value === 1 && x2Field.value > 0) {
            this.form.controls.x3.enable();
        } else {
            this.form.controls.x3.disable();
        }

        // Reset graph
        this.resetGraph();
    }

    // X2 Changed - Material select box change event
    public updateX3Value(x2Axis) {
        const x2Selection = this.X2_AXIS_OPTIONS.find(opt => opt.id === x2Axis.value);

        if (x2Selection && x2Selection.x3Options) {
            this.form.controls.x3.enable();
            this.form.controls.x3.setValue(0);
            this.x3Options = x2Selection.x3Options;
        } else {
            this.form.controls.x3.disable();
            this.form.controls.x3.setValue(null);
            this.x3Options = null;
        }

        // Reset graph
        this.resetGraph();
    }

    // Cluster Changed
    public updateConstructs(cluster) {
        const constructField = this.form.get('construct');
        constructField.setValue(null);

        if (cluster) {
            constructField.enable();
            this.constructs = cluster.constructs;
        } else {
            constructField.disable();
            this.constructs = null;
        }

        // Reset graph
        this.resetGraph();
    }

    private resetNestedSelection({ depth = 1 }: { depth?: number } = {}) {
        const schools = this.form.get('schools');
        const sy = this.form.get('school_years');
        const courses = this.form.get('courses');
        const grades = this.form.get('grades');

        if (depth <= 3) {
            this.SchoolReportService.courses = [];
            this.coursesMap = {};
            courses.setValue([]);
            // Reset grades if it exceeds the max num of selections
            if (schools.value.length > 1 && sy.value.length > 1 && grades.value.length > 1) {
                grades.setValue([]);
            }

            if (depth <= 2) {
                this.schoolYears = [];
                this.schoolYearMap = {};
                sy.setValue([]);

                if (depth <= 1) {
                    this.SchoolReportService.schools = [];
                    schools.setValue([]);
                }
            }
        }
    }

    public fetchGraphData() {
        this.form.markAsPristine({ onlySelf: true });
        // Deep copy form values to format before sending to backend
        const params = JSON.parse(JSON.stringify(this.form.value));

        params.districts = JSON.stringify(params.districts);
        params.schools = JSON.stringify(params.schools);
        params.school_years = JSON.stringify(params.school_years);

        if (params.grades) params.grades = JSON.stringify(params.grades);

        if (params.courses) {
            const allIdsFromUniqueCourses = params.courses.map(cId => this.coursesMap[cId].map(c => c.id)).flat();
            params.courses = JSON.stringify(allIdsFromUniqueCourses);
        }

        this.UtilsService.addLoadingOverlay();
        this.SchoolReportService.getSchoolReportGraphData(params)
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public resetGraph() {
        this.SchoolReportService.title = '';
        if (!this.SchoolReportService.isGraphReset) {
            this.SchoolReportService.isGraphReset = true;
        }
    }
}
