<nav id="sidenav" class="flex-column" [class.open]="navIsOpen">
    <div class="sidenav-content" (touchstart)="mobileTap($event)">
        <div class="link-container flex-row align-items-center" uiSref="map" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('map')" matTooltip="Map" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-map-marker"></i>
            </div>
            <span class="navigation-text">Map</span>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="assessment" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('assessment')" matTooltip="Student Hub" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-student-hub icon-size-lg"></i>
            </div>
            <span class="navigation-text">Student Hub</span>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="assessmentManage" [ngClass]="{ 'active-page': route.name.includes('assessmentManage') || route.name.includes('heatmap') }" *ngIf="sessionService.userAccess?.access.includes('assessmentManage')" matTooltip="Teacher Hub" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-teacher-hub icon-size-lg"></i>
            </div>
            <span class="navigation-text">Teacher Hub</span>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="report.school" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('report.school')" matTooltip="Administrator Hub" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-admin-hub icon-size-lg"></i>
            </div>
            <span class="navigation-text">Administrator Hub</span>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="section_roster" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('section_roster')" matTooltip="Class Roster" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-class-roster icon-size-md"></i>
            </div>
            <span class="navigation-text">Class Roster</span>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="resourcer" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('resourcer')" matTooltip="Resourcer" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-resources"></i>
            </div>
            <span class="navigation-text">Resourcer</span>
        </div>

        <div class="link-container flex-row align-items-center cursor-pointer" (click)="toggleSubmenu('sequencer')" *ngIf="sessionService.userAccess?.access.includes('sequencer')" [ngClass]="{ 'active-page': route.name.includes('sequencer') }" matTooltip="Sequencer" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-sequencer"></i>
            </div>
            <span class="navigation-text">Sequencer</span>
            <span class="flex"></span>
            <i class="icon icon-angle-down" [class.rotate]="isSubnavOpen.sequencer"></i>
        </div>
        <div *ngIf="isSubnavOpen.sequencer">
            <div class="sublink" uiSrefActive="active-page" uiSref="sequencer.demo" *ngIf="sessionService.userAccess?.access.includes('sequencer.demo')">Demo</div>
            <div class="sublink" uiSrefActive="active-page" uiSref="sequencer.courses" *ngIf="sessionService.userAccess?.access.includes('sequencer.courses')">Manage Courses</div>
            <div class="sublink" uiSrefActive="active-page" uiSref="sequencer.newCourse" *ngIf="sessionService.userAccess?.access.includes('sequencer.newCourse') && sessionService.userAccess?.teacher_roles.length">Create New Course</div>
        </div>

        <div class="link-container flex-row align-items-center" uiSref="account" uiSrefActive="active-page" *ngIf="sessionService.userAccess?.access.includes('account')" matTooltip="Account" matTooltipPosition="right" [matTooltipDisabled]="navIsOpen">
            <div class="icon-container flex-none justify-center align-items-center">
                <i class="icon icon-account icon-size-lg"></i>
            </div>
            <span class="navigation-text">Account</span>
        </div>
    </div>

    <span class="flex"></span>

    <div class="sidenav-footer"></div>
</nav>
