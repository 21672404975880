import { Inject, Injector, Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root',
})
export class GrowlerService {

    private defaultOptions = {
        timeOut: 10000,
        extendedTimeOut: 5000,
        enableHtml: true,
        positionClass: 'toast-bottom-right',
        titleClass: 'toast-title flex-row align-items-center space-children-row small-space',
        messageClass: 'toast-message space-children-row small-space',
        onActivateTick: true,
    }

    constructor(
        @Inject(Injector) private injector: Injector
    ) { }

    // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
    private get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

    public info(msg, title = '', options = {}) {
        this.toastr.info(msg, title, Object.assign({}, options, this.defaultOptions));
    }

    public success(msg, title = '', options = {}) {
        this.toastr.success(msg, title, Object.assign({}, options, this.defaultOptions));
    }

    public warning(msg, title = '', options = {}) {
        if ((!msg && !title) || typeof msg !== 'string') {
            console.error.apply(console, arguments);

            return false;
        }

        const icon = '<i class="icon icon-exclamation-triangle"></i>';
        msg = `${icon}<span>${msg}</span>`;

        this.toastr.warning(msg, title, Object.assign({}, options, this.defaultOptions));
    }

    public error(msg, title = 'Uh oh!', options = {}) {
        if (typeof msg === 'object') {
            msg = msg.msg; // When attempting to login the error message is nested under msg.msg
        }
        if ((!msg && !title) || typeof msg !== 'string') {
            // Log error to dev tools console instead of toastr because it won't be meaningful to the user
            console.error.apply(console, arguments);

            return false;
        }

        const icon = '<i class="icon icon-times-circle"></i>';
        msg = `${icon}<span>${msg}</span>`;

        this.toastr.error(msg, title, Object.assign({}, options, this.defaultOptions));
    }
}
