import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '@/core/service/utils.service';
import { dateToString } from '@/_helpers/transforms';
import { ModalService } from '@/shared/service/modal.service';
import { AssessmentManagementService } from '../assessment-management.service';

import './teacher-assigned-tests.component.scss';


@Component({
    selector: 'teacher-assigned-tests',
    templateUrl: './teacher-assigned-tests.component.html',
    // styleUrls: ['./teacher-assigned-tests.component.scss'],
})
export class TeacherAssignedTestsComponent implements OnInit, OnChanges {

    @Input() public schoolYears: any;
    @Input() public cluster: any;
    public testCluCon: any;

    public readonly dateFormat = dateFormat;

    // Accordion sorting and test edit variable
    public clusterSortExpression: string;
    public clusterSortReverse = false;
    public editingTest = false;

    constructor(
        public UtilsService: UtilsService,
        private ModalService: ModalService,
        public AssessmentManagementService: AssessmentManagementService,
    ) {}

    public ngOnInit() {
        // Wrap in timeout because section-assessment.component is also async (@TODO: decouple)
        setTimeout(() => {
            this.fetchTests();
        });
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.cluster && change.cluster.currentValue && !change.cluster.firstChange) {
            this.fetchTests();
        }
    }

    private fetchTests() {
        if (this.AssessmentManagementService.models.section) {
            let params = {
                section_id: this.AssessmentManagementService.models.section.id,
            };

            if (this.cluster) {
                params['cluster_id'] = this.cluster.id;
            }

            // Get tests for the section, group under clusters
            this.UtilsService.addLoadingOverlay();
            this.AssessmentManagementService.getTestsForSection(params)
                .then((result: any) => {
                    this.testCluCon = result;
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    public isCurrentSchoolYear(end_date) {
        if (!end_date) { return false; }
        let endDate = new Date(end_date + 'T00:00');
        let now = new Date();

        // A school year is valid for assigning new tests if today is before the end of the year
        return now <= endDate;
    }

    public sortBy(prop) {
        if (this.clusterSortExpression !== prop) {
            // this.clusterSortReverse = this.clusterSortExpression === prop ? !this.clusterSortReverse : false;
            this.clusterSortExpression = prop;
            if (prop === 'start_date') {
                this.testCluCon.sort((a, b) => a.tests[0].start_date < b.tests[0].start_date ? 1 : -1);
            } else {
                this.testCluCon = this.UtilsService.sortByProp(this.testCluCon, prop, this.clusterSortReverse);
            }
        }
    }

    public getStudentTestStatus(test, retest=null) {
        this.UtilsService.addLoadingOverlay();
        this.AssessmentManagementService.getStudentTestTaken(this.AssessmentManagementService.models.section.id, test.id, retest)
            .then((students) => {
                this.ModalService.openStudentTestStatusModal(students, test);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public previewAssessmentModal(aSsp, retest=null) {
        this.UtilsService.addLoadingOverlay();
        this.AssessmentManagementService.getAssessmentPreviewList(aSsp.id, retest)
            .then((previewList) => {
                this.AssessmentManagementService.openTestPreviewModal(previewList, aSsp, retest);
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public openNewClusterTestForm(cluster) {
        // Close other forms left open
        this.closeNewTestForm();

        // Open form
        cluster.isNewTestFormActive = true;
    }

    public assignNewTest() {
        this.AssessmentManagementService.openNewTestAssignmentModal(
            this.AssessmentManagementService.models,
            this.schoolYears.filter(y => this.isCurrentSchoolYear(y.end_date))
            )
            .then(assp => {
                if (assp) {
                    this.AssessmentManagementService.findOrCreateCluCon(this.testCluCon, assp);
                }
            })
            .catch(() => {});
    }

    public onNewTestForm(assp) {
        if (assp) {
            this.AssessmentManagementService.findOrCreateCluCon(this.testCluCon, assp);
        }
        this.closeNewTestForm();
    }

    private closeNewTestForm() {
        this.testCluCon.forEach(cluCon => {
            cluCon.isNewTestFormActive = false;
        });
    }

    public editTest(test) {
        this.editingTest = true;
        test.isEditing = true;
    }

    public onEditTestForm(assp) {
        this.AssessmentManagementService.findOrCreateCluCon(this.testCluCon, assp);
    }

    public cancelTestEdit() {
        this.editingTest = false;
    }

    public deleteAssessment(test, cluCon, retest=null) {
        let config = {
            bodyText: 'Students in your section will be no longer be able to see or take this test in their "Tests" page.',
            actionButtonText: 'Ok, Delete'
        };
        this.ModalService.openModal({}, config)
            .then(() => {
                // User confirmed delete
                let serverData = {
                    section_id: this.AssessmentManagementService.models.section.id,
                    id: test.id
                };

                if (retest) {
                    serverData['retest_id'] = retest.retest_id;
                }

                this.UtilsService.addLoadingOverlay(true);
                this.AssessmentManagementService.deleteClusterAssessment(serverData)
                    .then((result) => {
                        if (retest) {
                            // If it's a retest remove from parent assp array
                            let tIdx = test.retest.findIndex(t => t.retest_id === retest.retest_id);
                            if (tIdx > -1) {
                                return test.retest.splice(tIdx, 1);
                            }
                        } else if (cluCon.tests.length > 1) {
                            // If there are multiple tests in the array remove the test from clu/con
                            let tIdx = cluCon.tests.findIndex(t => t.id === test.id);
                            if (tIdx > -1) {
                                return cluCon.tests.splice(tIdx, 1);
                            }
                        } else {
                            // There's only 1 test left. Remove the whole cluster or construct
                            let cIdx = this.testCluCon.findIndex(c => c.id === cluCon.id && c.construct === cluCon.construct && c.cluster === cluCon.cluster);
                            if (cIdx > -1) {
                                return this.testCluCon.splice(cIdx, 1);
                            }
                        }
                    })
                    .catch(console.warn)
                    .finally(() => this.UtilsService.removeLoadingOverlay());
            })
            .catch(() => {});
    }
}
