import { Component } from '@angular/core';

import { SessionService } from '../core/service/session.service';
import { AnalyticsService } from '../core/service/analytics.service';
import { LoginService } from '../login/login.service';
import { ModalService } from '../shared/service';

import './landing.component.scss';


@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    // styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {

    showCovidMsg: boolean = true;

    constructor(
        public sessionService: SessionService,
        private LoginService: LoginService,
        private ModalService: ModalService,
        private AnalyticsService: AnalyticsService,
    ) {}

    public openGuestRegistration() {
        this.AnalyticsService.action({ action: 'open_registration_form_popover' });

        this.LoginService.openGuestLoginChange.next(true);
        this.LoginService.activeForm = 'registration';
    }

    public openContactModal() {
        this.ModalService.openContactModal();
    }

    public closeCovidMsg($event) {
        this.showCovidMsg = false;
    }
}
