<div class="resourcer-container page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2 class="page-title">Resourcer</h2>

        <button type="button" class="btn primary-button" *ngIf="SessionService.userAccess?.permission >= 8" (click)="openResourceManagerModal()" [disabled]="!fields">Add New Resource</button>
    </div>
</div>

<div class="resourcer-container page-content bottom container-fluid">
    <div class="flex-row space-children-row big-space">
        <!-- Left Nav Bar for filtering -->
        <div class="resourcer-filter-bar flex-column flex-25">
            <h4 class="filter-by-text">Filter by:</h4>

            <!-- Map Location Filter -->
            <button class="btn btn-block filter-button flex-none justify-space-between align-items-center space-children-row" (click)="openMapTagging()" [disabled]="!fields">
                <span>Map Location</span>
                <i class="icon icon-map-marker"></i>
            </button>

            <mat-accordion [multi]="true" class="margin-top-1em">
                <!-- Grade filter container -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Grades</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="space-children-column">
                        <div *ngFor="let grade of grades" class="filter-item">
                            <mat-checkbox   id="grade-{{ grade.id }}"
                                            name="grade-{{ grade.id }}"
                                            [(ngModel)]="filters.checkboxes[('grade-id-' + grade.id)]"
                                            [disableRipple]="true"
                                            labelPosition="after"
                                            (change)="toggleFilterSelection('grade', grade.id, grade.name)">
                                <span>{{ grade.name }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-expansion-panel>

                <!-- Other tags filter container -->
                <mat-expansion-panel *ngFor="let tagCategory of tags">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ tagCategory.name }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="space-children-column">
                        <div *ngFor="let tag of tagCategory.tags" class="filter-item">
                            <mat-checkbox   id="tag-{{ tag.id }}"
                                            name="tag-{{ tag.id }}"
                                            [(ngModel)]="filters.checkboxes[('tag-id-' + tag.id)]"
                                            [disableRipple]="true"
                                            labelPosition="after"
                                            (change)="toggleFilterSelection('tag', tag.id, tag.name)">
                                <span>{{ tag.name }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <!-- Right Search Results -->
        <div class="resourcer-right-search-results">
            <!-- Bar with selected tags displayed -->
            <div class="resourcer-tag-bar">
                <div class="flex-row align-items-center justify-space-between">
                    <span class="resourcer-results">{{ resourcer.count || 0 }} results</span>
                    <span class="resourcer-tag-clear-all" *ngIf="filters.selection.overall.length > 0" (click)="clearFilters()">Clear Search</span>
                </div>

                <div class="flex-row flex-wrap align-items-center">
                    <div class="resourcer-search-tag" *ngFor="let selected of filters.selection.overall">
                        <span class="limit-text">{{ selected.name }}</span>
                        <span class="tag-clear" (click)="removeFilterSelection(selected.type, selected.id)">&nbsp;x</span>
                    </div>
                </div>
            </div>

            <div class="resourcer-cost-row">
                <div class="flex-row align-items-center justify-space-between space-children-row">
                    <div class="resourcer-cost-options flex-none align-items-center">
                        <div class="cost-option" [class.selected]="filters.selection.paid === 0" (click)="savePaidSelection(0)">All</div>
                        <div class="cost-option" [class.selected]="filters.selection.paid === 52" (click)="savePaidSelection(52)">Free</div>
                        <div class="cost-option" [class.selected]="filters.selection.paid === 53" (click)="savePaidSelection(53)">Paid</div>
                    </div>

                    <div class="resourcer-search flex-none align-items-center">
                        <input type="search" id="resource-search" placeholder="Search..." [(ngModel)]="filters.searchText" class="resourcer-search-bar" (keydown.enter)="searchResourcesWithText()">
                        <button class="btn icon-button" (click)="searchResourcesWithText()">
                            <i class="icon icon-search"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="resourcer.count == 0" class="bold body-large">No search results found</div>

            <div class="flex-row flex-wrap justify-start">
                <resource-card *ngFor="let resource of resourcer.items; index as rIdx" [resource]="resource" [resourceIdx]="rIdx" cardType="preview" (openResourceManagerModal)="openResourceManagerModal($event)" (openResourceDeletionModal)="openResourceDeletionModal($event)"></resource-card>
            </div>

            <resourcer-pagination [resourcer]="resourcer" (pageChange)="loadNextPage()"></resourcer-pagination>
        </div>
    </div>
</div>
