<div class="flex-column space-children-column align-items-start margin-top-1em" *ngIf="ReportService.reportClusters?.length === 0">
    <h5>You have not taken any tests, so we do not have any data to display here.</h5>
</div>

<ng-container *ngIf="ReportService.reportClusters?.length > 0">
    <div class="flex-row justify-center align-items-center space-children-row medium-space margin-top-1em">
        <label class="bold">Cluster</label>
        <ng-select  class="custom body-regular forced-width-lg cluster-dropdown"
                    [items]="ReportService.reportClusters"
                    appendTo="body"
                    bindLabel="name"
                    [clearable]="false"
                    groupBy="regionName"
                    [searchable]="true"
                    [(ngModel)]="ReportService.models.cluster"
                    [loading]="loadingClusters"
                    (change)="getTestHistory($event)"
                    name="cluster"
                    placeholder="Select a cluster"
                    required>
            <ng-template ng-label-tmp let-item="item">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.parent.region]"></span>
                <span [innerHTML]="(item.code + ' - ' + item.name)"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.parent.region]"></span>
                <span [innerHTML]="(item.code + ' - ' + item.name)"></span>
            </ng-template>
        </ng-select>
    </div>
</ng-container>

<ng-container *ngIf="clusterRunningRecord">
    <div class="hr"></div>

    <div class="flex-row align-items-center justify-start space-children-row">
        <h4 class="dark-theme page-subheader reset">My Track Record</h4>
    </div>

    <div class="report-cluster whiteframe body-regular">
        <div class="flex-row report-table">
            <construct-info-column  [pageResults]="clusterRunningRecord">
            </construct-info-column>

            <div class="tests-container flex">
                <div class="flex-column align-items-center table-column text-center test-info-column is-link" [class.disabled-background]="!test.valid" *ngFor="let test of clusterRunningRecord.tests" uiSref="report.test" [uiParams]="{ trUid: test.tr_uid }">
                    <div class="table-header flex-column body-small">
                        <div class="bold">{{ test.status === 3 ? test.test_type === 9 ? 'Test' : UtilsService.testformTypeToText(test.test_type) : 'Practice' }} {{ test.sequence }}</div>
                        <div>{{ test.date | date: dateFormat.shortDate : dateFormat.timezone }}</div>
                        <div *ngIf="test.grade">Gr. {{ UtilsService.gradeBinToText(test.grade) }}</div>
                        <span class="flex"></span>
                        <div class="btn-link text-info">See Results <i class="fas fa-angle-double-right"></i></div>
                    </div>

                    <div class="table-data small-padding body-large" *ngFor="let construct of clusterRunningRecord.construct_info">
                        <!-- Tests/Checks Scores -->
                        <div class="vertical-center space-children-column small-space" *ngIf="test.score">
                            <div [innerHTML]="formatScore(test.score[construct.id], 'initial')"></div>
                            <div class="revised" *ngIf="test.score[construct.id]?.has_revised" [innerHTML]="formatScore(test.score[construct.id], 'revised')"></div>
                        </div>
                        <!-- Practice Scores -->
                        <div class="vertical-center space-children-column small-space" *ngIf="test.construct === construct.id && test.p_score?.ss !== null">
                            <div matTooltip="Success Score" matTooltipPosition="above">SS: {{ test.p_score.ss.toFixed() }}%</div>
                            <div matTooltip="Worked Problem Index (Count of questions taken)" matTooltipPosition="above">WPI: {{ test.p_score.wpi }} ({{ test.p_score.items }})</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-summary-column table-column">
                <div class="table-header flex-none align-items-center justify-center body-small">
                   <span class="bold">Actions</span>
                </div>
                <div class="table-data text-center" *ngFor="let construct of clusterRunningRecord.construct_info">
                    <div class="vertical-center space-children-column small-space">
                        <button class="btn link-dropdown text-info"
                                #constructActionsTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="constructActions">
                            <span>Go to...</span>
                            <i class="icon icon-caret-down" [class.rotate]="constructActionsTrigger.menuOpen"></i>
                        </button>

                        <mat-menu #constructActions="matMenu" panelClass="construct-actions-dropdown" xPosition="before">
                            <button mat-menu-item [disableRipple]="true" (click)="showPracticeAssessment(construct)" class="popover-action">Practice</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>

        <div class="report-cluster-footer">
            <table-scroll-buttons [autoScroll]="true" [scrollableData]="clusterRunningRecord"></table-scroll-buttons>
        </div>
    </div>
</ng-container>
