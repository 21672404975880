const Lightbox = require('lightbox2');

import { TransitionService } from '@uirouter/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionService, tokenGetter } from '@/core/service/session.service';
import { UtilsService } from '@/core/service/utils.service';
import { ModalService } from '@/shared/service/modal.service';


export function pageChangeWarningHook(transitionService: TransitionService) {
    // Close any open modals when starting a transition to a different route/state
    const checkAssessmentInProgress = (transition) => {
        const token = tokenGetter();
        const jwtHelper: JwtHelperService = transition.injector().get(JwtHelperService);
        const utilsService: UtilsService = transition.injector().get(UtilsService);
        const modalService: ModalService = transition.injector().get(ModalService);

        // Checks if assessment is in progress
        if (
            token &&
            !jwtHelper.isTokenExpired(token) &&
            (
                document.querySelector('.assessment-modal') ||
                document.querySelector('.feedback-modal') ||
                document.querySelector('.practice-test-modal:not(.in-remove)')
            )
        ) {
            const modalText = {
                actionButtonText: 'Leave without saving',
                closeButtonText: 'Return to page',
                bodyText: 'You are about to leave this page. If you leave without finishing, your current progress will not be saved.'
            };

            // Return promise so state change is delayed until user input
            return modalService.openModal({}, modalText)
                .then(function() {
                    // Close any open modals before changing states
                    utilsService.closeAllModals('Closing any open modals on state change');
                    Lightbox.end();
                })
                .catch(function() {
                    // Prevent state change if user chooses to stay in page
                    return false;
                });
        }

        // Close any open modals before changing states
        utilsService.closeAllModals('Closing any open modals on state change');
        if (Lightbox.album.length) {
            Lightbox.end();
        }
    };

    transitionService.onStart({}, (trans) => checkAssessmentInProgress(trans), { priority: 1 });
}
