import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormModule } from '../_custom/mat-form.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { ClassRosterService } from './class-roster.service';
import { ClassRosterComponent } from './class-roster.component';


@NgModule({
    imports: [
        CommonModule,
        MatFormModule,
        MatExpansionModule,
        NgbPopoverModule,
    ],
    providers: [
        ClassRosterService,
    ],
    declarations: [
        ClassRosterComponent,
    ],
})
export class ClassRosterModule { }
