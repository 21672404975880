import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { UtilsService } from '../../core/service/utils.service';
import { AssessmentService } from '../../assessment/assessment.service';
import { AssessmentStateService } from '../../assessment/common/assessment-state.service';

import './stack-panel.component.scss';


@Component({
    selector: 'stack-panel',
    templateUrl: './stack-panel.component.html',
    // styleUrls: ['./stack-panel.component.scss'],
})
export class StackPanelComponent implements OnInit, OnChanges {

    @Input() construct: any;
    @Input() constructsList: any;
    @Input() cumulativeScores: any;
    @Input() enableLadder: boolean = false;
    @Input() enableDragging: boolean = false;
    @Input() enableStackColors: boolean = false;
    @Input() enablePracticeTest: boolean = false;
    @Input() enableMisconceptions: boolean = false;
    @Output() closed = new EventEmitter();
    @Output() change = new EventEmitter();
    @ViewChild('stackPanel', { static: false }) stackPanel: ElementRef;

    constructor(
        private $element: ElementRef,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        // If a list of constructs is passed sort them by order
        if (this.constructsList) {
            this.constructsList.sort((a, b) => a.order - b.order);
        }
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.construct && change.construct.currentValue && change.construct.currentValue !== change.construct.previousValue) {
            this.constructChanged();
        }
    }

    private constructChanged() {
        if (!this.construct.region) {
            // Patch difference between backend to_json and serializer
            this.construct.region = this.construct.parent.region;
        }
        // calculate color classes depending on test scores at stack level
        if (this.enableLadder && this.enableStackColors) {
            this.colorCodeStackLevels(this.construct);
        }

        // Initialize stack misconceptions as collapsed
        this.construct.stacks.forEach(stack => stack.isMisconceptionCollapsed = true);

        // wait till dom is updated with the latest stacks and animate its contents
        setTimeout(this.animateStackLevels.bind(this));
    }

    public closePanel() {
        this.construct.selected = false;
        this.construct = null;
        this.closed.emit();
        // Remove inline styles (from dragging) to reset to initial position
        this.stackPanel.nativeElement.removeAttribute('style');
    }

    public switchConstruct(construct) {
        if (this.construct !== construct) {
            this.construct.selected = false;
            this.construct = construct;
            this.construct.selected = true;
            // ngOnChanges only catches external @Input construct change
            this.constructChanged();
            this.change.emit(construct);
        }
    }

    public toggleMisconceptionsCollapse(stack, $event) {
        if (stack.isMisconceptionCollapsed) {
            // Opening misconceptions card - pre-select first result
            stack.selectedMisconception = stack.misconceptions[0];
            // Scroll to current stack
            let stackElement = $event.target.closest('.stack-item');
            this.scrollToStack(stackElement);
        } else {
            // Reset misconception flipped status before collapsing
            if (stack.selectedMisconception && stack.selectedMisconception.flipped) {
                stack.selectedMisconception.flipped = false;
            }
        }
        // Finally toggle collapse variable
        stack.isMisconceptionCollapsed = !stack.isMisconceptionCollapsed;
    }

    public showPracticeAssessment(construct) {
        this.UtilsService.addLoadingOverlay();
        this.AssessmentStateService.getPracticeAssessmentInfo(construct)
            .then(() => this.AssessmentService.openPracticeTestModal())
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private colorCodeStackLevels(construct) {
        construct.stacks.forEach(stack => {
            stack.scoreClasses = '';

            if (stack.test_questions && stack.test_questions.length > 0) {
                let totalPercent = stack.test_questions.reduce((acc, test) => {
                    return acc + (test.percent_revised || test.percent_revised === 0 ? test.percent_revised : test.percent_points);
                }, 0);
                stack.avgPercent = Math.round(totalPercent / stack.test_questions.length);
                stack.scoreClasses += stack.avgPercent > 0 ? 'background-correct' : '';
                stack.scoreClasses += ' ' + this.UtilsService.getOpacityLevel(stack.avgPercent);
            } else if (this.cumulativeScores && this.cumulativeScores.stack_scores[stack.id]) {
                let score = this.cumulativeScores.stack_scores[stack.id];
                stack.avgPercent = Math.round(score.points / score.total * 100);
                stack.scoreClasses += stack.avgPercent > 0 ? 'background-correct ' : '';
                stack.scoreClasses += this.UtilsService.getOpacityLevel(stack.avgPercent);
            }
        });
    }

    private animateStackLevels() {
        let stacksContainer = this.$element.nativeElement.querySelector('.panel-scrollable');
        if (!stacksContainer) { return false; } // Don't animate if we can't find the container
        let domStackItems = stacksContainer.querySelectorAll('.stack-item');

        // Scroll to bottom of stack list (level 1)
        stacksContainer.scrollTop = stacksContainer.scrollHeight - stacksContainer.clientHeight;

        domStackItems.forEach((ele, idx) => {
            ele.style.opacity = 0;
            // Fade elements in over a 100ms difference between each one
            setTimeout(() => {
                ele.style.opacity = 1;
            }, 100 * (domStackItems.length - idx) + 100);
        });
    }

    private scrollToStack(stackElement) {
        let stacksContainer = this.$element.nativeElement.querySelector('.panel-scrollable');
        if (!stacksContainer || !stackElement) { return false; } // Don't animate if we can't find the nodes

        stacksContainer.scrollTop = stackElement.offsetTop - 15;
    }
}
