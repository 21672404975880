import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer, appInfo } from '@/app.constant';
import { HttpService } from '@/core/service/http.service';
import { AnalyticsService } from '@/core/service/analytics.service';
import { UtilsService } from '@/core/service/utils.service';
import { MapService } from '@/map/map.service';
import { any } from '@uirouter/core';


@Injectable()
export class SchoolReportService {

    private pendingSchoolInfoRequest = null;

    public schools: any = [];
    public courses: any = [];
    public GRAPH_TYPE_OPTIONS = [
        { id: 1, name: 'Usage Coverage' },
        { id: 2, name: 'Performance' },
    ];
    public GRAPH_TYPE_VARIANT = [
        { id: 1, name: 'CON/CLU', desc: 'percent correct' },
        { id: 2, name: 'LT Levels', desc: 'proportion correct' }
    ];
    public GRAPH_TYPE_OPTIONS_SECONDARY = [
        { id: 1, name: 'Grade' },
        { id: 2, name: 'Course' },
    ];

    public Y_AXIS_OPTIONS = [
        { id: 1, name: 'Number of tests' },
        { id: 2, name: 'Percentage of tests' },
        { id: 3, name: 'Percentage of students tested' },
    ];
    public X_AXIS_OPTIONS = [
        { id: 3, name: 'Yearly', isDisabled: false },
        { id: 2, name: 'Monthly', isDisabled: false },
        { id: 1, name: 'Biweekly', isDisabled: false },
    ];
    public X3_AXIS_OPTIONS = [
        { id: 0, name: 'None' },
        { id: 1, name: 'Teacher' },
        { id: 2, name: 'Course' },
        { id: 3, name: 'Grade' },
        { id: 4, name: 'Cluster' },
        { id: 5, name: 'Activity' },
        { id: 6, name: 'Test Type' },
    ];

    private graphDataChange = new BehaviorSubject<any>({
        params: null,
        graphData: null
    });
    public graphDataObs$: Observable<any> = this.graphDataChange.asObservable();

    public graphLayout: any = {};
    public graphConfig: any = {
        modeBarButtonsToRemove: ['toImage']
    };
    public graphTitle: string = 'Usage Report';
    public title = '';
    public isGraphReset: boolean = false;
    public isGraphEmpty: boolean = false;

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        private MapService: MapService,
        private ngbmodal: NgbModal,
    ) { }

    public hideLegend(event: any): any {
        return false;
    }

    public getSchoolReportFiltersInfo(): any {
        const url = `${apiServer.urlPrefix}/report/school/info/`;
        let params = {};
        let cachedInfo = JSON.parse(localStorage.getItem('school_info'));

        if (cachedInfo) {
            if (cachedInfo.version === appInfo.version) {
                this.AnalyticsService.action({
                    'action': 'return_school_info__cached',
                    'version': appInfo.version
                });

                return Promise.resolve(cachedInfo.result);
            }
            this.AnalyticsService.action({
                'action': 'break_school_info_cache',
                'version': appInfo.version
            });
            localStorage.removeItem('school_info');
        }

        if (this.pendingSchoolInfoRequest) {
            return this.pendingSchoolInfoRequest;
        }

        this.pendingSchoolInfoRequest = this.HttpService.get(url, params)
            .then(response => {
                response.version = appInfo.version;

                localStorage.setItem('school_info', JSON.stringify(response));

                this.AnalyticsService.action({
                    action: 'get_school_info_and_cache',
                    version: appInfo.version,
                    url: url,
                    params: params
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_school_info_fail',
                    version: appInfo.version,
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            })
            .finally(() => this.pendingSchoolInfoRequest = null);

        return this.pendingSchoolInfoRequest;
    }

    public getChartTitle(graphParams) {
        this.title = '';
        let school_list = [];

        // Get school names
        JSON.parse(graphParams.schools).map(school_id => {
            this.schools.forEach(school => {
                if (school.id === school_id) {
                    school_list.push(school.name);
                }
            });
        });

        if (graphParams.graph_type == 1) {
            // Usage Coverage
            this.title += this.Y_AXIS_OPTIONS[graphParams.y_axis - 1].name;
            if (graphParams.x2 > 0) {
                this.title += ' by ' + this.X3_AXIS_OPTIONS[graphParams.x2].name;
            }
            if (graphParams.x3 > 0) {
                this.title += ' by ' + this.X3_AXIS_OPTIONS[graphParams.x3].name;
            }
            this.title += ' in year ' + JSON.parse(graphParams.school_years).sort().join(', ');
            this.title += ' at ' + school_list.join(', ');

        } else if (graphParams.graph_type == 2) {
            // Performance
            if (graphParams.g_type == 1) {
                this.title += 'Test scores for ';
            } else if (graphParams.g_type == 2) {
                this.title += 'Proportion correct for ';
            }
            this.MapService.getLearningMap()
                .then(map => {
                    if (graphParams.construct) {
                        map.constructs.forEach(construct => {
                            if (construct.id == graphParams.construct) {
                                this.title += construct.name;
                            }
                        });
                    } else {
                        map.clusters.forEach(cluster => {
                            if (cluster.id == graphParams.cluster) {
                                this.title += cluster.name;
                            }
                        });
                    }
                    this.title += '<br/>';
                })
                .catch(console.warn)
                .finally(
                    () => {
                        if (graphParams.graph_option == 1 && graphParams.grades) {
                            const grade_list = JSON.parse(graphParams.grades).map(g => g - 4);
                            this.title += 'for grade ' + grade_list.join(', ');
                        } else if (graphParams.graph_option == 2 && graphParams.courses) {
                            let course_list = [];
                            const courses = JSON.parse(graphParams.courses).map(course_id => {
                                course_list.push(this.courses.find(c => c.id == course_id).name);
                            });
                            this.title += 'for course ' + course_list.join(', ');
                        }
                    }
                );
        }

        return this.title;
    }

    public getSchoolReportGraphData(
        params: {
            user_type: string,
            graph_type: number,
            schools: string, // number[] after parsed
            school_years: string, // string[] after parsed
            y_axis: number,
            x_axis: number,
            sorted?: number,
            coded?: number,
            cluster?: number[],
            grades?: number[],
            teachers?: string[],
            sections?: number[],
        }
    ): any {
        const url = `${apiServer.urlPrefix}/report/school/overall/`;

        return this.HttpService.post(url, params)
            .then(response => {
                this.AnalyticsService.action({
                    action: 'get_school_report_graph_data',
                    url: url,
                    params: params
                });

                this.graphDataChange.next({
                    params,
                    graphData: response.result,
                })

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'get_school_report_graph_data_fail',
                    url: url,
                    params: params,
                    error: error
                });

                return Promise.reject(error);
            });
    }
}
