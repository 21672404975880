import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { NgbCollapseModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';

import { SharedModule } from '../shared/shared.module';
// Import map module for: MisconceptionTabsComponent
import { MapModule } from '../map/map.module';
import { ReportModule } from '../report/report.module';

import { AssessmentService } from './assessment.service';
import { AssessmentStateService } from './common/assessment-state.service';
import { AssessmentToastService } from './common/assessment-toast/assessment-toast.service';
import { CalculatorService } from './common/calculator/calculator.service';

import { AssessmentComponent } from './assessment.component';
import { StudentAssignedTestsComponent } from './student-assigned-tests/student-assigned-tests.component';
import { AssignedTestformsComponent } from './assigned-testforms/assigned-testforms.component';
import { QuestionComponent } from './common/question/question.component';
import { StemComponent } from './common/stem/stem.component';
import { FoilComponent } from './common/foil/foil.component';
import { ItemAnalysisPanelComponent } from './common/question-view/item-analysis/item-analysis-panel.component';
import { MisconceptionTriggerPanelComponent } from './common/question-view/misconception-trigger/misconception-trigger-panel.component';
import { AssessmentToastComponent } from './common/assessment-toast/assessment-toast.component';
import { CalculatorComponent } from './common/calculator/calculator.component';
import { CmsItemLinkComponent } from './common/cms-item-link/cms-item-link.component';
// import { StackLadderComponent } from './practice/ladder/stack-ladder.component';
// import { ScoreBubbleComponent } from './practice/score-bubble/score-bubble.component';

import { PracticeTestModalComponent } from './practice/practice-test-modal.component';
import { QuestionViewModalComponent } from './common/question-view/question-view-modal.component';
import { TestInstructionsModalComponent } from './cluster/instructions/test-instructions-modal.component';
import { ClusterTestModalComponent } from './cluster/cluster-test-modal.component';
import { TestFinalizeModalComponent } from './cluster/finalize/test-finalize-modal.component';
import { ReviseRevealModalComponent } from '../report/revise-reveal/revise-reveal-modal.component';


@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        NgbCollapseModule,
        NgbPopoverModule,
        MatFormModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTabsModule,
        MatMenuModule,
        SharedModule,
        MapModule,
        ReportModule,
    ],
    providers: [
        AssessmentService,
        AssessmentStateService,
        AssessmentToastService,
        CalculatorService,
    ],
    declarations: [
        AssessmentComponent,
        StudentAssignedTestsComponent,
        AssignedTestformsComponent,
        QuestionComponent,
        StemComponent,
        FoilComponent,
        ItemAnalysisPanelComponent,
        MisconceptionTriggerPanelComponent,
        AssessmentToastComponent,
        CalculatorComponent,
        CmsItemLinkComponent,
        // StackLadderComponent,
        // ScoreBubbleComponent,
        PracticeTestModalComponent,
        QuestionViewModalComponent,
        TestInstructionsModalComponent,
        ClusterTestModalComponent,
        TestFinalizeModalComponent,
        ReviseRevealModalComponent,
    ],
    entryComponents: [
        PracticeTestModalComponent,
        QuestionViewModalComponent,
        TestInstructionsModalComponent,
        ClusterTestModalComponent,
        TestFinalizeModalComponent,
        ReviseRevealModalComponent,
    ],
})
export class AssessmentModule { }
