import { Injectable } from '@angular/core';

import {
    select as d3Select,
    selectAll as d3SelectAll,
    event as d3Event,
    mouse as d3Mouse,
} from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';
import * as moment from 'moment';

import { UtilsService } from '../../core/service/utils.service';
import { SequencerStateService } from './sequencer-state.service';
import SequencerCluster from './sequencer-cluster';
import SequencerRegion from './sequencer-region';


@Injectable()
export class SequencerService {

    private TAU = 2 * Math.PI;

    constructor(
        private SequencerStateService: SequencerStateService,
        private UtilsService: UtilsService,
    ) { }

    public initializeSSPData(courseSSP) {
        this.SequencerStateService.data.totalDays.value = courseSSP.totalDays || this.SequencerStateService.data.totalDays.default;

        if (courseSSP.course) {
            this.SequencerStateService.data.courseDates.start = courseSSP.course.start_date;
            this.SequencerStateService.data.courseDates.end = courseSSP.course.end_date;
        } else {
            let today = new Date();
            this.SequencerStateService.data.courseDates.start = today.getFullYear() - (today.getMonth() < 6 ? 1 : 0) + '-08-22';
            this.SequencerStateService.data.courseDates.end = this.incrementValidDays(this.SequencerStateService.data.courseDates.start, 180);
        }

        this.SequencerStateService.data.model.pieRegions = [];
        this.SequencerStateService.data.model.pieClusters = [];

        if (courseSSP.cluster_ssp) {
            courseSSP.cluster_ssp.forEach(c => {
                c.cluster = this.SequencerStateService.data.rawMapClusters.find(mapCl => {
                    let cId = typeof c.cluster === 'number' ? c.cluster : c.cluster.id;
                    return mapCl.id === cId;
                });
                // Checking if there is working days b/w start and end date and making them valid as well..
                c.days = this.findNumOfWorkingDays(moment(c.start_date), moment(c.end_date));

                if (c.days) {
                    c.start_date = this.getValidWeekDate(moment(c.start_date), false).format('YYYY-MM-DD');
                    c.end_date = this.getValidWeekDate(moment(c.end_date), true).format('YYYY-MM-DD');
                    this.SequencerStateService.data.model.pieClusters.push(new SequencerCluster(c, {}));
                }
            });
        }

        this.SequencerStateService.data.holidays = {};
        if (courseSSP.holidays) {
            courseSSP.holidays.forEach(holiday => {
                let iteratorM = moment(holiday.start_date);
                let endM = moment(holiday.end_date);

                while (iteratorM.isSameOrBefore(endM)) {
                    if (typeof this.SequencerStateService.data.holidays[iteratorM.format('YYYY-MM-DD')] === 'undefined') {
                        this.SequencerStateService.data.holidays[iteratorM.format('YYYY-MM-DD')] = [];
                    }
                    this.SequencerStateService.data.holidays[iteratorM.format('YYYY-MM-DD')].push(holiday.name);
                    iteratorM.add(1, 'day');
                }
            });
        }

        this.SequencerStateService.data.blockedDays = {};
        if (courseSSP.blocked_days) {
            courseSSP.blocked_days.forEach(blockedDay => {
                let iteratorM = moment(blockedDay.start_date);
                let endM = moment(blockedDay.end_date);

                while (iteratorM.isSameOrBefore(endM)) {
                    this.SequencerStateService.data.blockedDays[iteratorM.format('YYYY-MM-DD')] = {id: blockedDay.id, note: blockedDay.note};
                    iteratorM.add(1, 'day');
                }
            });
        }

        this.SequencerStateService.data.model.notes = (courseSSP.note_ssp || []);
        this.SequencerStateService.data.model.assessments = (courseSSP.assessment_ssp || []);
        this.SequencerStateService.data.model.resources = (courseSSP.resource_ssp || []);
        this.SequencerStateService.data.receivedJson = courseSSP;

        this.updateWheelModels();
    }

    /**
     * Initialization of Regions/Clusters
     **/
    updateMapRegions(regions) {
        let regionModels = [];
        regions.forEach(region => {
            this.SequencerStateService.data.rawMapRegions.push(region);

            let regionData = {
                days: 15,
                order: -1,
                start_date: '',
                end_date: '',
                region: region,
            };
            let mapRegion = new SequencerRegion(regionData, { insidePie: false });

            mapRegion.clusters = this.updateMapClusters(region, region.clusters);
            this.SequencerStateService.data.model.mapRegions.push(mapRegion);
            regionModels.push(mapRegion);
        });

        this.setDefaultRegionClusterDays();
        this.SequencerStateService.data.model.mapLoaded = true;

        return regionModels;
    }

    updateMapClusters(region, clusters) {
        let clusterModels = [];
        clusters.forEach(cluster => {
            this.SequencerStateService.data.rawMapClusters.push(cluster);

            let clusterData = {
                days: 0,
                order: -1,
                start_date: '',
                end_date: '',
                cluster: cluster,
            };
            let mapCluster = new SequencerCluster(clusterData, { insidePie: false });
            this.SequencerStateService.data.model.mapClusters.push(mapCluster);
            clusterModels.push(mapCluster);
        });

        return clusterModels;
    }

    /**
     * Distributing 15 days of default days b/w clusters
     */
    setDefaultRegionClusterDays() {
        let perClusterDays = 0;
        let extraDays;

        this.SequencerStateService.data.model.mapRegions.forEach(region => {
            perClusterDays = Math.floor(region.days / region.clusters.length);
            extraDays = region.days - perClusterDays * region.clusters.length;
            region.clusters.forEach(cluster => {
                cluster.days = perClusterDays + (extraDays > 0 ? 1 : 0);
                extraDays--;
            });
        });
    }

    showRegionPopup(region) {
        this.SequencerStateService.data.selected.cluster = null;
        if (this.SequencerStateService.data.selected.region && this.SequencerStateService.data.selected.region.region.id === region.region.id) {
            this.SequencerStateService.data.selected.region = null;
        } else {
            this.SequencerStateService.data.selected.region = region;
        }

        this.hideRegionPopup();

        if (this.SequencerStateService.data.selected.region) {
            d3Select('#region-popup-group-' + this.SequencerStateService.data.selected.region.region.id)
                .classed('active', true)
                .style('transform', function(d: any) {
                    return `translate(${d.ext.x}px, ${d.ext.visibleY}px)`;
                });

            this.SequencerStateService.data.selected.region.clusters.forEach(cluster => {
                // this.highlightCluster(cluster);

                this.SequencerStateService.data.svgSelections.pieBack
                    .select('#cluster-wedge-' + cluster.cluster.id)
                    .transition()
                    .duration(400)
                    .attr('d', this.SequencerStateService.data.svgSettings.donut.arc);

                this.SequencerStateService.data.svgSelections.pieBack
                    .select('#donut-text-' + cluster.cluster.id)
                    .classed('visible', true);
            });
        }
    }

    hideRegionPopup() {
        d3SelectAll('.region-popup-group')
            .classed('active', false)
            .style('transform', function(d: any) {
                // We use inline style so we can use css transition and not svg
                return 'translate(' + d.ext.x + 'px, ' + d.ext.y + 'px)';
            });

        // Accordion loads before pie, so first time it loads we don't need to hide these things
        if (this.SequencerStateService.data.svgSelections.pieBack) {
            this.SequencerStateService.data.svgSelections.pieBack
                .selectAll('.cluster-wedge-path')
                .transition()
                .duration(400)
                .attr('d', this.SequencerStateService.data.svgSettings.pie.arc);

            this.SequencerStateService.data.svgSelections.pieBack
                .selectAll('.donut-text')
                .classed('visible', false);
        }
    }

    // This function will be called every time we double click
    highlightCluster(cluster) {
        if (!this.SequencerStateService.data.selected.cluster || this.SequencerStateService.data.selected.cluster.cluster.id !== cluster.cluster.id) {
            this.SequencerStateService.data.selected.cluster = cluster;
        } else {
            this.SequencerStateService.data.selected.cluster = null;
        }

        this.unHighlightCluster();

        if (this.SequencerStateService.data.selected.cluster) {
            // Popup Cluster
            d3Select('#cluster-group-' + cluster.cluster.id).classed('highlighted', true);
            // Donut Clusters
            d3Select('#cluster-wedge-' + cluster.cluster.id).classed('highlighted', true);
            d3Select('#donut-text-' + cluster.cluster.id).classed('highlighted', true);
        }
    }

    unHighlightCluster() {
        d3SelectAll('.cluster-group').classed('highlighted', false);
        d3SelectAll('.cluster-wedge path').classed('highlighted', false);
        d3SelectAll('.donut-text').classed('highlighted', false);
    }

    isDraggerVisible(d) {
        return this.SequencerStateService.data.isBeingEdited && d && !d.ext.insidePie;
    }

    isInPie(d) {
        return this.SequencerStateService.data.isBeingEdited && d.ext.insidePie;
    }

    updateSummaryText() {
        let scheduledDays = this.SequencerStateService.getTotalPieClusterDays();
        let pieIsNotEmpty = scheduledDays > 0;

        this.SequencerStateService.data.svgSelections.summaryText
            .text(() => {
                return pieIsNotEmpty ? scheduledDays + ' days scheduled of ' + this.SequencerStateService.data.totalDays.value + ' total' : 'Drag & Drop here to start scheduling';
            })
            .call(this.wrapText.bind(this), this.SequencerStateService.data.pie.radiusInner * 0.95, pieIsNotEmpty);
    }

    renderPie() {
        let pieRegions = this.SequencerStateService.data.model.pieRegions;
        let pieClusters = this.SequencerStateService.data.model.pieClusters;

        this.SequencerStateService.data.svgSettings.pie.d3Pie
            .endAngle(this.computePieEndAngle(pieRegions, this.SequencerStateService.data.totalDays.value))
            .sort(function(a, b) {
                return a.order - b.order;
            });

        let pieRegionWedgeData = this.SequencerStateService.data.svgSettings.pie.d3Pie(pieRegions);
        let pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(pieClusters);

        // bind arcs to its data
        // draws the region by cluster not a whole region arc
        this.SequencerStateService.data.svgSelections.pieBack.selectAll('.region-wedge').remove();
        let regionWedges = this.SequencerStateService.data.svgSelections.pieBack
            .selectAll('.region-wedge')
            .data(pieClusterWedgeData, function(d) {
                return d.data.uid;
            });

        // init the region wedge path
        let regionWedgeEnterGroup = regionWedges.enter()
            .append('g')
            .attr('id', function(d) {
                return 'region-wedge-cluster-' + d.data.cluster.id;
            })
            .classed('region-wedge', true)
            .call(d3Drag()
                .on('start', (d: any) => {
                    for (let i = 0; i < pieRegionWedgeData.length; i++) {
                        if ((<any>pieRegionWedgeData[i]).data.region.id === d.data.cluster.region) {
                            this.handleWedgeInPieDragStart(pieRegionWedgeData[i]);
                        }
                    }
                })
                .on('drag', (d: any) => {
                    for (let i = 0; i < pieRegionWedgeData.length; i++) {
                        if ((<any>pieRegionWedgeData[i]).data.region.id === d.data.cluster.region) {
                            this.handleWedgeInPieDrag(pieRegionWedgeData[i]);
                        }
                    }
                })
                .on('end', (d: any) => {
                    for (let i = 0; i < pieRegionWedgeData.length; i++) {
                        if ((<any>pieRegionWedgeData[i]).data.region.id === d.data.cluster.region) {
                            this.handleWedgeInPieDragEnd(pieRegionWedgeData[i]);
                        }
                    }
                })
            )
            .on('mouseover', this.showRegionHoverDetails.bind(this))
            .on('mousemove', this.updatePopoverCoordinates.bind(this))
            .on('mouseout', this.hideRegionHoverDetails.bind(this))
            .on('click', d => {
                for (let i = 0; i < pieRegions.length; i++) {
                    for (let j = 0; j < pieRegions[i].clusters.length; j++) {
                        if (pieRegions[i].clusters[j].cluster.id === d.data.cluster.id) {
                            this.showRegionPopup(pieRegions[i]);
                            break;
                        }
                    }
                }
            });

        regionWedgeEnterGroup.append('path')
            .attr('class', function(d) {
                return 'region-wedge-path region-background-' + d.data.cluster.region;
            })
            .attr('d', this.SequencerStateService.data.svgSettings.pie.arc);

        regionWedges.exit().remove();
    }

    renderDonut() {
        let pieClusters = this.SequencerStateService.data.model.pieClusters;

        this.SequencerStateService.data.svgSettings.donut.d3Donut
            .endAngle(this.computePieEndAngle(pieClusters, this.SequencerStateService.data.totalDays.value))
            .sort(function(a, b) {
                return a.order - b.order;
            });

        let pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(pieClusters);
        // bind arcs to its data
        this.SequencerStateService.data.svgSelections.pieBack.selectAll('.cluster-wedge').remove();
        let clusterWedges = this.SequencerStateService.data.svgSelections.pieBack
            .selectAll('.cluster-wedge')
            .data(pieClusterWedgeData, function(d) {
                return d.data.uid;
            });

        // init the region wedge path
        let clusterWedgeEnterGroup = clusterWedges.enter()
            .append('g')
            .classed('cluster-wedge', true)
            .call(d3Drag()
                .on('start', this.handleWedgeInPieDragStart.bind(this))
                .on('drag', this.handleWedgeInPieDrag.bind(this))
                .on('end', this.handleWedgeInPieDragEnd.bind(this))
            );

        clusterWedgeEnterGroup.append('path')
            .attr('id', function(d) {
                return 'cluster-wedge-' + d.data.cluster.id;
            })
            .attr('class', function(d) {
                return 'cluster-wedge-path region-background-' + d.data.cluster.region;
            })
            .attr('d', this.SequencerStateService.data.svgSettings.pie.arc)
            .on('click', d => {
                this.highlightCluster(d.data);
            });

        clusterWedges.exit().remove();

        this.updateClusterDonutText(pieClusterWedgeData);
    }

    updateClusterDonutText(clusterData) {
        this.SequencerStateService.data.svgSelections.pieBack.selectAll('.donut-text').remove();
        let donutGroup = this.SequencerStateService.data.svgSelections.pieBack
            .selectAll('.donut-text')
            .data(clusterData);

        let donutEnterGroup = donutGroup.enter()
            .append('text')
            .attr('class', 'donut-text')
            .attr('id', function(d) {
                return 'donut-text-' + d.data.cluster.id;
            })
            .attr('x', 10) // Move the text from the start angle of the arc
            .attr('dy', 20); // Move the text down

        donutEnterGroup
            .append('textPath') // Textpath so it curves along the cluster svg
            .attr('xlink:href', function(d, i) {
                return '#cluster-wedge-' + d.data.cluster.id;
            })
            .classed('donut-text-inner', true)
            .text(d => {
                let angle = this.convertRadianToDegree(d.endAngle - d.startAngle);

                if (angle < 5) { return ''; }
                if (angle < 12) { return '..'; }

                let characterPoint = 0.5;
                if (angle >= 12 && angle <= 25) {
                    characterPoint = 0.3;
                } else if (angle >= 25 && angle <= 50) {
                    characterPoint = 0.4;
                } else if (angle >= 25 && angle <= 180) {
                    characterPoint = 0.45;
                }

                let truncString = d.data.cluster.name.substring(0, angle * characterPoint);

                return truncString + ((truncString.length < d.data.cluster.name.length) ? '...' : '');
            });

        // donutGroup.exit().remove();
    }

    showRegionHoverDetails(d) {
        if (this.SequencerStateService.data.isBeingEdited) {
            // Do not display this during edit mode as it is annoying to have this popup
            return;
        }

        let popover = this.SequencerStateService.data.popover;

        popover.element = document.querySelector('#cluster-hover-popover');
        popover.legendElement = popover.element.querySelector('.region-legend');
        popover.regionTitle = popover.element.querySelector('.region-title-text');
        popover.clusterTitle = popover.element.querySelector('.cluster-title-text');

        popover.regionTitle.innerText = d.data.cluster.regionName;
        popover.clusterTitle.innerText = this.UtilsService.stripHTMLTags(d.data.cluster.name);
        popover.legendElement.classList.add('region-background-' + d.data.cluster.region);
        popover.element.classList.add('visible');
    }

    updatePopoverCoordinates(d) {
        if (this.SequencerStateService.data.isBeingEdited) { return; }

        let coords = d3Mouse(document.querySelector('.sequencer-svg-container'));
        let popover = this.SequencerStateService.data.popover.element;

        // Fixed padding for outer margin top before the svg (stays the same with screen resize)
        popover.style.top = coords[1] + 35 + 'px';
        // Minus half own width so it's centered
        popover.style.left = coords[0] - Math.round(popover.offsetWidth / 2) + 'px';
    }

    hideRegionHoverDetails(d) {
        if (this.SequencerStateService.data.isBeingEdited) { return; }

        let popover = this.SequencerStateService.data.popover;

        popover.element.classList.remove('visible');
        popover.legendElement.classList.remove('region-background-' + d.data.cluster.region);
    }

    tabInitialLineData(clusterWedges) {
        // Insert one more item to cluster data to represent the start date
        clusterWedges.push({
            endAngle: 0,
            startAngle: 0,
            padAngle: 0,
            value: 0,
            data: {
                days: 0,
                endDateWithoutYearString: moment(this.SequencerStateService.data.courseDates.start).format('MM/DD/YY'),
                ext: {
                    lastCluster: true
                }
            }
        });
    }

    renderTabs() {
        let days;
        let pieClusters = this.SequencerStateService.data.model.pieClusters
            .sort((a, b) => a.order - b.order);

        let pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(pieClusters);

        this.tabInitialLineData(pieClusterWedgeData);

        d3Select('#tabs').selectAll('.tab').remove();
        // Tabs for changing duration
        // bind tabs to its data
        let tab = d3Select('#tabs')
            .selectAll('.tab')
            .data(pieClusterWedgeData);

        // init the tab object
        let tabEnter = tab.enter()
            .append('g')
            .attr('class', function(d: any) {
                return 'tab' + (d.data.days === 0 ? ' start-day' : '');
            })
            .call(
                d3Drag()
                    .on('start', this.handleTabDragStart.bind(this))
                    .on('drag', this.handleTabDrag.bind(this))
                    .on('end', this.handleTabDragEnd.bind(this))
            );

        tabEnter
            .append('rect')
            .attr('x', -1)
            .attr('y', -8)
            .attr('rx', 3)
            .attr('ry', 3)
            .classed('tab-duration-rect', true);

        tabEnter
            .append('text')
            .attr('x', 16)
            .attr('y', 6)
            .classed('tab-duration-text', true)
            .text(function(d: any) {
                return d.data.endDateWithoutYearString;
            });

        // Caret up icon
        tabEnter
            .append('text')
            .attr('x', 16)
            .attr('y', -4)
            .classed('tab-duration-icon', true)
            .text(function(d: any) {
                return d.data.days === 0 ? '' : '\u0064';
            });

        // Caret down icon
        tabEnter
            .append('text')
            .attr('x', 16)
            .attr('y', 18)
            .classed('tab-duration-icon', true)
            .text(function(d: any) {
                return d.data.days === 0 ? '' : '\u0061';
            });

        // set the tab positions on enter
        tabEnter.attr('transform', (d: any) => {
            let angle = (d.endAngle * 180 / Math.PI);
            let transform = this.SequencerStateService.data.pie.radiusOuter + 5 + (angle > 180 ? 35 : 0);

            return this.UtilsService.translateString((transform) * Math.sin(d.endAngle), (-transform) * Math.cos(d.endAngle)) + ' rotate(' + (angle + (angle <= 180 ? -90 : 90)) + ')';
        });

        days = 0;
        tabEnter.select('.tab-duration-text')
            .attr('class', (d: any) => {
                let duration = Math.round(d.data.days);

                return 'tab-duration-text' + (duration > 0 && duration <= 2 ? ' small' : '');
            })
            .text((d: any) => {
                if (d.data.days === 0 && days >= this.SequencerStateService.data.totalDays.value - 1) {
                    return '';
                }
                days += d.data.days;

                return this.SequencerStateService.data.dateShown ? (d.data.days === 0 ? 'Start' : 'Day ' + days) : d.data.endDateWithoutYearString;
            });

        tab.exit().remove();
    }

    renderTabLines() {
        let pieClusters = this.SequencerStateService.data.model.pieClusters
            .sort((a, b) => a.order - b.order);

        let pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(pieClusters);
        this.tabInitialLineData(pieClusterWedgeData);
        // Tabs for changing duration //
        // bind tabs to its data
        d3Select('#tab-lines')
            .selectAll('.tab-line')
            .remove();

        let tab = d3Select('#tab-lines')
            .selectAll('.tab-line')
            .data(pieClusterWedgeData);
        // init the tab object
        let tabEnter = tab.enter()
            .append('g')
            .classed('tab-line', true);

        tabEnter.append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', this.SequencerStateService.data.pie.radiusIntermediate)
            .attr('y2', 0)
            .attr('class', function(d: any) {
                return 'tab-line-inner ' + (d.data.ext.lastCluster ? 'solid' : 'dashed') + (d.data.days === 0 ? ' start-day' : '');
            });

        tabEnter.append('line')
            .attr('x1', this.SequencerStateService.data.pie.radiusIntermediate)
            .attr('y1', 0)
            .attr('x2', this.SequencerStateService.data.pie.radiusOuter)
            .attr('y2', 0)
            .attr('class', function(d: any) {
                return 'tab-line-outer ' + (d.data.ext.lastCluster ? 'solid' : 'transparent');
            });

        tabEnter.attr('transform', function(d: any) {
            return ' rotate(' + ((d.endAngle * 180 / Math.PI) - 90) + ')';
        });

        tab.exit().remove();
    }

    renderPieText() {
        let pieClusters = this.SequencerStateService.data.model.pieClusters;

        let textGroup = this.SequencerStateService.data.svgSelections.pieLayer
            .select('#cluster-days')
            .selectAll('.duration-text')
            .data(pieClusters);

        // Update action
        textGroup
            .text(function(d) {
                return Math.round(d.days) + 'd';
            })
            .classed('small', function(d) {
                return Math.round(d.days) <= 2;
            });

        // Enter action (new elements)
        textGroup.enter()
            .append('text')
            .classed('duration-text', true)
            .classed('small', function(d) {
                return Math.round(d.days) <= 2;
            })
            .attr('dy', '0.3em')
            .text(function(d) {
                return Math.round(d.days) + 'd';
            });

        // Exit action (elements removed from data array)
        textGroup.exit().remove();

        // re-draw the text on enter and on update
        this.rotateClusterDurationText();
    }

    rotateClusterDurationText() {
        let pieClusters = this.SequencerStateService.data.model.pieClusters;

        this.SequencerStateService.data.svgSelections.pieLayer.select('#cluster-days').selectAll('.duration-text')
            .attr('transform', (d, i) => {
                let originalDatum = this.SequencerStateService.data.svgSettings.donut.d3Donut(pieClusters)[i];
                let midAngle = (originalDatum.startAngle + originalDatum.endAngle) / 2;
                let finalAngle = (midAngle * 180 / Math.PI) - 90;
                finalAngle += (finalAngle > 90) ? 180 : 0; // Never show upside down text

                let textX = (this.SequencerStateService.data.pie.radiusIntermediate - 20) * Math.sin(midAngle);
                let textY = -(this.SequencerStateService.data.pie.radiusIntermediate - 20) * Math.cos(midAngle);

                return `${this.UtilsService.translateString(textX, textY)} rotate(${finalAngle})`;
            });
    }

    isPieFull(daysIncrement) {
        let totalClusterDays = this.SequencerStateService.data.model.pieClusters
            .filter(cluster => cluster.order > -1)
            .reduce((acc, cluster) => acc + cluster.days, 0);

        return totalClusterDays + daysIncrement >= this.SequencerStateService.data.totalDays.value;
    }

    // function bringToFront(group) { // There is no z-index in SVG so if we paint it last, it goes to the front
    //     return group.each(function() {
    //         let node = group.node();
    //         node.parentNode.appendChild(node);
    //     });
    // }

    checkIfEmptyPie() {
        let isEmpty = d3SelectAll('.cluster-wedge').nodes().length === 0;

        this.SequencerStateService.data.svgSelections.root
            .classed('empty', isEmpty);

        d3Select('.show-dates-row').classed('opaque', isEmpty);
    }

    checkIfRegionOrClusterAreSelected() {
        if (this.SequencerStateService.data.selected.region) {
            let region = this.SequencerStateService.data.selected.region;
            this.SequencerStateService.data.selected.region = null;
            this.showRegionPopup(region);
        }

        if (this.SequencerStateService.data.selected.cluster) {
            let cluster = this.SequencerStateService.data.selected.cluster;
            this.SequencerStateService.data.selected.cluster = null;
            this.highlightCluster(cluster);
        }
    }

    // Dynamic Parts of Map
    updateView() {
        this.renderDonut();
        this.renderPie();
        this.renderPieText();
        this.renderTabLines();
        this.renderTabs();

        this.updateSummaryText();
        this.checkIfEmptyPie();
        this.checkIfRegionIsDraggable();
        this.checkIfRegionOrClusterAreSelected();
        this.checkIfUndoIsVisible();
    }

    checkIfRegionIsDraggable() {
        // Dragger should be visible if we're in edit mode, and the region isn't already part of the pie
        d3SelectAll('.map-region-dragger')
            .classed('edit-mode', this.isDraggerVisible.bind(this));
        d3SelectAll('.region-legend')
            .classed('visible-dragger', this.isDraggerVisible.bind(this));
        // There are the arrows/move icon inside the region popups
        // They should only be visible when we are in edit mode, and the cluster/region is not already inside the pie
        d3SelectAll('.map-cluster-dragger')
            .classed('edit-mode', this.isDraggerVisible.bind(this));
        d3SelectAll('.popup-region-dragger')
            .classed('edit-mode', this.isDraggerVisible.bind(this));
        d3SelectAll('.cluster-group-text')
            .classed('in-pie', this.isInPie.bind(this));
        d3SelectAll('.popup-region-text')
            .classed('in-pie', this.isInPie.bind(this));

        // Make tab caret up and down icons available for hover event in edit mode
        d3SelectAll('.tab-duration-icon')
            .classed('visible', this.SequencerStateService.data.isBeingEdited);
    }

    checkIfUndoIsVisible() {
        d3Select('.sequencer-undo-button')
            .classed('invisible', this.SequencerStateService.data.snapshots.length === 0);
    }

    returnClustersToAccordion() {
        this.SequencerStateService.data.selected.validDrag = true;
        this.SequencerStateService.data.selected.insidePie = false;
        this.SequencerStateService.data.selected.previousIndex = null;
        this.SequencerStateService.data.selected.clusters.forEach(cluster => {
            let clustIndex = this.SequencerStateService.data.model.pieClusters.indexOf(cluster);

            if (clustIndex > -1) {
                this.SequencerStateService.data.model.pieClusters.splice(clustIndex, 1);
            }
        });
    }

    moveClustersToPie(angleToDragPoint) {
        let pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(this.SequencerStateService.data.model.pieClusters);
        let pieClusterWedgeDataOrdered = pieClusterWedgeData.sort(function(a, b) {
            return a.data.order - b.data.order;
        });
        let insertIndex = this.computeWedgeIndex(angleToDragPoint, pieClusterWedgeDataOrdered, 'split');

        /**
         *  Checking for Valid Drag
         */
        if (this.SequencerStateService.data.selected.previousIndex && this.SequencerStateService.data.selected.previousIndex !== insertIndex) {
            this.SequencerStateService.data.selected.validDrag = true;
        }
        this.SequencerStateService.data.selected.previousIndex = insertIndex;


        if (this.SequencerStateService.data.selected.insidePie === false) {
            // the drag point just crossed into the pie
            this.SequencerStateService.data.selected.insidePie = true;

            let totalClusterDays = this.SequencerStateService.data.selected.clusters.reduce((acc, cluster) => {
                return acc + cluster.days;
            }, 0);
            let angleToBeAdded = (totalClusterDays / this.SequencerStateService.data.totalDays.value) * this.TAU;

            insertIndex = this.computeWedgeIndex(angleToDragPoint, pieClusterWedgeDataOrdered, 'split', angleToBeAdded);

            this.SequencerStateService.data.selected.clusters.forEach(cluster => {
                cluster.order = insertIndex - 0.5;
                this.SequencerStateService.data.model.pieClusters.push(cluster);
            });
        } else {
            // already in the pie - all we need to do is update the order
            this.SequencerStateService.data.selected.clusters.forEach(function(cluster) {
                cluster.order = insertIndex - 0.5;
            });
        }

        this.adjustWedgeDaysIfNeeded(insertIndex);
    }

    handleAccordionToPieDragStart(d) {
        this.SequencerStateService.data.selected.insidePie = false;
        this.SequencerStateService.data.selected.clusters = [];
        this.SequencerStateService.data.selected.validDrag = true;
        this.SequencerStateService.data.selected.previousIndex = null;
        this.SequencerStateService.data.selected.originalDragDays = {};

        if (!this.SequencerStateService.data.isBeingEdited) {
            return;
        }

        this.copySnapshot();

        if (typeof d.clusters !== 'undefined') {
            let counter = 0;
            d.clusters.forEach(cluster => {
                if (!cluster.ext.insidePie) {
                    let nCluster = cluster.clone(0);
                    if (counter === 0 && (this.SequencerStateService.data.model.pieClusters.length === 0)) {
                        nCluster.startDate = this.SequencerStateService.data.courseDates.start;
                    }
                    this.SequencerStateService.data.selected.clusters.push(nCluster);
                    counter++;
                }
            });
        } else {
            if (!d.ext.insidePie) {
                this.SequencerStateService.data.selected.clusters.push(d.clone(0));
            }
        }

        let pieClusters = this.SequencerStateService.data.model.pieClusters
            .sort((a, b) => a.order - b.order)
            .forEach(pieCluster => {
                this.SequencerStateService.data.selected.originalDragDays[pieCluster.cluster.id] = pieCluster.days;
            });
    }

    handleAccordionToPieDrag(d) {
        if (!this.SequencerStateService.data.isBeingEdited || !this.SequencerStateService.data.selected.clusters.length) {
            return;
        }

        // Reset Cluster to original Days, as it might be possible we move cluster to other location and therefore need to adjust days nearby that..
        this.SequencerStateService.data.model.pieClusters
            .forEach(pieCluster => {
                if (typeof this.SequencerStateService.data.selected.originalDragDays[pieCluster.cluster.id] !== 'undefined') {
                    pieCluster.days = this.SequencerStateService.data.selected.originalDragDays[pieCluster.cluster.id];
                }
            });
        // manage dragging multiple clusters
        // when inside the pie, convert to and insert as a slice
        let x = d3Event.x;
        let y = d3Event.y;
        this.moveDragObject(d);

        let angleToDragPoint = this.computeAngle(x - this.SequencerStateService.data.pie.center.x, -(y - this.SequencerStateService.data.pie.center.y));
        if (this.isInsidePie(this.SequencerStateService.data.pie.center, this.SequencerStateService.data.pie.radiusIntermediate, {x: x, y: y})) {
            // negative y because of difference in coordinate systems
            this.moveClustersToPie(angleToDragPoint);
        } else if (this.SequencerStateService.data.selected.insidePie) {
            // the drag point just crossed back out of the pie
            this.returnClustersToAccordion();
        }

        this.updateWheelModels();
        this.updateView();
    }

    handleAccordionToPieDragEnd(d) {
        if (!this.SequencerStateService.data.isBeingEdited) {
            return;
        }

        this.SequencerStateService.data.selected.originalDragDays = {};
        this.SequencerStateService.data.selected.clusters = [];

        this.saveCopiedSnapshot();

        this.removeDragObject();
        this.updateWheelModels();
        this.updateView();
    }

    // wedge dragging functions - to reposition a slice within the pie
    handleWedgeInPieDragStart(d) {
        if (!this.SequencerStateService.data.isBeingEdited) {
            return;
        }

        this.copySnapshot();

        // when dragging, close cluster wedge (by unselecting)
        this.hideRegionPopup();
        this.SequencerStateService.data.selected.region = null;

        this.SequencerStateService.data.selected.insidePie = true;
        this.SequencerStateService.data.selected.clusters = [];

        this.SequencerStateService.data.selected.validDrag = false;
        this.SequencerStateService.data.selected.previousIndex = null;

        if (typeof d.data.clusters !== 'undefined') {
            d.data.clusters.forEach(cluster => {
                if (!cluster.ext.insidePie) {
                    this.SequencerStateService.data.selected.clusters.push(cluster);
                }
            });
        } else {
            if (!d.data.ext.insidePie) {
                this.SequencerStateService.data.selected.clusters.push(d.data);
            }
        }
    }

    handleWedgeInPieDrag(d) {
        if (!this.SequencerStateService.data.isBeingEdited) {
            return;
        }
        // Manage dragging a region wedge to a different location in the pie or from pie to outside
        // Note that we must keep regionWedgeData and pieRegionWedgeData in sync
        // Think of d.data as the region being dragged
        let x = d3Event.x;
        let y = d3Event.y;
        this.moveDragObject(d);

        // calc the insertion index if inside the pie - convert x,y to an angle, then find the nearest
        let angleToDragPoint = this.computeAngle(x, -y); // negative y because of difference in coordinate systems

        if (this.isInsidePie({ x: 0, y: 0 }, this.SequencerStateService.data.pie.radiusIntermediate, { x: x, y: y })) {
            this.moveClustersToPie(angleToDragPoint);
        } else if (this.SequencerStateService.data.selected.insidePie) {
            this.returnClustersToAccordion();
        } // else not in the pie and didn't just leave; region rect mode
        if (this.SequencerStateService.data.selected.validDrag) {
            this.updateWheelModels();
            this.updateView();
        }
    }

    handleWedgeInPieDragEnd(d) {
        this.removeDragObject(); // It is important that is always removed so user interaction is not blocked
        if (!this.SequencerStateService.data.isBeingEdited || !this.SequencerStateService.data.selected.validDrag) {
            return;
        }

        this.SequencerStateService.data.selected.clusters = [];

        this.saveCopiedSnapshot();

        this.updateWheelModels();
        this.updateView();
    }

    // tab dragging functions - to resize a slice
    handleTabDragStart(d) {
        if (!this.SequencerStateService.data.isBeingEdited || d.data.days === 0) {
            return;
        }
        this.copySnapshot();

        this.SequencerStateService.data.tempDatas.prevAngle = undefined;
        this.SequencerStateService.data.tempDatas.pieClusters = this.SequencerStateService.data.model.pieClusters;
        this.SequencerStateService.data.tempDatas.pieClusterWedgeData = this.SequencerStateService.data.svgSettings.donut.d3Donut(this.SequencerStateService.data.tempDatas.pieClusters);
        this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered = this.SequencerStateService.data.tempDatas.pieClusterWedgeData.sort(function(a, b) {
            return a.data.order - b.data.order;
        });
    }

    handleTabDrag(d) {
        if (!this.SequencerStateService.data.isBeingEdited || d.data.days === 0) {
            return;
        }

        let sliceIndex = parseInt(this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered.findIndex(c => c.data.uid === d.data.uid));

        // keep handle on the outer edge of donut
        let relX = d3Event.x;
        let relY = d3Event.y;
        let shiftKey = d3Event.sourceEvent.shiftKey;

        let relH = Math.sqrt(relX * relX + relY * relY);
        // constrain to stay on the outer radius of the circle - see angle constraints below
        let constrainedX = relX * (this.SequencerStateService.data.pie.radiusOuter / relH);
        let constrainedY = relY * (this.SequencerStateService.data.pie.radiusOuter / relH);
        // compute the angle and apply limits
        let angle = this.computeAngle(constrainedX, -constrainedY);

        // fix edge cases when the tab stradles 0 or 2π
        if (sliceIndex === this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered.length - 1 && angle < 0.1) {
            angle = this.TAU;
        }
        if (sliceIndex === 0 && this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered[0].days < 3 && angle > 6) {
            angle = 0;
        }

        // don't let the angle pass down through 0 nor up through 360
        if (this.SequencerStateService.data.tempDatas.prevAngle !== undefined) {
            if (angle > Math.PI * 3 / 2 && this.SequencerStateService.data.tempDatas.prevAngle < Math.PI / 2) {
                angle = 0;
            } else if (angle < Math.PI / 2 && this.SequencerStateService.data.tempDatas.prevAngle > Math.PI * 3 / 2) {
                angle = this.TAU;
            }
        }

        this.SequencerStateService.data.tempDatas.prevAngle = angle;

        let totalDaysToDragAngle = Math.round(angle / this.TAU * this.SequencerStateService.data.totalDays.value);
        let sliceDataBeforeThis = this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered.slice(0, sliceIndex).map(c => c.data);
        let daysBeforeThisSlice = (sliceIndex === 0) ? 0 : sliceDataBeforeThis.reduce((acc, c) => acc + c.days, 0);
        let nextWedge = (sliceIndex === this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered.length - 1) ? undefined : this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered[sliceIndex + 1];

        // proposed durations
        // Days this slice must be greater than equal to 1 from current Drag angle to previous clusters total days.
        let daysThisSlice = totalDaysToDragAngle - daysBeforeThisSlice;
        let deltaDays = daysThisSlice - this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered[sliceIndex].data.days;
        // reduce the days in next slice to keep the total days the same
        let daysNextSlice = (nextWedge) ? nextWedge.data.days - deltaDays : 1000;

        // update the model regions, within limits
        if (daysThisSlice >= 1 && daysNextSlice >= 1) {
            this.SequencerStateService.data.tempDatas.pieClusterWedgeDataOrdered[sliceIndex].data.days = daysThisSlice;
            // prevent overflow and if full, keep it full
            if (nextWedge) {
                if (shiftKey) {
                    nextWedge.data.days -= deltaDays;
                } else {
                    let proposedDeltaDays = daysNextSlice - nextWedge.data.days;
                    if (this.isPieFull(proposedDeltaDays)) {
                        nextWedge.data.days = daysNextSlice;
                    }
                }
            }
        }

        this.updateWheelModels();
        this.updateView();
    }

    handleTabDragEnd(d) {
        if (!this.SequencerStateService.data.isBeingEdited || d.data.days === 0) {
            return;
        }

        this.saveCopiedSnapshot();

        this.removeDragObject();
        this.updateWheelModels();
        this.updateView();
    }

    updateRegionsInsidePie() {
        /**
         * Checking for Regions not in Pie in full
         */
        let mapRegions = this.SequencerStateService.data.model.mapRegions;

        mapRegions.forEach(mapRegion => {
            let allInsidePie = true;

            mapRegion.clusters.forEach(regionCluster => {
                if (!regionCluster.ext.insidePie) {
                    allInsidePie = false;
                }
            });

            mapRegion.ext.insidePie = allInsidePie;
        });
    }

    /**
     * Very important function so have to be careful while editing
     */
    updateWheelModels() {
        /**
         *
         * Resetting all clusters ext property to
         */
        let mapClusters = this.SequencerStateService.data.model.mapClusters;
        mapClusters.forEach(cluster => {
            cluster.ext.insidePie = false;
        });

        let mapRegions = this.SequencerStateService.data.model.mapRegions;
        mapRegions.forEach(region => {
            region.ext.insidePie = false;
        });

        /**
         *
         * Regenerating PIE with new changed cluster and updating Map Regions cluster to be inside pie or not.
         */

        let pieClusters = this.SequencerStateService.data.model.pieClusters;
        let pieClustersOrdered = pieClusters.sort(function(a, b) {
            return a.order - b.order;
        });
        let order = 0;
        let pieOrder = 0;

        let pieRegions = [];

        let pieDays = 0;
        let currentPieRegion = null;

        let startDateMoment = moment(this.SequencerStateService.data.courseDates.start);

        // this.SequencerStateService.data.courseStartDate = startDateMoment.format('YYYY-MM-DD');

        let endDateMoment = null;
        let prevCluster = null;
        let curCluster = null;

        pieClustersOrdered.forEach(cluster => {
            curCluster = cluster;
            cluster.ext.lastCluster = false;
            startDateMoment = this.getValidWeekDate(startDateMoment);
            endDateMoment = this.addWeekdays(startDateMoment, cluster.days - 1);
            // Look for region for cluster
            let regions = this.SequencerStateService.data.model.mapRegions.filter(function(obj) {
                return obj.region.id === cluster.cluster.region;
            });

            if (regions.length) {
                let mapRegion = regions[0];
                // If Cluster regions changed from previous cluster or null, then set the new cluster regions
                if (currentPieRegion === null || mapRegion.region.id !== currentPieRegion.region.id) {
                    pieDays = 0;
                    currentPieRegion = new SequencerRegion({
                        days: pieDays,
                        order: pieOrder++,
                        start_date: startDateMoment.format('YYYY-MM-DD'),
                        region: mapRegion.region
                    });
                    pieRegions.push(currentPieRegion);

                    if (prevCluster) {
                        prevCluster.ext.lastCluster = true;
                    }
                }

                prevCluster = cluster;
                currentPieRegion.clusters.push(cluster);
                currentPieRegion.days += cluster.days;
                cluster.order = order++;

                // Updating Map Region Cluster to inside Pie as well as checking if all set to inside Pie
                let allInsidePie = true;

                mapRegion.clusters.forEach(regionCluster => {
                    if (cluster.cluster.id === regionCluster.cluster.id) {
                        regionCluster.ext.insidePie = true;
                    } else {
                        if (!regionCluster.ext.insidePie) {
                            allInsidePie = false;
                        }
                    }
                });

                cluster.ext.largeHandle = allInsidePie; // Large solid handle if last cluster, otherwise dashed line

                // Setting Cluster Dates
                cluster.startDate = this.getValidWeekDate(startDateMoment).format('YYYY-MM-DD');
                cluster.endDate = this.getValidWeekDate(endDateMoment).format('YYYY-MM-DD');

                currentPieRegion.endDate = this.getValidWeekDate(endDateMoment).format('YYYY-MM-DD');
                startDateMoment = this.incrementValidDays(moment(endDateMoment), 1);
            }
        });
        if (curCluster) {
            curCluster.ext.lastCluster = true;
        }

        this.updateRegionsInsidePie();
        this.SequencerStateService.data.model.pieRegions = pieRegions;

        this.setPrevNextEntity();
    }

    setPrevNextEntity() {
        let lastCluster = null;
        let pieClusters = this.SequencerStateService.data.model.pieClusters.sort(function(a, b) {
            return a.order - b.order;
        });
        pieClusters.forEach(cluster => {
            cluster.ext.insidePie = false;
            cluster.links = {};
            cluster.links.next = null;
            cluster.links.prev = lastCluster;
            cluster.links.startDate = moment(this.SequencerStateService.data.courseDates.start);
            cluster.links.endDate = moment(this.SequencerStateService.data.courseDates.end);

            if (lastCluster) {
                cluster.links.startDate = this.incrementValidDays(moment(lastCluster._startDate), 1);

                lastCluster.links.next = cluster;
                lastCluster.links.endDate = this.decrementValidDays(moment(cluster._endDate), 1);
            }

            lastCluster = cluster;
        });

        let lastRegion = null;
        let pieRegions = this.SequencerStateService.data.model.pieRegions.sort((a, b) => a.order - b.order);
        pieRegions.forEach(region => {
            region.ext.insidePie = false;
            region.links = {};
            region.links.next = null;
            region.links.prev = lastRegion;
            region.links.startDate = moment(this.SequencerStateService.data.courseDates.start);
            region.links.endDate = moment(this.SequencerStateService.data.courseDates.end);

            if (lastRegion) {
                region.links.startDate = this.incrementValidDays(moment(lastRegion._startDate), lastRegion.clusters.length);

                lastRegion.links.next = region;
                lastRegion.links.endDate = this.decrementValidDays(moment(region._endDate), region.clusters.length);
            }

            lastRegion = region;
        });
    }

    cloneModel() {
        // exact copy, including the region id's
        let regionsClone = this.SequencerStateService.data.model.pieRegions.map(function(region) {
            return new SequencerRegion(region.snapshot());
        });
        let clustersClone = this.SequencerStateService.data.model.pieClusters.map(function(cluster) {
            return new SequencerCluster(cluster.snapshot());
        });

        return {
            pieRegions: regionsClone,
            pieClusters: clustersClone
        };
    }

    // the snapshots are outside of the model object to avoid recursion
    saveSnapshot(clone) {
        // clones the current model
        // and adds it to the snapshots array
        // note: call this *before* each action is actually applied
        if (!clone) {
            clone = this.cloneModel();
        }
        if (clone !== this.SequencerStateService.data.snapshots[this.SequencerStateService.data.snapshots.length - 1]) {
            this.SequencerStateService.data.snapshots.push(clone);
        }
        // else no need to save it if nothing is different
    }

    copySnapshot() {
        this.SequencerStateService.data.temporarySnapshot = this.cloneModel();
    }

    saveCopiedSnapshot() {
        if (this.SequencerStateService.data.temporarySnapshot) {
            this.saveSnapshot(this.SequencerStateService.data.temporarySnapshot);
        }
        this.SequencerStateService.data.temporarySnapshot = null;
    }

    revertToSnapshot(snapshot) {
        // using the snapShot, re-hydrate the model (assumed to exist with functions intact)
        // in other words, overwrite the current model properties with those in the snapshot
        let snapshotObject = (typeof snapshot === 'string') ? JSON.parse(snapshot) : snapshot;
        // merge this object (not a complete model) into the current model
        let keys = Object.keys(snapshotObject);
        // this.SequencerStateService.data.model.clusterRegions = [];
        for (let i = 0, keys_1 = keys; i < keys_1.length; i++) {
            let key = keys_1[i];
            this.SequencerStateService.data.model[key] = snapshotObject[key];
        }
    }

    revertToLastSnapshot() {
        // this is the 'undo' function
        // restores and deletes latest snapshot (array length decreases by 1)
        // after calling this, the calling code should re-render the view
        if (this.SequencerStateService.data.snapshots.length > 0) {
            this.revertToSnapshot(this.SequencerStateService.data.snapshots.pop());
        }
        this.updateWheelModels();
        this.updateView();
    }

    revertAllSnapshots() {
        if (this.SequencerStateService.data.snapshots.length > 0) {
            this.revertToSnapshot(this.SequencerStateService.data.snapshots[0]);
        }
        this.emptySnapshots();
        this.updateWheelModels();
        this.updateView();
    }

    emptySnapshots() {
        this.SequencerStateService.data.snapshots.splice(0, this.SequencerStateService.data.snapshots.length);
    }

    updateEditMode(newValue) {
        this.SequencerStateService.data.isBeingEdited = newValue;
        if (this.SequencerStateService.data.initialized) {
            this.updateView();
        }
    }

    /**
     * Calculations related function
     */
    wrapText(text, width, separateFirstWord) {
        text.each(function() {
            let text = d3Select(this);
            let words = text.text().split(/\s+/).reverse();
            let word;
            let line = [];
            let lineNumber = 0;
            let lineHeight = 18;
            let y = text.attr('y');
            let dy = parseFloat(text.attr('dy'));
            let dx = parseFloat(text.attr('dx'));
            let tspan = text.text(null)
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dx', dx + 'px')
                .attr('dy', dy + 'px');

            while (words.length > 0) {
                word = words.pop();
                line.push(word); // Keep pushing words into the line until it exceeds the width
                tspan.text(line.join(' '));
                if ((<SVGTextContentElement>tspan.node()).getComputedTextLength() > width || (separateFirstWord && line.length === 2)) {
                    separateFirstWord = false;
                    // Remove the extra word that made it overflow the width
                    line.pop();
                    // Set the text to the current tspan
                    tspan.text(line.join(' '));
                    // Restore the last word we removed from the last line for the next line array
                    line = [word];
                    // Generate a new tspan with the last word
                    tspan = text.append('tspan')
                        .attr('x', 0)
                        .attr('y', y)
                        .attr('dx', dx + 'px')
                        .attr('dy', (++lineNumber * lineHeight) + dy + 'px')
                        .text(word);
                }
            }
        });
    }

    /**
     * Pie Related Methods
     */
    computePieEndAngle(data, daysTotal) {
        return this.TAU * data.reduce((acc, c) => acc + c.days, 0) / daysTotal;
    }

    isInsidePie(center, radius, point) {
        let pieRadiusSquared = radius * radius;
        let pointDistanceSquared = Math.pow(point.x - center.x, 2) + Math.pow(point.y - center.y, 2);

        return pointDistanceSquared < pieRadiusSquared;
    }

    adjustWedgeDaysIfNeeded(insertIndex) {
        let clusterDataArray = this.SequencerStateService.data.svgSettings.donut
            .d3Donut(this.SequencerStateService.data.model.pieClusters)
            .sort(function(a, b) {
                return a.data.order - b.data.order;
            });

        let selectedClusters = this.SequencerStateService.data.selected.clusters;
        let newDays = selectedClusters.reduce((acc, c) => acc + c.days, 0);
        let existingDays = clusterDataArray.map(c => c.data).reduce((acc, c) => acc + c.days, 0) - newDays;
        let totalDays = this.SequencerStateService.data.totalDays.value;

        // If adjustment required
        if (existingDays + newDays > totalDays) {
            let leftDays = totalDays - existingDays;
            let newClustersLength = selectedClusters.length;

            if (leftDays >= newClustersLength) {
                let perClusterDays = Math.floor(leftDays / newClustersLength);
                let extraDays = Math.floor(leftDays % newClustersLength);
                selectedClusters.forEach(cluster => {
                    cluster.days = perClusterDays + (extraDays > 0 ? 1 : 0);
                    extraDays--;
                });
            } else {
                selectedClusters.forEach(cluster => {
                    cluster.days = 1;
                });
                let requireDays = newClustersLength - leftDays;
                let deltaIndex = 0;

                while (requireDays) {
                    // decide which neighbor to trim down
                    let beforeIndex = (insertIndex - deltaIndex <= 0) ? 0 : (insertIndex - (deltaIndex + 1));
                    let afterIndex = (insertIndex + deltaIndex < clusterDataArray.length) ? (insertIndex + deltaIndex) : (clusterDataArray.length - 1);

                    let neighborDaysBefore = clusterDataArray[beforeIndex].data.days;
                    let neighborDaysAfter = clusterDataArray[afterIndex].data.days;

                    // Start with larger one
                    if (neighborDaysBefore > neighborDaysAfter) {
                        if (clusterDataArray[beforeIndex].data.days - 1 >= requireDays) {
                            clusterDataArray[beforeIndex].data.days -= requireDays;
                            requireDays = 0;
                        } else {
                            requireDays -= (clusterDataArray[beforeIndex].data.days - 1);
                            clusterDataArray[beforeIndex].data.days = 1;
                        }

                        if (requireDays) {
                            if (clusterDataArray[afterIndex].data.days - 1 >= requireDays) {
                                clusterDataArray[afterIndex].data.days -= requireDays;
                                requireDays = 0;
                            } else {
                                requireDays -= (clusterDataArray[afterIndex].data.days - 1);
                                clusterDataArray[afterIndex].data.days = 1;
                            }
                        }
                    } else {
                        if (clusterDataArray[afterIndex].data.days - 1 >= requireDays) {
                            clusterDataArray[afterIndex].data.days -= requireDays;
                            requireDays = 0;
                        } else {
                            requireDays -= (clusterDataArray[afterIndex].data.days - 1);
                            clusterDataArray[afterIndex].data.days = 1;
                        }

                        if (requireDays) {
                            if (clusterDataArray[beforeIndex].data.days - 1 >= requireDays) {
                                clusterDataArray[beforeIndex].data.days -= requireDays;
                                requireDays = 0;
                            } else {
                                requireDays -= (clusterDataArray[beforeIndex].data.days - 1);
                                clusterDataArray[beforeIndex].data.days = 1;
                            }
                        }
                    }

                    deltaIndex++;
                    requireDays = 0;
                }
            }
        }
    }

    convertRadianToDegree(radians) {
        return radians * (180 / Math.PI);
    }

    computeAngle(x, y) {
        // returns the angle from 12 o'clock and positive is clockwise, returns a number between 0 and 2pi
        // note: arg y must be positive up
        let angle = Math.atan2(x, y); // yes, args are in reverse order for this function as defined - see previous comment
        if (angle < 0) {
            // resulting angle is between 0 and 2pi
            angle += this.TAU;
        }

        return angle;
    }

    computeWedgeIndex(mouseAngle, pieAngles, split, incomingAngle = 0) {
        // assume that the first arc begins at angle 0 and the arcs are contiguous
        // pass in 'split' to get the nearest insertion index
        // otherwise, returns which wedge the mouseAngle is in
        if (pieAngles.length === 0) {
            return 0;
        }
        let extentAngle = pieAngles[pieAngles.length - 1].endAngle;
        // angle that bisects the space remaining with the new wedge in place
        let emptySplit = (this.TAU + Math.min(extentAngle + incomingAngle, this.TAU)) / 2;
        let index = pieAngles.length;

        for (let j = 1; j < pieAngles.length; j++) {
            let thresholdAngle1 = (!split) ? pieAngles[j - 1].endAngle : (pieAngles[j - 1].endAngle + pieAngles[j - 1].startAngle) / 2;
            let thresholdAngle2 = (!split) ? pieAngles[j].endAngle : (pieAngles[j].endAngle + pieAngles[j].startAngle) / 2;
            if (mouseAngle < thresholdAngle1) {
                index = 0;
                continue;
            }
            if (mouseAngle > thresholdAngle2) {
                continue;
            }
            index = j;
            break;
        }
        if (mouseAngle > emptySplit) {
            index = 0;
        }

        return index;
    }

    isHoliday(date) {
        return typeof this.SequencerStateService.data.holidays[date.format('YYYY-MM-DD')] !== 'undefined';
    }

    isBlockedDay(date) {
        return typeof this.SequencerStateService.data.blockedDays[date.format('YYYY-MM-DD')] !== 'undefined';
    }

    isWeekend(date) {
        return (date.isoWeekday() === 6 || date.isoWeekday() === 7);
    }

    addWeekdays(date, days) {
        let date_new = moment(date); // use a clone
        while (days > 0) {
            date_new = date_new.add(1, 'days');
            // decrease 'days' only if it's a weekday.
            if (!this.isWeekend(date_new) && !this.isHoliday(date_new)) {
                days -= 1;
            }
        }
        return date_new;
    }

    getValidWeekDate(date, reverse = false) {
        let date_new = moment(date).startOf('day');
        let valid = false;

        while (!valid) {
            valid = true;
            if (this.isWeekend(date_new) || this.isHoliday(date_new)) {
                valid = false;
                date_new.add((reverse ? -1 : 1), 'day');
            }
        }

        return date_new;
    }

    // its a tweak to getValidWeekDate method because we are decrement date in
    // case of Resources And Assessments
    getValidWeekDateDecrement(date) {
        let date_new = moment(date).startOf('day');
        let valid = false;

        while (!valid) {
            valid = true;
            if (this.isWeekend(date_new) || this.isHoliday(date_new)) {
                valid = false;
                date_new.subtract(1, 'day');
            }
        }

        return date_new;
    }

    decrementValidDays(date, days) {
        let date_new = this.getValidWeekDateDecrement(moment(date));
        let totalDays = 0;

        if (days === 0) {
            return date_new;
        }

        while (totalDays !== days) {
            date_new.subtract(1, 'day');
            if (!this.isWeekend(date_new) && !this.isHoliday(date_new)) {
                totalDays++;
            }
        }

        return date_new;
    }

    incrementValidDays(date, days) {
        let date_new = this.getValidWeekDateDecrement(moment(date));
        let totalDays = 0;

        if (days === 0) {
            return date_new;
        }

        while (totalDays !== days) {
            date_new.add(1, 'day');
            if (!this.isWeekend(date_new) && !this.isHoliday(date_new)) {
                totalDays++;
            }
        }

        return date_new;
    }

    findNumOfWorkingDays(start_orig, end_orig) {
        let dateItr = moment(start_orig).startOf('day');
        let start = moment(start_orig).startOf('day');
        let end = moment(end_orig).startOf('day');
        let numOfWorkingDays = 0;

        if (start.isAfter(end)) {
            return numOfWorkingDays;
        }

        while (!dateItr.isAfter(end)) {
            if (!this.isHoliday(dateItr) && !this.isWeekend(dateItr)) {
                numOfWorkingDays++;
            }
            dateItr.add(1, 'day');
        }

        return numOfWorkingDays;
    }

    moveDragObject(d) {
        let coords = d3Mouse(this.SequencerStateService.data.svgSelections.root.node());
        let regionId;

        // Dragging from region accordion
        if (d.region) {
            regionId = d.region.id;
        // Dragging from cluster accordion
        } else if (d.cluster) {
            regionId = d.cluster.region;
        // Dragging from pie
        } else if (d.data && d.data.region) {
            regionId = d.data.region.id;
        } else if (d.data && d.data.cluster) {
            regionId = d.data.cluster.region;
        }

        this.SequencerStateService.data.svgSelections.dragScreenOverlay.classed('active', true);
        this.SequencerStateService.data.svgSelections.dragObject
            .attr('transform', this.UtilsService.translateString(coords[0], coords[1]))
            .classed('dragging', true)
            .classed('region-background-' + regionId, true);
    }

    removeDragObject() {
        this.SequencerStateService.data.svgSelections.dragScreenOverlay.classed('active', false);
        this.SequencerStateService.data.svgSelections.dragObject.attr('class', '');
    }

    /**
     * SVG Rendering - Functions
     */
    initializeSequencer(svgElement) {
        // Store svg root d3 selection
        this.SequencerStateService.data.svgSelections.root = svgElement;
        this.initSVGSelections();
        this.initAccordionList();
        this.initPieContainer();
        this.initUndoButton();
        this.SequencerStateService.data.initialized = true;

        this.updateView(); // the initial draw
    }

    initSVGSelections() {
        this.SequencerStateService.data.svgSelections.dragObject = this.SequencerStateService.data.svgSelections.root.select('#drag-object');
        this.SequencerStateService.data.svgSelections.dragScreenOverlay = this.SequencerStateService.data.svgSelections.root.select('#dragging-screen-overlay');
    }

    initPieContainer() {
        this.SequencerStateService.data.svgSelections.pieLayer = this.SequencerStateService.data.svgSelections.root.select('.pie-layer');
        this.SequencerStateService.data.svgSelections.pieBack = this.SequencerStateService.data.svgSelections.pieLayer.select('.pie-back');
        // These elements will be pushed to the front of the pie each view update (on top of cluster wedges)
        this.SequencerStateService.data.svgSelections.pieFront = this.SequencerStateService.data.svgSelections.pieLayer.select('.pie-front');
        // Save reference to this element to update summary text later
        this.SequencerStateService.data.svgSelections.summaryText = this.SequencerStateService.data.svgSelections.pieLayer.select('.summary-text');

        // region pie
        this.SequencerStateService.data.svgSettings.pie.arc = d3Arc()
            .innerRadius(this.SequencerStateService.data.pie.radiusInner)
            .outerRadius(this.SequencerStateService.data.pie.radiusIntermediate);

        this.SequencerStateService.data.svgSettings.pie.d3Pie = d3Pie()
            .value(function(d: any) {
                return d.days;
            })
            .startAngle(0);

        // cluster wedges when a region is selected
        this.SequencerStateService.data.svgSettings.donut.arc = d3Arc()
            .innerRadius(this.SequencerStateService.data.pie.radiusInner)
            .outerRadius(this.SequencerStateService.data.pie.radiusOuter);

        this.SequencerStateService.data.svgSettings.donut.d3Donut = d3Pie()
            .value(function(d: any) {
                return d.days;
            })
            .startAngle(0);
    }

    initUndoButton() {
        this.SequencerStateService.data.svgSelections.root.select('.sequencer-undo-button')
            .on('click', () => { this.revertToLastSnapshot(); });
    }

    initAccordionList() {
        this.SequencerStateService.data.svgSelections.accordionLayer = this.SequencerStateService.data.svgSelections.root.select('.accordion-list');
        this.renderAccordionRegionGroups(this.SequencerStateService.data.svgSelections.accordionLayer);
    }

    renderAccordionRegionGroups(accordionContainer) {
        let regions = this.SequencerStateService.data.model.mapRegions
            .filter(function(obj) {
                return (!obj.region.wormhole || obj.region.id === 10);
            });
        let consecutiveRegions = 0;
        this.SequencerStateService.data.accordion.initialX = this.SequencerStateService.data.svgSettings.width / 2 + 40;
        this.SequencerStateService.data.accordion.endingX = this.SequencerStateService.data.svgSettings.width / 2 - 100;

        let regionGroup = accordionContainer.selectAll('.region-group')
            .data(regions);

        regionGroup.enter()
            .append('g')
            .classed('region-group', true)
            .attr('id', (d) => `region-group-${d.region.id}`)
            .attr('transform', (d) => {
                d.ext.x = this.SequencerStateService.data.accordion.initialX;
                d.ext.y = this.SequencerStateService.data.accordion.boxHeight * consecutiveRegions++ + this.SequencerStateService.data.accordion.initialYDisplacement;
                d.ext.visibleY = this.SequencerStateService.data.accordion.initialYDisplacement;

                return this.UtilsService.translateString(d.ext.x, d.ext.y);
            })
            .each((d, i, nodes) => this.renderAccordionRegions(d, i, nodes));

        regionGroup.exit().remove();

        this.hideRegionPopup();
        let popupElements = document.querySelectorAll('.region-popup-group');
        popupElements.forEach(popupGroup => {
            accordionContainer.node().appendChild(popupGroup);
        });
    }


    renderAccordionRegions(region, seq, container) {
        let regionGroup = d3Select(container[seq]);
        let regionDragBehavior = d3Drag()
            .subject((d: any) => {
                let regionPosition = this.UtilsService.getXYFromTranslate(d3Select('#region-group-' + d.region.id).attr('transform'));
                return {
                    x: regionPosition[0] + 15, // Middle of box
                    y: regionPosition[1] + 15
                };
            })
            .on('start', this.handleAccordionToPieDragStart.bind(this))
            .on('drag', this.handleAccordionToPieDrag.bind(this))
            .on('end', this.handleAccordionToPieDragEnd.bind(this));

        // Region title container
        regionGroup.append('rect')
            .classed('region-title-container', true)
            .attr('width', this.SequencerStateService.data.accordion.endingX)
            .attr('x', 30)
            .attr('y', 1)
            .on('mouseover', function(d) {
                (<SVGElement>(<SVGElement>this).parentNode).classList.add('highlighted');
            })
            .on('mouseout', function(d) {
                (<SVGElement>(<SVGElement>this).parentNode).classList.remove('highlighted');
            })
            .on('click', this.showRegionPopup.bind(this));

        // Region title text
        regionGroup.append('text')
            .classed('region-title-text', true)
            .attr('x', '2.5rem')
            .attr('y', '1.25rem')
            .attr('width', this.SequencerStateService.data.accordion.endingX - 40) // Margin, and room for icon
            .text(function(d: any) {
                return d.region.wormhole ? 'Other' : d.region.short_name;
            })
            .each(this.truncateText.bind(this));

        // Region legend color
        regionGroup.append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('class', function(d: any) {
                return `region-legend region-background-${d.region.id}`;
            })
            .call(regionDragBehavior); // Set the drag behavior trigger on the larger rect so it's easier to click/drag

        // Region dragger icon
        if (region.region.wormhole) {
            this.renderNonInstructionalPopup();
        } else {
            regionGroup.append('text')
                .classed('map-region-dragger', true)
                .attr('x', 15)
                .attr('y', 20)
                .text('\u0066');
            // Popup/Expanded region details group
            regionGroup.append('g')
                .classed('region-popup-group', true)
                .attr('id', function(d: any) {
                    return 'region-popup-group-' + d.region.id;
                })
                .style('transform', function(d: any) {
                    return 'translate(' + d.ext.x + 'px, ' + d.ext.y + 'px)';
                })
                .each(this.renderRegionPopup.bind(this));
        }
    }

    truncateText(d, seq, container) {
        let selection = d3Select(container[seq]);
        let textLength = selection.node().getComputedTextLength();
        let text = selection.text();

        while (textLength > selection.attr('width') && text.length > 0) {
            text = text.slice(0, -1);
            selection.text(text + '...');
            textLength = selection.node().getComputedTextLength();
        }
    }

    renderNonInstructionalPopup() {
        let niRegions = this.SequencerStateService.data.model.mapRegions
            .filter(function(obj) {
                return obj.region.wormhole;
            });

        let instPopup = this.SequencerStateService.data.svgSelections.accordionLayer
            .append('g')
            .datum(niRegions[0])
            .classed('region-popup-group', true)
            .attr('id', function(d) {
                return 'region-popup-group-' + d.region.id;
            })
            .style('transform', function(d) {
                return 'translate(' + d.ext.x + 'px, ' + d.ext.y + 'px)';
            });

        instPopup
            .selectAll('inst-region-group')
            .data(niRegions).enter()
            .append('g')
            .classed('inst-region-group', true)
            .attr('id', function(d) {
                return 'inst-region-group-' + d.region.id;
            })
            .each(this.renderRegionPopup.bind(this));
    }

    renderRegionPopup(region, seq, container) {
        let instructionalPeriod = (!region.region.wormhole || region.region.id === 10);
        if (instructionalPeriod) {
            this.SequencerStateService.data.regionPopup.clusterHeight = 0;
        }
        this.SequencerStateService.data.accordion.popupWidth = this.SequencerStateService.data.svgSettings.width / 2 - 70;
        let popupGroup = d3Select(container[seq]);
        let regionPopupDragBehavior = d3Drag()
            .subject((d: any) => {
                let regionPosition = this.UtilsService.getXYFromTranslate(d3Select('#region-group-1').attr('transform'));
                let dragger = d3Select('#popup-region-dragger-' + d.region.id);
                return {
                    x: regionPosition[0] + parseInt(dragger.attr('x')),
                    y: regionPosition[1] + parseInt(dragger.attr('y'))
                };
            })
            .on('start', this.handleAccordionToPieDragStart.bind(this))
            .on('drag', this.handleAccordionToPieDrag.bind(this))
            .on('end', this.handleAccordionToPieDragEnd.bind(this));

        if (instructionalPeriod) {
            // Popup background rect
            popupGroup.append('rect')
                .classed('popup-region-rect', true)
                .attr('width', this.SequencerStateService.data.accordion.popupWidth);

            // Region legend color
            popupGroup.append('rect')
                .attr('class', function(d: any) {
                    return 'popup-region-legend region-background-' + d.region.id;
                })
                .attr('width', 30);

            // Popup close icon / button
            popupGroup.append('text')
                .classed('popup-region-close', true)
                .attr('x', this.SequencerStateService.data.accordion.popupWidth - 15)
                .attr('y', 20)
                .text('\u0056')
                .on('click', d => {
                    this.SequencerStateService.data.selected.region = null;
                    this.SequencerStateService.data.selected.cluster = null;
                    this.hideRegionPopup();
                });
        }
        // Popup region dragger icon
        popupGroup.append('text')
            .classed('popup-region-dragger', true)
            .attr('id', function(d: any) {
                return 'popup-region-dragger-' + d.region.id;
            })
            .attr('x', 15)
            .attr('y', 20 + this.SequencerStateService.data.regionPopup.clusterHeight)
            .text('\u0066')
            .call(regionPopupDragBehavior);

        // Popup region name text
        popupGroup.append('text')
            .text(function(d: any) {
                return d.region.short_name;
            })
            .classed('popup-region-text', true)
            .attr('dy', 12 + this.SequencerStateService.data.regionPopup.clusterHeight)
            .attr('dx', 40)
            .attr('y', 8)
            .call(this.wrapText.bind(this), this.SequencerStateService.data.accordion.popupWidth - 80)
            .each((d: any, i, container) => {
                this.SequencerStateService.data.regionPopup.clusterHeight += container[i].getBBox().height + (d.region.wormhole ? 7 : 10);
            });

        if (region.region.id === 10 || region.region.id === 11) {
            // Do not expand region into clusters since there's only one with the same name
            return null;
        }
        // Data model for clusters in region
        let clusterEnterGroup = popupGroup.selectAll('cluster-group')
            .data(function(d: any) {
                return d.clusters;
            }).enter();

        // Clusters group
        clusterEnterGroup.append('g')
            .classed('cluster-group', true)
            .attr('id', function(d: any) {
                return 'cluster-group-' + d.cluster.id;
            })
            .call(this.renderPopupClusters.bind(this));
    }

    renderPopupClusters(selection) {
        let clusterDragBehavior = d3Drag()
            .subject((d: any) => {
                // Translate all popups to the top so we can use these coordinates
                let popupTranslate = this.UtilsService.getXYFromTranslate(d3Select('#region-group-1').attr('transform'));
                let clusterTranslate = this.UtilsService.getXYFromTranslate(d3Select('#cluster-group-' + d.cluster.id).attr('transform'));
                let filterDragger = d3Select('#cluster-group-' + d.cluster.id + ' .map-cluster-dragger');

                return {
                    x: popupTranslate[0] + clusterTranslate[0] + parseFloat(filterDragger.attr('x')),
                    y: popupTranslate[1] + clusterTranslate[1] + parseFloat(filterDragger.attr('y'))
                };
            })
            .on('start', this.handleAccordionToPieDragStart.bind(this))
            .on('drag', this.handleAccordionToPieDrag.bind(this))
            .on('end', this.handleAccordionToPieDragEnd.bind(this));


        // Cluster text container
        selection.append('rect')
            .attr('class', function(cluster) {
                return 'cluster-group-rect';
            })
            .attr('width', this.SequencerStateService.data.accordion.popupWidth - 60)
            .attr('height', 0)
            .on('click', this.highlightCluster.bind(this));

        // Cluster dragger icon
        selection.append('text')
            .attr('class', function(d) {
                return 'map-cluster-dragger region-background-' + d.cluster.region;
            })
            .attr('x', 10)
            .attr('y', 23)
            .text('\u0066')
            .call(clusterDragBehavior);

        // Cluster name text
        selection.append('text')
            .classed('cluster-group-text', true)
            .text(function(d) {
                return d.cluster.name;
            })
            .attr('dy', 12)
            .attr('dx', 30)
            .attr('y', 9)
            .call(this.wrapText.bind(this), this.SequencerStateService.data.accordion.popupWidth - 80)
            .each((d, i, container) => {
                d3Select('#cluster-group-' + d.cluster.id)
                    .attr('transform', 'translate(' + this.SequencerStateService.data.regionPopup.innerPadding.x + ',' + (this.SequencerStateService.data.regionPopup.clusterHeight) + ')')
                    .select('.cluster-group-rect')
                    .attr('height', container[i].getBBox().height + this.SequencerStateService.data.regionPopup.innerPadding.y);
                this.SequencerStateService.data.regionPopup.clusterHeight += container[i].getBBox().height + (d.cluster.wormhole ? 6 : this.SequencerStateService.data.regionPopup.innerPadding.y + this.SequencerStateService.data.regionPopup.outerPadding.y);
            });
    }
}
