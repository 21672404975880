import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbRatingModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormModule } from '../_custom/mat-form.module';

import { SharedModule } from '../shared/shared.module';

import { ResourcerService } from './resourcer.service';
import { ResourcerComponent } from './resourcer.component';
import { ResourceCardComponent } from './card/resource-card.component';
import { ResourcerPaginationComponent } from './pagination/resourcer-pagination.component';

import { MapTaggingModalComponent } from './map-tagging/map-tagging-modal.component';
import { ResourceDetailsModalComponent } from './card/resource-details-modal.component';
import { ResourceManagementModalComponent } from './resource-management/resource-management-modal.component';


@NgModule({
    imports: [
        CommonModule,
        NgbRatingModule,
        NgbPaginationModule,
        MatTooltipModule,
        MatExpansionModule,
        MatFormModule,
        SharedModule,
    ],
    providers: [
        ResourcerService,
    ],
    declarations: [
        ResourcerComponent,
        ResourceCardComponent,
        ResourcerPaginationComponent,
        MapTaggingModalComponent,
        ResourceDetailsModalComponent,
        ResourceManagementModalComponent,
    ],
    entryComponents: [
        MapTaggingModalComponent,
        ResourceDetailsModalComponent,
        ResourceManagementModalComponent,
    ],
    exports: [
        ResourcerComponent,
        ResourceCardComponent,
    ],
})
export class ResourcerModule { }
