<div class="modal-header">
    <div class="modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </div>

    <div class="flex-row">
        <h4 class="modal-title">Student Feedback</h4>
    </div>
</div>

<div class="modal-body">
    <div class="flex-row flex-wrap align-items-start justify-center">
        <div class="feedback-chart-container flex-column flex-none" *ngFor="let feedbackQuestion of feedback[0]">
            <div class="bold text-center feedback-question">{{ feedbackQuestion.feedback_question }}</div>
            <student-feedback-chart [responses]="feedbackQuestion.responses"></student-feedback-chart>
        </div>
    </div>

    <mat-accordion [multi]="false">
        <mat-expansion-panel *ngFor="let feedbackQuestion of feedback[1]">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>{{ feedbackQuestion.feedback_question }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex-column">
                <div *ngFor="let response of feedbackQuestion.responses">{{ response.response }}</div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
