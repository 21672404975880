<div class="flex-row whiteframe">
    <svg #svgRoot class="sequencer-svg" [attr.viewBox]="'0 0 ' + SequencerStateService.data.svgSettings.width + ' ' + SequencerStateService.data.svgSettings.height" preserveAspectRatio="xMidYMid meet" version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" [attr.height]="SequencerStateService.data.svgSettings.height">
        <defs>
            <filter id="inset-shadow">
                <feFlood flood-color="black"/>
                <feComposite operator="xor" in2="SourceGraphic"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite operator="in" in2="SourceGraphic"/>
                <feComposite operator="over" in2='SourceGraphic'/>
            </filter>
        </defs>

        <line class="divider-line" [attr.x1]="SequencerStateService.data.svgSettings.width / 2" [attr.x2]="SequencerStateService.data.svgSettings.width / 2" y1="20" [attr.y2]="SequencerStateService.data.svgSettings.height - 20"></line>

        <g class="accordion-list"></g>

        <rect x="0" y="0" height="100%" width="100%" id="dragging-screen-overlay"></rect>

        <g class="pie-layer" [attr.transform]="'translate(' + SequencerStateService.data.pie.center.x + ', ' + SequencerStateService.data.pie.center.y + ')'">
            <circle class="donut-outer" [attr.r]="SequencerStateService.data.pie.radiusIntermediate"></circle>

            <g class="pie-back"></g>

            <g class="pie-front">
                <g id="tab-lines"></g>

                <circle class="donut-inner" [attr.r]="SequencerStateService.data.pie.radiusInner"></circle>

                <text class="summary-text" x="0" y="0" dx=0 dy="-20"></text>

                <g class="sequencer-undo-button" transform="translate(-37, 50)">
                    <rect x="0" y="0" width="80" height="40" class="button-outline"></rect>
                    <text x="12" y="26" class="button-icon">&#x0070;</text>
                    <text x="30" y="26" class="button-text">Undo</text>
                </g>

                <g id="cluster-days"></g>
                <g id="tabs"></g>
            </g>
        </g>

        <text id="drag-object">&#x0066;</text>
    </svg>
</div>

<div class="flex-row">
    <div class="flex-row flex-50 justify-center show-dates-row">
        <mat-slide-toggle id="show-days-toggle" [disableRipple]="true" (change)="toggleDateRepresentation()">Show Dates</mat-slide-toggle>
    </div>
</div>

<div id="cluster-hover-popover">
    <div class="popover-container flex-column justify-space-between">
        <div class="triangle-tip"></div>
        <div class="flex-row align-items-center">
            <span class="region-legend"></span>
            <span class="region-title-text"></span>
        </div>

        <div class="hr"></div>

        <div class="flex-row">
            <span class="cluster-title-text"></span>
        </div>
    </div>
</div>
