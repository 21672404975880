import { Component, OnInit } from '@angular/core';

import { StateService } from '@uirouter/core';

import { appInfo } from '@/app.constant';
import { SessionService } from '../core/service/session.service';
import { AuthService } from '../core/service/auth.service';
import { AnalyticsService } from '../core/service/analytics.service';
import { GrowlerService } from '../core/service/growler.service';
import { ModalService } from '../shared/service';
import { LoginService } from './login.service';

import './login.component.scss';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    // styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    public readonly appInfo = appInfo;
    public showCaptcha: boolean = false;

    constructor(
        private $state: StateService,
        public sessionService: SessionService,
        private AuthService: AuthService,
        private AnalyticsService: AnalyticsService,
        private GrowlerService: GrowlerService,
        private ModalService: ModalService,
        public LoginService: LoginService,
    ) {}

    public ngOnInit() {
        this.AuthService.getCaptcha()
            .then(response => {
                this.showCaptcha = response.result.captcha;
            })
            .catch(console.warn);

        if (this.$state.current.name === 'login') {
            this.showForm('login');
            if (this.$state.params.key) {
                this.AuthService.loginWithKey(this.$state.params.key).catch(console.warn);
            }
        // } else if (this.$state.current.name === 'register') {
        //     this.showForm('registration');
        } else if (this.$state.current.name === 'practice_register') {
            this.showForm('practice_registration');
        } else if (this.$state.current.name === 'changePassword') {
            if (!this.$state.params.email || !this.$state.params.key) {
                this.GrowlerService.error('It appears your password reset link is broken. Please try again.');

                return this.showForm('login');
            }
            this.showForm('setPassword');
        } else if (this.$state.current.name === 'activateEmail') {
            if (!this.$state.params.email || !this.$state.params.key) {
                this.GrowlerService.error('The link you followed does not seem to be valid. Please check your email again.');

                return this.showForm('login');
            }
            this.showForm('activateEmail');
            this.activateEmail();
        }
    }

    public showForm(formName) {
        this.LoginService.activeForm = formName;
    }

    private activateEmail() {
        this.AuthService.activateEmail(this.$state.params.email, this.$state.params.key)
            .then(() => {
                this.GrowlerService.success('Your email has been confirmed.', 'Thank you!');
            })
            .catch(console.warn)
            .finally(() => {
                this.showForm('login');
            });
    }
}
