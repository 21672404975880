import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService } from '@uirouter/core';

import { AssessmentStateService } from '../../common/assessment-state.service';
import { FeedbackService } from '../../../shared/service/feedback.service';

@Component({
    selector: 'test-finalize-modal',
    templateUrl: './test-finalize-modal.component.html'
})
export class TestFinalizeModalComponent {

    constructor(
        private $state: StateService,
        public activeModal: NgbActiveModal,
        public AssessmentStateService: AssessmentStateService,
        private FeedbackService: FeedbackService,
    ) {}

    public submitFeedback(goToReport = false) {
        if (this.AssessmentStateService.data.feedback && this.AssessmentStateService.data.feedback.length > 0) {
            const options = {
                trid: this.AssessmentStateService.data.tr,
                a_ssp: this.AssessmentStateService.data.ssp,
            };
            this.FeedbackService.submitSurvey(this.AssessmentStateService.data.feedback, options)
                .catch(console.warn);
        }

        this.activeModal.close('assessment completed');

        if (goToReport) {
            this.$state.go('report.test', {trUid: this.AssessmentStateService.data.trUid});
        }
    }
}
