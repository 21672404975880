<div id="minimap" #minimap dragDrop [enableDrag]="addDragging" [@EnterLeave]="_isOpen ? 'open' : 'closed'" class="flex-column justify-end unselectable">
    <button class="btn modal-close" (click)="closePanel()">
        <i class="icon icon-x"></i>
    </button>

    <svg xmlns="http://www.w3.org/2000/svg" class="minimap-svg" [attr.viewBox]="'2000 0 25000 16000'">
        <g class="field-layer" *ngFor="let field of mapData?.fields">
            <g class="field-group" *ngIf="!field.wormhole">
                <g [attr.transform]="'translate( ' + field.attributes.x + ', ' + field.attributes.y + ')'">
                    <text class="field-title" [attr.x]="field.attributes.title_x" [attr.y]="field.attributes.title_y">{{ field.name }}</text>
                </g>

                <g class="region-group" *ngFor="let region of field.regions">
                    <rect class="region-background" [ngClass]="['region-background-' + region.id]" [class.opacity-50]="highlightAssps?.length && !regionInAssp(region)" [attr.x]="region.attributes.x" [attr.y]="region.attributes.y" width="4320" height="2880" rx="432" ry="432"></rect>

                    <g class="cluster-group" *ngFor="let cluster of region.clusters">
                        <g class="construct-group" *ngFor="let construct of cluster.constructs">
                            <circle class="construct-background" [class.inactive]="highlightAssps?.length && !constructInAssp(construct)" [attr.r]="construct.attributes.radius" [attr.cx]="construct.attributes.cx" [attr.cy]="construct.attributes.cy"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </g>

        <g class="zoom-element">
            <rect class="minimap-viewport"></rect>
        </g>
    </svg>
</div>

<button id="minimap-toggle" class="btn ghost-button map-fab flex-none inline-flex align-items-center justify-center" [@EnterLeave]="_isOpen ? 'closed' : 'open'" (click)="openPanel()">
    <i class="icon icon-map-marker"></i>
</button>
