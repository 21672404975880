import { Component, Input, Output, ViewChild, EventEmitter, ElementRef, OnInit, AfterViewInit } from '@angular/core';

import { StateService } from '@uirouter/core';

import { UtilsService } from '@/core/service/utils.service';
import { AssessmentService } from '../../assessment.service';
import { AssessmentStateService } from '../../common/assessment-state.service';
import { ReportService } from '@/report/report.service';
import { ordinal } from '@/_helpers/transforms';

import './stack-ladder.component.scss';


@Component({
    selector: 'stack-ladder',
    templateUrl: './stack-ladder.component.html',
    // styleUrls: ['./stack-ladder.component.scss'],
})
export class StackLadderComponent implements OnInit, AfterViewInit {

    @Input() construct: any;
    @Input() startingLevel: number;
    @Input() sessionSummary: boolean;
    @Input() formatStacks: boolean;
    @Input() assessmentStage: boolean;
    @Input() truncateChars: number = 100;
    @Output() viewQuestionByIdx = new EventEmitter<number>();
    @ViewChild('stackLadder', { static: false }) stackLadder: ElementRef;

    private scrollContainer: any;

    constructor(
        private element: ElementRef,
        private $state: StateService,
        private UtilsService: UtilsService,
        public AssessmentService: AssessmentService,
        public AssessmentStateService: AssessmentStateService,
        private ReportService: ReportService,
    ) {}

    public ngOnInit() {
        // Make ladder data available to other components
        this.AssessmentStateService.stackLadder.construct = this.construct;
        // If starting level is passed in (optional attr) initialize the ladder at that level
        if (this.startingLevel) {
            this.AssessmentStateService.stackLadder.stack = this.construct.stacks.find(s => s.order === Number(this.startingLevel));
        }
        // Deselect current stack during session summary review
        if (this.sessionSummary) {
            this.AssessmentStateService.stackLadder.stack = null;
        }
        // Modify backend data to match practice test scorecard data
        if (this.formatStacks) {
            this.construct.stacks.forEach(stack => {
                stack.itemsTaken = stack.test_questions.map(question => {
                    question.itemNumberInSequence = question.seq + 1;
                    question.score = (question.percent_revised === 100 || question.percent_points === 100) ? 'correct' : 'incorrect';
                    question.feedbackPrimary = question.score;
                    question.triesOrdinal = ordinal(question.tries);
                    return question;
                });
            });
        }
    }

    public ngAfterViewInit() {
        let ladderBlockNode = this.element.nativeElement.querySelector('.ladder-block');
        // Scroll to current stack level
        this.AssessmentStateService.stackLadderInitChange.next({
            totalLevels: this.construct.stacks.length,
            startingLevel: Number(this.startingLevel),
            ladderBlock: ladderBlockNode
        });
        
        setTimeout(this.animateStackLadder.bind(this));
    }

    public viewQuestion(question) {
        // Only allow question review at the end of practice test
        // since there can only be one "active" question at a given time
        if (this.sessionSummary) {
            // Handle special case in student practice report page
            if (this.formatStacks) {
                // Fetch question data (stems, foils)
                return this.viewQuestionByIdx.emit(question.seq);
            }
            this.AssessmentService.loadQuestion(question);
        }
    }

    public selectStack(stack) {
        if (!this.sessionSummary && stack.available_items > 0) {
            this.AssessmentStateService.stackLadder.stack = stack;
        }
    }

    private animateStackLadder(): void {
        this.scrollContainer = this.stackLadder.nativeElement;
        this.scrollContainer.scrollIntoView(false);
    }
}
