import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { mapSizesAndBounds } from '@/app.constant';
import { UtilsService } from '../../../core/service/utils.service';

import './cluster-graph.component.scss';


@Component({
    selector: 'cluster-graph',
    templateUrl: './cluster-graph.component.svg.html',
    // styleUrls: ['./cluster-graph.component.scss'],
    host: {
        class: 'cluster-graph',
    },
})
export class ClusterGraphComponent implements OnChanges {

    @Input() cluster: any;
    @Input() cumulativeScore: any;
    @Input() testScores: any;
    @Input() connectors: boolean;
    @Input() singleLetter: boolean;
    @Input() fullName: boolean;
    @Input() highlightedConstruct: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;
    public constructBgColor: string = '';

    constructor(
        public UtilsService: UtilsService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.cluster) {
            this.constructBgColor = `region-background-${this.cluster.parent.region}`;
        }
    }

    public openStacksPanel(construct) {
        if (this.cluster.selectedConstruct === construct) {
            this.cluster.selectedConstruct = null;
            return null;
        }
        this.cluster.selectedConstruct = construct;
    }

    public getViewBox(patternLevel) {
        if (patternLevel >= 3) {
            return '-550 -820 1100 1600';
        } else if (patternLevel === 2) {
            return '-550 -620 1100 1200';
        } else {
            return '-550 -420 1100 800';
        }
    }
}
