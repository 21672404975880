import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SequencerStateService } from '../sequencer-state.service';
import { CalendarService } from './calendar.service';

import './calendar-modal.component.scss';


@Component({
    selector: 'calendar-modal',
    templateUrl: './calendar-modal.component.html',
    // styleUrls: ['./calendar-modal.component.scss'],
})
export class CalendarModalComponent implements OnInit {

    public isMonth = true;
    public daysOfWeek = ['Sun', 'M', 'T', 'W', 'Th', 'F', 'Sat'];

    public daysScheduled: number;
    public totalCalendarDays: number;

    constructor(
        public activeModal: NgbActiveModal,
        private SequencerStateService: SequencerStateService,
        public CalendarService: CalendarService,
    ) {}

    public ngOnInit() {
        this.daysScheduled = this.SequencerStateService.getTotalPieClusterDays();
        this.totalCalendarDays = this.SequencerStateService.data.totalDays.value;
        this.CalendarService.generateCalendarData();
        this.CalendarService.buildMonth();
        this.CalendarService.buildWeek();
    }
}
