<form [formGroup]="rForm" class="practice-registration-form flex-column flex-auto space-children-column" (ngSubmit)="registerUser($event)">
    <div class="bold capitalize text-center">Registration</div>

    <div class="form-error-messages text-center" *ngIf="registerFailMessage">
        <div class="error-message body-regular">{{ registerFailMessage }}</div>
    </div>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>Email (for login and password recovery)</mat-label>
        <input matInput type="email" autocomplete="email" id="register-email" formControlName="email" name="registerEmail" [email]="true" (input)="resetFormErrors()" maxlength="150" required>
        <mat-error *ngIf="email.errors?.required">
            Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="email.errors?.email">
            Email is not <strong>valid</strong>
        </mat-error>
        <mat-error *ngIf="email.errors?.maxlength">
            Email cannot be longer than 150 characters
        </mat-error>
    </mat-form-field>
    <span class="body-small">Email will never be shared with third parties. Only <b><i>one</i></b> user per email address. </span>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>New Password</mat-label>
        <input matInput type="password" autocomplete="new-password" id="register-password" formControlName="password" (input)="resetFormErrors()" name="registerPassword" minlength="6" maxlength="75" required>
        <span matSuffix class="text-accent forgot-password text-right">at least 6 characters</span>
        <mat-error *ngIf="password.errors?.required">
            Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="password.errors?.minlength">
            Password must contain at least 6 characters
        </mat-error>
        <mat-error *ngIf="password.errors?.maxlength">
            Password cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>Password Confirmation</mat-label>
        <input matInput type="password" autocomplete="new-password" id="register-password-confirm" formControlName="passwordConfirm" name="registerPasswordConfirm" (input)="resetFormErrors()" minlength="6" maxlength="75" placeholder="Repeat password" required />
        <mat-error *ngIf="passwordConfirm.errors?.required">
            Password confirmation is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="passwordConfirm.errors?.minlength">
            Password must be at least 6 characters long
        </mat-error>
        <mat-error *ngIf="passwordConfirm.errors?.maxlength">
            Password cannot be longer than 75 characters
        </mat-error>
    </mat-form-field>

    <mat-error *ngIf="rForm.errors?.mismatchedPassword && (password.dirty && passwordConfirm.dirty)" class="no-margin body-xs">
        Passwords do not match
    </mat-error>

    <div class="bold text-center space-padding"><u>Information about Math Mapper User:</u></div>

    <mat-form-field appearance="standard" color="accent">
        <mat-label>First Name (for personalization)</mat-label>
        <input matInput type="text" autocomplete="first-name" id="register-name" formControlName="first_name" name="registerFirstName" (input)="resetFormErrors()" maxlength="30" required>
        <mat-error *ngIf="first_name.errors?.required">
            First name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="first_name.errors?.maxlength">
            First name cannot be longer than 30 characters
        </mat-error>
    </mat-form-field>

    <ng-select
        class="flex custom practice-register-location"
        formControlName="location"
        [items]="availLocation"
        appendTo="body"
        bindLabel="name"
        bindValue="id"
        [clearable]="true"
        [searchable]="true"
        name="location"
        placeholder="Location*">
    </ng-select>


    <div class="space-padding-small bold">Choose one:</div>

    <mat-button-toggle-group formControlName="user_type" (change)="updateUserType($event)">
        <mat-button-toggle *ngFor="let status of availUserType" class="flex" [value]="status.id" [aria-label]="status.name">
            {{ status.name }}
        </mat-button-toggle>
    </mat-button-toggle-group>

    <ng-select
        *ngIf="this.rForm.get('user_type').value == 1"
        class="flex custom space-padding-small practice-register-grade-level"
        formControlName="grade_level"
        [items]="availGradeLevel"
        appendTo="body"
        bindLabel="name"
        bindValue="id"
        [clearable]="true"
        [searchable]="false"
        name="grade_level"
        placeholder="Grade Level*">
    </ng-select>

    <ng-select
        *ngIf="this.rForm.get('user_type').value == 1"
        class="flex custom space-padding-small practice-register-school-type"
        formControlName="school_type"
        [items]="availSchoolType"
        appendTo="body"
        bindLabel="name"
        bindValue="id"
        [clearable]="true"
        [searchable]="false"
        name="school_type"
        placeholder="School type*">
    </ng-select>

    <ng-select
        *ngIf="this.rForm.get('user_type').value == 2"
        class="flex custom space-padding-small practice-register-grade-band"
        formControlName="grade_band"
        [items]="availGradeBand"
        appendTo="body"
        bindLabel="name"
        bindValue="id"
        [clearable]="true"
        [searchable]="false"
        name="grade_band"
        placeholder="Grade Band*">
    </ng-select>

    <mat-checkbox id="register-tou" class="space-padding" formControlName="terms_of_use" name="registerTou" [disableRipple]="true" required>
        I understand that this tool is for my personal use only<br/>and accept the <a href="#" class="tos-link" (click)="openTermsOfUse($event)">terms of use</a>.
    </mat-checkbox>

    <div class="g-recaptcha" align="center" *ngIf="captcha">
        <ngx-recaptcha2 #captchaElement
                        [siteKey]="captchaSiteKeyV2"
                        formControlName="recaptcha">
        </ngx-recaptcha2>
    </div>

    <div class="form-error-messages text-center" *ngIf="registerFailMessage">
        <div class="error-message body-regular">{{ registerFailMessage }}</div>
    </div>

    <button type="submit" class="btn btn-block primary-button alt-theme balloon" [disabled]="rForm.invalid">Create Account</button>
</form>
