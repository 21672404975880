import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '../../../core/service/analytics.service';

import './free-practice-intro-modal.component.scss';


@Component({
    selector: 'free-practice-intro-modal',
    templateUrl: './free-practice-intro-modal.component.html'
})
export class FreePracticeIntroModalComponent {

    @Input() public videoType: string;

    constructor(
        public activeModal: NgbActiveModal,
        private AnalyticsService: AnalyticsService,
    ) {}
}
