<div id="calculator" class="calculator" [class.visible]="CalculatorService.calculator.isOpen" dragDrop [enableDrag]="true">
    <div class="calculator-header">
        <div class="flex-row justify-end align-items-center">
            <div role="button" class="calculator-close" (click)="CalculatorService.operate('close', true)">
                <i class="icon icon-x"></i>
            </div>
        </div>
    </div>

    <div class="calculator-body">
        <div class="calculator-display">
            <div>{{ CalculatorService.calculator.displayVal }}</div>
            <div class="body-small text-black-50 limit-text" style="height: 16px;">
                <span>{{ CalculatorService.calculator.stored }}</span>
                <span [innerHTML]="CalculatorService.calculator.operation?.l"></span>
                <span>{{ CalculatorService.calculator.displayVal }}</span>
            </div>
        </div>

        <div class="calculator-keys">
            <div class="calculator-top-keys">
                <button *ngFor="let key of CalculatorService.calculator.topKeys" class="btn calculator-key" [class.active-key]="key.v === CalculatorService.calculator.operation?.v" [ngClass]="[key.c, key.s]" (click)="CalculatorService.pressButton(key)" [innerHTML]="key.l"></button>
            </div>
            <div class="calculator-left-keys">
                <button *ngFor="let key of CalculatorService.calculator.leftKeys" class="btn calculator-key" [ngClass]="[key.c, key.s]" (click)="CalculatorService.pressButton(key)">{{ key.l }}</button>
            </div>
            <div class="calculator-right-keys">
                <button *ngFor="let key of CalculatorService.calculator.rightKeys" class="btn calculator-key" [class.active-key]="key.v === CalculatorService.calculator.operation?.v" [ngClass]="[key.c, key.s]" (click)="CalculatorService.pressButton(key)" [innerHTML]="key.l"></button>
            </div>
        </div>
    </div>
</div>
