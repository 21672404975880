<div *ngIf="AssessmentManagementService.models.section; then testTriesPractice else showSectionMsg"></div>

<ng-template #testTriesPractice>
    <div class="flex-row align-items-center space-children-row margin-bottom-1em">
        <span class="bold">Filter By Cluster:</span>

        <ng-select  class="custom cluster-dropdown forced-width-lg"
                    [items]="clusters"
                    appendTo="body"
                    bindLabel="name"
                    [clearable]="true"
                    groupBy="regionName"
                    [searchable]="true"
                    [(ngModel)]="AssessmentManagementService.models.cluster"
                    [loading]="loadingClusters"
                    name="cluster"
                    placeholder="Select a cluster">
            <ng-template ng-label-tmp let-item="item">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                <span [innerHTML]="item.name"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                <span [innerHTML]="item.name"></span>
            </ng-template>
        </ng-select>
    </div>

    <div *ngIf="AssessmentManagementService.models.cluster; then clusterView else tabsView"></div>

    <ng-template #clusterView>
        <div class="flex-row align-items-center justify-space-between">
            <h4 class="dark-theme page-subheader reset">Assessments (CLU, CON)</h4>
        </div>

        <teacher-assigned-tests [schoolYears]="schoolYears" [cluster]="AssessmentManagementService.models.cluster"></teacher-assigned-tests>


        <div class="flex-row align-items-center justify-space-between margin-top-2em">
            <h4 class="dark-theme page-subheader reset">Tries (CLU)</h4>
        </div>

        <student-tries-activity [cluster]="AssessmentManagementService.models.cluster"></student-tries-activity>


        <div class="flex-row align-items-center justify-space-between margin-top-2em">
            <h4 class="dark-theme page-subheader reset">Practice (CON)</h4>
        </div>

        <student-practice-activity [cluster]="AssessmentManagementService.models.cluster"></student-practice-activity>
    </ng-template>

    <ng-template #tabsView>
        <mat-tab-group color="accent" [selectedIndex]="selectedIndex" (selectedIndexChange)="updateStateParams($event)">
            <mat-tab label="Assessments (CLU, CON)">
                <ng-template matTabContent>
                    <teacher-assigned-tests [schoolYears]="schoolYears"></teacher-assigned-tests>
                </ng-template>
            </mat-tab>

            <mat-tab label="Tries (CLU)">
                <ng-template matTabContent>
                    <student-tries-activity></student-tries-activity>
                </ng-template>
            </mat-tab>

            <mat-tab label="Practice (CON)">
                <ng-template matTabContent>
                    <student-practice-activity></student-practice-activity>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </ng-template>
</ng-template>

<ng-template class="flex-column space-children-column align-items-start" #showSectionMsg>
    <h5>Please try a different school year and section selection.</h5>
</ng-template>

<minimap-panel
    [isOpen]="isMinimapOpen"
    [highlightAssps]="highlightAssps"
    [addDragging]="true"
    (opened)="isMinimapOpen = true;"
    (closed)="isMinimapOpen = false;">
</minimap-panel>
