import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { HttpService } from '../../../core/service/http.service';
import { GrowlerService } from '../../../core/service/growler.service';

import './contact-modal.component.scss';


@Component({
    selector: 'contact-modal',
    templateUrl: './contact-modal.component.html',
    // styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent {

    public contact = {
        name: null,
        email: null,
        org: null,
        role: null,
        message: null,
    };

    constructor(
        public activeModal: NgbActiveModal,
        private HttpService: HttpService,
        private GrowlerService: GrowlerService,
    ) {}

    public sendEmail() {
        let url = `${apiServer.urlPrefix}/inquiry/`;

        this.HttpService.post(url, this.contact)
            .then(function() {
                this.GrowlerService.info('Your message has been delivered successfully. We will be in touch soon.', 'Thank you!');
                this.activeModal.close();
            })
            .catch(console.warn);
    }
}
