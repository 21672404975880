import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ResourcerService } from '../resourcer.service';
import { UtilsService } from '../../core/service/utils.service';
import { SessionService } from '../../core/service/session.service';
import { ModalService } from '../../shared/service/modal.service';

import './resource-management-modal.component.scss';


@Component({
    selector: 'resource-management-modal',
    templateUrl: './resource-management-modal.component.html',
    // styleUrls: ['./resource-management-modal.component.scss'],
})
export class ResourceManagementModalComponent implements OnInit {

    @Input() resource: any;
    @Input() fields: any;
    @Input() sources: any;
    @Input() grades: any;
    @Input() tags: any;

    private originalResource: string = '';
    public isTeacherOrAdmin: boolean = false;
    private isExistingResource: boolean;

    public modalTitle: string;

    public filterSelections = {
        tags: {},
        grades: {}
    };

    constructor(
        public activeModal: NgbActiveModal,
        private ResourcerService: ResourcerService,
        public UtilsService: UtilsService,
        private SessionService: SessionService,
        private ModalService: ModalService,
    ) {}

    public ngOnInit() {
        this.isTeacherOrAdmin = (this.SessionService.userAccess.permission & 8) === 8 || (this.SessionService.userAccess.permission & 16) === 16;
        this.isExistingResource = !!this.resource;
        this.modalTitle = this.isExistingResource ? `Edit Resource: ${this.resource.name}` : 'Add New Resource';
        this.resource = this.resource || {
            owner: true,
            standards: [],
            rating: {
                user_rating: 0,
            },
        };

        if (this.isExistingResource) {
            this.resource.disabled = true;
            this.resource.share = false;

            if (this.resource.owner || this.resource.editable) {
                if (this.resource.district) {
                    this.resource.share = true;
                }
                if (!this.resource.curated) {
                    this.resource.disabled = false;
                }
            }

            this.resource.standards = this.resource.standards || [];
            this.resource.clusters = this.resource.clusters || [];
            this.resource.constructs = this.resource.constructs || [];


            // Select checkboxes in global tag selection
            this.resource.tags.forEach(tag => {
                this.filterSelections.tags[tag.id] = true;
            });

            this.resource.grades.forEach(grade => {
                this.filterSelections.grades[grade.id] = true;
            });

            // Go through nested map data and select the resource tags
            if (this.fields) {
                this.fields.forEach(field => {
                    field.regions.forEach(region => {
                        region.clusters.forEach(cluster => {
                            cluster.isSelected = this.resource.clusters.includes(cluster.id);

                            cluster.constructs.forEach(construct => {
                                construct.isSelected = this.resource.constructs.includes(construct.id);
                            });
                        });
                    });
                });
            }
        }

        // Store copy of original resource to know if changes have been made when closing
        this.originalResource = JSON.stringify(this.resource);
    }

    public dismissModal(reason) {
        // Check if user has made any changes when closing the modal
        let isResourceEqual = JSON.stringify(this.resource) === this.originalResource;

        if (isResourceEqual) {
            this.activeModal.dismiss(reason);
        } else {
            // Prevent modal from closing until user confirms action
            let modalOptions = {
                closeButtonText: 'Discard',
                actionButtonText: 'Save',
                bodyText: '<p>Some changes have not been saved. Are you sure you want to discard your changes?</p>'
            };

            this.ModalService.openModal({}, modalOptions)
                .then(() => this.saveResourceChanges())
                .catch(reason => {
                    if (reason === 'cancel') {
                        this.resource = JSON.parse(this.originalResource);
                        // Close if explicitly discarded rather than clicking on x or backdrop
                        return this.activeModal.dismiss('force cancel');
                    }
                });
        }
    }

    public prependHTTP() {
        if (this.resource.url && !this.resource.url.toLowerCase().includes('http')) {
            this.resource.url = 'http://' + this.resource.url;
        }
    }

    public openMapTagging() {
        let instructions = 'Select at least one cluster or construct OR search for at least one standard to tag the resource.';
        this.ResourcerService.openMapTaggingModal({
            instructions,
            fields: this.fields,
            resource: this.resource,
        })
            .then((result) => this.selectStandards(result))
            .catch(() => {});
    }

    private selectStandards({ isMapCheckboxDisabled = false } = {}) {
        if (!isMapCheckboxDisabled) {
            // Wipe standard list
            this.resource.standards.splice(0, this.resource.standards.length);
            // Go through nested map data and select standards
            this.fields.forEach(field => {
                field.regions.forEach(region => {
                    region.clusters.forEach(cluster => {
                        cluster.constructs.forEach(construct => {
                            if (construct.isSelected) {
                                this.resource.standards = this.resource.standards.concat(construct.standards);
                            }
                        });
                    });
                });
            });

            // Keep only unique standards
            for (let i = this.resource.standards.length - 1; i >= 0; i--) {
                let standardIndex = this.resource.standards.findIndex(standard => {
                    return standard.id === this.resource.standards[i].id;
                });
                if (standardIndex !== i) {
                    this.resource.standards.splice(i, 1);
                }
            }
        }
    }

    public removeStandard(stIdx) {
        this.resource.standards.splice(stIdx, 1);
    }

    public saveResourceChanges() {
        // Deep copy resource and format values to meet backend parameters
        let finalResource = JSON.parse(JSON.stringify(this.resource));

        // Convert arrays to json
        let standards = finalResource.standards.map(standard => {
            return { id: standard.id };
        });
        finalResource.standards = JSON.stringify(standards);
        finalResource.sources = JSON.stringify(finalResource.sources);

        // Include only checkboxes that are selected and wrap id in object
        let gradeIds = Object.keys(this.filterSelections.grades)
            .filter(gradeId => this.filterSelections.grades[gradeId])
            .map(gradeId => { return { id: gradeId }; });

        let tagIds = Object.keys(this.filterSelections.tags)
            .filter(tagId => this.filterSelections.tags[tagId])
            .map(tagId => { return { id: tagId }; });

        finalResource.grades = JSON.stringify(gradeIds);
        finalResource.tags = JSON.stringify(tagIds);

        // Get cluster and construct ids from filter data model
        let clusters = [];
        let constructs = [];
        this.fields.forEach(field => {
            field.regions.forEach(region => {
                region.clusters.forEach(cluster => {
                    if (cluster.isSelected) {
                        clusters.push({ id: cluster.id });
                    }

                    cluster.constructs.forEach(construct => {
                        if (construct.isSelected) {
                            constructs.push({ id: construct.id });
                        }
                    });
                });
            });
        });
        finalResource.clusters = JSON.stringify(clusters);
        finalResource.constructs = JSON.stringify(constructs);
        finalResource.user_rating = finalResource.rating.user_rating;
        delete finalResource.rating;
        delete finalResource.textDesc;

        let requestMethod, action;
        if (this.isExistingResource) {
            action = 'edit_resource';
            requestMethod = 'PATCH';
        } else {
            action = 'create_resource';
            requestMethod = 'PUT';
        }

        this.UtilsService.addLoadingOverlay(true);
        this.ResourcerService.saveResource(finalResource, requestMethod, action)
            .then(result => {
                // Remove loading overlay before the modal promise is resolved
                this.UtilsService.removeLoadingOverlay();
                this.activeModal.close(result);
            })
            .catch(error => {
                this.UtilsService.removeLoadingOverlay();
                console.warn(error);
            });
    }
}
