import { Component, OnInit } from '@angular/core';

import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '@/core/service/utils.service';
import { ReportService } from '@/report/report.service';
import { AssessmentService } from '@/assessment/assessment.service';
import { AssessmentStateService } from '@/assessment/common/assessment-state.service';

// import './student-reports.component.scss';

@Component({
    selector: 'student-reports',
    templateUrl: './student-reports.component.html',
    // styleUrls: ['./student-reports.component.scss'],
})
export class StudentReportsComponent implements OnInit {

    public clusterRunningRecord: any;
    private practiceSub: Subscription;
    public loadingClusters: boolean = false;
    public readonly dateFormat = dateFormat;

    constructor(
        private $state: StateService,
        public UtilsService: UtilsService,
        public ReportService: ReportService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.loadingClusters = true;
        this.UtilsService.addLoadingOverlay();

        this.ReportService.getClustersWithStudentReports()
            .then(clusterReports => {
                // When arriving to this tab from map load selected cluster
                if (this.$state.params.clusterId && !this.ReportService.models.cluster) {
                    this.ReportService.models.cluster = clusterReports.clusters
                        .find(cl => cl.id === this.$state.params.clusterId);
                    this.getTestHistory(this.ReportService.models.cluster);
                }
            })
            .catch(console.warn)
            .finally(() => {
                this.loadingClusters = false;
                this.UtilsService.removeLoadingOverlay();
            });

        // If user already chose a cluster before switching tabs and we
        // still have it in memory then load that track record
        if (this.ReportService.models.cluster) {
            this.getTestHistory(this.ReportService.models.cluster);
        }

        this.practiceSub = this.AssessmentService.practiceFinished$.subscribe(({ trUid, isStateChange }) => {
            // Refresh track record after practice test has been completed
            if (!isStateChange) {
                this.getTestHistory(this.ReportService.models.cluster);

            }
        });
    }

    public ngOnDestroy() {
        if (this.practiceSub) {
            this.practiceSub.unsubscribe();
        }
    }

    public getTestHistory(cluster) {
        this.UtilsService.addLoadingOverlay();

        return this.ReportService.getClusterRunningRecord(cluster.id)
            .then(result => {
                this.clusterRunningRecord = result;
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public formatScore(scores, type) {
        if (scores) {
            return (scores[type] / scores.total * 100).toFixed() + '%';
        }
        return '&mdash;';
    }

    public showPracticeAssessment(construct) {
        this.UtilsService.addLoadingOverlay();

        this.AssessmentStateService.getPracticeAssessmentInfo(construct)
            .then(() => this.AssessmentService.openPracticeTestModal())
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }
}
