<div class="modal-header">
    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
    <h4 class="modal-title text-center">Data Agreement</h4>
</div>

<div class="modal-body">
    <p>{{ today | date: dateFormat.shortDate }}</p>
    <p>Dear Students, Parents or Other Users,</p>
    <p class="text-indent">
        We are offering a free use of our software tool Math Mapper 6-8 (“MM”) to users to assist those whose schools have been closed to contain the spread of the COVID-19 virus. This letter is to inform you of our software and the data we collect from it. You can opt out from any data collection using the form below.
    </p>
    <p class="text-indent" *ngIf="!showMore">
        <span class="hide-text" (click)="detailText($event)">Show more...</span>
    </p>
    <p class="text-indent" *ngIf="showMore">
        Kids need to know what they know, and what they still need to learn. Learning trajectories describe evidence-based patterns in how students learn each big idea in mathematics from beginning to advanced levels. Therefore, we have built MM’s assessment problems on learning trajectories to help students understand what they are learning, recognize difficulties that they commonly experience, and help them develop effective strategies for understanding and solving math problems. Students work the problems digitally, and receive immediate feedback, as well as opportunities to rework their problems. The assessments problems have been created by our research group, “SUDDS” (Scaling Up Digital Design Studies—sudds.ced.ncsu.edu), at North Carolina State University. Your student will have access to “Practice” and “Tries.” Each practice session can be as long as they want it to be. If they choose to take a “Try”, it will take about 30 minutes for middle school-aged students to complete and will be about 8-10 items long. At the end of each activity, the MM system will provide digitally-scored reports and instant feedback to users.
        <span class="hide-text" (click)="detailText($event)">Hide...</span>
    </p>
    <p class="text-indent">
        We analyze the data--the responses to the problems--as part of our research to ensure that we constantly improve Math-Mapper’s underlying learning trajectory framework. All data will be stored securely, and all data from your answers to the problems will be disconnected from each user’s name before researchers have access to it--so any data used for such research purposes will be completely anonymous. However, you may still opt out of having your de-identified data used in our ongoing research analysis (see below). If you stay in, however, we thank you for contributing the quality of our work. We will collect your email address in order to verify your account, to send you password recovery information, and to contact you about any bug fixes you may request. We will not ever share any of this information with any third party. Our priority is to ensure we are serving the best interest of our users. Please contact us at sudds_outreach@ncsu.edu if you have any questions or concerns.
    </p>
    <p>
        Sincerely,<br/>
        Jere Confrey, Joseph D. Moore Distinguished Professor of Mathematics Education<br/>
        STEM Department, NC State University
    </p>
    <p class="add-spacing">Note -- ONLY IF opting out, check the box below. Otherwise, click “Proceed to Math Mapper”, button.</p>
    <form [formGroup]="optOutForm" class="opt-out-form flex-column flex-auto space-children-column" (ngSubmit)="saveOptOut($event)">
        <p class="margin-top-5em">
            <mat-checkbox id="opt-out-check" class="space-padding" formControlName="optOut" name="optOut" [disableRipple]="true">
                I do <u><i>not</i></u> want the assessment data from this email address to be used for research.
            </mat-checkbox>
        </p>
    </form>
    <p class="opt-out-statement">I understand that I/my student will still be allowed to take these assessment problems and have access to his/her/their own data reports.</p>
    <p class="opt-out-reminder" *ngIf="optOutReminder">(You will not see this message again when logging in, but you can revisit this page via your Profile.)</p>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
    <button type="button" class="btn btn-block btn-primary" (click)="saveOptOut($event)">Proceed to Math Mapper</button>
</div>
