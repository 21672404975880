<div class="school-report-container page-content top">
    <h2 class="page-title flex-none">School Report</h2>
</div>

<div class="school-report-container page-content bottom">
    <div class="flex-row">
        <school-report-filters></school-report-filters>

        <school-report-graph></school-report-graph>

        <school-report-graph-options></school-report-graph-options>
    </div>
</div>
