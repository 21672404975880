<div class="modal-header dark-theme question-header" *ngIf="option.itemAnalysis">
    <div class="flex-row align-items-center space-children-row big-space justify-space-between flex-wrap">
        <div class="flex-none space-children-row align-items-center">
            <div class="misconceptions-toggle cursor-pointer flex-none space-children-row small-space" *ngIf="questionMisconceptions.data?.length > 0" (click)="toggleMisconceptionsPanel()">
                <i class="icon icon-exclamation-triangle misconception-icon"></i>
                <i class="icon icon-caret-right" [class.rotate]="option.isMisconceptionsPanelOpen"></i>
            </div>

            <span *ngIf="AssessmentStateService.data.question?.difficulty === 1" class="item-difficulty flex-none" matTooltip="This question was aligned to this level but it was unusually easy">
                <i class="icon icon-arrow-down-1"></i>
            </span>
            <span *ngIf="AssessmentStateService.data.question?.difficulty === 2" class="item-difficulty flex-none" matTooltip="This question was aligned to this level but it was unusually hard">
                <i class="icon icon-arrow-up-1"></i>
            </span>
        </div>

        <div class="question-toggles flex justify-center space-children-row medium-space align-items-center">
            <div class="checkbox-container flex-none align-items-center">
                <mat-slide-toggle id="toggle-response-pattern" [(ngModel)]="option.isItemAnalysisOpen" (change)="recordAnalyticsAction(option.isItemAnalysisOpen, 'question_analysis_panel')" labelPosition="before" [disableRipple]="true">Question Analysis</mat-slide-toggle>
            </div>

            <div class="checkbox-container flex-none align-items-center">
                <mat-slide-toggle id="toggle-answer-key" [(ngModel)]="AssessmentStateService.data.answerKey" (change)="recordAnalyticsAction(AssessmentStateService.data.answerKey, 'answer_key')" labelPosition="before" [disableRipple]="true">Answer Key</mat-slide-toggle>
            </div>
        </div>

        <div class="modal-close" (click)="activeModal.dismiss('clicked X')">
            <i class="icon icon-x"></i>
        </div>
    </div>
</div>
<button class="btn modal-close" (click)="activeModal.dismiss('clicked X')" *ngIf="!option.itemAnalysis">
    <i class="icon icon-x"></i>
</button>

<div class="modal-body">
    <misconception-trigger-panel
        *ngIf="questionMisconceptions.data?.length > 0"
        [option]="option"
        [questionMisconceptions]="questionMisconceptions"
        [foilIdToRomanMap]="foilIdToRomanMap">
    </misconception-trigger-panel>

    <div class="question-score-bubble-row flex-row justify-start space-children-row align-items-center" *ngIf="AssessmentStateService.data.question?.score">
        <score-bubble [question]="AssessmentStateService.data.question"></score-bubble>
        <span class="feedback-primary capitalize" [ngClass]="AssessmentStateService.data.question?.score">{{ AssessmentStateService.data.question?.feedbackPrimary }}</span>
    </div>

    <item-analysis-panel *ngIf="option.itemAnalysis && questionAnalysis" [option]="option" [questionAnalysis]="questionAnalysis" [foilIdToRomanMap]="foilIdToRomanMap"></item-analysis-panel>

    <question class="flex-row full-height flex-wrap"></question>
</div>

<div class="modal-footer flex-row align-items-center justify-center">
    <cms-item-link></cms-item-link>
</div>
