import { Injectable, ErrorHandler } from '@angular/core';

import { BackendLoggerService } from './backend-logger.service';


@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {

    private TIME_LIMIT_IN_QUEUE = 15 * 60;
    private errorQueue = [];
    private errorHistory = {};

    constructor(
        private BackendLoggerService: BackendLoggerService,
    ) {
        super();
    }

    // Main method called by Angular when an exception occurs
    public handleError(error) {
        // Log error to dev tools console (default functionality)
        super.handleError(error);

        let newError = this.generateErrorObject(error);

        // Remove old/expired errors
        this.removeExpiredErrors(newError);

        // Log error to backend if not already in queue
        // Unless it is in the queue but it has reached the time limit since the last
        // time we sent the error (infinite loop case), so it's ok to send the error again
        if (!this.errorInQueue(newError) || newError.timestamp - this.errorHistory[newError.msg].lastDelivered > this.TIME_LIMIT_IN_QUEUE) {
            newError.occurrence = this.errorHistory[newError.msg].occurrences;
            this.BackendLoggerService.log(newError);
            this.errorHistory[newError.msg].lastDelivered = newError.timestamp;
        }
        this.errorQueue.push(newError);

    }

    private generateErrorObject(exception) {
        // Create a new error with properties used by the backend
        let newError = {
            url: window.location.href,
            msg: exception.toString(),
            trace: exception.stack,
            cause: exception.message,
            timelimit: this.TIME_LIMIT_IN_QUEUE,
            timestamp: Math.floor(Date.now() / 1000),
            occurrence: 1,
        };
        // Categorize error occurrence by error message
        this.errorHistory[newError.msg] = this.errorHistory[newError.msg] || {
            type: newError.msg,
            occurrences: 0
        };
        this.errorHistory[newError.msg].occurrences += 1;

        // Return newly created error
        return newError;
    }

    private removeExpiredErrors(newError) {
        // Find the first error that is within the set time limit
        let firstNonExpiredError = this.errorQueue.findIndex((error) => {
            return newError.timestamp - error.timestamp <= this.TIME_LIMIT_IN_QUEUE;
        });

        // Remove all expired errors
        this.errorQueue.splice(0, firstNonExpiredError === -1 ? this.errorQueue.length : firstNonExpiredError);
    }

    private errorInQueue(newError) {
        return this.errorQueue.some(error => newError.msg === error.msg);
    }
}
