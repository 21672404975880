<div
    class="question-feedback-toast flex-none align-items-center space-children-row"
    *ngIf="AssessmentToastService.toast.show"
    [@EnterLeave]="'slideUp'"
    [ngClass]="AssessmentToastService.toast.status"
    (click)="closeToast(null, $event)">
    <div class="status-color-bar"></div>

    <div class="status-icon flex-none align-items-center justify-center" *ngIf="AssessmentToastService.toast.status">
        <i class="icon" [ngClass]="{ 'icon-arrow-right': AssessmentToastService.toast.status === 'neutral', 'icon-exclamation': AssessmentToastService.toast.status === 'reveal', 'icon-check': AssessmentToastService.toast.status === 'correct', 'icon-test': AssessmentToastService.toast.status === 'partial', 'icon-times': AssessmentToastService.toast.status === 'incorrect' }"></i>
    </div>

    <button class="btn primary-button upcase body-regular" *ngIf="AssessmentToastService.toast.action" (click)="closeToast(AssessmentToastService.toast.action, $event)">{{ AssessmentToastService.toast.action }}</button>

    <div class="question-feedback">
        <div class="feedback-primary capitalize">{{ AssessmentToastService.toast.title }}</div>
        <div class="feedback-secondary">{{ AssessmentToastService.toast.message }}</div>
    </div>

    <div class="modal-close">
        <i class="icon icon-x"></i>
    </div>
</div>
