import { Component, Input, ElementRef, OnInit } from '@angular/core';

import { UtilsService } from '../../../core/service/utils.service';
import { FeedbackService } from '../../../shared/service/feedback.service';

import './feedback-popup.component.scss';


@Component({
    selector: 'feedback-popup',
    templateUrl: './feedback-popup.component.html',
    // styleUrls: ['./feedback-popup.component.scss'],
})
export class FeedbackPopupComponent implements OnInit {

    @Input() questions: any;
    @Input() assp: number;
    @Input() trid: number;
    @Input() delay: number;
    public survey = {
        state: null,
        show: false,
        isComplete: false,
    };

    constructor(
        private element: ElementRef,
        private UtilsService: UtilsService,
        private FeedbackService: FeedbackService,
    ) {}

    public ngOnInit() {
        // Initialize popup after delay threshold
        setTimeout(() => {
            if (!this.questions || this.questions.length === 0) {
                return null;
            }
            this.survey.state = 'started';
        }, Number(this.delay) || 60000);
    }

    public submitFeedback() {
        this.UtilsService.addLoadingOverlay();
        this.FeedbackService.submitSurvey(this.questions, { trid: this.trid, a_ssp: this.assp })
            .catch(console.warn)
            .finally(() => {
                this.UtilsService.removeLoadingOverlay();
                this.survey.state = 'submitted';
                // Show thank you message, and remove itself after 5 seconds
                setTimeout(() => this.survey.state = null, 5000);
            });
    }
}
