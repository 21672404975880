<svg:g
    [attr.transform]="'translate(' + region.attributes.x + ', ' + region.attributes.y + ')'"
    (click)="activateRegion(region, $event)">
    <svg:rect
        [class.region-background-invalid]="!region.valid"
        [ngClass]="['region-background-' + region.id]"
        x="0"
        y="0"
        [attr.width]="mapSizesAndBounds.region.width"
        [attr.height]="mapSizesAndBounds.region.height"
        [attr.rx]="mapSizesAndBounds.region.width / 10"
        [attr.ry]="mapSizesAndBounds.region.width / 10">
    </svg:rect>

    <!-- Test notification -->
    <svg:g
        class="map-icon-container"
        *ngIf="MapService.learningMap.option.currentZoomLevel === 0 && region.test_count"
        [attr.transform]="'translate(' + (mapSizesAndBounds.region.width - 150) + ', 150)'">
        <svg:circle cx="0" cy="0" r="240" fill="#E53935" stroke="#FFF" stroke-width="3em"></svg:circle>
        <!-- <text x="0.1em" y="0.6em" class="map-icon region-test-notification">&#123;</text> -->
        <text x="0" y="0.4em" class="test-count">{{ region.test_count }}</text>
    </svg:g>


    <svg:image
        class="region-icon"
        [class.scale-down]="MapService.learningMap.option.currentZoomLevel > 0"
        [attr.xlink:href]="'src/assets/image/svg/icon_region_' + region.id + (region.valid ? '' : '_grey') + '.svg'"
        x="0"
        y="-70"
        width="4320"
        height="2880">
    </svg:image>

    <svg:g
        class="icon-i"
        [class.active]="region.showPopover"
        [attr.transform]="'translate(' + (mapSizesAndBounds.region.width * 0.96) + ', ' + (mapSizesAndBounds.region.height * 0.95) + ') scale(' + mapSizesAndBounds.region.iButtonScaling[MapService.learningMap.option.currentZoomLevel] + ')'"
        (click)="togglePopover($event)">
        <!-- i-button -->
        <svg:g transform="translate(-30, -30)">
            <!-- Make up for click-through area in the middle of svg where the "i" resides -->
            <svg:circle cx="15" cy="15" r="15" fill="rgba(255, 255, 255, 0)" opacity="0"></svg:circle>
            <svg:path d="M15,30 C23.2842712,30 30,23.2842712 30,15 C30,6.71572875 23.2842712,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842712 6.71572875,30 15,30 Z M12.4312,13.2528 L12.4312,12.5184 C12.6472011,12.4319996 12.9351982,12.3420005 13.2952,12.2484 C13.6552018,12.1547995 14.036798,12.0720004 14.44,12 C14.843202,11.9279996 15.242798,11.8668003 15.6388,11.8164 C16.034802,11.7659997 16.3839985,11.7408 16.6864,11.7408 L17.0752,12 L15.1312,21.3096 L16.6432,21.3096 L16.6432,22.044 C16.4559991,22.1736006 16.2364013,22.2959994 15.9844,22.4112 C15.7323987,22.5264006 15.4660014,22.6271996 15.1852,22.7136 C14.9043986,22.8000004 14.6200014,22.8683997 14.332,22.9188 C14.0439986,22.9692003 13.7704013,22.9944 13.5112,22.9944 C12.9639973,22.9944 12.586001,22.890001 12.3772,22.6812 C12.168399,22.472399 12.064,22.2456012 12.064,22.0008 C12.064,21.7127986 12.0855998,21.4320014 12.1288,21.1584 C12.1720002,20.8847986 12.2295996,20.5824017 12.3016,20.2512 L13.7704,13.5552 L12.4312,13.2528 Z M14.2456,8.3928 C14.2456,7.90319755 14.4111983,7.52160137 14.7424,7.248 C15.0736017,6.97439863 15.4911975,6.8376 15.9952,6.8376 C16.5424027,6.8376 16.9779984,6.97439863 17.302,7.248 C17.6260016,7.52160137 17.788,7.90319755 17.788,8.3928 C17.788,8.8536023 17.6260016,9.22079863 17.302,9.4944 C16.9779984,9.76800137 16.5424027,9.9048 15.9952,9.9048 C15.4911975,9.9048 15.0736017,9.76800137 14.7424,9.4944 C14.4111983,9.22079863 14.2456,8.8536023 14.2456,8.3928 Z"></svg:path>
        </svg:g>
    </svg:g>
</svg:g>

<svg:g *ngFor="let cluster of region.clusters">
    <svg:g
        class="cluster-group zoom-level-transition"
        *ngIf="MapService.learningMap.option.currentZoomLevel >= mapSizesAndBounds.cluster.visibleAtLevel"
        (click)="$event.stopPropagation(); MapService.zoomChange.next({ level: 4, x: cluster.attributes.cx, y: cluster.attributes.cy })">
        <svg:g map-cluster [cluster]="cluster"></svg:g>
    </svg:g>
</svg:g>
