import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';
// import { NgSelectModule } from '@ng-select/ng-select';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
// For school report page
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PlotlyViaCDNModule } from 'angular-plotly.js';

import { SharedModule } from '../shared/shared.module';
import { MapModule } from '../map/map.module';

import { ReportService } from './report.service';
import { SchoolReportService } from './school/school-report.service';
import { ReportPageComponent } from './report.component';
import { StudentReportsComponent } from './student-reports/student-reports.component';
import { ReportClusterComponent } from './cluster/report-cluster.component';
import { ReportTestComponent } from './test/report-test.component';

import { SchoolReportComponent } from './school/school-report.component';
import { SchoolReportFiltersComponent } from './school/filters/school-report-filters.component';
import { SchoolReportGraphComponent } from './school/graph/school-report-graph.component';
import { UsageBarChartComponent } from './school/graph/usage/bar-chart/usage-bar-chart.component';
import { UsageDonutChartComponent } from './school/graph/usage/donut-chart/usage-donut-chart.component';
import { PerformanceBoxPlotComponent } from './school/graph/performance/box-plot/performance-box-plot.component';
import { PerformanceStackedBarPlotComponent } from './school/graph/performance/stacked-bar-plot/performance-stacked-bar-plot.component';
import { SchoolReportGraphOptionsComponent } from './school/graph-options/school-report-graph-options.component';

import { QuestionBreakdownComponent } from './test/question-breakdown/question-breakdown.component';
import { PointScaleComponent } from './test/point-scale/point-scale.component';
import { FeedbackPopupComponent } from './test/feedback-popup/feedback-popup.component';
import { ClusterGraphComponent } from './common/cluster-graph/cluster-graph.component';
import { ClusterGraphLegendComponent } from './common/cluster-graph/legend/cluster-graph-legend.component';
import { CumulativeScoreComponent } from './common/cluster-graph/cumulative-score/cumulative-score.component';
import { TestScoreComponent } from './common/cluster-graph/test-score/test-score.component';
import { TableScrollButtonsComponent } from './common/table-scroll-buttons/table-scroll-buttons.component';
import { ConstructInfoColumnComponent } from './common/construct-info/construct-info-column.component';


PlotlyViaCDNModule.plotlyVersion = 'latest'; // can be `latest` or any version number (i.e.: '1.40.0')
PlotlyViaCDNModule.plotlyBundle = 'cartesian'; // optional: can be null (for full) or 'basic', 'cartesian', 'geo', 'gl3d', 'gl2d', 'mapbox' or 'finance'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule,
        // NgSelectModule,
        MatFormModule,
        MatMenuModule,
        MatTooltipModule,
        MatTabsModule,
        MatButtonToggleModule,
        PlotlyViaCDNModule,
        SharedModule,
        MapModule,
    ],
    providers: [
        ReportService,
        SchoolReportService,
    ],
    declarations: [
        ReportPageComponent,
        StudentReportsComponent,
        ReportClusterComponent,
        ReportTestComponent,
        SchoolReportComponent,
        SchoolReportFiltersComponent,
        SchoolReportGraphComponent,
        UsageBarChartComponent,
        UsageDonutChartComponent,
        PerformanceBoxPlotComponent,
        PerformanceStackedBarPlotComponent,
        SchoolReportGraphOptionsComponent,
        QuestionBreakdownComponent,
        PointScaleComponent,
        FeedbackPopupComponent,
        ClusterGraphComponent,
        ClusterGraphLegendComponent,
        CumulativeScoreComponent,
        TestScoreComponent,
        TableScrollButtonsComponent,
        ConstructInfoColumnComponent,
    ],
    exports: [
        QuestionBreakdownComponent,
        // Both part of track record
        StudentReportsComponent,
        TableScrollButtonsComponent,
        ConstructInfoColumnComponent,
        // Used in heatmap
        ClusterGraphComponent,
    ],
})
export class ReportModule { }
