<div class="modal-header assessment-modal-header">
    <h4 class="text-center">Thanks!</h4>
</div>

<div class="modal-body text-center">
    <div>{{ AssessmentStateService.data.submitText }}</div>

    <feedback-form [questions]="AssessmentStateService.data.feedback" (completed)="AssessmentStateService.data.surveyCompleted = $event"></feedback-form>
</div>

<div class="modal-footer modal-footer--assessment flex-row align-items-center justify-center space-children-row flex-wrap">
    <button class="btn ghost-button" (click)="submitFeedback()" [disabled]="!AssessmentStateService.data.surveyCompleted">Close</button>

    <button class="btn primary-button" (click)="submitFeedback(true)" *ngIf="AssessmentStateService.data.submitType === 1" [disabled]="!AssessmentStateService.data.surveyCompleted">See My Report</button>
</div>
