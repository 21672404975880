import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { SchoolReportService } from '@/report/school/school-report.service';

// import './usage-bar-chart.component.scss';


@Component({
    selector: 'usage-bar-chart',
    templateUrl: 'usage-bar-chart.component.svg.html',
    // styleUrls: ['./usage-bar-chart.component.scss'],
    host: {
        class: 'usage-bar-chart'
    }
})
export class UsageBarChartComponent implements OnInit, OnChanges {

    @Input() public graphData: any;
    @Input() public graphParams: any;
    private COLOR_PALETTE = [
        '#4A4BA3', '#C5396C', '#4ABAA3', '#877B5D', '#FF8333', '#A093B7', '#F60563', '#F3A6CE',
        '#C690FF', '#FDC8AD', '#D4575A', '#AD97DC', '#5D7299', '#D08E75', '#FFD933', '#FBF48B',
        '#D0E1D1', '#FDC8AD', '#FFCC82', '#D5D4DC', '#DACE4C', '#FAA7A5', '#63C8CF', '#6AC15F',
        '#DEA235', '#B9A991', '#A19C98', '#ADB2C7', '#8AC6A1', '#A0A53E', '#A88E59', '#F67B62',
        '#7C91CD', '#5A94D2', '#AC7545', '#F87929', '#5B644E', '#786F81', '#346EA0', '#4C5A63',
        '#174670', '#923439', '#54762A', '#E57E00', '#6D8E84', '#C30030', '#938BD4', '#CAC37A',
        '#A15227', '#7A92A9', '#2A6D66', '#9D8942', '#C0A19B', '#FFD400', '#D9E6EB', '#2897FF',
    ];
    private CLUSTER_PALETTE = [
        '#6A50A7', '#E6BC2F', '#F39C12', '#2ECC67', '#23A679',
        '#46C5F1', '#3C9CC0', '#5D8AEC', '#FC6351', '#E33737',
    ];
    public barChartData: any = [];
    public colorMap: any;
    public colorIdx: number;

    constructor(
        public SchoolReportService: SchoolReportService,
    ) {}

    public ngOnInit() {

    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.graphData && change.graphData.currentValue) {
            this.formatPlotlyGraphData();
        }
    }

    public formatPlotlyGraphData() {
        this.colorMap = {};
        this.colorIdx = 0;
        this.barChartData = [];
        this.SchoolReportService.graphLayout = {
            autosize: true,
            bargroupgap: 0.1,
            clickmode: 'event+select',
            dragmode: false,
            height: window.innerHeight - 250,
            hovermode: 'closest',
            legend: {
                font: { family: 'Lato, Arial', size: 12, color: 'rgba(0, 0, 0, 0.75)' }
            },
            margin: { t: 50, b: 50, l: 50, r: 10 },
            showlegend: true,
            // title: {
            //     text: this.SchoolReportService.getChartTitle(this.graphParams),
            //     font: { family: 'Lato, Arial', size: 16, color: 'rgba(0, 0, 0, 0.65)' }
            // },
            yaxis: {
                visible: true,
                automargin: true,
                title: {
                    text: 'Number of Tests',
                    font: { family: 'Lato, Arial', size: 12, color: 'rgba(0, 0, 0, 0.68)' }
                },
                fixedrange: true,
                color: 'rgba(0, 0, 0, 0.68)',
                linecolor: 'rgba(0, 0, 0, 0.68)',
                tickmode: 'auto',
                nticks: 4,
                ticks: 'outside',
                tickfont: { family: 'Lato, Arial', size: 10, color: 'rgba(0, 0, 0, 0.40)' },
            },
        };

        if (this.graphParams.y_axis === 3) {
            this.SchoolReportService.graphLayout.yaxis.title.text = 'Percentage of students tested';
            this.SchoolReportService.graphLayout.yaxis.tickmode = 'array';
            this.SchoolReportService.graphLayout.yaxis.tickvals = [25, 50, 75, 100];
            this.SchoolReportService.graphLayout.yaxis.ticktext = ['25%', '50%', '75%', '100%'];
            this.SchoolReportService.graphLayout.yaxis.range = [0, 100];
        }

        // Sort graph data by date ascending
        this.graphData.sort((a, b) => a.end < b.end ? -1 : 1);

        const xAxisDefault: any = {
            visible: true,
            automargin: true,
            showgrid: true,
            color: 'rgba(0,0,0,0.68)',
            side: 'bottom',
            tickmode: 'auto',
            ticks: 'outside',
            tickangle: 'auto',
            // tickangle: -45,
            tickfont: { family: 'Lato, Arial', size: 11, color: 'rgba(0,0,0,0.68)' },
        };

        const isx3Active = this.graphParams.x3 > 0 || (this.graphParams.x2 > 0 && JSON.parse(this.graphParams.schools).length > 1);

        if (isx3Active) {
            this.SchoolReportService.graphLayout.barmode = 'stack';
            const subPlotSize = 1 / this.graphData.length;

            for (let i = 0; i < this.graphData.length; i++) {
                const currentXaxis = (i === 0 ? 'xaxis' : `xaxis${i + 1}`);
                const subPlotDate = new Date(this.graphData[i].end + 'T00:00');
                const subPlotTitle = subPlotDate.toLocaleDateString('en-US', {month: 'short', year: '2-digit'});
                this.SchoolReportService.graphLayout[currentXaxis] = Object.assign({}, xAxisDefault, {
                    domain: [subPlotSize * i, subPlotSize * (i + 1)],
                    anchor: `x${i + 1}`,
                    title: {
                        text: this.graphParams.x_axis === 3 ? this.graphData[i].name : subPlotTitle,
                        font: { family: 'Lato, Arial', size: 12, color: 'rgba(0,0,0,0.55)' }
                    },
                });

                if (i !== 0) {
                    this.SchoolReportService.graphLayout[currentXaxis].matches = 'x';
                }
            }
        } else {
            this.SchoolReportService.graphLayout.barmode = 'group';
            this.SchoolReportService.graphLayout.xaxis = Object.assign({}, xAxisDefault, {
                domain: [0, 1],
                title: {
                    text: 'Time',
                    font: { family: 'Lato, Arial', size: 12, color: 'rgba(0,0,0,0.55)' }
                },
                tickformat: '%b`%y',
            });

            if (this.graphParams.x_axis === 3) {
                // If yearly show only year in x axis
                this.SchoolReportService.graphLayout.xaxis.tickformat = '%Y';
                this.SchoolReportService.graphLayout.xaxis.nticks = 3;
            }

        }

        this.graphData.forEach((schoolYear, syIdx) => {
            const schools = Object.keys(schoolYear.schools);
            const timeAxisGroup: any = {
                type: 'bar',
                hoverinfo: 'y+name',
                x: [],
                y: [],
            };
            const startDateMS = new Date(schoolYear.start).getTime();
            const endDateMS = new Date(schoolYear.end).getTime();
            const timePeriodDiff = endDateMS - startDateMS;
            const timeMidPoint = new Date(endDateMS - timePeriodDiff / 2);

            // If percentage of student tested y axis is selected
            if (this.graphParams.y_axis === 3) {
                timeAxisGroup.hovertext = '%';
                timeAxisGroup.hoverinfo = 'text+y+name';
                timeAxisGroup.hovertemplate = '%{y:.2f}%<extra>%{fullData.name}</extra>';
            }

            if (isx3Active) {
                schools.forEach((schoolName, snIdx) => {
                    const schoolDetails = schoolYear.schools[schoolName];
                    const schoolProps = Object.keys(schoolDetails).filter(this.notDisplayedProps);

                    timeAxisGroup['xaxis'] = (syIdx === 0 ? 'x1' : `x${syIdx + 1}`);

                    schoolProps.forEach((x2Label, x2Idx) => {
                        const x2Details = schoolDetails[x2Label];
                        const x2Props = Object.keys(x2Details).filter(this.notDisplayedProps);

                        if (this.graphParams.x3 > 0) {
                            // x2 and x3 selected
                            x2Props.forEach((x3Label, x3Idx) => {
                                let existingX3Group = this.barChartData.find(bar => bar.time === schoolYear.end && bar.name === x3Label);

                                // Shorten cluster name in bar chart x axis
                                if (this.graphParams.x2 === 4) {
                                    x2Label = this.limitText(x2Label);
                                }

                                if (existingX3Group) {
                                    existingX3Group.x.push(x2Label);
                                    existingX3Group.y.push(x2Details[x3Label]);
                                } else {
                                    this.barChartData.push(
                                        Object.assign({}, timeAxisGroup, {
                                            name: this.limitText(x3Label),
                                            time: schoolYear.end,
                                            x: [x2Label],
                                            y: [x2Details[x3Label]],
                                        })
                                    );

                                    this.assignBarColor(this.barChartData[this.barChartData.length - 1], x2Details, x3Label);
                                }
                            });
                        } else {
                            // multi school and x2 (as x3) selected
                            let existingX3Group = this.barChartData.find(bar => bar.time === schoolYear.end && bar.name === x2Label);

                            if (existingX3Group) {
                                existingX3Group.x.push(schoolName);
                                existingX3Group.y.push(x2Details.total);
                            } else {
                                this.barChartData.push(
                                    Object.assign({}, timeAxisGroup, {
                                        name: this.limitText(x2Label),
                                        time: schoolYear.end,
                                        x: [schoolName],
                                        y: [x2Details.total],
                                    })
                                );

                                this.assignBarColor(this.barChartData[this.barChartData.length - 1], x2Details, x2Label);
                            }
                        }
                    });
                });
            } else {
                if (this.graphParams.x2 > 0) {
                    // Single school with x2 selected - use grouped bar chart
                    schools.forEach((schoolName, snIdx) => {
                        const schoolDetails = schoolYear.schools[schoolName];
                        const schoolProps = Object.keys(schoolDetails).filter(this.notDisplayedProps);

                        schoolProps.forEach((x2Label, x2Idx) => {
                            const x2Details = schoolDetails[x2Label];
                            const existingTrace = this.barChartData.find(trace => trace.name === x2Label);

                            if (existingTrace) {
                                existingTrace.x.push(timeMidPoint);
                                existingTrace.y.push(x2Details.total);
                            } else {
                                this.barChartData.push(
                                    Object.assign({}, timeAxisGroup, {
                                        name: this.limitText(x2Label),
                                        x: [timeMidPoint],
                                        y: [x2Details.total],
                                    })
                                );

                                this.assignBarColor(this.barChartData[this.barChartData.length - 1], x2Details, x2Label);
                            }
                        });
                    });
                } else {
                    // Single or multiple schools with no x2/x3 selected - use grouped bar chart

                    // Very hard to deduce the range of x axis when there's one data point, so set a pre-defined range span
                    if (this.graphParams.x_axis === 3 && JSON.parse(this.graphParams.schools).length === 1 && JSON.parse(this.graphParams.school_years).length === 1) {
                        // Beginning of starting year to ending of ending year
                        this.SchoolReportService.graphLayout.xaxis.range = [
                            new Date(this.graphData[0].start.substr(0,4) + 'T00:00'),
                            new Date(new Date(this.graphData[this.graphData.length - 1].end.substr(0,4) + 'T00:00').getTime() + 1000*60*60*24*365)
                        ];
                        // Bar should be the width of ~300 days in milliseconds
                        timeAxisGroup.width = 1000 * 60 * 60 * 24 * 300;
                    }

                    schools.forEach((schoolName, snIdx) => {
                        const schoolDetails = schoolYear.schools[schoolName];
                        const existingTrace = this.barChartData.find(trace => trace.name === schoolName);

                        if (existingTrace) {
                            existingTrace.x.push(timeMidPoint);
                            existingTrace.y.push(schoolDetails.total);
                        } else {
                            // Assign bar graph color
                            let markerColor = { color: schools.length > 1 ? this.colorMap[snIdx % this.colorMap.length] : '#1e88e5'}

                            this.barChartData.push(
                                Object.assign({}, timeAxisGroup, {
                                    name: this.limitText(schoolName),
                                    x: [timeMidPoint],
                                    y: [schoolDetails.total],
                                    marker: { color: markerColor },
                                })
                            );
                        }
                    });
                }
            }
        });

        // Get graph title
        this.SchoolReportService.getChartTitle(this.graphParams);

        // Check if pie chart is empty (no values, or all values are 0)
        this.SchoolReportService.isGraphEmpty = this.barChartData.every(bar => bar.y.every(tests => !tests));
        this.SchoolReportService.isGraphReset = this.SchoolReportService.isGraphEmpty;
    }

    private getChartTitle() {
        let title = '';

        title += this.graphParams.y_axis === 1 ? 'Number of tests taken in years ' : 'Percentage of students tested in years ';
        title += JSON.parse(this.graphParams.school_years).sort().join(', ');

        return title;
    }

    private assignBarColor(newBar, xDetails, legendLabel) {
        if (this.colorMap[legendLabel]) {
            newBar.showlegend = false;
        } else {
            // If x2 is color coded by cluster type
            if (this.graphParams.x2 === 4 && !this.graphParams.x3) {
                this.colorMap[legendLabel] = this.CLUSTER_PALETTE[xDetails.region_id] || '#6A50A7';
            } else {
                this.colorMap[legendLabel] = this.COLOR_PALETTE[this.colorIdx++];
            }
        }

        newBar.marker = { color: this.colorMap[legendLabel] };

        if (!this.COLOR_PALETTE[this.colorIdx]) {
            this.colorIdx = 0;
        }
    }

    private notDisplayedProps(prop: string) {
        return prop !== 'total' && prop !== 'region_id' && prop !== 'undefined' && prop !== 'gr none';
    }

    private limitText(text: string, cap: number = 35) {
        return text.length > cap ? (text.substr(0, cap) + '...') : text;
    }
}
