export const SEQUENCER_DEMO = {
    cluster_ssp: `[
        {"order":0,"days":8,"start_date":"2017-08-22","end_date":"2017-08-31","cluster":{"id":1,"name":"Positioning Rationals on a Number Line","valid":1,"parent":{"region":1},"display":true,"wormhole":false}},
        {"order":1,"days":8,"start_date":"2017-09-01","end_date":"2017-09-12","cluster":{"id":2,"name":"Operating with Integers, Fractions, and Decimals","valid":1,"parent":{"region":1},"display":true,"wormhole":false}},
        {"order":2,"days":9,"start_date":"2017-09-13","end_date":"2017-09-25","cluster":{"id":20,"name":"Plotting in the Coordinate Plane","valid":1,"parent":{"region":7},"display":true,"wormhole":false}},
        {"order":3,"days":7,"start_date":"2017-09-26","end_date":"2017-10-04","cluster":{"id":4,"name":"Finding Key Ratio Relationships","valid":1,"parent":{"region":2},"display":true,"wormhole":false}},
        {"order":4,"days":6,"start_date":"2017-10-05","end_date":"2017-10-12","cluster":{"id":5,"name":"Comparing Ratios and Solving for Missing Values in Proportions","valid":1,"parent":{"region":2},"display":true,"wormhole":false}},
        {"order":5,"days":7,"start_date":"2017-10-13","end_date":"2017-10-23","cluster":{"id":6,"name":"Finding Key Percent Relationships","valid":1,"parent":{"region":2},"display":true,"wormhole":false}},
        {"order":6,"days":5,"start_date":"2017-10-24","end_date":"2017-10-30","cluster":{"id":7,"name":"Calculating with Percents","valid":1,"parent":{"region":2},"display":true,"wormhole":false}},
        {"order":7,"days":8,"start_date":"2017-10-31","end_date":"2017-11-09","cluster":{"id":21,"name":"Representing Expressions, Equations, and Inequalities","valid":1,"parent":{"region":8},"display":true,"wormhole":false}},
        {"order":8,"days":5,"start_date":"2017-11-10","end_date":"2017-11-16","cluster":{"id":8,"name":"Multiplying and Dividing Rational Numbers","valid":1,"parent":{"region":2},"display":true,"wormhole":false}}
    ]`,
    region_ssp: `[
        {"order":0,"days":16,"start_date":"2017-08-22","end_date":"2017-09-12","region":{"id":1,"name":"Position, compare, and operate on one dimensional quantities","short_name":"Integers, Rationals & Irrationals","valid":1,"display":true,"wormhole":false}},
        {"order":1,"days":9,"start_date":"2017-09-13","end_date":"2017-09-25","region":{"id":7,"name":"Represent and explore Pythagorean Theorem and polygons using coordinate points","short_name":"2-D Graphing & Pythagorean Theorem","valid":1,"display":true,"wormhole":false}},
        {"order":2,"days":25,"start_date":"2017-09-26","end_date":"2017-10-30","region":{"id":2,"name":"Compare quantities as ratio, rate, or percent and operate with them","short_name":"Ratios & Percents","valid":1,"display":true,"wormhole":false}},
        {"order":3,"days":8,"start_date":"2017-10-31","end_date":"2017-11-09","region":{"id":8,"name":"Algebraically relate, express, modify, and evaluate unknown quantities","short_name":"Expressions & Equations","valid":1,"display":true,"wormhole":false}},
        {"order":4,"days":5,"start_date":"2017-11-10","end_date":"2017-11-16","region":{"id":2,"name":"Compare quantities as ratio, rate, or percent and operate with them","short_name":"Ratios & Percents","valid":1,"display":true,"wormhole":false}}
    ]`,
    totalDays: 140
};
