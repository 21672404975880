<!-- Day Assessments -->
<div *ngIf="CalendarService.calendar.displayAssessments[day.formattedDate]" [ngbPopover]="dayAssessments" [autoClose]="'outside'" #dayAssessmentsTrigger="ngbPopover" placement="bottom-left" popoverClass="day-assessments-popover" class="test-corner-triangle icon-button"></div>
<ng-template #dayAssessments>
    <div class="space-children-column" *ngFor="let test of CalendarService.calendar.displayAssessments[day.formattedDate]; last as isLast">
        <div class="flex-row">
            <b class="upcase title">Tests Scheduled on {{ day.formattedDate | date: dateFormat.shortDate : dateFormat.timezone }}</b>
        </div>

        <div class="flex-row">
            <b>Name:&nbsp;</b>
            <span>{{ test.assessment.name }}</span>
        </div>

        <div class="flex-row">
            <b>Grade:&nbsp;</b>
            <span>{{ UtilsService.gradeBinToText(test.grade) }}</span>
        </div>

        <div class="flex-row">
            <b>Number of Forms:&nbsp;</b>
            <span>{{ test.forms }} ({{ test.testform_names.join(', ') }})</span>
        </div>

        <div class="flex-row">
            <b>Cluster:&nbsp;</b>
            <span [innerHTML]="test.cluster.name"></span>
        </div>

        <div class="flex-row">
            <b>Due:&nbsp;</b>
            <span>{{ test.window === 0 ? 'By' : 'On' }}&nbsp;</span>
            <span>{{ test.end_date | date: dateFormat.shortDate : dateFormat.timezone }}</span>
        </div>

        <div class="flex-row">
            <b>Retest Allowed:&nbsp;</b>
            <span>{{ test.retake_end_date ? (test.retake_end_date | date: dateFormat.shortDate : dateFormat.timezone) : 'No' }}</span>
        </div>
        <div class="hr" *ngIf="!isLast"></div>
    </div>
</ng-template>

<div class="flex-row justify-space-between align-items-center">

    <div class="calendar-day--date flex-none align-items-center justify-center limit-text">{{ date }}</div>

    <span class="flex"></span>

    <!-- Day Actions Trigger Button -->
    <button class="btn no-bg day-actions" *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp.editable) || (CourseManagementService.data.resourceType === 'section' && resource.teacher || SessionService.userAccess?.permission >= 16 || SessionService.userAccess?.teacher_roles?.length)" [matMenuTriggerFor]="dayActionsMenu">
        <i class="icon icon-ellipses"></i>
    </button>
    <!-- Day Actions Trigger Menu -->
    <mat-menu #dayActionsMenu="matMenu" panelClass="actions-popover" xPosition="before">
        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" [disabled]="CourseManagementService.data.resourceType === 'demo'" (click)="assignTest(day)" *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp.editable) || (CourseManagementService.data.resourceType === 'section' && resource.permissions.tests)">Assign Test</button>
        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" [disabled]="CourseManagementService.data.resourceType === 'demo'" (click)="assignResource(day)" *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp.editable) || (CourseManagementService.data.resourceType === 'section' && resource.permissions.resources)">Assign Resource</button>
        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="openNewNoteModal(day)">Add Note</button>
        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="openDayBlockPopover(day)" *ngIf="!SequencerStateService.data.blockedDays[day.formattedDate]">Block Day</button>
        <button mat-menu-item [disableRipple]="true" class="popover-action limit-text" (click)="deleteBlockedDays(day)" *ngIf="SequencerStateService.data.blockedDays[day.formattedDate]">Unblock Day</button>
    </mat-menu>
</div>

<div *ngIf="SequencerStateService.data.blockedDays[day.formattedDate]" class="calendar-day--blocked-day">
    <div class="text-center limit-text">
        {{ SequencerStateService.data.blockedDays[day.formattedDate].note }}
    </div>
</div>

<div *ngIf="SequencerStateService.data.holidays[day.formattedDate]" class="calendar-day--holiday">
    <div class="text-center" *ngFor="let holiday of SequencerStateService.data.holidays[day.formattedDate]">
        {{ holiday }}
    </div>
</div>

<div class="calendar-day--bottom flex-row justify-end align-items-center space-children-row">
    <!-- Day Resources Popover -->
    <div *ngIf="CalendarService.calendar.displayResources[day.formattedDate]" [ngbPopover]="dayResources" [autoClose]="'outside'" #dayResourcesTrigger="ngbPopover" placement="bottom-right" popoverClass="day-resources-popover" class="cursor-pointer icon-button">
        <i class="fas fa-book cursor-pointer"></i>
    </div>
    <ng-template #dayResources>
        <div class="space-children-column" *ngFor="let resource of CalendarService.calendar.displayResources[day.formattedDate]; last as isLast; index as $index">
            <div class="flex-row justify-space-between align-items-center space-children-row">

                <a [href]="resource.resource.url" target="_blank" rel="noopener" class="flex-row justify-space-between align-items-center">
                    <span class="flex limit-text resource-name">{{ resource.resource.name }}</span>
                    <i class="fas fa-external-link-alt"></i>
                </a>

                <div *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp.editable) || (CourseManagementService.data.resourceType === 'section' && resource.teacher || SessionService.userAccess?.permission >= 16 || SessionService.userAccess?.teacher_roles?.length)" (click)="deleteSspResource(day, resource, $index)" class="cursor-pointer">
                    <i class="far fa-trash-alt"></i>
                </div>
            </div>

            <div class="hr" *ngIf="!isLast"></div>
        </div>
    </ng-template>

    <!-- Day Notes Popover -->
    <div *ngIf="day.notes.length >= 1" [ngbPopover]="dayNotes" [autoClose]="'outside'" #dayNotesTrigger="ngbPopover" placement="bottom-right" popoverClass="popover-simple contained day-notes-popover" class="cursor-pointer icon-button">
        <span *ngIf="day.notes.length === 1"><i class="far fa-sticky-note"></i></span>
        <span *ngIf="day.notes.length > 1"><i class="icon icon-notes"></i></span>
    </div>
    <ng-template #dayNotes>
        <div class="flex-row">
            <div class="cursor-pointer text-info space-children-row small-space" *ngIf="CourseManagementService.data.resourceType === 'demo' || (CourseManagementService.data.resourceType === 'course' && CourseManagementService.data.ssp.editable) || (CourseManagementService.data.resourceType === 'section' && resource.teacher || SessionService.userAccess?.permission >= 16 || SessionService.userAccess?.teacher_roles?.length)" (click)="$event.stopPropagation();openNewNoteModal(day)">
                <i class="fas fa-plus"></i>
                <span>Add a Note</span>
            </div>
        </div>

        <div class="dashed-hr"></div>

        <div class="note-list-item" *ngFor="let note of day.notes; last as isLast">
            <div class="note-container space-children-column" *ngIf="!note.editing && !note.deleting">
                <p class="note-content">{{ note.content}}</p>

                <div class="flex-row justify-space-between align-items-center space-children-row small-space">
                    <span *ngIf="note.editable" class="text-info space-children-row small-space">
                        <span class="cursor-pointer" (click)="$event.stopPropagation();editNote(note)">
                            <i class="far fa-edit"></i>
                        </span>
                        <span class="cursor-pointer" (click)="$event.stopPropagation();deleteNote(note)">
                            <i class="far fa-trash-alt"></i>
                        </span>
                    </span>
                    <span class="space-children-row small-space">
                        <span class="user-name">- {{ note.user }}</span>
                        <span class="note-date">on {{ note.added | date: dateFormat.shortDate: dateFormat.timezone }}</span>
                    </span>
                </div>
            </div>

            <div class="note-container space-children-column" *ngIf="note.editing">
                <textarea class="body-regular" [(ngModel)]="note.tempContent">{{ note.tempContent }}</textarea>

                <div *ngIf="note.editing" class="flex-row justify-end align-items-center space-children-row">
                    <a (click)="$event.stopPropagation();updateNote(note)">Save</a>
                    <a (click)="$event.stopPropagation();cancelEditingNote(note)">Cancel</a>
                </div>
            </div>

            <div class="note-container flex-row space-children-row" *ngIf="note.deleting">
                <span class="note-deleted-text flex">Note deleted</span>
                <a class="text-info flex" (click)="$event.stopPropagation();undoDeleteNote(note)">Undo</a>
            </div>

            <div class="hr" *ngIf="!isLast"></div>
        </div>
    </ng-template>
</div>
