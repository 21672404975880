<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" preserveAspectRatio="xMidYMid meet" class="pie-chart-svg unselectable" viewBox="0 0 960 480" height="100%" width="100%">
    <g class="pie-chart-layer" transform="translate(480, 240)">
        <g class="slices"></g>
        <g class="response-label"></g>
        <g class="lines"></g>
    </g>
</svg>

<div class="chart-popover popover bottom popover-simple-text">
    <div class="arrow"></div>
    <div class="popover-body"></div>
</div>
