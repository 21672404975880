import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxCaptchaModule } from 'ngx-captcha';
import { UIRouterModule } from '@uirouter/angular';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LoginService } from './login.service';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { PracticeRegistrationFormComponent } from './practice-registration-form/practice-registration-form.component';
import { ForgotPasswordFormComponent } from './password-reset/forgot-password.component';
import { NewPasswordFormComponent } from './new-password-form/new-password-form.component';


@NgModule({
    imports: [
        CommonModule,
        NgxCaptchaModule,
        UIRouterModule,
        MatFormModule,
        MatButtonToggleModule,
    ],
    providers: [
        LoginService,
    ],
    declarations: [
        LoginComponent,
        LoginFormComponent,
        RegistrationFormComponent,
        PracticeRegistrationFormComponent,
        ForgotPasswordFormComponent,
        NewPasswordFormComponent,
    ],
    exports: [
        LoginComponent,
        LoginFormComponent,
        RegistrationFormComponent,
        PracticeRegistrationFormComponent,
        ForgotPasswordFormComponent,
        NewPasswordFormComponent,
    ],
})
export class LoginModule { }
