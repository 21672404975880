import { Directive, OnChanges, AfterViewInit, OnDestroy, ElementRef, Input, SimpleChanges } from '@angular/core';


@Directive({
    selector: '[dragDrop]',
    host: {
        '[class.draggable]': 'enableDrag'
    }
})
export class DragDropDirective implements OnChanges, AfterViewInit, OnDestroy {

    @Input() enableDrag: boolean = true;
    private startX: number;
    private startY: number;
    private eleY: number;
    private eleX: number;
    private elementBounds = {
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0
    };
    private resizeHandler = this.updateElementPos.bind(this);

    public constructor(private element: ElementRef) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.enableDrag && change.enableDrag.currentValue !== change.enableDrag.previousValue) {
            this.toggleDragging();
        }
    }

    public ngAfterViewInit() {
        this.toggleDragging();
    }

    public ngOnDestroy() {
        this.disableDragging();
    }

    private toggleDragging() {
        if (this.enableDrag) {
            this.initializeDragging();
        } else {
            this.disableDragging();
        }
    }

    private initializeDragging() {
        if (!this.element.nativeElement.onmousedown) {
            // Add top bar to indicate element can now be dragged
            let draggableBar = document.createElement('div');
            this.element.nativeElement.insertBefore(draggableBar, this.element.nativeElement.firstChild);
            draggableBar.outerHTML = `
                <div class="draggable-symbol flex-row justify-center align-items-end">
                    <span class="draggable-text">........</span>
                </div>`;
            // Apply event listeners
            this.element.nativeElement.onmousedown = this.startDrag.bind(this);
            this.element.nativeElement.ontouchstart = this.startDrag.bind(this);
            window.addEventListener('resize', this.resizeHandler);
        }
    }

    private disableDragging() {
        if (this.element.nativeElement.onmousedown) {
            this.element.nativeElement.querySelector('.draggable-symbol').remove();
            this.element.nativeElement.onmousedown = null;
            this.element.nativeElement.ontouchstart = null;
            window.removeEventListener('resize', this.resizeHandler);
            this.endDrag();
        }
    }

    private updateElementPos() {
        // When window is resized restrict element to be within window bounds
        this.calculateElementBounds();
        let top = Math.max(this.elementBounds.minY, Math.min(this.elementBounds.maxY, this.element.nativeElement.offsetTop));
        let lft = Math.max(this.elementBounds.minX, Math.min(this.elementBounds.maxX, this.element.nativeElement.offsetLeft));
        this.element.nativeElement.style.top = top + 'px';
        this.element.nativeElement.style.left = lft + 'px';
    }

    private calculateElementBounds() {
        let node = this.element.nativeElement;
        let elePos = node.getBoundingClientRect();
        this.elementBounds.minX = node.offsetLeft - elePos.x;
        this.elementBounds.minY = node.offsetTop - elePos.y;
        this.elementBounds.maxX = this.elementBounds.minX + (window.innerWidth - elePos.width);
        this.elementBounds.maxY = this.elementBounds.minY + (window.innerHeight - elePos.height);
    }

    private startDrag(event) {
        event.preventDefault();

        this.calculateElementBounds();
        // Store current element position
        this.eleY = this.element.nativeElement.offsetTop;
        this.eleX = this.element.nativeElement.offsetLeft;
        // Store initial mouse position
        this.startY = event.pageY;
        this.startX = event.pageX;
        // Set dragging listeners
        document.onmousemove = this.dragHandler.bind(this);
        document.ontouchmove = this.dragHandler.bind(this);
        document.onmouseup = this.endDrag.bind(this);
        document.ontouchend = this.endDrag.bind(this);
    }

    private dragHandler(event) {
        // Restrict dragging to be within window bounds
        let top = Math.max(this.elementBounds.minY, Math.min(this.elementBounds.maxY, this.eleY + event.pageY - this.startY));
        let lft = Math.max(this.elementBounds.minX, Math.min(this.elementBounds.maxX, this.eleX + event.pageX - this.startX));
        this.element.nativeElement.style.top = top + 'px';
        this.element.nativeElement.style.left = lft + 'px';
    }

    private endDrag() {
        document.onmousemove = null;
        document.ontouchmove = null;
        document.onmouseup = null;
        document.ontouchend = null;
    }
}
