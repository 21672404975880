import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { LoginService } from '../login.service';
import { AuthService } from '../../core/service/auth.service';
import { UtilsService } from '../../core/service/utils.service';


@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordFormComponent {

    public form: FormGroup;
    public passwordResetFailMessage: string;
    public emailSent: boolean = false;

    get email() { return this.form.get('email'); }

    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private AuthService: AuthService,
        private UtilsService: UtilsService,
    ) {
        this.form = this.fb.group({
            email:  ['', [Validators.required, Validators.maxLength(150)]],
        });
    }

    public resetFormErrors() {
        this.passwordResetFailMessage = null;
    }

    public showForm($event, formName) {
        $event.stopPropagation();
        this.resetFormErrors();
        this.loginService.activeForm = formName;
    }

    public submitPasswordReset($event) {
        $event.stopPropagation();

        this.UtilsService.addLoadingOverlay(true);
        this.AuthService.passwordReset(this.form.value)
            .then(() => {
                this.emailSent = true;
            })
            .catch(error => {
                if (error.result && error.result.msg) {
                    this.passwordResetFailMessage = error.result.msg.msg || 'Sorry. We are encountering issues sending a password reset email. Please try again later.';
                }
            })
            .finally(() => {
                this.UtilsService.removeLoadingOverlay();
                this.form.reset();
            });
    }
}
