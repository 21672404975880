<div *ngIf="option.isMisconceptionsPanelOpen" class="misconception-trigger-panel flex-column" dragDrop [enableDrag]="true">
    <div class="shadow-container">
        <div class="panel-header">
            <div class="modal-close" (click)="option.isMisconceptionsPanelOpen = false">
                <i class="icon icon-x"></i>
            </div>

            <span class="panel-title capitalize" [ngPlural]="questionMisconceptions.data.length">
                <ng-template ngPluralCase="one">Misconception</ng-template>
                <ng-template ngPluralCase="other">Misconceptions</ng-template>
            </span>
        </div>

        <misconception-tabs [misconceptions]="questionMisconceptions.data" [selectedMisconception]="questionMisconceptions.selectedMisconception"></misconception-tabs>

        <div class="panel-content flex-column">
            <span class="body-small"># of students who show evidence of this misconception:</span>
            <div class="student-count">{{ questionMisconceptions.selectedMisconception.num_students }} ({{ questionMisconceptions.selectedMisconception.percent_triggered | number: '1.0-1' }}%)</div>
            <div class="flex-row align-items-center justify-end">
                <div class="learn-more-text-container flex-none align-items-center cursor-pointer" (click)="questionMisconceptions.isLearnMoreOpen = !questionMisconceptions.isLearnMoreOpen">
                    <span class="text-info italic">learn more</span>
                    <i class="icon icon-angle-down" [ngClass]="{ rotate: questionMisconceptions.isLearnMoreOpen }"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="learn-more-container">
        <div class="panel-learn-more" *ngIf="questionMisconceptions.isLearnMoreOpen">
            <div>The following responses trigger {{ questionMisconceptions.selectedMisconception.code }}:</div>

            <div class="question-stems" *ngFor="let stem of questionMisconceptions.selectedMisconception.stems; index as $index">
                <span *ngIf="AssessmentStateService.data.multi.total > 1">Part {{ $index + 1 }}:</span>
                <div class="foil-triggers" *ngFor="let foilTriggers of stem.foil_triggers | keyvalue">
                    <div [ngSwitch]="stem.type">
                        <!-- Stem type 1: Multiple Choice -->
                        <div *ngSwitchCase="1" class="foil-roman-numeral">
                            <span class="trigger-part">{{ foilToRoman(foilTriggers.key) }}</span>
                        </div>

                        <!-- Stem type 2: Multiple Select -->
                        <div *ngSwitchCase="2">
                            <div *ngFor="let trigger of foilTriggers.value" class="foil-roman-numeral">
                                <span class="trigger-part">{{ foilToRoman(trigger.join('|')) }}</span>
                            </div>
                        </div>

                        <!-- Stem type 4: Numeric Response -->
                        <div *ngSwitchCase="4" class="foil-roman-numeral">
                            <span class="trigger-part">{{ foilToRoman(foilTriggers.key) }}-</span>
                            <span *ngFor="let trigger of foilTriggers.value; last as isLast">
                                <span class="trigger-part">{{ trigger.left }}</span>
                                <span class="trigger-part" [innerHTML]="comparisonSymbol(trigger.left_comp)"></span>
                                <span class="trigger-part" *ngIf="trigger.left && trigger.right">
                                    <span>x</span> <span [innerHTML]="comparisonSymbol(trigger.right_comp)"></span>
                                </span>
                                <span class="trigger-part">{{ trigger.right }}</span>
                                <span *ngIf="!isLast">, </span>
                            </span>
                        </div>

                        <!-- Stem type 3: Open Ended | Stem type 6: 1 Letter -->
                        <div *ngSwitchCase="3" class="foil-roman-numeral">
                            <span class="trigger-part">{{ foilToRoman(foilTriggers.key) }}-</span>
                            <span class="trigger-part">{{ foilTriggers.value.join(', ') }}</span>
                        </div>
                        <div *ngSwitchCase="6" class="foil-roman-numeral">
                            <span class="trigger-part">{{ foilToRoman(foilTriggers.key) }}-</span>
                            <span class="trigger-part">{{ foilTriggers.value.join(', ') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
