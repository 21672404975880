<div class="modal-header">
    <h4 class="modal-title">Teachers can:</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body">
    <div class="flex-column space-children-column">
        <mat-checkbox id="edit-schedule" name="edit-schedule" [(ngModel)]="permissions.ssp" [disableRipple]="true">Edit Schedule</mat-checkbox>
        <mat-checkbox id="assign-resources" name="assign-resources" [(ngModel)]="permissions.resources" [disableRipple]="true">Assign/Edit Resources</mat-checkbox>
        <mat-checkbox id="assign-tests" name="assign-tests" [(ngModel)]="permissions.tests" [disableRipple]="true">Assign/Edit Tests</mat-checkbox>
    </div>

</div>

<div class="modal-footer flex-row justify-center align-items-center space-children-row flex-wrap">
    <button class="btn ghost-button" (click)="activeModal.dismiss()">Cancel</button>
    <button class="btn primary-button" (click)="saveChanges()">Save</button>
</div>
