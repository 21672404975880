import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '../../../core/service/utils.service';


@Component({
    selector: 'student-practice-activity-modal',
    templateUrl: './student-practice-activity-modal.component.html'
})
export class StudentPracticeActivityModalComponent implements OnInit {

    @Input() students: any;
    public sortExpression: string;
    public sortReverse = false;

    constructor(
        private DatePipe: DatePipe,
        public activeModal: NgbActiveModal,
        public UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
        if (this.students && this.students.length > 0) {
            this.students.forEach(stu => {
                // Unpack practice score properties to allow sorting
                if (stu.p_score) {
                    Object.assign(stu, stu.p_score);
                }
            });
        }
    }

    public sortBy(prop) {
        this.sortReverse = this.sortExpression === prop ? !this.sortReverse : false;
        this.sortExpression = prop;
        this.students = this.UtilsService.sortByProp(this.students, prop, this.sortReverse);
    }

    public getSubmittedMsg(student) {
        if (student.last_test_date) {
            student.submittedMsg = this.DatePipe.transform(student.last_test_date, dateFormat.datetime, dateFormat.timezone);
        } else {
            student.submittedMsg = '<i>Not Practiced</i>';
        }

        return student.submittedMsg;
    }
}
