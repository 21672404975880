<svg:g
    class="cluster-group"
    [attr.transform]="'translate(' + (cluster.attributes.cx || 0) + ', ' + (cluster.attributes.cy || 0) + ')'">
    <!-- invisible rect that defines the cluster group click target -->
    <svg:rect
        class="cluster-shape"
        [attr.x]="cluster.attributes.bounding_x"
        [attr.y]="cluster.attributes.bounding_y"
        [attr.width]="cluster.attributes.bounding_width"
        [attr.height]="cluster.attributes.bounding_height"
        fill="#fff"
        opacity="0"
        stroke="#000">
    </svg:rect>

    <!-- Test notification (cluster shape) -->
    <svg:g
        class="map-icon-container"
        *ngIf="MapService.learningMap.option.currentZoomLevel === 1 && cluster.test_count"
        [attr.transform]="'translate(640, ' + (cluster.attributes.title_y + 95) + ')'">
        <svg:circle cx="0" cy="0" r="90" fill="#E53935" stroke="#FFF" stroke-width="1.2em"></svg:circle>
        <!-- <text x="0em" y="0.55em" class="map-icon cluster-test-notification-lg">&#123;</text> -->
        <text x="0em" y="0.4em" class="test-count cluster-test-count-lg">{{ cluster.test_count }}</text>
    </svg:g>

    <!-- cluster title -->
    <foreignObject
        x="-32em"
        [attr.y]="cluster.attributes.title_y || 0"
        width="64em"
        height="22.5em">
        <xhtml:div
            class="zoom-level-transition cluster-title flex-column flex-none align-items-center"
            [class.cluster-title-invalid]="!cluster.valid"
            [style.font-size]="mapSizesAndBounds.cluster.fontSizes[MapService.learningMap.option.currentZoomLevel]">
            <xhtml:div *ngIf="MapService.learningMap.option.codeEnabled">{{ cluster.code }}</xhtml:div>
            <xhtml:div class="bold" [innerHTML]="cluster.name"></xhtml:div>
        </xhtml:div>
    </foreignObject>

    <!-- lines that connect constructs -->
    <svg:line
        *ngFor="let connector of mapSizesAndBounds.cluster.patternConnectors[cluster.pattern]"
        class="construct-line"
        [class.construct-line-invalid]="!cluster.valid"
        [attr.x1]="connector.x1 * mapSizesAndBounds.cluster.width"
        [attr.y1]="connector.y1 * mapSizesAndBounds.cluster.width"
        [attr.x2]="connector.x2 * mapSizesAndBounds.cluster.width"
        [attr.y2]="connector.y2 * mapSizesAndBounds.cluster.width">
    </svg:line>

    <!-- external resource button -->
    <svg:g
        class="cluster-resource-button"
        *ngIf="MapService.learningMap.option.currentZoomLevel > 1"
        [attr.transform]="'translate(' + (-mapSizesAndBounds.cluster.width * 0.10) + ', ' + (cluster.attributes.title_y + 240) + ')'"
        (click)="showClusterResources($event, cluster)">
        <svg:rect x="0" y="0" width="6em" height="5.625em" class="button-outline"></svg:rect>
        <text x="0.75em" y="1.25em" class="button-icon">&#53;</text>
    </svg:g>

    <!-- assessment button -->
    <svg:g
        class="cluster-assessment-button"
        [class.disabled]="!cluster.assessment"
        *ngIf="MapService.learningMap.option.currentZoomLevel > 1"
        [attr.transform]="'translate(' + (mapSizesAndBounds.cluster.width * 0.02) + ', ' +  (cluster.attributes.title_y + 240) + ')'"
        (click)="showAssessmentOptions($event, cluster)">
        <svg:rect x="0" y="0" width="6em" height="5.625em" class="button-outline"></svg:rect>
        <text x="0.7em" y="1.15em" class="button-icon">&#52;</text>
    </svg:g>
    <!-- Test notification (assessment button) -->
    <svg:g
        class="map-icon-container"
        *ngIf="MapService.learningMap.option.currentZoomLevel > 1 && cluster.test_count"
        [attr.transform]="'translate(' + (mapSizesAndBounds.cluster.width * 0.02 + 100) + ', ' +  (cluster.attributes.title_y + 235) + ')'">
        <svg:circle cx="0" cy="0" r="33" fill="#E53935" stroke="#FFF" stroke-width="0.5em"></svg:circle>
        <!-- <text x="0em" y="0.55em" class="map-icon cluster-test-notification-sm">&#123;</text> -->
        <text x="0em" y="0.4em" class="test-count cluster-test-count-sm">{{ cluster.test_count }}</text>
    </svg:g>
</svg:g>

<!-- construct button, stacks, pins, etc. -->
<svg:g *ngIf="MapService.learningMap.option.currentZoomLevel >= mapSizesAndBounds.construct.visibleAtLevel">
    <svg:g
        class="constructs-group zoom-level-transition"
        *ngFor="let construct of cluster.constructs">
        <svg:g
            map-construct
            [construct]="construct"
            [cluster]="cluster">
        </svg:g>
        <svg:g
            standard-pin
            [construct]="construct"
            (click)="$event.stopPropagation(); MapService.zoomChange.next({ level: 4, x: construct.attributes.cx, y: construct.attributes.cy })">
        </svg:g>
        <svg:g
            misconception-pin
            [construct]="construct"
            (click)="$event.stopPropagation(); MapService.zoomChange.next({ level: 4, x: construct.attributes.cx, y: construct.attributes.cy })">
        </svg:g>
    </svg:g>
</svg:g>
