<div class="flex footer-left-side">
    <div class="flex-row justify-start align-items-center">
        <div class="btn-link footer-item limit-text" (click)="openAboutModal()">About</div>
        <a class="btn-link footer-item limit-text" [href]="userGuidePath" *ngIf="SessionService.userAccess?.access.includes('help')" target="_blank" rel="noopener">User Guide</a>
        <div class="btn-link footer-item limit-text" (click)="openHelpModal()">Contact Us</div>
    </div>
</div>

<div class="flex footer-right-side full-height">
    <div class="flex-row justify-end align-items-center full-height">
        <div class="btn-link footer-item limit-text" (click)="openAccessibilityModal()">Accessibility</div>
        <div class="btn-link footer-item limit-text" (click)="openDataPrivacyModal()">Data Privacy</div>
        <div class="btn-link footer-item limit-text" (click)="openTermsOfUseModal()">Terms of Use</div>
        <a class="footer-item image-container full-height" [href]="appInfo.providerUrl" target="_blank" rel="noopener">
            <img [src]="appInfo.providerLogo" class="footer-logo" [alt]="appInfo.provider + ' logo'">
        </a>
    </div>
</div>
