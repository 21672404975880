import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { CoachmarksService } from './coachmarks.service';
import { coachmarksData, coachmarksMeta } from './coachmarks.constant';
import { SessionService } from '../service/session.service';

import './coachmarks.component.scss';


@Component({
    selector: 'coach-marks',
    templateUrl: './coachmarks.component.html',
    // styleUrls: ['./coachmarks.component.scss'],
})
export class CoachMarksComponent implements OnInit, OnDestroy {

    public coachmark = null;
    public coachmarkState = {
        currentlyViewing: null,
    };
    public coachmarkSubscription: Subscription;
    public imageLoaded: boolean = false;

    constructor(
        private SessionService: SessionService,
        private CoachmarksService: CoachmarksService,
    ) {}

    public ngOnInit() {
        this.coachmarkSubscription = this.CoachmarksService.getCurrentState().subscribe(newState => {
            const newCM = newState.currentlyViewing;
            this.coachmarkState = newState;
            if (this.coachmark === null && (newCM || newCM === 0)) {
                // Do not open coach marks in small screens
                if (window.innerWidth < 768) {
                    return this.exitCoachMarks();
                }
                // Disable scrolling before loading coach mark
                if (!document.body.classList.contains('modal-open')) {
                    document.body.classList.add('modal-open');
                }
                this.navigate(newState.currentlyViewing);
            } else if (newCM === null && this.coachmark) {
                this.exitCoachMarks();
            }
        });
    }

    public ngOnDestroy() {
        this.coachmarkSubscription.unsubscribe();
    }

    public requestPreviousCoachmark() {
        this.coachmarkState.currentlyViewing = this.coachmarkState.currentlyViewing - 1;
        this.CoachmarksService.updateState(this.coachmarkState);
        this.navigate(this.coachmarkState.currentlyViewing);
    }

    public requestNextCoachmark() {
        this.coachmarkState.currentlyViewing = this.coachmarkState.currentlyViewing + 1;
        this.CoachmarksService.updateState(this.coachmarkState);
        this.navigate(this.coachmarkState.currentlyViewing);
    }

    public navigate(nextIndex) {
        this.imageLoaded = false;
        // Update current coachmark in component to display on template
        this.coachmark = this.CoachmarksService.getCoachmarkData(nextIndex);
        if (nextIndex < 0
            || nextIndex >= coachmarksData.length
            || (nextIndex === coachmarksMeta.sequencer.start
                && !this.SessionService.userAccess.access.includes('sequencer'))
        ) {
            this.exitCoachMarks();
        }
        this.CoachmarksService.recordMilestones(nextIndex);
    }

    public exitCoachMarks() {
        // Makes coach marks modal disappear
        this.coachmark = null;
        // Update currently viewing value
        this.coachmarkState.currentlyViewing = null;
        this.CoachmarksService.updateState(this.coachmarkState);
        // Allow scrolling on page again
        document.body.classList.remove('modal-open');
    }

    public loadedImage() {
        this.imageLoaded = true;
    }
}
