export const coachmarksData = [
    {
        src: '0_tour_intro/1_intro_screen',
        back: false,
        exit: {
            text: 'No thanks',
            position: {
                top: '50%',
                left: '40%',
                bottom: 'initial',
                right: 'initial'
            }
        },
        next: {
            text: 'Let\'s get started',
            position: {
                top: '50%',
                left: 'calc(40% + 107px + 25px)',
                bottom: 'initial',
                right: 'initial'
            }
        }
    },
    {
        src: '1_nav_map/1_fields',
        back: false,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/2_big_ideas',
        back: true,
        next: true,
        exit: true,
        learnMore: {
            popover: {
                title: 'Big Ideas',
                content: 'Each big idea describes a set of core concepts and methods that focus explorations in middle school math.'
            },
            position: {
                top: '55%',
                left: '67%'
            }
        }
    },
    {
        src: '1_nav_map/3_constructs',
        back: true,
        next: true,
        exit: true,
        learnMore: {
            popover: {
                title: 'Constructs',
                content: 'The constructs in a cluster should be learned together. Diagnostic assessments are available for each cluster on the map.'
            },
            position: {
                top: '51%',
                left: '80%'
            }
        }
    },
    {
        src: '1_nav_map/4_big_idea_zoom',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/5_learning_trajectories',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/5a_misconceptions',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/5b_standards',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/5c_options',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '1_nav_map/6_ui_buttons',
        back: {
            position: {
                right: 'calc(4% + 99px + 20px)'
            }
        },
        next: {
            text: 'Continue',
        },
        exit: true
    },
    {
        src: '2_take_test/1_take_test_intro',
        back: false,
        next: true,
        exit: true
    },
    {
        src: '2_take_test/2_test_menu',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '2_take_test/3_take_test_option',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '2_take_test/4_test_overview',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '2_take_test/5_take_test_outro',
        back: {
            position: {
                right: 'calc(4% + 99px + 20px)'
            }
        },
        next: {
            text: 'Continue'
        },
        exit: true
    },
    {
        src: '3_view_reports/1_test_results',
        back: false,
        next: true,
        exit: true
    },
    {
        src: '3_view_reports/2_test_results',
        back: {
            position: {
                right: 'calc(4% + 99px + 20px)'
            }
        },
        next: {
            text: 'Continue',
        },
        exit: false
    },
    {
        src: '4_access_resources/1_resourcer_icon',
        back: false,
        next: true,
        exit: true
    },
    {
        src: '4_access_resources/2_resourcer_menu',
        back: {
            position: {
                right: 'calc(4% + 99px + 20px)'
            }
        },
        next: {
            text: 'Continue',
        },
        exit: false
    },
    {
        src: '5_sequencer/1_manage_courses',
        back: false,
        next: true,
        exit: true
    },
    {
        src: '5_sequencer/2_sequencer',
        back: true,
        next: true,
        exit: true
    },
    {
        src: '5_sequencer/3_calendar',
        back: {
            position: {
                right: 'calc(4% + 116px + 20px)'
            }
        },
        next: {
            text: 'Get Started',
        },
        exit: false
    }
];

const findFirstOccurrence = (text) => {
    return coachmarksData.findIndex(coachmark => coachmark.src.includes(text));
};

const findSectionLength = (text) => {
    return coachmarksData.filter(coachmark => coachmark.src.includes(text)).length;
};

export const coachmarksMeta = {
    min: 0,
    map: {
        start: findFirstOccurrence('1_nav_map'),
        length: findSectionLength('1_nav_map')
    },
    test: {
        start: findFirstOccurrence('2_take_test'),
        length: findSectionLength('2_take_test')
    },
    report: {
        start: findFirstOccurrence('3_view_reports'),
        length: findSectionLength('3_view_reports')
    },
    resourcer: {
        start: findFirstOccurrence('4_access_resources'),
        length: findSectionLength('4_access_resources')
    },
    sequencer: {
        start: findFirstOccurrence('5_sequencer'),
        length: findSectionLength('5_sequencer')
    },
    max: coachmarksData.length - 1
};
