<div *ngIf="SchoolReportService.isGraphReset" class="p-2 text-center">
    <!--No test data for these selections-->
</div>

<div *ngIf="!SchoolReportService.isGraphReset" class="text-center">
    <span [innerHTML]="SchoolReportService.title"></span>
</div>

<div *ngIf="!SchoolReportService.isGraphReset">
    <mat-tab-group color="accent" [selectedIndex]="selectedIndex" style="width:65vw;">
        <mat-tab *ngFor="let tabData of stackedBarPlotData" [label]="tabData.timePeriod">
            <ng-template matTabContent>
                <div *ngIf="tabData.isTabEmpty" class="p-2 text-center">No test data in this time period</div>

                <div *ngFor="let graphData of tabData.chartData">
                    <div class="p-2">{{ graphData.name }}</div>
                    <plotly-plot
                        [data]="graphData.data"
                        [layout]="SchoolReportService.graphLayout"
                        [config]="SchoolReportService.graphConfig"
                        [useResizeHandler]="true"
                        [divId]="'school-report-chart'"
                        (legendClick)="SchoolReportService.hideLegend($event)">
                    </plotly-plot>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
