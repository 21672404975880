import { Component, Input } from '@angular/core';

import { UtilsService } from '../../../core/service/utils.service';


@Component({
    selector: 'construct-info-column',
    templateUrl: './construct-info-column.component.html',
})
export class ConstructInfoColumnComponent {

    @Input() public pageResults: any;
    @Input() public cluster: any;

    constructor(public UtilsService: UtilsService) {}
}
