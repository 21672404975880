<div class="sequencer-container page-content top">
    <div class="flex-row justify-space-between align-items-center">
        <h2>New Course</h2>
    </div>
</div>

<div class="sequencer-container page-content bottom container-fluid">
    <form class="new-course-form" name="newCourseForm" [formGroup]="form">
        <div class="flex-row align-items-start space-children-row">
            <div class="flex-column flex-50">
                <mat-form-field appearance="outline">
                    <mat-label>Course Code</mat-label>
                    <input
                        matInput
                        type="text"
                        id="course-code"
                        name="courseCode"
                        maxlength="20"
                        formControlName="code"
                        required>
                </mat-form-field>
            </div>
            <div class="flex-column flex-50">
                <mat-form-field appearance="outline">
                    <mat-label>Course Nickname</mat-label>
                    <input
                        matInput
                        type="text"
                        id="course-nickname"
                        name="courseNickname"
                        maxlength="75"
                        formControlName="name">
                </mat-form-field>
            </div>
        </div>

        <div class="select-row flex-row align-items-center space-children-row">
            <div class="flex-50 space-children-row align-items-center">
                <label class="required no-wrap">School Year</label>

                <ng-select  class="custom flex"
                            [items]="schoolYears"
                            appendTo="body"
                            bindLabel="name"
                            bindValue="id"
                            [clearable]="false"
                            groupBy="school_name"
                            [searchable]="false"
                            formControlName="school_year"
                            (change)="selectSchoolYear($event)"
                            name="schoolYear"
                            placeholder="Select a school year"
                            required>
                </ng-select>
            </div>

            <div class="flex-50 space-children-row align-items-center">
                <label>Grade(s)</label>

                <ng-select  class="custom flex"
                            [items]="grades"
                            appendTo="body"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="true"
                            [clearable]="true"
                            [searchable]="true"
                            [closeOnSelect]="false"
                            [hideSelected]="true"
                            formControlName="grades"
                            name="grades"
                            placeholder="Select one or more grades"
                            required>
                </ng-select>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-row align-items-center space-children-row">
                <div class="flex-35 align-items-center space-children-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input
                            matInput
                            type="text"
                            [matDatepicker]="startDate"
                            placeholder="mm/dd/yyyy"
                            formControlName="start_date"
                            id="start_date"
                            name="startdate"
                            [min]="datepickerOptions.minDate"
                            [max]="datepickerOptions.maxDate"
                            required>
                        <mat-datepicker-toggle matSuffix [for]="startDate" [disableRipple]="true"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="flex-35 align-items-center space-children-row">
                    <mat-form-field appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input
                            matInput
                            type="text"
                            [matDatepicker]="endDate"
                            placeholder="mm/dd/yyyy"
                            formControlName="end_date"
                            id="end_date"
                            name="endDate"
                            [min]="datepickerOptions.minDate"
                            [max]="datepickerOptions.maxDate"
                            required>
                        <mat-datepicker-toggle matSuffix [for]="endDate" [disableRipple]="true"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="flex-30 align-items-center space-children-row num-sections-row">
                    <mat-form-field appearance="outline">
                        <mat-label>Number of Sections</mat-label>
                        <input
                            matInput
                            type="number"
                            id="number-of-sections"
                            name="numOfSections"
                            formControlName="section_num"
                            placeholder="Create between 1 and 9 sections"
                            min="1"
                            max="9"
                            step="1" />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <mat-error *ngIf="form.errors?.datelt">Start date must be before end date</mat-error>

        <div class="flex-row justify-end align-items-center space-children-row">
            <button type="button" class="btn ghost-button" uiSref="sequencer.courses">Cancel</button>
            <button type="submit" class="btn primary-button" (click)="createNewCourse()" [disabled]="!form.valid">Create Course</button>
        </div>
    </form>
</div>
