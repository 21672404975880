<div class="modal-header">
    <div class="modal-close" (click)="activeModal.close(updateScoreMatrix)">
        <i class="icon icon-x"></i>
    </div>

    <div class="flex-row align-items-center space-children-row big-space justify-space-between flex-wrap">
        <h4 class="modal-title">Question {{ AssessmentStateService.data.currentQuestion + 1 }} of {{ questions.length }} </h4>

        <div class="reporting-nav-arrows space-children-row small-space">
            <button type="button" class="btn primary-button icon-button flex-none inline-flex align-items-center justify-center" [disabled]="AssessmentStateService.data.currentQuestion <= 0" (click)="navigateToQuestion(AssessmentStateService.data.currentQuestion - 1)">
                <i class="fas fa-arrow-left"></i>
            </button>

            <button type="button" class="btn primary-button icon-button flex-none inline-flex align-items-center justify-center" [disabled]="AssessmentStateService.data.currentQuestion >= questions.length - 1" (click)="navigateToQuestion(AssessmentStateService.data.currentQuestion + 1)">
                <i class="fas fa-arrow-right"></i>
            </button>
        </div>
    </div>
</div>

<div class="modal-body">
    <question class="flex-row full-height flex-wrap" (revealAnswer)="revealAnswer($event)"></question>

    <assessment-toast (closed)="onToastClosed($event)"></assessment-toast>
</div>

<div class="modal-footer flex-row align-items-center justify-space-between flex-wrap space-children-row">
    <div class="points space-children-row" *ngIf="!AssessmentStateService.data.isPracticeTest">
        <span>Points Earned: <span class="points-count">{{ questionScore.revised || questionScore.revised === 0 ? questionScore.revised : questionScore.points || 0 }}</span></span>
        <span>Points Possible: <span class="points-count">{{ questionScore.total }}</span></span>
    </div>
    <div class="practice-construct-name flex" *ngIf="AssessmentStateService.data.isPracticeTest">
        <span>Practice: <span [innerHTML]="AssessmentStateService.data.construct.name"></span></span>
    </div>

    <div class="flex-none align-items-center justify-space-between assessment-actions-bar" *ngIf="!AssessmentStateService.data.isPracticeTest">
        <!-- Left side buttons -->
        <button class="test-action left btn ghost-button white-bg upcase" (click)="requestAnswerReveal()" *ngIf="AssessmentStateService.data.question?.isSubmitted && AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isRevealable" [disabled]="!hasEditPermission || AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isCorrect">
            <span>Reveal</span>
            <span *ngIf="AssessmentStateService.data.multi.total > 1">&nbsp;Part {{ AssessmentStateService.data.multi.current }}</span>
        </button>

        <!-- Right side buttons -->
        <button class="test-action right btn ghost-button white-bg upcase" (click)="allowQuestionRevision()" *ngIf="AssessmentStateService.data.question?.isSubmitted && !AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isRevised" [disabled]="!hasEditPermission || AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isCorrect || !AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isRevealable">
            <span>Revise</span>
            <span *ngIf="AssessmentStateService.data.multi.total > 1">&nbsp;Part {{ AssessmentStateService.data.multi.current }}</span>
        </button>

        <button class="test-action right btn ghost-button white-bg upcase" (click)="submitRevisedAnswer()" *ngIf="!AssessmentStateService.data.question?.isSubmitted && !AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isRevised" [disabled]="!hasEditPermission || !AssessmentStateService.data.multi.isPartAnswered || AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isCorrect || !AssessmentStateService.data.question?.stems[AssessmentStateService.data.multi.current - 1]?.isRevealable">
            <span>Submit</span>
            <span *ngIf="AssessmentStateService.data.multi.total > 1">&nbsp;Part {{ AssessmentStateService.data.multi.current }}</span>
        </button>
    </div>
</div>
