import * as moment from 'moment';


let nextUid = 1;
export default class SequencerRegion {

    private _startDate: any;
    private _endDate: any;

    public uid = nextUid++;
    public region: any;
    public order: number;
    public days: number;
    public clusters = [];

    constructor(
        json: any,
        public ext = {}
    ) {
        if ('base' in json) {
            ext = json.ext;
            json = json.base;
        }

        this.region = json.region;
        this.order = json.order;
        this.days = json.days;

        // re-hydrate the dates
        this.startDate = json.start_date; // note: could be null, which is valid
        this.endDate = json.end_date; // note: could be null, which is valid
    }

    get startDateIsoString() {
        return (this._startDate) ? this._startDate.format('YYYY-MM-DD') : '';
    }

    get startDate() {
        return this._startDate;
    }
    set startDate(d) {
        if (d === undefined || d === null) {
            this._endDate = undefined;
        }
        if (typeof d === 'string') {
            this._startDate = (d === '') ? undefined : moment(d);
        } else {
            this._startDate = d;
        }
    }

    get endDateIsoString() {
        return (this._endDate) ? this._endDate.format('YYYY-MM-DD') : '';
    }

    get endDate() {
        return this._endDate;
    }
    set endDate(d) {
        if (d === undefined || d === null) {
            this._endDate = undefined;
        }
        if (typeof d === 'string') {
            this._endDate = (d === '') ? undefined : moment(d);
        } else {
            this._endDate = d;
        }
    }

    // this function is for displaying date string on pie tab
    // and this if for end date only as end date is only get dispalyed on pie
    get endDateWithoutYearString() {
        return moment(this._endDate).format('MM/DD');
    }


    snapshot() {
        // clone the model, making sure no object references are common between existing and new
        return {
            base: this.toJson(),
            ext: JSON.parse(JSON.stringify(this.ext))
        };
    }

    public toJson() {
        // create a plain vanilla object with keys that the api expects (no underscore prefix)
        // and values that are primitive; no referenced objects
        return {
            order: this.order,
            days: this.days,
            start_date: this.startDateIsoString,
            end_date: this.endDateIsoString,
            region: {
                id: this.region.id,
                name: this.region.name,
                short_name: this.region.short_name,
                wormhole: this.region.wormhole,
            },
        };
    }
}
