import { Directive, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';


@Directive({
    selector: '[imageSrc]',
})
export class ImageSrcDirective implements OnChanges, AfterViewInit {

    @Input() imageSrc: string;
    @Output() imageOnLoad = new EventEmitter();
    @Output() imageOnError = new EventEmitter();

    public constructor(private element: ElementRef) {}

    public ngAfterViewInit() {
        this.element.nativeElement.onload = () => {
            this.imageOnLoad.emit();
        };

        this.element.nativeElement.onerror = () => {
            console.warn('image could not be loaded', this.element.nativeElement.src);
            this.imageOnError.emit();
        }

        this.element.nativeElement.src = this.imageSrc;
    }

    public ngOnChanges(change: SimpleChanges) {
        if (change.imageSrc) {
            this.element.nativeElement.src = change.imageSrc;
        }
    }
}
