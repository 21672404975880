<div class="modal-header">
    <h4 class="modal-title">New Note</h4>

    <button class="btn modal-close" (click)="activeModal.dismiss()">
        <i class="icon icon-x"></i>
    </button>
</div>

<div class="modal-body space-children-column">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Note</mat-label>
        <textarea   matInput
                    id="new-note-input"
                    name="new-note-input"
                    [(ngModel)]="content"
                    required></textarea>
    </mat-form-field>
</div>

<div class="modal-footer flex-row align-items-center justify-center space-children-row">
    <button class="btn ghost-button" (click)="activeModal.dismiss()">Cancel</button>
    <button class="btn primary-button" (click)="submitNewNote()">Save</button>
</div>
