import { Component, Input, Output, EventEmitter } from '@angular/core';

import './calendar-navigation.component.scss';


@Component({
    selector: 'calendar-navigation',
    templateUrl: './calendar-navigation.component.html',
    // styleUrls: ['./calendar-navigation.component.scss'],
    host: {
        class: 'calendar-navigation',
    },
})
export class CalendarNavigationComponent {
    @Input() title: string;
    @Output() navigate = new EventEmitter<string>();

    constructor() {}
}
