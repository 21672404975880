import { Injectable } from '@angular/core';

import { apiServer } from '@/app.constant';
import { HttpService } from '@/core/service/http.service';
import { AnalyticsService } from '@/core/service/analytics.service';
import { SessionService } from '@/core/service/session.service';


@Injectable()
export class ProfileService {

    constructor(
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private SessionService: SessionService,
    ) {}

    public updateUserProfile(profile): any {
        let url = `${apiServer.urlPrefix}/user/profile/`;

        return this.HttpService.post(url, profile)
            .then((response) => {
                this.AnalyticsService.action({ action: 'update_user', profile });

                this.SessionService.setUserProfile({ ...response.result });

                return Promise.resolve(response.result);
            })
            .catch((error) => {
                this.AnalyticsService.warning({ action: 'update_user_fail', profile });

                return Promise.reject(error);
            });
    }
}
