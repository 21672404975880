import { Component, ElementRef, ChangeDetectorRef, Input, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';


@Component({
    selector: 'truncate-text',
    templateUrl: './truncate-text.component.html',
    host: {
        'class': 'truncate-text'
    }
})
export class TruncateTextComponent implements OnChanges, AfterViewInit {

    @Input() chars: number = 140;
    @Input() truncateDisabled: boolean = false;
    @ViewChild('textContent', { static: false }) textContent: ElementRef;
    public textOverflow = false;
    public innerText: string;
    private innerHTML: string;

    constructor(
        private element: ElementRef,
        private cdr: ChangeDetectorRef
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.truncateDisabled && change.truncateDisabled.currentValue !== change.truncateDisabled.previousValue) {
            this.toggleTruncate();
        }
    }

    public ngAfterViewInit() {
        this.innerHTML = this.textContent.nativeElement.innerHTML;
        this.innerText = this.textContent.nativeElement.innerText.trim();
        this.toggleTruncate();
        this.cdr.detectChanges();
    }

    public toggleTruncate() {
        if (this.innerText) {
            if (this.truncateDisabled) {
                // Undo truncate if previously enabled (inner text is different)
                if (this.textContent.nativeElement.innerHTML !== this.innerHTML) {
                    // Note: This will break inner content directives such as mathHTML
                    this.textContent.nativeElement.innerHTML = this.innerHTML;
                }
            } else {
                // Not necessary to truncate when it's a short text
                if (this.innerText.length <= this.chars) { return; }

                this.textOverflow = true;
                this.textContent.nativeElement.innerText = this.innerText.substr(0, this.chars) + '...';
            }
        }
    }
}
