import { Component, OnInit, OnDestroy } from '@angular/core';

import { MapService } from './map.service';
import { SessionService } from '../core/service/session.service';
import { ModalService } from '../shared/service/modal.service';

import './map.component.scss';


@Component({
    selector: 'map-page',
    templateUrl: './map.component.html',
    // styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {

    constructor(
        private SessionService: SessionService,
        private ModalService: ModalService,
        public MapService: MapService
    ) {}

    public ngOnInit() {
        // open up free practice test intro if criteria is met
        if (this.SessionService.getMapInterstitial()) {
            this.ModalService.openFreePracticeIntroModal();
        };
    }

    public ngOnDestroy() {
        this.MapService.resetLearningMap();
    }
}
