import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { AssessmentStateService } from '../../../assessment/common/assessment-state.service';

import './confetti.component.scss';


export class ConfettiPiece {

    private COLORS = [
        [235, 90, 70],
        [97, 189, 79],
        [242, 214, 0],
        [0, 121, 191],
        [195, 119, 224]
    ];
    private style: number[];
    private rgb: string;
    private r: number;
    private r2: number;
    private width: number;

    private opacity: number;
    private dop: number;
    private x: number;
    private y: number;
    private xmax: number;
    private ymax: number;
    private vx: number;
    private vy: number;
    private xpos = 0.9; // 0 = left edge, 1 = right edge

    constructor(
        private canvasEle: HTMLCanvasElement,
        private context: CanvasRenderingContext2D,
    ) {
        // ~~ found here multiple times is essentially a more efficient Math.floor
        // https://stackoverflow.com/questions/4055633/what-does-double-tilde-do-in-javascript
        this.style = this.COLORS[~~this.range(0, 5)];
        this.rgb = 'rgba(' + this.style[0] + ',' + this.style[1] + ',' + this.style[2];
        this.r = ~~this.range(2, 6);
        this.r2 = 2 * this.r;
        this.replace();
    }

    public updateXPos(newPos) {
        this.xpos = newPos;
    }

    public replace() {
        this.opacity = 0;
        this.dop = 0.03 * this.range(1, 4);
        this.x = this.range(-this.r2, this.canvasEle.width - this.r2);
        this.y = this.range(-20, this.canvasEle.height - this.r2);
        this.xmax = this.canvasEle.width - this.r;
        this.ymax = this.canvasEle.height - this.r;
        this.vx = this.range(0, 2) + 8 * this.xpos - 5;
        this.vy = 0.7 * this.r + this.range(-1, 1);
    }

    public draw(percentProgress = 0) {
        this.x += this.vx;
        this.y += this.vy;
        this.opacity += this.dop;

        if (this.opacity > 1) {
            this.opacity = 1;
            this.dop *= -1;
        } else if (this.opacity < 0 || this.y > this.ymax) {
            this.replace();
        } else if (percentProgress > 0.8 && this.dop > 0) {
            // Start fading everything when we're close to ending the animation
            this.dop *= -1;
        }

        if (!(this.x > 0 && this.x < this.xmax)) {
            this.x = (this.x + this.xmax) % this.xmax;
        }

        this.drawCircle(~~this.x, ~~this.y, this.r, this.rgb + ',' + this.opacity + ')');
        this.drawCircle3(0.5 * ~~this.x, ~~this.y, this.r, this.rgb + ',' + this.opacity + ')');
        this.drawCircle2(1.5 * ~~this.x, 1.5 * ~~this.y, this.r, this.rgb + ',' + this.opacity + ')');
    }

    private range(a, b) {
        return (b - a) * Math.random() + a;
    }

    private drawCircle(a, b, c, d) {
        this.context.beginPath();
        this.context.moveTo(a, b);
        this.context.bezierCurveTo(a - 17, b + 14, a + 13, b + 5, a - 5, b + 22);
        this.context.lineWidth = 2;
        this.context.strokeStyle = d;
        return this.context.stroke();
    }

    private drawCircle2(a, b, c, d) {
        this.context.beginPath();
        this.context.moveTo(a, b);
        this.context.lineTo(a + 6, b + 9);
        this.context.lineTo(a + 12, b);
        this.context.lineTo(a + 6, b - 9);
        this.context.closePath();
        this.context.fillStyle = d;
        return this.context.fill();
    }

    private drawCircle3(a, b, c, d) {
        this.context.beginPath();
        this.context.moveTo(a, b);
        this.context.lineTo(a + 5, b + 5);
        this.context.lineTo(a + 10, b);
        this.context.lineTo(a + 5, b - 5);
        this.context.closePath();
        this.context.fillStyle = d;
        return this.context.fill();
    }
}

@Component({
    selector: 'confetti',
    template: `<canvas #canvasEle class="confetti-canvas" width="800" height="600"></canvas>`,
    // styleUrls: ['./confetti.component.scss'],
})
export class ConfettiComponent implements OnInit, AfterViewInit, OnDestroy {

    private NUM_CONFETTI = 50;
    private DURATION = 2000;

    @ViewChild('canvasEle', { static: false }) canvasEle: ElementRef;
    private context: any;
    private timeStart: number;
    private percentProgress: number;
    private confettiList = [];
    private sub1: Subscription;
    private sub2: Subscription;
    private resizeHandler = this.resizeCanvas.bind(this);

    constructor(
        private element: ElementRef,
        private AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnInit() {
        this.sub1 = this.AssessmentStateService.correctQuestion$.subscribe(() => {
            this.startAnimation();
        });

        this.sub2 = this.AssessmentStateService.newQuestion$.subscribe(() => {
            this.rushAnimationEnd();
        });
    }

    public ngAfterViewInit() {
        this.context = this.canvasEle.nativeElement.getContext('2d');
        for (let i = 0; i < this.NUM_CONFETTI; i++) {
            this.confettiList.push(new ConfettiPiece(this.canvasEle.nativeElement, this.context));
        }
    }

    public ngOnDestroy() {
        this.sub1.unsubscribe();
        this.sub2.unsubscribe();
    }

    private resizeCanvas() {
        this.canvasEle.nativeElement.width = this.element.nativeElement.parentNode.offsetWidth;
        this.canvasEle.nativeElement.height = this.element.nativeElement.parentNode.offsetHeight;
    }

    private updateXPos(event) {
        this.confettiList.forEach(c => {
            c.updateXPos(event.pageX / this.canvasEle.nativeElement.width);
        });
    }

    private animationStep(timestamp) {
        this.timeStart = this.timeStart || timestamp;
        this.percentProgress = (timestamp - this.timeStart) / this.DURATION;
        this.clearCanvas();
        for (let b = 0; b < this.confettiList.length; b++) {
            this.confettiList[b].draw(this.percentProgress);
        }
        window.requestAnimationFrame(this.percentProgress < 1 ? this.animationStep.bind(this) : this.endAnimation.bind(this));
    }

    private clearCanvas() {
        this.context.clearRect(0, 0, this.canvasEle.nativeElement.width, this.canvasEle.nativeElement.height);
    }

    private endAnimation() {
        window.removeEventListener('resize', this.resizeHandler);
        document.onmousemove = null;
        this.clearCanvas();
    }

    private startAnimation() {
        this.timeStart = null;
        this.resizeCanvas();
        window.addEventListener('resize', this.resizeHandler);
        document.onmousemove = this.updateXPos.bind(this);
        window.requestAnimationFrame(this.animationStep.bind(this));
    }

    private rushAnimationEnd() {
        // We want to get progress to ~0.8 but this is derived from start value
        let timeLeft = (0.8 - this.percentProgress) * this.DURATION;
        this.timeStart -= timeLeft;
    }
}
