import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SchoolReportService } from '@/report/school/school-report.service';

// import './usage-donut-chart.component.scss';


@Component({
    selector: 'usage-donut-chart',
    templateUrl: 'usage-donut-chart.component.svg.html',
    // styleUrls: ['./usage-donut-chart.component.scss'],
    host: {
        class: 'usage-donut-chart'
    }
})
export class UsageDonutChartComponent implements OnChanges {

    @Input() public graphData: any;
    @Input() public graphParams: any;
    private COLOR_PALETTE = [
        '#4A4BA3', '#C5396C', '#4ABAA3', '#877B5D', '#FF8333', '#A093B7', '#F60563', '#F3A6CE',
        '#C690FF', '#FDC8AD', '#D4575A', '#AD97DC', '#5D7299', '#D08E75', '#FFD933', '#FBF48B',
        '#D0E1D1', '#FDC8AD', '#FFCC82', '#D5D4DC', '#DACE4C', '#FAA7A5', '#63C8CF', '#6AC15F',
        '#DEA235', '#B9A991', '#A19C98', '#ADB2C7', '#8AC6A1', '#A0A53E', '#A88E59', '#F67B62',
        '#7C91CD', '#5A94D2', '#AC7545', '#F87929', '#5B644E', '#786F81', '#346EA0', '#4C5A63',
        '#174670', '#923439', '#54762A', '#E57E00', '#6D8E84', '#C30030', '#938BD4', '#CAC37A',
        '#A15227', '#7A92A9', '#2A6D66', '#9D8942', '#C0A19B', '#FFD400', '#D9E6EB', '#2897FF',
    ];
    private CLUSTER_PALETTE = [
        '#6A50A7', '#E6BC2F', '#F39C12', '#2ECC67', '#23A679',
        '#46C5F1', '#3C9CC0', '#5D8AEC', '#FC6351', '#E33737',
    ];
    public donutChartData: any = [];
    public selectedIndex: number = 0;

    constructor(
        public SchoolReportService: SchoolReportService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change.graphData && change.graphData.currentValue) {
            this.formatPlotlyGraphData();
        }
    }

    public formatPlotlyGraphData() {
        this.selectedIndex = 0;
        this.donutChartData = [];
        this.SchoolReportService.graphLayout = {
            autosize: true,
            showlegend: true,
            height: window.innerHeight - 300,
            // title: {
            //     text: this.SchoolReportService.getChartTitle(this.graphParams),
            //     font: { family: 'Lato, Arial', size: 16, color: 'rgba(0,0,0,0.65)' }
            // },
            annotations: []
        };

        const isx2Active = this.graphParams.x2 > 0;

        if (isx2Active) {
            this.SchoolReportService.graphLayout['grid'] = {
                rows: 1,
                columns: this.graphData[0].schools_num,
            };
        }

        this.graphData.forEach((schoolYear, syIdx) => {
            const schools = Object.keys(schoolYear.schools);
            const startDate = new Date(schoolYear.start + 'T00:00').toLocaleDateString('en-US', { month: 'short', year: '2-digit' });
            const endDate = new Date(schoolYear.end + 'T00:00').toLocaleDateString('en-US', { month: 'short', year: '2-digit' });
            // If time is yearly show start date as well
            const timePeriod = this.graphParams.x_axis === 3 ? `${startDate} - ${endDate}` : endDate;

            let timeAxisGroup = [];
            let timeClusterColors = [];

            schools.forEach((schoolName, snIdx) => {
                const schoolDetails = schoolYear.schools[schoolName];
                const schoolProps = Object.keys(schoolDetails).filter(this.notDisplayedProps);

                if (this.graphParams.x2 === 4) {
                    schoolProps.sort((a, b) => schoolDetails[a].region_id - schoolDetails[b].region_id);
                }

                const pieChartData: any = {
                    type: 'pie',
                    name: schoolName,
                    hole: 0.6,
                    values: [],
                    labels: [],
                    marker: {
                        line: {
                            color: '#ffffff',
                            width: '2'
                        }
                    }
                };

                if (isx2Active) {
                    pieChartData['domain'] = { column: snIdx };

                    const existingAnnotation = this.SchoolReportService.graphLayout.annotations.find(label => label.text === schoolName);

                    if (!existingAnnotation) {
                        let newAnnotation = {
                            font: { family: 'Lato, Arial', size: 16, color: 'rgba(0,0,0,0.75)' },
                            text: schoolName,
                            showarrow: false,
                            // 1 - 0.15 from legend = 0.85 space remaining
                            x: (1 / (schoolYear.schools_num + 1)) * (snIdx + 1),
                            y: 0.5,
                            xref: 'paper',
                        };
                        newAnnotation['xanchor'] = newAnnotation.x === 0.5 ? 'center' : (newAnnotation.x < 0.5 ? 'right' : 'left');

                        this.SchoolReportService.graphLayout.annotations.push(newAnnotation);
                    }

                    schoolProps.forEach((x2Label, x2Idx) => {
                        const x2Details = schoolDetails[x2Label];

                        pieChartData.values.push(x2Details.total);
                        pieChartData.labels.push(this.limitText(x2Label));
                        pieChartData['marker']['colors'] = this.COLOR_PALETTE;

                        // If x2 is cluster color code the bars
                        if (this.graphParams.x2 === 4) {
                            timeClusterColors.push(this.CLUSTER_PALETTE[x2Details.region_id] || '#6A50A7');
                            pieChartData['marker']['colors'] = timeClusterColors;
                            // Do not sort from largest to smallest %, but keep order to match cluster colors
                            pieChartData['sort'] = false;
                        }
                    });
                } else {
                    pieChartData.values.push(schoolDetails.total);
                    pieChartData.labels.push(schoolName);
                }


                // Check if pie chart is empty (no values, or all values are 0)
                pieChartData.isChartEmpty = pieChartData.values.every(val => !val);
                this.SchoolReportService.isGraphReset = !this.SchoolReportService.isGraphReset && pieChartData.isChartEmpty;

                timeAxisGroup.push(pieChartData);
            });

            const isTabEmpty = timeAxisGroup.every(g => g.isChartEmpty);

            this.SchoolReportService.getChartTitle(this.graphParams);

            this.donutChartData.push({ timePeriod, isTabEmpty, chartData: timeAxisGroup });
        });
    }

    private getChartTitle() {
        let title = '';

        title += 'Percentage of tests taken';// displayed by ';
        // @TODO get filter text and add to string
        // title += JSON.parse(this.graphParams.x2);

        return title;
    }

    private notDisplayedProps(prop: string) {
        return prop !== 'total' && prop !== 'region_id' && prop !== 'undefined' && prop !== 'gr none';
    }

    private limitText(text: string, cap: number = 35) {
        return text.length > cap ? (text.substr(0, cap) + '...') : text;
    }
}
