import { Component, OnInit } from '@angular/core';

import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HttpService } from '../../core/service/http.service';
import { AnalyticsService } from '../../core/service/analytics.service';
import { UtilsService } from '../../core/service/utils.service';
import { SessionService } from '../../core/service/session.service';
import { SequencerService } from './sequencer.service';
import { SequencerStateService } from './sequencer-state.service';
import { CourseManagementService } from '../course-management/course-management.service';
import { MapService } from '@/map/map.service';

import { CalendarModalComponent } from './calendar/calendar-modal.component';

import './ssp-page.component.scss';


@Component({
    selector: 'ssp-page',
    templateUrl: 'ssp-page.component.html',
    // styleUrls: ['./ssp-page.component.scss'],
})
export class SSPPageComponent implements OnInit {

    public resource: any;
    public isDemoBannerCollapsed = false;

    constructor(
        private $state: StateService,
        private ngbmodal: NgbModal,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
        private UtilsService: UtilsService,
        public SessionService: SessionService,
        private SequencerService: SequencerService,
        public SequencerStateService: SequencerStateService,
        public CourseManagementService: CourseManagementService,
        private MapService: MapService,
    ) {
        // Store course/section type and id values
        this.CourseManagementService.data.resourceType = this.$state.current.data.resourceType;
        this.CourseManagementService.data.resourceId = Number(this.$state.params.id);
    }


    public ngOnInit() {
        this.UtilsService.addLoadingOverlay();

        let promises = [
            this.CourseManagementService.fetchResourceInfo(),
            this.CourseManagementService.fetchSSP(),
            this.MapService.getLearningMap(),
        ];

        Promise.all(promises)
            .then(responses => {
                // Course or section info
                this.resource = responses[0];
                this.supplementCourseOrSectionProperties(this.resource);
                // Reset any previous SSP data if existing
                this.SequencerStateService.resetSequencer();
                // If this is a new SSP, automatically load on edit mode
                if (responses[1].cluster_ssp.length === 0) {
                    this.SequencerService.updateEditMode(true);
                }
                // Let sequencer directive know that map data is ready by updating observable
                this.SequencerStateService.updateSequencerData({
                    courseOrSection: responses[0],
                    ssp: responses[1],
                    regions: responses[2].regions,
                });
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private supplementCourseOrSectionProperties(courseOrSection) {
        let status = (courseOrSection.status || courseOrSection.course_status);
        courseOrSection.permissions = this.UtilsService.calculateSectionPermissions(status);
        // Store course or section info in service for future use
        this.CourseManagementService.data[this.CourseManagementService.data.resourceType] = courseOrSection;
    }

    public editScopeAndSequence() {
        this.SequencerService.updateEditMode(true);
    }

    public saveScopeAndSequence() {
        if (this.$state.current.name === 'sequencer.demo') {
            let demoSSP = JSON.stringify(this.SequencerStateService.getRegionClusterJson());
            localStorage.setItem('sequencer_demo', demoSSP);
        } else {
            this.UtilsService.addLoadingOverlay();
            this.CourseManagementService.saveSSP(this.SequencerStateService.getRegionClusterJson('compact'))
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }

        this.SequencerService.emptySnapshots();
        this.SequencerService.updateEditMode(false);
    }

    public cancelEditingScopeAndSequence() {
        this.SequencerService.revertAllSnapshots();
        this.SequencerService.updateEditMode(false);
    }

    public viewCalendar() {
        this.openCalendarModal()
            .catch(() => {
                for (let i = this.SequencerStateService.data.model.notes.length - 1; i >= 0; i--) {
                    if (this.SequencerStateService.data.model.notes[i].deleting) {
                        this.SequencerStateService.data.model.notes.splice(i, 1);
                    }
                }
            });
    }

    public openCalendarModal() {
        const modalRef = this.ngbmodal.open(CalendarModalComponent, {
            windowClass: 'calendar-modal',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        });

        return modalRef.result;
    }
}
