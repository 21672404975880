import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { apiServer } from '@/app.constant';
import { HttpService, BackendResponse } from '@/core/service/http.service';
import { AnalyticsService } from '@/core/service/analytics.service';

import { StudentFeedbackModalComponent } from './student-feedback/student-feedback-modal.component';

@Injectable()
export class HeatmapService {

    public filters = {
        revised: false,
        untested: false,
        initials: false,
        testforms: {},
        highlightedStudents: [],
        highlightedQuestion: null,
        activeConstructPanel: null,
    };
    private filtersMaster = JSON.stringify(this.filters);
    public testedConstructs = null;
    private revisedFilterChange = new Subject<boolean>();
    public revisedFilter$: Observable<boolean> = this.revisedFilterChange.asObservable();

    constructor(
        private ngbmodal: NgbModal,
        private HttpService: HttpService,
        private AnalyticsService: AnalyticsService,
    ) { }

    public resetHeatmapFilters() {
        this.filters = JSON.parse(this.filtersMaster);
        this.testedConstructs = null;
    }

    public revisedFilterChanged() {
        this.revisedFilterChange.next(this.filters.revised);
    }

    public getClusterHeatmap(assp_id: number, retest_id=0) {
        let url = `${apiServer.urlPrefix}/report/heatmap/cluster/`;
        let serverData = {
            assp_id,
            retest_id,
        };

        return this.HttpService.post(url, serverData)
            .then((response: BackendResponse) => {
                this.AnalyticsService.action({
                    action: 'fetch_cluster_heatmap',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_cluster_heatmap_fail',
                    url,
                    error,
                    data: serverData
                });

                return Promise.reject(error);
            });
    }

    public getStudentTriesHeatmap(section_id: number, cluster_id: number, gradeband: number) {
        let url = `${apiServer.urlPrefix}/report/heatmap/tries/`;
        let serverData = {
            section_id,
            cluster_id,
            gradeband,
        };

        return this.HttpService.post(url, serverData)
            .then((response: BackendResponse) => {
                this.AnalyticsService.action({
                    action: 'fetch_student_tries_heatmap',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_student_tries_heatmap_fail',
                    url,
                    error,
                    data: serverData
                });

                return Promise.reject(error);
            });
    }

    public getCourseQuestion(questionId, courseId, sectionId) {
        let url = `${apiServer.urlPrefix}/course/question/`;
        let serverData = {
            question_id: questionId,
            course_id: courseId,
            section_id: sectionId
        };

        return this.HttpService.post(url, serverData)
            .then((response: BackendResponse) => {
                this.AnalyticsService.action({
                    action: 'heatmap_fetch_question_preview',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'heatmap_fetch_question_preview_fail',
                    url,
                    error,
                    data: serverData
                });

                return Promise.reject(error);
            });
    }

    public getItemAnalysis(assp_id, q_id, retest_id) {
        let url = `${apiServer.urlPrefix}/report/item_analysis/`;
        let serverPostData = {
            assp_id,
            q_id,
            retest_id
        };

        return this.HttpService.post(url, serverPostData)
            .then((response: BackendResponse) => {
                this.AnalyticsService.action({
                    action: 'fetch_item_analysis',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_item_analysis_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public getQuestionMisconception(assp_id, q_id, retest_id) {
        let url = `${apiServer.urlPrefix}/report/misconception/`;
        let serverPostData = {
            assp_id,
            q_id,
            retest_id
        };

        return this.HttpService.post(url, serverPostData)
            .then((response: BackendResponse) => {
                this.AnalyticsService.action({
                    action: 'fetch_question_misconception',
                    url,
                });

                return Promise.resolve(response.result);
            })
            .catch(error => {
                this.AnalyticsService.warning({
                    action: 'fetch_question_misconception_fail',
                    url,
                    error,
                });

                return Promise.reject(error);
            });
    }

    public openStudentFeedbackModal(feedback) {
        this.AnalyticsService.action({ action: 'open_student_feedback_modal', feedback: feedback });

        const modalRef = this.ngbmodal.open(StudentFeedbackModalComponent, {
            windowClass: 'student-feedback-modal',
            size: 'lg',
        });
        modalRef.componentInstance.feedback = feedback;

        return modalRef.result;
    }
}
