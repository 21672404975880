import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { StateService } from '@uirouter/core';

import { dateFormat } from '@/app.constant';
import { GrowlerService } from '../../core/service/growler.service';
import { SessionService } from '../../core/service/session.service';
import { UtilsService } from '../../core/service/utils.service';
import { AssessmentService } from '../../assessment/assessment.service';
import { AssessmentStateService } from '../../assessment/common/assessment-state.service';
import { ReportService } from '../report.service';

import './report-test.component.scss';


@Component({
    selector: 'report-test',
    templateUrl: 'report-test.component.html',
    // styleUrls: ['./report-test.component.scss'],
})
export class ReportTestComponent implements OnInit {

    public constScore: any;
    public resultsByConstruct: any;
    public highlightedConstruct: any;
    public questionBreakdown: any;
    public isTeacherViewing: boolean;
    public readonly dateFormat = dateFormat;

    constructor(
        private DatePipe: DatePipe,
        private $state: StateService,
        public UtilsService: UtilsService,
        private AssessmentService: AssessmentService,
        private AssessmentStateService: AssessmentStateService,
        public ReportService: ReportService,
    ) {}

    public ngOnInit() {
        this.isTeacherViewing = !!this.$state.params.u;

        let request1 = this.ReportService.getResultsByConstruct(this.$state.params.trUid)
            .then(result => {
                this.resultsByConstruct = result;
                // If there's a construct with practice questions highlight it
                this.highlightedConstruct = result.constructs.find(co => {
                    return co.stacks.some(stack => stack.test_questions && stack.test_questions.length > 0);
                });
            })
            .catch(console.warn);

        let request2 = this.ReportService.getQuestionBreakdown(this.$state.params.trUid)
            .then(result => {
                this.questionBreakdown = result;
                this.AssessmentStateService.data.isPracticeTest = result.test_info.status === 5;
                this._computeConstructScore();
            })
            .catch(console.warn);

        // Once all requests are done open stacks panel in first available construct that the user has taken the test
        this.UtilsService.addLoadingOverlay();
        Promise.all([request1, request2])
            .then(this._openStacksPanel.bind(this))
            .catch(() => {})
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    // public displayTestInfo(test) {
    //     if (test) {
    //         return this.DatePipe.transform(test.date, dateFormat.shorterDate, dateFormat.timezone) +
    //             (test.status === 3 ? ` Gr. ${this.UtilsService.gradeBinToText(test.grade)} ${this.UtilsService.testformTypeToText(test.type)}` : ' Practice Test');
    //     }
    //     return '';
    // }

    public viewQuestion(qIndex) {
        // Set variables that will be cleared when modal is closed
        this.AssessmentStateService.data.construct = this.highlightedConstruct;
        this.AssessmentStateService.data.isPracticeTest = this.questionBreakdown.test_info.status === 5;

        this.AssessmentService.openReviseRevealModal(qIndex, this.questionBreakdown)
            .then(refreshScores => {
                if (!refreshScores) {
                    return null;
                }
                // Re-calculate construct scores on score matrix
                this.ReportService.getQuestionBreakdown(this.$state.params.trUid)
                    .then(result => {
                        this.questionBreakdown = result;
                        this._computeConstructScore();
                    })
                    .catch(console.warn);

                // If a stack panel was open, store which one it was
                let tempIdx = this.resultsByConstruct.constructs.findIndex(c => c.id === this.resultsByConstruct.selectedConstruct.id);
                // Request this again so we can update the stacks panel with the right color coding
                this.ReportService.getResultsByConstruct(this.$state.params.trUid)
                    .then(result => {
                        this.resultsByConstruct = result;
                        if (tempIdx >= 0) {
                            this.resultsByConstruct.selectedConstruct = this.resultsByConstruct.constructs[tempIdx];
                        }
                    })
                    .catch(console.warn);
            })
            .catch(() => {})
            .finally(() => this.AssessmentStateService.resetAssessment() );
    }

    private _openStacksPanel() {
        for (let i = 0; i < this.resultsByConstruct.constructs.length; i++) {
            // If there's any activity in this construct (there are test results) then open the stacks panel
            if (this.constScore[this.resultsByConstruct.constructs[i].id]) {
                this.resultsByConstruct.selectedConstruct = this.resultsByConstruct.constructs[i];

                return true;
            }
        }
        return false;
    }

    private _computeConstructScore() {
        this.constScore = this.ReportService.computeConstructScores(this.questionBreakdown.question_scores);
    }
}

