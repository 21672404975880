import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { dateFormat } from '@/app.constant';
import { UtilsService } from '@/core/service/utils.service';


@Component({
    selector: 'student-tries-activity-modal',
    templateUrl: './student-tries-activity-modal.component.html'
})
export class StudentTriesActivityModalComponent implements OnInit {

    @Input() students: any;
    @Input() tryData: any;
    @Input() cluster: any;
    public sortExpression: string;
    public sortReverse = false;

    constructor(
        private DatePipe: DatePipe,
        public activeModal: NgbActiveModal,
        public UtilsService: UtilsService,
    ) {}

    public ngOnInit() {
        if (this.students && this.students.length > 0) {
            this.students.forEach(stu => {
                this.getSubmittedMsg(stu);
            });
        }
    }

    public sortBy(prop) {
        this.sortReverse = this.sortExpression === prop ? !this.sortReverse : false;
        this.sortExpression = prop;
        this.students = this.UtilsService.sortByProp(this.students, prop, this.sortReverse);
    }

    public getSubmittedMsg(student) {
        if (student.tests && student.tests.length) {
            student.submittedMsg = student.tests.map(test => {
                return this.DatePipe.transform(test.test_date, dateFormat.shortDate, dateFormat.timezone);
            }).join(', ');
        } else {
            student.submittedMsg = '&mdash;';
        }

        return student.submittedMsg;
    }
}
