import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';


export const matchingPasswordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const p1 = control.get('password');
    const p2 = control.get('passwordConfirm');

    if (p2.errors && !p2.errors.mismatchedPassword) {
        // return if another validator has already found an error
        return;
    }

    return p1 && p2 && p1.value === p2.value ? null : { 'mismatchedPassword': true };
};
