import { Component, Input, Output, EventEmitter } from '@angular/core';

import { apiServer } from '@/app.constant';
import { AssessmentStateService } from '../assessment-state.service';
import { CalculatorService } from '../calculator/calculator.service';

import './question.component.scss';


@Component({
    selector: 'question',
    templateUrl: './question.component.html',
    // styleUrls: ['./question.component.scss'],
    host: {
        class: 'question-template',
    }
})
export class QuestionComponent {

    @Output() revealAnswer = new EventEmitter<any>();
    public readonly apiServer = apiServer;

    constructor(
        public AssessmentStateService: AssessmentStateService,
        public CalculatorService: CalculatorService,
    ) {}
}
