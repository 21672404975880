<form [formGroup]="form" class="flex-column">
    <div class="school-report-filter-content space-children-column">

        <div class="filter-container">
            <label class="body-small text-muted-alt">Report type</label>
            <mat-button-toggle-group formControlName="graph_type" [vertical]="true" (change)="updateGraphType($event)">
                <mat-button-toggle *ngFor="let option of SchoolReportService.GRAPH_TYPE_OPTIONS" [value]="option.id" [aria-label]="option.name + ' Report'" class="body-regular">
                    {{ option.name }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="filter-container position-relative">
            <label class="body-small text-muted-alt">District</label>

            <div *ngIf="districts?.length > 1; then selectableDistrict else fixedDistrict"></div>

            <ng-template #selectableDistrict>
                <ng-select
                    #districtSelect
                    class="custom school-report-dropdown"
                    dropdownPosition="auto"
                    [items]="districts"
                    [multiple]="true"
                    appendTo="body"
                    bindValue="id"
                    bindLabel="name"
                    [searchable]="false"
                    [clearable]="false"
                    [clearOnBackspace]="false"
                    [closeOnSelect]="false"
                    [loading]="isLoadingDistricts"
                    formControlName="districts"
                    (change)="districtsChanged($event)"
                    name="districts"
                    placeholder="District*">
                    <ng-template ng-header-tmp>
                        <mat-form-field appearance="standard" class="simple-form-field">
                            <mat-label>Search</mat-label>
                            <input matInput type="text" (input)="districtSelect.filter($event.target.value)" />
                        </mat-form-field>
                    </ng-template>

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">
                                <span>{{ item.name }}</span>
                            </span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox [checked]="item$.selected" [disableRipple]="true">{{ item.name }}</mat-checkbox>
                    </ng-template>
                </ng-select>
            </ng-template>

            <ng-template #fixedDistrict>
                <div class="fixed-district">
                    {{ districts[0] ? districts[0].name : 'No district' }}
                </div>
            </ng-template>

            <div class="arrow-container">
                <div class="arrow-right"></div>
            </div>
        </div>

        <div class="filter-container ml-3">
            <label class="body-small text-muted-alt">School</label>
            <ng-select
                #schoolSelect
                class="custom school-report-dropdown"
                dropdownPosition="auto"
                [items]="SchoolReportService.schools"
                [multiple]="true"
                appendTo="body"
                bindValue="id"
                bindLabel="name"
                groupBy="district"
                [searchable]="false"
                [clearable]="false"
                [clearOnBackspace]="false"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                [closeOnSelect]="false"
                [loading]="isLoadingDistricts"
                formControlName="schools"
                (change)="schoolsChanged($event)"
                name="schools"
                placeholder="School*">
                <ng-template ng-header-tmp>
                    <mat-form-field appearance="standard" class="simple-form-field">
                        <mat-label>Search</mat-label>
                        <input matInput type="text" (input)="schoolSelect.filter($event.target.value)" />
                    </mat-form-field>
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:2">
                        <span class="ng-value-label">
                            <span>{{ item.name }}</span>
                        </span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>

                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                    </div>
                </ng-template>

                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox [checked]="item$.selected" [disableRipple]="true">All Schools ({{ item.district }})</mat-checkbox>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox [checked]="item$.selected" [disableRipple]="true">{{ item.name }}</mat-checkbox>
                </ng-template>
            </ng-select>
        </div>

        <div class="filter-container">
            <label class="body-small text-muted-alt">Academic Year</label>
            <ng-select
                #schoolYearSelect
                class="custom school-report-dropdown"
                dropdownPosition="auto"
                [items]="schoolYears"
                [multiple]="true"
                appendTo="body"
                bindValue="name"
                bindLabel="name"
                [searchable]="false"
                [clearable]="false"
                [clearOnBackspace]="false"
                [selectableGroup]="true"
                [selectableGroupAsModel]="false"
                [closeOnSelect]="false"
                [loading]="isLoadingDistricts"
                formControlName="school_years"
                (change)="schoolYearsChanged($event)"
                name="school-years"
                placeholder="Academic Year*">
                <ng-template ng-header-tmp>
                    <mat-form-field appearance="standard" class="simple-form-field">
                        <mat-label>Search</mat-label>
                        <input matInput type="text" (input)="schoolYearSelect.filter($event.target.value)" />
                    </mat-form-field>
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:2">
                        <span class="ng-value-label">
                            <span>{{ item.name }}</span>
                        </span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>

                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox [checked]="item$.selected" [disableRipple]="true">{{ item.name }}</mat-checkbox>
                </ng-template>
            </ng-select>
        </div>

        <div class="hr"></div>

        <ng-container *ngIf="form.controls.graph_type.value === 1">
            <div class="secondary-filter">
                <mat-form-field color="accent">
                    <mat-label>View data as</mat-label>
                    <mat-select formControlName="y_axis" name="y-axis-filter" [disableRipple]="true" (selectionChange)="updateTimeOptions($event)">
                        <mat-option *ngFor="let option of SchoolReportService.Y_AXIS_OPTIONS" [value]="option.id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="secondary-filter flex-column margin-bottom-1em">
                <label class="body-small text-muted-alt" id="x-axis-group-label">Display time as</label>
                <mat-radio-group
                  aria-labelledby="x-axis-group-label"
                  class="radio-group-column"
                  formControlName="x_axis">
                    <mat-radio-button class="radio-button-column" *ngFor="let option of SchoolReportService.X_AXIS_OPTIONS" [value]="option.id" [disabled]="option.isDisabled" (change)="resetGraph()">
                        {{ option.name}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="secondary-filter" *ngIf="multipleSchoolsSelected && form.get('y_axis').value !== 2">
                <mat-form-field color="accent">
                    <mat-label>Displayed by</mat-label>
                    <mat-select name="multiple-schools" value="0" [disableRipple]="true" [disabled]="true" (selectionChange)="resetGraph()">
                        <mat-select-trigger class="flex-none align-items-center space-children-row small-space">
                            <i class="icon icon-lock"></i><span>Multiple Schools</span>
                        </mat-select-trigger>
                        <mat-option value="0">
                            <i class="icon icon-lock"></i><span>Multiple Schools</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="secondary-filter">
                <mat-form-field color="accent">
                    <mat-label>{{ multipleSchoolsSelected && form.get('y_axis').value !== 2 ? 'Color-coded by' : 'Displayed by' }}</mat-label>
                    <mat-select formControlName="x2" name="x2-filter" [disableRipple]="true" (selectionChange)="updateX3Value($event)">
                        <mat-option *ngFor="let option of X2_AXIS_OPTIONS" [value]="option.id" [disabled]="option.isDisabled">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('x2').hasError('required') || !form.get('x2')">Please make a selection</mat-error>
                </mat-form-field>
            </div>

            <div class="secondary-filter" *ngIf="!multipleSchoolsSelected && form.get('y_axis').value === 1">
                <mat-form-field color="accent">
                    <mat-label>Color-coded by</mat-label>
                    <mat-select formControlName="x3" name="x3-filter" [disableRipple]="true"  (selectionChange)="resetGraph()">
                        <mat-option *ngFor="let option of x3Options" [value]="option.id">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('x3').hasError('required')">Please make a selection</mat-error>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="form.controls.graph_type.value === 2">
            <label class="body-small text-muted-alt">See result by</label>
            <mat-button-toggle-group formControlName="g_type">
                <mat-button-toggle *ngFor="let option of SchoolReportService.GRAPH_TYPE_VARIANT" class="flex" (change)="resetGraph()" [value]="option.id" [aria-label]="option.name" [matTooltip]="option.desc">
                    {{ option.name }}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <ng-select
                #clusterSelect
                class="custom school-report-dropdown cluster-dropdown w-auto"
                [items]="clusters"
                dropdownPosition="auto"
                [multiple]="false"
                appendTo="body"
                bindValue="id"
                bindLabel="name"
                [clearable]="true"
                [closeOnSelect]="true"
                groupBy="regionName"
                [loading]="isLoadingClusters"
                [searchable]="false"
                formControlName="cluster"
                (change)="updateConstructs($event)"
                name="cluster"
                placeholder="Cluster*">
                <ng-template ng-header-tmp>
                    <mat-form-field appearance="standard" class="simple-form-field">
                        <mat-label>Search</mat-label>
                        <input matInput type="text" (input)="clusterSelect.filter($event.target.value)" />
                    </mat-form-field>
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>
            </ng-select>

            <ng-select
                #constructSelect
                class="custom school-report-dropdown cluster-dropdown w-auto"
                [items]="constructs"
                dropdownPosition="auto"
                [multiple]="false"
                appendTo="body"
                bindValue="id"
                bindLabel="name"
                [clearable]="true"
                [closeOnSelect]="true"
                [searchable]="false"
                formControlName="construct"
                (change)="resetGraph()"
                name="construct"
                placeholder="Construct">
                <ng-template ng-header-tmp>
                    <mat-form-field appearance="standard" class="simple-form-field">
                        <mat-label>Search</mat-label>
                        <input matInput type="text" (input)="constructSelect.filter($event.target.value)" />
                    </mat-form-field>
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span class="region-legend-circle" [ngClass]="['region-background-' + item.region]"></span>
                    <span [innerHTML]="item.name"></span>
                </ng-template>
            </ng-select>

            <div class="hr"></div>

            <mat-button-toggle-group formControlName="graph_option" (change)="updateGraphTypeSecondary($event)">
                <mat-button-toggle *ngFor="let option of SchoolReportService.GRAPH_TYPE_OPTIONS_SECONDARY" class="flex" [value]="option.id" [aria-label]="option.name">
                    {{ option.name }}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <ng-container *ngIf="form.get('graph_option').value === 1">
                <ng-select
                    #gradeSelect
                    class="custom school-report-dropdown ng-select ng-invalid ng-touched"
                    dropdownPosition="auto"
                    [items]="GRADE_OPTIONS"
                    [multiple]="true"
                    [maxSelectedItems]="maxPerformanceSelections"
                    appendTo="body"
                    bindValue="id"
                    bindLabel="name"
                    [searchable]="false"
                    [clearable]="false"
                    [clearOnBackspace]="false"
                    [closeOnSelect]="true"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    (change)="resetGraph()"
                    formControlName="grades"
                    name="grades"
                    placeholder="Grades*">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">
                                <span>{{ item.name }}</span>
                            </span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox [checked]="item$.selected" [disableRipple]="true">{{ item.name }}</mat-checkbox>
                    </ng-template>
                </ng-select>

                <div class="text-warning body-regular mt-1" *ngIf="form.get('grades').value?.length === maxPerformanceSelections">
                    * Max grade selection reached
                </div>
            </ng-container>

            <ng-container *ngIf="form.get('graph_option').value === 2">
                <ng-select
                    #courseSelect
                    class="custom school-report-dropdown"
                    dropdownPosition="auto"
                    [items]="SchoolReportService.courses"
                    [multiple]="true"
                    [maxSelectedItems]="maxPerformanceSelections"
                    appendTo="body"
                    bindValue="code"
                    bindLabel="code"
                    [searchable]="false"
                    [clearable]="false"
                    [clearOnBackspace]="false"
                    [closeOnSelect]="true"
                    [selectableGroup]="true"
                    [selectableGroupAsModel]="false"
                    (change)="resetGraph()"
                    formControlName="courses"
                    name="courses"
                    placeholder="Courses*">
                    <ng-template ng-header-tmp>
                        <mat-form-field appearance="standard" class="simple-form-field">
                            <mat-label>Search</mat-label>
                            <input matInput type="text" (input)="courseSelect.filter($event.target.value)" />
                        </mat-form-field>
                    </ng-template>

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2">
                            <span class="ng-value-label">
                                <span>{{ item.code || item.name }}</span>
                            </span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                        </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox [checked]="item$.selected" [disableRipple]="true">{{ item.code || item.name }}</mat-checkbox>
                    </ng-template>
                </ng-select>

                <div class="text-warning body-regular mt-1" *ngIf="form.get('courses').value?.length === maxPerformanceSelections">
                    * Max course selection reached
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="flex"></div>

    <div class="school-report-filter-footer flex-none align-items-center justify-center margin-top-1em">
        <button class="btn btn-block primary-button" [disabled]="!form.valid || !form.dirty" (click)="fetchGraphData()">Apply Changes</button>
    </div>
</form>
