import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from './shared/shared.module';
import { SequencerAbstractComponent } from './sequencer/sequencer-abstract.component';
import { APP_STATES } from './app-states';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UIRouterModule.forChild({
            states: APP_STATES,
        }),
    ],
    declarations: [
        SequencerAbstractComponent,
    ],
    exports: [
        UIRouterModule,
    ],
    providers: [
    ],
})
export class AppRoutingModule {}
