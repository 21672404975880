<div class="modal-header flex-row align-items-center justify-space-between space-children-row">
    <div class="modal-title">Test: {{ AssessmentStateService.data.name }}</div>
    <div class="modal-title" *ngIf="AssessmentStateService.data.type !== 'preview'">Take {{ AssessmentStateService.data.tests + 1 }}</div>
</div>

<div class="modal-body text-center">
    <p>Welcome to the test for <i>{{ AssessmentStateService.data.name }}</i>.</p>
    <p class="instructions-list" [mathHTML]="AssessmentStateService.data.instructions"></p>
    <p *ngIf="AssessmentStateService.data.saved_test > 0">You can now <span class="bold">resume</span> your unfinished test that was saved.</p>

    <feedback-form [questions]="AssessmentStateService.data.feedback" (completed)="AssessmentStateService.data.surveyCompleted = $event"></feedback-form>
</div>

<div class="modal-footer flex-row justify-center align-items-center space-children-row flex-wrap">
    <button class="btn ghost-button" (click)="activeModal.dismiss()">Back</button>

    <button class="btn primary-button" ngbAutofocus (click)="activeModal.close(-1)" *ngIf="AssessmentStateService.data.type === 'preview'">Preview Test</button>

    <button class="btn primary-button" ngbAutofocus (click)="beginTest()" *ngIf="AssessmentStateService.data.type !== 'preview'" [disabled]="!AssessmentStateService.data.surveyCompleted">{{ AssessmentStateService.data.saved_test === 0 ? 'Start' : 'Resume Unfinished' }} Test</button>
</div>
