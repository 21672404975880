import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { dateFormat } from '@/app.constant';
import { matchingPasswordValidator } from '../shared/directives';
import { GrowlerService } from '../core/service/growler.service';
import { UtilsService } from '../core/service/utils.service';
import { AssessmentManagementService } from '../assessment-management/assessment-management.service';
import { ClassRosterService } from './class-roster.service';

import './class-roster.component.scss';


@Component({
    selector: 'class-roster-page',
    templateUrl: 'class-roster.component.html',
    // styleUrls: ['./class-roster.component.scss'],
})
export class ClassRosterComponent implements OnInit {

    @ViewChildren('pwdChangeTrigger') private pwdChangeTrigger: QueryList<NgbPopover>;
    public schoolYears: any;
    public readonly dateFormat = dateFormat;
    public form = new FormGroup({
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(75),
            ]),
        passwordConfirm: new FormControl('', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(75),
            ]),
    }, { validators: matchingPasswordValidator });
    get password() { return this.form.get('password'); }
    get passwordConfirm() { return this.form.get('passwordConfirm'); }

    constructor(
        private GrowlerService: GrowlerService,
        private UtilsService: UtilsService,
        private AssessmentManagementService: AssessmentManagementService,
        private ClassRosterService: ClassRosterService,
    ) { }


    public ngOnInit() {
        this.UtilsService.addLoadingOverlay();

        this.AssessmentManagementService.getTeacherSchoolYear('current')
            .then(schoolYears => {
                this.schoolYears = schoolYears;
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    public getSectionRoster(section) {
        // Fetch section roster from backend if it's the first time opening the accordion
        if (!section.roster) {
            this.UtilsService.addLoadingOverlay();

            this.ClassRosterService.getSectionRoster(section.id)
                .then((roster: any) => {
                    section.roster = roster.sort((a, b) => a.name.localeCompare(b.name));
                })
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        }
    }

    public closeRoster(section) {
        if (section.roster) {
            section.roster.forEach(student => {
                // Reset every button where password has been changed
                student.passwordChanged = false;
            });
        }
    }

    public closePopover(student) {
        this.form.reset();
        this.pwdChangeTrigger.forEach(popover => popover.close());
    }

    public submitPasswordChange(sectionId, student) {
        let newPassword = this.password.value;
        this.UtilsService.addLoadingOverlay(true);

        this.ClassRosterService.updateStudentPassword(sectionId, student.user_id, newPassword)
            .then((result: any) => {
                student.pwd_updated = result.password_updated;
                student.passwordChanged = true;
                this.closePopover(student);
                this.GrowlerService.success('New password has been saved.', 'Student Password');
            })
            .catch((error) => {
                this.form.reset();
                student.passwordResetFailMessage = error.result.msg;
            })
            .finally(() => {
                this.UtilsService.removeLoadingOverlay();
            });
    }
}
