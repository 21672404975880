import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import './misconception-tabs.component.scss';


@Component({
    selector: 'misconception-tabs',
    templateUrl: './misconception-tabs.component.html',
    // styleUrls: ['./misconception-tabs.component.scss'],
    host: {
        class: 'misconception-tabs',
    }
})
export class MisconceptionTabsComponent implements OnInit {

    @Input() misconceptions: any;
    @Input() selectedMisconception: any;

    constructor(
    ) {}

    public ngOnInit() {
        if (this.misconceptions && !this.selectedMisconception) {
            this.selectedMisconception = this.misconceptions[0];
        }
    }

    public getMisconceptionIndex(selectedMisconception) {
        if (!selectedMisconception) {
            return -1;
        }
        return this.misconceptions.findIndex(misc => misc.id === selectedMisconception.id);
    }

    public selectMisconceptionTab(misconception) {
        // Ignore clicking on the same misconception that's already selected
        if (this.selectedMisconception.id === misconception.id) {
            return false;
        }

        // Reset misconception flipped status with a delay if flipped
        if (this.selectedMisconception && this.selectedMisconception.flipped) {
            this.selectedMisconception.flipped = false;
            // Wait 300ms (card is half-way flipped) to update with the new misconception text
            setTimeout(() => {
                this.selectedMisconception = misconception;
            }, 300);
        } else {
            this.selectedMisconception = misconception;
        }
    }
}
