import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

import { UserAccess, UserProfile } from '@/_models';


export function tokenGetter() {
    return localStorage.getItem('access_token');
}

@Injectable()
export class SessionService {

    public userAccess: UserAccess;
    public userProfile: UserProfile;
    public lastInteraction: number = new Date().getTime();
    public unloadingPage: boolean = false;
    public mapInterstitial: string;

    private _userAccessSource = new BehaviorSubject<UserAccess|null>(null);
    public userAccess$ = this._userAccessSource.asObservable();

    constructor(public jwtHelper: JwtHelperService) { }

    private updateUserAccess(ua) {
        this._userAccessSource.next(ua);
    }

    public setMapInterstitial() {
        localStorage.setItem('map_interstitial', '1');
    }

    public getMapInterstitial() {
        this.mapInterstitial = localStorage.getItem('map_interstitial');

        return (this.mapInterstitial !== null && this.mapInterstitial == '1') ? true : false;
    }

    public setUserProfile(up) {
        this.userProfile = up;

        localStorage.setItem('user_profile', JSON.stringify(up));
    }

    public getUserProfile() {
        this.userProfile = JSON.parse(localStorage.getItem('user_profile'));

        return this.userProfile;
    }

    public create({ access = '', refresh = '' } = {}): UserAccess {
        let utc = Math.floor(new Date().getTime() / 1000);
        let decodedToken = this.jwtHelper.decodeToken(access);

        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        localStorage.setItem('token_created', String(utc));
        localStorage.setItem('token_updated', String(utc));
        localStorage.setItem('map_animated', 'false');

        this.userAccess = decodedToken;
        this.updateUserAccess(decodedToken);

        return this.userAccess;
    }

    public update({ access = '', refresh = '' } = {}): UserAccess {
        let utc = Math.floor(new Date().getTime() / 1000);
        let decodedToken = this.jwtHelper.decodeToken(access);

        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        localStorage.setItem('token_updated', String(utc));

        this.userAccess = decodedToken;
        this.updateUserAccess(decodedToken);

        return this.userAccess;
    }

    public getUser(): UserAccess|null {
        let token = tokenGetter();

        if (!token) {
            this.userAccess = null;
            this.updateUserAccess(null);
            return null;
        }

        let decodedToken = this.jwtHelper.decodeToken(token);

        this.userAccess = decodedToken;
        this.updateUserAccess(decodedToken);

        return this.userAccess;
    }

    public destroy(): void {
        localStorage.clear();
        this.userAccess = null;
        this.updateUserAccess(null);
    }
}
