import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { apiServer } from '@/app.constant';
import { SessionService } from '../../../core/service/session.service';
import { AssessmentStateService } from '../../../assessment/common/assessment-state.service';

import './foil.component.scss';


@Component({
    selector: 'foil',
    templateUrl: './foil.component.html',
    // styleUrls: ['./foil.component.scss'],
    host: {
        class: 'foil-component',
    },
})
export class FoilComponent implements OnChanges {

    @Input() foil: any;
    @Input() foilIndex: number;
    @Input() stem: any;
    @Input() stemIndex: number;
    public readonly apiServer = apiServer;

    constructor(
        public SessionService: SessionService,
        public AssessmentStateService: AssessmentStateService,
    ) {}

    public ngOnChanges(change: SimpleChanges) {
    }

    public isStemAnswered() {
        let isAnswered;
        if (this.stem.type === 1) {
            isAnswered = !!this.stem.selected || this.stem.selected === 0;
        } else if (this.stem.type === 2) {
            isAnswered = this.stem.foils.some(foil => foil.selected);
        } else if (this.stem.type === 3 || this.stem.type === 4 || this.stem.type === 6) {
            isAnswered = this.stem.foils.every(foil => !!foil.selected || foil.selected === 0);
        }

        this.AssessmentStateService.data.multi.isPartAnswered = isAnswered;
    }
}
