import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormModule } from '../_custom/mat-form.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';

// Imported for ResourcerCardComponent
import { ResourcerModule } from '../resourcer/resourcer.module';

import { CourseManagementService } from './course-management/course-management.service';
import { SequencerService } from './ssp/sequencer.service';
import { SequencerStateService } from './ssp/sequencer-state.service';
import { CalendarService } from './ssp/calendar/calendar.service';

import { CourseManagementComponent } from './course-management/course-management.component';
import { NewCourseComponent } from './new-course/new-course.component';
import { SSPPageComponent } from './ssp/ssp-page.component';
import { SequencerComponent } from './ssp/svg-component/sequencer-svg.component';
import { CalendarModalComponent } from './ssp/calendar/calendar-modal.component';
import { CalendarNavigationComponent } from './ssp/calendar/navigation/calendar-navigation.component';
import { CalendarDaysComponent } from './ssp/calendar/days/calendar-days.component';
import { CalendarDayItemsComponent } from './ssp/calendar/day-items/calendar-day-items.component';
import { CalendarDayFrontRowComponent } from './ssp/calendar/day-front-row/calendar-day-front-row.component';

import { EditResourceModalComponent } from './course-management/edit-resource/edit-resource-modal.component';
import { CoursePermissionsModalComponent } from './course-management/course-permissions/course-permissions-modal.component';
import { NewNoteModalComponent } from './ssp/calendar/day-items/new-note/new-note-modal.component';
import { BlockedDayModalComponent } from './ssp/calendar/day-items/blocked-day/blocked-day-modal.component';
import { ResourceAssignmentModalComponent } from './ssp/calendar/resource-assignment/resource-assignment-modal.component';


@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        NgbPopoverModule,
        MatFormModule,
        MatSlideToggleModule,
        MatMenuModule,
        ResourcerModule,
    ],
    providers: [
        CourseManagementService,
        SequencerService,
        SequencerStateService,
        CalendarService,
    ],
    declarations: [
        CourseManagementComponent,
        NewCourseComponent,
        SSPPageComponent,
        SequencerComponent,
        CalendarModalComponent,
        CalendarNavigationComponent,
        CalendarDaysComponent,
        CalendarDayItemsComponent,
        CalendarDayFrontRowComponent,
        EditResourceModalComponent,
        CoursePermissionsModalComponent,
        NewNoteModalComponent,
        BlockedDayModalComponent,
        ResourceAssignmentModalComponent,
    ],
    entryComponents: [
        CalendarModalComponent,
        EditResourceModalComponent,
        CoursePermissionsModalComponent,
        NewNoteModalComponent,
        BlockedDayModalComponent,
        ResourceAssignmentModalComponent,
    ],
})
export class SequencerModule { }
