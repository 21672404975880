import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'new-test-assignment-modal',
    templateUrl: './new-test-assignment-modal.component.html'
})
export class NewTestAssignmentModalComponent {

    @Input() dropdownModels: any;
    @Input() schoolYears: any;
    @Input() cluster: any;
    @Input() courseOrSection: any;
    @Input() courseOrSectionType: 'course'|'section';
    @Input() selectedDate: any;

    constructor(
        public activeModal: NgbActiveModal,
    ) {}

    public onNewTest(newTest) {
        if (newTest) {
            this.activeModal.close(newTest);
        }
        this.activeModal.dismiss();
    }
}
