<div class="hr"></div>

<div class="flex-row align-items-center justify-space-between heatmap-roster-control">
    <h4 class="page-subheader dark-theme reset">Student Details</h4>
    <button class="reset-sorting-button btn ghost-button capitalize" (click)="resetSorting()" [disabled]="heatmapSortExpression === sortOriginal">Reset Sorting</button>
</div>

<table class="heatmap-roster table table-bordered table-hover">
    <thead>
        <tr class="table-layout">
            <th class="table-header cursor-pointer roster-header space-children-row small-space name-header" rowspan="2" (click)="sortBy('student_name')">
                <span>Name</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'opacity-25': heatmapSortExpression !== 'student_name', 'icon-sort-amount-desc': heatmapSortExpression === 'student_name' && sortReverse }"></i>
            </th>

            <th class="table-header cursor-pointer roster-header space-children-row small-space" rowspan="2" (click)="sortBy('cl_weight', true)">
                <span>Score</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'opacity-25': !heatmapSortExpression.includes('cl_weight_'), 'icon-sort-amount-desc': heatmapSortExpression.includes('cl_weight_') && sortReverse }"></i>
            </th>

            <th class="table-header roster-header space-children-row small-space" rowspan="2" matTooltip="Construct label / Success Score / Worked Problem Index" matTooltipPosition="above">
                <span>Practice Record</span>
                <i class="icon icon-info text-info"></i>
            </th>

            <th class="table-header roster-header construct-breakdown" [attr.colspan]="HeatmapService.testedConstructs.length" [style.width]="(10 * (HeatmapService.testedConstructs.length)) + '%'">Construct Breakdown</th>

            <th class="table-header cursor-pointer roster-header space-children-row small-space" rowspan="2" (click)="sortBy('testform')">
                <span>Test Form</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'opacity-25': heatmapSortExpression !== 'testform', 'icon-sort-amount-desc': heatmapSortExpression === 'testform' && sortReverse }"></i>
            </th>

            <th class="table-header roster-header space-children-row small-space" rowspan="2">
                <span>Student Reports</span>
            </th>
        </tr>
        <tr>
            <th class="table-header cursor-pointer roster-header space-children-row small-space" (click)="sortBy(('construct_' + construct.id), true)" *ngFor="let construct of HeatmapService.testedConstructs">
                <span>{{ UtilsService.getLetterFromIndex(construct.order - 1) }}</span>
                <i class="icon icon-sort-amount-asc" [ngClass]="{ 'opacity-25': !heatmapSortExpression.includes('construct_' + construct.id), 'icon-sort-amount-desc': heatmapSortExpression.includes('construct_' + construct.id) && sortReverse }"></i>
            </th>
        </tr>
    </thead>
    <tbody class="table-body">
        <ng-template ngFor let-studentScore [ngForOf]="roster" let-$index="index">
            <tr [ngClass]="{'active-student': HeatmapService.filters.highlightedStudents.indexOf(studentScore.student_id) > -1, 'open-report': studentScore.isStudentReportOpen }" class="cursor-pointer" (click)="toggleActiveStudent.emit(studentScore.student_id)">
                <td class="table-data student-name">
                    <span>{{ studentScore.student_name }}</span>
                </td>

                <td class="table-data bold" [innerHTML]="formatWeight(studentScore, 'cl_weight_')"></td>

                <td class="table-data">
                    <div *ngIf="studentScore.hasPracticed; then showScores else showDash"></div>
                    <ng-template #showScores>
                        <ng-container *ngFor="let construct of HeatmapService.testedConstructs">
                            <div *ngIf="studentScore['construct_' + construct.id + '_tests']">
                                <span>{{ UtilsService.getLetterFromIndex(construct.order - 1) }}&nbsp;/&nbsp;</span>
                                <span>{{ studentScore['construct_' + construct.id + '_ss'].toFixed() }}%&nbsp;/&nbsp;</span>
                                <span>{{ studentScore['construct_' + construct.id + '_wpi'] }}</span>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template #showDash>
                        <span>&mdash;</span>
                    </ng-template>
                </td>

                <td class="table-data" *ngFor="let construct of HeatmapService.testedConstructs" [innerHTML]="formatWeight(studentScore, 'construct_' + construct.id + '_')"></td>

                <td class="table-data bold">{{ studentScore.testform ? HeatmapService.filters.testforms[studentScore.testform]?.name : '&mdash;' }}</td>

                <td class="table-data item-results">
                    <div *ngIf="studentScore.tr_uid" class="flex-none align-items-center justify-space-around">
                        <div class="item-results-button text-info cursor-pointer" (click)="toggleStudentReport(studentScore, $event)">
                            <span>Question Scores</span>
                            <i class="icon icon-angle-down" [ngClass]="{ rotate: studentScore.isStudentReportOpen }"></i>
                        </div>

                        <a matTooltip="Open Full Report" uiSref="report.test" [uiParams]="{ trUid: studentScore.tr_uid, u: studentScore.student_id }" target="_blank" rel="noopener" class="report-external-link">
                            <i class="fas fa-external-link-alt"></i>
                        </a>
                    </div>
                    <span *ngIf="!studentScore.tr_uid">&mdash;</span>
                </td>
            </tr>
            <tr>
                <td [attr.colspan]="HeatmapService.testedConstructs.length + 5" class="student-report-container">
                    <ng-container *ngIf="studentScore.isStudentReportOpen">
                        <div class="student-report shadow-svg">
                            <question-breakdown [matrix]="questionBreakdown" [cluster]="cluster" [testScores]="constScore" (viewQuestion)="viewQuestion($event)"></question-breakdown>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </tbody>
</table>
