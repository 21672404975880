import { Injectable } from '@angular/core';

import { appInfo, apiServer } from '@/app.constant';
import { HttpService } from './http.service';
import { SessionService } from './session.service';


@Injectable()
export class AnalyticsService {

    constructor(
        private HttpService: HttpService,
        private SessionService: SessionService,
    ) { }

    public pageTrack(from, to) {
        return this.sendAnalyticsData(1, { from, to });
    }

    public assessment(data) {
        return this.eventTrack(2, data);
    }

    public action(data) {
        return this.eventTrack(3, data);
    }

    public error(data) {
        return this.eventTrack(4, data);
    }

    public warning(data) {
        return this.eventTrack(5, data);
    }

    private eventTrack(type, data) {
        let uid;

        if (this.SessionService.userAccess) {
            // Get the last part of the uid string (12byte LSB)
            uid = this.SessionService.userAccess.uid.split('-');
            uid = uid[uid.length - 1];
        }

        if (window['ga']) {
            ga(
                'send',
                'event',
                appInfo.version,
                (data.action || JSON.stringify(data)),
                uid,
            );
        }

        return this.sendAnalyticsData(type, data);
    }

    private sendAnalyticsData(type, data) {
        let url = `${apiServer.domain}/events/put/`;

        data.client = appInfo.version;
        data.location = window.location.pathname;

        let analyticsEvent = {
            type: type,
            timestamp: Math.floor(new Date().getTime() / 1000),
            data: JSON.stringify(data)
        };

        return this.HttpService.post(url, analyticsEvent).catch(() => {});
    }
}
