import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ReportService } from '../report.service';
import { UtilsService } from '../../core/service/utils.service';
import { AssessmentStateService } from '../../assessment/common/assessment-state.service';
import { AssessmentToastService } from '../../assessment/common/assessment-toast/assessment-toast.service';
import { CalculatorService } from '../../assessment/common/calculator/calculator.service';

import './revise-reveal-modal.component.scss';


@Component({
    selector: 'revise-reveal-modal',
    templateUrl: './revise-reveal-modal.component.html',
    // styleUrls: ['./revise-reveal-modal.component.scss'],
})
export class ReviseRevealModalComponent implements OnInit {

    @Input() qIndex: number;
    @Input() questions: any;
    @Input() hasEditPermission: boolean;
    public updateScoreMatrix = false;
    public questionScore: any;

    constructor(
        public activeModal: NgbActiveModal,
        private ReportService: ReportService,
        private UtilsService: UtilsService,
        public AssessmentStateService: AssessmentStateService,
        private CalculatorService: CalculatorService,
        private AssessmentToastService: AssessmentToastService,
    ) {}

    public ngOnInit() {
        this.AssessmentStateService.data.currentQuestion = this.qIndex;
        this.AssessmentStateService.data.isReportPage = true;
        this.navigateToQuestion(this.AssessmentStateService.data.currentQuestion);
    }

    public navigateToQuestion(seq) {
        if (seq < 0 || seq > this.questions.length - 1) {
            return false;
        }

        // Update current question to the question index we are navigating to
        this.AssessmentStateService.data.currentQuestion = seq;

        // Close calculator and question feedback toast when switching to a new question
        this.CalculatorService.operate('close', true);
        this.AssessmentToastService.closeToast();

        // Hide answer if revealed from last question
        this.AssessmentStateService.data.answerKey = false;

        // Extract question scores object from contruct id wrapper
        let constructId = this.questions[this.AssessmentStateService.data.currentQuestion].construct_id;
        let questionScore = this.questions[this.AssessmentStateService.data.currentQuestion][constructId];
        this.questionScore = questionScore;

        // Store tr id and uid for future access in revise/reveal endpoints
        this.AssessmentStateService.data.tr = questionScore.tr_id;
        this.AssessmentStateService.data.trUid = questionScore.tr_uid;

        // Fetch question data (stems, foils)
        this.ReportService.getReviseRevealQuestion(questionScore.q_id, questionScore.stem_id, questionScore.tr_uid)
            .then(result => this.initReportQuestion(result))
            .catch(console.warn);
    }

    private initReportQuestion(result) {
        // Set up base assessment variables
        this.AssessmentStateService.setAssessmentVariables(result.question);
        // Disable all input fields until student decides to revise or reveal if applicable
        this.AssessmentStateService.data.question.isSubmitted = true;

        // Make sure stems are ordered since we rely on them being in the correct order
        this.AssessmentStateService.data.question.stems.sort((a, b) => a.order - b.order);

        // Iterate over all stems and foils and find the current state of the questions
        this.AssessmentStateService.data.question.stems.forEach(stem => {
            stem.isRevised = stem.response_status.includes('revise');
            stem.isRevealable = !stem.response_status.includes('reveal');
            stem.isCorrect = stem.response_status.indexOf('correct') === 0;
            stem.isIncorrect = stem.response_status.indexOf('incorrect') === 0;

            stem.foils.forEach((foil, foilIndex) => {
                // Pre-select the student's last answer and grade as correct or incorrect (except for checkboxes because it would reveal the answer)
                if (stem.isRevised) {
                    this.selectAndGradeFoils(stem, foil, 'revise');
                } else {
                    // If the stem was not revised, select original responses
                    this.selectAndGradeFoils(stem, foil, 'response');
                }

                // Show the answer key if question has been revealed and make stem not revealable
                if (foil.key) {
                    this.AssessmentStateService.data.answerKey = true;
                    stem.isRevealable = false;
                }
            });

            // After going over all the foils, decide if we should continue to the next stem in multi part questions
            // Skip to next stem if current stem is revised or revealed, or it is correct and is the last revisable stem
            if (this.AssessmentStateService.data.multi.current === stem.order && (stem.isRevised || !stem.isRevealable || stem.isCorrect)) {
                this.AssessmentStateService.data.multi.current += 1;
                this.AssessmentStateService.data.multi.current = Math.min(this.AssessmentStateService.data.multi.current, this.AssessmentStateService.data.multi.total);
            }
        });

        // Generate appropriate feedback based on current stem status and display to student
        this.handleScoreResult(this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1]);
    }

    public allowQuestionRevision() {
        // Remove only the incorrect feedback so they can revise the incorrect fields
        this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1].foils.forEach(foil => {
            if (foil.isCorrect === false) {
                foil.isCorrect = undefined;
            }
        });
        this.AssessmentStateService.data.question.isSubmitted = false;
        this.AssessmentToastService.closeToast();
    }

    public submitRevisedAnswer() {
        let stemResponse = this.AssessmentStateService.collectStudentResponse();
        stemResponse.next = (<any>-1);
        let action = this.AssessmentStateService.data.multi.current < this.AssessmentStateService.data.multi.total ? 'revise_part' : 'revise_answer';

        // Refresh score matrix when the revise/reveal modal is closed
        this.updateScoreMatrix = true;
        // Disable button until action is complete
        this.UtilsService.addLoadingOverlay(true);

        return this.AssessmentStateService.submitTestQuestion(stemResponse, action)
            .then(response => this.processRevisedAnswerResponse(response))
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private processRevisedAnswerResponse(response) {
        let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];
        // Update question object from matrix with new score
        Object.assign(this.questionScore, response.grade);

        // Update question and stem statuses
        this.AssessmentStateService.data.question.isSubmitted = true;
        stem.isRevised = true;
        stem.isCorrect = response.grade.percent_revised >= 100;
        stem.isIncorrect = response.grade.percent_revised <= 0;

        // Iterate over question foils and update the grading
        stem.foils.forEach((foil, foilIndex) => {
            // Find matching foil in the response
            let responseFoil = response.result.find(resFoil => resFoil.foil === foil.id);

            // Skip foils that had no match with response (radio) - no grading
            // or that were not selected (checkboxes) - no grading
            // but include if they were not selected and were a correct answer (checkbox) - grading, but 0 pts earned
            if ( (responseFoil && responseFoil.grading) || (responseFoil && responseFoil.points) ) {
                foil.revise = responseFoil;
                this.selectAndGradeFoils(stem, foil, 'revise');
            }
        });

        // Show feedback toast
        this.handleScoreResult(stem);
        this.proceedToNextQuestionPart();
    }

    public requestAnswerReveal() {
        let stem = this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];

        if (stem.isRevised) {
            // Not necessary to warn that they won't be able to revise anymore
            return this.revealAnswer(stem);
        }

        this.AssessmentToastService.openRevealAnswerToast();
    }

    public onToastClosed(reason) {
        if (reason === 'reveal') {
            this.revealAnswer();
        }
    }

    public revealAnswer(stem = null) {
        stem = stem || this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1];
        // Disable button until action is complete
        this.UtilsService.addLoadingOverlay(true);

        this.ReportService.revealQuestion(this.AssessmentStateService.data.question.id, stem.id, this.AssessmentStateService.data.tr)
            .then((result: any) => {
                // Update question and stem statuses
                this.AssessmentStateService.data.answerKey = true;
                stem.isRevealable = false;

                // Iterate over question foils and update the grading
                stem.foils.forEach((foil, foilIndex) => {
                    // Find matching foil in the response
                    let responseFoil = result.find(resFoil => resFoil.id === foil.id);

                    foil.key = responseFoil || {};
                });

                // Show feedback toast
                this.handleScoreResult(stem);
                // Proceed to next part if the revealed stem is the current stem
                if (stem.id === this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1].id) {
                    this.proceedToNextQuestionPart();
                }
            })
            .catch(console.warn)
            .finally(() => this.UtilsService.removeLoadingOverlay());
    }

    private handleScoreResult(stem) {
        // let c = this.AssessmentStateService.data.multi.current;
        let t = this.AssessmentStateService.data.multi.total;
        let p = this.AssessmentStateService.data.isPracticeTest;
        this.AssessmentStateService.data.question.feedbackPrimary = t > 1 ? ('Part ' + stem.order + /* ' of ' + t + */ ': ') : '';

        if (stem.isCorrect) {
            this.AssessmentStateService.data.question.feedbackPrimary += (stem.isRevised ? 'Revised Correctly' : 'Correct');
            this.AssessmentStateService.data.question.score = 'correct';
            this.AssessmentStateService.data.question.feedbackSecondary = 'You answered this ' + (t > 1 ? 'part of the' : '') + ' question correctly!';
        } else if (!stem.isRevealable) {
            this.AssessmentStateService.data.question.feedbackPrimary += (stem.isRevised ? 'Revised and ' : '') + 'Revealed';
            this.AssessmentStateService.data.question.score = 'neutral';
            this.AssessmentStateService.data.question.feedbackSecondary = 'You revealed the answer.';
        } else if (stem.isIncorrect || p) {
            this.AssessmentStateService.data.question.feedbackPrimary += (stem.isRevised ? 'Revised Incorrectly' : 'Incorrect');
            this.AssessmentStateService.data.question.feedbackSecondary = (stem.isRevised ? 'U' : 'Try again or u') + 'se the reveal button to reveal the answer.';
            if (p) {
                this.AssessmentStateService.data.question.feedbackSecondary = 'Your did not earn credit for your answer.';
            }
            this.AssessmentStateService.data.question.score = 'incorrect';
        } else {
            this.AssessmentStateService.data.question.feedbackPrimary += (stem.isRevised ? 'Revised ' : '') + 'Partially Correct';
            this.AssessmentStateService.data.question.feedbackSecondary = (stem.isRevised || p ? 'You did not earn credit for your partially correct answer.' : ' Try again to get full credit!');
            this.AssessmentStateService.data.question.score = 'partial';
        }

        return this.AssessmentToastService.openToast({
            show: true,
            status: this.AssessmentStateService.data.question.score,
            title: this.AssessmentStateService.data.question.feedbackPrimary,
            message: this.AssessmentStateService.data.question.feedbackSecondary
        }, 0);
    }

    private selectAndGradeFoils(stem, foil, answerType) {
        let isFoilCorrect = (foil[answerType] && foil[answerType].grading === 3) || (!foil[answerType] && foil.value === 0);
        if (stem.type === 1) {
            if (foil[answerType]) {
                foil.isCorrect = isFoilCorrect;
                stem.selected = foil[answerType].foil;
            }
            return null; // Stem score check only done per foil for other stem types
        } else if (stem.type === 2) {
            if (foil[answerType] && foil[answerType].grading) {
                foil.selected = foil[answerType].foil === foil.id;
            }
        } else if (stem.type === 3 || stem.type === 4 || stem.type === 6) {
            foil.isCorrect = isFoilCorrect;
            if (foil[answerType]) {
                foil.selected = foil[answerType].response;
            }
        }
    }

    private proceedToNextQuestionPart() {
        // After revising or revealing answer make user proceed to next part if next part exists
        do {
            this.AssessmentStateService.data.multi.current += 1;
            // Reset time when user begins working on next part (no new question is fetched so it isn't reset)
            this.AssessmentStateService.data.questionStartTime = Math.floor(Date.now() / 1000);
        } while (this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1] &&
                this.AssessmentStateService.data.question.stems[this.AssessmentStateService.data.multi.current - 1].isCorrect);
        // Apply current stem part boundaries if necessary
        this.AssessmentStateService.data.multi.current = Math.min(this.AssessmentStateService.data.multi.current, this.AssessmentStateService.data.multi.total);
    }
}
