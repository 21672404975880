<!-- Margin top 1/3 of block -->
<div class="flex-row"></div>

<div class="question-detail flex-row align-items-center justify-center">
    <i class="icon" [ngClass]="getIconClass(question)"></i>
</div>

<div class="flex-row align-items-center justify-center space-children-row small-space">
    <span *ngIf="question.bookmark">
        <i class="icon icon-bookmark"></i>
    </span>

    <span *ngIf="question.misconception">
        <i class="icon icon-exclamation-triangle misconception-icon"></i>
    </span>

    <span *ngIf="question.difficulty === 1" class="item-difficulty" matTooltip="This question was aligned to this level but it was unusually easy">
        <i class="icon icon-arrow-down-1"></i>
    </span>
    <span *ngIf="question.difficulty === 2" class="item-difficulty" matTooltip="This question was aligned to this level but it was unusually hard">
        <i class="icon icon-arrow-up-1"></i>
    </span>
</div>
