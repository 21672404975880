import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MapService } from '../map.service';

import './map-slider.component.scss';


@Component({
    selector: 'map-slider',
    templateUrl: './map-slider.component.html',
    // styleUrls: ['./map-slider.component.scss'],
    host: {
        class: 'map-slider',
    },
})
export class MapSliderComponent {

    constructor(
        public MapService: MapService,
    ) {}

    public zoomSliderChanged($event) {
        let czl = this.MapService.learningMap.option.currentZoomLevel;
        let targetLevel = typeof $event === 'number' ? (czl + $event) : $event.value;

        if (targetLevel >= 0 && targetLevel <= 4) {
            this.MapService.zoomChange.next({ level: targetLevel, duration: 400 });
        }
    }
}
