<div #barTop class="bar-top text-center" *ngIf="scrollDirection === 'vertical'">
    <span *ngIf="enableButtons" (click)="scrollup()" class="cursor-pointer">
        <i class="button-top icon icon-caret-up"></i>
    </span>
</div>

<div class="bar-left text-center show-bar" *ngIf="scrollDirection === 'horizontal'">
    <span *ngIf="enableButtons" (click)="scrollLeft()" class="cursor-pointer" [class.inactive]="panelScrollable.scrollLeft <= 0">
        <i class="button-left fas fa-angle-double-left"></i>
    </span>
</div>

<div #panelScrollable class="panel-scrollable" [class.flex-row]="scrollDirection === 'horizontal'" [style.max-height]="maxHeight">
    <ng-content></ng-content>
</div>

<div #barBottom class="bar-bottom text-center" *ngIf="scrollDirection === 'vertical'">
    <span *ngIf="enableButtons" (click)="scrolldown()" class="cursor-pointer">
        <i class="button-bottom icon icon-caret-down"></i>
    </span>
</div>

<div class="bar-right text-center show-bar" *ngIf="scrollDirection === 'horizontal'">
    <span *ngIf="enableButtons" (click)="scrollRight()" class="cursor-pointer" [class.inactive]="panelScrollable.scrollLeft >= panelScrollable.scrollWidth - panelScrollable.clientWidth">
        <i class="button-right fas fa-angle-double-right"></i>
    </span>
</div>
