import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { StateService } from '@uirouter/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { AuthService } from '@/core/service/auth.service';
import { AnalyticsService } from '@/core/service/analytics.service';
import { GrowlerService } from '@/core/service/growler.service';
import { SessionService } from '@/core/service/session.service';


@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    constructor(
        private $state: StateService,
        private AuthService: AuthService,
        private AnalyticsService: AnalyticsService,
        private GrowlerService: GrowlerService,
        private SessionService: SessionService,

    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startTimestamp: number = new Date().getTime();
        const requestMethod = request.method;
        const requestData = request.method === 'GET' ? request.params : request.body;

        this.SessionService.lastInteraction = startTimestamp;

        return next.handle(request)
            .pipe(
                retry(2),
                catchError(errorResponse => this.processException(errorResponse, startTimestamp, requestMethod, requestData))
            );
    }

    private processException(response: HttpErrorResponse, startTimestamp: number, requestMethod: string, requestData: any) {
        const endTimestamp: number = new Date().getTime();
        const duration = (endTimestamp - startTimestamp) / 1000;

        // Only log the error if this is not the endpoint that is failing to avoid infinite requests
        // Also, ignore errors caused from aborting requests when closing/refreshing the page
        if (
            response.url.includes('/events/') ||
            response.url.includes('/logout/') ||
            (response.status === -1 && this.SessionService.unloadingPage)
        ) {
            return throwError(response);
        }

        let msg = response.statusText;

        if (response.error) {
            if (response.error.non_field_errors) {
                msg = response.error.non_field_errors.join(' ');
            } else if (response.error.detail) {
                msg = response.error.detail;
            } else if (response.error.result) {
                msg = response.error.result.msg;
            }
        }

        if (!navigator.onLine) {
            // Handle offline error
            this.GrowlerService.warning('Please reconnect and try again', 'No Internet Connection');
            return throwError(response);
        } else {
            if (response.status <= 0) {
                // We aren't getting any data from the server - possible scenarios:
                // Server is offline or restarting, request times out after 120 seconds,
                // request is somehow cancelled without being resolved perhaps due to an adblocker or inconsistent network connection
                this.GrowlerService.warning('Please try again in a few seconds', 'Server could not be reached');
            } else if (response.status === 400) {
                this.GrowlerService.error(msg, 'Bad Request');
            } else if (response.status === 401) {
                this.GrowlerService.error(msg, 'Please log in again');
                this.logoutUser();
            } else if (response.status === 403) {
                this.GrowlerService.error(msg, 'No access to this resource');
                if (this.SessionService.userAccess && this.SessionService.userAccess.page) {
                    this.$state.go(this.SessionService.userAccess.page);
                } else {
                    this.logoutUser();
                }
            } else if (response.status === 404) {
                this.GrowlerService.error('Resource not found on server', 'Not Found');
            } else {
                this.GrowlerService.error('Please try again later', 'We are experiencing difficulties');
            }
        }

        // Remove user sensitive information before logging it
        for (const [key, value] of requestData.map) {
            if (key.includes('password')) {
                requestData.map.delete(key);
            }
        }

        // Do not log error if user token is expired (user will still be redirected to login after status code check)
        if (msg.includes(' has expired')) {
            return throwError(response);
        }

        const errorDetails = {
            'status': response.status,
            'statusText': response.statusText,
            'url': response.url,
            'state': this.$state.current.name,
            'duration': duration,
            'method': requestMethod,
            'data': requestData.toString(),
        };

        if (response.status <= 0) {
            this.AnalyticsService.warning(errorDetails);
        } else {
            this.AnalyticsService.error(errorDetails);
        }

        // We need to return the promise rejection so we can continue the promise chain and
        // so that catch methods further down the line have access to the response object
        return throwError(response);
    }

    private logoutUser() {
        this.AuthService.logout({
            isForcedLogout: true,
            nextStateName: this.$state.current.name,
            nextStateParams: this.$state.params,
        }).catch(console.warn);
    }
}
