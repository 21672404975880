import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { StateService } from '@uirouter/core';

import { UtilsService } from '@/core/service/utils.service';
import { AnalyticsService } from '@/core/service/analytics.service';
import { HeatmapService } from './heatmap.service';

import './heatmap.component.scss';


@Component({
    selector: 'heatmap-page',
    templateUrl: './heatmap.component.html',
    // styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapPageComponent implements OnInit, OnDestroy {

    public reportData: any;
    public testformType: string;
    public gradeLevel: string;
    public patternedConstructs: any;

    constructor(
        private $state: StateService,
        public UtilsService: UtilsService,
        private AnalyticsService: AnalyticsService,
        public HeatmapService: HeatmapService,
    ) {}

    public ngOnInit() {
        const p = this.$state.params;

        this.UtilsService.addLoadingOverlay();

        if (Number(p.asspId)) {
            this.HeatmapService.getClusterHeatmap(Number(p.asspId), Number(p.retestId))
                .then(r => this.initReportData(r))
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        } else if (Number(p.sectionId) && Number(p.clusterId) && Number(p.gradeband)) {
            this.HeatmapService.getStudentTriesHeatmap(Number(p.sectionId), Number(p.clusterId), Number(p.gradeband))
                .then(r => this.initReportData(r))
                .catch(console.warn)
                .finally(() => this.UtilsService.removeLoadingOverlay());
        } else {
            this.UtilsService.removeLoadingOverlay();
        }
    }

    public ngOnDestroy() {
        this.HeatmapService.resetHeatmapFilters();
    }

    private initReportData(reportData) {
        this.reportData = reportData;
        this.testformType = this.UtilsService.testformTypeToText(this.reportData.assp.type);
        this.gradeLevel = this.UtilsService.gradeBinToText(this.reportData.assp.grade);

        // If this is a construct check show a heatmap of only the construct
        if (this.reportData.assp.construct) {
            this.HeatmapService.testedConstructs = this.reportData.heatmap.cluster.constructs.filter(co => co.id === this.reportData.assp.construct.id);
            this.patternedConstructs = [this.HeatmapService.testedConstructs];
        } else {
            this.HeatmapService.testedConstructs = this.reportData.heatmap.cluster.constructs;
            this.patternedConstructs = this._patternConstructs(this.reportData.heatmap.cluster);
        }

        if (this.reportData.assp.testforms) {
            let currentTest = this.reportData.assp;
            let testforms = currentTest.testforms;
            let retestId = Number(this.$state.params.retestId);

            if (retestId) {
                currentTest = this.reportData.assp.retest.find(r => r.retest_id === retestId);
                testforms = currentTest.testforms;
            }

            testforms.forEach((testform, idx) => {
                this.HeatmapService.filters.testforms[testform] = {
                    name: currentTest.testform_names[idx],
                    selected: true,
                };
            });
        }
    }

    public viewStudentFeedback() {
        this.HeatmapService.openStudentFeedbackModal(this.reportData.feedback);
    }

    public toggleActiveStudent(studentId) {
        let studentIdx = this.HeatmapService.filters.highlightedStudents.indexOf(studentId);
        if (studentIdx > -1) {
            this.HeatmapService.filters.highlightedStudents.splice(studentIdx, 1);
        } else {
            this.HeatmapService.filters.highlightedStudents.push(studentId);
        }
        this.AnalyticsService.action({ action: 'heatmap_students_toggled', 'student_uids': this.HeatmapService.filters.highlightedStudents });
    }

    public resetFilters() {
        this.HeatmapService.filters.highlightedStudents.splice(0, this.HeatmapService.filters.highlightedStudents.length);
        this.HeatmapService.filters.highlightedQuestion = null;
        this.AnalyticsService.action({ action: 'heatmap_filters_reset' });
    }

    public emitRevisedValue() {
        this.HeatmapService.revisedFilterChanged();
        this.recordFilterAnalytics('revised');
    }

    public recordFilterAnalytics(filterName) {
        this.AnalyticsService.action({ action: 'heatmap_toggle_filter_' + filterName, filters: this.HeatmapService.filters });
    }

    private _patternConstructs(cluster) {
        // Function to group constructs by pattern
        // e.g. pattern '123' would return [ [co, co, co], [co, co], [co] ]
        let clusterPattern = cluster.pattern.split('').reverse();
        let constructIdx = cluster.constructs.length - 1;

        // Sort constructs by order before making construct pattern groups
        cluster.constructs.sort((a, b) => a.order - b.order);

        return clusterPattern.map(numConstructs => {
            let arr = new Array(Number(numConstructs));
            for (let i = 0; i < arr.length; i++) {
                arr[i] = cluster.constructs[constructIdx];
                constructIdx -= 1;
            }
            return arr.reverse();
        });
    }
}
