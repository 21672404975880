<svg:g [attr.transform]="'translate(' + construct.attributes.cx + ', ' + construct.attributes.cy + ')'">
    <svg:circle
        class="pin-pulse"
        *ngIf="MapService.searchable.meta.displaySelected"
        [attr.id]="'misconception-pulse-' + construct.id"
        [attr.cx]="construct.attributes.radius * 0.7 + 40 * mapSizesAndBounds.construct.misconceptionScaling[MapService.learningMap.option.currentZoomLevel]"
        [attr.cy]="construct.attributes.radius * 0.4 + 40 * mapSizesAndBounds.construct.misconceptionScaling[MapService.learningMap.option.currentZoomLevel]"
        r="0"
        fill="white"
        opacity="0">
    </svg:circle>

    <svg:image
        class="misconception-pin-image"
        xlink:href="src/assets/image/svg/misconception_icon.svg"
        *ngIf="MapService.showPin(construct.id, 'misconceptions', 'constructs')"
        [attr.transform]="'translate(' + (construct.attributes.radius * 0.7) + ', ' + (construct.attributes.radius * 0.4) + ') scale(' + mapSizesAndBounds.construct.misconceptionScaling[MapService.learningMap.option.currentZoomLevel] + ')'">
    </svg:image>
</svg:g>
