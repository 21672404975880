import { Component, Input, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

import { mapSizesAndBounds } from '@/app.constant';
import { MapService } from '../map.service';

import './map-region-popover.component.scss';


@Component({
    selector: '[map-region-popover]',
    templateUrl: './map-region-popover.component.svg.html',
    // styleUrls: ['./map-region-popover.component.scss'],
    host: {
        class: 'map-region-popover',
    },
})
export class MapRegionPopoverComponent implements AfterViewInit, OnDestroy {

    @Input() region: any;
    public readonly mapSizesAndBounds = mapSizesAndBounds;

    constructor(
        private element: ElementRef,
        public MapService: MapService,
    ) {}

    public ngAfterViewInit() {
        // Bring region popovers to the "front" so they aren't hidden by other regions
        this.element.nativeElement.closest('#zoom-element').appendChild(this.element.nativeElement);
    }

    public ngOnDestroy() {
        this.element.nativeElement.remove();
    }

    public hidePopover($event) {
        $event.stopPropagation();

        if (this.MapService.learningMap.option.showAllRegionLabels) {
            // if all the popovers are already displayed, then hide them all
            this.MapService.learningMap.option.showAllRegionLabels = false;
            this.MapService.allRegionLabelsChanged();
        } else {
            this.region.showPopover = false;
        }
    }
}
